export default {
  isShowbrTaxhint(state){
    return state.checkout?.checkoutBFFAbtInfo?.brTaxhint?.param?.isSwitchOn == 1 ? true : false
  },
  isNewShopBagPopTypeByAbt(state) {
    if(state.locals.IS_RW ?? gbCommonInfo?.IS_RW) return false
    return state.checkout.checkoutBFFAbtInfo?.ShoppingBagPopType?.param?.ShoppingBagPopType == 'Item_Detail' ? true : false
  },
  isNewPrimeVersion(state) {
    return state.checkout?.checkoutBFFAbtInfo?.OrderEntryprimeVersion?.param?.primeVersion == 'new' ? true : false
  },
  isShowPriceReminderByAbt(state) {
    return state.checkout.checkoutBFFAbtInfo?.PriceReminder?.param?.PriceReminderswitch == 'show' ? true : false
  },
  // 购物袋入口位置
  shoppingBagPosition(state, getters){
    return getters.isSiteMallList ? true : state.checkout?.checkoutBFFAbtInfo.Shoppingbagposition?.param?.shoppingbag_position == 'old' ? false : true
  },
  // abt开启快速运输
  showQuickShipByAbt(state, getters){
    return getters.isSiteMallList ? false : state.checkout?.checkoutBFFAbtInfo?.TradeQuickship?.p == 'show_QuickShipping' ? true : false
  },
  forcedShippingByAbt(state) {
    return state.checkout?.checkoutBFFAbtInfo?.forcedShipping?.param == 'forcedShippingGuarantee'
  },
  switchQsFlagByAbt(state, getters) {
    let switch_qs_flag = 0
    const qsMakeup = state.checkout?.checkoutBFFAbtInfo?.quickshipFeeSwitch?.param?.qsMakeup || 0
    
    const singleMallCode = state.checkout?.results?.mallInfo?.mallInfoForSite?.[0]?.mall_code || ''
    const filterCarts = state.checkout?.results?.carts?.carts?.filter(item => item.mall_code == singleMallCode) || []
    const quickShipCarts = filterCarts?.filter(item => item.real_quick_ship == 1) || []
    const isAllQuickShip = !!quickShipCarts.length && (filterCarts.length == quickShipCarts.length)
    
    if (getters.showQuickShipByAbt && !isAllQuickShip) switch_qs_flag = qsMakeup
    
    return switch_qs_flag
  },
  orderInterceptByAbt(state) {
    return state.checkout?.checkoutBFFAbtInfo?.OrderInterceptionRules?.param?.InterceptionRulesStatus == 'on' ? true : false
  },
  userBehaviorAbt(state) {
    return state.checkout.checkoutBFFAbtInfo?.UserBehaviorTips?.param || {}
  },
  // 是否展示时效百分比
  timePercentAbt(state){
    return state.checkout.checkoutBFFAbtInfo?.TimePercent?.param?.['show_shippingpercentage'] == 0 ? false : true
  },
  lowestPriceDays(state) {
    return state.checkout?.checkoutBFFAbtInfo?.LowestPriceDays?.param || {}
  },
  canEditShoppingbag(state) {
    if(state.locals.IS_RW ?? gbCommonInfo?.IS_RW) return false
    return state.checkout?.checkoutBFFAbtInfo?.editShoppingbag?.param?.editstatus == '1'
  },
  showOrderRulesByAbt(state) {
    if(state.locals.IS_RW ?? gbCommonInfo?.IS_RW) return false
    return true
  },
  showOrderBubbleSort(state) {
    const sortList = state.checkout?.checkoutBFFAbtInfo?.placeOrderbubblesort?.param?.placeOrderbubblesortvalue || 'promotion,inventory,coupon'
    return sortList.toLocaleLowerCase().replace(/\s/g, '').split(',')
  },
  underPriceAbtValue (state) {
    return state.checkout?.checkoutBFFAbtInfo?.UnderPrice?.param?.UnderPriceShow == 'A' ?  true : false
  },
  autoRenewalPrimeShowAbt(state) {
    return state.checkout?.checkoutBFFAbtInfo?.sheinClubRenew?.param?.sheinClubRenewStatus == 1
  },
  shoppingBagCrossedPriceByAbt(state) {
    return state.checkout?.checkoutBFFAbtInfo?.Shoppingbagcrossedprice?.param?.add_shoppingbag_price_switch != 'off'
  },
  discountLogoByAbt(state) {
    return state.checkout?.checkoutBFFAbtInfo?.Discountlogo?.param?.add_discount_logo_switch != 'off'
  },
  saverNewStyleAbt(state) {
    return state.checkout?.checkoutBFFAbtInfo?.saverNewOrderStyle?.param?.saverNewOrderStyleStatus == 1
  },
  // 是否自动使用钱包
  isAutoUseWallet(state) {
    return state.checkout.checkoutBFFAbtInfo?.autoWalletBalance?.param?.isAuto == 1
  },
  orderConfirmDiscountSwitchByAbt(state) {
    const SiteUID = state.locals.SiteUID ?? gbCommonInfo?.SiteUID
    const isBrSite = !!~SiteUID.indexOf('br')
    const IS_RW = state.locals.IS_RW ?? gbCommonInfo?.IS_RW

    if(IS_RW || isBrSite) return false
    // abt全推 orderConfirmDiscountSwitchStatus 写死等于1 不受abt控制
    return true
  },
  placeorderStatusmoveByAbt(state) {
    const SiteUID = state.locals.SiteUID ?? gbCommonInfo?.SiteUID
    const isBrSite = !!~SiteUID.indexOf('br')
    const IS_RW = state.locals.IS_RW ?? gbCommonInfo?.IS_RW

    if(IS_RW || isBrSite) return false
    // 代码推全 placeorderStatusmoveStatus 等于1 不受abt控制
    return true
  },
  showAddEntranceByAbt(state, getters){
    return getters.isSiteMallList ? false : state.checkout?.checkoutBFFAbtInfo?.CheckoutShippingAddEntracne?.param?.checkout_shipping_add_entracne_switch == 'on'
  },
  // 下单页面凑单入口是否展示新逻辑
  showNewAddEntranceByAbt(state){
    return state.checkout?.checkoutBFFAbtInfo?.CheckoutShippingAddEntracne?.param?.checkout_shipping_add_entracne_strategy == 'on'
  },
  shippingTipEnhanceByAbt(state){
    return state.checkout?.checkoutBFFAbtInfo?.CheckoutShippingTipEnhance?.param?.checkout_shipping_tip_enhance_switch == 'on'
  },
  clubScribePriceByAbt(state) {
    const IS_RW = state.locals.IS_RW ?? gbCommonInfo?.IS_RW
    if(IS_RW) return false

    const SiteUID = state.locals?.SiteUID ?? gbCommonInfo?.SiteUID
    return state.checkout?.checkoutBFFAbtInfo?.ClubScribePrice?.param?.ClubScribePrice == 'noshow' && ['fr', 'pl', 'de'].includes(SiteUID)
  },
  shippingSlideByAbt(state) {
    return state.checkout?.checkoutBFFAbtInfo?.shippingVersion?.param?.isShippingVersion == 'new'
  },
  // 是否开启evoluSHEIN标签
  switchOnEvoluSHEIN(state) {
    return state.checkout?.checkoutBFFAbtInfo?.evoluSHEINShow?.param?.evoluSHEIN_show === 'on'
  },
  showNewCouponLanguage(state) {
    return state.checkout?.checkoutBFFAbtInfo?.Placeordercoupon?.param?.Placeordercouponvalue == 1
  },
  showArrivalPriceByAbt() {
    return true
  },
  sheinClubUIByAbt(state) {
    const SiteUID = state.locals?.SiteUID || gbCommonInfo?.SiteUID || ''
   
    let res = 1
    switch(SiteUID){
      case 'ar': // 只有ar 站是2其他其他都是1
        res = 2
        break
    }
    return res
    // return state.checkout?.checkoutBFFAbtInfo?.Ordersheinclub?.param?.sheinclubui || 0
  },
  saverHeightByAbt(state) {
    return state.checkout?.checkoutBFFAbtInfo?.saverHeight?.param?.saverHeightStatus == 1
  },
  storeIconChangeByAbt(state) {
    return state.checkout?.checkoutBFFAbtInfo?.storeiconchange?.param?.storeiconchange == 'new'
  },
  // 是否支持卡分期前置判断 目前仅支持卡分期
  isUsePrePayForCheckout(state, getter) {
    if (getter.isSelectInstallmentTokenPay) return false
    return state.status.cardPay === 'routepay-cardinstallment' && 
      state.checkout?.checkoutBFFAbtInfo?.cardinstallmentPaymentFront?.param?.cardinstallmentPaymentFrontStatus == 1
  },
  // 卡分期是否需要二次check
  isCheckCardinstallmentInfoStatus(state, getter) {
    if (getter.isSelectInstallmentTokenPay) return false
    return state.status.cardPay === 'routepay-cardinstallment' && 
      state.checkout?.checkoutBFFAbtInfo?.checkCardinstallmentInfo?.param?.checkCardinstallmentInfoStatus == 1 // 卡分期需要二次check
  },
  // 控制气泡展示的时机和气泡的文案
  bubbleCopyWritingByAbt(state) {
    const BubbleCopywriting = state.checkout?.checkoutBFFAbtInfo?.BubbleCopywriting?.param?.BubbleCopywriting || 'on'
    return BubbleCopywriting  == 'on'
  },
  defaultLocalSizeByAbt(state) {
    return state.checkout?.checkoutBFFAbtInfo?.defaultlocalsize?.param?.defaultlocalsize || ''
  },
  isUseNewCheckoutLoading(state) {
    return state.checkout?.checkoutBFFAbtInfo?.PaymentSafetyExperiment?.param?.PaymentSafetyFlow === 'Show' // 安全流程动效
  },
  // 在Palce Order 按钮底部，设置利诱点的提示
  placeOrderBarByAbt(state) {
    return state.checkout?.checkoutBFFAbtInfo?.PlaceOrderBar?.param?.PlaceOrderBar
  },
  outOfStockTips(state) {
    return state.checkout?.checkoutBFFAbtInfo?.outofstocktips?.param || {}
  },
  showCartRefundByAbt(state) {
    return state.checkout?.checkoutBFFAbtInfo?.Cartrefund?.param?.cart_nonrefundable == 'on'
  },
  CartOrderSFSVisual(state) {
    return {
      visual_tabs: state.checkout?.checkoutBFFAbtInfo?.CartOrderSFSVisual?.param?.visual_tabs == 'show_tabs',
      visual_benefit_point: state.checkout?.checkoutBFFAbtInfo?.CartOrderSFSVisual?.param?.visual_benefit_point == 'show_benefit_point'
    }
  },
  showNewPriceDetailByAbt(state) {
    return state.checkout?.checkoutBFFAbtInfo?.NewPricedetail?.param?.NewPricedetailStatus == 1
  }
} 

