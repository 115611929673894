import type { Libs } from '../types'

export class ProxyHost<T extends string>
  implements Libs.EventProxyHostInterface<T>
{
  readonly collections: Record<string, Partial<Libs.FuncObject<T>>> = {}

  public subscribe(compName: string, funcs: Partial<Libs.FuncObject<T>>) {
    this.collections[compName] = funcs
  }

  public describe(compName: string) {
    delete this.collections[compName]
  }

  public trigger<K extends T>(event: K, params?: any) {
    Object.values(this.collections).forEach(_ => _?.[event]?.(params))
  }
}
