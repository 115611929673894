<template>
  <s-alert
    v-if="sessionShowDom && msg"
    v-expose="{ id: '1-7-2-17' }"
    v-tap="{ id: '1-7-2-16' }"
    class="cart-rule-info"
    type="warning"
    :max-rows="0"
    closeable
    @close="onClose"
  >
    <span v-html="msg"></span>
  </s-alert>
</template>
<script>

export default {
  name: 'CartRuleInfo',
}
</script>
<script setup>
import { ref } from 'vue'
defineProps({
  msg: {
    type: String,
    default: ''
  }
})

let sessionShowDom = ref(true)

const onClose = () => {
  sessionShowDom.value = false
}
</script>

<style lang="less">
.cart-rule-info {
  padding: 12px;
  color: #000;

  .sui-alert__flex-operate {
    margin-left: 8px;
  }

  .sui-alert__flex-closebtn {
    margin-left: 0;
  }
}
</style>
