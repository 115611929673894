<script setup>
import { ref, inject, nextTick, computed } from 'vue'
import { SCheckbox } from '@shein-aidc/sui-checkbox'
import { SMessage } from '@shein-aidc/sui-message'
import { handleUpdateAndUpdateAttrErr } from 'public/src/pages/cart_new/utils/index.js'
import {
  selectGoodsReport,
  updateGoodsAttrReport,
  clickQtyInputReport,
  qtyExposeReport,
  inputQtyChangeReport,
  selectQtyChangeReport,
} from 'public/src/pages/common/business-cart-bff/utils/analysis.js'
import { scroll } from 'public/src/pages/common/business-cart-bff/utils/scroll.js'
import { getImageRatio } from 'public/src/pages/common/utils/index.js'
import { BMainCartItem, getConstant } from '@shein-aidc/bs-cart-item-laptop'
import QuickViewPopover from 'public/src/pages/common/quickView/index.js'
import { isCustomizationProduct } from '../utils/index'
import { UPDATE_CART_INFO_ACTIONS } from '../constants'

/**
 * 非标购物车商品行组件
 */
const emit = defineEmits(['expose', 'rePick'])
const refetchData = inject('refetchData')
const modify = inject('modify')
const updateQty = inject('updateQty')
const openDelete = inject('openDelete')

// props
const props = defineProps({
  /**
   * 商品行数据
   */
  data: {
    type: Object,
    default: () => ({})
  },
  /**
   * 多语言包
   */
  language: {
    type: Object,
    default: () => ({})
  },
  /**
   * 非标界面配置
   */
  uiConfig: {
    type: Object,
    default: () => ({}),
  },
})

const constant = ref(getConstant())
// 商品行配置
const use = computed(() => {
  const defaultUse = [
    'GoodsImg',
    'GoodsDiscountFlag',
    'GoodsBelt',
    'GoodsTitle',
    'EvoluTag',
    'SFSTag',
    'MorePromotionTag',
    'GoodsPrice',
    'GoodsDeleteButton',
    'GoodsQty',
    'DiscountNormalTag',
    'DiscountLimitTag',
    'EstimatedPrice',
    'GoodsSaleAttr',
    'GoodsRepickButton',
  ]
  if (props.uiConfig.showQuickShip === '1') {
    defaultUse.push('QuickShipTag')
  }
  if (props.uiConfig.showFreeShip === '1') {
    defaultUse.push('FreeShippingTag')
  }
  if (props.uiConfig.MorePromotionTag === '1') {
    defaultUse.push('MorePromotionTag')
  }
  return defaultUse
})
const config = ref({
  // 商品图片
  GoodsImg: {
    props({ data }) {
      const imgUrl = data.value.product?.goods_img || ''
      // 是否是定制商品
      const isCustomGood = isCustomizationProduct(data.value)
      return {
        isClick: false,
        isShowMask: false,
        isLazyLoad: true,
        isCutImg: !isCustomGood,
        imgUrl,
      }
    }
  },
  // 折扣角标
  GoodsDiscountFlag: {
    props() {
      return {
        isShowMask: false
      }
    }
  },
  // 商品腰带
  GoodsBelt: {
    props() {
      return {
        isShowMask: false
      }
    }
  },
  GoodsTitle: {
    props() {
      return {
        isClick: false,
        isShowMask: false
      }
    }
  },
  // 快速发货标签
  QuickShipTag: {
    props() {
      return {
        isShowMask: false,
      }
    }
  },
  // 免邮标签
  FreeShippingTag: {
    props() {
      return {
        isShowMask: false,
      }
    }
  },
  // 环保标签
  EvoluTag: {
    props() {
      return {
        isShowMask: false,
      }
    }
  },
  // SFS标签
  SFSTag: {
    props() {
      return {
        isShowMask: false,
      }
    }
  },
  MorePromotionTag: {
    props() {
      return {
        isShowMask: false,
      }
    }
  },
  // 商品价格
  GoodsPrice: {
    props({ data }) {
      return {
        isShowPremiumMemberIcon: !!data.value.aggregateProductBusiness?.priceData?.unitPrice?.icon,
        isShowSheinVipIcon: false,
        isShowRomweVipIcon: false,
        isShowTimeIcon: false,
        isShowAppOnlyIcon: false,
        isShowBrandSaleIcon: false,
        isShowFlashSaleIcon: false,
      }
    }
  },
  // 重选按钮
  GoodsRepickButton: {
    events: {
      onClick() {
        emit('rePick')
      }
    }
  },
  // 商品删除
  GoodsDeleteButton: {
    type: 'v2',
    events: {
      onClick({ data }) {
        openDelete(data.value)
      }
    }
  },
  /**
   * 商品数量
   */
  GoodsQty: {
    props() {
      return {
        isSoldoutByInventory: false,
        isShowMask: false,
      }
    },
    events: {
      async onChange({ data, event }) {
        // 缓存更新前的值
        const num = data.value.quantity
        // 更新成字符串，避免后续恢复原值不触发更新
        data.value.quantity = String(event.quantity)

        const handleReport = (item, result) => {
          if (event.action === 'input') {
            inputQtyChangeReport({ item, result })
          }
          if (event.action === 'select') {
            selectQtyChangeReport({ item, result })
          }
        }

        const _data = {
          cartId: data.value.id,
          quantity: event.quantity,
          isChecked: data.value.is_checked,
          appendIdList: data.value.appendIds,
          onError: (res) => {
            // 更新失败，恢复原值
            data.value.quantity = num
            handleReport(data.value, 0)
            if (res?.msg) {
              SMessage({
                message: res.msg,
                type: 'error'
              })
            }
          },
          onSuccess: () => {
            handleReport(data.value, 1)
          }
        }
        updateQty(_data)
      },
      onReport({ event, data }) {
        if (event.type === 'clickInput') {
          clickQtyInputReport({ item: data.value })
        }
        if (event.type === 'clickNullValueOption') {
          selectQtyChangeReport({ item: data.value, result: '-' })
        }
      },
      onExpose({ data }) {
        qtyExposeReport({ item: data.value })
      }
    }
  },
  DiscountNormalTag: {
    props() {
      return {
        isShowMask: false,
      }
    },
  },
  DiscountLimitTag: {
    props() {
      return {
        isShowMask: false,
      }
    },
  },
  /**
   * 到手价
   */
  EstimatedPrice: {
    props() {
      return {
        isShowMask: false,
        hideCountdown: true,
      }
    },
  },
  /**
   * 商品销售属性
   */
  GoodsSaleAttr: {
    props({ data }) {
      return {
        loading: data.value.isOpenQuickView,
        isShowMask: false,
      }
    },
    events: {
      async onClick({ data, event }) {
        const toggleLoading = () => {
          data.value.isOpenQuickView = !data.value.isOpenQuickView
        }
        if (event.isCustomGoods && event.event === 'attr') {
          SMessage({
            htmlString: props.language.SHEIN_KEY_PC_27388,
            type: 'warning',
          })
          return
        }
        toggleLoading()
        const getSelectedAttrIndex = () => {
          const selectedAttrIndex = {}
          const attr = data.value.attr || null
          const sku_sale_attr = data.value.product?.sku_sale_attr || []
          if(attr) {
            selectedAttrIndex[attr.attr_id] = { attr_value_id: attr.attr_value_id }
          }
          sku_sale_attr && sku_sale_attr.forEach(item => {
            if(item.is_main != '1' && !selectedAttrIndex[item.attr_id]) {
              selectedAttrIndex[item.attr_id] = { attr_value_id: item.attr_value_id }
            }
          })
          return selectedAttrIndex
        }
        const handleUpdateOrDone = async (res) => {
          if (+res.code === 0) {
            await refetchData({ action: UPDATE_CART_INFO_ACTIONS.UPDATE_ATTR, isShowLoading: true })
            if(data.value.mall_code != res.info.cart.mall_code || data.value.id != res.info.cart.id){
              nextTick(() => {
                scroll(res.info.cart.id)
              })
            }
          } else {
            let errorMessage = handleUpdateAndUpdateAttrErr(res, props.language, data.value)
            errorMessage.msg && SMessage({
              message: errorMessage.msg,
              type: errorMessage.times ? 'error' : 'warning',
              offset: 180,
              duration: errorMessage.times || 3000
            })
          }
        }
        QuickViewPopover.show({
          goodsId: data.value.product.goods_id,
          imgRatio: await getImageRatio(data.value.product.goods_img),
          mallCode: data.value.mall_code,
          showPromotionTagInfo: false,
          extendForm: {
            id: data.value.id,
            quantity: +data.value.quantity,
            selectedAttrIndex: getSelectedAttrIndex(),
          },
          config: {
            isUpdateButton: true,
            hideViewFullDetail: true,
            hideWishlistBtn: true,
            showOneClickPay: false,
            showPromotionTagInfo: false,
          },
          callback: {
            handleOpenQuickViewDone() {
              toggleLoading()
              updateGoodsAttrReport({
                sku_id: data.value.product.goods_sn,
              })
            },
            // 点击更新按钮之后的回调
            handleUpdateDone: handleUpdateOrDone,
            // 加车成功之后的回调
            handleAddToCartDone: handleUpdateOrDone,
          }
        })
      }
    }
  },
})

// methods
const handleCheckedItem = async ({ checked = false }) => {
  let targetItem = props.data
  if((parseInt(targetItem.quantity) > parseInt(targetItem.realTimeInventory))){
    SMessage({
      message: props.language.SHEIN_KEY_PC_21623,
      type: 'error'
    })
    return
  }
  const data = {
    cartIdList: [targetItem.id],
    operationType: checked ? '1' : '2',
    appendIdList: targetItem.appendIds,
  }
  await modify(data)
  selectGoodsReport({
    is_select: checked ? 0 : 1,
    select_type: 1,
  })
}

const checked = computed(() => props.data.is_checked == '1')
const disabledCheckbox = computed(() => props.data.aggregateProductBusiness?.isAdditionGoods == '1')
const additionGoodsView = computed(() => props.data.aggregateProductBusiness?.cartRowViewControl?.additionGoodsView)
const styles = computed(() => {
  return {
    '--bg-color': !!additionGoodsView.value ? '#FFF8E1' : '#fff'
  }
})
</script>

<template>
  <div
    class="bc-cart-item"
    :style="styles"
  >
    <div class="bc-cart-item__checked">
      <SCheckbox
        v-model="checked"
        :disabled="disabledCheckbox"
        :gap="'4px'"
        outside-check
        @change="handleCheckedItem"
      />
    </div>
    <div class="bc-cart-item__item">
      <BMainCartItem
        :data="data"
        :use="use"
        :config="config"
        :language="language"
        :constant="constant"
        @expose="emit('expose')"
      />
    </div>
  </div>
</template>

<style lang="less" scoped>
.bc-cart-item {
  display: flex;
  align-items: center;
  background-color: var(--bg-color);
  &__checked {
    flex-shrink: 0;
    width: 20px;
    margin-right: 16px;
  }
  &__item {
    flex: 1;
  }
  :deep(.main-carts__wrap) {
    background-color: var(--bg-color);
  }
}
</style>
