<!-- cccNoticeContent: '' 公告内容 -->
<template>
  <common-notice
    v-if="data.content"
    class="ccc-notification"
    :rtl-icon-roate="rtlIconRoate"
    :message="data.content"
    @close-alert="closeCccNotice"
    @show-detail-evt="exposeCccDetail"
  />
</template>
<script setup>
import { ref } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter'
import { setLocalStorage } from '@shein/common-function'
import commonNotice from '../../../common/commonNotice/index.vue'

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  },
  isRtl: {
    type: Boolean,
    default: false
  }
})

const rtlIconRoate = ref(props.isRtl || gbCommonInfo?.GB_cssRight)

const closeCccNotice = () => {
  setLocalStorage({
    key: 'hideCccNotice',
    value: '1',
    expire: 24 * 60 * 60 * 1000
  })
  daEventCenter.triggerNotice({
    daId: '1-22-2-8'
  })
  // 公告优化 产品新加的埋点 担心之前的埋点没有被使用了 保留两份
  daEventCenter.triggerNotice({
    daId: '1-22-2-17'
  })
}
const exposeCccDetail = () => {
  daEventCenter.triggerNotice({
    daId: '1-22-2-15'
  })
}
</script>
<style lang="less" scoped>
.ccc-notification {
  margin-bottom: 10px;
}
</style>
