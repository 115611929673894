<template>
  <div
    v-if="isCouponValid"
    class="head-right"
  >
    <div
      v-if="buttonInfo"
      class="head-right__top"
    >
      <!-- button为1时，券面存在use按钮,为2时，券面存在领取按钮 -->
      <!-- class禁用：forbidden，非禁用no-forbidden -->
      <s-button
        v-if="[COUPON_LAYER_BUTTON_TYPE.shop, COUPON_LAYER_BUTTON_TYPE.claim].includes(buttonInfo)"
        :type="['primary']"
        :class="[
          'no-forbidden',
          'apply-btn',
          buttonInfo === COUPON_LAYER_BUTTON_TYPE.claim
            ? 'no-forbidden-hover'
            : ''
        ]"
        :style="
          buttonInfo === COUPON_LAYER_BUTTON_TYPE.claim
            ? { color: '#fff', background: '#FA6338', border: 'none' }
            : { color: '#fff' }
        "
        @click="handleButtonClick({ btnType: buttonInfo, couponInfo: data })"
      >
        {{ buttonText }}
      </s-button>
      <!-- button为3时，券面存在可选筛选框 -->
      <div v-if="['3', '4', '5', '6'].includes(buttonInfo)">
        <div class="checkbox-item">
          <s-checkbox
            v-model="isChecked"
            class="coupon__checkbox"
            size="size16"
            @change="e => handleChange({ checked: e, couponInfo: data, btnType: buttonInfo })"
          />
        </div>
      </div>
    </div>
    <div
      v-if="promptInfo?.length"
      class="head-right__bottom"
    >
      <!-- inform为best_choice时，券面存在最佳优惠提示,为stackable时，券面存在可叠加标签 -->
      <div
        v-for="item in promptInfo"
        :key="item"
        class="tag-content"
      >
        <!-- 最佳优惠icon -->
        <Icon
          v-if="item === 'best_choice'"
          :name="`sui_icon_${iconType[couponColorType]}_bestchoice_12px_color`"
          size="14px"
          color="#C44A01"
          :is-responsive-name="true"
          class="icon"
        />
        <!-- 可叠加 -->
        <Icon
          v-if="item === 'stackable'"
          :name="`sui_icon_${iconType[couponColorType]}_stackable_12px_color`"
          size="13px"
          :is-responsive-name="true"
          color="#C44A01"
          class="icon"
        />
        <span class="informText">
          {{ getInformText(item, language) || '' }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HeadRight'
}
</script>
<script setup>
import { Icon } from '@shein-aidc/icon-vue3'
import { computed, inject } from 'vue'
import { iconType, getInformText } from '../utils'
import { EventBus } from '@/public/src/pages/common/event-bus.js'
import { CLICK_EVENT_FROM } from '../constants.js'

const {
  COUPON_LAYER_BUTTON_TYPE
} = require('@/controllers/common-coupon/constants.js')
//props
const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  },
  language: {
    type: Object,
    default: () => ({})
  }
})

let isCouponValid = inject('isCouponValid')

//解构数据
const promptInfo = computed(() => props?.data?.promptInfo || [])
const couponColorType = computed(() => props?.data?.couponColorType || '')
const buttonInfo = computed(() => props?.data?.buttonInfo)

//Checkbox
const isChecked = computed(() => {
  switch (buttonInfo.value) {
    case COUPON_LAYER_BUTTON_TYPE.availableAndUnselected:
    case COUPON_LAYER_BUTTON_TYPE.disabledAndUnselected:
      return false
    case COUPON_LAYER_BUTTON_TYPE.availableAndSelected:
    case COUPON_LAYER_BUTTON_TYPE.disabledAndSelected:
      return true
    default:
      return true
  }
})

// 按钮文案
const buttonText = computed(() => {
  switch (buttonInfo.value) {
    case COUPON_LAYER_BUTTON_TYPE.shop:
      return props.language?.SHEIN_KEY_PC_30603
    case COUPON_LAYER_BUTTON_TYPE.claim:
      return props.language?.SHEIN_KEY_PC_30604
    default:
      return ''
  }
})

// 券面点击事件
const handleButtonClick = ({ btnType, couponInfo }) => {
  EventBus.emit('button-click', {
    btnType,
    couponInfo,
    from: CLICK_EVENT_FROM.couponLayer
  })
}

const handleChange = ({ checked, couponInfo, btnType }) => {
  EventBus.emit('button-click', {
    checked,
    couponInfo,
    btnType,
    from: CLICK_EVENT_FROM.couponChecked
  })
}
</script>

<style lang="less" scoped>
/* stylelint-disable selector-max-specificity  */
@import './style/index.less';
.head-right {
  font-size: 12px;
  .flex-config(flex,null,flex-end);
  flex-direction: column;
  flex-grow: 1;
  .apply-btn {
    margin-bottom: 2px;
    max-width: 90px;
    height: 28px;
    padding: 0 10px;
    .ellipsis();
    .base-font(12px);
    min-width: 51px;
    border-radius: 3px;
    line-height: 0.28px;
    font-weight: 700;
  }
  .no-forbidden {
    color: #fff;
    border: 0.5px solid rgba(0, 0, 0, 0.6);
  }
  .no-forbidden-hover:hover {
    border-color: rgba(0, 0, 0, 0.3);
  }
  .forbidden {
    border: 0.5px solid rgba(0, 0, 0, 0.6);
  }

  .tag-content {
    color: var(--core-color);
    margin-top: 2px;
    width: 100%;
    .flex-config(flex,null,center,flex-end);
    .base-font(12px);
    font-weight: 700;
    .icon {
      margin-right: 2px;
      margin-top: -2px;
    }
    .informText {
      max-width: 90px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  /deep/ .sui-checkbox__label-select {
    border-radius: 50%;
    border: 1.5px solid #767676;
  }
  /deep/.sui-checkbox__checked {
    .sui-checkbox__label-select {
      border: none;
    }
  }
  /deep/.sui-checkbox__disabled {
    .sui-checkbox__label-select {
      border: 1.5px solid #e5e5e5;
      background: #f6f6f6;
    }
  }
  .checkbox-item {
    text-align: right;
    .coupon__checkbox {
      margin-right: 0;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      overflow: hidden;

      /deep/ .sui-checkbox__label-select {
        background: var(--bg-color);
        fill: var(--bg-color);
      }
    }
  }

  .checkbox-item .sui-checkbox__checked {
    /deep/ .sui-checkbox__label-select {
        background: #222;
        fill: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

    /deep/ .sui-checkbox__label-icon {
      height: 13px;
    }
  }

}
</style>
