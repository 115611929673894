<template>
  <div
    v-if="productInfo"
    class="goods-summary__container"
  >
    <!-- 标题 -->
    <h4
      class="goods-name__title"
      :class="{ 'no-link': quickViewConfig.hideViewFullDetail }"
      tabindex="0"
      @click="!quickViewConfig.hideViewFullDetail ? goDetail() : ''"
    >
      {{ productInfo.goods_name }}
    </h4>

    <div class="goods-name__summary">
      <span class="goods-sku">
        <template v-if="lang != 'ar'">SKU: {{ productInfo.goods_sn }}</template>
        <template v-else>{{ productInfo.goods_sn }} :SKU</template>
      </span>
    </div>

    <div
      v-if="allCommentInfoNum && allCommentInfoNum > 0"
      class="goods-reviews"
      tabindex="0"
      :aria-label="
        language.SHEIN_KEY_PC_14660 +
          ' ' +
          commentInfo.comment_rank_average +
          ' ' +
          allCommentCountStr +
          ' ' +
          language.SHEIN_KEY_PC_14678
      "
    >
      <CommonRate
        :size="'small'"
        :rank-num="commentInfo.comment_rank_average"
      />
      <span
        class="goods-reviews__text color-blue-text"
        da-event-click="1-8-6-3"
        :data-review-num="allCommentInfoNum"
      >
        ({{ allCommentCountStr }} {{ language.SHEIN_KEY_PC_14678 }})
      </span>
    </div>
    <!-- 价格 -->
    <div
      class="goods-price"
      :class="{ 'goods-price__no-bottom': isOneSize }"
    >
      <!-- 普通闪购 -->
      <QuickFlashSale 
        v-if="showPopFlashBanner&&normalFlashStyle&&!IS_RW"
        :is-percent-new-style="isPercentNewStyle"
        :is-sheinappwordsize-new="isSheinappwordsizeNew"
        :show-pop-discoun-table="showPopDiscounTable"
        :estimated-comp-props="estimatedCompProps"
        :flash-goods="flashGoods"
        :is-flash-sale-count-down-abt-hide="isFlashSaleCountDownAbtHide"
        :language="language"
        :flash-desc-text="flashDescText"
        :goods-id="productInfo.goods_id"
        :promotion-info="promotionInfo"
        :estimated-info="estimatedInfo"
        :sale-price="salePrice"
        :retail-price="retailPrice"
        :discount-percent="priceSource.unit_discount"
        :show-special-flash-del-price="true"
        :suggested-sale-price="suggestedSalePrice"
        :compliance-mode-de="complianceModeDe"
        :de-discount-info="deDiscountInfo"
        :estimated-tag-has-link="estimatedTaghasLink"
        :show-estimated-price="showEstimatedOrAbPrice"
        :is-small-style="isSmallStyle"
        :is-satisfied-old-style="isSatisfiedOldStyle"
        :has-retail-price="hasRetailPrice"
        :recommend-retail-price="recommendRetailPrice"
        :compliance-tips-mode="complianceTipsMode"
        @handle-click-estimated-tag="handleClickEstimatedTag"
      />
      <template v-else>
        <div
          v-if="!isPercentNewStyle"
          class="goods-price__container"
          :class="{'goods-price__container_bigSmall': isSheinappwordsizeNew}"
        >
          <template v-if="quickViewConfig.birthdayGift">
            <!-- 可领取生日礼 -->
            <template v-if="quickViewConfig.birthdayGift.hasAuthority4Gifts">
              <div class="vip-birth__quickview-comp">
                <em
                  :class="[GB_cssRight ? 'vip-birth-tag__ar' : 'vip-birth-tag']"
                ></em>
                {{ symbolLeft }}{{ pricePattern }}{{ symbolRight }}
                <span
                  class="goods-price__has-birth"
                  tabindex="0"
                >
                  {{ retailPrice }}
                </span>
              </div>
            </template>
            <!-- 不可领取生日礼 -->
            <template v-else>
              <div class="vip-birth__quickview-comp">
                <div
                  class="goods-price__nobirth"
                  tabindex="0"
                  v-html="mainPriceWordSizeFormater(salePrice)"
                ></div>
                <em
                  :class="[
                    'goods-price__nobirth-icon',
                    GB_cssRight ? 'vip-birth-tag__ar' : 'vip-birth-tag',
                  ]"
                ></em>
                <span
                  class="vip-birth__quickview-price goods-price__nobirth-price"
                >
                  {{ symbolLeft }}{{ pricePattern }}{{ symbolRight }}
                </span>
              </div>
            </template>
          </template>
          <!-- Special Flash -->
          <template v-if="isSpecialFlash">
            <div class="goods-summary__special-flash">
              <div class="goods-summary__flashTopLine">
                <div
                  class="goods-summary__specialFlashLeft"
                  :class="{
                    'goods-summary__specialFlashLeft__has-estiamted':
                      estimatedCompProps &&
                      estimatedCompProps.config,
                    'goods-summary__specialFlashLeft_bigSmall': isSheinappwordsizeNew
                  }"
                >
                  <p 
                    :style="{'line-height': isSheinappwordsizeNew ? '1' : '1.2'}"
                    v-html="mainPriceWordSizeFormater(newFlashPromotionPrice)"
                  ></p>
                  <template v-if="estimatedCompProps && estimatedCompProps.config">
                    <template v-if="complianceModeDe">
                      <div
                        v-if="deDiscountInfo.retailPrice.show"
                        class="goods-price__de-discount"
                      >
                        <span>{{ language.SHEIN_KEY_PC_31021 }}&ensp;</span>
                        <del class="goods-price__de-discountDel">{{ deDiscountInfo.retailPrice.value }}</del>
                        <p
                          v-if="deDiscountInfo.retailDiscountPercent.show"
                          :class="discountPercentClassMap"
                        >
                          {{ formatPercentText(deDiscountInfo.retailDiscountPercent.value) }}
                        </p>
                      </div>
                      <div
                        v-if="deDiscountInfo.suggestedSalePrice.show"
                        class="goods-price__de-discount"
                        :style="{
                          width: deDiscountInfo.retailPrice.show ? '100%' : '',
                          margin: deDiscountInfo.retailPrice.show ? '10px 0' : ''
                        }"
                      >
                        <span>{{ language.SHEIN_KEY_PC_31022 }}&ensp;</span>
                        <del class="goods-price__de-discountDel">{{ deDiscountInfo.suggestedSalePrice.value }}</del>
                        <p
                          v-if="deDiscountInfo.suggestedDiscountPercent.show"
                          :class="discountPercentClassMap"
                        >
                          {{ formatPercentText(deDiscountInfo.suggestedDiscountPercent.value) }}
                        </p>
                      </div>
                    </template>
                    <span v-else-if="suggestedSalePrice.show">
                      <del
                        class="goods-summary__specialFlashRetail"
                      >
                        {{ suggestedSalePrice.value }}
                      </del>
                      <span
                        v-if="!!suggestedSalePrice.tip"
                        class="goods-price__suggestedSalePrice-tip"
                      >
                        {{ suggestedSalePrice.tip }}
                      </span>
                      <del
                        v-else-if="retailPrice && hasRetailPrice && isSatisfiedOldStyle"
                        class="goods-price__origin"
                        :style="{
                          transform: isSheinappwordsizeNew ? 'translateY(-2px)' : '',
                          'line-height': isSheinappwordsizeNew ? '1.1' : ''
                        }"
                      >
                        {{ retailPrice }}
                      </del>
                    </span>
                    <div
                      v-if="!complianceMode"
                      class="goods-summary__specialFlashEstimated"
                      :style="{ 'padding-top': suggestedSalePrice.show ? '6px' : '' }"
                    >
                      <EstimatedTag
                        :analysis-data="{ location: 'popup' }"
                        :language="language"
                        :estimated-info="estimatedInfo"
                        :promotion-info="promotionInfo"
                        :goods-id="productInfo.goods_id"
                        :is-special-flash="isSpecialFlash"
                        :has-link="estimatedTaghasLink"
                        :is-show-count-down-mill-sec="isShowCountDownMillSec"
                        @click-link="handleClickEstimatedTag"
                      />
                    </div>
                  </template>
                  
                  <template v-else>
                    <template v-if="complianceModeDe">
                      <div
                        v-if="deDiscountInfo.retailPrice.show"
                        class="goods-price__de-discount"
                      >
                        <span>{{ language.SHEIN_KEY_PC_31021 }}&ensp;</span>
                        <del class="goods-price__de-discountDel">{{ deDiscountInfo.retailPrice.value }}</del>
                        <p
                          v-if="deDiscountInfo.retailDiscountPercent.show"
                          :class="discountPercentClassMap"
                        >
                          {{ formatPercentText(deDiscountInfo.retailDiscountPercent.value) }}
                        </p>
                      </div>
                      <div
                        v-if="deDiscountInfo.suggestedSalePrice.show"
                        class="goods-price__de-discount"
                        :style="{
                          width: deDiscountInfo.retailPrice.show ? '100%' : '',
                          margin: deDiscountInfo.retailPrice.show ? '10px 0' : ''
                        }"
                      >
                        <span>{{ language.SHEIN_KEY_PC_31022 }}&ensp;</span>
                        <del class="goods-price__de-discountDel">{{ deDiscountInfo.suggestedSalePrice.value }}</del>
                        <p
                          v-if="deDiscountInfo.suggestedDiscountPercent.show"
                          :class="discountPercentClassMap"
                        >
                          {{ formatPercentText(deDiscountInfo.suggestedDiscountPercent.value) }}
                        </p>
                      </div>
                    </template>
                    <template v-else-if="showSpecialFlashDiscount">
                      <del
                        v-if="suggestedSalePrice.show && !suggestedSalePrice.tip"
                        class="goods-summary__specialFlashRetail"
                      >
                        {{ suggestedSalePrice.value }}
                      </del>
                      <del
                        v-else-if="showSpecialFlashDelPrice"
                        class="goods-summary__specialFlashRetail"
                      >
                        {{ retailPrice }}
                      </del>
                      <div
                        v-if="newFlashPromotion.discount_percent"
                        class="goods-summary__specialFlashDiscountPercent"
                      >
                        {{ formatPercentText(newFlashPromotion.discount_percent) }}
                      </div>
                    </template>
                  </template>
                </div>
                <a
                  class="goods-summary__specialFlashRight"
                  :href="`${langPath}/flash-sale.html?ici=GoodsDetail_FlashSale&flash_type=${flashType}`"
                  target="_blank"
                >
                  {{
                    flashType === 2
                      ? language.SHEIN_KEY_PC_23050
                      : language.SHEIN_KEY_PC_23051
                  }}
                  <Icon
                    name="sui_icon_more_right_18px"
                    size="12"
                    class="icon"
                    :custom-style="{ verticalAlign: 'none'}"
                  />
                </a>
              </div>
              <span v-if="suggestedSalePrice.show && !!suggestedSalePrice.tip">
                <del class="goods-summary__specialFlashRetail">
                  {{ suggestedSalePrice.value }}
                </del>
                <span
                  class="goods-price__suggestedSalePrice-tip"
                >
                  {{ suggestedSalePrice.tip }}
                </span>
              </span>
              <del
                v-else-if="retailPrice && hasRetailPrice && isSatisfiedOldStyle"
                class="goods-price__origin"
                :style="{
                  transform: isSheinappwordsizeNew ? 'translateY(-2px)' : '',
                  'line-height': isSheinappwordsizeNew ? '1.1' : ''
                }"
              >
                {{ retailPrice }}
              </del>
              <div
                v-if="complianceMode && estimatedCompProps && estimatedCompProps.config"
                class="goods-summary__specialFlashEstimated"
                :style="{ 'padding-top': suggestedSalePrice.show ? '6px' : '' }"
              >
                <EstimatedTag
                  :analysis-data="{ location: 'popup' }"
                  :language="language"
                  :estimated-info="estimatedInfo"
                  :promotion-info="promotionInfo"
                  :goods-id="productInfo.goods_id"
                  :is-special-flash="isSpecialFlash"
                  :has-link="estimatedTaghasLink"
                  :is-show-count-down-mill-sec="isShowCountDownMillSec"
                  @click-link="handleClickEstimatedTag"
                />
              </div>
            </div>
          </template>

          <div class="goods-price__normal-container" v-else>
            <Icon
              v-if="normalFlashStyle"
              name="sui_icon_flashsale_16px"
              size="16"
              class="flasesale-icon"
              :custom-style="{ verticalAlign: 'none'}"
            />
            <span
              class="goods-price__sale"
              tabindex="0"
              :style="mainPriceStyle"
              v-html="mainPriceWordSizeFormater(salePrice)"
            ></span>
            <img 
              v-if="s3ClubPriceInfo"
              class="goods-price__s3-tag"
              src="https://img.ltwebstatic.com/images3_ccc/2024/09/06/2d/17256037293379c1b812e0bbcbed140c7dd8a0d490.png"
            />
            <template v-if="estimatedCompProps && !showEstimatedNoThresholdNewType">
              <template v-if="complianceModeDe">
                <div
                  v-if="deDiscountInfo.retailPrice.show"
                  class="goods-price__de-discount"
                >
                  <span>{{ language.SHEIN_KEY_PC_31021 }}&ensp;</span>
                  <del class="goods-price__de-discountDel">{{ deDiscountInfo.retailPrice.value }}</del>
                  <p
                    v-if="deDiscountInfo.retailDiscountPercent.show"
                    :class="discountPercentClassMap"
                  >
                    {{ formatPercentText(deDiscountInfo.retailDiscountPercent.value) }}
                  </p>
                </div>
                <div
                  v-if="deDiscountInfo.suggestedSalePrice.show"
                  class="goods-price__de-discount"
                  :style="{
                    width: deDiscountInfo.retailPrice.show ? '100%' : '',
                    margin: deDiscountInfo.retailPrice.show ? '10px 0' : ''
                  }"
                >
                  <span>{{ language.SHEIN_KEY_PC_31022 }}&ensp;</span>
                  <del class="goods-price__de-discountDel">{{ deDiscountInfo.suggestedSalePrice.value }}</del>
                  <p
                    v-if="deDiscountInfo.suggestedDiscountPercent.show"
                    :class="discountPercentClassMap"
                  >
                    {{ formatPercentText(deDiscountInfo.suggestedDiscountPercent.value) }}
                  </p>
                </div>
              </template>
              <span v-else>
                <span
                  v-if="hasDiscount && complianceTipsMode"
                  :class="discountPercentClassMap"
                  :style="{
                    transform: isSheinappwordsizeNew ? 'translateY(-2px)' : '',
                  }"
                >
                  {{ formatPercentText(discountPercent) }}
                </span>
                <del
                  v-if="suggestedSalePrice.show"
                  class="goods-price__origin"
                  :style="{
                    transform: isSheinappwordsizeNew ? 'translateY(-2px)' : '',
                    'line-height': isSheinappwordsizeNew ? '1.1' : ''
                  }"
                >
                  {{ suggestedSalePrice.value }}
                </del>
                <span
                  v-if="!!suggestedSalePrice.tip"
                  class="goods-price__suggestedSalePrice-tip"
                >
                  {{ suggestedSalePrice.tip }}
                </span>
                <del
                  v-else-if="retailPrice && hasRetailPrice &&isSatisfiedOldStyle"
                  class="goods-price__origin"
                  :style="{
                    transform: isSheinappwordsizeNew ? 'translateY(-2px)' : '',
                    'line-height': isSheinappwordsizeNew ? '1.1' : ''
                  }"
                >
                  {{ retailPrice }}
                </del>
              </span>
              <template v-if="recommendRetailPrice">
                <RecommendRetailPrice
                  :recommend-retail-price="recommendRetailPrice"
                  :is-quick-add="true"
                />
                <div style="width: 4px;"></div>
              </template>
              <EstimatedTag
                class="goods-price__estimated"
                :class="{'goods-price__estimated_bigSmall': isSheinappwordsizeNew}"
                :analysis-data="{ location: 'popup' }"
                :language="language"
                :estimated-info="estimatedInfo"
                :promotion-info="promotionInfo"
                :goods-id="productInfo.goods_id"
                :is-special-flash="isSpecialFlash"
                :has-link="estimatedTaghasLink"
                :is-show-count-down-mill-sec="isShowCountDownMillSec"
                @click-link="handleClickEstimatedTag"
              />
            </template>
            <template
              v-else-if="
                (!estimatedCompProps || (estimatedCompProps && showEstimatedNoThresholdNewType)) &&
                  (sheinClubPromotionInfo && isPaidUser) &&
                  !complianceMode
              "
            >
              <template v-if="complianceModeDe">
                <div
                  v-if="deDiscountInfo.retailPrice.show"
                  class="goods-price__de-discount"
                >
                  <span>{{ language.SHEIN_KEY_PC_31021 }}&ensp;</span>
                  <del class="goods-price__de-discountDel">{{ deDiscountInfo.retailPrice.value }}</del>
                  <p
                    v-if="deDiscountInfo.retailDiscountPercent.show"
                    :class="discountPercentClassMap"
                  >
                    {{ formatPercentText(deDiscountInfo.retailDiscountPercent.value) }}
                  </p>
                </div>
                <div
                  v-if="deDiscountInfo.suggestedSalePrice.show"
                  class="goods-price__de-discount"
                  :style="{
                    width: deDiscountInfo.retailPrice.show ? '100%' : '',
                    margin: deDiscountInfo.retailPrice.show ? '10px 0' : ''
                  }"
                >
                  <span>{{ language.SHEIN_KEY_PC_31022 }}&ensp;</span>
                  <del class="goods-price__de-discountDel">{{ deDiscountInfo.suggestedSalePrice.value }}</del>
                  <p
                    v-if="deDiscountInfo.suggestedDiscountPercent.show"
                    :class="discountPercentClassMap"
                  >
                    {{ formatPercentText(deDiscountInfo.suggestedDiscountPercent.value) }}
                  </p>
                </div>
              </template>
              <template v-else>
                <span v-if="suggestedSalePrice.show">
                  <del
                    class="goods-price__origin"
                    :style="{
                      transform: isSheinappwordsizeNew ? 'translateY(-2px)' : '',
                      'line-height': isSheinappwordsizeNew ? '1.1' : ''
                    }"
                  >
                    {{ suggestedSalePrice.value }}
                  </del>
                  <span
                    v-if="!!suggestedSalePrice.tip"
                    class="goods-price__suggestedSalePrice-tip"
                  >
                    {{ suggestedSalePrice.tip }}
                  </span>
                </span>
                <del
                  v-else
                  class="goods-price__origin"
                  :style="{
                    transform: isSheinappwordsizeNew ? 'translateY(-2px)' : '',
                    'line-height': isSheinappwordsizeNew ? '1.1' : ''
                  }"
                >
                  {{ retailPrice }}
                </del>
              </template>
              <template v-if="recommendRetailPrice">
                <RecommendRetailPrice
                  :recommend-retail-price="recommendRetailPrice" 
                  :is-quick-add="true"
                />
                <div style="width: 4px;"></div>
              </template>
            </template>
            <template v-else>
              <template v-if="complianceModeDe">
                <div
                  v-if="deDiscountInfo.retailPrice.show"
                  class="goods-price__de-discount"
                >
                  <span>{{ language.SHEIN_KEY_PC_31021 }}&ensp;</span>
                  <del class="goods-price__de-discountDel">{{ deDiscountInfo.retailPrice.value }}</del>
                  <p
                    v-if="deDiscountInfo.retailDiscountPercent.show"
                    :class="discountPercentClassMap"
                  >
                    {{ formatPercentText(deDiscountInfo.retailDiscountPercent.value) }}
                  </p>
                </div>
                <div
                  v-if="deDiscountInfo.suggestedSalePrice.show"
                  class="goods-price__de-discount"
                  :style="{
                    width: deDiscountInfo.retailPrice.show ? '100%' : '',
                    margin: deDiscountInfo.retailPrice.show ? '10px 0' : ''
                  }"
                >
                  <span>{{ language.SHEIN_KEY_PC_31022 }}&ensp;</span>
                  <del class="goods-price__de-discountDel">{{ deDiscountInfo.suggestedSalePrice.value }}</del>
                  <p
                    v-if="deDiscountInfo.suggestedDiscountPercent.show"
                    :class="discountPercentClassMap"
                  >
                    {{ formatPercentText(deDiscountInfo.suggestedDiscountPercent.value) }}
                  </p>
                </div>
              </template>
              <template v-else-if="showDiscount">
                <del
                  v-if="suggestedSalePrice.show && !suggestedSalePrice.tip"
                  class="goods-price__origin"
                  :style="{
                    'line-height': isSheinappwordsizeNew ? '1.1' : ''
                  }"
                >
                  {{ suggestedSalePrice.value }}
                </del>
                <del
                  v-if="retailPrice"
                  class="goods-price__origin"
                  :style="{
                    'line-height': isSheinappwordsizeNew ? '1.1' : ''
                  }"
                >
                  {{ retailPrice }}
                </del>
                <span
                  v-if="hasDiscount"
                  :class="discountPercentClassMap"
                  :style="{
                    transform: isSheinappwordsizeNew ? 'translateY(-2px)' : '',
                  }"
                >
                  {{ formatPercentText(discountPercent) }}
                </span>
                <span>
                  <del
                    v-if="suggestedSalePrice.show && !!suggestedSalePrice.tip"
                    class="goods-price__origin"
                    :style="{
                      'line-height': isSheinappwordsizeNew ? '1.1' : ''
                    }"
                  >
                    {{ suggestedSalePrice.value }}
                  </del>
                  <span
                    v-if="!!suggestedSalePrice.tip"
                    class="goods-price__suggestedSalePrice-tip"
                  >
                    {{ suggestedSalePrice.tip }}
                  </span>
                </span>
              </template>
              <template v-if="recommendRetailPrice">
                <RecommendRetailPrice
                  :recommend-retail-price="recommendRetailPrice" 
                  :is-quick-add="true"
                />
              </template>
            </template>
          </div>

          <!-- 单品直降活动倒计时（非到手价，非闪购） -->
          <template v-if="isShowPromotionCountDown">
            <PromotionCountDown
              style="height: 28.8px; align-self: flex-start;"
              :end-time="promotionEndTime"
              :language="language"
              :is-show-count-down-mill-sec="isShowCountDownMillSec"
            />
          </template>
        </div>
        <template v-if="isPercentNewStyle">
          <template v-if="isSpecialFlash">
            <div class="goods-summary__specialFlashNewpercent">
              <a
                class="goods-summary__specialFlashTop"
                :href="`${langPath}/flash-sale.html?ici=GoodsDetail_FlashSale&flash_type=${flashType}`"
                target="_blank"
              >
                {{
                  flashType === 2
                    ? language.SHEIN_KEY_PC_23050
                    : language.SHEIN_KEY_PC_23051
                }}
                <Icon
                  name="sui_icon_more_right_18px"
                  size="12"
                  class="icon"
                  :custom-style="{ verticalAlign: 'none'}"
                />
              </a>
              <EstimatedTag
                :analysis-data="{ location: 'popup' }"
                :language="language"
                :estimated-info="estimatedInfo"
                :promotion-info="promotionInfo"
                :goods-id="productInfo.goods_id"
                :is-special-flash="isSpecialFlash"
                :has-link="estimatedTaghasLink"
                :is-show-count-down-mill-sec="isShowCountDownMillSec"
                @click-link="handleClickEstimatedTag"
              />
            </div>
          </template>
          <EstimatedTag
            v-else
            :analysis-data="{ location: 'popup' }"
            :language="language"
            :estimated-info="estimatedInfo"
            :promotion-info="promotionInfo"
            :goods-id="productInfo.goods_id"
            :has-link="estimatedTaghasLink"
            :is-show-count-down-mill-sec="isShowCountDownMillSec"
            @click-link="handleClickEstimatedTag"
          />
        </template>
        <div
          v-if="showBestDealTips"
          class="best-deal-tips__wrap"
        >
          <BestDealTips
            :text="bestDealConfig.text"
            :analysis="bestDealTipsAnalysis"
          />
        </div>
        <div
          v-if="!IS_RW && exclusivePromotionPrice && exclusivePromotionPrice.amountWithSymbol && !s3ClubPriceInfo"
          class="vip-exclusive__quickView goods-price__vip"
        >
          {{ exclusivePromotionPrice.amountWithSymbol }}
          <div class="vip-exclusive-txt__quickView">
            <span>{{ language.SHEIN_KEY_PC_17426 }}</span>
          </div>
          <Icon
            name="sui_icon_doubt_16px_1"
            size="20"
            @click="isShowExplainationModal(true)"
          />
        </div>
        <RomweVipPrice
          v-if="IS_RW && exclusivePromotionPrice && exclusivePromotionPrice.amountWithSymbol && !s3ClubPriceInfo"
          class="goods-rw-vip"
          :price="exclusivePromotionPrice.amountWithSymbol"
        />
        <!-- app专属折扣 -->
        <AppExclusiveDiscount
          v-if="appExclusiveData"
          :data="appExclusiveData"
          :thumb-img="productInfo.goods_thumb"
          :goods-name="productInfo.goods_url_name"
          :goods-id="productInfo.goods_id"
          :sale-price="salePrice"
          :web-client="WEB_CLIENT"
          :retail-price="hasDiscount ? retailPrice : ''"
          :promotion-label="hasDiscount ? discount : ''"
        />
        <PaidVipEstimated
          v-if="sheinClubPromotionInfo || showVipEstimatedTagWithShipping"
          class="goods-paidvip-estimated"
          :text="vipEstimatedText"
          :vip-estimated-class="vipEstimatedClass"
          :is-estimated-anti-content="isEstimatedAntiContent"
          :detail-shein-club-content="detailSheinClubContent"
          :data="sheinClubPromotionInfo"
          :paid-vip-data="paidVipData"
          :price-detect="priceDetect"
          :show-shein-club="showSheinClub"
          :is-paid-user="isPaidUser"
          :is-free-receive-user="isFreeReceiveUser"
          :show-club-type="showClubType"
          :language="language"
          :cdn-url="PUBLIC_CDN"
          :css-right="GB_cssRight"
          :goods-id="productInfo.goods_id"
          :is-needs-to-renew="isNeedsToRenew"
          :is-shipping-type="showVipEstimatedTagWithShipping"
          source="quickview"
        />
      </template>
      <EstimatedTag
        v-if="showEstimatedNoThresholdNewType"
        class="goods-estimatedNoThresholdNewType"
        :analysis-data="{ location: 'popup' }"
        :language="language"
        :estimated-info="estimatedInfo"
        :promotion-info="promotionInfo"
        :goods-id="productInfo.goods_id"
        :is-estimated-no-threshold-new-type="true"
        :is-show-add="showEstimatedNoThresholdNewAdd"
        :has-link="true"
        :is-show-count-down-mill-sec="isShowCountDownMillSec"
        @click-link="handleClickNewNoThresholdEstimatedTag"
      />
    </div>

    <AddOnDialog
      v-model:visible="addMoreDialogVisible"
      :query-params="AddOnDialogData.preParams"
      :sa-info="AddOnDialogData.saInfo"
      :config="{
        isClickToDetail: true
      }"
      :coupon="AddOnDialogData.optimalCouponCode"
      type="coupon"
    >
      <!-- <template #title>
        <span>{{ template(discountPercent, thresholdValue, couponLanguage.SHEIN_KEY_PC_26474) }}</span>
      </template> -->
    </AddOnDialog>
    <s-dialog
      v-model:visible="isProductsModalShow"
      :type="'W996'"
      :show-close="true"
      :close-on-click-modal="true"
      :immediately-render="true"
      :append-to-body="true"
      class="products-modal"
    >
      <template #title>
        <div>{{ couponLanguage.SHEIN_KEY_PC_16480 }}</div>
      </template>
      <block-status 
        v-if="isProFetching" 
        :language="couponLanguage"  
        type="loading" 
      />
      <block-status
        v-else-if="isProFetchError"
        :language="couponLanguage"
        type="error"
        @refresh="getPromProducts"
      />
      <block-status
        v-else-if="promotion.items.length === 0"
        :language="couponLanguage"
        type="empty"
      />
      <div v-else>
        <p class="sub-title">
          {{ promotion.items.length }} {{ couponLanguage.SHEIN_KEY_PC_14931 }}
        </p>
        <div
          v-if="promotion.coupon_type_id === '2' && advShow"
          class="advert-notice"
        >
          <Icon 
            name="sui_icon_notice_18px"
            size="12"
            :is-rotate="GB_cssRight"
          />
          <div class="value">
            <p v-html="noticeText"></p>
          </div>
          <Icon
            name="sui_icon_closed_14px_1"
            size="12"
            :custom-style="{ verticalAlign: 'none'}"
            @click="advShow = false"
          />
        </div>
        <ul class="product-list">
          <production
            v-for="(item, i) in promotion.items"
            :key="item.id"
            :item-data="item"
            :language="couponLanguage"
            :show-quick-ship-tag="false"
            @click="popQuickView(item, i)"
          />
        </ul>
      </div>
    </s-dialog>
  </div>
</template>

<script>
const { lang, langPath, currencies, currency, GB_cssRight, IS_RW, SiteUID, PUBLIC_CDN } =
  gbCommonInfo
import AppExclusiveDiscount from '../../../components/AppExclusiveDiscount'
import RomweVipPrice from 'public/src/pages/components/productItem/components/RomweVipPrice.vue'
import CommonRate from '../../../goods_detail_v2/components/CommonRate'
import BestDealTips from 'public/src/pages/goods_detail_v2/components/ProductIntroPriceTips/BestDealTips.vue'
import { daEventCenter } from 'public/src/services/eventCenter'
import EstimatedTag from 'public/src/pages/goods_detail_v2/components/EstimatedTag/index.vue'
import QuickFlashSale from '../components/QuickFlashSale.vue'
import { template } from '@shein/common-function'
import PaidVipEstimated from 'public/src/pages/components/PaidVipEstimated'
import { souceFormat } from 'public/src/pages/components/coupon/mall/utils.js'
import schttp from 'public/src/services/schttp'
import BlockStatus from 'public/src/pages/user_coupon/components/blockStatus.vue'
import Production from 'public/src/pages/user_coupon/components/production.vue'
import { defineAsyncComponent } from 'vue'
import salePriceWordSize from 'public/src/pages/goods_detail_v2/utils/salePriceWordSize'
import { getGoodsUrl, isLogin } from 'public/src/pages/common/utils/index.js'
import { Icon } from '@shein-aidc/icon-vue3'
import { parsePercentText } from 'public/src/pages/goods_detail_v2/utils/ar.js'
import RecommendRetailPrice from 'public/src/pages/goods_detail_v2/components/ProductIntroHeadPrice/UI/RecommendRetailPrice.vue'
import PromotionCountDown from 'public/src/pages/goods_detail_v2/components/ProductIntroHeadPrice/UI/PromotionCountDown.vue'

export default {
  name: 'GoodsNameSummary',
  components: {
    Icon,
    RomweVipPrice,
    AppExclusiveDiscount,
    CommonRate,
    EstimatedTag,
    QuickFlashSale,
    PaidVipEstimated,
    BestDealTips,
    BlockStatus,
    Production,
    AddOnDialog: defineAsyncComponent(() => import('public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog.vue')),
    RecommendRetailPrice,
    PromotionCountDown,
  },
  inject: ['changeRootStatus'],
  props: {
    goodsData: {
      type: Object,
      default() {
        return {}
      },
    },
    promotionInfo: {
      type: Array,
      default: () => ([])
    },
    exclusivePromotionPrice: {
      type: Object,
      default() {
        return {}
      },
    },
    newFlashPromotion: {
      type: Object,
      default() {
        return {}
      },
    },
    pageListType: {
      type: String,
    },
    mainCouponcode: {
      type: String,
      default: '',
    },
    isSoldOut: {
      type: Boolean,
      default() {
        return false
      },
    },
    quickViewConfig: {
      type: Object,
      default() {
        return {}
      },
    },
    // 加车form
    addBagForm: {
      type: Object,
      default() {
        return {
          sizeInfo: null,
        }
      },
    },
    saleAttrList: {
      type: Object,
      default() {
        return {}
      },
    },
    analysis: {
      type: Object,
      default() {
        return {}
      },
    },
    lowestPriceTipsConfig: {
      type: Object,
      default() {
        return {}
      },
    },
    cccFlashZoneData: {
      type: Object,
      default() {
        return {}
      },
    },
    retentionPositionConfig: {
      type: Object,
      default: () => {},
    },
    isShowFollowBelt: {
      type: Boolean,
    },
    fixedRatio: {
      type: String,
      default: '3-4',
    },
    bestDealConfig: {
      type: Object,
      default: () => ({}),
    },
    estimatedInfo: {
      type: Object,
      default: () => null
    },
    realTimeReady: {
      type: Boolean,
      default: false
    },
    showSheinClub: {
      type: Boolean,
      default: false
    },
    complianceMode: {
      type: Boolean,
      default: false
    },
    complianceTipsMode: {
      type: Boolean,
      default: false
    },
    complianceModeDe: {
      type: Boolean,
      default: false
    },
    isInversion: {
      type: Boolean,
      default: false
    },
    isInversionDe: {
      type: Boolean,
      default: false
    },
    deDiscountInfo: {
      type: Object,
      default: () => null
    },
    isHitComplianceMode: {
      type: Boolean,
      default: false
    },
    s3ClubPriceInfo: {
      type: Object,
      default: () => null
    },
    pspcPriceInfo: {
      type: Object,
      default: () => null
    },
    isShowPromotionCountDown: {
      type: Boolean,
      default: false
    },
    promotionEndTime: {
      type: String,
      default: ''
    },
    isShowCountDownMillSec: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      langPath,
      lang,
      IS_RW,
      SiteUID,
      WEB_CLIENT,
      GB_cssRight,
      PUBLIC_CDN,
      symbolLeft: currencies[currency]?.symbol_left, // 币种
      symbolRight: currencies[currency]?.symbol_right, // 币种
      decPoint: currencies[currency]?.dec_point, // 小数符号
      decimalPlace: currencies[currency]?.decimal_place,

      // 凑单弹窗相关
      IS_USE_NEW_ADD_MORE: false,
      addMoreDialogVisible: false,
      isProductsModalShow: false,
      selectedCoupon: null,
      isProFetching: false,
      promotion: { items: [] },
      isProFetchError: false,
      advShow: true,
      couponLanguage: {},
      isUseNewAddMore: false,
    }
  },
  computed: {
    mallCode() {
      return this.goodsData.mallCode
    },
    priceSource() {
      let result = this.addBagForm?.sizeInfo?.mall?.[this.mallCode] || this.productInfoPrice

      // 手动屏蔽到手价ab价
      let iscloseEstimatedAndAbPriceWithPromo32 = this.promotionInfo?.find?.(item => item.typeId == 32) && this.goodsData?.closeEstimatedAndAbPrice
      return {
        ...result,
        ...( iscloseEstimatedAndAbPriceWithPromo32 ? { salePrice: result.retailPrice, unit_discount: 0 } : {})
      }
    },
    productInfo() {
      return this.goodsData.detail || {}
    },
    currentGoodsId() {
      return this.productInfo?.goods_id || ''
    },
    screenAbtConfig() {
      return this.goodsData?.screenAbtConfig || {}
    },
    productInfoPrice() {
      return this.productInfo?.mall?.[this.mallCode] || {}
    },
    commentInfo() {
      return this.goodsData.commentInfo || {}
    },
    allCommentInfoNum() {
      return Number(this.commentInfo.comment_num) || 0
    },
    showClubType(){
      // 推全poskey：clubtype=new状态
      // return this.screenAbtConfig?.clubtype?.p?.clubtype === 'new' || false
      return true
    },
    showPopDiscounTable() {
      return true // popdiscountable推全
      // return this.screenAbtConfig?.popdiscountable?.p?.popdiscountable === 'show'
    },
    isBrandFlash() {
      return this.flashType == 6
    },
    showPopFlashBanner() {
      return true // popdiscountable推全
      // if (this.isBrandFlash) return true
      // return this.screenAbtConfig?.popdiscountable?.p?.popflashbanner === 'show'
    },
    isFlashSaleCountDownAbtHide() {
      return this.screenAbtConfig?.FlashSaleCountDown?.p?.FlashSaleCountDown === 'Hide'
    },
    flashDescText(){
      let  title = this.language.SHEIN_KEY_PC_15950
      if (this.flashType == 1) {
        title = this.language.SHEIN_KEY_PC_15950 // 常规闪购
      } else if ([4, 5].includes(Number(this.flashType))) {
        const { titleKey } = this.cccFlashZoneData || {} 
        title = this.language[titleKey] || this.language?.SHEIN_KEY_PC_27483 || this.language?.SHEIN_KEY_PC_15950 // 专区兜底文案
      } else if (this.isBrandFlash) {
        return this.language.SHEIN_KEY_PC_29165
      }
      return title
    },
    isShowDiscountPriceStyle() {
      const isRetention = this.retentionPositionConfig?.show // 保留款
      const { isShowFollowBelt } = this // 跟价款
      return isRetention || isShowFollowBelt
    },
    // 展示的评论数
    allCommentCountStr() {
      const { comment_num, comment_num_str, comment_fuzzy } =
        this.commentInfo || {}
      if (comment_fuzzy) {
        return comment_num_str
      }
      return comment_num >= 10000 ? '9999+' : `${comment_num}`
    },
    /**
     * 判断是否是闪购商品
     */
    isFlashGoods() {
      return (
        this.promotionInfo &&
        this.promotionInfo.some(item => Number(item.typeId) === 10) &&
        !this.quickViewConfig.hidePromotionInfo
      )
    },
    flashGoods() {
      return this.promotionInfo &&
        this.promotionInfo?.find?.(item => item.typeId == 10)
    },
    zoneFlash () {
      const { flashType, newFlashPromotion } = this
      return [4].includes(flashType) && newFlashPromotion?.price?.amountWithSymbol
    },
    normalFlashStyle () {
      // 走常规闪购样式
      const { isFlashGoods, flashType, zoneFlash } = this
      const isNormalType = [1, 5, 6].includes(flashType) || zoneFlash
      return isFlashGoods && isNormalType
    },
    flashType() {
      if (this.isInversion || this.isInversionDe) return null
      const flashGoods = this.promotionInfo && this.promotionInfo.find((item) => Number(item.typeId) === 10)
      return flashGoods?.flashType
    },
    isSpecialFlash() {
      return (
        [2, 3].includes(this.flashType) &&
        this.newFlashPromotion?.price?.amountWithSymbol
      )
    },
    newFlashPromotionPrice() {
      return this.newFlashPromotion?.price?.amountWithSymbol
    },
    showEstimatedOrAbPrice(){
      return this.quickViewConfig.showEstimatedPrice || this.estimatedInfo?.isAb
    },
    showSpecialFlashDiscount () {
      let result = true
      let { showEstimatedOrAbPrice, estimatedCompProps, showEstimatedNoThresholdNewType } = this
      if (showEstimatedOrAbPrice && estimatedCompProps && estimatedCompProps.config) {
        result = showEstimatedNoThresholdNewType ? true : false
      }
      return result
    },
    showSpecialFlashDelPrice() {
      return (
        this.newFlashPromotion?.price?.amount !=
        this.priceSource.retailPrice.amount
      )
    },
    showOriginPrice() {
      if (this.complianceMode) {
        return Number(this.suggestedSalePrice?.suggestedSalePrice?.amount) > Number(this.priceSource?.salePrice?.amount)
      }
      return Number(this.priceSource?.retailPrice?.amount) > Number(this.priceSource?.salePrice?.amount)
    },
    /**
     * 生日礼 0元 价格 格式化
     */
    pricePattern() {
      return Number(0).toFixed(this.decimalPlace).replace('.', this.decPoint)
    },
    language() {
      return this.$root.language || {}
    },
    appExclusiveData() {
      const appPromotion = this.productInfoPrice?.appPromotion || []
      return appPromotion.find(item => item.typeId == 8)
    },
    salePrice() {
      if (this.s3ClubPriceInfo) return this.s3ClubPriceInfo?.show_price?.amount_text
      if (this.isPaidUser && this.sheinClubPromotionInfo) return this.sheinClubPromotionInfo.price?.amountWithSymbol
      if (this.zoneFlash) return this.newFlashPromotion?.price?.amountWithSymbol
      return this.priceSource?.salePrice?.amountWithSymbol
    },
    retailPrice() {
      if (this.complianceMode) return ''
      return this.priceSource?.retailPrice?.amountWithSymbol
    },
    // 是否有零售价
    hasRetailPrice() {
      if (this.complianceMode || this.complianceModeDe) return false
      const { flashType, newFlashPromotion } = this
      return (
        this.salePrice !== this.retailPrice
        || (flashType == 4 && newFlashPromotion?.price?.amountWithSymbol)
      )
    },
    hasDiscount() {
      if (this.isPaidUser && this.sheinClubPromotionInfo) return this.priceSource.discountValue > 0
      return this.priceSource.unit_discount > 0
    },
    showDiscount() {
      if(this.isSatisfiedOldStyle){
        return true
      }
      if (this.isInversion || this.isInversionDe) return false
      let { quickViewConfig, hasDiscount, showOriginPrice, estimatedCompProps, showEstimatedNoThresholdNewType } = this

      if (quickViewConfig.hidePromotionInfo) {
        return false
      }
      if (!hasDiscount && !showOriginPrice) return false
      if (estimatedCompProps && estimatedCompProps.config) {
        return showEstimatedNoThresholdNewType ? true : false
      }
      return true
    },
    discountPercent() {
      if (this.s3ClubPriceInfo) return this.s3ClubPriceInfo?.retail_discount_value
      if (this.isPaidUser && this.sheinClubPromotionInfo) return this.priceSource.discountValue || this.priceSource.unit_discount
      return this.priceSource.unit_discount
    },
    discount() {
      const lang = this.lang
      const langOne =
        lang == 'en' ||
        lang == 'us' ||
        lang == 'in' ||
        lang == 'tw' ||
        lang == 'au' ||
        lang == 'uk' ||
        GB_cssRight

      return langOne
        ? GB_cssRight
          ? `%${this.priceSource.unit_discount}${this.language.SHEIN_KEY_PC_15796}`
          : `${this.priceSource.unit_discount}% OFF`
        : `-${this.priceSource.unit_discount}%`
    },
    isNewDiscountLabel() {
      if (this.IS_RW || this.isFlashGoods) return false
      return (
        this.screenAbtConfig?.discountLabel?.param?.discountLabel == 'Label'
      )
    },
    paidVipData() {
      return this.goodsData.promotionData?.find(_ => _.typeId == 29)
    },
    isOneSize() {
      const { sale_attr_list = {} } = this.goodsData.attrSizeList || {}
      const { skc_sale_attr = [] } = sale_attr_list?.[this.currentGoodsId] || {}
      return !skc_sale_attr.length
    },
    isPaidUser() {
      return this.goodsData?.isPaidUser
    },
    isFreeReceiveUser (){
      return this.goodsData?.isFreeReceiveUser
    },
    sheinClubPromotionInfo() {
      const {
        promotionInfo,
        productInfoPrice,
        quickViewConfig,
        addBagForm,
        mallCode,
      } = this
      const { sizeInfo = {} } = addBagForm
      const isSheinClub = promotionInfo?.find(({ typeId }) => +typeId === 29)
      if (
        !!!isSheinClub ||
        quickViewConfig.isExchangeButton ||
        quickViewConfig.hidePromotionInfo
      )
        return null
      const { showPrice: skuShowPrice, showTypeId: skuShowTypeId } = sizeInfo?.mall?.[mallCode] || {}
      const isSkuMallDetail = !!(skuShowPrice && skuShowTypeId === 29)
      const {
        showPrice = {},
        vipDiscountPrice = {},
        discountValue = 0,
        showTypeId,
      } = isSkuMallDetail
        ? sizeInfo?.mall?.[mallCode]
        : productInfoPrice
      if (showTypeId !== 29) return null
      return {
        price: showPrice,
        discountValue: ((1 - discountValue) * 100).toFixed(0),
        discount_amount: vipDiscountPrice,
        sku_code: sizeInfo?.sku_code || null,
      }
    },
    showVipEstimatedTagWithShipping() {
      let { isPaidUser, sheinClubPromotionInfo, sheinClubShippingSiteConfig } = this
      let { clubfreeship, sheinclubprice } = this.screenAbtConfig || {}
      if (isPaidUser) return false
      if (sheinClubPromotionInfo) return false
      if (clubfreeship?.p?.clubfreeship !== 'show') return false
      if (!sheinClubShippingSiteConfig.FREE_SHIPPING_TIMES) return false
      if (sheinclubprice?.p !== 'exposure_prime_price') return false
      if (!this.goodsData.sheinClubContent?.goodsDetail?.shipping_pricebar?.fieldContent) return false
      return true
    },
    priceDetect() {
      const { saleAttrList } = this
      // skc下的多价格检测
      let sizePrice = -1
      for (let i = 0; i < saleAttrList.sku_list?.length; i++) {
        if (sizePrice === -1) {
          sizePrice = saleAttrList.sku_list?.[i]?.price?.salePrice?.amount
          continue
        }
        if (
          sizePrice !== saleAttrList.sku_list?.[i]?.price?.salePrice?.amount
        ) {
          return true
        }
      }
      return false
    },

    isEstimatedMatch() {
      let { EstimatedPrice } = this.screenAbtConfig || {}
      let E_Price_Cal = EstimatedPrice?.p?.E_Price_Cal
      return ['A', 'B', 'C', 'D', 'Non_Threshold', 'Have_Threshold'].includes(
        E_Price_Cal
      )
    },
    estimatedTaghasLink() {
      return this.quickViewConfig?.callback?.clickEstimated
    },
    estimatedCompProps() {
      return this.estimatedInfo?.estimatedCompProps || null
    },
    vipEstimatedClass() {
      let { SHEINclubshowtype } = this.goodsData?.screenAbtConfig
      return SHEINclubshowtype?.p?.SHEINclubshowtype
    },
    // 是否使用付费会员接口返回的 estimated_anti文案， 到手价(不露出专属折扣金额)场景
    isEstimatedAntiContent() {
      let estimatedPrice = this.estimatedInfo?.value?.amount || ''
      // 付费会员到手价
      let clubEstimatedPrice = this.estimatedInfo?.sheinClubAfterCouponsInfo?.after_coupon_price?.amount || ''
      // 付费会员价
      let clubPrice = this.sheinClubPromotionInfo?.price?.amount || ''
      // 
      /**
     * 1. 未开通付费会员，有原价到手价、付费会员到手价，且原价到手价<付费会员到手价
     * 2. 未开通付费会员，有原价到手价，付费会员没有到手价，且原价到手价<付费会员原价
     * 满足以上两者条件之一，则取sheinClubContent中estimated_anti字段文案
     * */
      return !this.isPaidUser && (!!(estimatedPrice && clubEstimatedPrice && estimatedPrice < clubEstimatedPrice) || !!(estimatedPrice && !clubEstimatedPrice && estimatedPrice < clubPrice))
    },
    // 付费会员是否需要续费  
    isNeedsToRenew() {
      const sheinClubContent = this.goodsData.sheinClubContent
      return sheinClubContent?.goodsDetail?.['jump_to']?.fieldContent == 1
    },
    detailSheinClubContent() {
      const sheinClubContent = this.goodsData.sheinClubContent
      const isEstimatedAntiContent = this.isEstimatedAntiContent
      const contentKey = isEstimatedAntiContent ? 'estimated_anti' : 'estimated_normal'
      let result = sheinClubContent?.goodsDetail?.[contentKey]?.fieldContent || ''
      if (result.includes('</&') && result.includes('&/>')) {
        result = result.replaceAll('</&', '<span style="color: #C44A01;font-weight: 700;">')
        result = result.replaceAll('&/>', '</span>')
      }
      return result
    },
    vipEstimatedText() {
      const { sheinClubShippingSiteConfig, realTimeReady, vipEstimatedClass, detailSheinClubContent } = this
      // let value = this.priceSource?.vipDiscountPrice?.amountWithSymbol
      let value = this.priceSource?.prev_discount_value?.amountWithSymbol
      if (!this.showVipEstimatedTagWithShipping) {
        if (!value) return ''
        if (detailSheinClubContent) return detailSheinClubContent
      } else {
        let result = this.goodsData.sheinClubContent?.goodsDetail?.shipping_pricebar?.fieldContent
        if (result.includes('</&') && result.includes('&/>')) {
          result = result.replaceAll('</&', '<span style="color: #C44A01;font-weight: 700;">')
          result = result.replaceAll('&/>', '</span>')
        }
        return result
      }

      // 付费会员入口样式强化
      let vipEstimatedText = value
      let vipEstimatedNew = ['New1', 'New2'].includes(vipEstimatedClass)
      if(vipEstimatedNew){
        vipEstimatedText = `<span style="color: #C44A01; font-weight: 700;">${value}</span>`
      }

      if(this.isPaidUser){
        return template(vipEstimatedText, this.language.SHEIN_KEY_PC_26352) 
      } else {
        if (!realTimeReady) return ''
        if (!sheinClubShippingSiteConfig.IS_NEW_SHEIN_CLUB_SHIPPING) {
          if(this.isFreeReceiveUser && this.showClubType){
            return template(vipEstimatedText, this.language.SHEIN_KEY_PC_28207)
          } else{
            return template(vipEstimatedText, this.language.SHEIN_KEY_PC_26351)
          }
        } else {
          const { VALUE_DETAIL = {} } = sheinClubShippingSiteConfig
          if (VALUE_DETAIL.single_coupon_value) { // 当有付费会员运费券时
            let text = vipEstimatedNew ? `<span style="color: #C44A01; font-weight: 700;">${VALUE_DETAIL.single_coupon_value_with_symbol}</span>` : VALUE_DETAIL.single_coupon_value_with_symbol
            return template(text, this.language.SHEIN_KEY_PC_29254)
          } else if (VALUE_DETAIL.multiple_points) { // 当没有会员运费券 但有奖励积分权益时
            let text = vipEstimatedNew ? `<span style="color: #C44A01; font-weight: 700;">${VALUE_DETAIL.multiple_points}</span>` : VALUE_DETAIL.multiple_points
            return template(text, this.language.SHEIN_KEY_PC_29255)
          } else { // 当既没有运费券又没有积分权益时
            return this.language.SHEIN_KEY_PC_29256
          }
        }
      }
    },
    isPercentNewStyle() {
      // 仅影响促销弹窗打开的加车弹窗的样式
      return this.estimatedInfo?.value && this.estimatedInfo?.discountPercent
    },
    showBestDealTips() {
      const { text, type } = this.bestDealConfig
      return text && !!~type.indexOf('Text')
    },
    bestDealTipsAnalysis() {
      const { goodsData, bestDealConfig, productInfoPrice } = this
      const { retailPrice, salePrice } = productInfoPrice
      const { mallCode, detail, quickShip } = goodsData
      const { tipsType } = bestDealConfig || {}
      const { goods_id, goods_sn, productRelationID } = detail || {}
      const goods_list = `${goods_id}\`${goods_sn}\`${productRelationID}\`1\`1\`1\`\`\`pri_${salePrice.usdAmount}|pri_${retailPrice.usdAmount}\`${quickShip == 1 ? 'quickship_1' : ''}\`\`mall_${mallCode}\``
      return {
        pricestatus: tipsType,
        location: 'popup',
        goods_list
      }
    },
    showEstimatedNoThresholdNewType() {
      let { EstimatedNothreShowType, isSatisfied, thresholdValue } = this.estimatedInfo || {}
      return ['New1', 'New2'].includes(EstimatedNothreShowType) &&
          !isSatisfied &&
          !!thresholdValue?.amountWithSymbol &&
          !this.quickViewConfig.isAddOnItem &&
          this.estimatedCompProps &&
          this.estimatedCompProps.config &&
          this.quickViewConfig?.addSource == 'DetailPage'
    },
    AddOnDialogData() {
      const { goods_id, cat_id } = this.goodsData?.detail || {}
      const optimalCouponCode = this.estimatedInfo?.optimalCoupon?.coupon_code
      // 基于商详商品推出凑单商品
      const isOpenRecommend = this.screenAbtConfig?.EstimatedNothreShowType?.p?.recommendtype == '1'
      return {
        preParams: {
          goodsIds: [goods_id], // 商品ID,
          cateIds: [cat_id], // 类目ID
          sceneId: 152, // 凑单列表值
          addOnType: 2,
          mainGoodsIds: isOpenRecommend ? [goods_id] : [],
        },
        saInfo: {
          promotion_code: optimalCouponCode,
          state: 'goods_detail_coupon_add',
          activity_from: 'goods_detail_coupon_add',
        },
        optimalCouponCode
      }
    },
    noticeText() {
      if (!this.promotion) {
        return ''
      }
      return this.template(
        this.promotion.coupon,
        this.couponLanguage.SHEIN_KEY_PC_17695
      )
    },
    showEstimatedNoThresholdNewAdd() {
      let { EstimatedPrice } = this.screenAbtConfig
      const { E_Price_AddOnItem, E_Price_Cal } = EstimatedPrice?.p
      const {
        apply_for,
        rule_dimension,
        satisfied,
        coupon_dimension
      } = this.estimatedInfo?.optimalCoupon
      const isRightRange = coupon_dimension == 1 && 
      (
        ( apply_for == 1 && rule_dimension == 5) || 
        ((apply_for == 2 || apply_for == 3) && (rule_dimension == 2 || rule_dimension == 5))
      )
      return E_Price_AddOnItem === 'AddOnItem'
        && E_Price_Cal === 'Non_Threshold' 
        && satisfied == 0
        && (this.isUseNewAddMore || isRightRange)
    },
    isSheinappwordsizeNew() {
      return this.screenAbtConfig?.sheinappwordsize?.p?.sheinappwordsize === 'newprice' & !this.isSmallStyle
    },
    isSmallStyle(){
      // 当middleast=new，且属于 满足门槛到手价+老样式的时候，主价格缩小，到手价放大且改为驼峰样式
      const isMiddleastNew = this.screenAbtConfig?.middleast?.p?.middleast === 'new'
      return isMiddleastNew && this.isSatisfiedOldStyle
    },
    isSatisfiedOldStyle(){ // 满足门槛到手价+老样式
      let { EstimatedNothreShowType, isSatisfied } = this.estimatedInfo || {}
      const showEstimatedTagOld = !this.showEstimatedNoThresholdNew && !(['New1', 'New2'].includes(EstimatedNothreShowType) && !!this.estimatedCompProps?.price?.amountWithSymbol) && !this.estimatedCompProps?.price?.discountPercent
      return this.showEstimatedOrAbPrice && this.hasEstimated  && isSatisfied && showEstimatedTagOld && !this.isHitComplianceMode
    },
    hasEstimated() {
      return !!this.estimatedCompProps && !!this.estimatedCompProps?.config
    },
    showEstimatedNoThresholdNew() {
      let { EstimatedNothreShowType, isSatisfied, isSatisfiedBuyMultiple } = this.estimatedInfo || {}
      return ['New1', 'New2'].includes(EstimatedNothreShowType) && !isSatisfied && !isSatisfiedBuyMultiple
    },
    sheinClubShippingSiteConfig() {
      const { estimatedInfo, goodsData } = this
      const { primeShipping } = goodsData
      let { privilege_code_list = [], privilege_value_detail = {} } = primeShipping || {}
      return {
        FREE_SHIPPING_TIMES: privilege_code_list?.includes('FREE_SHIPPING_TIMES'),
        MULTIPLE_POINTS: privilege_code_list?.includes('MULTIPLE_POINTS'),
        IS_NEW_SHEIN_CLUB_SHIPPING: !!estimatedInfo?.isNewSheinClubShipping,
        VALUE_DETAIL: privilege_value_detail
      }
    },
    
    suggestedSalePrice() {
      let { newFlashPromotion, priceSource, complianceMode, complianceTipsMode, isInversion, language } = this
      let suggestedSalePrice = null
      let suggestedValue = ''
      if (priceSource?.suggestedSalePrice?.amountWithSymbol) {
        suggestedSalePrice = priceSource?.suggestedSalePrice
        suggestedValue = parseFloat(suggestedSalePrice?.amount) === 0 ? '' : suggestedSalePrice?.amountWithSymbol
      }
      if (newFlashPromotion?.suggested_sale_price?.amountWithSymbol) {
        suggestedSalePrice = newFlashPromotion?.suggested_sale_price
        suggestedValue = parseFloat(suggestedSalePrice?.amount) === 0 ? '' : suggestedSalePrice.amountWithSymbol
      }
      let suggestedShow = complianceMode && !!suggestedValue && !isInversion
      let tip = complianceTipsMode && suggestedShow ? language.SHEIN_KEY_PC_30542 : ''
      return {
        suggestedSalePrice,
        show: suggestedShow,
        value: suggestedValue,
        tip,
      }
    },
    mainPriceStyle() {
      let { isSheinappwordsizeNew, complianceMode, complianceModeDe, deDiscountInfo, isInversion, isInversionDe, salePrice, retailPrice, sheinClubPromotionInfo, isPaidUser, isSpecialFlash, suggestedSalePrice, isShowDiscountPriceStyle, s3ClubPriceInfo } = this
      let color = '#000'
      let fontSize = '24px'
      let discountColor = this.IS_RW ? '#fc4070' : '#fa6338'
      if (complianceMode) {
        if (suggestedSalePrice?.suggestedSalePrice?.amount != salePrice) color = discountColor
      } else {
        if (retailPrice != salePrice) color = discountColor
      }
      if (complianceModeDe && (deDiscountInfo.retailDiscountPercent.show || deDiscountInfo.suggestedDiscountPercent.show)) {
        color = discountColor
      }
      if (isShowDiscountPriceStyle) color = discountColor
      if (isSpecialFlash) {
        color = discountColor
        fontSize = '20px'
      }
      if (isInversion || isInversionDe) {
        color = '#000'
      }
      if (sheinClubPromotionInfo && isPaidUser) color = '#873C00'
      if (s3ClubPriceInfo) color = '#806208'
      return {
        color,
        fontSize,
        'line-height': isSheinappwordsizeNew ? '1' : '1.2'
      }
    },
    discountPercentClassMap() {
      let { normalFlashStyle, isNewDiscountLabel } = this
      return {
        'flashsale-label': normalFlashStyle,
        'discount-label': !(normalFlashStyle),
        'new-discount-label': isNewDiscountLabel
      }
    },
    recommendRetailPrice() {
      let { complianceTipsMode, pspcPriceInfo, screenAbtConfig, mallCode, suggestedSalePrice, sheinClubPromotionInfo, isPaidUser, newFlashPromotion, s3ClubPriceInfo } = this
      if (screenAbtConfig?.detailrrpprice?.p?.detail_rrp_price != 'show') return null
      if (!complianceTipsMode) return null
      let skuCode =  this.addBagForm.sizeInfo?.sku_code
      let { skuMallPrices, skcMallPrices } = pspcPriceInfo || {}
      let curPriceInfo = (() => {
        if (this.addBagForm.sizeInfo?.sku_code) return skuMallPrices?.find?.(item => item.product_code == this.addBagForm.sizeInfo?.sku_code)?.mall_prices?.find?.(item=> item.mall_code == mallCode)
        return skcMallPrices?.find?.(item=> item.mall_code == mallCode)
      })()
      const isShowPrice = (sheinClubPromotionInfo && isPaidUser) || newFlashPromotion?.price?.amountWithSymbol || s3ClubPriceInfo
      let currentPriceAmount = (() =>{
        if (isShowPrice) return curPriceInfo?.show_price?.amount
        return curPriceInfo?.sale_price?.amount
      })()
      let { srp_price, srp_discount, show_srp_discount } = curPriceInfo || {}
      let value = ''

      if (!srp_price?.amount) return null
      if (parseFloat(srp_price?.amount) <= parseFloat(currentPriceAmount)) return null
      if (parseFloat(srp_price?.amount) <= parseFloat(suggestedSalePrice?.suggestedSalePrice?.amount)) return null
      value = srp_price?.amount_text

      let discountPercent = (() => {
        if (!suggestedSalePrice?.show) return ''
        return (isShowPrice ? show_srp_discount : srp_discount) + '%'
      })()
      return {
        value: `${this.language.SHEIN_KEY_PC_32910 || 'Recommend Retail Price'} ${value}`,
        discountPercent,
        usdAmountWithSymbol: srp_price.usd_amount_text,
        skuCode
      }
    }
  },
  watch: {
    'suggestedSalePrice.show': {
      handler(val) {
        if (val) daEventCenter.triggerNotice({ daId: '1-8-6-224' })
      },
      immediate: true,
    },
    'deDiscountInfo.suggestedSalePrice.show': {
      handler(val) {
        if (val) daEventCenter.triggerNotice({ daId: '1-8-6-224' })
      },
      immediate: true,
    }
  },
  async mounted() {
    const res = await schttp({
      method: 'POST',
      url: '/api/config/apollo/get',
      data: { apolloKeys: 'IS_USE_NEW_ADD_MORE' }
    })
    const { IS_USE_NEW_ADD_MORE } = res?.info || {}
    this.isUseNewAddMore = IS_USE_NEW_ADD_MORE
    if(this.lowestPriceTipsConfig?.hitLowestPrice) {
      daEventCenter.triggerNotice({
        daId: '1-8-6-193',
        extraData: {
          priceday: this.lowestPriceTipsConfig.hitLowestPrice,
        }
      })
    }
  },
  methods: {
    isShowExplainationModal() {
      this.changeRootStatus({
        rootTips: true,
        vipHelpTips: true,
        notShowFooter: false,
      })
    },
    goDetail() {
      const { daIdConfig, ga, sa } = this.analysis
      // 触发埋点上报
      if (daIdConfig?.viewFullDetail) {
        daEventCenter.triggerNotice({
          daId: (daIdConfig && daIdConfig.viewFullDetail),
          extraData: {
            goodsData: this.goodsData,
            gaList: ga && ga.gaList,
            eventCategory: (ga && ga.eventCategory) || '',
            activity_from: (sa && sa.activity_from) || '',
            style: sa?.style,
          },
        })
      }
      let url = 'javascript:;'
      if (this.goodsData.detail) {
        const { goods_url_name, goods_id, cat_id } = this.goodsData.detail
        let extendUrl = ''
        const { detailUrlExtendParam } = this.quickViewConfig
        if (detailUrlExtendParam) {
          Object.keys(detailUrlExtendParam).forEach((item) => {
            extendUrl += `&${item}=${detailUrlExtendParam[item]}`
          })
        }
        if (this.pageListType !== undefined) {
          extendUrl += `&pageListType=${this.pageListType || ''}`
        }
        url =
          getGoodsUrl(goods_url_name, goods_id, cat_id) +
          '?imgRatio=' +
          this.fixedRatio + 
          extendUrl
        if(this.mainCouponcode){
          url += `&mainCode=${this.mainCouponcode}`
        }
      }
      setTimeout(() => {
        window.location.href = url
      }, 0)
    },
    handleClickEstimatedTag() {
      this.quickViewConfig?.callback?.clickEstimated?.(this.estimatedInfo)
    },
    async handleClickNewNoThresholdEstimatedTag() {
      let formatCoupon = souceFormat(this.estimatedInfo?.optimalCoupon) || {}
      const {
        apply_for,
        category_name = [],
        coupon,
        coupon_type_id,
        id,
        coupon_dimension
      } = formatCoupon
      if (!this.IS_USE_NEW_ADD_MORE) {
        const apolloRes = await schttp({
          method: 'POST',
          url: '/api/config/apollo/get',
          data: { apolloKeys: 'IS_USE_NEW_ADD_MORE' }
        })
        this.IS_USE_NEW_ADD_MORE = apolloRes?.info || {}
      }
      const isToPage = coupon_dimension == 1 && (apply_for == 2 || apply_for == 3) && (coupon_type_id == 2 || coupon_type_id == 5)
      const isToDrawer = this.IS_USE_NEW_ADD_MORE || (coupon_dimension == 1 && apply_for == 1 && coupon_type_id == 5)
      if(isToDrawer){ // 平台券-券适用范围为全部商品的应付价折扣的平台券
        // 唤起凑单弹窗 
        this.addMoreDialogVisible = true
      }else if(isToPage){ // 平台券-原价折扣券/应付折扣券 -部分商品券 || 平台券-原价折扣券/应付折扣券-部分品类券
        // 跳转凑单页
        const category_id = category_name.map(item => item.cat_id)
        const coupon_type = 1
        const catIds = (category_id && category_id.join(',')) || ''
        const showTip = coupon_type_id == '2' ? 1 : 0
        let query = ''
        switch (apply_for) {
          case '2':
            if (!Object.keys(this.couponLanguage).length) {
              this.getCouponLan()
            }
            // 打开可用商品弹窗, 去页面内请求数据
            if (!isLogin()) { // 判断是否登录
              return SHEIN_LOGIN.show({
                cb: () => this.showProductModal(formatCoupon),
                from: 'other',
              })
            }
            this.showProductModal(formatCoupon)
            break
          case '3':
            // 跳转对应品类列表页，需要携带品类id category_id，打开新窗口
            // 如果coupon_type_id=2携带参数ShowTip=1&CouponCode={券码}
            query = `ShowTip=${showTip}&CouponCode=${coupon.toLowerCase()}&multi_cat_ids=${catIds}&act_id=${id}&coupon_type=${coupon_type}`
            sessionStorage.removeItem('couponTipClose') // 删除关闭优惠券tip的标识
            window.open(`/coupon-product.html?${query}`, '_blank')
            break
          default:
        }
      }
    },
    showProductModal(coupon) {
      this.isProductsModalShow = true
      this.selectedCoupon = coupon
      this.getPromProducts(coupon.coupon)
    },
    async getPromProducts(couponCode) {
      this.isProFetching = true
      const res = await schttp({
        method: 'POST',
        url: '/api/user/coupon/promProducts/get',
        data: { couponCode },
      })
      this.isProFetching = false
      if (res.code === 0) {
        this.promotion = {
          items: res.info.couponSkcList[0].skc,
          ...this.selectedCoupon,
        }
      } else {
        this.isProFetchError = true
      }
    },
    getCouponLan () {
      schttp({
        url: '/api/common/language/get',
        params: { page: 'coupon_item' },
      }).then(({ language }) => {
        this.couponLanguage = language
      })
    },
    // 价格大小实验
    mainPriceWordSizeFormater(priceStr) {
      return salePriceWordSize({
        priceStr,
        gate: this.isSheinappwordsizeNew || this.isSmallStyle,
        size: this.isSmallStyle ? 16 : 28,
        smallSize: this.isSmallStyle ? 16 : 18,
      })
    },
    formatPercentText(num) {
      return parsePercentText(`${lang != 'il' ? '-' : ''}${num}%`)
    }
  },
}
</script>

<style lang="less" scoped>
.goods-price__normal-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.goods-rw-vip {
  width: 100%;
  margin-bottom: -20px;
  margin-top: 10px;
}
.goods-paidvip.paidvip {
  width: 100%;
  margin: 20px 0 0;
}
.goods-estimatedNoThresholdNewType {
  width: 100%;
  margin: 20px 0 0;
}
.goods-paidvip-estimated {
  width: 100%;
  margin: 12px 0 0;
}

.goods-summary {
  &__container {
    .best-deal-tips__wrap {
      margin-top: 8px;
      width: 100%;
    }
  }
  &__special-flash {
    // display: flex;
    // justify-content: space-between;
    flex: 1;
    padding: 6px;
    background: linear-gradient(
      180deg,
      #fff6f3 0%,
      rgba(255, 246, 243, 0) 100%
    );
  }
  &__flashTopLine{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &__specialFlashNewpercent {
    width: 100%;
    padding: 8px 8px 0;
    background: linear-gradient(
      180deg,
      #fff7f4 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  &__specialFlashLeft {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px 0;
    & > p:nth-child(1) {
      font-family: Arial;
      font-size: 24px;
      line-height: 34px;
      font-weight: 900;
      color: #fa6338;
      margin-right: 6px;
      line-height: 1.2;
    }
  }
  &__specialFlashLeft_bigSmall {
    align-items: flex-end;
  }
  &__specialFlashLeft__has-estiamted {
    padding: 0 0;
  }
  &__specialFlashEstimated {
    padding: 4px 0;
  }
  &__specialFlashRetail {
    color: #999;
    vertical-align: middle;
    font-size: 14px;
    line-height: 1;
    margin-right: 5px;
  }
  &__specialFlashDiscountPercent {
    padding: 0 8px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    display: inline-block;
    font-size: 12px;
    background-color: #fa6338;
    border-radius: 2px;
    margin-right: 5px;
    color: #fff;
  }

  &__specialFlashRight {
    font-size: 12px;
    font-weight: 700;
    color: #fa6338;
    white-space: nowrap;
    margin-top: 9px;
  }
  &__specialFlashTop {
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    color: #fa6338;
    white-space: nowrap;
    margin-bottom: 5px;
    cursor: pointer;
    text-decoration: none;
  }
}


.goods-price {
  padding-bottom: 12px;
  margin-bottom: 16px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  border-bottom: 1px dashed #e5e5e5;
  .flasesale-icon {
    color: @sui_color_flash !important;
  }
  &__has-birth {
    color: #999;
    font-size: 12px;
    text-decoration: line-through;
    font-weight: normal;
    display: inline-block;
    margin-left: 4px;
  }
  &__nobirth {
    display: inline-block;
    font-weight: 700;
    font-size: 24px;
    font-family: "Arial Black";
    color: #222;
  }
  &__nobirth-icon {
    transform: scale(0.7);
  }
  &__nobirth-price {
    font-size: 12px;
    margin-left: -6px;
  }
  &__vip {
    flex: 0 0 100%;
    margin: 12px 0 0 0;
  }
  // .flash-icon {
  //   color: #fcd364;
  //   font-size: 20px;
  //   margin: 0 5px;
  //   display: inline-block;
  //   vertical-align: top;
  //   /* rw:begin */
  //   color: #ff5078;
  // }
  &__container {
    display: flex;
    align-items: center;
    flex: 1;
    flex-wrap: nowrap;
    max-width: 100%;
  }
  &__container_bigSmall {
    align-items: flex-end;
  }
  &__sale {
    // margin-right: 15px;
    font-weight: 700;
    font-size: 24px;
    color: @sui_color_discount;
    font-family: "Arial Black";
    // line-height: 1.2;
    display: flex;
    align-items: center;
    margin-right: 6px;
  }
  &__s3-tag{
    width: 27px;
    margin-right: 2px;
  }
  &__estimated {
    margin: 6px 0;
    max-width: 100%
  }
  &__estimated_bigSmall {
    margin: 6px 0 0;
  }
  &__origin {
    font-size: 12px;
    margin-right: 4px;
    color: #999;
    margin-right: 5px;
  }
  .flashsale-label,
  .discount-label {
    //min-width: 47px;
    padding: 0 8px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    display: inline-block;
    font-size: 12px;
    margin-right: 5px;

    /* rw:begin*/
    font-family: "Adieu";
    font-weight: 400;
  }
  .flashsale-label {
    margin-left: 5px;
    background-color: @sui_color_flash;
    /* rw:begin */
    color: #fff;
  }
  .discount-label {
    background-color: #222;
    color: @sui_color_white;
    /* rw:begin */
    background-color: @sui_color_main;
    &.new-discount-label {
      background-color: #FA6338;
      border-radius: 2px;
    }
  }
  &__suggestedSalePrice-tip {
    font-family: SF Pro;
    font-size: 12px;
    font-weight: 400;
    color: #959595;
  }
  &__no-bottom {
    border-bottom: none;
  }
  &__de-discount {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #999;
    .flashsale-label {
      color: #222;
    }
  }
  &__de-discountDel {
    margin-right: 4px;
  }
}

.vip-birth {
  &__quickview-comp {
    display: inline-block;
    font-size: 26px;
    font-weight: bold;
    color: #BF9F53;
    .vip-birth-tag, .vip-birth-tag__ar {
      transform: scale(1.3);
      transform-origin: 50% 100%;
    }
  }
  &__quickview-price {
    font-size: 14px;
    font-weight: normal;
    transform: scale(0.9);
  }
}

.vip-birth-tag, .vip-birth-tag__ar {
  margin-right: 2px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 864px 149px;
  width: 15px;
  height: 16px;
  background-position: -320px -53px;
}

@icon_level: '/she_dist/images/sheinvip2/icon_level-cef5be75f3.png';
@icon_level_ar: '/she_dist/images/sheinvip2/icon_level_ar-5660eafcef.png';
@rw_vip_banner: '/she_dist/images/sheinvip2/rw_banner-cd97bee0d3.png';

.vip-birth-tag {
  background-image: url(@icon_level);
}
.vip-birth-tag__ar {
  background-image: url(@icon_level_ar);
}

.vip-exclusive {
  &__quickView {
    position: relative;
    height: 32px;
    line-height: 32px;
    padding: 0 10px;
    background: rgba(228,202,152,0.5);
    color: #A78A45;
    font-weight: bold;
    font-size: 16px;
    & when (@IS_RW){
      color: #936D0C;
      background-image: url(@rw_vip_banner);
      background-position: center top;
      margin-bottom: -15px;
    }
  }
  &__detail-comp-page {
    margin-top: 10px;
    height: 42px;
    line-height: 42px;
    font-size: 20px;
    .vip-exclusive-txt {
      vertical-align: text-top;
      line-height: 18px;
    }
  }
}

.vip-exclusive-txt__quickView {
  display: inline-block;
  padding: 0 18px;
  height: 21px;
  line-height: 21px;
  background-color: #222;
  border-radius: 40px 0px 40px 0px;
  transform: scale(0.8);
  
  span {
    color: #d8bb49;
    font-weight: normal;
    font-size: 14px;
  }
}
</style>
