import i18n from 'public/src/pages/common/biz_helper/i18n'
import schttp from 'public/src/services/schttp'
import { getQueryString, template } from '@shein/common-function'
import { userModule } from 'public/src/pages/common/user_module.js'
import { onWindowLoad } from 'public/src/pages/common/utils/index.js'

const { langPath, lang, IS_RW } = gbCommonInfo

onWindowLoad(function () {
  requestAnimationFrame(function () {
    if (document.querySelectorAll('.j-customer-vue').length) {
      import(/* webpackChunkName: "CustomerContainer" */ './customerContainer').then(({ default: CustomerContainer }) => {
        new CustomerContainer().init()
      })
    }
  })
  window.V_Comp = {}
  ;(() => {
    var currencies = gbCommonInfo.currencies
    var check_freeShippingTips_promise = window.check_freeShippingTips_promise = userModule.check_freeShippingTips_promise() || null
    if (check_freeShippingTips_promise) {
      // 动态设置登录用户名, 货币
      check_freeShippingTips_promise.then(async function (data) {
        var currency = getQueryString({ key: 'currency' }) || data.currency
        // 更改货币
        if (currencies[currency] && currencies[currency].code) {
          document.querySelectorAll('.j-currency-' + currencies[currency].code).forEach(node => {
            const parent = node.parentElement
            if (parent) {
              parent.classList.remove('j-change-currency')
              parent.classList.add('active')
            }
          })
          document.querySelectorAll('.j-range-currency').forEach(node => {
            node.innerText = currencies[currency].code
          })
          
          window.GB_SHEIN_currency = currencies[currency].code
        }
        // 免邮信息
        const dataInfo = await schttp({
          url: '/api/others/getShippingFreeDetails/get',
        })
        const shippingData = dataInfo?.list || []
        const language = await i18n.loadByQueue('header')
        var freeHTML = []
        if (shippingData && shippingData.length) {
          var freeShippingTipsData = []
          var textTpl = {
            ['EXPRESS SHIPPING']: language.SHEIN_KEY_PC_14681, // 快递
            ['ECONOMIC SHIPPING']: language.SHEIN_KEY_PC_16129, // 平邮
            ['STANDARD SHIPPING']: language.SHEIN_KEY_PC_14682, // 标准
            ['PICK UP AT CONVENIENCE STORE']: language.SHEIN_KEY_PC_16131, // 店配
          }
          var textTpl1 = {
            ['EXPRESS SHIPPING']: language.SHEIN_KEY_PC_14684,
            ['ECONOMIC SHIPPING']: language.SHEIN_KEY_PC_18178,
            ['STANDARD SHIPPING']: language.SHEIN_KEY_PC_14685,
            ['PICK UP AT CONVENIENCE STORE']: '<strong style=\"color:#f35b6e; font-weight:700\">' + language.SHEIN_KEY_PC_17102 + '</strong>',
          }
          shippingData.forEach(function (val) {
            if (textTpl[val.title]) {
              var el1 = '<span>' + template(val?.full_amount?.amountWithSymbol, textTpl[val.title]) + '</span>'
              var el2 = '<span>' + textTpl1[val.title] + '</span>'
              var invoke = Number(val?.full_amount?.amount)
              if (invoke > 0) {
                freeShippingTipsData.push(el1)
              } else if (invoke == 0 && val.shippingPrices.amount == 0) {
                freeShippingTipsData.push(el2)
              }
            }
          })
          freeHTML = freeShippingTipsData.map(function (tip) {
            const currentId = !IS_RW ? 280 : 471
            var hrefVal = langPath + `/Shipping-Info-a-${currentId}.html`
            return `<li><a href=${hrefVal}>${tip}${dataInfo?.mallInfo?.mall_name && lang !== 'mx' ? `<span class="shipping-info_mall">(${language.SHEIN_KEY_PC_22820} ${dataInfo?.mallInfo?.mall_name})</span>` : ''}</a></li>`
          })
        }
        // 首单优惠数据
        if (data.freeShippingTips && data.freeShippingTips.length) {
          var freeShippingTips = JSON.parse(data.freeShippingTips)
          freeShippingTips.forEach(function (item) {
            freeHTML.push('<li class="discount">' + item.replace(/http\:\/\/\w+.shein.\w+/gi, langPath).replace(/\s/g, '{space}').replace(/\{space\}/g, ' ') + '</li>')
          })
        }
        // abtservice
        //   .getUserAbtResult({ posKeys: 'TopRotation' })
        //   .then(({ TopRotation }) => {
        const abtInfo = {
          param: 'type=A',
          postkey: 'TopRotation',
        }
        // abtInfo?.param !== 'type=B' &&
        //      (document.querySelector('.j-free-shipping')?.innerHTML = freeHTML.join(''))
        if (abtInfo?.param !== 'type=B') {
          const jFreeShipping = document.querySelector('.j-free-shipping')
          if (jFreeShipping) {
            jFreeShipping.innerHTML = freeHTML.join('')
          }
        }
        // free shipping 动画
        const liEls = document.querySelectorAll('.j-free-shipping >li')
        var index = 0,
            stop = false,
            num = liEls.length

        function shippingflipInX() {
          if (stop) return false
          if (index > num - 2) {
            liEls.forEach(li => {
              li.classList.add('she-none')
              li.classList.remove('animated')
              li.classList.remove('flipInX')
              li.classList.remove('she-block')
            })
            index = 0
            if (liEls[0]) {
              liEls[0].classList.add('animated')
              liEls[0].classList.add('flipInX')
              liEls[0].classList.add('he-block')
              liEls[0].classList.remove('she-none')
            }
            return
          }

          liEls.forEach(li => {
            li.classList.add('she-none')
            li.classList.remove('animated')
            li.classList.remove('flipInX')
            li.classList.remove('she-block')
          })

          if (liEls[index + 1]) {
            liEls[index + 1].classList.remove('she-none')
            liEls[index + 1].classList.add('animated')
            liEls[index + 1].classList.add('flipInX')
            liEls[index + 1].classList.add('she-block')
          }
          index++
        }
        setInterval(function () {
          shippingflipInX()
        }, 3000)

        const jFreeShipping = document.querySelector('.j-free-shipping')
        if (jFreeShipping) {
          jFreeShipping.addEventListener('mouseenter', () => {
            stop = true
          })
          jFreeShipping.addEventListener('mouseleave', () => {
            stop = false
          })
        }
      })
    }
  })()
})
