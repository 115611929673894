<template>
  <AppCcc
    :content="content"
    :context="context"
    :scene-data="sceneData"
    :hooks="hooks"
  />
</template>

<script>
import getCccRecommendConfig, { getBffCccRecommendConfig } from '@/public/src/pages/common/getCccRecommendConfig'
import { isSwitchBffApiVersion } from '@/public/src/pages/common/bff-new/index'
import AppCcc from '@/public/src/pages/components/ccc/Index.vue'

const { PUBLIC_CDN, WEB_CLIENT } = gbCommonInfo

export default {
  name: 'DefaultRecommend',
  components: {
    AppCcc
  },
  props: {
    extendParams: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      cccxContext: null,
      cccxLanguage: {},
    }
  },
  computed: {
    content() {
      return this.cccxContext?.content || []
    },
    context() {
      return {
        PUBLIC_CDN,
        WEB_CLIENT,
        language: this.cccxLanguage,
      }
    },
    sceneData() {
      const { pageType = '' } = this.cccxContext || {}
      return {
        pageFrom: pageType,
        sceneName: pageType,
        pageType: pageType,
      }
    },
    hooks() {
      return {
        PRODUCTRECOMMEND: {
          // context_params参数
          generateContextParams: () => {
            const { cate_ids = '', goods_ids = '' } = this.extendParams

            return {
              goods_ids,
              cate_ids,
            }
          },
          recordQueryTs: true, // 记录非翻页请求的时间戳 abt interleaving需要 给埋点用
        }
      }
    }
  },
  async mounted() {
    let cccxContext, cccxLanguage
    const switchBffApiVersionKey = 'cccx_recom_cart_v1'
    if (
      isSwitchBffApiVersion([switchBffApiVersionKey])?.[switchBffApiVersionKey]
    ) {
      ;[cccxContext, cccxLanguage] = await getBffCccRecommendConfig({
        pageType: 'shoppingCartPage'
      })
    } else {
      ;[cccxContext, cccxLanguage] = await getCccRecommendConfig('shoppingCartPage', 'shoppingCartPage', {
        contextParams: this.hooks.PRODUCTRECOMMEND.generateContextParams()
      })
    }
    this.cccxContext = cccxContext || {}
    this.cccxLanguage = cccxLanguage || {}
  }
}
</script>
