<template>
  <AppCcc
    :content="content"
    :context="context"
    :scene-data="sceneData"
    :hooks="cccAppHooks"
  >
    <!-- 自定义推荐组件标题和副标题 -->
    <template #recommend-title>
      <div class="recommend-title-wrap">
        <div class="main-title">
          <Icon
            name="sui_icon_coupon_discount_12px_color"
            size="24px"
            class="coupon-icon"
          />
          <div v-html="mainTitle"></div>
        </div>
        <div
          class="subhead"
          v-html="subhead"
        ></div>
      </div>
    </template>
    <!-- 自定义商卡折扣标签 -->
    <template #recommend-item-beforeTitle>
      <template v-if="isShowLabel">
        <Icon
          name="sui_icon_coupon_discount_16px"
          size="16px"
          color="#FA6338"
        />
      </template>
    </template>
  </AppCcc>
</template>

<script>
import getCccRecommendConfig, { getBffCccRecommendConfig } from '@/public/src/pages/common/getCccRecommendConfig'
import { isSwitchBffApiVersion } from '@/public/src/pages/common/bff-new/index'
import AppCcc from '@/public/src/pages/components/ccc/Index.vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { mapState } from 'vuex'

const { PUBLIC_CDN, WEB_CLIENT } = gbCommonInfo

export default {
  name: 'CouponRecommend',
  components: {
    AppCcc,
    Icon
  },
  props: {
    extendParams: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      cccxContext: null,
      cccxLanguage: {},
      needRefresh: false,
    }
  },
  emits: ['change-model'],
  computed: {
    ...mapState(['filterState', 'language', 'locals']),

    // 是否显示商卡折扣标签（当前券是否凑满最低门槛）
    isShowLabel() {
      return +this.filterState.selectFilterLabel?.level > 0
    },
    // 主标题
    mainTitle() {
      return this.filterState.selectFilterLabel?.recommendInfo?.recommendTip || ''
    },
    /**
     * 副标题
     * 未达到最低门槛    SHEIN_KEY_PC_30338
     * 达到最高门槛      SHEIN_KEY_PC_30340
     * 达到最低未达到最高 SHEIN_KEY_PC_30339
     */
    subhead() {
      return this.filterState.selectFilterLabel?.recommendInfo?.recommendAddTip || ''
    },

    // 传给推荐的券有效信息
    couponInfo() {
      const { coupon = '', recommendInfo } = this.filterState.selectFilterLabel || {}

      return {
        id: coupon,
        include_tsp_id: recommendInfo?.include_tsp_id || '',
        exclude_tsp_id: recommendInfo?.exclude_tsp_id || '',
        goods_price: recommendInfo?.goods_price?.amount || 0,
      }
    },

    content() {
      return this.cccxContext?.content || []
    },
    context() {
      return {
        PUBLIC_CDN,
        WEB_CLIENT,
        language: this.cccxLanguage,
      }
    },
    sceneData() {
      const { pageType = '' } = this.cccxContext || {}
      return {
        pageFrom: pageType,
        sceneName: pageType,
        pageType: pageType,
      }
    },
    cccAppHooks() {
      const _this = this
      return {
        PRODUCTRECOMMEND: {
          // context_params参数
          generateContextParams: () => {
            const { cate_ids = '', goods_ids = '',  } = this.extendParams
            const {
              include_tsp_id = '',
              exclude_tsp_id = '',
              goods_price = 0
            } = this.couponInfo
            return {
              goods_ids,
              cate_ids,
              include_tsp_id,
              exclude_tsp_id,
              goods_price: (Number(goods_price) * 100).toFixed(0),
              add_on_type: '5',
              stock_enough: 1,  // 1为有库存
              is_on_sale: 1,
            }
          },
          generateExtendSaParams: () => {
            return {
              activity_from: 'cart_filter_coupon_add_recommend',
              coupon_type: this.filterState.selectFilterLabel?.coupon_type || '-',
            }
          },
          changeToRefresh: this.needRefresh, // 是否刷新数据
          recordQueryTs: true, // 记录非翻页请求的时间戳 abt interleaving需要 给埋点用
          // 准备完成事件，数据加载完成时触发，ssr会在create事件触发
          ready({ withoutDatas = false, currentListPageNum = 0 }) {
            // withoutDatas表示存在列表数据，仅pc&pwa支持
            // currentListPageNum为首页的列表数据条数
            if (withoutDatas && currentListPageNum == 0) {
            // eslint-disable-next-line no-console
              console.log('[cart&recommend]152失败，切换为128', withoutDatas)
              // 切换为128
              _this.changeTo128()
            }
          }
        }
      }
    }
  },

  watch: {
    // 当切换券或凑单差额发生变化，重新刷新推荐数据
    couponInfo(newVal, oldVal) {
      if (newVal?.id && 
        (newVal?.id !== oldVal?.id || newVal?.goods_price !== oldVal?.goods_price)
      ) {
        // 在ccc组件中会监听needRefresh值的变化，一旦变化会重新刷新数据
        this.needRefresh = !this.needRefresh
        // eslint-disable-next-line no-console
        console.log('[cart&recommend] needRefresh')
      }
    }
  },

  async mounted() {
    let cccxContext, cccxLanguage
    const switchBffApiVersionKey = 'cccx_recom_cart_v1'
    if (
      isSwitchBffApiVersion([switchBffApiVersionKey])?.[
        switchBffApiVersionKey
      ]
    ) {
      ;[cccxContext, cccxLanguage] = await getBffCccRecommendConfig({
        pageType: 'shoppingCartCouponPage',
      })
    } else {
      ;[cccxContext, cccxLanguage] = await getCccRecommendConfig('shoppingCartCouponPage', 'shoppingCartCouponPage', {
        contextParams: this.cccAppHooks.PRODUCTRECOMMEND.generateContextParams(),
        withoutListFault: true, // 券筛选推荐时（152）下不进行默认容错，手动切换为defaultRecommend，走128
      }, 'shoppingCartCouponBottom')
    }
    this.cccxContext = cccxContext || {}
    this.cccxLanguage = cccxLanguage || {}
  },

  methods: {
    changeTo128() {
      this.$emit('change-model', 128)
    }
  },
}
</script>

<style lang="less" scoped>
.recommend-title-wrap {
  height: 58px;
  margin: 20px 0;
  .main-title {
    display: inline-flex;
    align-items: center;
    font-family: "Arial Black";
    font-size: 24px;
    font-weight: 900;
    line-height: 34px;
    color: #FA6338;
    .coupon-icon {
      margin-right: 4px;
    }
  }
  .subhead {
    font-family: "SF Pro";
    font-size: 14px;
    line-height: 17px;
    color: #000;
    :deep(span) {
      /* stylelint-disable-next-line declaration-no-important */
      font-size: 14px!important;
    }
  }
}
</style>
