import { useAppConfigs, Libs } from '@shein-aidc/bs-sdk-libs-manager'

import type { CacheConfig } from '../types'

class Cache {
  #appConfigs: Libs.AppConfigs
  #key: string
  #cache: any
  #config: CacheConfig
  #initCallback?: (cahce: any) => void
  #setCallback?: (cahce: any) => void

  constructor(key, config: CacheConfig = {}) {
    this.#appConfigs = useAppConfigs()

    this.#key = key + '__byCartTagTip'
    this.#cache = undefined

    this.#config = config

    this.#initCallback = config.initCallback
    this.#setCallback = config.setCallback


    if (config.disabledSession) {
      this.#cache = {
        data: null,
      }
    } else {
      this.init(config)
    }
  }

  init(config: CacheConfig = {}) {
    if (typeof window === 'undefined') return

    const currentStatus = {
      langPath: this.#appConfigs.$envs.langPath,
      currency: this.#appConfigs.$envs.currency,
      isLogin: this.#appConfigs.$getters.isLogin,
    }

    let cache = JSON.parse(window.sessionStorage.getItem(this.#key) || '{}')

    // 没有缓存
    const noCache = !cache
    // 有缓存 && ( 地址变更 || 币种变更 || 登录状态变更 )
    const isChange =
      !noCache &&
      (cache.langPath !== currentStatus.langPath ||
        cache.currency !== currentStatus.currency ||
        cache.isLogin !== currentStatus.isLogin)

    if (noCache || isChange || config.clear) {
      cache = {
        data: null,
        ...currentStatus,
      }

      // 更新缓存信息
      window.sessionStorage.setItem(this.#key, JSON.stringify(cache))
    }

    this.#cache = cache

    this.#initCallback?.(cache)
  }

  get() {
    return this.#cache.data
  }

  set(val) {
    this.#cache.data = val

    if (!this.#config.disabledSession) {
      // 更新缓存信息
      window.sessionStorage.setItem(this.#key, JSON.stringify(this.#cache))
    }

    this.#setCallback?.(this.#cache)
  }

  clear() {
    window.sessionStorage.removeItem(this.#key)
  }
}

export default Cache
