// const en = '//shein.ltwebstatic.com/svgicons/2024/04/22/17137715531447742748.svg'
// const pt = '//shein.ltwebstatic.com/svgicons/2024/04/22/17137723411120326754.svg'
// const fr = '//shein.ltwebstatic.com/svgicons/2024/06/04/17174693142959207518.svg'
// const it = '//shein.ltwebstatic.com/svgicons/2024/04/22/1713772451816482582.svg'
// const ar = '//shein.ltwebstatic.com/svgicons/2024/04/22/17137725741718790054.svg'
// const pl = '//shein.ltwebstatic.com/svgicons/2024/04/22/1713771089284162936.svg'
// const elgr = '//shein.ltwebstatic.com/svgicons/2024/04/22/17137713432716155383.svg'
// const ja = '//shein.ltwebstatic.com/svgicons/2024/04/22/17137714221691934212.svg'
// const ro = '//shein.ltwebstatic.com/svgicons/2024/04/25/17140505522250097767.svg'

const en = '//img.ltwebstatic.com/images3_ccc/2024/06/05/48/17175168449ad3d19db7e25f153f448739674f1058.png'
const cscz  = '//img.ltwebstatic.com/images3_ccc/2024/06/05/48/171751684412f657b32ff572e946f742828b964781.png'
const ar = '//img.ltwebstatic.com/images3_ccc/2024/06/05/48/171751684452f981ab5c0ae69f53e3b7fa2dfe2e5f.png'
const elgr = '//img.ltwebstatic.com/images3_ccc/2024/06/05/48/17175168441ac5fab3b4fce928535dd7072210b083.png'
const fr = '//img.ltwebstatic.com/images3_ccc/2024/06/05/48/17175168448c14d76ca9f3b0d099ff83f0f1da4fb7.png'
const it = '//img.ltwebstatic.com/images3_ccc/2024/06/05/48/1717516844bb8b2c4e321ba055b7c6ebf682b2ffe8.png'
const ja = '//img.ltwebstatic.com/images3_ccc/2024/06/05/48/17175168443f022d748e185af5c34ca314e90835c7.png'
const pl = '//img.ltwebstatic.com/images3_ccc/2024/06/05/48/17175168442029223d5f7c183d952c3190ae2b38ab.png'
const pt = '//img.ltwebstatic.com/images3_ccc/2024/06/05/48/1717516844b5b8c815abdffa104d00230198a9ed9b.png'
const ro = '//img.ltwebstatic.com/images3_ccc/2024/06/05/48/1717516844ac02224e4961e542396d52fddb86f16b.png'
const sk = '//img.ltwebstatic.com/images3_ccc/2024/07/25/32/1721893297a9b995db61d9397dcfbff167c26e5f77.png'
const hu = '//img.ltwebstatic.com/images3_ccc/2024/07/25/28/1721893301bef08c1e225ffa02e980a4e07760d20b.png'
const bg = '//img.ltwebstatic.com/images3_ccc/2024/07/25/8e/172189330445d42a3e8959c2c204fce58e7f283aa6.png'
const sv = '//img.ltwebstatic.com/images3_ccc/2024/09/10/e4/172593820948ef5deeb4db732e64160f5bc9cbc321.png'
const th = '//img.ltwebstatic.com/images3_ccc/2024/12/26/fd/1735196018d39acff574f7870b7a7f78b57d2e9fc7.png'
const il = '//img.ltwebstatic.com/images3_ccc/2024/12/11/a4/1733885888113a4b7775493d531200c2c8f0e9513f.png'
export default {
  en,
  us: en,
  de: en,
  es: en,
  el: en,
  nl: en,
  ru: en,
  'en_au': en,
  'es-mx': en,
  'zh-tw': en,
  ko: en,
  pt,
  'pt-br': pt,
  'pt-pt': pt,
  'br': pt,
  fr,
  it,
  ar,
  pl,
  'cs-cz': cscz,
  'el-gr': elgr,
  ja,
  ro,
  uses: en,
  quqssk: sk,
  euqshu: hu,
  eurbg: bg,
  sk,
  hu,
  bg,
  sv,
  sesv: sv,
  th,
  he: il,
}
// export default {
//   en: '//img.ltwebstatic.com/images3_ccc/2024/03/18/54/17107416807f90c9c39527ddae495ef0ed8998fa23.png',
//   de: '//img.ltwebstatic.com/images3_ccc/2024/03/18/54/17107416807f90c9c39527ddae495ef0ed8998fa23.png',
//   es: '//img.ltwebstatic.com/images3_ccc/2024/03/18/54/17107416807f90c9c39527ddae495ef0ed8998fa23.png',
//   pt: '//img.ltwebstatic.com/images3_ccc/2024/03/18/54/1710741680996cace2a617fddc46b1842116d5c432.png',
//   'pt-br': '//img.ltwebstatic.com/images3_ccc/2024/03/18/54/1710741680996cace2a617fddc46b1842116d5c432.png',
//   'pt-pt': '//img.ltwebstatic.com/images3_ccc/2024/03/18/54/1710741680996cace2a617fddc46b1842116d5c432.png',
//   fr: '//img.ltwebstatic.com/images3_ccc/2024/03/18/54/17107416800a3bc11f70d929699e41e63583cb0814.png',
//   it: '//img.ltwebstatic.com/images3_ccc/2024/03/18/54/1710741680c234cd53e604ea7ff1831e7b6af3db69.png',
//   ar: '//img.ltwebstatic.com/images3_ccc/2024/03/18/b3/17107417036d8cff8b0cdaa10559eaac02ce50dd37.png',
// }
