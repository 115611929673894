<template>
  <div
    :class="[
      'payment-item',
      'payment-ideal',
      {
        'payment-selected': cardValue,
        fold: isShowViewMore && inx >= checkout.results.folded_position,
      },
    ]"
    :type="item.code"
    tabindex="0"
    role="radio"
    :aria-checked="
      item.enabled && status.cardPay == item.code && !status.disabled[item.code]
    "
    @click="clickPayment(item)"
  >
    <s-radio
      :model-value="radioModalValue"
      :label="true"
      :toggle="true"
      :disabled="
        item.enabled == 0 || status.disabled[item.code] || (!isPaymentDisplay
          ? true
          : false) || isDisabledPay
      "
      size="radio20"
      theme="icon"
      gap="0"
      class="payment-item__effiency-radio"
    />

    <img
      v-if="!isShowTokenPayment && !isShowTokenFrontInstallment"
      class="payment-src payment-src__img"
      :src="transformImg({ img: getPaymentLogoUrl })"
    />
    <TokenCardImg
      v-else
      :item="selectTokenCard"
      wrap-size="60px"
      class="payment-src"
    />

    <div
      v-expose="paypalExposeData(item)"
      class="payment-item__effiency-right"
      :class="[`j-item-right-${item.id}`, {
        'token-pay-item': isHadToken,
        'paypal-bnpl-item': item.code === 'PayPal-bnpl',
      }]"
    >
      <!-- 支付方式名 & bankList 列表展示 -->
      <div class="payment-extra-more">
        <div>
          <div>
            <!-- 支付方式名 -->
            <span
              :class="[
                'payment-des',
                {
                  'payment-disabled': item.enabled != 1 || !isPaymentDisplay || isDisabledPay,
                },
              ]"
            >
              <span
                v-if="item.code === 'afterpay-card' && afterpayVault && afterpayVault.title && afterpayVault.title === 'A' && !signupPayVault?.[item.code]?.useOneTimePay"
                v-html="language.SHEIN_KEY_PC_30642"
              ></span>
              <!-- <span v-else-if="item.code === 'dlocal-nupay'">{{ '' }}</span> -->
              <span v-else>{{ getPaymentTitle }}</span>
            </span>
            <!-- bankList 列表展示 -->
            <template
              v-if="
                !isDisabledPayment(item) &&
                  banksConfig[item.code] &&
                  banksConfig[item.code].list &&
                  banksConfig[item.code].list.length
              "
            >
              <div
                class="select-bank-wrap"
              >
                <span
                  v-if="!banksConfig[item.code].bankCodeText"
                  class="select-bank-text"
                  :class="{ 'txt-error': banksConfig[item.code].error }"
                >
                  {{ language.SHEIN_KEY_PC_16119 }}
                </span>
                <Icon
                  v-if="banksConfig[item.code].error"
                  tabindex="0"
                  name="sui_icon_more_down_14px"
                  color="#bf4123"
                  size="14px"
                />
                <Icon
                  v-if="!banksConfig[item.code].error"
                  tabindex="0"
                  name="sui_icon_more_down_14px"
                  color="#222222"
                  size="14px"
                />
              </div>
            </template>
            <!-- 支付方式描述desc -->
            <span
              v-if="
                (!paymentDescriptionAbt ||
                  (paymentDescriptionAbt && status.cardPay != item.code)) && !isShowTokenPayment
              "
            >
              <span
                v-if="!!paymentDesTips"
                :aria-label="paymentDesTips"
              ></span>
              <span
                v-if="
                  GOOGLEPAY_CODE.includes(item.code) &&
                    !paymentDesTips &&
                    !googleValid
                "
                :aria-label="language.SHEIN_KEY_PC_14428"
              ></span>
              <s-popover
                v-if="!!paymentDesTips"
                class="payment-item-tip"
                placemen="bottom"
                trigger="hover"
                :content="paymentDesTips"
              >
                <template #reference>
                  <Icon
                    name="sui_icon_doubt_16px_1"
                    color="#666666"
                    size="14px"
                  />
                </template>
              </s-popover>
              <s-popover
                v-if="
                  GOOGLEPAY_CODE.includes(item.code) &&
                    !paymentDesTips &&
                    !googleValid
                "
                class="payment-item-tip"
                placemen="bottom"
                trigger="hover"
                :content="language.SHEIN_KEY_PC_14428"
              >
                <template #reference>
                  <Icon
                    name="sui_icon_doubt_16px_1"
                    color="#666666"
                    size="14px"
                  />
                </template>
              </s-popover>
            </span>
  
            <!-- 0元门槛 -->
            <span
              v-if="
                item.enabled &&
                  mall_caculate_info &&
                  mall_caculate_info.onlinePayDiscountInfo &&
                  mall_caculate_info.onlinePayDiscountInfo.onlinePayDiscountValue &&
                  mall_caculate_info.onlinePayDiscountInfo.onlinePayDiscountValue[
                    item.code
                  ]
              "
              class="payment-save"
            >
            
              <s-label
                v-if="
                  +mall_caculate_info.onlinePayDiscountInfo
                    .onlinePayDiscountValue[item.code].min.amount == 0
                "
                type="promo"
                class="payment-discount-zero"
              >
                <template
                  v-if="
                    mall_caculate_info.onlinePayDiscountInfo
                      .onlinePayDiscountValue[item.code].discountType == 0
                  "
                >
                  {{
                    (GB_cssRight ? language.SHEIN_KEY_PC_16347 : '') +
                      ' ' +
                      mall_caculate_info.onlinePayDiscountInfo
                        .onlinePayDiscountValue[item.code].amount.amountWithSymbol +
                      ' ' +
                      (!GB_cssRight ? language.SHEIN_KEY_PC_16347 : '')
                  }}
                </template>
                <template
                  v-else-if="
                    mall_caculate_info.onlinePayDiscountInfo
                      .onlinePayDiscountValue[item.code].discountType == 1
                  "
                >
                  {{
                    (GB_cssRight ? language.SHEIN_KEY_PC_16347 : '') +
                      ' ' +
                      mall_caculate_info.onlinePayDiscountInfo
                        .onlinePayDiscountValue[item.code].percent +
                      '%' +
                      (!GB_cssRight ? language.SHEIN_KEY_PC_16347 : '')
                  }}
                </template>
              </s-label>
            </span>
  
            <!-- <div
              v-show="
                banksConfig[item.code] && banksConfig[item.code].chooseBank
              "
              class="ideal-unselect"
            >
              {{ language.SHEIN_KEY_PC_16119 }}
            </div> -->
  
            <!-- 支付方式是走内嵌且是paypalga时 -->
            <div
              v-if="showOneTimeSignArrow"
              class="ppgv-arrow-effiency"
              @click.stop="clickPaypalGaType(item)"
            >
              <Icon
                name="sui_icon_more_down_14px"
                color="#222222"
                size="14px"
              />
            </div>
          </div>
          <!-- 区域:分期支付信息展示 -->
          <!-- bnpl组件 -->
          <ItemInfoBnpl
            v-if="isShowItemInfoBnpl"
            class="extra-tip"
            scene="checkout"
            :is-select="cardValue"
            :options="installmentInfo.bnpl_info"
            :item="item"
            :front-list-items-value="frontListItemsValue"
            :language="language"
            @event="handleBnplEvent"
          >
            <!-- 分期文案描述逻辑，提供slot插入能力， -->
            <!-- 需求保持文案展示旧逻辑，故先不处理选中文案逻辑拆分 -->
          </ItemInfoBnpl>
        </div>
        <!-- 箭头icon -->
        <div
          v-show="isHadToken && !isPayMethodsDialog"
          class="left-drawer"
        >
          <span 
            v-if="isShowMoreDiscount"
            class="more-discount" 
          >
            {{ language.SHEIN_KEY_PC_30614 }}
          </span>

          <Icon
            v-if="isShowAllTokenList"
            name="sui_icon_more_up_14px"
            size="14px"
            @click.stop.prevent="hideTokenList"
          />
          <Icon
            v-else
            name="sui_icon_more_down_14px"
            size="14px"
            @click.stop.prevent="handlerTokenList"
          />
        </div>
      </div>
     
      <!-- 快捷选择paypal签约 -->
      <CheckPpgaType
        v-if="
          item.code === 'PayPal-GApaypal' &&
            !isDisabledPayment(item) &&
            paypalGaVault &&
            paypalGaVault.valid &&
            paypalGaVault.express &&
            (selectedPaypal || isAutoRenewal)
        "
        :paypal-ga-vault="paypalGaVault"
        :language="language"
        :checked-label="PPGA_CHECK_TYPE.CHECKED"
        @toggle="handleChangePaypalGaType"
      />
      
      <!-- 快捷选择afterpay签约 -->
      <!-- <CheckAfterpayType
        v-if="
          item.code === 'afterpay-card' &&
            !isDisabledPayment(item) &&
            afterpayVault &&
            afterpayVault.valid && 
            (selectedAfterpay || isAutoRenewal)
        "
        :afterpay-vault="afterpayVault"
        :language="language"
        :checked-label="AFTER_PAY_CHECK_TYPE.CHECKED"
        @toggle="handleChangeAfterpayType"
      /> -->
      <!-- 快速选择签约 -->
      <CheckSignupPayType
        v-if="!['PayPal-GApaypal'].includes(item.code) &&
          item.enabled &&
          signupPayVault?.[item.code]?.valid &&
          status?.cardPay == item?.code
        "
        :vault="signupPayVault?.[item.code]"
        :language="language"
        :payment-code="item.code"
        :checked-label="SIGNUP_CHECK_TYPE.CHECKED"
        @toggle="handleChangeSignupPayType"
      />
      <!-- 快捷选择dlocal_nupay签约 -->
      <!-- <CheckDlocalNupayType
        v-if="
          item.code === 'dlocal-nupay' &&
            !isDisabledPayment(item) &&
            dlocalNupayVault &&
            dlocalNupayVault.valid && 
            selectedDlocalNupay"
        :vault="dlocalNupayVault"
        :language="language"
        :checked-label="DLOCAL_NUPAY_CHECK_TYPE.CHECKED"
        @toggle="handleChangeDlocalNupayType"
      /> -->
      <!-- 在线支付优惠为“折扣”类型PaymentItem -->
      <p
        v-if="isShowAllDiscountBlock"
        class="discount-percent_tips dicount-flex-sy"
      >
        <client-only>
          <!-- 卡bin优惠券 -->
          <pay-bin-coupons
            v-if="isSupportBinCouponsPay"
            :card-bin="nowShowTokenPaymentCardBin"
            :is-pay-bin-coupons-add="isPayBinCouponsAdd"
            :is-show-token-payment="isCouponsShowTokenPayment"
            :language="language"
            :pay-code="item.code"
          />
          <!-- 二次下单页卡bin优惠券 -->
          <again-pay-bin-coupons
            v-if="['routepay-card', 'routepay-cardinstallment'].includes(item.code) && isAgainHadBinCoupons"
            :is-pay-bin-coupons-add="isPayBinCouponsAdd" 
            :language="language"
            :pay-code="item.code"
            :coupon-list="couponList"
            :order-info="orderInfo"
          />
        </client-only>
       
        <!-- token 卡 bin 优惠 -->
        <template v-if="isShowTokenBinDiscountBlock && !isNoShowCardBinRandomDiscount">
          <span class="discount-tips-item">
            {{ tokenBinDiscountDesc }}
          </span>
          <span
            v-if="isHadShowOnlinePayDiscount || isShowRandomDiscount"
            class="add-icon"
          >+</span>
        </template>

        <span
          v-if="isShowRandomDiscount"
          class="discount-tips-item"
        >
          <span>
            {{ randomDiscountDes(item) }}
          </span>
        </span>
        <template
          v-if="isHadShowOnlinePayDiscount"
        >
          <span
            v-if="
              getOnlinePayDiscountValue.min &&
                +getOnlinePayDiscountValue.min.amount != 0
            "
            class="discount-tips-item"
          >
            <!-- 满减优惠 -->
            <template
              v-if="
                getOnlinePayDiscountValue &&
                  getOnlinePayDiscountValue.discountType == 0
              "
            >
              {{
                template(
                  mall_caculate_info.onlinePayDiscountInfo
                    .onlinePayDiscountValue[item.code].min.amountWithSymbol,
                  mall_caculate_info.onlinePayDiscountInfo
                    .onlinePayDiscountValue[item.code].amount.amountWithSymbol,
                  language.SHEIN_KEY_PC_16461
                )
              }}
            </template>

            <!-- 折扣优惠 -->
            <template
              v-else-if="
                mall_caculate_info.onlinePayDiscountInfo.onlinePayDiscountValue[
                  item.code
                ].discountType == 1
              "
            >
              <span>
                {{
                  template(
                    mall_caculate_info.onlinePayDiscountInfo
                      .onlinePayDiscountValue[item.code].min.amountWithSymbol,
                    mall_caculate_info.onlinePayDiscountInfo
                      .onlinePayDiscountValue[item.code].percent + '%OFF',
                    language.SHEIN_KEY_PC_16461
                  )
                }}.
              </span>
              <span
                v-if="
                  mall_caculate_info.onlinePayDiscountInfo
                    .onlinePayDiscountValue[item.code].limit_value &&
                    +mall_caculate_info.onlinePayDiscountInfo
                      .onlinePayDiscountValue[item.code].limit_value.amount != 0
                "
              >
                {{
                  language.SHEIN_KEY_PC_20465.replace(
                    '{limitValue}',
                    mall_caculate_info.onlinePayDiscountInfo
                      .onlinePayDiscountValue[item.code].limit_value
                      .amountWithSymbol
                  )
                }}
              </span>
            </template>
          </span>
          <span
            v-else-if="
              getOnlinePayDiscountValue &&
                getOnlinePayDiscountValue.discountType == 1 &&
                getOnlinePayDiscountValue.limit_value &&
                +getOnlinePayDiscountValue.limit_value.amount != 0
            "
            class="discount-tips-item"
          >
            {{
              language.SHEIN_KEY_PC_20465.replace(
                '{limitValue}',
                mall_caculate_info.onlinePayDiscountInfo.onlinePayDiscountValue[
                  item.code
                ].limit_value.amountWithSymbol
              )
            }}
          </span>
        </template>

        <template v-if="!isShowTokenPayment && !isShowTokenFrontInstallment">
          <span v-if="isShowOnlinePayDiscount && isShowCardBinDiscount">+</span>
          <span
            v-if="isShowCardBinDiscount"
            class="discount-tips-item card-bin-discount-tips"
          >
            <span>{{ cardBinDiscountDesc }}</span>
            <Icon
              class="doubt-icon"
              name="sui_icon_doubt_16px_1"
              size="15px"
              color="#666666"
              @click.stop="handleCardBinDiscountTips"
            />
          </span>
        </template>
      </p>
      <!-- 签约支付term信息展示 -->
      <SignupTermInfo 
        v-if="cardValue" 
        :signup-account-info="signupAccountInfo"
        :payment-code="item.code"
        :language="language"
      />

      <!-- 区域:卡列表展示 -->
      <div
        v-if="item.card_logo_list && item.card_logo_list.length && !isShowTokenPayment"
        class="card-logo"
      >
        <ul>
          <li
            v-for="(img, i) in item.card_logo_list"
            :key="i"
            class="card-logo-item"
          >
            <img :src="transformImg({ img })" />
          </li>
        </ul>
      </div>
      <!-- 选中展示信息 -->
      <slide-up-down
        v-if="paymentDescriptionAbt && (calcDescription || calcLimitInfo || calcExplain)"
        :active="cardValue"
        :duration="500"
        :responsive="true"
        class="slide-up-down-out"
      >
        <div class="paytm-upi-input hint">
          <!-- <p
            v-show="item.code === 'dlocal-nupay'"
            class="content"
          >
            {{ item.enabled ? language.SHEIN_KEY_PC_32593 : '' }}
          </p> -->
          <p
            v-show="calcDescription"
            class="content"
          >
            {{ item.enabled ? item.description : item.gray_description }}
          </p>
          <p
            v-show="calcLimitInfo"
            class="content"
          >
            {{ payLimitTip }}
          </p>
          <!-- google文案 -->
          <p
            v-show="calcExplain"
            class="content"
          >
            {{ language.SHEIN_KEY_PC_14428 }}
          </p>
        </div>
      </slide-up-down>
      <div
        v-if="!isShowItemInfoBnpl"
        class="paypal-bnpl-checkout-message"
        data-pp-message
        data-pp-layout="text"
        data-pp-text-color="black"
        data-pp-logo-type="inline"
        :data-pp-amount="totalPriceAmount"
      >
      </div>
      <!-- 区域:PayInline -->
      <template v-if="needPayInlinePaymentMethods.indexOf(item.code) > -1">
        <div
          v-show="cardValue && showWidgetForm && showPayInlineInBnpl"
          :id="paymentsContainerID"
          class="pay-inline-payments-container"
        ></div>
      </template>
    </div>

    <TokenPayList 
      v-if="isShowTokenListBlock"
      :is-show-all-token-list="isShowAllTokenList" 
      :language="language"
      :pay-item="item"
      :is-show-discount-tips="isShowDiscountTips"
      :is-had-show-online-pay-discount="isHadShowOnlinePayDiscount"
      :is-show-random-discount="isShowRandomDiscount"
      :random-discount-des="randomDiscountDes"
      :get-online-pay-discount-value="getOnlinePayDiscountValue"
      :mall-caculate-info="mall_caculate_info"
      :is-show-token-payment="isCouponsShowTokenPayment"
      :is-show-online-pay-discount="isShowOnlinePayDiscount"
      :is-show-card-bin-discount="isShowCardBinDiscount"
      :card-bin-discount-desc="cardBinDiscountDesc"
      :payment-item="item"
      :card-bin-discount-info="cardBinDiscountInfo"
      :locals="locals"
      :is-support-bin="isSupportBin"
      :is-show-token-front-installment="isShowTokenFrontInstallment"
    />
  </div>
</template>

<script>
/* eslint-disable vue/prop-name-casing */
import {
  transformImg,
  template as _commonTemplate,
} from '@shein/common-function'
import ItemInfoBnpl from 'public/src/pages/checkout/components/payment_list/item_info_bnpl.vue'
import { payCardPrefetchFn } from 'public/src/pages/checkout/utils'
import slideUpDown from '@sheinfe/vue-slide-up-down'
import CheckPpgaType from '../components/CheckPpgaType.vue'
// import CheckAfterpayType from '../components/CheckAfterpayType.vue'
import CheckSignupPayType from '../components/CheckSignupPayType.vue'
import { PPGA_CHECK_TYPE, AFTER_PAY_CHECK_TYPE, SIGNUP_CHECK_TYPE } from 'public/src/pages/common/constants'
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import { SToast } from '@shein-aidc/sui-toast'
import { Icon } from '@shein-aidc/icon-vue3'
import SignupTermInfo from './SignupTermInfo.vue'
import TokenPayList from './tokenPayList.vue'
import { daEventCenter } from 'public/src/services/eventCenter'
import TokenCardImg from './TokenCardImg.vue'
import { useTokenBinDiscount } from './token_pay/useTokenBinDiscount.js'
import PayBinCoupons from 'public/src/pages/checkout/vue_tpls/coupons/pay_bin_coupons.vue'
import AgainPayBinCoupons from 'public/src/pages/checkout/vue_tpls/coupons/again_pay_bin_coupons.vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { exposeScenesAbtAggregator } from 'public/src/pages/checkout/hooks/EventAggregator.js'

// const { GB_cssRight } = gbCommonInfo

const isSSR = () => typeof window === 'undefined'

export default {
  name: 'PaymentItem',
  components: {
    slideUpDown,
    CheckPpgaType,
    Icon,
    SignupTermInfo,
    TokenPayList,
    TokenCardImg,
    // CheckAfterpayType,
    CheckSignupPayType,
    ItemInfoBnpl,
    PayBinCoupons,
    ClientOnly,
    AgainPayBinCoupons
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    isShowViewMore: {
      type: Boolean,
      default: false,
    },
    inx: {
      type: Number,
      default: 0,
    },
    checkout: {
      type: Object,
      default: function () {
        return {}
      },
    },
    status: {
      type: Object,
      default: function () {
        return {}
      },
    },
    isPaymentDisplay: {
      type: Boolean,
      default: false,
    },
    paypalGaVault: Object,
    afterpayVault: Object,
    signupPayVault: Object,
    ppgvAccountInfo: Object,
    afterpayAccountInfo: Object,
    signupAccountInfo: Object,
    language: {
      type: Object,
      default: function () {
        return {}
      },
    },
    banksConfig: {
      type: Object,
      default: function () {
        return {}
      },
    },
    paymentDescriptionAbt: {
      type: Boolean,
      default: false,
    },
    paymentDesTips: {
      type: String,
      default: '',
    },
    GOOGLEPAY_CODE: {
      type: [String, Array],
      default: '',
    },
    googleValid: {
      type: Boolean,
      default: false,
    },
    mall_caculate_info: {
      type: Object,
      default: () => {},
    },
    needPayInlinePaymentMethods: {
      type: Array,
      default: function () {
        return []
      },
    },
    installmentInfo: {
      type: Object,
      default: function () {
        return {}
      },
    },
    isShowDiscountTips: {
      // 是否展示折扣提示
      type: [Boolean, Number],
      default: false,
    },
    isShowOnlinePayDiscount: {
      type: [Boolean, Number],
      default: false,
    },
    getOnlinePayDiscountValue: {
      type: Object,
      default: () => {},
    },
    isShowCardBinDiscount: {
      type: [Boolean, Number],
      default: false,
    },
    cardBinDiscountDesc: {
      type: String,
      default: '',
    },
    payLimitTip: {
      type: String,
      default: '',
    },
    showWidgetForm: {
      type: Number,
      default: 0,
    },
    randomDiscountDes: {
      type: Function,
    },
    randomDiscountAbt: {
      type: Boolean,
      default: false,
    },
    handleStartSelectBank: {
      type: Function,
    },
    isPayMethodsDialog: {
      type: Boolean,
      default: false,
    },
    defaultSelectTokenPay: {
      type: Object,
      default: () => {}
    },
    isAutoRenewal: {
      type: Boolean,
      default: false
    },
    isDisabledPay: {
      type: Boolean,
      default: true
    },
    locals: Object,
    totalPriceAmount: {
      type: String,
      default: ''
    },
    paypalMessageShow: {
      type: Boolean,
      default: false
    },
    isAgainHadBinCoupons: {
      type: Boolean,
      default: false
    },
    couponList: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      PPGA_CHECK_TYPE,
      AFTER_PAY_CHECK_TYPE,
      SIGNUP_CHECK_TYPE,
      isShowAllTokenList: false, // 是否展示token列表 
      tokenBinDiscountDesc: '',
      showPayInlineInBnpl: true,
      resizeHandler: null,
      nowDiscountType: 0
    }
  },
  computed: {
    ...mapState(['usableCouponList', 'coupon', 'installmentSelectBin', 'installmentSelectToken']),
    ...mapGetters(['productAutoInfo', 'xtraAutoController']),

    paymentsContainerID() {
      if (this.isPayMethodsDialog) {
        return this.item.code + '-dialog-payments-container'
      }
      return this.item.code + '-payments-container'
    },

    selectedAfterpay() {
      return this?.item?.enabled && this?.status?.cardPay == this?.item?.code && this?.item?.code === 'afterpay-card'
    },

    selectedPaypal() {
      return this?.item?.enabled && this?.status?.cardPay == this?.item?.code && this?.item?.code === 'PayPal-GApaypal'
    },

    selectedDlocalNupay() {
      return this?.item?.enabled && this?.status?.cardPay == this?.item?.code && this?.item?.code === 'dlocal-nupay'
    },

    cardValue() {
      return !!(this?.item?.enabled && this?.status?.cardPay == this?.item?.code)
    },
    // 是否显示 ItemInfoBnpl
    isShowItemInfoBnpl() {
      if (this?.item?.code !== 'PayPal-bnpl') {
        return true
      }
      // PayPal-bnpl 且 PayPal-bnpl 未选中 才展示，选中时不展示
      // return !(this.cardValue && this?.status?.cardPay === 'PayPal-bnpl')
      return !this.paypalMessageShow
    },

    // abt 是否展示 token 支付
    isShowTokenPaymentByAbt() {
      return this.checkout?.isShowTokenPaymentByAbt
    },

    isShowTokenPayment() {
      // 后端字段是否展示 token 支付
      const isShowTokenPaymentByBackEnd = this.item?.code === 'routepay-card' && this.item?.shieldAddCard === 0 // 0代表b不屏蔽，则走abt的判断逻辑
      // 若token数据为空，则判断不符合token前置的场景，则无新增逻辑
      return isShowTokenPaymentByBackEnd && this.isShowTokenPaymentByAbt && (this.item?.card_token_list || [])?.length > 0 
    },

    isShowTokenFrontInstallment() {
      // 后端字段是否展示 token 支付
      const isShowTokenPaymentByBackEnd = this.item?.code === 'routepay-cardinstallment'

      // 若token数据为空，则判断不符合token前置的场景，则无新增逻辑
      return isShowTokenPaymentByBackEnd && this.checkout?.isShowTokenFrontInstallmentAbt && (this.item?.card_token_list || [])?.length > 0 
    },

    nowInstallmentTokenPayment() {
      if (!this.isShowTokenFrontInstallment || !this.item?.card_token_list) return {}
      return (this.item?.card_token_list || [])?.find(item => item.card_bin === (this?.$store && this?.installmentSelectBin))
    },

    nowShowTokenPayment() {
      return this.isShowTokenPayment && (this.item?.card_token_list || [])?.find(item => item.id === this.defaultSelectTokenPay?.id)
    },

    nowShowTokenPaymentCardBin() {
      if (this.isShowTokenFrontInstallment) return this.nowInstallmentTokenPayment?.card_bin
      return this.nowShowTokenPayment?.card_bin
    },

    // 是否展示右侧提示问号
    isShowCheckTips() {
      if (this.isShowTokenPayment) return false
      return !(this.status?.cardPay == this.item?.code)
    },

    getPaymentTitle() {
      const signupInfo = this.signupAccountInfo?.[this.item?.code]
      const signupVault = this.signupPayVault?.[this.item?.code]
      if (this?.item.code == 'PayPal-GApaypal' && this.paypalGaVault?.valid) {
        if (!this.isDisabledPayment(this?.item) && this.paypalGaVault?.express) {
          return this.language?.SHEIN_KEY_PC_26681
        } else if (this.paypalGaVault?.type == PPGA_CHECK_TYPE.CHECKED) {
          return (
            this.ppgvAccountInfo?.signUpEmail ||
            this.language?.SHEIN_KEY_PC_20468
          )
        }
      }

      if (this.item.code == 'afterpay-card' && this.afterpayVault?.title && !signupVault?.useOneTimePay) {
        if(this.afterpayVault?.title === 'A') {
          return this.language?.SHEIN_KEY_PC_30642
        } else if (this.afterpayVault?.title === 'B') {
          return this.language?.SHEIN_KEY_PC_30643
        }
      }

      if (this.item?.code == 'afterpay-cashapp' && signupInfo?.signUpEmail && !signupVault?.useOneTimePay) {
        return this.template(signupInfo.signUpEmail, this.language?.SHEIN_KEY_PC_32613)
      }

      if (this.item?.code == 'dlocal-nupay' && signupInfo?.id && !signupVault?.useOneTimePay) {
        return this.language?.SHEIN_KEY_PC_32637
      }

      if (this.item?.code == 'klarna-klarna' && signupInfo?.signUpPhoneNumber && !signupVault?.useOneTimePay) {
        return this.template(signupInfo.signUpPhoneNumber, this.language?.SHEIN_KEY_PC_32640)
      }

      if (this.isShowTokenPayment && this.nowShowTokenPayment) {
        return this.nowShowTokenPayment?.card_no
      }

      if (this.isShowTokenFrontInstallment && this.nowInstallmentTokenPayment) {
        return `${this.nowInstallmentTokenPayment?.card_no}${this.language.SHEIN_KEY_PC_31500}`
      }

      return (
        (this?.item?.bank_list?.length &&
          this.banksConfig?.[this?.item.code]?.bankCodeText) ||
        (this?.item.show_title && this?.item.title) ||
        ''
      )
    },
    getPaymentLogoUrl() {
      if (this.isDisabledPay) {
        return this?.item.gray_logo_url
      }
      if (this.isShowTokenPayment && this.nowShowTokenPayment) {
        return this.nowShowTokenPayment?.app_logo
      }

      if (this.isShowTokenFrontInstallment && this.nowInstallmentTokenPayment) {
        return this.nowInstallmentTokenPayment?.app_logo
      }

      if (!this.isDisabledPayment(this?.item)) {
        return this.banksConfig?.[this?.item.code]?.bankLogo || this?.item.logo_url
      }
      return this?.item.gray_logo_url
    },

    isRouterPayCard() {
      return this.item?.code === 'routepay-card'
    },

    isShowTokenListBlock() {
      return this.isRouterPayCard || this.item?.code === 'routepay-cardinstallment'
    },

    selectTokenCard() {
      if (this.isRouterPayCard) {
        return (this.checkout?.memberPreCardTokenInfo?.data?.info?.tokenList || [])?.find(item => item.id === this.defaultSelectTokenPay?.id) || {}
      }

      if (this.item?.code === 'routepay-cardinstallment') {
        return (this.checkout?.installmentMemberPreCardTokenInfo?.data?.info?.tokenList || [])?.find(item => item.id === (this?.$store && this.installmentSelectToken?.id)) || {}
      }

      return {}
    },

    radioModalValue() {
      return !!(
        this.item.enabled &&
          this.status.cardPay == this.item.code &&
          !this.status.disabled[this.item.code]
      )
    },
    cardBinDiscountInfo() {
      if(this?.$store?.state) { // 检查模块是否存在
        return this?.$store.state?.cardBinDiscountInfo
      }
      return {}
    },
    handlerSelectBin() {
      if(this?.$store?.state) { // 检查模块是否存在
        return this?.$store.state?.handlerSelectBin
      }
      return ''
    },
    tokenBinDiscountFrontWebAbt() {
      return this.checkout?.memberPreCardTokenInfo?.tokenBinDiscountFrontWebAbt
    },
    isShowRandomDiscount() {
      return this.getOnlinePayDiscountValue.discountType == 2 &&
              this.randomDiscountAbt &&
              this.item.enabled
    },
    isHadShowOnlinePayDiscount() {
      return this.isShowOnlinePayDiscount &&
              this.getOnlinePayDiscountValue.discountType != 2
    },

    isShowTokenBinDiscount() {
      if (!this.tokenBinDiscountFrontWebAbt) {
        return false
      }

      if (this.isShowTokenPayment && this.nowShowTokenPayment) {
        const { all_card_bin_discount_map = {} } = this.cardBinDiscountInfo || {}
        return (this.nowShowTokenPayment?.card_bin in all_card_bin_discount_map)
      }

      if (this.isShowTokenFrontInstallment && this.nowInstallmentTokenPayment) {
        const { all_card_bin_discount_map = {} } = this.cardBinDiscountInfo
        return (this.nowInstallmentTokenPayment?.card_bin in all_card_bin_discount_map)
      }

      return false
    },
    isShowMoreDiscount() {
      const { all_card_bin_discount_map = {} } = this.cardBinDiscountInfo || {}
      if (Object.keys(all_card_bin_discount_map).length === 0) {
        return false
      }

      const tempBinList = (this.item?.card_token_list || [])?.map(item => item?.card_bin) || []
      const allCardBinDiscountList = (Object.keys(all_card_bin_discount_map) || []).some(item => tempBinList.includes(item))
      if (!allCardBinDiscountList) {
        return false
      }

      const cardBin = this.item?.code === 'routepay-card' ? this.nowShowTokenPayment?.card_bin : (this?.$store && this?.installmentSelectBin)
      return !(cardBin in all_card_bin_discount_map)
    },

    isShowTokenBinDiscountBlock() {
      return this.isShowTokenBinDiscount && this.tokenBinDiscountDesc?.length > 0
    },


    calcDescription() {
      // 屏蔽klarna-klarna、dlocal-nupay签约时描述文案展示
      if (['klarna-klarna', 'dlocal-nupay'].includes(this.item.code) && this.signupPayVault?.[this.item.code]?.valid && this.item.enabled == 1) {
        return ''
      }
      return this.item.show_description && ((this.item.enabled && this.item.description) || (!this.item.enabled && this.item.gray_description))
    },
    calcLimitInfo() {
      return this.item.enabled == 0 && this.mall_caculate_info && this.mall_caculate_info.paymentMethodLimitInfo && +this.mall_caculate_info.paymentMethodLimitInfo[this.item.code] == 0
    },
    calcExplain() {
      return this.GOOGLEPAY_CODE.includes(this.item.code) && !( this.item.show_description && (this.item.description || this.item.gray_description)) && !this.googleValid
    },
    frontListItemsValue() {
      return this.installmentInfo?.front_list_items?.[this.item?.code] || {}
    },

    isPayBinCouponsAdd() {
      return this.isShowTokenBinDiscountBlock || this.isHadShowOnlinePayDiscount || this.isShowRandomDiscount
      || (
        !this.isShowTokenPayment && this.isShowCardBinDiscount && this.cardBinDiscountDesc
      )
    },
    GB_cssRight () {
      if (this.locals?.GB_cssRight) return this.locals.GB_cssRight
      if (isSSR()) return false
      return window.gbCommonInfo?.GB_cssRight
    },

    supportBinData() {
      if(this.isHadToken){
        const allCanUseUsableCouponList =
      this.$store && this.usableCouponList?.filter(item =>
        item?.card?.card_codes?.includes(this.nowShowTokenPaymentCardBin)
      ) || []

        const nowUseCoupon = allCanUseUsableCouponList?.find(item =>
          this.$store && this.coupon?.applyCoupons?.includes(item?.coupon)
        )

        // 已若卡bin优惠券券已被使用（计算价格使用了券码），则正常下发展示使用成功的卡bin优惠券信息
        if (nowUseCoupon) {
          return nowUseCoupon
        }

        // 若存在交集的券数量＞1，则优先取接口返回的第一个券
        return allCanUseUsableCouponList?.[0]
      }

      const nowUseCoupon = this.$store && this.usableCouponList?.find(item => {
        return (
          this.$store && this.coupon?.applyCoupons?.includes(item?.coupon) &&
      item?.card?.card_name
        )
      })
      if (nowUseCoupon) {
        return nowUseCoupon
      }
      // 非token支付情况下，选择第一个有cardName的券
      const curCoupon = this.$store && this.usableCouponList?.find(
        item => item?.card?.card_name
      )
      return curCoupon
    },
    isSupportBin() {
      return !!this.supportBinData
    },
    isHadToken() {
      return this.isShowTokenPayment || this.isShowTokenFrontInstallment
    },

    nowInstallmentSelectBin() {
      return this?.$store && this.installmentSelectBin
    },

    // 支付方式列表返回了卡bin优惠随机立减+计算价格返回了支付方式随机立减，只优先展示支付方式随机立减
    isNoShowCardBinRandomDiscount() {
      return this.nowDiscountType == 3 && this.isShowRandomDiscount
    },

    // 是否展示卡bin优惠券的支付方式
    isSupportBinCouponsPay() {
      return ['routepay-card', 'routepay-cardinstallment'].includes(this.item.code)
    },

    isShowAgainBinCouponsPay() {
      return ['routepay-card', 'routepay-cardinstallment'].includes(this.item.code) && this.isAgainHadBinCoupons
    },

    isShowAllDiscountBlock() {
      return (this.isShowDiscountTips || this.isSupportBin) && (this.isSupportBinCouponsPay
      || (this.isShowTokenBinDiscountBlock && !this.isNoShowCardBinRandomDiscount)
      || this.isShowRandomDiscount
      || this.isHadShowOnlinePayDiscount
      || this.isShowCardBinDiscount) || this.isShowAgainBinCouponsPay
    },

    isCouponsShowTokenPayment() {
      return this?.item?.code === 'routepay-cardinstallment' ? this.isShowTokenFrontInstallment : this.isShowTokenPayment
    },
    showOneTimeSignArrow() {
      if (this.item.code == 'PayPal-GApaypal') {
        return this.paypalGaVault &&
          this.paypalGaVault.valid &&
          this.item.enabled == 1 &&
          !this.status.disabled[this.item.code] &&
          this.isPaymentDisplay &&
          this.needPayInlinePaymentMethods.indexOf(this.item.code) > -1 &&
          this.item.code == 'PayPal-GApaypal' &&
          this.paypalGaVault.visibleArrow
      } else {
        return this.item.supportOneTimePay &&
          this.item.enabled == 1 &&
          this.isPaymentDisplay
      }
    },

    orderInfo() {
      return this.checkout?.order || {}
    }
  },
  watch: {
    cardValue: {
      immediate: true,
      handler(newValue){
        if (newValue === true) {
          payCardPrefetchFn(this?.item?.payment_type)
        }
      }
    },
    isAutoRenewal: {
      immediate: true,
      handler(val) {
        if (val) {
          this.afterpayVault.radioType = PPGA_CHECK_TYPE.CHECKED
          this.afterpayVault.type = PPGA_CHECK_TYPE.CHECKED
        }
      }
    },
    'item.card_token_list': {
      immediate: true,
      deep: true,
      handler(newValue){
        if (this?.item?.code === 'routepay-card') {
          const isHadDefaultSelectTokenPay = (this.item?.card_token_list || [])?.some(item => item.id === this.defaultSelectTokenPay?.id)
          if (Object.keys(this.defaultSelectTokenPay || {})?.length > 0 && isHadDefaultSelectTokenPay) return
          if (newValue) {
            const nowData = (this.item?.card_token_list || [])?.find(item => item?.default_token === 1) || []
            this.$emit('changeDefaultSelectTokenPay', nowData)
          }
        }

        if (this?.item?.code === 'routepay-cardinstallment') {
          const isHadDefaultSelectTokenPay = (this.item?.card_token_list || [])?.some(item => item.id === (this?.$store && this.installmentSelectToken?.id))
          if (Object.keys((this?.$store && this.installmentSelectToken) || {})?.length > 0 && isHadDefaultSelectTokenPay) return
          if (newValue) {
            const nowData = (this.item?.card_token_list || [])?.find(item => item?.default_token === 1) || {}
            this?.$store && this.assignState({
              installmentSelectToken: nowData,
              installmentSelectBin: nowData?.cardBin || nowData?.card_bin
            })
          }
        }
      }
    },

    'isShowTokenPayment': {
      handler(val) {
        if (typeof window === 'undefined') return
        if (this.item?.code === 'routepay-card') {
          if (isSSR()) return

          this?.$store && this.assignState({
            isShowTokenPay: val
          })

          exposeScenesAbtAggregator.addEvent({
            daId: '1-11-1-169',
            extraData: {
              scenes: 'front_token',
              type: val ? 1 : 0
            }
          })
        }
      },
      immediate: true
    },

    'status.cardPay': {
      handler(val) {
        if (val !== 'routepay-card') {
          this.hideTokenList()
        }
      }
    },

    isShowTokenBinDiscount: {
      handler(val) {
        if (val) {
          const cardBin = (this.item?.code === 'routepay-card') ? this.nowShowTokenPayment?.card_bin : (this?.$store && this?.installmentSelectBin)
          const { getCardBinDiscountDesc, type } = useTokenBinDiscount(this.cardBinDiscountInfo, cardBin, this.language)
          this.tokenBinDiscountDesc = getCardBinDiscountDesc()
          this.nowDiscountType = type
        }
      }
    },

    'nowShowTokenPayment.card_bin': {
      handler(val) {
        if (val) {
          const { getCardBinDiscountDesc, type } = useTokenBinDiscount(this.cardBinDiscountInfo, this.nowShowTokenPayment?.card_bin, this.language)
          this.tokenBinDiscountDesc = getCardBinDiscountDesc()
          this.nowDiscountType = type
        }
      }
    },

    'nowInstallmentSelectBin': {
      handler(val) {
        if (val && this?.$store) {
          const { getCardBinDiscountDesc } = useTokenBinDiscount(this.cardBinDiscountInfo, this?.installmentSelectBin, this.language)
          this.tokenBinDiscountDesc = getCardBinDiscountDesc()
        }
      }
    },


    isShowTokenBinDiscountBlock: {
      handler(val) {
        if (this.item?.code === 'routepay-card') {
          this?.$store && this.assignState({
            isShowTokenBinDiscountBlock: val,
            tokenBinDiscountType: this.nowDiscountType
          })
        }
        const changeData = val ? this.nowShowTokenPayment?.card_bin : ''
        this?.$store && this?.updateCreateOrderBin(changeData)
      },
      immediate: true
    },
    tokenBinDiscountDesc: {
      handler(val) {
        if (this.item?.code === 'routepay-card') {
          this?.$store && this.assignState({
            tokenBinDiscountDesc: val,
            tokenBinDiscountType: this.nowDiscountType
          })
        }
      },
      immediate: true
    },
    isShowTokenFrontInstallment: {
      handler(val) {
        if (this.item?.code === 'routepay-cardinstallment') {
          this?.$store && this.assignState({
            isShowTokenFrontInstallment: val
          })
        }
      },
      immediate: true
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.updateVerticalAlign()
    })
    this.resizeHandler = () => {
      this.updateVerticalAlign()
    }

    window.addEventListener('resize', this.resizeHandler)

    if (this?.item?.code === 'routepay-cardinstallment') {
      exposeScenesAbtAggregator.addEvent({
        daId: '1-11-1-169',
        extraData: {
          scenes: 'installmentToken',
          type: (this.item?.card_token_list || [])?.length > 0 ? 1 : 0
        }
      })
    }
  },
  updated() {
    if (isSSR()) return
    this.$nextTick(() => {
      this.updateVerticalAlign()
    })
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resizeHandler)
  },
  methods: {
    ...mapMutations(['updateCreateOrderBin', 'assignState']),
    ...mapActions(['handleUpdateIsShowTokenPay']),
    transformImg,
    template: _commonTemplate,
    clickPayment(item) {
      if (this.isDisabledPay) {
        this.$emit('handler-tips')
        return
      }
      if (item?.code === 'routepay-card' && this.isShowTokenPayment) {
        this?.$store?.commit('assignState', {
          handlerSelectBin: this.nowShowTokenPayment?.card_bin || ''
        })
      }
      this.$emit('clickPayment', item.id, item.code, item)
      if(!this.isDisabledPayment(item) && this.banksConfig[item.code] &&  this.banksConfig[item.code]?.list?.length){
        this.handleStartSelectBank(item.code)
      }
    },
    clickPaypalGaType(item) {
      this.$emit('clickPaypalGaType', item)
    },
    paytmupiInputBlur(code) {
      this.$emit('paytmupiInputBlur', code)
    },
    handleCardBinDiscountTips() {
      this.$emit('handleCardBinDiscountTips')
    },
    handleChangePaypalGaType() {
      // 1. 当前有续费包 2. 当前选中的是paypal
      const orderActivity = this?.checkout?.results?.primeActivityType == 1
      const { limitedPayTypeList: limitedPayTypeListPrime, currentProduct, currentProductCycleDays, caculateData } = this.productAutoInfo || {}
      const { currentXtraIsAuto, xtraCurrentAutoPayLimit } = this.xtraAutoController || {}
      const currentPrimeisAuto = currentProduct?.product_activity_type == 1 && currentProductCycleDays ? true : false
      // 1.成单提示 2.用户同时选择了会员续费产品包和超省卡续费产品包提示 3.区分付费会员或者超省卡提示 
      let tostLanguage = ''
      if(orderActivity){
        tostLanguage = this.language?.SHEIN_KEY_PC_31173 
      } else {
        if(currentXtraIsAuto && currentPrimeisAuto){
          tostLanguage = this.language.SHEIN_KEY_PC_31173
        } else {
          tostLanguage = currentXtraIsAuto ? this.language?.SHEIN_KEY_PC_31173 : this.template(currentProduct?.product_name_language, this.language?.SHEIN_KEY_PC_29646)
        }
      }
      const paymentLimitIncludes = limitedPayTypeListPrime?.includes(caculateData?.payment_code_unique) || xtraCurrentAutoPayLimit
      const currentIsAfterPay = caculateData?.payment_code_unique == 'PayPal-GApaypal'
      const isProdctAuto = currentPrimeisAuto || currentXtraIsAuto
      // 当前支付是afterpay-card && (paymentLimitIncludes && isProdctAuto)当前支付在快捷里且当前有产品包是自动续费 || orderActivity成单或者随单 && 选中状态
      if((currentIsAfterPay && paymentLimitIncludes && isProdctAuto) || orderActivity && this.paypalGaVault?.type == 1){
        return SToast( tostLanguage )
      }

      this.$emit('toggle-ppgv-type', this.paypalGaVault)
    },
    handleChangeAfterpayType() {
      // 1. 当前有续费包 2. 当前选中的是paypal
      const orderActivity = this?.checkout?.results?.primeActivityType == 1
      const { limitedPayTypeList: limitedPayTypeListPrime, currentProduct, currentProductCycleDays, caculateData } = this.productAutoInfo || {}
      const { currentXtraIsAuto, xtraCurrentAutoPayLimit } = this.xtraAutoController || {}
      const currentPrimeisAuto = currentProduct?.product_activity_type == 1 && currentProductCycleDays ? true : false
      // 1.成单提示 2.用户同时选择了会员续费产品包和超省卡续费产品包提示 3.区分付费会员或者超省卡提示 
      let tostLanguage = ''
      if(orderActivity){
        tostLanguage = this.language?.SHEIN_KEY_PC_31173 
      } else {
        if(currentXtraIsAuto && currentPrimeisAuto){
          tostLanguage = this.langauge.SHEIN_KEY_PC_31173
        } else {
          tostLanguage = currentXtraIsAuto ? this.language?.SHEIN_KEY_PC_31173 : this.template(currentProduct?.product_name_language, this.language?.SHEIN_KEY_PC_29646)
        }
      }
      const paymentLimitIncludes = limitedPayTypeListPrime?.includes(caculateData?.payment_code_unique) || xtraCurrentAutoPayLimit
      const currentIsAfterPay = caculateData?.payment_code_unique == 'afterpay-card'
      const isProdctAuto = currentPrimeisAuto || currentXtraIsAuto
      // 当前支付是afterpay-card && (paymentLimitIncludes && isProdctAuto)当前支付在快捷里且当前有产品包是自动续费 || orderActivity成单或者随单 && 选中状态
      if((currentIsAfterPay && paymentLimitIncludes && isProdctAuto) || orderActivity && this.afterpayVault?.type == 1){
        return SToast( tostLanguage )
      }
      this.$emit('toggle-afterpay-type', this.afterpayVault)
    },
    handleChangeSignupPayType(paymentCode) {
      this.$emit('toggle-signup-pay-type', paymentCode)
    },
    isDisabledPayment(item) {
      return (
        item.enabled == 0 ||
        this.status?.disabled?.[item.code] ||
        !this.isPaymentDisplay
      )
    },
    paypalExposeData(paymentItem = {}) {
      if (paymentItem?.code !== 'PayPal-GApaypal') return {}
      return {
        id: '2-31-8',
        data: {
          payment_method: paymentItem.code,
          is_paypal: this.paypalGaVault?.valid ? '1' : '0',
          is_vaulting_account: !!this.ppgvAccountInfo?.signUpEmail ? '1' : '0',
          is_vaulting_status: this.paypalGaVault?.valid ? '1' : '0',
        },
      }
    },

    handlerTokenList() {
      if (this.item.enabled === 0) return
      this.isShowAllTokenList = true
    },

    hideTokenList() {
      this.isShowAllTokenList = false
    },
    handleBnplEvent(evt){
      switch(evt.type){
        case 'changePayInLine':
          this.showPayInlineInBnpl = evt.data
          break
        default:
          break
      }
    },
    updateVerticalAlign() {
      const element = document.querySelector(`.j-item-right-${this.item.id}`)
      if (element) {
        const itemRightHeight = element.offsetHeight
        const alignStyle = itemRightHeight >= 40 ? 'top' : 'middle'
        element.style.verticalAlign = alignStyle
      }
    }
  },
  emits: [
    'clickPayment',
    'clickPaypalGaType',
    'handleCardBinDiscountTips',
    'toggle-ppgv-type',
    'changeDefaultSelectTokenPay',
    'paytmupiInputBlur',
    'toggle-afterpay-type',
    'handler-tips',
    'toggle-signup-pay-type'
  ],
}
</script>
<style lang="less">
  /* stylelint-disable selector-class-pattern, declaration-no-important, selector-max-specificity, selector-max-type  */

  .payment-item{
		position: relative;
		cursor: pointer;
		font-size: 0;
		border: 1px solid transparent;
		label{
			vertical-align: middle;
		}
		.payment-extra-more {
			display: inline-block;
			vertical-align: middle;
      .select-bank-wrap {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
      }
			.extra-tip {
				font-size: 12px;
				display: block;
				color: #666;
				padding-top: 6px;
				a {
					display: inline-block;
					text-decoration: none;
					vertical-align: middle;
					color: #999;
				}
			}
		}
		.discount-percent_tips {
			font-size: 12px;
			line-height: 14px;
		}

		.payment-src{
			width: 60px;
			vertical-align: middle;
		}
		.payment-des{
			font-size: 14px;
			vertical-align: middle;
		}
		.payment-disabled {
			opacity: 0.3;
		}

		.payment-item-tip{
			font-size: 12px;
			vertical-align: middle;
		}
		&.payment-ideal{
			.ideal-unselect{
				padding-left: 12px;
				margin-top: 3px;
				font-size: 12px;
				color: @sui_color_unusual;
			}
		}
		.card-logo{
			.card-logo-item{
				width: 36px;
				height: 24px;
				display: inline-block;
				.margin-r(10px);
				img{
					width: 100%;
					height: 100%;
				}
			}
		}
		.pay-inline-payments-container {
      .margin-l(0);
      margin-top: 4px;
    }

    .more-discount {
      color: #767676;
      font-size: 14px;
      font-family: SF UI Text;
      font-weight: 400;
      word-wrap: break-word;
      margin-right: 8px;
    }

    .add-icon {
      margin: 0 4px;
    }

    .paypal-bnpl-item {
      width: 100%;
    }

    .paypal-bnpl-checkout-message {
      margin-top: 8px;
    }
	}
</style>
