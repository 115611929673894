// 时间码
// shein siteuid prefix
function sh(sites) {
  let result: string[] = []
  sites?.forEach(site => {
    result = [...result, `iossh${site}`, `andsh${site}`]
  })
  return result
}
export const MESSAGE_DATE_PC = {
  'A-1': ['ar', 'tw', 'jp', 'rwar'],
  'A-2': ['www', 'us', 'il', 'asia', 'rw', 'rwus'],
  'A-3': ['de', 'fr', 'es', 'it', 'ca', 'se', 'mx', 'th', 'eur', 'nl', 'au', 'ma', 'br', 'sg', 'nz', 'pl', 'vn', 'rwde', 'rwfr', 'rwes', 'rwit', 'rwca', 'rwmx', 'rwau'],
  'A-4': ['uk', 'ru', 'in', 'hk', 'za', 'cl', 'rwuk', 'rwin'],
}

export const MESSAGE_DATE_IOS = {
  'A-1': sh(['sa', 'bh', 'om', 'kw', 'ae', 'qa', 'tw', 'jp']),
  'A-2': sh(['other', 'us', 'il', 'asia']),
  'A-3': sh(['de', 'fr', 'es', 'it', 'ca', 'se', 'mx', 'th', 'eur', 'nl', 'au', 'ma', 'br', 'sg', 'nz', 'pl', 'vn']),
  'A-4': sh(['gb', 'ru', 'in', 'hk', 'za', 'cl']),
}

export const MESSAGE_DATE_PWA = {
  'A-1': ['pwar', 'pwaren', 'mtw', 'mjp', 'rwar'],
  'A-2': ['m', 'pwus', 'mil', 'masia'],
  'A-3': ['pwde', 'pwfr', 'pwes', 'pwit', 'mca', 'mse', 'pwmx', 'mth', 'meur', 'pwnl', 'pwau', 'pwma', 'mbr', 'msg', 'mnz', 'mpl', 'mvn', 'rwde', 'rwfr', 'rwes', 'rwit', 'rwca', 'rwmx', 'rwau'],
  'A-4': ['pwuk', 'mru', 'pwin', 'mhk', 'mza', 'mcl', 'rwuk', 'rwin'],
}
