<template>
  <div class="gift-item">
    <img
      :src="baseCutImg"
      alt="gift"
      class="gift-item-img"
    />
    <div
      class="gift-item-pick"
      @click="handleOpenQuickAdd"
    >
      +{{ language.SHEIN_KEY_PC_15968 }}
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
let QuickViewPopover = null
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    promotionId: {
      type: [String, Number],
      require: true,
      default: ''
    },
    promotionType: {
      type: [String, Number],
      require: true,
      default: ''
    },
  },
  computed: {
    ...mapState(['language']),
    baseCutImg() {
      // 没有cutImg方法或者使用原图时，不裁剪  
      let url = this.item.goods_img
      if (!this?.cutImg) {
        return url?.replace(/^https?\:/, '')?.replace(/\b(\.jpg|\.png)\b/, '.webp')
      }
      return this.cutImg(url, 0, {})
    },
  },
  mounted() {
    this.loadQuickView()
  },
  inject: {
    cutImg: {
      from: 'cutImg',
      default: (url) => url,
    },
  },
  methods: {
    ...mapActions(['fetchCartIndex']),
    async handleOpenQuickAdd() {
      await this.loadQuickView()
      QuickViewPopover.show({
        goodsId: this.item.goods_id,
        mallCode: this.item.mall_code,
        lockmall: true,
        extendQvData: {
          salePrice: this.item.sale_price
        },
        extendForm: {
          promotion_id: this.promotionId,
          promotion_type: this.promotionType
        },
        config: {
          hidePromotionInfo: true,
          hideViewFullDetail: true,
          hideWishlistBtn: true,
          hideColorGroup: true,
          promotion_id: this.promotionId,
          promotion_type: 4,
          // 不展示一键购
          showOneClickPay: false,
        },
        callback: {
          handleAddToCartDone: (res) => {
            if (res.code == 0) {
              this.$message({
                message: this.language.SHEIN_KEY_PC_17621,
                type: 'success'
              })
              this.fetchCartIndex()
            }
          },
        },
      })
    },
    loadQuickView(){
      return new Promise((resolve) => {
        if(QuickViewPopover) {
          resolve()
        } else {
          import('public/src/pages/common/quickView').then(_ => {
            QuickViewPopover = _.default
            resolve()
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.gift-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 68px;
  height: 96px;
  margin-right: 6px;
  /* stylelint-disable-next-line selector-nested-pattern */
  &-img {
    width: 68px;
    height: 68px;
  }
  /* stylelint-disable-next-line selector-nested-pattern */
  &-pick {
    width: 68px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    background-color: #fff;
    font-size: 12px;
    cursor: pointer;
  }
}
</style>
