import schttp from 'public/src/services/schttp'
import newBffUtils from './utils'

const { currency, appLanguage } =
  typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

let versionV1 = ['payment_v1']

export const fetchCardBindPreRouting = async data => {
  const isNewBff = newBffUtils.checkNewBff(versionV1)
  if (isNewBff) {
    const res = await schttp({
      method: 'POST',
      url: `/pay/payPre/cardBindPreRouting`,
      headers: {
        AppCurrency: currency,
        AppLanguage: appLanguage
      },
      params: data,
      useBffApi: true
    })
    return {
      ...res,
      info: {
        ...res?.info,
        countryCode: data.countryCode,
      }
    }
  } else {
    return await schttp({
      method: 'POST',
      url: '/api/checkout/cardBindPreRouting/query',
      data,
    })
  }
}

export const deletePaymentToken = async data => {
  const isNewBff = newBffUtils.checkNewBff(versionV1)
  if (isNewBff) {
    return await schttp({
      method: 'POST',
      url: `/pay/del_payment_sign_up`,
      headers: {
        AppCurrency: currency,
        AppLanguage: appLanguage
      },
      params: data,
      useBffApi: true
    })
  } else {
    return await schttp({
      method: 'POST',
      url: '/api/user/payment/channel/delete',
      data,
    })
  }
}

export const deletePaymentSignUp = async data => {
  const isNewBff = newBffUtils.checkNewBff(versionV1)
  if (isNewBff) {
    return await schttp({
      method: 'POST',
      url: `/pay/del_worldpay_token`,
      headers: {
        AppCurrency: currency,
        AppLanguage: appLanguage
      },
      params: data,
      useBffApi: true
    })
  } else {
    return await schttp({
      method: 'GET',
      url: '/api/user/payment/delete',
      params: data,
    })
  }
}

export const queryAutoBindCardInfo = async data => {
  const res = await schttp({
    method: 'POST',
    url: '/pay/queryAutoBindCardInfo',
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage
    },
    data,
    useBffApi: true
  })
  return {
    ...res,
    ...res?.info
  }
}

export const fetchPaymentToken = async data => {
  const isNewBff = newBffUtils.checkNewBff(versionV1)
  if (isNewBff) {
    const res = await schttp({
      method: 'GET',
      url: `/pay/get_world_pay_token`,
      headers: {
        AppCurrency: currency,
        AppLanguage: appLanguage
      },
      params: data,
      useBffApi: true
    })
    return {
      ...res,
      ...res?.info,
    }
  } else {
    return await schttp({
      url: '/api/user/payment/get',
      params: data,
    })
  }
}
