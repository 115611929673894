<template>
  <div
    v-if="usefulLabels.length"
    class="real-time-labels"
  >
    <div
      class="real-time-swiper"
    >
      <div
        class="label-with-swiper"
        :style="{'animation': animationStyle}"
      >
        <div
          v-for="(item, index) in usefulLabels"
          :key="index"
          v-expose="index == usefulLabels.length - 1 ? {} : getAnalysis(item)"
          :index="index"
          class="swiper-content"
        >
          <img
            :src="item.icon"
            width="16"
            height="16"
          />
          <span
            class="label-text"
            v-html="item.tagName"
          >
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup name="RealTimeLabels">
import { ref, onMounted, onBeforeUnmount, watch, nextTick, computed } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { buildKeyFramesCSS } from 'public/src/pages/cart_v2/utils/buildKeyFramesCSS.js'

let props = defineProps({
  labels: {
    type: Array,
    default: () => ([]),
  },
})

let buildAnimationStyle = ref('')
let exposeIds = []
// 实际展示的标签
const usefulLabels = computed(() =>{
  let usefulLabelList = props.labels.filter((item) => item.actionDataTagShow == 1 && item.hasAvailableTag == 1 && item.tagName) || []
  return usefulLabelList.length > 1 ? [...usefulLabelList, usefulLabelList[0]] : usefulLabelList
})

const animationStyle = computed(() => {
  return usefulLabels.value.length > 1 ? buildAnimationStyle.value : ''
})

watch(() => usefulLabels.value, (v) => {
  if(v.length === 0) return
  initSwiper()
})

onMounted(() => {
  nextTick(() => {
    initSwiper()
    // 上报实时标签
    window.addEventListener('beforeunload', () => {
      // 配置了abt则用实际配置的标签，否则使用全部标签
      const reportArr = !!usefulLabels.value.length ? usefulLabels.value : props.labels
      if (!reportArr.length) return
      daEventCenter.triggerNotice({
        daId: '1-7-3-48',
        extraData: {
          userbehavior_tips: Array.from(new Set(reportArr.map((v) => v.tagId))).join(','),
          userbehavior_tips_show: exposeIds.join(','),
        }
      })
    })
  })
})
onBeforeUnmount(() => {
  destorySwiper()
})

const destorySwiper = () => {
  buildAnimationStyle.value = ''
}
const initSwiper = () => {
  if (buildAnimationStyle.value) {
    buildAnimationStyle.value = ''
  }
  if(usefulLabels.value.length < 2) {
    buildAnimationStyle.value = ''
    return
  }
  buildAnimationStyle.value = buildKeyFramesCSS(false, (usefulLabels.value.length - 1), .2, 1.3)
}

const getAnalysis = (item) => {
  return {
    id: '1-8-1-10000000', // 假id
    prefix: 'realtime_behavior_lable_prefix', // 用于重置埋点
    callback: () => {
      onExpose({ item })
    }
  }
}
// 埋点处理
const onExpose = ({ item }) =>{
  if (!item?.tagId || exposeIds.includes(item?.tagId)) return
  exposeIds.push(item.tagId)
}
</script>

<style lang="less" scoped>
.real-time-labels {
  height: 24px;
  min-width: 100px;
  border-radius: 12px;
  padding: 4px 8px;
  background: @sui_color_micro_emphasis_bg;
}
.real-time-swiper {
  margin: 0;
  width: auto;
  min-width: 120px;
  height: 18px;
  overflow: hidden;
  contain: layout;
}
.label-with-swiper {
  scroll-snap-type: y mandatory;
  overscroll-behavior: none;
  scroll-behavior: smooth;
  display: inline-flex;
  flex-direction: column;
  will-change: transform;
  .swiper-content {
    overflow: hidden;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: 18px;
    .label-text {
      margin-left: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      /* rtl:begin:ignore */
      direction: ltr;
    }
  }
}
</style>
