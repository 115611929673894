<template>
  <div
    v-expose="getContainerExposeData()"
    class="aside-module__wrapper"
  >
    <div class="aside-module__header">
      <div class="aside-module__title">
        <span
          class="aside-module__title-text"
          role="heading"
          tabindex="0"
        >
          {{ title }}
        </span>
        <div
          v-if="label"
          class="aside-module__title-label"
        >
          {{ label }}
        </div>
      </div>
      <div
        v-enterkey
        v-tap="getMoreClickData()"
        class="aside-module__more"
        role="link"
        tabindex="0"
        @click="emits('click-more')"
      >
        <span>{{ moreText }}</span>
        <sui_icon_more_right_12px
          class="aside-module__more-icon"
          size="12px"
          :is-rotate="constantData.GB_cssRight"
        />
      </div>
    </div>
    <div
      v-if="data.length"
      class="aside-module__content"
    >
      <swiper-container
        ref="swiperRef"
        init="false"
        pagination="true"
        class="aside-module__swiper-container"
        :dir="constantData.GB_cssRight ? 'rtl' : 'ltr'"
      >
        <swiper-slide
          v-for="(item, index) in data"
          :key="item.goodsImage"
          v-expose="getItemExposeData(item.originData, index)"
          v-tap="getItemClickData(item.originData, index)"
          class="aside-module__swiper-slide"
          @click="
            emits('click-item', { data: item.originData, index, activeIndex })
          "
        >
          <div
            class="aside-module__goods-item"
            :data-content-carrier-id="item.originData.contentCarrierId"
            :data-index="index"
            :data-active-index="activeIndex"
          >
            <img
              :src="item.goodsImage"
              class="aside-module__goods-item-img"
              alt=""
            />
            <div
              v-if="item.title"
              class="aside-module__goods-item-title"
            >
              {{ item.title }}
            </div>
          </div>
          <!-- 价格本期隐藏，误删 -->
          <!-- <div class="aside-module__goods-item-price">
            <ProductCardPriceSimple
              :goods-info="item.product"
              :language="language"
              :config="{
                hidePriceBottomLabel: true,
                noCamelCasePrice: true
              }"
            />
          </div> -->
        </swiper-slide>
      </swiper-container>
      <div
        v-if="showRecBelt"
        class="aside-module__recommend-belt"
      >
        {{ language.SHEIN_KEY_PC_25105 || 'Recommended' }}
      </div>
      <template v-if="data.length > 3">
        <div
          ref="prevBtnRef"
          class="aside-module__prev-btn"
        >
          <Icon
            size="16px"
            name="sui_icon_more_left_12px_1"
            :is-rotate="constantData.GB_cssRight"
          />
        </div>
        <div
          ref="nextBtnRef"
          class="aside-module__next-btn"
        >
          <Icon
            color="#222"
            size="16px"
            name="sui_icon_more_right_12px_1"
            :is-rotate="constantData.GB_cssRight"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { Pagination, Navigation } from 'swiper/modules'
import { register } from 'swiper/element'
import { ref, nextTick, computed, watch } from 'vue'
// import ProductCardPriceSimple from 'public/src/pages/components/productItemV3/components/ProductCardPrice/ProductCardPriceSimple.vue'
import { Icon, sui_icon_more_right_12px } from '@shein-aidc/icon-vue3'
import { useCommonState } from '../../utils/useCommonState'

typeof window !== 'undefined' && register()
const props = defineProps({
  data: {
    type: Array,
    default: () => []
  },
  title: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  // 展示 0 items
  showZeroItems: {
    type: Boolean,
    default: true
  },
  showRecBelt: {
    type: Boolean,
    default: false
  },
  /** 埋点 start */
  getContainerExposeData: {
    type: Function,
    default: () => ({})
  },
  getMoreClickData: {
    type: Function,
    default: () => ({})
  },
  getItemExposeData: {
    type: Function,
    default: () => ({})
  },
  getItemClickData: {
    type: Function,
    default: () => ({})
  }
  /** 埋点 end */
})
const emits = defineEmits(['click-item', 'click-more'])

const { language, constantData } = useCommonState()

const moreText = computed(() => {
  if (!props.data.length && props.showZeroItems) {
    return `0 ${language.value.SHEIN_KEY_PC_29598}`
  }
  return language.value.SHEIN_KEY_PC_15663
})

const swiperRef = ref()
const prevBtnRef = ref()
const nextBtnRef = ref()
const activeIndex = ref(0)
const initSwiper = async () => {
  await nextTick(() => {})
  if (!swiperRef.value) return
  const options = {
    injectStylesUrls: [
      `${constantData.value.PUBLIC_CDN}/she_dist/libs/swiper/modules/navigation-element.min.css`,
      `${constantData.value.PUBLIC_CDN}/she_dist/libs/swiper/modules/pagination-element.min.css`
    ],
    injectStyles: [
      `
          .swiper-pagination {
            --swiper-pagination-bottom: 2px;
            text-align: center;
          }
          .swiper-pagination-bullet {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #ccc;
          }
          .swiper-pagination-bullet-active {
            background: #222;
          }
          .swiper-button-next,
          .swiper-button-prev {
            display: none;
          }
        `
    ],
    on: {
      slideChange: swiper => {
        activeIndex.value = swiper.activeIndex
      }
    },
    modules: [Navigation, Pagination],
    navigation: {
      enabled: props.data.length > 1,
      prevEl: prevBtnRef.value,
      nextEl: nextBtnRef.value
    },
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 5,
    uniqueNavElements: false,
    observer: true // 修改swiper自己或子元素时，自动初始化swiper
  }
  Object.assign(swiperRef.value, options)
  swiperRef.value.initialize()
}

watch(
  () => props.data,
  val => {
    if (!val?.length) return
    initSwiper()
  },
  { immediate: true }
)
</script>

<style lang="less" scoped>
.aside-module {
  &__wrapper {
    position: relative;
    margin-top: 10px;
    background: #fff;

    &:hover {
      .aside-module__next-btn,
      .aside-module__prev-btn {
        display: flex;
      }
    }

    .swiper-button-disabled {
      opacity: 0.35;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
  }

  &__title {
    flex: 1;
    display: flex;
    align-items: center;
    min-width: 0;
  }

  &__title-text {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: bold;
  }
  &__title-label {
    display: flex;
    align-items: center;
    padding: 1px 2px;
    margin-left: 6px;
    background: #ff5454;
    color: #fff;
    font-size: 9px;
  }

  &__more {
    display: flex;
    align-items: center;
    color: #666;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    cursor: pointer;

    &:hover {
      color: #222;
    }
  }
  &__content {
    position: relative;
    padding: 0 36px 4px;
    cursor: pointer;
  }
  &__swiper-container {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    height: 110px;
  }

  &__swiper-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    margin-right: 5px;
  }

  &__next-btn,
  &__prev-btn {
    position: absolute;
    top: calc(50% - 10px);
    align-items: center;
    display: none;
  }
  &__next-btn {
    right: 10px /* rtl: unset */;
    left: unset /* rtl: 10px */;
  }
  &__prev-btn {
    left: 10px /* rtl: unset */;
    right: unset /* rtl: 10px */;
  }

  &__goods-item {
    position: relative;
    width: 68px;
  }
  &__goods-item-img {
    width: 100%;
    height: auto;
  }
  &__goods-item-title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 2px;
    height: 16px;
    color: #fff;
    text-align: center;
    font-size: 11px;
    background: rgba(0, 0, 0, 0.6);
    line-height: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__goods-item-price {
    margin-top: 4px;
    color: var(---sui_color_gray_dark1, #222);
    font-family: 'SF UI Text';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &__recommend-belt {
    position: absolute;
    top: 70px;
    left: 26px;
    width: calc(100% - 52px);
    height: 20px;
    line-height: 20px;
    z-index: 1;
    text-align: center;
    font-weight: 500;
    color: #fff;
    background: rgba(44, 44, 44, 0.58);
  }
}
</style>
