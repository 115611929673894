
import {
  SEARCH_SOURCE_ENUM,
  ORIGIN_QUERY_FILTER_KEYS
} from 'public/src/pages/common/search_words/const'

export const isSearch = (type) => type === 'search' // 搜索(全站，店铺，专题)
export const isAllSearch = (type, query) => isSearch(type) && !isStoreSearch(type, query) && !isBrandSearch(type, query) && !isTopTrendResultPage(type, query) // 全站搜索
export const isStoreSearch = (type, query) => isSearch(type) && query.search_type === 'store' // 店铺搜索
export const isBrandSearch = (type, query) => isSearch(type) && query.search_type === 'brand' // 专题搜索
export const isTopTrendResultPage = (type, query) => isSearch(type) && query.search_type === 'pageTrend' // v.趋势搜索结果页屏蔽该功能

/**
 * 商品接口是否传adp
 * 图文筛选 且 该图文有goods_id，则传该goodsId
 * 存在筛选条件，则传空
 * 无筛选条件，且则传默认query上adp
 * @param {*} query
 */
export const getGoodsAdp = query => {
  if (query.pic_nav_adp) return query.pic_nav_adp
  const hasFilter = [
    'child_cat_id',
    'attr_ids',
    'brand_ids',
    'tag_ids',
    'min_price',
    'max_price',
    'pic_nav_id',
    'tsp_ids',
  ].some(key => query[key] || query[key] === 0)

  return hasFilter ? '' : query.adp || ''
}


export const fixSearchSource = (params) => {
  const { pic_nav_id = '', search_source = '' } = params
  let fix = ''
  // 图文导航
  if (pic_nav_id) {
    fix = SEARCH_SOURCE_ENUM.IMG_NAV
  }
  const hasFilter = ORIGIN_QUERY_FILTER_KEYS.some(key => params[key])
  if (hasFilter) {
    fix = SEARCH_SOURCE_ENUM.USER_INPUT
  }
  return fix || search_source
}

// 清除第一层对象中的空值
export const cleanNullValueObjectFirstLevel = (params) => {
  if (typeof params !== 'object' || params === null) {
    return params
  }

  const cleanObj = {}

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const value = params[key]

      // 仅在值不为undefined, null或空字符串时赋值
      if (value !== undefined && value !== null && value !== '') {
        cleanObj[key] = value
      }
    }
  }

  return cleanObj
}
