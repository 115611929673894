<template>
  <s-dialog
    v-model:visible="modalOptions.show"
  >
    <template #title>
      {{ modalOptions.titleText }}
    </template>
    <template #footer>
      <s-button-group hor>
        <s-button-group-item @click="cancelCallback">
          {{ modalOptions.cancelText || language.SHEIN_KEY_PC_15738 }}
        </s-button-group-item>
        <s-button-group-item
          :type="['primary']"
          @click="okCallback"
        >
          {{ modalOptions.okText || language.SHEIN_KEY_PC_15737 }}
        </s-button-group-item>
      </s-button-group>
    </template>
  </s-dialog>
</template>
<script>
export default {
  name: 'CartCommonModalTip',
}
</script>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'

const { state } = useStore()
const modalOptions = computed(() => {
  return state.modalOptions
})
const language = computed(() => {
  return state.language
})

const cancelCallback = () => {
  state.modalOptions.show = false
  state.modalOptions.cancelCallback?.()
}

const okCallback = () => {
  state.modalOptions.show = false
  state.modalOptions.okCallback?.()
}
</script>
