<template>
  <component
    :is="renderComponent"
    ref="componentRef"
    v-bind="{
      ...props,
      scrollWrapClass,
      scrollItemClass,
    }"
    @goScroll="goScroll"
    @custom-event="(v) => emits('custom-event', v)"
  >
    <template #footer>
      <slot name="footer"></slot>
    </template>
  </component>
</template>

<script setup lang="ts">
import { ref, provide, computed, onMounted, nextTick } from 'vue'
import DrawerCheckout from './components/DrawerCheckout.vue'
import DrawerPersonalCenter from './components/DrawerPersonalCenter.vue'
import { E_SCENE, type C_GiftCard } from '../../types'
import useApis from '../../hooks/useApis'
import { getMultLangMap } from '../../common/language'
import { useAnalysis } from '../../common/analysisSource'

const { getLangApi } = useApis()
const analysisInstance = useAnalysis()

provide('analysisInstance', analysisInstance)

const props = withDefaults(defineProps<{
  scene: `${E_SCENE}`,
  drawerProps: Record<string, any>
  cardData: C_GiftCard.CardData
  extraData: Record<string, string>
}>(), {})

const emits = defineEmits(['custom-event'])

onMounted(() => {
  const langMap = getMultLangMap({
    platform: props.scene === E_SCENE.Checkout ? 'PC_C' : 'PC_P',
  })
  if (langMap) {
    getLangApi(langMap).then(res => {
      _language.value = res
    })
  }
})

const _language = ref()
const componentRef = ref()
const scrollWrapClass = ref('bsc-gc-card-scroll-wrap')
const scrollItemClass = ref('bsc-gc-card-scroll')

const getCardData = computed(() => {
  return props.cardData
})
const getLanguage = computed(() => _language.value || {})
const getScene = computed(() => props.scene)

const renderComponent = computed(() => {
switch (props.scene) {
  case E_SCENE.PersonalCenter:
    return DrawerPersonalCenter
  case E_SCENE.Checkout:
    return DrawerCheckout
  default:
    return DrawerPersonalCenter
  }
})

const goScroll = (num: number) => {
  nextTick(() => {
    const itemWrap = document.getElementsByClassName(scrollWrapClass.value)?.[0] as HTMLElement
    const item = document.getElementsByClassName(scrollItemClass.value)?.[num] as HTMLElement
    if (item && itemWrap) {
      itemWrap.scrollTo({
        top: num === 0 ? 0 :item.getBoundingClientRect().top - itemWrap.getBoundingClientRect().top + itemWrap.scrollTop,
        behavior: 'smooth',
      })
    }
  })
}

const goTab = (index: number) => {
  componentRef.value?.$refs?.tabListRef?.handleTabIndex(index)
}

provide('language', getLanguage) // 多语言
provide('cardData', getCardData) // 列表数据
provide('scene', getScene) // 列表数据

defineExpose({
  goScroll,
  goTab,
})
</script>


<!-- <template>
  <div>bs-gift-card-laptop</div>
</template>

<script name="BGiftCard" setup lang="ts">
// 数据源:
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { getSource } from '../../common/dataSource'
import type { AS_GiftCard, DS_GiftCard } from '../../types'

const appConfigs = useAppConfigs()
const props = defineProps<{
  fsData?: DS_GiftCard.FS_DATA
  dataSource?: DS_GiftCard.DataSource
  analysisSource?: AS_GiftCard.AnalysisSource
}>()
const apis = getSource({ appConfigs, dataSource: props.dataSource })
// 数据源: END

// 埋点源:
import { useAnalysis } from '../../common/analysisSource'
import { provide } from 'vue'
const analysisInstance = useAnalysis(props.analysisSource)
provide('analysisInstance', analysisInstance)
const { vTap, vExpose, triggerNotice } = analysisInstance
// 埋点源: END

// 内置路由:
// appConfigs.$router.push({ path: `/somepath`, query: { test: 123 } })
// 内置路由: END
</script> -->
