<template>
  <div
    ref="el"
    :class="[
      'cart-table-item',
      'j-cart-item',
      'j-valid-cart-item',
      `j-cart-item-${item.id}`,
    ]"
  >
    <CartItem
      :item="item"
      :index="index"
    >
      <SPopover
        :content="getDisabledReason(item)"
        :empty-un-show="true"
        :fix-disappear-position="true"
        theme="dark"
        placemen="right"
        trigger="hover"
        @opened="exposeCheckItem(item)"
      >
        <template #reference>
          <SCheckbox
            :label="item.id"
            :disabled="item.isDisabled"
            :gap="'0px'"
            :model-value="item.is_checked == 1"
            :outside-check="true"
            @change="(res) => handleCheckedItem(res, item)"
          />
        </template>
      </SPopover>
    </CartItem>
  </div>
</template>

<script>
export default {
  name: 'CartItem',
}
</script>
<script setup>
import useModifyCartCheckStatus from 'public/src/pages/cart_v2/hooks/useModifyCartCheckStatus'
import CartItem from 'public/src/pages/cart_v2/components/functional/cartList/CartItem.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index.js'

defineProps({
  item: {
    type: Object,
    default: () => ({})
  },
  index: {
    type: Number,
    default: 0
  },
})

const { modifyCartCheckStatus } = useModifyCartCheckStatus()

const handleCheckedItem = ({ label = '', checked = false }, item) => {
  modifyCartCheckStatus({ cartIdList: [item.id], operationType: checked ? 1 : 2, type: 'item', isShowOverLimitToast: true })
}

const getDisabledReason = (item) => {
  if (item?.canChangeMallInfo) {
    return item?.aggregateProductBusiness?.changeMallInfo?.toastTip || ''
  }
  if (item?.canChangeGoodInfo) {
    return item?.aggregateProductBusiness?.changeGoodInfo?.toastTip || ''
  }
  return ''
}

const exposeCheckItem = (item) => {
  daEventCenter.triggerNotice({
    daId: '1-7-3-67',
    extraData: {
      type: item?.canChangeMallInfo ? 1 : item?.canChangeGoodInfo ? 2 : '',
    }
  })
}
</script>
<style lang="less">
.cart-table-item{
  background-color: #fff;
  padding: 8px 16px;
}
</style>
