import { SToast } from '@shein-aidc/sui-toast'
import { exposeScenesAbtAggregator } from 'public/src/pages/cart_v2/utils/EventAggregator.js'

export const handlerCouponSaveToast = async (tip, type) => {

  if (tip) {
    SToast(tip)
  }

  exposeScenesAbtAggregator.addEvent({
    daId: '1-7-3-22',
    extraData: {
      scenes: 'coupon_add_toast',
      type: type,
    }
  })
}
