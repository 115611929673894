<template>
  <div
    class="crop-image-container"
    :class="{
      'loading': !imgLoadingCompleted
    }"
    :data-before-crop-src="imgSrc"
    :style="cropContainerStyle"
    @click="handleClickImage"
  >
    <!-- <div -->
    <!--   class="backdrop-frame" -->
    <!--   :style="backdropFrameStyle" -->
    <!-- ></div> -->
    <span 
      v-if="imgThumb && isNotExtSrc"
      class="crop-image-container__inner fsp-element" 
      :style="{ 'background-image': `url(${imgThumb})` }"
    >
      <TransformImg
        ref="TransformImgRef"
        v-bind="forwardProps"
        class="crop-image-container__img not-fsp-element"
        :img-src="originImgSrc"
        @load="onLoadImg"
        @error="onErrorImg"
      />
    </span>
    <TransformImg
      v-else
      ref="TransformImgRef"
      v-bind="forwardProps"
      class="crop-image-container__img"
      :img-src="originImgSrc"
      @load="onLoadImg"
      @error="onErrorImg"
    />

    <div
      v-if="!ignoreMask"
      class="crop-image-container__mask"
    ></div>
    <slot name="content"></slot>
  </div>
</template>

<script>
import TransformImg from '../transformImg.vue'
import { closestRatio } from 'public/src/pages/common/utils/index.js'

export default {
  name: 'CropImageContainer',
  components: {
    TransformImg
  },
  props: {
    /**
     * @param {String} imgSrc 图片原始地址
     * @param {Object} transformImgProps 透传 transformImg 组件props
     * @param {String} containerBackground 容器背景颜色
     * @param {String} fixedRatio 容器固定宽高比 （宽-高）例如 fixedRatio="3-4"
     * @param {String} ignoreImgExt 忽略根据图片地址后缀决定容器比例的逻辑
     * */
    isSupportCropImage: {
      type: Boolean,
      default: false
    },
    imgSrc: {
      required: true,
      type: String,
      default: ''
    },
    imgThumb: {
      type: String,
      default: ''
    },
    containerBackground: {
      type: String,
      default: '#FFF'
    },
    transformImgProps: {
      type: Object,
      default: () => {
        return {}
      }
    },
    fixedRatio: {
      type: String,
      default: ''
    },
    ignoreImgExt: {
      type: Boolean,
      default: false
    },
    ignoreMask: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      imgLoadingCompleted: false,   // 等待图片加载完成后显示补图蒙层
      realImgRatio: 1
    }
  },
  emits: ['clickImage', 'imgLoadSucc', 'imgLoadFail'],
  computed: {
    cropContainerStyle() {
      const backdropContainerStyle = {
        // backgroundImage: `url(${this.originImgSrc})`,
        // backgroundRepeat: 'no-repeat',
        // backgroundSize: 'cover',
        backgroundColor: `${this.containerBackground}`,
      }

      const containerRatioStyle = {
        paddingBottom: `calc(${this.containerRatio.toFixed(2)} * 100%)`,
      }

      const containerStyle = {
        ...containerRatioStyle,
        ...backdropContainerStyle
      }
      
      if (this.ignoreImgExt) {
        return containerStyle
      }

      if (this.isNotExtSrc) {
        return containerRatioStyle
      }
      
      return containerStyle
    },
    backdropFrameStyle() {
      if (this.ignoreImgExt) {
        return {}
      }
      
      return this.isNotExtSrc ? { display: 'none' } : {}
    },
    isSquareSrc() {
      // 未命中 Apollo 依旧使用未被去后缀的图片地址
      if (!this.isHitApollo()) {
        return false
      }

      return this.imgSrc.includes('_square')
    },
    isSqufixSrc() {
      // 未命中 Apollo 依旧使用未被去后缀的图片地址
      if (!this.isHitApollo()) {
        return false
      }

      return this.imgSrc.includes('_squfix')
    },
    isNotExtSrc() {
      return !this.isSqufixSrc && !this.isSquareSrc
    },
    containerRatio() {
      if (this.fixedRatio === '3-4') {
        return 4 / 3
      }
      
      if (this.fixedRatio === '4-5') {
        return 5 / 4
      }

      if (this.fixedRatio === '5-6') {
        return 6 / 5
      }

      if (this.fixedRatio === '13-16') {
        return 16 / 13
      }

      if (this.fixedRatio === '1-1') {
        return 1 / 1
      }
      
      // 以下是对非固定容器宽高比 根据imgSrc地址确定的容器大小
      const isSquareSrc = this.isSquareSrc
      // const isSqufixSrc = this.isSqufixSrc 

      if (isSquareSrc) {
        // 1 : 1
        return 1 / 1
      }
      
      // 3 : 4
      return 4 / 3
    },
    originImgSrc() {
      // 未命中 Apollo 依旧使用未被去后缀的图片地址
      if (this.isHitApollo()) {
        return this.imgSrc.replace(/(_square)|(_squfix)/ig, '')
      }
      return this.imgSrc
    },
    closestRatioStr() {
      return closestRatio(this.realImgRatio)
    },
    forwardProps() {
      const forwardProps = {
        ...this.$props.transformImgProps
      }

      // 没传给默认值 LAZY_IMG_SOLID_COLOR
      if (forwardProps.loadImg === undefined) {
        forwardProps.loadImg = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdj+PTp038ACYYD1sVKshAAAAAASUVORK5CYII='
      }

      return forwardProps
    },
  },
  created() {
    // 有懒加载图并且用到懒加载才会执行
    const { loadImg, usePicture, isFirstPage } = this.forwardProps || {}
    if (!loadImg || (usePicture && isFirstPage) || (!usePicture && isFirstPage)) {
      this.imgLoadingCompleted = true
    }
  },
  mounted() {
    const TransformImgRef = this.$refs.TransformImgRef
    const imgSrc = TransformImgRef?.$el?.getAttribute('src') ?? ''
    const loadImg = this.forwardProps.loadImg
    
    if (imgSrc !== loadImg && !this.imgLoadingCompleted) {
      this.imgLoadingCompleted = true
    }
  },
  methods: {
    onLoadImg(e) {
      const loadImg = this.forwardProps?.loadImg
      if (e.target.src.includes(loadImg)) return

      this.$emit('imgLoadSucc', e)

      const width = e.target.width
      const height = e.target.height

      this.realImgRatio = width / height

      if (!this.imgLoadingCompleted) {
        this.imgLoadingCompleted = true
      }
    },
    onErrorImg(e) {
      this.$emit('imgLoadFail', e)
    },
    handleClickImage() {
      const realImgRatio = this.realImgRatio
      
      this.$emit('clickImage', realImgRatio, this.closestRatioStr)
    },
    isHitApollo() {
      // Apollo 已经推全，不再需要传递 isSupportCropImage
      return true
      // if (typeof window === 'undefined') {
      //   return this.isSupportCropImage
      // }

      // const { RESOURCE_SDK } = gbCommonInfo
      // if (RESOURCE_SDK && RESOURCE_SDK.isSupportCropImage) {
      //   return true
      // }

      // return false
    }
  }
}
</script>

<style lang="less">
.crop-image-container {
  position: relative;
  overflow: hidden;
  height: 0;

  &.loading {
    --crop-img-fit: cover;

    .crop-image-container__mask {
      background-color: transparent;
    }
  }

  &__inner {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: 0 0 !important; /* stylelint-disable-line declaration-no-important */
    background-size: cover !important; /* stylelint-disable-line declaration-no-important */
  }

  &__img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: var(--crop-img-fit, contain);
  }

  &__mask {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.03);
  }

  // .backdrop-frame {
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   width: 100%;
  //   height: 100%;
  //   backdrop-filter: blur(100px);
  // }
}
</style>
