import { ref } from 'vue'

export const useCartNum = () => {
  const isClient = typeof window !== 'undefined'

  let cartNum = ref(0)
  if (isClient) {
    // @ts-ignore
    // eslint-disable-next-line vue/no-ref-as-operand
    cartNum = window.__cartNum__ || (window.__cartNum__ = ref(0))
  }

  const update = ({ num }: { num: number }) => {
    if (isClient) cartNum.value = num || 0
  }

  return {
    cartNum,
    update,
  }
}
