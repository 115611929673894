import { createApp } from 'vue'
import Report from './report.vue'
import DsaReport from './DsaReport.vue'
import { registerBaseContext } from 'public/src/pages/common/common_inject.js'
let ReportInstance = null
let DsaReportInstance = null
const CommentReportPopover = {
  open: ({ config, props }) => {
    const isDsaSite = ['eur', 'euqs', 'fr', 'de', 'it', 'es', 'nl', 'pl', 'pt', 'roe', 'es', 'se', 'uk', 'at', 'ro'].includes(gbCommonInfo?.SiteUID)
    const isDsaReport = isDsaSite && !config?.isFree && gbCommonInfo?.WEB_CLIENT === 'shein'
    if (ReportInstance && !isDsaReport) return ReportInstance.open(config)
    if (DsaReportInstance && isDsaReport) return DsaReportInstance.open(config)
    const app = createApp(isDsaReport ? DsaReport : Report, props)
    registerBaseContext(app)
    const root = document.createElement('div')
    document.body.appendChild(root)
    const Instance = app.mount(root)
    Instance.open(config)
    if (isDsaReport) {
      DsaReportInstance = Instance
    } else {
      ReportInstance = Instance
    }
  },
}

export default CommentReportPopover
