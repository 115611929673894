import { click_store_name, expose_store_name } from 'public/src/pages/cart_v2/common/analysis/item/goodsStore.js'

/**
 * GoodsStore 原子组件操作
 */
export function useGoodsStoreEvent() {

  /**
   * 曝光
   */
  const onExpose = ({ itemOpts: { data }, analysis = {} }) => {
    expose_store_name(data.value, analysis.expose_store_name)
  }

  /**
   * 点击
   */
  const onReport = ({ itemOpts: { data }, analysis = {} }) => {
    click_store_name(data.value, analysis.click_store_name)
  }

  const onClick = ({ itemOpts: { data } }) => {
    const storeRouting = data?.value?.storeRouting
    if (storeRouting) {
      window.open(`${gbCommonInfo.langPath}${storeRouting}`, '_blank' )
    }
  }

  return {
    onExpose,
    onReport,
    onClick
  }
}
