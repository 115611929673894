/**
 * 职责： 记录用户的路由行为
 */

// todo ： 打开多个窗口的情况下 会读取其他 history.length < 3 表示打开新窗口 没有返回

// 功能：
//  定义有那些来源路由name， 到指定的路由name，命中就为开始记录
//  开始的次数+1：  进入+1
//  只要开始后，在命中来源路由name的时候，也进行记录但不是开始。
// 结束：返回-1， 只到为0的时候，结束记录

//  一、商详一些例子
//  1. 首页 =》 列表 （sesssion_id1） =>  商详 （推荐夹带sesssion_id1） => 列表 （sesssion_id2） => 商详（推荐夹带sesssion_id1，sesssion_id2）
// 结束点为一直返回到第一次列表的到首页
//  2. category =》 列表 （sesssion_id1） =>  商详 （推荐夹带sesssion_id1） => 列表 （sesssion_id2） => 商详（推荐夹带sesssion_id1，sesssion_id2）
// 结束点为一直返回到第一次列表的到首页

// 二、搜索 cycle的例子
//  1.首页 =》 预搜索页（sesssion_id）=> 搜索结果页 (夹带sesssion_id) => 预搜索页 =》 搜索结果页（夹带sesssion_id）
// 结束点： （1） 直接回到首页 （2）回到第一个预搜索页并到首页的时候结束

//  以下场景case需要去代办：
//  1.pwa的场景： 首页 => 列表（开始） =>  商详 =》 回到首页结束/列表到首页结束

class RouterAction {
  /***
   *  @params数说明
   *  targetPaths:  目标页面name 集合
   *  clearDataPaths : 清空数据的name 集合
   * onStartCallback: 开始回调
   * onEndCallback: 结束回调
   * targetTriggerCallback: 目标触发回调, 只要当前路由命中目标路由，就会触发(如果有多个目标路由，以第一个为准)
   * onWatch : 每次更新回调
   */
  constructor({ targetPaths, clearDataPaths, singleKey }) {
    this.startState = false // 开始记录状态
    this.userRouterActions = [] // 收集的路由行为
    this.targetPaths = targetPaths || []
    this.clearDataPaths = clearDataPaths || []
    this.singleKey = singleKey || false
  }

  getData() {
    return this.userRouterActions
  }

  setInitData(userRouterActions) {
    this.userRouterActions = Array.isArray(userRouterActions)
      ? userRouterActions
      : []
    this.startState = this.userRouterActions.length > 0
  }

  /**
   * @description: 暴露外部路由触发方法
   */
  trigger(to = {}, from = {}) {
    if (to.path === from.path) return // 同一个页面
    if (!this.onStartCallback) {
      throw new Error('请注册开始回调')
    }
    if (this.targetPaths.length === 0) {
      return
    }

    // 防止最近一条重复记录
    let _userRouterActions = this.userRouterActions
    if (!this.singleKey)  {
      const sortKeys =  Object.keys(this.userRouterActions).sort((a, b) => b - a) // 取最后一条
      const lastData = this.userRouterActions[sortKeys[0]]
      _userRouterActions = [lastData]
    }
    if (_userRouterActions.some(item => item?.to?.path === to?.path && item.from?.path === from.path)) {
      return
    }

    this.onStart(to, from)
    this.recordRouterAction(to, from)
  }

  /**
   * @description: 暴露外部设置一些回调的方法
   */
  registerHandler({
    onStartCallback,
    onEndCallback,
    targetTriggerCallback,
    watch
  }) {
    this.onStartCallback = onStartCallback || (() => {})
    this.onEndCallback = onEndCallback || (() => {})
    this.targetTriggerCallback = targetTriggerCallback || (() => {})
    this.onWatch = watch || (() => {}) // 每次更新回调都要更新
  }

  /**
   * @description: 开始记录, 确认标识开始记录
   * */
  onStart(to, from) {
    if (this.startState) return
    // 匹配是否为开始记录的路由， name和url都匹配上才行
    const isToCurrentTarget = this.isHitTargetRouter({ to })
    if (isToCurrentTarget || this.userRouterActions.length > 0) {
      this.startState = true
      this.onStartCallback({ to, from })
    }
  }

  //  是否命中目标的路由
  isHitTargetRouter({ to }) {
    const isToCurrent = this.targetPaths.some(path => {
      if (path) {
        const reg = new RegExp(path)
        return reg.test(to.path)
      }
      return false
    })
    return isToCurrent
  }

  onEnd() {
    this.startState = false
    this.onEndCallback()
    this.userRouterActions = []
    this.onWatch(this.userRouterActions)
  }

  recordRouterAction(to, from) {
    if (!this.startState) return
    const data = {
      to: {
        path: to.path
      },
      from: {
        path: from.path
      }
    }
    this.userRouterActions.push(data)
    if (this.isEnd({ to, from })) {
      this.onEnd()
      return
    }
    if (this.isHitTargetRouter({ to })) {
      this.targetTriggerCallback({ to, from })
    }
    this.onWatch(this.userRouterActions)
  }

  /**
   * @description: 检测是否已经结束页面： 返回到第一个开始的页面
   * */
  isEnd({ to }) {
    // 判断 to.path和当前配置的结束clearDataNames里面能否匹配上，匹配上就结束
    const isClearNames = this.clearDataPaths.some(path => {
      if (path) {
        const reg = new RegExp(path)
        return reg.test(to.path)
      }
      return false
    } )
    if (isClearNames) {
      return true
    }

    if (this.userRouterActions.length === 0) return false // 没有开始记录
    // 这个case: 首页 =》 预搜索页面  =>  搜素结果页 => 详情页 => 首页 =》 预搜索页面 =》 搜索结果页 =》预搜索页面 => 首页 => 详情页 => 搜索结果页 => 预搜索页面 => 首页 end
    const { to: { path: startFullPath }, from: { path: startFromFullPath } } = this.userRouterActions[0]
    const startRouterKey = `${startFullPath}_${startFromFullPath}`
    const endRouterKey = `${startFromFullPath}_${startFullPath}`
    // 遍历this.userRouterActions 判断两个开始和结束数量相等说明结束
    const startData = this.userRouterActions.filter(
      item => {
        const { to: { path }, from: { path: fromFullPath } } = item
        return `${path}_${fromFullPath}` === startRouterKey
      }
    )
    const endData = this.userRouterActions.filter(
      item => {
        const { to: { path }, from: { path: fromFullPath } } = item
        return `${path}_${fromFullPath}` === endRouterKey
      }
    )
    return startData.length === endData.length
  }
}

export { RouterAction }
