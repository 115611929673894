const indexAnalysis = {
  clickCoupons(activity_param) {
    sa('send', { activity_name: 'click_my_coupons', activity_param })
  },
  clickGiftcardMiddle(activity_param) {
    sa('send', { activity_name: 'click_gift_card', activity_param })
  },
  clickMyMessageRight() {
    sa('send', { activity_name: 'click_my_message_right' }, { beacon: 1 })
  },
  clickOrderDetails() {
    sa('send', { activity_name: 'click_order_details' }, { beacon: 1 })
  },
  clickPointsMiddle(activity_param) {
    sa('send', { activity_name: 'click_shein_points', activity_param })
  },
  clickProcessingMiddle(activity_param) {
    sa('send', { activity_name: 'click_order_status', activity_param }, { beacon: 1 })
  },
  clickRecentlyViewedGoodsRight() {
    sa('send', { activity_name: 'click_order_status' }, { beacon: 1 })
  },
  clickRecentlyViewedRight() {
    sa('send', { activity_name: 'click_recently_viewed_right' }, { beacon: 1 })
  },
  clickReturnsMiddle(activity_param) {
    sa('send', { activity_name: 'click_order_status', activity_param }, { beacon: 1 })
  },
  clickReviewMiddle(activity_param) {
    sa('send', { activity_name: 'click_order_status', activity_param }, { beacon: 1 })
  },
  clickProfileImageMiddle() {
    sa('send', { activity_name: 'click_self_profile_image_middle' }, { beacon: 1 })
  },
  clickServiceRecords() {
    sa('send', { activity_name: 'click_service_records_right' }, { beacon: 1 })
  },
  clickShippedMiddle(activity_param) {
    sa('send', { activity_name: 'click_order_status', activity_param }, { beacon: 1 })
  },
  clickUnpaidMiddle(activity_param) {
    sa('send', { activity_name: 'click_order_status', activity_param }, { beacon: 1 })
  },
  clickViewAllMiddle(activity_param) {
    sa('send', { activity_name: 'click_view_all_middle' }, { beacon: 1 })
    sa('send', { activity_name: 'click_order_all', activity_param })
  },
  clickOrderAll(activity_param) {
    sa('send', { activity_name: 'click_order_all', activity_param })
  },
  clickVipMiddle() {
    sa('send', { activity_name: 'click_vip_middle' }, { beacon: 1 })
  },
  clickSheinClub () {
    daEventCenter.triggerNotice({
      daId: '1-22-2-23'
    })
  },
  clickWalletMiddle(activity_param) {
    sa('send', { activity_name: 'click_my_wallet', activity_param })
  },
  clickWishlistGoods() {
    sa('send', { activity_name: 'click_wishlist_goods_right' }, { beacon: 1 })
  },
  clickWishlistMore(activity_param) {
    sa('send', { activity_name: 'click_wishlist_view_all', activity_param })
  },
}

const mapAnalysis = {
  coupon: 'clickCoupons',
  giftCard: 'clickGiftcardMiddle',
  mssage: 'clickMyMessageRight',
  orderDetail: 'clickOrderDetails',
  points: 'clickPointsMiddle',
  processing: 'clickProcessingMiddle',
  recentView: 'clickRecentlyViewedGoodsRight',
  recentViewMore: 'clickRecentlyViewedRight',
  returns: 'clickReturnsMiddle',
  review: 'clickReviewMiddle',
  profile: 'clickProfileImageMiddle',
  records: 'clickServiceRecords',
  shipped: 'clickShippedMiddle',
  unpaid: 'clickUnpaidMiddle',
  allOrder: 'clickViewAllMiddle',
  vip: 'clickVipMiddle',
  prime: 'clickSheinClub',
  wallet: 'clickWalletMiddle',
  wishlist: 'clickWishlistGoods',
  wishlistMore: 'clickWishlistMore',
  // 父级
  
}

function analysisEvent(id, activity_param) {
  if (Object.keys(mapAnalysis).includes(id)) {
    indexAnalysis[mapAnalysis[id]](activity_param)
  }
}

const triggerPageMeEnter = ({ commonState }) => {
  window.SaPageInfo = {
    page_id: 115,
    page_name: 'page_me',
    start_time: new Date().getTime(),
    page_param: {
      vip_level: commonState.userInfo.level,
    }
  }
  window.sa('set', 'setPageData', window.SaPageInfo)
  window.sa('send', 'pageEnter', { start_time: new Date().getTime() })
}

export {
  triggerPageMeEnter,
  analysisEvent
}
