<template>
  <div
    v-if="sellerInfoText.soldByVal || sellerInfoText.shipFromVal"
    class="soldbybox-container"
    :style="{padding: noPadding ? '0' : '0 20px'}"
  >
    <div class="soldbybox-header">
      <sui_icon_shop_16px_1 
        class="soldbybox-header__icon"
        color="#198055"
        size="24px"
      />
      <div 
        class="soldbybox-header__title" 
      >
        <div
          v-if="sellerInfoText.soldByVal"  
        >
          <span class="soldbybox-header__title-text">{{ sellerInfoText.soldByVal }}</span>


          <ClientOnly>
            <SPopover
              v-if="showSellerIcon"
              fix-disappear-position
              trigger="hover"
              :prop-style="{
                minWidth: '400px',
              }"
              placemen="top-end"
              @opened="clickSellerIcon"
            >
              <template #reference>
                <Icon
                  v-expose="{
                    id: '1-6-1-170',
                    data: sellerAnalysisData
                  }"
                  name="sui_icon_doubt_circle_14px_1"
                  color="#999"
                  size="14px"
                />
              </template>
              <template #default>
                <div class="soldbybox-popover">
                  <loaders
                    :value="loadingCurPageDrawer"
                    class="la-ball-pulse-2x soldbybox-popover__loading"
                  />
                  <template v-if="!loadingCurPageDrawer">
                    <div class="soldbybox-popover__title">
                      {{ language.SHEIN_KEY_PC_25988 }}
                    </div>
                    <template v-if="!sellerInfoType">
                      <ul
                        class="soldbybox-popover__content"
                      >
                        <li>
                          <span class="cell-title">{{ language.SHEIN_KEY_PC_25994 }}</span>
                          <span class="cell-content">{{ businessNameValue }}</span>
                        </li>
                        <li>
                          <span class="cell-title">{{ language.SHEIN_KEY_PC_25990 }}</span>
                          <span class="cell-content ltr">{{ businessAddressValue }}</span>
                        </li>
                        <li>
                          <span class="cell-title">{{ language.SHEIN_KEY_PC_25996 }}</span>
                          <span class="cell-content ltr">{{ tradeRegisterNumberValue }}</span>
                        </li>
                        <li>
                          <span class="cell-title">{{ language.SHEIN_KEY_PC_25998 }}</span>
                          <span class="cell-content ltr">{{ vatNumberValue }}</span>
                        </li>
                      </ul>
                      <div class="soldbybox-popover__footer">
                        <p>{{ language.SHEIN_KEY_PC_26003 }}</p>
                        <p>{{ language.SHEIN_KEY_PC_26004 }}</p>
                      </div>
                    </template>
                    <SoldBySellerKr
                      v-else
                      :seller-info-box="sellerInfo"
                    />
                  </template>
                </div>
              </template>
            </SPopover>
          </ClientOnly>
        </div>
        <div 
          v-if="sellerInfoText.shipFromVal"  
          class="soldbybox-header__sub-title"
        >
          {{ sellerInfoText.shipFromVal }}
          <span
            v-show="localWarehouseText"
            v-expose="{ id: '1-6-1-173', once: true }"
            class="soldbybox-header__local"
          >{{ localWarehouseText }}</span>
        </div>
      </div>
    </div>
    <div 
      v-if="showObligationsseller"
      class="soldbybox-content"
    >
      <div

        v-expose="{ id: '1-6-1-150' }"
        class="soldbybox-content__report"
        @click="clickSeller"
      >
        <p class="soldbybox-content__report-text">
          {{ language.SHEIN_KEY_PC_31904 || 'Information and obligations of the seller' }}
        </p>
        <Icon
          v-if="!cssRight"
          name="sui_icon_more_right_12px_1"
          size="12px"
        />
        <Icon
          v-else
          name="sui_icon_more_left_12px_1"
          size="12px"
        />
      </div>
    </div>
    <div class="soldbybox-content">
      <div
        v-if="showReport"
        v-expose="{ id: '1-6-1-150' }"
        class="soldbybox-content__report"
        @click="clickHandle"
      >
        <p class="soldbybox-content__report-text">
          {{ language.SHEIN_KEY_PC_28170 }}
        </p>
        <Icon
          v-if="!cssRight"
          name="sui_icon_more_right_12px_1"
          size="12px"
        />
        <Icon
          v-else
          name="sui_icon_more_left_12px_1"
          size="12px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { SPopover } from '@shein-aidc/sui-popover'
import { expose } from 'public/src/pages/common/analysis/directive.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import schttp from 'public/src/services/schttp'
import loaders from 'public/src/pages/components/loaders'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { Icon, sui_icon_shop_16px_1 } from '@shein-aidc/icon-vue3'
import SoldBySellerKr from 'public/src/pages/goods_detail_v2/components/ProductIntroShippingEnter/SoldBySellerKr.vue'
let SiteUID
if (typeof window !== 'undefined') {
  SiteUID = gbCommonInfo?.SiteUID
}

export default {
  name: 'SoldBySeller',
  components: {
    SPopover,
    loaders,
    Icon,
    sui_icon_shop_16px_1,
    ClientOnly,
    SoldBySellerKr
  },
  directives: {
    expose,
  },
  props: {
    sellerAnalysisData: {
      type: Object,
      default: () => {}
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    language: {
      type: Object,
      default: () => {}
    },
    // sellerInfo: {
    //   type: Object,
    //   default: () => {}
    // },
    storeInfo: {
      type: Object,
      default: () => {}
    },
    labelList: {
      type: Array,
      default: () => []
    },
    showSellerIcon: {
      type: Boolean,
      default: false
    },
    showReport: {
      type: Boolean,
      default: false
    },
    showObligationsseller: {
      type: Boolean,
      default: false
    },
    cssRight: {
      type: Boolean,
      default: false
    },
    businessModel: {
      type: String,
      default: ''
    },
    goodsId: {
      type: String,
      default: ''
    },
    localWarehouseText: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      sellerInfo: {},
      loadingCurPageDrawer: true,
      SELLER_TIP_SITES_SUPPORT: [],
      goodsIdHadChange: false // skc改变重新获取数据，根据goodsid判断，
    }
  },
  computed: {
    businessNameValue () {
      const { fieldValues = [], isDirectSaleStore } = this.sellerInfo
      if (isDirectSaleStore) {
        // 自营取写死的
        return this.sellerInfo?.businessNameValue
      }
      const { SHEIN_KEY_PC_25999: ERROR } = this.language
      // 取数逻辑 3 => 4 再到 Error 兜底
      let item3 = null, item4 = null
      for (let item of fieldValues) {
        if (item.fieldType === 3) {
          item3 = item
        } else if (item.fieldType === 4) {
          item4 = item
        }
      }
      return item3?.fieldValue || item4?.fieldValue || ERROR
    },
    businessAddressValue () {
      const { fieldValues = [], isDirectSaleStore } = this.sellerInfo
      if (isDirectSaleStore) {
        // 自营取写死的
        return this.sellerInfo?.businessAddressValue
      }
      const { SHEIN_KEY_PC_25999: ERROR } = this.language
      const item6 = fieldValues.find(item => item.fieldType === 6)
      return item6?.fieldValue || ERROR
    },
    tradeRegisterNumberValue () {
      const { isDirectSaleStore, codeRegisterNo } = this.sellerInfo
      if (isDirectSaleStore) {
        // 自营取写死的
        return this.sellerInfo?.tradeRegisterNumberValue
      }
      return codeRegisterNo || this.language.SHEIN_KEY_PC_25999
    },
    vatNumberValue () {
      const { taxInfoList = [], isDirectSaleStore } = this.sellerInfo
      if (isDirectSaleStore) {
        // 自营取写死的
        return this.sellerInfo?.vatNumberValue
      }
      if(taxInfoList?.length) {
        if(taxInfoList.length == 1) {
          return taxInfoList[0].supplierTaxNumber
        } else {
          let vatInfo = taxInfoList.find(item => item.saleCountry == 'FR' && item.supplierTaxNumber) || null
          vatInfo = vatInfo ? vatInfo : taxInfoList.find(item => item.saleCountry == 'DE' && item.supplierTaxNumber) || null
          vatInfo = vatInfo ? vatInfo : taxInfoList.find(item => item.supplierTaxNumber) || null
          return vatInfo ? vatInfo.supplierTaxNumber : ''
        }
      }
      const { SHEIN_KEY_PC_26000: NOT_APPLICABLE } = this.language
      return NOT_APPLICABLE
    },
    sellerInfoText () {
      const { language } = this
      const { storeType, title, tocPerformParty } = this.storeInfo
      const hasLabel = this.labelList?.some?.(item => item.labelCode === 'sold_by_storename') || false
      const is1PStore = storeType === 6
      const SHEIN = language.SHEIN_KEY_PC_25674
      const isPlatform = storeType === 1
      let soldByVal = (isPlatform ? title : SHEIN) || ''
      if(is1PStore && hasLabel){
        soldByVal = title || ''
      }
      const shipFromVal = (isPlatform ? (tocPerformParty === 1 ? SHEIN : title) : SHEIN) || ''
      const isPlSeller = this.SELLER_TIP_SITES_SUPPORT?.includes(SiteUID) && soldByVal === title
      // 1.阿波罗配置的站点，本次会为pl 且 2.sold by后接的是 店铺名称 且 2.sold by后接的是 店铺名称
      const sellerTip = ` (${(language.SHEIN_KEY_PC_33113 || 'Seller')})`
      return {
        soldByVal: soldByVal && `${language.SHEIN_KEY_PC_28157} ${soldByVal}${isPlSeller ? sellerTip : ''}`,
        shipFromVal: shipFromVal && `${language.SHEIN_KEY_PC_28158} ${shipFromVal}`,
      }
    },
    sellerInfoType() {
      // sellerInfoText
      return this.sellerInfo?.type == 'krCompliance'
    }
  },
  watch: {
    goodsId() {
      this.goodsIdHadChange = true
    }
  },
  mounted(){
    this.getApolloConfig()
  },
  methods: {
    async getApolloConfig() {
      const res = await schttp({
        method: 'POST',
        url: '/api/config/apollo/get',
        data: { apolloKeys: 'SELLER_TIP_SITES_SUPPORT' }
      })
      const { SELLER_TIP_SITES_SUPPORT } = res?.info || {}
      this.SELLER_TIP_SITES_SUPPORT = SELLER_TIP_SITES_SUPPORT || []
    },
    async getSellerInfo() {
      return new Promise((resolve, reject) => {
        if(Object.keys(this.sellerInfo).length && !this.goodsIdHadChange) {
          resolve(this.sellerInfo)
        } else {
          const { businessModel } = this
          const { sellerSource, sellerId } = this.storeInfo
          const params = { business_model: businessModel, sellerSource, sellerId }
          schttp({
            method: 'GET',
            url: '/api/productInfo/sellerInfo/get',
            params: params
          }).then(res => {
            this.loadingCurPageDrawer = false
            this.goodsIdHadChange  = false
            resolve(res)
          }).catch(() => reject({}))
        }
      })
    },

    clickHandle() {
      this.$emit('clickReport')
    },
    clickSeller() {
      window.open(`/information-and-obligations-of-the-seller-a-5192.html`)
    },
    async clickSellerIcon() {
      this.sellerInfo = await this.getSellerInfo()
      daEventCenter.triggerNotice({
        daId: '1-6-1-169',
        extraData: this.sellerAnalysisData
      })
    }
  },
  emits: ['clickReport', 'clickIcon']
}
</script>
<style lang="less">
  .soldbybox-container {
    .soldbybox-header {
      display: flex;
      flex-direction: row;
      // align-items: center;
      gap: 8px;
      text-align: center;
      vertical-align: middle;
      position: relative;
      &__icon {
        color: #198055;
        height: 24px;
        width: 24px;
        font-size: 24px !important;/* stylelint-disable-line declaration-no-important */
      }
      &__title{
        color: var(--sui-color-gray-dark-1, #222);
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: 4px;
        text-align: left;
      }
      &__title-text {
        margin-right: 4px;
      }
      &__title-icon {
        position: relative;
        top: 1px;
        font-size: 14px !important;/* stylelint-disable-line declaration-no-important */
        cursor: pointer;
      }
      &__sub-title {
        color: #999;
        font-family: Arial;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
        white-space: normal;
        text-align: start;
        margin-top: 4px;
        // margin-left: 32px;
      }
      &__local{
        color: #198055;
      }
    }
    .soldbybox-content {
      margin: 4px 0;
      padding: 0 32px;
      &__report {
        color: var(--sui-color-gray-dark-2, #666);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
        display: flex;
        flex-wrap: nowrap;
        cursor: pointer;
        align-items: center;
      }
      &__report-text {
        color: @sui_color_gray_dark2;
        width: auto;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .soldbybox-popover {
    // TODO: zackshi 把 popover 宽样式
    display: flex;
    flex-direction: column;
    align-self: stretch;
    padding: 0 16px;
    gap: 16px;
    &__title {
      color: var(--sui-color-main, #222);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 114.286% */
    }
    &__content {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      gap: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px; /* 116.667% */      
      .cell-title {
        width: 114px;
        display: inline-block;
        color: #666;
        word-wrap: break-word;
      }
      .cell-content {
        width: 200px;
        display: inline-block;
        margin-left: 8px;
        color: var(--sui-color-gray-dark-1, #000);
        line-height: normal;
        word-wrap: break-word;
        vertical-align: top;
      }
      .ltr {
        direction: ltr/*rtl:ignore*/;
      }
    }
    &__footer {
      display: flex;
      flex-direction: column;
      gap: 8px;
      p {
        color: var(--sui-color-gray-light-1, #959595);
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    &__loading {
        text-align: center;
        padding: 50px 0;
      } 
  }
</style>
