<template>
  <s-checkbox-tag
    class="filter-check-tag"
    :class="{
      'style-default': labelInfo.style == '0',
      'style-a': labelInfo.style == '1',
      'style-b': !inModal && labelInfo.style == '2',
      'style-b-modal': inModal && labelInfo.style == '2',
      'scan-lights': showScanLights && labelInfo.groupClassId == 'coupon'
    }"
    style-type="white"
    :hide-icon="hideDefaultCloseIcon"
    :label="labelInfo.filterTagId"
  >
    <div class="filter-label">
      <template v-if="showPrefixIcon">
        <Icon
          v-if="prefixIcon"
          size="12px"
          class="prefix-icon custom-prefix-icon"
          :name="prefixIcon"
        />
        <img
          v-else
          class="label-icon"
          :src="getLabelIcon"
        />
      </template>
      <i
        v-if="showDivider"
        class="divider"
      ></i>
      <TransitionGroup 
        name="slide" 
        tag="div"
        class="transition-tag"
      >
        <div 
          v-for="item in slideItems" 
          :key="item"
          class="label-tip"
        >
          {{ labelInfo.tip }}
        </div>
      </TransitionGroup>
      <Icon
        v-if="showSuffixIcon && checked && ['1', '2'].includes(labelInfo.style)"
        class="suffix-icon"
        name="sui_icon_closed_filter_12px_1" 
        size="14px"
      />
    </div>
  </s-checkbox-tag>
</template>

<script>
export default {
  name: 'FilterCheckTag',
}
</script>

<script setup>
import { defineProps, computed, ref, watch, nextTick } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { SCheckboxTag } from '@shein-aidc/sui-checkbox-tag/laptop'
const props = defineProps({
  labelInfo: {
    type: Object,
    default: () => {}
  },
  checked: {
    type: Boolean,
    default: false,
  },
  // 展示标签文字前icon
  showPrefixIcon: {
    type: Boolean,
    default: false,
  },
  prefixIcon: {
    type: String,
    default: '',
  },
  showDivider: {
    type: Boolean,
    default: false,
  },
  // 展示标签文字后关闭icon
  showSuffixIcon: {
    type: Boolean,
    default: false,
  },
  // 是否播放扫光动画
  showScanLights: {
    type: Boolean,
    default: false,
  },
  inModal: {
    type: Boolean,
    default: false,
  },
})

// 是否隐藏checkbox组件的删除按钮
const hideDefaultCloseIcon = computed(() => {
  if (['1', '2'].includes(props.labelInfo?.style)) return true
  if (props.showSuffixIcon && props.checked) return false
  return true
})
const getLabelIcon = computed(() => {
  if (props.checked) {
    return props.labelInfo.selectIconUrl
  }
  return props.labelInfo.iconUrl
})
const slideItems = ref([props.labelInfo.tip])
watch(() => props.labelInfo.filterTagId, async (n, o) => {
  slideItems.value?.shift()
  await nextTick()
  slideItems.value?.push(n)
})
</script>

<style lang="less" scoped>
.filter-check-tag {
  width: auto;
  margin-right: 8px;
  height: 28px;
  /deep/.sui-checkbox-tag {
    height: 28px;
  }
  .filter-label {
    display: inline-flex;
    align-items: center;
    height: 100%;
    font-size: 12px;
    max-width: 156px;
    overflow: hidden;
    .label-icon {
      width: 12px;
      height: 12px;
      margin-right: 4px;
    }
    .divider {
      width: 1px;
      height: 10px;
      background: #999;
      margin: 0 4px;
    }
    .transition-tag {
      flex: 1;
      overflow: hidden;
    }
    .label-tip {
      height: 16px;
      line-height: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .suffix-icon {
    margin-left: 4px;
  }
  &.style-default {
    /deep/.sui-checkbox-tag {
      background: #fff;
      border: 1px solid #e5e5e5;
    }
    &.sui-checkbox__checked {
      /deep/.sui-checkbox-tag {
        border: 1px solid #222;
      }
    }
  }
  &.style-a {
    /deep/.sui-checkbox-tag {
      background: #fff;
      border: .5px solid #e5e5e5;
      border-radius: 24px;
    }
    &.sui-checkbox__checked {
      /deep/.sui-checkbox-tag {
        background: #FFF6F3;
        border:.5px solid #FA6338;
        color: #FA6338;
      }
      .divider {
        background: #FA6338;
      }
    }
  }
  &.style-b {
    /deep/.sui-checkbox-tag {
      background: #fff;
      border: .5px solid #e5e5e5;
      border-radius: 24px;
    }
    &.sui-checkbox__checked {
      /deep/.sui-checkbox-tag {
        background: #000;
        border: .5px solid #000;
        color: #fff;
      }
      .divider {
        background: #000;
      }
    }
  }
  &.style-b-modal {
    /deep/.sui-checkbox-tag {
      background: #fff;
      border: .5px solid #e5e5e5;
      border-radius: 24px;
      line-height: 100%;
    }
    &.sui-checkbox__checked {
      /deep/.sui-checkbox-tag {
        border: 1px solid #000;
        background: #fff;
        color: #000;
      }
    }
  }
}
.scan-lights {
  position: relative;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to right, rgba(255, 230, 167, 0), rgba(255, 230, 167, 1), rgba(255, 230, 167, 0));
    animation: ScanLights 1.5s linear 2 forwards;
  }
}
@keyframes ScanLights {
  0% {
    transform: translate(-100%);
  }
  100% {
    transform: translate(100%);
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: all .2s linear;
}
.slide-enter {
  transform: translate3d(0, 100%, 1px);

}
.slide-leave-to {
  opacity: 0;
  transform: translate3d(0, -100%, 1px);
}
</style>
