import { getTogetherBetterDealsPrice } from 'public/src/pages/detail/main/services/index.js'
import { STYLE_TYPE } from 'public/src/pages/detail/main/components/BTF/innerComponents/Recommend/combo_buy/utils.js'
import { getCalcBundlePrice } from 'public/src/pages/detail/main/components/BTF/innerComponents/Recommend/common_cart/utils.js' 
import { abtservice } from 'public/src/services/abt'

/**
 * @description: 组合购数据中心
 */
export default {
  namespaced: true,
  state: {
    products: [],
    cBundlePrice: {},
    loading: false
  },
  mutations: {
    /**
     * 更新商品数据
     */
    update_products(state, payload) {
      state.products = payload
    },
    /**
     * 更新价格
     */
    update_bundle_price(state, payload) {
      state.cBundlePrice = getCalcBundlePrice(payload)
    },
    /**
     * 加载状态
     */
    update_loading(state, payload) {
      state.loading = payload
    }
  },
  getters: {
    /**
     * 组合购abt
     */
    combo_buy_abt(state, getters, rootState, rootGetters) {
      const screen_abt_tsp_config = rootGetters['Adaptor/screen_abt_tsp_config']
      const { buytogethernew = '', buytogethershownew = '', heightnew = '' } = screen_abt_tsp_config.buytogethernew?.param || {}
      const isShowComboBuy = buytogethernew === 'show'
      // new 橙色 ｜ old 黑色
      const isShowNewComboBuyStyleAbt = buytogethershownew === 'new'
      // tall 不压缩 ｜ short 压缩
      const isShowComboBuyScreenStyleAbt = isShowNewComboBuyStyleAbt && heightnew === 'short'
      return {
        isShowComboBuy,
        isShowNewComboBuyStyleAbt,
        isShowComboBuyScreenStyleAbt,
        // eslint-disable-next-line @shein-aidc/abt/abt
        abTest: abtservice.getUserAbtResultForAnalysis({ abtInfo: { 'buytogethernew': screen_abt_tsp_config.buytogethernew || {} } })?.sa || ''
      }
    },
    /** 
     * 组合购多语言映射
     */
    combo_buy_language(state, getters, rootState, rootGetters) {
      const language = rootGetters['Adaptor/language_v2']
      return {
        //标题
        headerTitleText: language.SHEIN_KEY_PC_29052 || '',
        selectTipsText: language.SHEIN_KEY_PC_29943 || '',
        addItemText: language.SHEIN_KEY_PC_29942 || '',
        thisItemText: language.SHEIN_KEY_PC_25497 || '',
        // 弹窗
        drawerTitleText: language.SHEIN_KEY_PC_29054 || '',
        mainGoodsTipsText: language.SHEIN_KEY_PC_25497 || '',
        saveText: language.SHEIN_KEY_PC_28628 || '',
        promotionsText: language.SHEIN_KEY_PC_12231 || '',
        activityText: language.SHEIN_KEY_PC_21927 || '',
        expectedToSaveText: language.SHEIN_KEY_PC_29050 || '',
        saveWithCouponsText: language.SHEIN_KEY_PC_29055 || '',
        expiresInText: language.SHEIN_KEY_PC_29102 || '',
        promotionsTipText: language.SHEIN_KEY_PC_29051 || '',
        addToCartText: language.SHEIN_KEY_PC_14634 || '',
        // 加车校验
        noSelectText: language.SHEIN_KEY_PC_29056 || '',
        noSkuText: language.SHEIN_KEY_PC_29057 || '',
        soldOutText: language.SHEIN_KEY_PC_29058 || '',
        // 加车失败提醒
        outOfStockText: language.SHEIN_KEY_PC_14617 || '',
        limitQuantityText: language.SHEIN_KEY_PC_14661 || '',
        errorText: language.SHEIN_KEY_PC_14605 || '',
      }
    },
    /**
     * 组合购依赖数据配置
     */
    combo_buy_config(state, getters, rootState, rootGetters) {
      const baseInfo = rootGetters['Adaptor/base_info']
      const isPaidUser = rootGetters['Adaptor/is_paid_user']
      const isHitComplianceMode = rootGetters['Adaptor/is_hit_compliance_mode']
      const skuInfo = rootGetters['Adaptor/skuInfo']
      const { combo_buy_abt = {} } = getters

      const { isShowNewComboBuyStyleAbt, isShowComboBuyScreenStyleAbt } = combo_buy_abt
      // default 黑色 ｜ orange 橙色 ｜ screen_orange 橙色压缩
      let styleType = isShowNewComboBuyStyleAbt ? STYLE_TYPE.ORANGE : STYLE_TYPE.DEFAULT
      if (isShowComboBuyScreenStyleAbt) {
        styleType = STYLE_TYPE.ORANGE_SCREEN
      }

      return {
        abTest: combo_buy_abt.abTest,
        styleType,
        isDefaultStye: styleType === STYLE_TYPE.DEFAULT,
        skuInfo,
        isPaidUser,
        isHitComplianceMode,
        goodsId: baseInfo.goods_id,
        mallCode: baseInfo.mall_code,
      }
    },
    /** 
     * 组合购勾选商品
     */
    select_items(state) {
      return state.products.filter(d => d.promotionGroup === '1' && !d.soldOutStatus)
    },
    /** 
     * 组合购勾选商品的 SKU 数据
     */
    selected_goods_id_skus(state, getters) {
      const selectItems = getters.select_items
      if (!Array.isArray(selectItems) || !selectItems.length) return []
      return selectItems.map(d => d.sku_code ? `${d.goods_id},${d.mall_code},${d.sku_code}` : `${d.goods_id},${d.mall_code}` ).join('-')
    },
  },
  actions: {
    /**
     * 初始化数据
     */
    init_combo_buy({ commit }, _) {
      _.then((res = {}) => {
        const { togetherBetterDealsProdList = [], ...cBundlePrice } = res.info || {}
        commit('update_products', togetherBetterDealsProdList)
        commit('update_bundle_price', cBundlePrice)
      })
    },
    /** 
     * 更新组合购数据
     */
    async update_combo_buy_data({ state, commit, rootGetters }, payload = {}) {
      let { updateGoodsId = '', oldGoodsId = '',  selectedGoodsIdSkus, isEnterDetailCall = '0' } = payload

      const { cat_id, goods_id, goods_sn, mall_code } = rootGetters['Adaptor/base_info']
      const is_sold_out = rootGetters['Adaptor/is_sold_out']
      const is_paid_user = rootGetters['Adaptor/is_paid_user']

      const params = {
        cat_id,
        goods_id,
        goods_sn,
        mall_code,
        is_sold_out,
        is_paid_user,
        isEnterDetailCall,
        selectedGoodsIdSkus,
        ...( updateGoodsId ? { updateGoodsId } : {})
      }

      commit('update_loading', true)
      const { code, info = {} } = await getTogetherBetterDealsPrice(params)
      commit('update_loading', false)
      const { togetherBetterDealsProdList = [], ...cBundlePrice } = info
      if (code !== '0') return false

      // 更新推荐商品
      if (Array.isArray(togetherBetterDealsProdList) && togetherBetterDealsProdList.length) {
        // 更新skc
        if (togetherBetterDealsProdList.length === 1) {
          const updateProduct = togetherBetterDealsProdList[0]
          const tempProducts = state.products?.map(item => {
            // 找到同一个替换商品并购选
            updateProduct.promotionGroup = '1'
            return item.goods_id === oldGoodsId ? updateProduct : item
          })
          commit('update_products', tempProducts)
        } else {
          // 更新全部
          commit('update_products', togetherBetterDealsProdList)
        }

      }
      // 更新价格
      commit('update_bundle_price', cBundlePrice || {})

      return true
    },
  },
}
