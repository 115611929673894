<template>
  <div class="store-address-new-card">
    <div
      class="store-address-new-card-header"
      tabindex="0"
    >
      <h4 class="store-address-new-card-header__title">
        {{ language.SHEIN_KEY_PC_16785 }}
      </h4>
    </div>
    <div
      v-if="storeData && storeData.status == 1"
      class="store-address-new-card-body"
    >
      <div class="store-address-info">
        <div
          class="store-address-info__header"
          tabindex="0"
        >
          <div class="store-address-info__header-left-wrapper">
            <h5 class="store-address-info__name">
              <template v-if="storeData.countryId == '209'">
                {{ storeData.lname }} {{ storeData.fname }}
              </template>
              <template v-else>
                {{ storeData.fname }} {{ storeData.lname }}
              </template>
            </h5>
            <div class="store-address-info__telphone">
              {{ storeData.tel }}
            </div>
          </div>
          <div
            class="store-address-info__header-right-wrapper"
            @click="clickEditStoreAddress"
          >
            <i class="sh_pc_sui_icon_edit_accounts_18px"></i>
          </div>
        </div>

        <p
          class="store-address-info__store-address"
          tabindex="0"
        >
          <template v-if="storeData.countryId == 209">
            {{ storeData.storeName }}
            {{ storeData.storeType == 1 ? '(7-ELEVEN)' : '(全家便利商店)' }}
          </template>
          <template v-else>
            {{ storeData.storeName }} {{ storeData.address1 }}
            {{ storeData.address2 }}
          </template>
        </p>

        <p
          class="store-address-info__detail-address"
          tabindex="0"
        >
          <template v-if="storeData.countryId == 209">
            {{ storeData.address1 }} {{ storeData.address2 }}
          </template>
          {{ storeData.street }} {{ storeData.district }}
          {{ storeData.city }} {{ storeData.state }}
          {{ storeData.countryName }}
          {{ storeData.countryId != 209 ? storeData.postcode : '' }}

          <s-label
            class="store-address-info__tag"
            type="neutral"
            tabindex="0"
          >
            {{ language.SHEIN_KEY_PC_16785 }}
          </s-label>
        </p>
        <div
          v-if="sensitiveNote"
          class="store-address-info__error-tips"
          tabindex="0"
        >
          {{ sensitiveNote }}
        </div>
      </div>

      <!-- 店配地址特殊规则提示信息展示 -->
      <p
        v-if="storeAddressSpecialRuleTip.show"
        class="address-title-tips-screen-efficiency sdw"
        v-html="storeAddressSpecialRuleTip.rule"
      >
      </p>
    </div>

    <div
      v-else
      class="store-address-new-card__add-btn"
      @click="clickAddStoreAddress"
    >
      +{{ language.SHEIN_KEY_PC_16789 }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'StoreAddressCard',
  emits: ['click-edit-store-address'],
  props: {
    storeData: {
      type: [Object, String],
      default: () => {}
    },
    checkout: Object,
    status: Object,
    adrNameVerifiedTitleTip: {
      type: String,
      default: ''
    },
    defaultShipping: Array,
    language: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    sensitiveNote () {
      const sensitiveRuleVoList = this.storeData?.sensitiveRuleVoList || []
      if (!sensitiveRuleVoList.length) {
        return ''
      }
      return sensitiveRuleVoList?.[0]?.rule_content || ''
    },
    storeAddressSpecialRuleTip() {
      if (this.checkout.default_address?.countryId == '209' && this.status?.adrTitleTipsShow) {
        return {
          show: true,
          rule: this.adrNameVerifiedTitleTip
        }
      }
      if (this.status?.generalAddressTipRule && !!this.status?.generalAddressTipRule) {
        return {
          show: true,
          rule: this.status?.generalAddressTipRule
        }
      }
      return {
        show: false,
        rule: ''
      }
    }
  },
  methods: {
    clickAddStoreAddress() {
      window.checkoutEventCenter.emit('click-edit-store-address', { type: 'add' })
    },
    clickEditStoreAddress() {
      window.checkoutEventCenter.emit('click-edit-store-address', { type: 'edit' })
    }
  }
}
</script>

<style lang="less" scoped>
.border-left() {
  position: relative;
  border: 1px solid #e5e5e5;
  border-left: none;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background-image: url('/she_dist/images/border2-6cd4ecac71.png');
  }
}

.store-address-new-card {
  padding: 16px;
  color: @sui_color_gray_dark1;
  background: #fff;
  font-family: Arial;
  font-style: normal;

  & > .store-address-new-card-header {
    margin-bottom: 16px;

    &__title {
      height: 28px;
      font-size: 20px;
      font-family: Arial Black;
      color: #000;
      text-transform: capitalize;

      & when (@IS_RW) {
        font-family: Adieu;
      }
    }
  }

  & > .store-address-new-card-body {
    padding: 20px 12px;
    .border-left();
  }

  .store-address-info {

    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
    }

    &__header-left-wrapper{
      display: flex;
      flex-wrap: wrap;
      row-gap: 8px;
      margin-right: 16px;
    }

    &__name {
      margin-right: 16px;
      color: #000;
      font-size: 16px;
      font-weight: 700;
      text-transform: capitalize;
    }

    &__telphone {
      color: #959595;
      font-size: 16px;
      white-space: nowrap;
    }

    &__header-right-wrapper{
      display: flex;
      cursor: pointer;

      .sh_pc_sui_icon_edit_accounts_18px {
        display: inline-block;
        background: url(data:image/svg+xml;base64,PHN2ZyBzdHlsZT0id2lkdGg6IGF1dG87IGhlaWdodDogMWVtOyIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiB2aWV3Qm94PSIwIDAgMjAgMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTcuNjA1OSA2LjA4OTg0TDE0LjgyOTEgMy4yMTA2OUwxMy4zODk1IDQuNTk5MDhMMTYuMTY2MyA3LjQ3ODIzTDE3LjYwNTkgNi4wODk4NFpNMTUuMzAxNiA4LjMxMjE3TDEyLjUyNDggNS40MzMwMkw0Ljc0NzQxIDEyLjkzMzlWMTUuODEzMUg0Ljc0OTUxVjE1Ljg4NzlIMTYuMjAyOVYxNC44ODc5SDguNDgzNDNMMTUuMzAxNiA4LjMxMjE3WiIgZmlsbD0iYmxhY2siPgo8L3BhdGg+Cjwvc3ZnPg==) no-repeat;
        background-size: 100%;
        width: 18px;
        height: 18px;
      }
    }

    &__store-address {
      margin-bottom: 8px;
      color: #000;
      font-size: 14px;
    }

    &__detail-address {
      margin-bottom: 16px;
      color: #000;
      font-size: 14px;
    }

    &__tag {
      text-transform: capitalize;
    }

    &__error-tips {
      color: #BF4123;
      font-size: 13px;
    }
  }
  
  &__add-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 148px;
    padding: 20px 12px;
    font-size: 16px;
    font-weight: bold;
    color: #000;
    .border-left();
    cursor: pointer;
  }

  .address-title-tips-screen-efficiency {
    margin-top: 16px;
    font-size: 12px;
    line-height: 14px;
    padding: 10px;
    color: #666;
    background: #f6f6f6;
    a {
      color: #1860a7;
    }
  }
}
</style>
