import { click_discount_details } from 'public/src/pages/cart_v2/common/analysis/item/discountTag.js'

/**
 * DiscountNormalTag 原子组件操作
 */
export function useDiscountNormalTagEvent() {

  /**
   * 点击 
   */
  const onOpened = ({ itemOpts: { data }, analysis = {} }) => {
    click_discount_details(data.value, analysis.click_discount_details)
  }

  return {
    onOpened,
  }
}
