<template>
  <SDialog
    :visible="state.visible"
    type="W996"
    class="add-on-container j-add-on-container"
    @close-from-mask="handleClose"
  >
    <component
      :is="componentNameFromType"
      ref="addOnRef"
      v-bind="state.bindProps"
    >
      <template #default="slotInfo">
        <Recommends v-bind="slotInfo" />
      </template>
    </component>
  </SDialog>
</template>
<script setup>
import { provide, computed, defineAsyncComponent, watch, ref } from 'vue'
import { useAddOn } from 'public/src/pages/cart_v2/components/business/add-on/hooks/useAddOn.js'
import { daEventCenter } from '@/public/src/services/eventCenter/index'
import { HOOKS } from './utils/contants'
import mitt from 'mitt'
const emitter = mitt()

daEventCenter.addSubscriber({ modulecode: '1-7-4' })
daEventCenter.addSubscriber({ modulecode: '2-3' }) // 商卡

const Recommends = defineAsyncComponent(() => import('public/src/pages/cart_v2/components/business/add-on/comps/products/Recommends.vue'))
const TypeCoupon = defineAsyncComponent(() => import('public/src/pages/cart_v2/components/business/add-on/layout/typeCoupon/index.vue'))
const TypeFreeShipping = defineAsyncComponent(() => import('public/src/pages/cart_v2/components/business/add-on/layout/typeFreeShipping/index.vue'))
const TypePromotion = defineAsyncComponent(() => import('public/src/pages/cart_v2/components/business/add-on/layout/typePromotion/index.vue'))

const { state, open, close, viewCart, updateOpts, executeHook, updateAddCartNum } = useAddOn()

provide('state', state)
provide('emitter', emitter)

const addOnRef = ref(null)

const componentNameFromType = computed(() => {
  if (state.bindProps?.type === 'coupon') return TypeCoupon
  if (state.bindProps?.type === 'freeShipping') return TypeFreeShipping
  if (state.bindProps?.type === 'promotion') return TypePromotion
  return null
})

watch(() => state.visible, (v) => {
  if (v) {
    initEvents()
  } else {
    removeAllEvents()
  }
})

const initEvents = () => {
  emitter.on('EventExposeAddCart', ({ service_error = '' }) => {
    const extraData = addOnRef.value?.getSaExtraData?.() || {}
    daEventCenter.triggerNotice({
      daId: '1-7-4-13',
      extraData: {
        state: state.bindProps?.saInfo?.state,
        parameter_detail: state.bindProps,
        promotion_code: service_error ? '-' : extraData?.promotion_code,
        is_satisfied: extraData?.is_satisfied,
        service_error,
        is_multiple_coupons: extraData?.is_multiple_coupons || '-',
        newuser_label: extraData?.newuser_label || 0,
      }
    })
  })

  emitter.on('EventClose', () => {
    handleClose()
  })

  emitter.on('EventViewCart', () => {
    const extraData = addOnRef.value?.getSaExtraData?.() || {}
    const addCartNum = state.addCartNum
    daEventCenter.triggerNotice({
      daId: '1-7-4-10',
      extraData: {
        state: state.bindProps?.saInfo?.state,
        add_cart_number: extraData?.other_add_cart_number > 0 ? (addCartNum - extraData.other_add_cart_number) : addCartNum,
        ...extraData,
      },
    })
    viewCart()
  })

  emitter.on('EventUpdateCart', (payload) => {
    addOnRef.value?.handleCartUpdated?.(payload)
    updateAddCartNum()
    executeHook(HOOKS.updateCart)
  })
  
  emitter.on('EventUpdateBusinessCart', () => {
    executeHook(HOOKS.updateCart)
  })

  emitter.on('EventPickItem', (payload) => {
    const extraData = addOnRef.value?.getSaExtraData?.() || {}
    daEventCenter.triggerNotice({
      daId: '1-7-4-15',
      extraData: {
        add_cart_number: state.addCartNum,
        coupon_change: extraData?.coupon_change,
        action: extraData?.action,
      },
    })
    executeHook(HOOKS.pickItem, payload)
  })

  emitter.on('EventViewTap', (payload) => {
    executeHook(HOOKS.viewTap, payload)
  })
  emitter.on('EventOpenLogin', (payload) => {
    executeHook(HOOKS.openLogin, payload)
  })
}
const removeAllEvents = () => {
  emitter.all.clear()
}

const handleClose = () => {
  const extraData = addOnRef.value?.getSaExtraData?.() || {}
  const addCartNum = state.addCartNum
  daEventCenter.triggerNotice({
    daId: '1-7-4-14',
    extraData: {
      state: state.bindProps?.saInfo?.state,
      add_cart_number: extraData?.other_add_cart_number > 0 ? (addCartNum - extraData.other_add_cart_number) : addCartNum,
      ...extraData,
    },
  })

  close()
}

defineExpose({
  open,
  close,
  updateOpts,
})

</script>
<style lang="less" scoped>
.add-on-container {
  :deep(.sui-dialog__W996) {
    min-width: 996px;
    height: 80%;
    .sui-dialog__body, .sui-dialog__title-holder, .sui-dialog__footer-holder { /* stylelint-disable-line selector-max-specificity */
      padding: 0;
    }
  }
}
</style>
