import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

const appConfigs = useAppConfigs()

export const getCartNum = async () => {
  const res: any = await appConfigs.$schttp({
    url: '/order/get_cart_goods_sum_quantity',
    headers: {
      AppCurrency: appConfigs.$envs.currency,
      AppLanguage: appConfigs.$envs.appLanguage,
      'frontend-scene': 'page_web_common',
    },
  })
  return res.data
}
