<template>
  <LazyHydrationWrapper :when-triggered="isIdle">
    <PageHeader 
      container1350
      :locals="state.locals"
      :page-header-language="state.language"
    >
      <div class="new-cart-address">
        <CartAddress />
      </div>
    </PageHeader>
    <NavBar
      :links="links"
      :active="0"
    >
      <template #notice>
        <div
          v-if="!showSkeleton && showNotice"
        >
          <cartNotice 
            :message="state.noticeMessage"
            :language="state.language"
            :locals="state.locals"
          />
        </div>
      </template>
    </NavBar>
  </LazyHydrationWrapper>
</template>

<script>
export default {
  name: 'CartHeader'
}
</script>
<script setup>
import { defineAsyncComponent, computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { LazyHydrationWrapper } from '@shein-aidc/shein-lazy-hydration'

const PageHeader = defineAsyncComponent(()=>import(/* webpackChunkName: "cartv2_top_part" */'public/src/pages/components/page-header/PageHeader.vue'))
const NavBar = defineAsyncComponent(()=>import(/* webpackChunkName: "cartv2_top_part" */'public/src/pages/components/nav-bar/NavBar.vue'))
const CartNotice = defineAsyncComponent(()=>import(/* webpackChunkName: "cartv2_top_part" */'public/src/pages/cart_v2/components/functional/cartNotice/index.vue'))
const CartAddress = defineAsyncComponent(()=>import(/* webpackChunkName: "cartv2_top_part" */'public/src/pages/cart_v2/components/functional/cart-address/index.vue'))

const { state } = useStore()

let isIdle = ref(false)

const showSkeleton = computed(() => state.showSkeleton)
const links = computed(() => {
  return [state.language.SHEIN_KEY_PC_14710, state.language.SHEIN_KEY_PC_14711, state.language.SHEIN_KEY_PC_14714, state.language.SHEIN_KEY_PC_14715]
})
const showNotice = computed(() => {
  return !(!state.loginStatus && state.cartInfo.cartSumQuantity) && state.noticeMessage // 未登录非空车不展示
})
onMounted(() => {
  requestIdleCallback(() => {
    isIdle.value = true
  })
})
</script>
<style lang="less">
.new-cart-address { 
  flex: 1;
  overflow: hidden;
  display: flex;
}
</style>
