/**
 * 与接口相关的工具函数
 */
import { getLocalStorage } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import itemSer from 'public/src/services/goodsItemInfo/interfaceSer'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'

/**
 * 移除购物车商品到心愿单
 * @param {String} id 购物车列表项id
 */
export const deleteCartItem = ({ id } = {}) => {
  return schttp({
    url: '/api/cart/delete',
    method: 'POST',
    data: {
      id,
      cart_prime_product_code: window.cart_prime_product_code || ''
    }
  })
}

/**
 * 批量加车
 */
export const batchAddMall = (product_list) => {
  const data = {
    product_list,
  }
  return schttp({
    url: '/api/cart/batch_add_mall/create',
    method: 'POST',
    data,
  })
}

/**
 * 批量删除
 * @param {Array, String, Number} ids 需要删除的商品的cartId
 */
export const batchDelete = ({ ids = [], page = 'cart', filterCheck } = {}) => {
  const batchIds = [].concat(ids)
  return schttp({
    url: '/api/cart/batchDelete/update',
    params: {
      ids: batchIds,
      page: page
    },
    data: {
      cart_prime_product_code: window.cart_prime_product_code || ''
    }
  }).then(res => {
    if (filterCheck == 1 && res?.info?.carts?.length) {
      // 下单页无库存弹窗删除删除商品更新购物车时，在下单页过滤掉未选中的商品
      res.info.carts = res.info.carts.filter(item => item.is_checked == 1)
    }
    return res
  })
}

/**
 * 批量收藏
 * @param {Array, String, Number} ids 需要收藏的商品的cartId
 */
export const batchWishGoods = ({ ids = [], filterCheck } = {}) => {
  const batchIds = [].concat(ids)
  return schttp({
    url: '/api/cart/batchWishGoods/update',
    method: 'POST',
    data: {
      id: batchIds,
      cart_prime_product_code: window.cart_prime_product_code || ''
    }
  }).then(res => {
    if (filterCheck == 1 && res?.info?.carts?.length) {
      // 下单页无库存弹窗删除删除商品更新购物车时，在下单页过滤掉未选中的商品
      res.info.carts = res.info.carts.filter(item => item.is_checked == 1)
    }
    return res
  })
}

export const updateCart = () => {
  return schttp({
    url: '/api/cart/checkcart/get',
    params: {
      cart_prime_product_code: window.cart_prime_product_code || ''
    }
  })
}

/**
 * 获取优惠券
 */
export const getCouponListForCart = () => {
  return schttp({
    url: '/api/coupon/getNewCouponListForCart/get'
  })
}

export const getTaxTip = (params) => {
  return schttp({
    url: '/api/cart/getIncludeTaxInfo/get',
    method: 'POST',
    data: {
      ...params
      // outside params
      // 'lang': 'en', 
      // "siteUid": "pwus"
    }
  })
}

export function fetchAtomicInfo(products, fields) {
  return itemSer.getAtomicInfo({
    goods: products,
    needAssign: true,
    fields: fields,
    pageKey: 'page_cart'
  })
}

// 批量查询用户信息
export function getMembersInfo(memberIds) {
  return schttp({
    url: '/api/user/auth/batchMemberInfo/get',
    method: 'POST',
    data: {
      memberIds,
    }
  })
}

export async function queryLatestUnPaidOrderInfo() {
  const formatUnPaidOrderInfo = (orderInfo, isSupportCountryInfo) => {
    let orderData = {}
    if (isSupportCountryInfo?.info?.res == 1) {
      orderData = orderInfo?.info?.order_list?.find(order => order?.order_type == 1) || {}
    }
    return {
      traceId: [orderInfo?.traceId, isSupportCountryInfo?.traceId],
      orderData
    }
  }
  let data = await schttp({
    url: '/api/cart/queryLatestUnPaidOrderInfo/get',
    method: 'POST',
    data: {
      isClient: true // 自定义的参数, 标明客户端请求, 需要串行聚合两个接口
    }
  })
  return formatUnPaidOrderInfo(data.unPaidOrderInfo, data.isSuppoortCountry)
}

const getAddressInfo = () => {
  const addressCookie = getLocalStorage('addressCookie')
  let addressInfo = {}
  if (addressCookie) {
    try {
      addressInfo = typeof addressCookie === 'string' ? JSON.parse(addressCookie) : addressCookie
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('获取地址缓存失败', error)
    }
  }
  return addressInfo
}

/**
 * 获取赠品列表
 * @param {object} param
 * @param {string} param.promotion_id 促销活动 id
 * @param {number} param.range 活动的档次
 * @param {number} param.page 页码
 * @param {number} param.limit 每页数量
 * @param {string} param.delivery_id 投放 id
 * @returns 
 */
export const getGiftProductList = ({ promotion_id, range, page, limit, delivery_id }) => {
  const { currency, appLanguage } = window.gbCommonInfo
  const addressInfo = getAddressInfo()

  // 此处app中间层要求请求体格式为formData
  const formData = new FormData()
  formData.append('promotion_id', promotion_id)
  formData.append('range', range)
  formData.append('page', page)
  formData.append('limit', limit)
  formData.append('country_id', addressInfo?.countryId)
  delivery_id && formData.append('delivery_id', delivery_id)

  return schttp({
    method: 'POST',
    url: '/promotion/gift_product_list',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

/**
 * 获取加价购换购列表
 * @param {object} param
 * @param {string} param.promotion_id 促销活动 id
 * @param {number} param.range 活动的档次
 * @param {string} param.brand 品牌code
 * @param {number} param.page 页码
 * @param {number} param.limit 每页数量
 * @param {string} param.delivery_id 投放 id
 * @param {string} param.filter_cate_id 过滤分类id
 * @param {string} param.cate_id 分类id
 * @param {string} param.did 商品分类投放id
 * @returns 
 */
export const getAddBuyProductList = ({ promotion_id, range, brand, page, limit, delivery_id, filter_cate_id, cate_id, did }) => {
  const { currency, appLanguage } = window.gbCommonInfo
  const addressInfo = getAddressInfo()

  // 此处app中间层要求请求体格式为formData
  const formData = new FormData()
  formData.append('promotion_id', promotion_id)
  formData.append('range', range)
  brand && formData.append('brand', brand)
  formData.append('page', page)
  formData.append('limit', limit)
  formData.append('country_id', addressInfo?.countryId)
  delivery_id && formData.append('delivery_id', delivery_id)
  filter_cate_id && formData.append('filter_cate_id', filter_cate_id)
  cate_id && formData.append('cate_id', cate_id)
  did && formData.append('did', did)

  return schttp({
    method: 'POST',
    url: '/promotion/add_buy_product_list',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

export const getCancelOrderInfo = ({ cachedOrderIdList }) => {
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'POST',
    url: '/cart/order/unpaid_order_info',
    data: {
      cachedOrderIdList,
    },
    headers: {
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

const cartCommonParams =  async (params = {}) =>{
  const { Cartshowcoupon } = await getUserAbtData()
  const addressInfo = getAddressInfo()
  return {
    address_id: addressInfo?.addressId,
    auto_use_coupon: Cartshowcoupon?.param?.cart_show_coupon_switch == 'on' ? 1 : 0, // 是否自动使用优惠券
    city: addressInfo?.city || '',
    country_id: addressInfo?.countryId,
    couponFilterGoods: [],
    district: addressInfo?.district || '',
    filterTagId: '',
    filter_tag: '',
    holdTopFilterTagIds: '',
    isOpenScreenEfficiency: '1',
    postcode: addressInfo?.postcode || '',
    state: addressInfo?.state || '',
    userLocalSizeCountry: window.localStorage.getItem('last_select_country'),
    ...params
  }
}

export const getCartInfo = async (params = {}) => {
  let data = await cartCommonParams(params)
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'POST',
    url: '/order/mall/cart/index',
    data: data,
    headers: {
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

/**
 * 获取多语言数据
 * @param {Array} params - 请求参数 ['SHEIN_KEY_PWA_xxx', 'SHEIN_KEY_PWA_xxx', 'SHEIN_KEY_PWA_xxx']
 *
 *  */ 
export const getLanguageApi = (languageKeys = []) => {
  return schttp({
    url: '/system/configs/multi_language',
    method: 'POST',
    data: { languageKeys },
    headers: {
      'Content-Type': 'application/json'
    },
    useBffApi: true
  })
}

/**
 * 获取多语言数据
 * @param {Object} params - 请求参数 {posKeys:['xxx', 'xxx', 'xxx'],newPosKeys:['xxx', 'xxx', 'xxx']}
 */
export const getAbt = (params) => {
  let oldPosKeys = params?.posKeys?.map(item => `SPc${item}`) || []
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'GET',
    url: '/abt/merge/get_abt_by_poskey',
    params: {
      posKeys: [...oldPosKeys, ...params?.newPosKeys || []].join(',')
    },
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage,
    },
    useBffApi: true,
  }).then((res)=>{
    let ABTParams = {}
    if (res.code == 0) {
      let pos = res.info?.pos || {}
      Object.keys(pos).forEach(key => {
        let poskey = oldPosKeys.includes(key) ? key.replace(/(SPc)(\w*)/, '$2') : key
        ABTParams[poskey] = pos[key] || {}
        if(pos[key] && !(pos[key].param)){
          ABTParams[poskey].param = ABTParams[poskey].p
        }
      })
    }
    return ABTParams
  }) 
}

/**
 * 勾选购物车商品
 * @param {Object} params - 请求参数 {append_id_list: ['xxx'], cart_id_list: [1], operation_type: 0}
 * append_id_list: 附属品购物车行id集合
 * cart_id_list: 购物车行id集合
 * operation_type: 操作类型1-勾选 2-取消勾选 3-全部勾选 4-全部取消勾选
 */
export const modifyCartCheckStatus = async (params) => {
  let data = await cartCommonParams(params)
  const formData = new FormData()
  Object.keys(data).forEach(key => {
    formData.append(key, data[key] || '')
  })
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'POST',
    url: '/order/mall/modify_cart_check_status',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

/**
 * 购物车商品数量更新
 * @param {Object} params - 请求参数 {append_id_list: ['xxx'], cart_id: xxx, is_checked: 0, quantity; 1}
 * append_id_list: 附属品购物车行id集合
 * cart_id: 购物车行id
 * quantity: 数量
 * is_checked: 是否勾选
 */
export const cartUpdate = async (params) => {
  let data = await cartCommonParams(params)
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'POST',
    url: '/order/mall/cart_update',
    data: data,
    headers: {
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

export const checkStock = () => {
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'GET',
    url: '/order/cart/stock/check',
    params: { is_old_version: 0 },
    headers: {
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

/**
 * 购物车商品删除
 * @param {object} params 
 * @param {Array} params.ids 商品行id数组
 * @returns 
 */
export const cartDelete = async (params) => {
  let data = await cartCommonParams(params)
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'POST',
    url: '/order/mall/cart_delete',
    data,
    headers: {
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

/**
 * 更新属性
 */
export const updateAttrMall = (data) => {
  return schttp({
    url: '/api/cart/update_attr_mall/update',
    method: 'POST',
    data: {
      ...data,
      cart_prime_product_code: window.cart_prime_product_code || ''
    }
  })
}

export function getCartIndex(data) {
  const { currency, appLanguage } = window.gbCommonInfo
  const addressCookie = getLocalStorage('addressCookie')
  const localCache = JSON.parse(localStorage.getItem('localCache'))
  return schttp({
    method: 'POST',
    url: '/order/mall/cart/index',
    data: {
      is_first: '0',
      prime_product_code: '',
      isOpenScreenEfficiency: '1',
      city: localCache?.city,
      district: localCache?.district,
      postcode: localCache?.postcode,
      state: localCache?.state,
      address_id: addressCookie?.addressId,
      country_id: addressCookie?.countryId,
      ...data,
    },
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage,
    },
    useBffApi: true,
  })
}

/**
 * 获取商品主图蒙层
 */
export const getMaskLayerGoodsMap = (params) => {
  const { goodsSns, maskLayerAndTsp } = params
  return schttp({
    url: '/api/cart/getMaskLayerGoodsMap/get',
    method: 'POST',
    data: {
      goodsSns,
      maskLayerAndTsp,
    }
  })
}
