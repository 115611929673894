import type { DS_BindGiftCard } from '../../../types'
import { getLangs } from '@shein-aidc/bs-sdk-libs-manager'

// TODO write your apis
export const api1: DS_BindGiftCard.APIS_THIS['api1'] = async function (x, y, z) {
  const result = await this.$schttp<Array<number>>({
    url: '/api/*',
    params: { x, y, z },
  })
  return result.data
}

export const postBindGiftCardApi: DS_BindGiftCard.APIS_THIS['postBindGiftCardApi'] = async function (url: string = '', datas: object = {}) {
  const result = await this.$schttp<Array<number>>({
    url: url,
    method: 'POST',
    data: {
      ...datas,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return result.data
}

const langsMap = {
  SHEIN_KEY_PWA_17514: 'SHEIN_KEY_PWA_17514',
  SHEIN_KEY_PWA_15847: 'SHEIN_KEY_PWA_15847',
  SHEIN_KEY_PWA_15850: 'SHEIN_KEY_PWA_15850',
  SHEIN_KEY_PWA_15849: 'SHEIN_KEY_PWA_15849',
  SHEIN_KEY_PWA_31762: 'SHEIN_KEY_PWA_31762',
  SHEIN_KEY_PWA_35112: 'SHEIN_KEY_PWA_35112',
  SHEIN_KEY_PWA_35113: 'SHEIN_KEY_PWA_35113',
  SHEIN_KEY_PWA_35114: 'SHEIN_KEY_PWA_35114',
  SHEIN_KEY_PWA_35115: 'SHEIN_KEY_PWA_35115',
  SHEIN_KEY_PWA_34593: 'SHEIN_KEY_PWA_34593',
  SHEIN_KEY_PWA_35128: 'SHEIN_KEY_PWA_35128',
  SHEIN_KEY_PWA_35129: 'SHEIN_KEY_PWA_35129',
  SHEIN_KEY_PWA_35127: 'SHEIN_KEY_PWA_35127',
  SHEIN_KEY_PWA_35130: 'SHEIN_KEY_PWA_35130',
  SHEIN_KEY_PWA_35131: 'SHEIN_KEY_PWA_35131',
  SHEIN_KEY_PWA_35132: 'SHEIN_KEY_PWA_35132',
  SHEIN_KEY_PWA_35133: 'SHEIN_KEY_PWA_35133',
  SHEIN_KEY_PWA_35134: 'SHEIN_KEY_PWA_35134',
  SHEIN_KEY_PWA_35126: 'SHEIN_KEY_PWA_35126',
  SHEIN_KEY_PWA_35110: 'SHEIN_KEY_PWA_35110',
  SHEIN_KEY_PWA_35111: 'SHEIN_KEY_PWA_35111',
  SHEIN_KEY_PWA_29480: 'SHEIN_KEY_PWA_29480',
}
export const langs: DS_BindGiftCard.APIS_THIS['langs'] = async function () {
  const result = await getLangs(langsMap, this.$schttp)
  return result
}

const langsMapLaptop = {
  SHEIN_KEY_PC_33271: 'SHEIN_KEY_PC_33271',
  SHEIN_KEY_PC_33256: 'SHEIN_KEY_PC_33256',
  SHEIN_KEY_PC_33257: 'SHEIN_KEY_PC_33257',
  SHEIN_KEY_PC_33272: 'SHEIN_KEY_PC_33272',
  SHEIN_KEY_PC_33273: 'SHEIN_KEY_PC_33273',
  SHEIN_KEY_PC_33274: 'SHEIN_KEY_PC_33274',
  SHEIN_KEY_PC_33275: 'SHEIN_KEY_PC_33275',
  SHEIN_KEY_PC_33276: 'SHEIN_KEY_PC_33276',
  SHEIN_KEY_PC_33277: 'SHEIN_KEY_PC_33277',
  SHEIN_KEY_PC_33278: 'SHEIN_KEY_PC_33278',
  SHEIN_KEY_PC_33279: 'SHEIN_KEY_PC_33279',
  SHEIN_KEY_PC_33259: 'SHEIN_KEY_PC_33259',
  SHEIN_KEY_PC_33260: 'SHEIN_KEY_PC_33260',
  SHEIN_KEY_PC_33261: 'SHEIN_KEY_PC_33261',
  SHEIN_KEY_PC_24336: 'SHEIN_KEY_PC_24336',
  SHEIN_KEY_PC_33555: 'SHEIN_KEY_PC_33555',
  SHEIN_KEY_PC_31378: 'SHEIN_KEY_PC_31378',
  SHEIN_KEY_PC_33258: 'SHEIN_KEY_PC_33258',
  SHEIN_KEY_PC_29559: 'SHEIN_KEY_PC_29559',
}
export const langsLaptop: DS_BindGiftCard.APIS_THIS['langsLaptop'] = async function () {
  const result = await getLangs(langsMapLaptop, this.$schttp)
  return result
}
