import { isEqual } from 'lodash'
import overLimitMutaion from './overLimitMutaion'
import commonModal from './commonModal'

const cartDialogComponentMap = {
  fullGiftShow: 'cartFullGiftsDrawIsLoaded',
  addBuyShow: 'cartAddBuyDrawIsLoaded',
  // buyGiftShow: 'cartBuyGiftDrawIsLoaded',
  batchActive: 'batchActiveIsLoaded',
  // clubGiftShow: 'cartClubGiftDrawIsLoaded'
  showSoldOutItemDialog: 'soldOutItemDialogIsLoaded',
  showSoldOutDialog: 'soldOutDialogIsLoaded',
  showLowPriceUser: 'lowPriceUserDialogIsLoaded',
}

const getPickStatus = (promotion) => {
  if (!promotion?.type_id) return ''
  return [22, 13].includes(+promotion?.type_id) ? 'addBuyShow' : 'fullGiftShow'
}

const mutations = {
  updateState (state, payload) {
    const { key, value } = payload
    if(cartDialogComponentMap[key] && !state.asyncComponentLoadedStatus[cartDialogComponentMap[key]]){
      state.asyncComponentLoadedStatus[cartDialogComponentMap[key]] = true
    }
    // loadingShow 使用计数，避免多个请求并发时，loading状态不正确
    if (key === 'loadingShow') {
      if (value) {
        state[key]++
      } else {
        state[key] = state[key] - 1 < 0 ? 0 : state[key] - 1
      }
    } else {
      state[key] = value
    }
  },
  updateCartItemMap(state, payload) {
    const { carts } = payload
    carts.forEach(item => {
      if (state.cartItemMap[item.id] === undefined || !isEqual(state.cartItemMap[item.id], item)) {
        state.cartItemMap[item.id] = item
        return
      }
    })
  },
  updateFilterState({ filterState }, payload) {
    Object.assign(filterState, payload)
  },
  updateCarouselState({ carouselState }, payload) {
    Object.assign(carouselState, payload)
  },
  updatePromotionState({ promotionState }, payload) {
    Object.assign(promotionState, payload)
  },
  updateOrderState({ orderState }, payload) {
    Object.assign(orderState, payload)
  },
  updateCheckoutState({ checkoutState }, payload) {
    Object.assign(checkoutState, payload)
  },
  handleBatchActive (state, payload) {
    const { value, checkSoldout } = payload

    if (value === undefined) {
      state.batchActive = !state.batchActive
    } else {
      if(cartDialogComponentMap['batchActive'] && !state.asyncComponentLoadedStatus[cartDialogComponentMap['batchActive']]){
        state.asyncComponentLoadedStatus[cartDialogComponentMap['batchActive']] = true
        state.loadingShow++
      }
      state.batchActive = value
    }

    if (state.batchActive) {
      if (checkSoldout) {
        state.batchEditItemIds = [].concat(state.soldoutItems.map(item => item.id))
      }
    } else {
      state.batchEditItemIds = []
    }
  },
  // 更新领券弹窗状态
  toggleCartGetCouponDrawer(state, payload) {
    const { value = false } = payload
    state.drawerGetCoupon = !!value
  },
  /**
   * 
   * @param {*} state
   * @param {*} payload {
   *  promotionId: 活动id,
   *  fromType: 'pick' | 'addItem' ｜ 'addItem-auto'
   *  }
   */
  changeAppendageDrawerStatus (state, payload) {
    const { promotionId, fromType = 'pick' } = payload
    const promoItem = state.promotionState.integrityPromotionInfo?.[promotionId]?.data
    const status = getPickStatus(promoItem)
    if (!promoItem) return
    if(cartDialogComponentMap[status] && !state.asyncComponentLoadedStatus[cartDialogComponentMap[status]]){
      state.asyncComponentLoadedStatus[cartDialogComponentMap[status]] = true
    }
    state.appendageDrawer = {
      promotionId,
      fromType,
      fullGiftShow: status == 'fullGiftShow',
      addBuyShow: status == 'addBuyShow',
    }
  },
  resetAppendageDrawerStatus (state) {
    state.appendageDrawer = {
      promotionId: '',
      fromType: '',
      fullGiftShow: false,
      addBuyShow: false,
    }
  },
}

export default {
  ...mutations,
  ...overLimitMutaion,
  ...commonModal,
}
