import * as monitor from '@shein-aidc/client-monitor-union-log/async-exports'
import * as originMonitor from './origin_index'

const ENABLE_MONITOR_UNION = gbCommonInfo && (gbCommonInfo.ENABLE_MONITOR_UNION || (gbCommonInfo.ENABLE_MONITOR_UNION_PAGE !== '' && gbCommonInfo.templateType === gbCommonInfo.ENABLE_MONITOR_UNION_PAGE))

export const SILogger = ENABLE_MONITOR_UNION ? monitor.SILogger : originMonitor.SILogger
export const SIMetric = ENABLE_MONITOR_UNION ? monitor.SIMetric : originMonitor.SIMetric
export const useVueErrorHandler = monitor.useVueErrorHandler

export default ENABLE_MONITOR_UNION ? monitor.SILog : originMonitor.default
