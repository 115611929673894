<template>
  <s-dialog
    v-model:visible="isShow"
    class="cart-promotion__drawer"
    :show-close="false"
    type="W996"
    @closed="handleClosed"
  >
    <ModalHeader
      :title="attachmentInfo.attachmentChooseTips"
      :count-down-time-stamp="promotionItem.is_count_down == 1 ? promotionItem.end_time : 0"
      @close="handleClose"
    />
    <div class="cart-promotion__tab-alert-wrapper">
      <GearTab
        :model-value="activeIndex"
        :tabs="tabs"
        :type-id="typeId"
        :fulfill-status="fulfillStatus"
        :promotion-id="promotionId"
        @change="switchTab"
      />
      <AlertTips
        v-if="activeIndex > -1"
        :text="currentTabItem.tips"
        :promotion-type-id="typeId"
        :show-add-btn="notSatisfied"
        :promotion-id="promotionId"
        :level="currentTabItem.range"
        :popup-info-id="promotionItem.popupInfoId"
      />
    </div>
    <BrandFilter
      v-if="brands.length > 1 && activedItem.listBrandArrayTotal > 5"
      :brands="brands"
      :selected-brand-code="activedItem.brands"
      :promotion-id="promotionId"
      @change="tagChange"
    />
    <ul
      v-show="!firstLoad"
      ref="productList"
      v-infinite-scroll="handleInfiniteScroll"
      class="promotion-product__list"
      infinite-scroll-disabled="disabledScroll"
      infinite-scroll-nodata="false"
      infinite-scroll-distance="10"
    >
      <PromotionProductItem
        v-for="(item, index) in activedItem.list"
        :key="'promotion-list-item-' + index + '-' + item.goods_id"
        :item="item"
        :promotion-item="promotionItem"
        :is-disabled="notSatisfied"
        :fulfill-status="fulfillStatus"
        :index="index"
        :disabled-tip="currentTabItem.tips"
        :page="activedItem.page"
      />
    </ul>
    <Skeleton v-show="firstLoad" />
  </s-dialog>
</template>

<script>
import { mapState, useStore } from 'vuex'
import ModalHeader from './components/Header.vue'
import GearTab from './components/GearTab.vue'
import AlertTips from './components/AlertTips.vue'
import PromotionProductItem from './components/PromotionProductItem.vue'
import BrandFilter from './components/BrandFilter.vue'
import { daEventCenter } from 'public/src/services/eventCenter'
import { useAppendageHandle } from './components/appendageHandle.js'
import { getAddBuyProductList } from 'public/src/pages/cart_v2/services/api.js'
import Skeleton from './components/Skeleton.vue'

function createDefaultList ({ index, range, delivery_id = '' }) {
  return {
    total: 0, // 总数量
    page: 1, // 页数
    list: [], // 商品
    loading: false,   // 是否正在请求
    index, // 活动多档key
    complete: false,  // 是否全部加载完毕
    brands: '', // 品牌
    listBrandArray: [], // 品牌数据
    listBrandArrayTotal: 0, // 品牌总数
    range,
    delivery_id,
    filter_cate_id: '', // 过滤分类id
    cate_id: '', // 分类id
    did: '', // 商品分类投放id
  }
}

export default {
  name: 'AddBuyModal',

  // 组件注册
  components: {
    ModalHeader,
    GearTab,
    AlertTips,
    PromotionProductItem,
    BrandFilter,
    Skeleton
  },
  setup() {
    let store = useStore()
    const { 
      isShow,
      promotionId,
      promotionItem,
      typeId,
      isLoaded,
      tabs,
      currentRange,
      fulfillStatus,
      attachmentInfo,
      // getAtomicInfo,
      difference,
      handleClose
    } = useAppendageHandle(store, 'addBuyShow')
    return {
      isLoaded,
      promotionItem,
      typeId,
      isShow,
      promotionId,
      tabs,
      currentRange,
      fulfillStatus,
      attachmentInfo,
      // getAtomicInfo,
      difference,
      handleClose
    }
  },
  data () {
    return {
      activeIndex: -1,
      listArray: [],
      firstLoad: true
    }
  },
  // 计算属性
  computed: {
    ...mapState(['language', 'appendageDrawer']),
    currentTabItem() {
      return this.tabs?.find(v => v.id == this.activeIndex) || {}
    },
    activedItem() {
      return this.listArray.find(item => item.index == this.activeIndex) || {}
    },
    notSatisfied() {
      return this.activedItem.range > this.currentRange
    },
    disabledScroll() {
      return this.activedItem.complete ? true : false
    },
    brands() {
      return this.activedItem.listBrandArray || []
    },
    /**
     * wiki.pageId=935214792
     * 当有已满足门槛的档次时，从购物车跳转至附属品列表，优先展示的是已满足且有可售附属品档次中的门槛最高的档次
     * */
    currentIndex() {
      let reached = -1
      let range = this.currentRange
      if (this.fulfillStatus !== 0) { // 满足满赠条件
        while (range && (!(this.listArray[range - 1].list?.find(item => item.on_sale_status == '1')))) { // 有可售附属品档次中的门槛最高的档次
          range--
        }
        reached = range ? range - 1 : this.currentRange - 1
      } else { // 所有档次都未满足
        let ruleIndex = 0
        while (this.listArray?.[ruleIndex]?.list && !(this.listArray?.[ruleIndex]?.list?.find(item => item.on_sale_status == '1')) ){ // 有可售附属品档次中的门槛最低的档次
          ruleIndex++
        }
        reached = ruleIndex < this.listArray.length ? ruleIndex : 0
      }
      return reached
    },
  },

  watch: {
    isShow(n) {
      if (n) {
        this.handleShow()
      }
    }
  },

  // 方法实现
  methods: {
    switchTab (id) {
      this.activeIndex = id
      this.$refs.productList.scrollTop = 0
    },
    async handleShow () {
      this.activeIndex = -1
      this.setDefaultData()
      await this.init()
      
      // 默认展示最高档
      this.$nextTick(() => {
        if(this.appendageDrawer.fromType == 'addItem-auto'){
          this.$message({
            message: this.language.SHEIN_KEY_PC_28558,
            type: 'info'
          })
        }
        this.activeIndex = this.currentIndex
        if(this.tabs.length == 1){
          daEventCenter.triggerNotice({
            daId: '1-8-9-1',
            extraData: {
              promotion_typeid: this.typeId,
              promotion_code: this.promotionId,
              is_satisfied: this.notSatisfied ? 0 : 1,
              promotion_state: this.fulfillStatus,
              level: 1
            }
          })
        }
      })
    },
    /**
     * 初始化
     */
    async init () {
      let promiseList = []
      this.listArray.forEach((item) => {
        promiseList.push(this.reFetch({ tabIndex: item.index }))
      })
      await Promise.all(promiseList)
      this.firstLoad = false
    },
    /**
     * 设置默认属性：tabs & listArray
     */
    setDefaultData () {
      let lists = []
      if (!this.promotionItem) return 

      let rules = this.attachmentInfo.attachmentChooseHeadList || []
      if (rules.length) {
        rules.forEach((rule, index) => {
          lists.push(createDefaultList({ 
            index,
            range: rule.range,
            delivery_id: rule.delivery_id
          }))
        })
      }
      this.listArray = lists
    },
    /**
     * 发送请求
     * @param {Number} tabIndex 请求的列表index
     * @param {Boolean} loadMore 加载更多
     */
    async reFetch ({ tabIndex = 0, loadMore = false } = {}) {
      const target = this.listArray[tabIndex]
      if (!target || target.loading) return

      if (loadMore) {
        if (target.complete) return
        target.page++
      }

      target.loading = true

      const handleListReady = list => {
        target.loading = false
        if (loadMore) {
          target.list = target.list.concat(list)
        } else {
          target.list = list
          this.$nextTick(() => {
            document.querySelector('.promotion-product__list')?.scrollTo({ top: 0 })
          })
        }
      }

      const list = await this.fetchData({ tabIndex, page: target.page, brands: target.brands, delivery_id: target.delivery_id, filter_cate_id: target.filter_cate_id, cate_id: target.cate_id, did: target.did })
        .catch(() => {
          target.loading = false
          return []
        })

      if (list.length) {
        handleListReady(list)
      }
    },
    /**
     * 列表请求
     */
    async fetchData ({ tabIndex = 0, limit = 20, page = 1, brands = '', delivery_id, filter_cate_id, cate_id, did }) {
      const requestData = {
        promotion_id: this.promotionId,
        range: tabIndex + 1,
        limit,
        page,
        brand: brands,
        delivery_id,
        filter_cate_id,
        cate_id,
        did
      }
      try {
        let res = await getAddBuyProductList(requestData)

        if (res.code == 0 && res.info && res.info.list) {
          const resInfo = res.info
          const { list, total = 0, brand = [] } = resInfo
          // 达到最后一页
          const target = this.listArray[tabIndex]
          const listTotal = list.length + (target.list && target.list.length || 0)
          if (listTotal >= +total) target.complete = true
          if (!brands && brand.length && !target.listBrandArrayTotal) {
            // 没有品牌设置品牌和总类型数量
            target.listBrandArray = brand
            target.listBrandArrayTotal = total
          }
         
          target.total = total
          return list
        } else {
          return []
        }
      } catch (e) {
        // console.log(e)
        return []
      }
    },

    /**
     * 滚动到底
     */
    handleInfiniteScroll () {
      this.reFetch({ loadMore: true, tabIndex: this.activeIndex })
    },
    tagChange ({ brand_code = '', filter_cate_id = '', cate_id = '', did } = {}) {
      const tabIndex = this.activeIndex
      const target = this.activedItem
      target.complete = false
      target.brands = brand_code
      target.filter_cate_id = filter_cate_id
      target.cate_id = cate_id
      target.did = did
      target.page = 1
      target.loading = false
      
      this.reFetch({ tabIndex })
    },
    handleClosed() {
      this.reset()
    },
    reset() {
      this.listArray = []
      this.firstLoad = true
    }
  },
}
</script>

<style lang='less' scoped>
.cart-promotion__drawer{
  :deep(.sui-dialog__title-holder){
    padding: 0;
  }
  :deep(.sui-dialog__body){
    display: flex;
    flex-direction: column;
    padding: 0;
    min-height: 50vh;
    overflow: hidden;
  }
  .cart-promotion__tab-alert-wrapper{
    flex-shrink: 0;
    background: #fff;
    display: flex;
    justify-content: space-between;
    height: 44px;
    align-items: center;
    border-bottom: 1px solid #F1F1F1;
    padding: 0 48px;
  }
  .promotion-product__list{
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    padding: 0 28px 0 48px;
    align-items: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    .loading-content{
      position: absolute;
      left: 50%;
      top: 50%;
    }
  }
}
</style>
