import { goodsTransformation } from 'public/src/services/goodsItemInfo/goodsTransformation/index.js'
import { GoodsItemInfo } from 'public/src/services/goodsItemInfo'
import { getNewGoodsCardV3Config } from 'public/src/pages/goods_detail_v2/utils/productDetail.js'
import { Expose } from '@shein-aidc/basis-expose'
import {
    unref,
    shallowRef,
    computed,
    onMounted,
    onUnmounted,
    onBeforeUnmount,
} from 'vue'
import { EItemConfigType, getProductBffDependData, getItemLanguage } from 'public/src/pages/detail/main/utils/recommend-utils/index.js'
import { useMapState, useMapMutation } from 'public/src/pages/detail/main/hooks/vuex.composition.js'
/**
 * 获取商卡使用的基本数据
 * @param {isPaidUser} 是否会员
 * @returns 
 */
export function useProductCardData({ isPaidUser } = {}) {
  /**
   * 商卡多语言
   */
  const itemLanguage = shallowRef({})
  const goodsItemInfo = shallowRef(null)
  const productBffDependData = shallowRef({})

  onMounted(async () => {
    const results = await Promise.all([
      getItemLanguage(),
      getProductBffDependData({
        isPaidUser: unref(isPaidUser),
      })
    ])
    itemLanguage.value = results[0]
    productBffDependData.value = results[1]

    goodsItemInfo.value = new GoodsItemInfo({
      listLanguage: itemLanguage.value,
    })
  })

  const updateProductCardData = async ({ isPaidUser } = {}) => {
    if (typeof isPaidUser !== 'undefined') {
      productBffDependData.value = await getProductBffDependData({
        isPaidUser: unref(isPaidUser),
      })
    }
  }

  const cardLanguage = computed(() => itemLanguage.value)
  const cardItemInfo = computed(() => goodsItemInfo.value)
  const constantData = computed(() => {
    const { IMG_LINK, LAZY_IMG, LAZY_IMG_SQUARE, GB_cssRight, IS_RW } = typeof gbCommonInfo === 'undefined' ? {} : gbCommonInfo
    return {
      IMG_LINK, 
      LAZY_IMG, 
      LAZY_IMG_SQUARE, 
      GB_cssRight, 
      IS_RW
    }
  })

  const productCardBindData = computed(() => {
    return {
      language: cardLanguage.value,
      constantData: constantData.value,
      goodsItemInfo: cardItemInfo.value,
    }
  })


  /**
   * 商详特有字段适配
   * dataTypeExtendProductMaterialMap 商详外露楼层橱窗配置
   */
  const _detailGoodsTransformation = (item, useDetail, type) => {
    const _item = { ...item }
    if (!useDetail) return _item
    // 一行一图配置
    if (type === EItemConfigType.Pic1) {
      _item.productMaterial = item?.dataTypeExtendProductMaterialMap.ONE_IMAGE_RECOMMEND || {}
    }
    // 一行三图配置
    if (type === EItemConfigType.Pic3) {
      _item.productMaterial = item?.dataTypeExtendProductMaterialMap.THREE_IMAGE_RECOMMEND || {}
    }

    // 一行四/五图配置
    if (type === EItemConfigType.Pic45) {
      _item.productMaterial = item?.dataTypeExtendProductMaterialMap.FOUR_FIVE_IMAGE__RECOMMEND || {}
    }
    return _item
  }

  /**
   * 转换bff商卡数据
   */
  const goodsTransformationWithSetup = (item, {
    useDetail, // 处理橱窗配置
    type, // 商卡类型
    dealDetailUrl = true, // 是否生成详情页url
    urlQuery, // 详情页url参数
  } = {}) => {
    const _item = _detailGoodsTransformation(item, useDetail, type)


    const _newItem = goodsTransformation(_item, unref(productBffDependData))

    if (dealDetailUrl && goodsItemInfo.value) {
      goodsItemInfo.value.dealGoodsDetailUrl({ goodsItem: _newItem, query: {
        mallCode: item.mall_code || '',
        ...(urlQuery || {}),
      } })
    }

    return _newItem
  }

  /**
   * 获取商卡配置
   */
  const getItemCardConfig = async () => {
    return getNewGoodsCardV3Config({
      isPaidUser: unref(isPaidUser),
    })
  }


  return {
    cardItemInfo,
    cardLanguage,
    constantData,
    productBffDependData,
    productCardBindData,
    goodsTransformationWithSetup,
    getItemCardConfig,
    updateProductCardData,
  }
}


let QuickViewPopover = null
export const useProductCardQuickAdd = () => {

  const loadQuickViewPopover = () => {
    return new Promise((resolve) => {
      if (QuickViewPopover) {
        resolve(QuickViewPopover)
        return
      }
      import('public/src/pages/common/quickView').then(_ => {
        QuickViewPopover = _.default
        resolve(QuickViewPopover)
      })
    })
  }

  const handleOpenQuickAdd = async (payload, {
    config: _config,
    analysis: _analysis,
    callback: _callback,

    handleIsLoading,
    handleUnLoading,
  } = {}) => {
    const { goodsItem, target, index, imgRatio } = payload || {}

    const cartHideEstimatedPrice = goodsItem?.pretreatInfo?.suggestedSalePriceConfigInfo?.config?.cartHideEstimatedPrice

    const config = {
      showAbPrice: true, // 展示ab价
      showBestDealLabel: true,
      showFollowBeltByOrigin: true,
      showEstimatedPrice: !cartHideEstimatedPrice, // 展示到手价
      ...(_config || {}),
    }
    
    const { goods_id, mall_code } = goodsItem
    
    typeof handleIsLoading == 'function' && handleIsLoading({
      goods_id,
      index,
    })
    
    const _unLoading = () => typeof handleUnLoading == 'function' && handleUnLoading({
      goods_id,
      index,
    })
    
    const { handleOpenQuickViewDone, ...otherCallback } = _callback || {}
    const quickViewData = {
      goodsId: goods_id,
      mallCode: mall_code,
      imgRatio,
      // lockmall: true,
      config,
      analysis: {
        sourceTarget: target,
        pageType: '详情页',
        index,
        ..._analysis,
      },
      callback: {
        handleAddToCartDone: (res) => {
          // 快捷购物车刷新购物车清单
          if (res.code == 0) {
            window.cart_module ? window.cart_module.recart(res) : window.location.reload()
          }
        },
        handleOpenLogin: (qvAddWishFn) => { // todo
          SHEIN_LOGIN?.show?.({
            from: 'recommendList',
            cb: () => typeof qvAddWishFn == 'function' && qvAddWishFn()
          })
        },
        handleOpenQuickViewDone: () => {
          _unLoading()
          typeof handleOpenQuickViewDone == 'function' && handleOpenQuickViewDone()
        },
        ...(otherCallback || {})
      }
    }

    try {
      await loadQuickViewPopover()
      QuickViewPopover.show(quickViewData)
    } catch(e) {
      _unLoading()
    }
  }

  return {
    handleOpenQuickAdd,
    loadQuickViewPopover,
  }
}




/**
 * 注册dom曝光回调
 */
export const useRegisterDomExpose = ({ $el, callback }) => {
  if (!$el || typeof callback !== 'function') throw 'invalid params'
  
  let exposeInstance = new Expose()
  
  const unWatch = () => {
    exposeInstance?.destroy()
    exposeInstance = null
  }
  exposeInstance.observe(
    {
      elements: $el
    },
    () => {
      // 曝光之后当前这个实例不需要了
      unWatch()
      callback()
    }
  )
  
  onBeforeUnmount(() => {
    unWatch()
  })
  
  return {
    unWatch,
  }
}


export const useProductItemExpose = ({
  /**
   * 商品项目列表饮用
   */
  productItemRefs,
  /**
   * 声明式曝光实例
   */
  daEventExpose,

  daExposeCode = `product_list_wrap_${+new Date()}`, // 祖先元素唯一key值
  daEventExposeId = '2-3-2', // 商品项目曝光事件id

  afterExpose = () => {},
}) => {


  let timer = null
  const KEY_CODE = `${daExposeCode}\`${daEventExposeId}`

  const handleProductItemBindExpose = (startIndex = 0) => {
    if (!daEventExpose || !daEventExpose) return

    clearTimeout(timer)
    timer = setTimeout(() => {
      productItemRefs.value.slice(startIndex).forEach((e) => {
        e?.setElAttr?.()
      })

      // 曝光埋点
      daEventExpose.subscribe({
        keycode: KEY_CODE,
        type: 'list',
        hooks: {
          afterExpose: ({ targets }) => {
            typeof afterExpose == 'function' && afterExpose({ targets })
          }
        }
      })
    })

  }

  onUnmounted(() => {
    // 注销事件
    daEventExpose?.remove?.(KEY_CODE)
  })

  return {
    handleProductItemBindExpose,
  }
}

// 统一接入推荐统一曝光池子，融合期间会剥离对vuex的依赖
export const useRecommendExposedList = () => {
  const { exposedFilterList } = useMapState(['exposedFilterList'])
  const { assignState } = useMapMutation(['assignState'])

  const MAX_LENGTH = 100
  const updateExposedList = (goods_id) => {

    const _exposedFilterList = exposedFilterList.value.concat()

    const _goods_id = Number(goods_id)
    if(_exposedFilterList.includes(_goods_id)) return
    if(_exposedFilterList.length >= MAX_LENGTH){
      _exposedFilterList.shift()
    }
    assignState({
      exposedFilterList: [..._exposedFilterList, _goods_id]
    })
  }

  const batchUpdateExposedWithTargets = ({ targets = [] } = {}) => {
    if (!targets?.length) return
    const _exposedFilterList = exposedFilterList.value.concat()
    const exposeGoods = []
    targets?.forEach(target => {

      const _goods_id = target?.getAttribute?.('data-id') || target?.getAttribute?.('data-goods-id')
      const goods_id = typeof _goods_id !== 'undefined' && Number(_goods_id)
      if (goods_id && !_exposedFilterList.includes(goods_id)) {
        exposeGoods.push(goods_id)
      }
    })
    if (!exposeGoods.length) return
    const _newList = [..._exposedFilterList, ...exposeGoods].slice(-MAX_LENGTH) // 优先保留最新的

    assignState({
      exposedFilterList: _newList
    })

  }

  return {
    exposedFilterList,

    updateExposedList,
    batchUpdateExposedWithTargets,
  }
}
