<template>
  <div
    v-expose="{
      id: 'page_view:simple',
      data: {
        from: 'card_home',
        $options: {
          page_name: 'page_linked_giftcard_list',
        },
      },
    }"
    class="bsc-gc-personal"
  >
    <div
      class="bsc-gc-personal__title"
      @click="() => emits('goScroll', 0)">
      {{ language?.SHEIN_KEY_PWA_24759 }}
    </div>
    <div class="bsc-gc-personal__subtitle">
      <TabList
        ref="tabListRef"
        tabJustifyContent="left"
        :is-show-no-card-icon="cardList?.length === 0"
        @updateTabIndex="updateTabIndex"
        @updateCardList="updateCardList"
      />
      <div
        class="bsc-gc-personal__add"
        @click="() => handleOpenBindCard(true)"
      >
        <Icon
          name="sui_icon_add_16px"
          size="16px"
        />
        {{ language?.SHEIN_KEY_PWA_35100 }}
      </div>
    </div>
    <div
      :class="{
        'bsc-gc-personal__card--block': cardList.length !== 0,
        [`${props.scrollWrapClass}`]: true
      }">
      <SFeedback
        v-if="cardList.length === 0"
        :content="tabIndex === 0 ? language?.SHEIN_KEY_PWA_35143 : language?.SHEIN_KEY_PWA_35144"
      />
      <template v-else>
        <Card
          v-for="item in cardList"
          :key="`${item.card_no}${item.card_status_tip}`"
          :backgroundGray="tabIndex === 1"
          :enableCopy="true"
          :enableGoDetail="true"
          :readonly="true"
          :class="['card', props.scrollItemClass]"
          :item="item"
          cardHeight="164px"
          @goDetail="goDetail"
        />
      </template>
    </div>

    <!-- bind card drawer -->
    <BBindGiftCard
      ref="bsBindGiftCardRef"
      :is-form-inline="false"
      :is-show-switch="false"
      :is-show-step-text="true"
      :gift-card-list="cardAllData"
      :is-need-drawer="true"
      :visible="bindCardVisible"
      sence="person"
      @handle-close="() => handleOpenBindCard(false)"
      @bind-card-cb="onBindCardCb"
    />
  </div>
</template>

<script setup lang="ts">
import { inject, ref } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { SFeedback } from '@shein-aidc/sui-feedback/laptop'
import BBindGiftCard from '@shein-aidc/bs-bind-gift-card-laptop'
// import BBindGiftCard from '../../../../bind-gift-card/laptop/src/index'

import Card from './Card.vue'
import TabList from './TabList.vue'
import { type C_GiftCard } from '../../../types'

const {triggerNotice } = inject('analysisInstance') as any

const cardList = ref<C_GiftCard.CardItem[]>([])
const cardAllData = ref([])
const tabIndex = ref<number>(0)
const bsBindGiftCardRef = ref<any>(null)
const bindCardVisible = ref(false)

const props = defineProps<{
  drawerProps: Record<string, string>
  scrollWrapClass: string
  scrollItemClass: string,
  extraData?: Record<string, any>
}>()

const emits = defineEmits(['goScroll', 'custom-event'])

const language: C_GiftCard.language= inject('language')!

const updateCardList = (data) => {
  cardAllData.value = data.allList
  cardList.value = data.currentList
}

const updateTabIndex = (index: number) => {
  tabIndex.value = index
}

const onBindCardCb = (data) => {
  const { resData } = data
  if(resData.code == 0) {
    bindCardVisible.value = false
    triggerNotice?.({
      id: 'page_linked_giftcard_list:simple',
      extraData: {
        from: 'link_success',
        $options: {
          page_name: 'page_linked_giftcard_list',
        },
      },
    })
  }
  emits('custom-event', { key: 'bind-card', data })
}

const handleOpenBindCard = (isOpen) => {
  triggerNotice?.({
    id: 'expose_giftcard_link:simple',
    extraData: {
      scene: 'card_list',
      $options: {
        page_name: 'page_linked_giftcard_list',
      },
    },
  })
  bindCardVisible.value = isOpen
}

const goDetail = (no) => {
  emits('custom-event', { key: 'go-detail', data: no })
}

</script>

<style lang="less">
.bsc-gc-personal {
  background-color: #fff;
  padding: 16px 40px;
  &__title {
    color:  #222;
    text-align: center;
    font-size: 20px;
    font-weight: 900;
  }
  &__subtitle {
    margin-top: 16px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  &__card--block{
    display: grid;
    grid-template-columns: repeat(2, 380px);
    grid-row-gap: 30px;
    grid-column-gap: 30px;
    position: relative;
    // height: 70vh;
    margin-top: 30px;
    .card {
      // margin-bottom: 16px;
    }
  }
  &__add {
    border: 1px solid #222;
    padding: 9px 16px;
    color: #222;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    cursor: pointer;
  }
  &__footer {
    position: absolute;
    left: 0;
    bottom: 32px;
    width: 100%;
    padding: 0 30px;
  }
}
</style>
