<template>
  <div>
    <s-dialog
      :visible="visible"
      append-to-body
    >
      <div>
        {{ language.SHEIN_KEY_PC_33516 }}
      </div>
      <template #footer>
        <s-button-group :width="'100%'">
          <s-button-group-item
            :type="['primary', 'H72PX']"
            @click="reselectGoods"
          >
            {{ language.SHEIN_KEY_PC_33517 }}
          </s-button-group-item>
          <s-button-group-item
            :type="['H72PX']"
            @click="confirmSelect"
          >
            {{ language.SHEIN_KEY_PC_33518 }}
          </s-button-group-item>
        </s-button-group>
      </template>
    </s-dialog>
  </div>
</template>
<script>
import { SButtonGroup } from '@shein-aidc/sui-button-group'
import { SButtonGroupItem } from '@shein-aidc/sui-button-group-item'
import { daEventCenter as daEventCenterV2 } from '@shein-aidc/basis-sa-event-center'

export default {
  components: {
    SButtonGroup,
    SButtonGroupItem,
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    language: {
      type: Object,
      default: () => ({})
    },
    replacedGoodsId: {
      type: String,
      default: ''
    }
  },
  mounted() {
    daEventCenterV2.triggerNotice({
      id: 'expose_exchange_out_of_stock:simple',
      data: {
        order_goods_id: this.replacedGoodsId
      }
    })
  },
  methods: {
    reselectGoods() {
      daEventCenterV2.triggerNotice({
        id: 'click_exchange_out_of_stock:simple',
        data: {
          order_goods_id: this.replacedGoodsId,
          button: 'reselect'
        }
      })

      this.$emit('reselect-goods')
      this.updateVisible()
    },
    confirmSelect() {
      daEventCenterV2.triggerNotice({
        id: 'click_exchange_out_of_stock:simple',
        data: {
          order_goods_id: this.replacedGoodsId,
          button: 'confirm'
        }
      })

      this.$emit('confirm-select')
      this.updateVisible()
    },
    updateVisible() {
      this.$emit('update:visible', false)
    }
  },
  emits: ['reselect-goods', 'confirm-select', 'update:visible']
}
</script>
