<template>
  <s-dialog
    v-model:visible="currentDialogShow"
    :type="'W480'"
    :show-close="false"
    :close-on-click-modal="false"
    class="freegift-dialog"
    @opened="openedCallback"
  >
    <div
      v-expose="getAnalysisData({ id: '1-22-8-75',data: {
        sceneType: exposeData?.sceneType
      } })"
      class="freegift-dialog__wrapper"
      :class="{
        'freegift-dialog__wrapper-animate': true 
      }"
    >
      <!-- 头部切图 -->
      <div
        class="freegift-dialog__content-img"
        :style="{ backgroundImage: `url(${imgs.title})` }"
      >
      </div>
      <div class="freegift-dialog__fireworks">
        <img
          id="fireworks"
          width="350"
          :src="imgs.fireworks"
        />
      </div>
      <div class="freegift-dialog__content">
        <div 
          class="freegift-dialog__title"
        >
          <span>{{ popupInfo?.title }}</span>
        </div>

        <div 
          class="freegift-dialog__subtitle"
        >
          <span>{{ popupInfo?.subTitle }}</span>
        </div>

        <!-- count down -->
        <div
          v-if="freeGiftPopupInfo?.countDown"
          class="freegift-dialog__counter"
        >
          <span class="freegift-dialog__countertext">{{ popupInfo?.countDownText }}</span>
          <span
            :style="{ 'unicode-bidi': 'bidi-override', direction: 'ltr' }"
            class="freegift-dialog__time"
            v-html="timeTips"
          ></span>
        </div>

        <div 
          class="freegift-dialog__goods"
          :style="carouselStyle"
        >
          <div
            v-for="(giftInfo, index) in (freeGiftPopupInfo?.giftInfoList || [])" 
            :key="index"
            :style="index === freeGiftPopupInfo?.giftInfoList?.length -1 ? { 'margin-right': 0 } : {}"
            class="freegift-dialog__card"
          >
            <div class="freegift-dialog__img">
              <div class="freegift-dialog__tag">
                {{ popupInfo?.productTagText }}
              </div>
              <img
                :src="giftInfo.giftImg"
                alt="goods image"
                draggable="false"
              />
            </div>
            <div class="freegift-dialog__price">
              <span class="freegift-dialog__currentprice">{{ GB_cssRight ? giftInfo.giftPrice?.split('')?.reverse()?.join('') : giftInfo.giftPrice }}</span>
              <span class="freegift-dialog__orgprice">{{ GB_cssRight ? giftInfo.giftOriginalPrice?.split('')?.reverse()?.join('') : giftInfo.giftOriginalPrice }}</span>
            </div>
          </div>
        </div>
      
        <SvgIcon
          :color="'#959595'"
          class="freegift-dialog__close"
          name="sui_icon_close_24px" 
          size="30px"
          @click="closeHandle"
        />
      </div>
      <span
        id="freegift-btn"
        class="freegift-dialog__btn"
        :style="scanStyle"
        @click="jumpLink"
      >
        <img
          :class="{ 'animate': isAnimating }"
          :src="imgs.scanStatic"
          alt="scan image"
          @animationend="handleAnimationEnd"
        />
        <span>{{ popupInfo?.buttonText }}</span>
      </span>
    </div>
  </s-dialog>
</template>
  
<script>
import { mapState } from 'vuex'
import {
    template,
    CountDown,
} from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
// import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import schttp from 'public/src/services/schttp/index'

const { PUBLIC_CDN = '', GB_cssRight } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

// SHEIN-US AR GB FR DE MX ES市场
// const isTargetMarket = () => {
//   return ['us', 'ar', 'uk', 'fr', 'de', 'mx', 'es'].includes(SiteUID)
// }

export default {
  name: 'FreeGiftDialog',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    freeGiftPopupInfo: {
      type: Object,
      default: () => {}
    },
  },
  emits: ['dialog-opened'],
  data() {
    return {
      GB_cssRight,
      isShowFreeGiftDialog: !!this.freeGiftPopupInfo?.giftInfoList?.length,
      scanValue: 236,
      isAnimating: true,
      moreThanThree: this.freeGiftPopupInfo?.giftInfoList?.length > 3,
      imgs: {
        // title: `${PUBLIC_CDN}/she_dist/images/prime/free_gift/fg-center.png`,
        title: `${PUBLIC_CDN}/she_dist/images/prime/free_gift/fg-head.png`,
        scanStatic: `${PUBLIC_CDN}/she_dist/images/prime/free_gift/scan-static.png`,
        fireworks: `${PUBLIC_CDN}/she_dist/images/prime/free_gift/fireworks.gif`,
      },
      remainSecs: parseInt(this.freeGiftPopupInfo?.countDown),
      countTimer: new CountDown(),

      popupInfo: this.freeGiftPopupInfo?.popupInfo || {},
    }
  },
    
  computed: {
    ...mapState(['dialogName']),
    carouselStyle() {
      const len = this.freeGiftPopupInfo?.giftInfoList.length
      const distance = len - 3
      const translateValue = distance > 0 ? (GB_cssRight ? 146 : -146) * distance : 0
      let posStyle = {}
      if(this.freeGiftPopupInfo?.countDown) {
        posStyle['margin-top'] = '16px'
      } else  {
        posStyle['margin-top'] = '20px'
      }
      posStyle['align-self'] = 'flex-start'
      if(distance <= 0) {
        posStyle['padding-left'] = 0
        posStyle['align-self'] = 'center'
      }
      // if(GB_cssRight) {
      //   posStyle['align-self'] = 'flex-end'
      // } else {
      //   posStyle['align-self'] = 'flex-start'
      // }
      return {
        '--translate-value': `${translateValue}px`, 
        ...posStyle
      }
    },

    scanStyle() {
      return {
        '--scan-value': `${GB_cssRight ? -this.scanValue : this.scanValue}px`, 
      }
    },

    exposeData(){
      const { billNo, cycleNum, sceneType } = this.freeGiftPopupInfo || {}
      return {
        billNo,
        cycleNum,
        sceneType
      }
    },

    timeTips () {
      const { H, M, S } = this.countTimer?.timeObj || {}
      let spanTxt = (txt, cls = 'time') => cls ? `<span class="${cls}">${txt}</span>` : `<span>${txt}</span>`
      return spanTxt(H) + spanTxt(':', 'comma') + spanTxt(M) + spanTxt(':', 'comma') + spanTxt(S)
    },

    currentDialogShow(){
      return this.isShowFreeGiftDialog && this.isShow
    },
  },
  watch: {
    isShow: {
      handler() {
        
      },
      immediate: true
    },
    currentDialogShow: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            this.init()
            this.report()
          })
        }
      },
      immediate: true
    },
  },
  mounted() {
    this.init()
    // document.documentElement.setAttribute("mir", "rtl")
    // GB_cssRight = true
  },
  unmounted() {
    this.countTimer?.clear()
  },
  methods: {
    template,
    getAnalysisData({ id, data }) {
      return {
        id,
        data
      }
    },
    init() {
      const img = document.getElementById('fireworks')
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
  
      const btn = document.getElementById('freegift-btn')
      if(btn) {
        this.scanValue = btn.offsetWidth
      }

      if(img) {
        img.onload = () => {
          setTimeout(() => {
            canvas.width = img.width
            canvas.height = img.height
            ctx.drawImage(img, 0, 0)
            img.parentNode.replaceChild(canvas, img)
          }, 1500)
        }
      }
      // 初始化倒计时
      if(this.remainSecs) {
        this.countTimer?.clear()
        this.countTimer?.start({
          seconds: this.remainSecs,
        })
      }
        
    },

    handleAnimationEnd() {
      this.isAnimating = false
    },

    report() {
      // const { sceneType } = this.freeGiftPopupInfo
      // const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'FreeGiftDialog' })
      schttp({
        method: 'post',
        url: '/user/center/free_gift_popup_exposure',
        useBffApi: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: { 
          billno: this.exposeData.billNo,
          scene_type: this.exposeData.sceneType,
          cycle_num: this.exposeData.cycleNum,
        },
      })
    },

    reportData(id, data) {
      daEventCenter.triggerNotice({
        daId: id,
        bindData: data
      })
    },

    jumpLink() {
      const link = this.freeGiftPopupInfo?.popupInfo?.freeGiftUrl || `/user/prime?productsource=freeGiftPop`
      this.reportData('1-22-8-76', { scene_type: this.freeGiftPopupInfo?.sceneType })
      window.location.href = link
    },
      
    // 关闭弹窗
    closeHandle() {
      this.reportData('1-22-8-77', { scene_type: this.freeGiftPopupInfo?.sceneType })
      this.isShowFreeGiftDialog = false
      this.report()
    },
    openedCallback() {
      this.$emit('dialog-opened', 'FREE_GIFT_DIALOG')
    }
  }
}
</script>
  
  <style lang="less">
  .freegift-dialog {
    background: rgba(0, 0, 0, 0.85);
    .sui-dialog__wrapper {
      background: rgba(0, 0, 0, 0);
    }
    .sui-dialog__wrapper {
      width: 564px;
    }
    &__wrapper {
      background: linear-gradient(0deg, #fffaf5, #fff1e9);
      border-radius: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      min-height: 348px;
      opacity: 0;
      position: relative;
      z-index: 2;
      box-shadow: 0 0 5px 5px rgba(255, 239, 219, .2);
      border: 1px solid rgba(247, 193, 133, .1)
    }
    &__wrapper-animate {
      opacity: 1;
    }
    &__title {
      padding: 0 14px;
      margin-top: 38px;
      text-shadow: 1px 1px 0px rgba(255, 252, 244, 0.72);
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      color: #873c00;
      line-height: 18px;
      margin-bottom: 5px;
    }
    &__subtitle {
      max-width: 350px;
      text-align: center;
      text-shadow: 1px 1px 0px rgba(255, 252, 244, 0.74);
      font-size: 24px;
      font-style: normal;
      font-weight: 860;
      line-height: 28px;
      color: rgba(135, 60, 0, 1);
    }
    &__color {
      width: 300px;
      height: 74px;
      background-repeat: no-repeat;
      background-size: 100%;
      margin-bottom: -58px;
    }
    &__content {
      width: 100%;
      align-items: center;
      display: flex;
      flex-direction: column;
      overflow: hidden
    }
    &__content-img {
      position: absolute;
      top: -74px;
      width: 140px;
      height: 112px;
      z-index: 2;
      background-repeat: no-repeat;
      background-size: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
    }
    
    &__close {
      position: absolute;
      right: -5px;
      top: -72px;
      cursor: pointer;
    }
  
    @keyframes slide {
      0% {
        transform: translateX(0); /* 初始位置 */
      }
      100% {
        transform: translateX(var(--translate-value)); /* 移动到左侧，显示下一个 item */
      }
    }
  
    &__goods {
      position: relative;
      display: flex;
      // align-self: flex-start;
      padding-left: 30px;
      animation: slide 8s forwards;
      animation-delay: 1.6s;
    }
  
    &__card {
      position: relative;
      width: 126px;
      height: 159px;
      margin-right: 20px;
    }
    &__img {
      width: 100%;
      height: 126px;
      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
        border-radius: 6px;
      }
    }
    &__tag {
      font-size: 14px;
      font-weight: 700;
      color: #fff;
      text-align: center;
      position: absolute;
      left: 0;
      top: 0;
      width: 48px;
      height: 22.27px;
      border-radius: 6px 0;
      background-color: #fa6338;
    }
  
    &__price {
      unicode-bidi: bidi-override;
      margin-top: 8px;
    }
  
    &__currentprice {
      color: #fa6338;
      font-size: 20px;
      font-weight: 700;
      margin-right: 4px;
    }
  
    &__orgprice {
      color: #873c00;
      font-size: 10px;
      font-weight: 400;
      text-decoration: line-through;
      opacity: 0.5;
    }
  
    &__btn {
      position: relative;
      margin: 17px 10px 20px 10px;
      height: 42px;
      border-radius: 68px;
      background: linear-gradient(90deg, #FFC3A5 0%, #EAA27E 100%), linear-gradient(90deg, #F7C695 0%, #EDA65F 100%), linear-gradient(90deg, #FFC3A5 0%, #EAA27E 100%);
      background: linear-gradient(0deg, #FFC3A5 0%, #F7C695 54.26%, #FFC3A5 100%);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 27px;
      border-radius: 68px;
      overflow: hidden;
      cursor: pointer;
      min-width: 160px;
      span {
        text-align: center;
        text-shadow: 0.5px 0.5px 0.2px rgba(255, 244, 215, 0.58);
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: rgba(135, 60, 0, 1);
  
        // background: linear-gradient(96deg, rgba(255, 83, 34, 1) 86%, rgba(135, 60, 0, 1) 100%);
        // background-clip: text !important;
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
      }

      .animate {
        animation: btn-scan 0.4s 1;
        animation-delay: 1.6s;
      }
  
      img {
        width: 38px;
        height: 42px;
        position: absolute;
        left: 0px;
        opacity: 0;
        transform: translateX(-100%);
      }
    }
  
  @keyframes btn-scan {
    0% {
      opacity: 1;
    }
    100% {
      transform: translateX(var(--scan-value));
      opacity: 0;
    }
  }
  
    &__fireworks {
      position: absolute;
      top: -110px;
    }
  
    canvas {
      width: 100%;
      height: auto;
    }

    &__counter {
        font-size: 12px;
        margin: 6px 0 0 0;
    }

    &__countertext {
        font-weight: 400;
        color: rgba(135, 60, 0, 1);
    }

    &__time {
        color: rgba(250, 99, 56, 1);
        margin-left: 8px;
        font-size: 12px;
        .comma {
          margin: 0 4px 0 4px;
        }
        .time {
            background: rgba(159, 95, 45, 0.1);
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 4px;
        }
    }
    
  }
  </style>
  
