<template>
  <div class="bsc-gc-tablist">
    <STab
      v-if="getTabList.length > 0 && !!language"
      v-model="tabIndex"
      :justify-content="props.tabJustifyContent"
      @change="(v) => handleTabIndex(v.val)"
    >
      <STabItem
        v-for="(tabName, index) in getTabList"
        :id="index"
        :key="tabName"
      >
        {{ tabName }}
      </STabItem>
    </STab>
  </div>
</template>

<script setup lang="ts">
import { ref, inject, computed, watch, type Ref } from 'vue'
import { STab } from '@shein-aidc/sui-tab/laptop'
import { STabItem } from '@shein-aidc/sui-tab-item/laptop'
import { E_SCENE, type C_GiftCard } from '../../../types'

const language: C_GiftCard.language = inject('language')!
const cardData: Ref<C_GiftCard.CardData> = inject('cardData')!
const { triggerNotice } = inject('analysisInstance') as any
const scene: Ref<`${E_SCENE}`> = inject('scene')!


const tabIndex = ref<number>(0)

const getTabList = computed(() => ([language?.value?.SHEIN_KEY_PWA_22586, language?.value?.SHEIN_KEY_PWA_35099]))

const props = defineProps<{
  tabJustifyContent: string
}>()

const emits = defineEmits(['updateCardList', 'updateTabIndex'])


const handleTabIndex = (index: number) => {
  tabIndex.value = index
  if (scene.value === E_SCENE.Checkout) {
    triggerNotice?.({
      id: 'click_giftcard_tab:simple',
      extraData: {
        tab_type: index === 0 ? 'available' : 'unavailable',
      },
    })
  }
}

const getCardList = (index, data) => {
  const { available_card_list = [], unavailable_card_list = [] } = data || {}
  return index === 0 ? available_card_list : unavailable_card_list
}

watch(() => [tabIndex.value, cardData.value], (v) => {
  const [index, data] = v
  emits('updateCardList', {
    currentList: getCardList(index, data),
    allList: Object.values(data).reduce((sum: any, item) => sum.concat(item), []),
  })
  emits('updateTabIndex', index)
}, { immediate: true })

defineExpose({
  handleTabIndex,
})

</script>

<style lang="less">
.bsc-gc-tablist {
  margin-bottom: 10px;
}
</style>
