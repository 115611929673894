
export const getPopupInstance = () => {
  return new Promise((resolve) => {
    import(
      'public/src/pages/detail/main/components/Drawer/new_outfit/main.js'
    ).then(res => {
      resolve(res.default)
    })
  })
}
