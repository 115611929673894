// api 交互
import schttp from 'public/src/services/schttp'
import { getLocalStorage } from '@shein/common-function'

export const getAddressInfo = () => {
  const addressCookie = getLocalStorage('addressCookie')
  const localCache = JSON.parse(localStorage.getItem('localCache')) || {}
  let addressInfo = {}
  if (addressCookie) {
    try {
      addressInfo = typeof addressCookie === 'string' ? JSON.parse(addressCookie) : addressCookie
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('获取地址缓存失败', error)
    }
  }
  return { ...addressInfo, ...localCache }
}

/**
 * 购物车查询未支付订单接口
 * @param {string} cachedOrderIdList 缓存的订单id列表
 */
export async function getCanceledOrderInfo(params) {
  const { currency, appLanguage } = window.gbCommonInfo
  const [res] = await schttp({
    method: 'POST',
    url: '/cart/order/unpaid_order_info',
    data: {
      cachedOrderIdList: params || []
    },
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage,
    },
    useBffApi: true,
  }).then(r => [r, null], e => [null, e])
  if(res.code == '0') {
    return res.info
  } else {
    return {}
  }
}

/**
 * 获取购物车优惠券列表
 * @param {object} param
 * @param {string} param.is_return 是否返回最优券
 * @param {number} param.enableCouponCmp 开启组件功能，是否使用新券组件
 * @returns 
 */
export const geCartCouponList = ({ is_return = 1, enableCouponCmp = 1 } = {}) => {
  const { currency, appLanguage } = window.gbCommonInfo

  const params = {
    is_return,
    enableCouponCmp,
    is_old_version: 0,
  }
  return schttp({
    method: 'GET',
    url: '/order/cart/coupon/list',
    params,
    headers: {
      'Content-Type': 'multipart/form-data',
      'AppCurrency': currency,
      'AppLanguage': appLanguage,
    },
    useBffApi: true
  })
}

/**
 * 根据多语言key获取多语言
 * @param {object} data
 * @param {array<string>} data.languageKeys 多语言key
 * @example getLanguage({ languageKeys: ['SHEIN_KEY_PWA_17315', 'SHEIN_KEY_PWA_17316'] })
 * @returns 
 */
export const getLanguage = (data) => {
  const { currency, appLanguage } = window.gbCommonInfo

  return schttp({
    method: 'POST',
    url: '/system/configs/multi_language',
    data,
    headers: {
      'AppCurrency': currency,
      'AppLanguage': appLanguage,
    },
    useBffApi: true
  })
}

/**
 * 获取购物车接口通用参数
 * @param {object} params
 * @param {string} addType 凑单类型, 非必须的字段,https://wiki.dotfashion.cn/pages/viewpage.action?pageId=413794416
 * @param {string} auto_use_coupon 是否自动用券(0否 1是)默认为0
 * @param {object} couponFilterGoods 指定券在购物车顶部筛选凭证的商品信息集合， 这个只有TR-31366 才有加上的入参，也是因为它才把查车的get请求改为post请求。
 * @param {string} coupon_code 优惠券券码， 用于凑单组件跳转购物车时的toast提示
 * @param {string} currentRangeIndex 当前选择的档次信息
 * @param {string} filterTagId 前端传的筛选标签唯一id
 * @param {string} filter_tag 筛选标签枚举值，1：闪购， 2：降价， 3：售罄， 具体定义在CartFilterTagEnum类
 * @param {string} holdTopFilterTagIds 维持顶部筛选项固定位置筛选集合: 从二级页面回来，前端要继续保持登顶的筛选项id的传参，支持多个传参， 多个筛选值用逗号隔开。  目前只有券需要保持登顶，方案类似线上coupon/list接口券登顶的coupons传参
 * @param {string} njActivityId 免邮活动id, 用于免邮活动凑单重构
 * @param {string} njActivityType 免邮活动类型， 用于免邮活动凑单重构
 * @param {string} now_level 运费活动当前档次值, 用于免邮活动凑单重构
 * @param {string} transport_type 运输方式类型， 用于免邮活动凑单重构
 *
 */
const getCartCommonParams =  (params = {}) =>{
  const addressInfo = getAddressInfo()
  return {
    is_first: '0',
    address_id: addressInfo?.addressId,
    city: addressInfo?.city || '',
    country_id: addressInfo?.countryId,
    district: addressInfo?.district || '',
    isOpenScreenEfficiency: '1',
    postcode: addressInfo?.postcode || '',
    state: addressInfo?.state || '',
    userLocalSizeCountry: window.localStorage.getItem('last_select_country'),
    ...params
  }
}

export function getCartIndex(data) {
  const { currency, appLanguage } = window.gbCommonInfo
  const params = getCartCommonParams(data)
  return schttp({
    method: 'POST',
    url: '/order/mall/cart/index',
    data: params,
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage,
    },
    useBffApi: true,
  })
}

/**
 * 获取多语言数据
 * @param {Object} params - 请求参数 {posKeys:['xxx', 'xxx', 'xxx'],newPosKeys:['xxx', 'xxx', 'xxx']}
 */
export const getAbt = (params) => {
  let oldPosKeys = params?.posKeys?.map(item => `SPc${item}`) || []
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'GET',
    url: '/abt/merge/get_abt_by_poskey',
    params: {
      posKeys: [...oldPosKeys, ...params?.newPosKeys || []].join(',')
    },
    headers: {
      AppCurrency: currency,
      AppLanguage: appLanguage,
    },
    useBffApi: true,
  }).then((res)=>{
    let ABTParams = {}
    if (res.code == 0) {
      let pos = res.info?.pos || {}
      Object.keys(pos).forEach(key => {
        let poskey = oldPosKeys.includes(key) ? key.replace(/(SPc)(\w*)/, '$2') : key
        ABTParams[poskey] = pos[key] || {}
        if(pos[key] && !(pos[key].param)){
          ABTParams[poskey].param = ABTParams[poskey].p
        }
      })
    }
    return ABTParams
  }) 
}

/**
 * 勾选购物车商品
 * @param {Object} params - 请求参数 {append_id_list: ['xxx'], cart_id_list: [1], operation_type: 0}
 * append_id_list: 附属品购物车行id集合
 * cart_id_list: 购物车行id集合
 * operation_type: 操作类型1-勾选 2-取消勾选 3-全部勾选 4-全部取消勾选
 */
export const modifyCartCheckStatus = (params) => {
  let data = getCartCommonParams(params)
  const { cart_id_list = [], append_id_list = [] } = data
  data.cart_id_list = cart_id_list?.join(',') || ''
  data.append_id_list = append_id_list?.join(',') || ''
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'POST',
    url: '/order/mall/modify_cart_check_status',
    data,
    headers: {
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

/**
 * 购物车商品数量更新
 * @param {Object} params - 请求参数 {append_id_list: ['xxx'], cart_id: xxx, is_checked: 0, quantity; 1}
 * append_id_list: 附属品购物车行id集合
 * cart_id: 购物车行id
 * quantity: 数量
 * is_checked: 是否勾选
 */
export const cartUpdate =  (params) => {
  let data = getCartCommonParams(params)
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'POST',
    url: '/order/mall/cart_update',
    data: data,
    headers: {
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

export const checkStock = () => {
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'GET',
    url: '/order/cart/stock/check',
    params: { is_old_version: 0 },
    headers: {
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

/**
 * 购物车商品删除
 * @param {object} params 
 * @param {Array} params.ids 商品行id数组
 * @returns 
 */
export const cartDelete =  (params) => {
  let data = getCartCommonParams(params)
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'POST',
    url: '/order/mall/cart_delete',
    data,
    headers: {
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

/**
 * 新版mall-修改购物车内的商品属性
 * @param {string} abt_mark 全场景商品图AB测试
 * @param {string} append_id_list 附属品的购物车id集合
 * @param {string} attrs[0][attr_id] 
 * @param {string} attrs[0][attr_value_id] 
 * @param {string} auto_use_coupon 是否自动用券(0否 1是)默认为0
 * @param {string} auto_use_point 是否自动用积分 0不自动用 1自动用 默认为0
 * @param {string} auto_use_point_threshold 自动使用的积分最低门槛
 * @param {boolean} checked 
 * @param {string} filter_tag 筛选标签枚举值，1：闪购， 2：降价， 3：售罄， 具体定义在CartFilterTagEnum类
 * @param {string} goods_id 
 * @param {string} id 购物车id(主品id)
 * @param {string} is_checked 商品勾选状态(0:未勾选 1:已勾选)
 * @param {string} mall_code 市场编码
 * @param {string} promotion_id 促销活动ID
 * @param {string} promotion_product_mark 促销活动主附属品标识
 * @param {string} promotion_type 促销活动类型
 * @param {string} quantity 数量
 * @param {string} sku_code sku_code码
 *
 */
export const cartUpdateAttr =  (params) => {
  let data = getCartCommonParams(params)
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'POST',
    url: '/order/mall/cart_update_attr',
    data: {
      ...data,
      is_old_version: 0,
      is_direct_request_pmsc: 1,
    },
    headers: {
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

/**
 * 购物车批量添加商品
 * @param {object} params
 * @param {string} auto_use_coupon[必填] 是否自动用券(0否 1是)默认为0
 * @param {string} product_list[必填] 商品id
 *
 */
export const cartBatchAdd =  (params) => {
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'POST',
    url: '/order/cart/batch_add',
    data: params,
    headers: {
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

/**
 * 收藏购物车中的商品(批量)
 * @param {object} params
 * @param {string} auto_use_coupon[必填] 是否自动用券(0否 1是)默认为0
 * @param {string} id[][必填] 批量添加商品id
 * @param {string} is_old_version 1、老版本 0、新版本
 * @param {number} need_drop 是否移除商品 1、是 0、否
 */
export const cartBatchWish =  (params) => {
  let data = getCartCommonParams(params)
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'POST',
    url: '/order/cart/cart_batch_to_wish',
    data: data,
    params: {
      ...params,
      is_old_version: 0,
      need_drop: 1
    },
    headers: {
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

/**
 * 查询宅配地址层级信息接口
 * @param {object} params
 * @param {string} parent_id 父级id
 * @param {string} type 类型
 */
export const getAddressById = ({ parent_id, type = 2 }) => {
  const { currency, appLanguage } = window.gbCommonInfo
  // 此处app中间层要求请求体格式为formData
  const formData = new FormData()
  formData.append('parent_id', parent_id)
  formData.append('type', type)
  formData.append('from_type', '')

  return schttp({
    method: 'POST',
    url: '/user/address/country/get_by_id',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

/**
 * 获取国家全量的省或市信息
 * @param {object} params
 * @param {string} type 类型
 * @param {string} state_id
 * @param {string} country_id
 */
export const getAreaByType = ({ type, state_id, country_id }) => {
  const { currency, appLanguage } = window.gbCommonInfo
  // 此处app中间层要求请求体格式为formData
  const formData = new FormData()
  formData.append('type', type)
  formData.append('state_id', state_id)
  formData.append('country_id', country_id)

  return schttp({
    method: 'POST',
    url: '/user/address/country/get_area_by_type',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

/**
 * 获取地址列表
 */
export const getAddressList = () => {
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'GET',
    url: '/address/address_list',
    headers: {
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

/**
 * 获取国家列表信息
 */
export const getCountryAll = () => {
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'GET',
    url: '/user/address/get_country_region_by_site',
    headers: {
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}


export const getAdministrativeLevelInfo = ({ country_id = '' }) => {
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'GET',
    url: '/order/cart/administrative_level_info',
    params: { country_id },
    headers: {
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

/**
 * 领取店铺券
 * @param {object} params
 * @param {string} mall_code 商城编码
 * @param {string} store_code 店铺编码
 * @param {Array} products 商品信息
 * {
 *   "mall_code": "1",
 *   "products": [{
 *     "skc": "st2304077570709397"
 *   }, {
 *    "skc": "sw2210208652933898"
 *   }],
 *   "store_code": "9186185270"
 * }
 */
export const getCartStoreCoupon = ({ mall_code, store_code, products }) => {
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'POST',
    url: '/order/cart/coupon/list/store',
    data: {
      mall_code,
      store_code,
      products
    },
    headers: {
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

/**
 * 获取加价购换购列表
 * @param {object} param
 * @param {string} param.promotion_id 促销活动 id
 * @param {number} param.range 活动的档次
 * @param {string} param.brand 品牌code
 * @param {number} param.page 页码
 * @param {number} param.limit 每页数量
 * @param {string} param.delivery_id 投放 id
 * @param {string} param.filter_cate_id 过滤分类id
 * @param {string} param.cate_id 分类id
 * @param {string} param.did 商品分类投放id
 * @returns 
 */
export const getAddBuyProductList = ({ promotion_id, range, brand, page, limit, delivery_id, filter_cate_id, cate_id, did }) => {
  const { currency, appLanguage } = window.gbCommonInfo
  const addressInfo = getAddressInfo()

  // 此处app中间层要求请求体格式为formData
  const formData = new FormData()
  formData.append('promotion_id', promotion_id)
  formData.append('range', range)
  brand && formData.append('brand', brand)
  formData.append('page', page)
  formData.append('limit', limit)
  formData.append('country_id', addressInfo?.countryId || '')
  delivery_id && formData.append('delivery_id', delivery_id)
  filter_cate_id && formData.append('filter_cate_id', filter_cate_id)
  cate_id && formData.append('cate_id', cate_id)
  did && formData.append('did', did)

  return schttp({
    method: 'POST',
    url: '/promotion/add_buy_product_list',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}

/**
 * 获取赠品列表
 * @param {object} param
 * @param {string} param.promotion_id 促销活动 id
 * @param {number} param.range 活动的档次
 * @param {number} param.page 页码
 * @param {number} param.limit 每页数量
 * @param {string} param.delivery_id 投放 id
 * @returns 
 */
export const getGiftProductList = ({ promotion_id, range, page, limit, delivery_id }) => {
  const { currency, appLanguage } = window.gbCommonInfo
  const addressInfo = getAddressInfo()

  // 此处app中间层要求请求体格式为formData
  const formData = new FormData()
  formData.append('promotion_id', promotion_id)
  formData.append('range', range)
  formData.append('page', page)
  formData.append('limit', limit)
  formData.append('country_id', addressInfo?.countryId || '')
  delivery_id && formData.append('delivery_id', delivery_id)

  return schttp({
    method: 'POST',
    url: '/promotion/gift_product_list',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      'AppCurrency': currency,
      'AppLanguage': appLanguage,
    },
    useBffApi: true
  })
}

export const cartLoginPromise = ({ type = '', config = {} }) => {
  const scenes = {
    'checkout': 'checkout',
    'saveforlater': 'wishlist',
    'wishlist': 'wishlist',
    'cart': 'cart',
  }

  const scene = scenes[type] || 'other'

  return new Promise(resolve => {
    SHEIN_LOGIN.show({
      from: scene,
      bi: scene,
      ...config,
      cb (res) {
        resolve(res)
      }
    })
  })
}

export const getCartEntranceGuideApi = async ({ auto_use_coupon }) => {
  const { currency, appLanguage } = window.gbCommonInfo
  const addressInfo = getAddressInfo()

  // 此处app中间层要求请求体格式为formData
  const formData = new FormData()
  formData.append('country_id', addressInfo?.countryId)
  formData.append('auto_use_coupon', auto_use_coupon)

  return schttp({
    method: 'POST',
    url: '/order/cart/get_entrance_guide',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      'AppCurrency': currency,
      'AppLanguage': appLanguage,
      'frontend-scene': 'page_web_common'
    },
    useBffApi: true
  })
}
