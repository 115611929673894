/**
 * 通用弹窗
 */
const mutations = {
  showCommonModal(state, { titleText, cancelText, okText, okCallback = () => {}, cancelCallback = () => {} }) {
    state.modalOptions = {
      show: true,
      titleText,
      cancelText,
      okText,
      okCallback,
      cancelCallback
    }
  },
  hideCommonModal(state) {
    state.modalOptions = {
      show: false,
      titleText: '',
      cancelText: '',
      okText: '',
      okCallback: () => {},
      cancelCallback: () => {},
    }
  }
}

export default mutations
