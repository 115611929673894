import { PRODUCT_LIST_URL_API, PRODUCT_LIST_REQUEST_URL_MAP } from './const.js'

import schttp from 'public/src/services/schttp'
import { promiseAsyncAuto } from '../common/asyncAuto.js'
import { generateRealSelectBffFilterInfo } from './utils.js'

import { getFetchParams } from './params/index.js'

function getUrl({ catInfo }) {
  if (!catInfo) throw new Error('catInfo is required')
  if (catInfo?.type == 'picks') {
    return location.pathname
  }
  return PRODUCT_LIST_URL_API
}

const getBffUrl = ({ catInfo }) => {
  if (!catInfo) throw new Error('catInfo is required')

  const { SEARCH } = PRODUCT_LIST_REQUEST_URL_MAP
  switch (catInfo.type) {
    case 'search':
      return {
        goods: SEARCH.goods,
        filters: SEARCH.filters,
      }
  }
}

// 商品列表
const bffProductRequest = (options) => {
  const method = options.method || 'POST'
  const url = getBffUrl(options)?.goods

  schttp({
    method,
    url,
    params: options.params,
    useBffApi: true,
  }).then((res) => {
    options.cb(null, res)
  })
}

// 分类筛选等
const bffFilterRequest = (options) => {
  const method = options.method || 'GET'
  const url = getBffUrl(options)?.filters
  
  schttp({
    method,
    url,
    params: options.params,
    useBffApi: true,
  }).then((res) => {
    options.cb(null, res)
  })

}

// Warn: 非泛列表页面，请求写到自己链路里
/**
 * @description: 获取商品列表
 * @param {Object} config 请求参数
 * @param {Object} config.catInfo 分类信息
 * @param {Object} config.searchKeywords 搜索关键词
 * @param {Object} config.atomicParams 原子参数
 * @param {Object} config.viewedGoods 已查看商品
 * @param {Object} config.request 请求参数
 *
 */
export async function fetchProductListApi(config, options = {}) {
  const requertParams = getFetchParams(config)

  // 是否走融合
  const useBffProducts = requertParams?.nodeParams?.useBffProducts

  // 首屏请求商品数据
  const isBffPrePureGoods = useBffProducts && config.request?.query?.prePureGoods === 1 && config.request?.query?.page === 1

  const requertQueue = {}

  if (!isBffPrePureGoods) {
    // node请求
    requertQueue.nodeRes = (cb) => {
      schttp({
        url: getUrl(config),
        params: requertParams.nodeParams,
        ...options
      }).then((res) => {
        cb(null, res)
      })
    }
  }

  // 商品
  if (requertParams.goodsParams) {
    requertQueue.goods = (cb) => {
      bffProductRequest({
        ...config,
        params: requertParams.goodsParams,
        cb
      })
    }
  }

  // 筛选
  if (requertParams.filterParams && config.request?.type !== 'pageChange' && !isBffPrePureGoods) {
    requertQueue.filters = (cb) => {
      bffFilterRequest({
        ...config,
        params: requertParams.filterParams,
        cb
      })
    }
  }

  const results = await promiseAsyncAuto(requertQueue)

  return formatData(config, results)
}

// 处理返回参数
const formatData = (config, results) => {
  const catInfo = config.catInfo || {}

  if (results?.goods) {
    // bff处理
    const goods = results.goods ?? {}
    const filters = results.filters ?? {}
    const cloudTags = results.cloudTags ?? {}
    const dailyDates = results.dailyDates ?? {}
  
    const bffProductsInfo = goods.code === '0' ?  { code: '0', ...goods.info } : {}
    // 日期选择数据
    const bffDailyDates = dailyDates.code === '0' ?  { code: '0', ...dailyDates.info } : {}
  
    let bffFilterInfo = {}
    if (catInfo.type === 'search') {
      bffFilterInfo = filters.code === '0' ? filters.info : {}
    } else if (['entity', 'selection'].includes(catInfo.type)) {
      bffFilterInfo = generateRealSelectBffFilterInfo({
        bffCateAttrResult: filters.code === '0' ? filters : {},
        bffCloudTagResult: cloudTags.code === '0' ? cloudTags : {},
        bffDailyDatesResult: dailyDates.code === '0' ? dailyDates : {}
      }, catInfo)
    }

    return {
      ...results.nodeRes,

      bffProductsInfo,
      bffFilterInfo,
      bffDailyDates,
    }
  } else {
    // 非融合直接返回
    return results.nodeRes
  }
}

