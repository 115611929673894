import DetailPrefetch from '../utils/DetailPrefetch.js'
import { getCCCXBanner, getNewOutfitInfo, getDetailsPictures, getBuyerShowFloor, getTogetherBetterDealsPrice } from './index.js'

/**
 * key: CCCXBanner | cccxbanner 数据
 * key: ComboBuy   | 组合购数据
 */

/**
 * @description BTF 数据预取
 * BTF 首屏数据统一在这里预取，底部推荐除外
 * @param {*} 
 */
const prefetchBTF = (params) => {
  const { 
    goods_sn, 
    goods_id, 
    cat_id, 
    is_sold_out, 
    mall_code, 
    is_paid_user,
    product_relation_id,
    comboBuyParamsInfo,
  } = params
  const queens = [
    DetailPrefetch.register({ key: 'CCCXBanner', value: getCCCXBanner(goods_sn) }),
    DetailPrefetch.register({ key: 'DetailsPicturesInfo', value: getDetailsPictures(goods_id) }),
    DetailPrefetch.register({ key: 'BuyerShowFloorInfo', value: getBuyerShowFloor({ goods_sn, goods_id, page: 1, pageSize: 20, product_relation_id }) }),
    DetailPrefetch.register({ key: 'NewOutfitInfo', value: getNewOutfitInfo({ goods_sn, goods_id, cat_id, is_sold_out, mall_code, is_paid_user }) 
    })
  ]

  // 组合购
  const { is_need_combo_buy = false, comboBuyParams = {} } = comboBuyParamsInfo
  if (is_need_combo_buy) {
    queens.push(DetailPrefetch.register({ key: 'ComboBuy', value: getTogetherBetterDealsPrice(comboBuyParams) }))
  }

  return new Promise(resolve => {
    Promise.all(queens).then(res => {
      resolve(res)
    })
  })
}


export default prefetchBTF
