<template>
  <div
    class="bsc-gc-card"
  >
    <div
      class="card-prime"
      :style="{
        ...getGrayStyle,
        height: props.cardHeight,
      }">
      <div
        v-if="isShowSeal"
        class="card-prime__fail">
        <img
          class="img"
          :src="getItem.unavailable_seal_url" />
        <span
          class="text"
          :style="{
            transform: `translate(${GB_cssRight? '50%' : '-50%'}, -50%) rotate(-15deg)`
          }"
        >
          {{ getItem.unavailable_seal_tip }}
        </span>
      </div>
      <div
        class="card-prime__header"
        :style="getGrayStyle">
        <img
          class="card-prime__img"
          :src="getCardImg" />
        <div class="card-prime__content">
          <div
            v-if="getItem.card_no_tip"
            class="content__id">
            <span>{{ getItem.card_no_tip || '' }}</span>
            <Icon
              v-if="props.enableCopy"
              class="copy-btn"
              name="sui_icon_copy_14px"
              size="14px"
              :data-clipboard-text="getItem.card_no"
              @click="handleCopyId"
            />
          </div>
          <div class="content__val">
            {{ getPriceTitle }}
            <div
              v-if="props.enableGoDetail"
              class="content__val--more"
              @click="() => emits('goDetail', getItem.card_no)">
              <span>{{ language?.SHEIN_KEY_PWA_35136 }}</span>
              <Icon
                name="sui_icon_more_right_18px"
                size="12px"
                color="#fff"
                :is-rotate="GB_cssRight"
              />
            </div>
          </div>
        </div>
        <div
          v-if="getItem?.bind_card_tip"
          class="card-prime__tag"
        >
          <img
            src="https://shein.ltwebstatic.com/svgicons/2024/11/21/17321768361225399327.svg"
            alt="svg" />
          <div class="tip">{{ getItem.bind_card_tip }}</div>
        </div>

      </div>
      <div
        class="card-prime__expire"
        @click="!props.readonly && emits('click')"
      >
        <div
          v-if="!props.readonly"
          class="expire__checked">
          <SRadio
            :modelValue="isSelect"
            gap="0"
            :label="true"
          />
        </div>
        <div class="expire__tip">
          {{ getItem.card_status_tip }}
        </div>
        <div class="expire__footer">
          <div class="time">
            {{ getExpTitle }}
          </div>
          <div
            class="balance"
            :style="{
              direction: 'ltr',
              display: 'flex',
              alignItems: 'baseline'
            }"
          >
            {{ getBalanceText.beforeText }}&nbsp;
            <span
              v-if="getBalanceSignBefore"
              class="balance__small">
              {{ getPriceSign }}
            </span>
            <TextAdaptation
              class="balance__large"
              :text="getPriceInteger"
              :size-limits="[16, 26]"
              max-width="70px"
            />
            <span class="balance__small">
              {{ getPriceDecimal }}
            </span>
            <span
              v-if="!getBalanceSignBefore"
              class="balance__small">
              {{ getPriceSign }}
            </span>
            {{ getBalanceText.afterText }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="getItem.unavailable_reason"
      class="bsc-gc-card__footer">
      <Icon
        class="icon"
        name="sui_icon_reported_14px_1"
        size="12px" />
      <span>{{ getItem.unavailable_reason }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { inject, computed } from 'vue'
import { template, timeTransformer } from '@shein/common-function'
import Clipboard from 'clipboard'
import { SRadio } from '@shein-aidc/sui-radio/laptop'
import { Icon } from '@shein-aidc/icon-vue3'
import { SMessage } from '@shein-aidc/sui-message/laptop'
import TextAdaptation from './TextAdaptation.vue'
import { MESSAGE_DATE_PC } from '../../../common/time'
import { type C_GiftCard } from '../../../types/index.ts'

// 这个文件里面不应该有埋点
const { GB_cssRight } = gbCommonInfo

const props = withDefaults(defineProps<{
    backgroundGray?: boolean
    enableCopy?: boolean
    enableGoDetail?: boolean
    readonly?: boolean
    isSelect?: boolean
    item: C_GiftCard.CardItem
    cardHeight?: string
  }>(),
  {
    backgroundGray: false,
    enableCopy: false,
    enableGoDetail: false,
    readonly: false,
  })

const emits = defineEmits(['click', 'goDetail'])

const language: C_GiftCard.language = inject('language')!

const getItem = computed(() => props.item)
const isShowSeal = computed(() => getItem.value.unavailable_seal_url && getItem.value.unavailable_seal_tip)
const getGrayStyle = computed(() => (props.backgroundGray && isShowSeal.value) ? {
  filter: 'grayscale(100%)',
} : undefined)
const getPriceTitle = computed(() => template(getItem.value?.shop_price?.amountWithSymbol, language?.value?.SHEIN_KEY_PWA_35102) || '')
const getCardImg = computed(() => getItem.value?.image_url || 'https://img.ltwebstatic.com/images3_ccc/2024/11/21/95/173217579574e3e2ccd14b9be72301ceed2c4c1b17.png')

const getExpTitle = computed(() =>{
  const isPreStatus = [0, 1].includes(+getItem.value?.card_status)
  const expTime: number = +(isPreStatus ? getItem.value?.pre_active_time : getItem.value?.expire_time) || 0
  const time = expTime ? `${timeTransformer({
    time: expTime * 1000,
    sDateMap: MESSAGE_DATE_PC,
    multiLang: false,
  })} ${timeTransformer({
    time: expTime * 1000,
    defaultCode: 'G-1',
    multiLang: false,
  })}` : 0
  const preTimeTip = time ? template(time, language?.value?.SHEIN_KEY_PWA_35137) : ''
  const expireTimeTip = getItem.value.expire_time === 0 ? language?.value?.SHEIN_KEY_PWA_35105 : template(time, language?.value?.SHEIN_KEY_PWA_35104)
  return isPreStatus ? preTimeTip : expireTimeTip
})

const getBalanceText = computed(() => {
  const matches = (language?.value?.SHEIN_KEY_PWA_35106 || '')?.match(/(.*?)\{0\}(.*?)/)
  return matches ?
  {
    beforeText: matches?.[1]?.trim() || '',
    afterText: matches?.[2]?.trim() || '',
  } :
  {
    beforeText: '',
    afterText: '',
  }
})
const getBalanceSignBefore = computed(() => `${parseInt(getItem.value?.balance?.amountWithSymbol)}` === 'NaN')

const getPriceSign = computed(() => {
  const regExp = new RegExp(`(?<left>.*?)(${getItem.value?.balance?.priceShowStyle})(?<right>.*)`)
  const { groups } = getItem.value?.balance?.amountWithSymbol.match(regExp) || {}
  return groups?.left || groups?.right || ''
})
const getPriceInteger = computed(() => getItem.value?.balance?.priceShowStyle)
const getPriceDecimal = computed(() => {
  try {
    const regExp = new RegExp(`(?<left>.*?)(${getItem.value?.balance?.priceShowStyle})(?<right>.*)`)
    const { groups } = getItem.value?.balance?.amountWithSymbol.match(regExp) || {}
    const val = `${groups?.left}${groups?.right}`
    const reg = new RegExp(`\\${getPriceSign.value}`,'g')
    return val?.replace(reg, '')
  } catch(e) {
    return ''
  }
})

const handleCopyId = () => {
  if (!getItem.value.card_no) return
  try {
    const clipboard = new Clipboard('.copy-btn')
    clipboard.on('success', (e) => {
      SMessage({
        message: language?.value?.SHEIN_KEY_PWA_16466 || 'copy success',
        type: 'success',
      })
      e.clearSelection()
      clipboard.destroy()
    })
  } catch(err) {
    console.error('Failed to copy: ', err)
  }
}
</script>

<style lang="less">
.bsc-gc-card {
  color: #000;
  position: relative;
  width: 380px;
  .card-prime {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    background: url('https://img.ltwebstatic.com/images3_ccc/2024/12/13/44/173408093842029e3984551567096a3fd9eda49293.png') no-repeat;
    background-size: 100%;
    background-position: bottom;
    &__fail {
      position: absolute;
      z-index: 10;
      right: 10px;
      top: 10px;
      .img {
        width: 83px;
      }
      .text {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 60px;
        color: #666;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
        font-weight: 400;
      }
    }
    &__header {
      display: flex;
      align-items: center;
      padding: 16px 10px;
      background: url('https://img.ltwebstatic.com/images3_ccc/2024/12/13/af/1734079464a4cea70530c05a87322416e1db7c48f7.webp') no-repeat;
      background-size: 100%;
      background-position: bottom;
    }
    &__img {
      width: 60px;
      height: 36px;
      margin-right: 6px;
    }
    &__content {
      color: #FFF;
      white-space: nowrap;
      line-height: 1.2;
      .content {
        &__id {
          display: flex;
          align-items: center;
          margin-bottom: 3px;
          span {
            overflow: hidden;
            max-width: 200px;
            text-overflow: ellipsis;
            font-size: 14px;
            font-weight: 590;
            margin-right: 3px;
          }
        }
        &__val {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 400;
          &--more {
            cursor: pointer;
            margin-left: 5px;
            padding-left: 5px;
            display: flex;
            border-left: .5px solid #fff;
            align-items: center;
            span {
              display: block;
              overflow: hidden;
              max-width: 120px;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
    &__tag {
      position: absolute;
      right: 10px;
      top: 10px;
      display: flex;
      align-items: center;
      img {
        position: relative;
        width: 12px;
        height: 12px;
      }
      .tip {
        overflow: hidden;
        max-width: 38px;
        white-space: nowrap;
        color: #FFD0CC;
        text-align: right;
        text-overflow: ellipsis;
        font-size: 12px;
        font-weight: 400;
        margin-left: 2px;
      }
    }
    &__expire {
      padding: 10px 10px 45px;
      .expire {
        &__checked {
          position: relative;
          display: flex;
          justify-content: flex-end;
          right: 2px;
          text-align: right;
          .sui-radio__check {
            border-color: #222222;
            border-width: 0.5px;
            margin-right: 0;
          }
        }
        &__tip {
          overflow: hidden;
          color: #3CBD45;
          text-overflow: ellipsis;
          font-size: 16px;
          font-weight: 590;
          max-width: 170px;
          white-space: nowrap;
        }
        &__footer {
          box-sizing: border-box;
          position: absolute;
          bottom: 20px;
          left: 0;
          width: 100%;
          padding: 0 10px;
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          line-height: 1;
          .time {
            color: #525252;
            font-size: 12px;
            font-weight: 400;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 160px;
          }
          .balance {
            font-size: 12px;
            font-weight: 400;
            display: flex;
            align-items: baseline;
            overflow: hidden;
            white-space: nowrap;
            max-width: 160px;
            &__large {
              position: relative;
              bottom: 0;
              font-weight: 600;
            }
            &__small {
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  &__footer {
    font-size: 12px;
    font-weight: 400;
    padding: 6px 8px;
    color:#BF4123;
    margin-top: 8px;
    border-radius: 4px;
    background: linear-gradient(90deg, rgba(255, 103, 66, 0.18) 52.24%, rgba(255, 109, 68, 0.00) 100%);
    .icon {
      margin-right: 1px;
    }
  }
}
</style>
