<template>
  <section
    v-expose="analysisDataEvent()"
    class="section shipping-sec"
  >
    <!-- 普通免邮、平台免邮、首单免邮、平台首单免邮 -->
    <!-- PS:根节点层级的注释会影响测试环境打包时, 父组件透传属性如class之类 -->
    <div class="section-l">
      <sui_icon_shipping_18px
        class="icon_shipping"
        size="18px"
      />
    </div>
    <div class="section-r">
      <p
        class="title"
      >
        <span>{{ language.SHEIN_KEY_PC_15066?.replace(':','') }}</span>
        <a
          v-if="showShipSupplement"
          v-expose="analysisEvent('1-7-1-21')"
          v-tap="analysisEvent('1-7-1-20')"
          class="operation"
          role="button"
          tabindex="0"
          @keydown.stop.enter="clickCartAdd"
          @click="clickCartAdd"
        >
          {{ language.SHEIN_KEY_PC_15215 }}
          <sui_icon_more_right_12px
            :is-rotate="locals.GB_cssRight"
            size="12px"
          />
        </a>
      </p>
      <ul class="contain not-fsp-element">
        <li>
          <template v-if="firstFreeShipping">
            <p class="contain-tips">
              <span v-html="freeShippinpTips"></span>
            </p>
            <ClientOnly>
              <count-down-time
                v-if="firstFreeShipping.showCountDown && firstFreeShipping.countDownTime > 0"
                :time-stamp="firstFreeShipping.countDownTime"
                :is-left-time="true"
                show-type="inline-promotion"
                :show-end-tips="true"
              />
            </ClientOnly>
          </template>
          <span
            v-else
            v-html="freeShippinpTips"
          ></span>
        </li>
      </ul>
    </div>
  </section>
</template>
<script>
export default {
  name: 'ShippingInfoTip'
}
</script>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'
import { sui_icon_shipping_18px, sui_icon_more_right_12px } from '@shein-aidc/icon-vue3'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import countDownTime from 'public/src/pages/cart_v2/components/functional/common/CartCountDown.vue'
import { useCartAddItem } from 'public/src/pages/cart_v2/hooks/useCartAddItem.js'

const { state } = useStore()
const AddItem = useCartAddItem()

const props = defineProps({
  shippingInfo: {
    type: Object,
    default: () => ({})
  },
  mallCode: {
    type: String,
    default: '',
  }
})
const language = computed(() =>state.language)
const locals = computed(() => state.locals)
const freeShippinpTips = computed(() => {
  return props.shippingInfo?.tip || ''
})

const showShipSupplement = computed(() => {
  return props.shippingInfo?.isOpenDiff == 1 ? true : false
})

const firstFreeShipping = computed(() => { // 首单免邮
  return props.shippingInfo.freeType == 1 ?  props.shippingInfo || null : null
})
const analysisEvent = (id) => {
  return {
    id: firstFreeShipping.value ? '' : id,
    data: {
      shipping_free_type: props.mallCode == 'platform' ? 0 : 3,
      mall_code: props.mallCode == 'platform' ? '' : props.mallCode,
    }
  }
}
const shippingAnalysisParam = computed(() => {
  return state.mallShippingAnalysisParam[`${props.mallCode}`] || {}
})

const analysisDataEvent = (type) => {
  let id = ''
  let { shipping_detail_obj } = props.shippingInfo
  if(type == 'click'){
    id = shipping_detail_obj ? '1-7-5-6' : '1-7-5-4'
  } else {
    id = shipping_detail_obj ? '1-7-5-5' : '1-7-5-3'
  }
  return {
    id: id,
    data: {
      ...shippingAnalysisParam.value,
      mall_code: props.mallCode == 'platform' ? '' : props.mallCode,
    }
  }
}

const clickCartAdd = () => {
  daEventCenter.triggerNotice(analysisDataEvent('click'))
  AddItem.show({
    popupInfoId: props.shippingInfo?.popupInfoId,
    state: 'shipping_add',
  })
}
</script>

<style lang='less' scoped>
.shipping-sec {
  border: 0.5px solid rgba(25, 128, 85, 0.3);
}
.icon_shipping{
  color: #222222;
}
.contain-tips {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
