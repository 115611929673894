<template>
  <div
    ref="marqueeRef"
    class="bsc-cart-item-row-marquee"
    :style="style"
  >
    <div class="bsc-cart-item-row-marquee__content">
      <slot :start="start"></slot>
    </div>
  </div>
</template>
<script name="RowMarquee" setup lang="ts">
import { onMounted, ref, nextTick, computed, defineExpose } from 'vue'
import { useIntersectionObserver } from '../hooks/useIntersectionObserver'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

const props = defineProps({
  speed: { // 每帧移动1px（常规浏览器每秒60帧）
    type: Number,
    default: 1,
  },
  forced: { // 强制跑马灯效果，如果内容不足会copy元素
    type: Boolean,
    default: false,
  },
  gap: {
    type: String,
    default: '36px',
  },
  // 触发时机
  trigger: {
    type: String,
    default: 'mounted',
    validator: (trigger: string) => ['mounted', 'observer', 'unset'].includes(trigger),
  },
})

const appConfigs = useAppConfigs()

const marqueeRef = ref(null)
const intervel = ref<{cancel: () => void} | null>(null)
const moveLeft = ref(0)

const style = computed(() => {
  return {
    '--gap-val': props.gap,
    '--move-val': appConfigs.$envs.cssRight ? `${moveLeft.value}px` : `-${moveLeft.value}px`,
  }
})

const start = () => {
  nextTick(() => {
    initMarquee(marqueeRef.value, props.speed)
  })
}

const initMarquee = (selector, speed) => {
  const parentSelector = selector as HTMLElement
  const cloneEle = parentSelector.innerHTML
  const firstEle = parentSelector.children[0] as HTMLElement

  const childWidth = firstEle.getBoundingClientRect().width
  const parentWidth = parentSelector.getBoundingClientRect().width
  if (childWidth <= parentWidth) {
    if (!props.forced) return
    const insertCount = (Math.floor(parentWidth / childWidth) || 0) * 2
    for (let i = 0; i < insertCount; i++) {
      parentSelector.insertAdjacentHTML('beforeend', cloneEle)
    }
  } else {
    parentSelector.insertAdjacentHTML('beforeend', cloneEle) // 复制用于跑马灯无缝连接的效果
  }

  intervel.value?.cancel()
  intervel.value = customeInterval(function () {
    if (moveLeft.value > firstEle.clientWidth) {
      moveLeft.value = 0
    }
    moveLeft.value += speed
  }, 0)
}

const customeInterval = (callback, interval) => {
  let start = null //开始时间
  let frameId
  const loop = (timestamp) => {
    if (!start) start = timestamp // 记录开始时间
    const elapsed = timestamp - start // 计算经过的时间
    if (elapsed >= interval) {
      callback() // 执行回调
      start = timestamp // 重置开始时间
    }
    frameId = requestAnimationFrame(loop) // 请求下一帧
  }
  frameId = requestAnimationFrame(loop) // 启动循环

  return {
    cancel: () => {
      cancelAnimationFrame(frameId)
    },
  }
}

if (props.trigger === 'mounted') {
  onMounted(() => {
    start()
  })
} else if (props.trigger === 'observer') {
  useIntersectionObserver(marqueeRef, ({ isVisible, hasBeenVisible }) => {
    if (!isVisible || hasBeenVisible) return
    start()
  })
}

defineExpose({
  start,
})

</script>
<style lang="less" scoped>
.bsc-cart-item-row-marquee {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
 .bsc-cart-item-row-marquee__content {
    height: 100%;
    width: initial;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin: 0 auto;
    flex-shrink: 0;
    overflow: hidden;
    transform: translateX(var(--move-val));
    &:not(:last-of-type) {
      padding-right: var(--gap-val);
    }
 }
}
</style>
