import { _createApp } from './base'
import { businessLibInitializeForCSR } from 'public/src/pages/common/business-lib-initialize/csr.js'

const homepageClientRender = (context) => {
  const { app } = _createApp(context)
  businessLibInitializeForCSR(app, { bsLibsEnvs: context.bsLibsEnvs })
  app.mount('.j-home-index')
}

homepageClientRender(window.gbRawData)
