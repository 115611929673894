import type { AS_BindGiftCard } from '../../../types'

const standard: AS_BindGiftCard.AnalysisFuncs = {
  // TODO
  'click_your_sa_event_name.comp_bind-gift-card': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_giftcard_link_check.comp_bind-gift-card': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_giftcard_checkbalance_submit.comp_bind-gift-card': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_bind_card_swtich.comp_bind-gift-card': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_bind_card_swtich.comp_bind-gift-card': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_card_confirm.comp_bind-gift-card': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_stored_giftcard_list.comp_bind-gift-card': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_stored_giftcard_list.comp_bind-gift-card': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_input_giftcard_pop.comp_bind-gift-card': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
}

export default standard
