export const USE_BFF_API_KEYS = [
  'cart_v5',
]

// 需要上报品牌名的活动
export const BRAND_PRODUCT_PROMOTION_TYPE_IDS = [2, 28]
// 促销活动标题
export const TITLE_PROMOTION_TYPE_IDS = {
  SHEIN_KEY_PC_16032: [1, 9, 14, 15, 16, 17, 18, 19],  // BuyMoreSaveMore
  SHEIN_KEY_PC_16033: [2, 4, 28],  // GiftsReward
  SHEIN_KEY_PC_15966: [13, 22],    // AddOnItems
  SHEIN_KEY_PC_16035: [20] // ReturnCoupons
}
