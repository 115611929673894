import { ref, computed } from 'vue'
import { getCanceledOrderInfo } from 'public/src/pages/cart_v2/services/api.js'
import { useStore } from 'vuex'
import { delCache, getCache, UN_PAID_ORDER_CACHE_KEY, CANCEL_ORDER_CACHE_KEY, setCache } from './cache.js'
import { clickCancelledTipViewReport } from './analysis.js'

export const useCancelOrderTip = () => {
  // data
  const data = ref({})
  const isShow = ref(false)
  const refDrawer = ref(null)
  const isOpen = ref(false)

  // store
  const { state, dispatch } = useStore()

  // computed
  const language = computed(() => state.language)
  const isShowIncentive = computed(() => Object.keys(data.value?.lureInfo ?? {}).length > 0)
  const popupInfo = computed(() => data.value?.popupInfo ?? {})
  const goodsImg = computed(() => data.value?.goodsImgList ?? [])
  const goodsNum = computed(() => +data.value?.goodsNum)
  const incentiveIcon = computed(() => data.value.lureInfo?.prefixIcon)
  const incentiveText = computed(() => data.value.lureInfo?.text)
  const titleText = computed(() => data.value.canceledOrderTip)
  const btnText = computed(() => language.value.SHEIN_KEY_PC_19825)
  const isPlatform = computed(() => state.isPlatform)


  const getData = () => {
    return getCanceledOrderInfo([
      ...getCache(UN_PAID_ORDER_CACHE_KEY),
      ...getCache(CANCEL_ORDER_CACHE_KEY),
    ]).then(res => {
      if (res?.type == 1 && res?.popupInfo?.canceledProductList?.length > 0) {
        // 追加 index 方便埋点
        res?.popupInfo?.canceledProductList?.forEach((item, index) => {
          item.index = index
          item.id = `${item.goodsId}_${item.skuCode}_${item.mallCode}`
        })
        data.value = res
        isShow.value = true
        setCache(res.billno, CANCEL_ORDER_CACHE_KEY)
      }
    })
  }

  const toggleShow = () => {
    isShow.value = !isShow.value
  }

  const handleAddCartSuccess = async () => {
    dispatch('fetchCartIndex')
    handleToggleDrawer()
    toggleShow()
  }

  const handleToggleDrawer = () => {
    isOpen.value = !isOpen.value
    if (isOpen.value) {
      clickCancelledTipViewReport()
    }
  }

  const handleRefresh = async () => {
    refDrawer.value.showLoading?.()
    delCache(data.value.billno, CANCEL_ORDER_CACHE_KEY)
    await getData()
    refDrawer.value.hideLoading?.()
  }

  const handleAddMoreSuccess = async () => {
    await handleRefresh()
    dispatch('fetchCartIndex')
  }

  return {
    // variables
    refDrawer,
    isOpen,
    data,
    popupInfo,
    isShow,
    isShowIncentive,
    goodsImg,
    goodsNum,
    titleText,
    btnText,
    incentiveIcon,
    incentiveText,
    isPlatform,
    language,

    // methods
    getData,
    toggleShow,
    handleAddCartSuccess,
    handleToggleDrawer,
    handleRefresh,
    handleAddMoreSuccess,
  }
}
