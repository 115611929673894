<template>
  <div
    ref="tempRef"
    class="bsc-cart-item-single__wrap">
    <!-- 商品行内容 -->
    <div class="bsc-cart-item-single__top">
      <GoodsImg
        v-if="goodsImgData.isShow"
        v-bind="goodsImgData.attrs"
      >
        <template #left>
          <GoodsDiscountFlag
            v-if="goodsDiscountFlagData.isShow"
            v-bind="goodsDiscountFlagData.attrs"
          />
        </template>
        <template #right>
          <GoodsSeriesFlag
            v-if="goodsSeriesFlagData.isShow"
            v-bind="goodsSeriesFlagData.attrs"
          />
        </template>
        <template #bottom>
          <GoodsBelt
            v-if="goodsBeltData.isShow"
            v-bind="goodsBeltData.attrs"
          />
        </template>
      </GoodsImg>
    </div>
    <div class="bsc-cart-item-single__bottom">
      <GoodsPriceV1
        v-if="goodsPriceData.isShow"
        v-bind="goodsPriceData.attrs"
      />
      <FindSimilarText
        v-if="findSimilarTextData.isShow"
        class="bsc-cart-item-single__find-similar-text"
        v-bind="findSimilarTextData.attrs"
      />
    </div>
  </div>
</template>

<script name="BSingleImageItem" setup lang="ts">
import type { Laptop } from '../../../types/laptop'
import { onMountedOrActivated } from '../hooks/onMountedOrActivated'


// cell components
import { GoodsImg, useGoodsImg } from '../cell/goods-img'
import { GoodsDiscountFlag, useGoodsDiscountFlag } from '../cell/goods-discount-flag'
import { GoodsBelt, useGoodsBelt } from '../cell/goods-belt'
import { GoodsPriceV1, useGoodsPrice } from '../cell/goods-price'
import { GoodsSeriesFlag, useGoodsSeriesFlag } from '../cell/goods-series-flag'
import { FindSimilarText, useFindSimilarText } from '../cell/find-similar-text'
/**
 * 单图模板
 */

// template hooks
import { useTemplate } from '../hooks/useTemplate'

// emit
const emit = defineEmits(['expose'])

// template props
const props = defineProps<{
  data?: Laptop.Data
  config?: Laptop.Config<Laptop.SingleImage>
  language: Laptop.Language<Laptop.SingleImage>,
  constant: Laptop.Constant<Laptop.SingleImage>,
  use: Laptop.SingleImage[],
}>()

// hooks
const {
  // ref
  tempRef,

  // helpers data
  helpersData: [
    goodsImgData,
    goodsDiscountFlagData,
    goodsBeltData,
    goodsPriceData,
    goodsSeriesFlagData,
    findSimilarTextData,
  ],

  // methods
  registerExpose,
} = useTemplate(
  props,
  // Helper functions used by the current template
  [
    useGoodsImg,
    useGoodsDiscountFlag,
    useGoodsBelt,
    useGoodsPrice,
    useGoodsSeriesFlag,
    useFindSimilarText,
  ],
)

// methods
const handleExpose = () => {
  const callback = () => emit('expose')
  registerExpose('MainCartItem', callback)
}

onMountedOrActivated(handleExpose)
</script>

<style lang="less">
// 模板宽度
@template-width: 100px;
// 模板上侧高度
@template-top-height: 100px;
// 模板上下间距
@template-gap: 4px;
// 模板背景色
@template-background-color: #fff;

.bsc-cart-item-single {
  &__wrap {
    display: flex;
    flex-direction: column;
    background-color: var(--template-background-color, @template-background-color);
    text-align: left;
    width: var(--template-width, @template-width);
    line-height: 1.15;
  }
  &__content {
    display: flex;
  }
  &__top {
    margin-bottom: var(--template-gap, @template-gap);
    width: 100%;
    height: var(--template-top-height, @template-top-height);
  }
  &__bottom {
    flex: 1;
  }
  &__find-similar-text {
    width: 100%;
  }
}
</style>
