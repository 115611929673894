
import { daEventCenter } from 'public/src/services/eventCenter/index'


export const sendSaClickPromotiondetails = ({ isOpen }) => {
  daEventCenter.triggerNotice({
    daId: '1-7-2-19',
    extraData: {
      is_open: +isOpen,
    },
  })
}

export const sendSaExposePromotiondetailsbox = () => {
  daEventCenter.triggerNotice({
    daId: '1-7-2-2',
  })
}

export const sendSaExposePromotiondetailsOriginalPrice = () => {
  daEventCenter.triggerNotice({
    daId: '1-7-7-21',
  })
}

export const sendSaClickPromotiondetailsDetail = ({ type, isOpen }) => {
  daEventCenter.triggerNotice({
    daId: '1-7-3-50',
    extraData: {
      type,
      is_open: +isOpen,
    }
  })
}
