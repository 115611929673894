import { daEventCenter } from 'public/src/services/eventCenter/index'

export const expose_left_findsimilar = (data, callback) => {
  const defaultData = {
    sale_status: 0,
    main_goods_id: data?.product?.goods_id,
  }
  const isReport = callback?.(defaultData) !== null
  const extraData = callback?.(defaultData) ?? defaultData
  if (isReport) {
    daEventCenter.triggerNotice({ daId: '1-7-3-27', extraData })
  }
}

export const click_left_findsimilar = (data, callback) => {
  const defaultData = {
    sale_status: 0,
    main_goods_id: data?.product?.goods_id,
  }
  const isReport = callback?.(defaultData) !== null
  const extraData = callback?.(defaultData) ?? defaultData
  if (isReport) {
    daEventCenter.triggerNotice({ daId: '1-7-3-28', extraData })
  }
}

export const expose_findsimilar_button = (data, callback) => {
  const defaultData = {
    main_goods_id: data?.product?.goods_id,
  }
  const isReport = callback?.(defaultData) !== null
  const extraData = callback?.(defaultData) ?? defaultData
  if (isReport) {
    daEventCenter.triggerNotice({ daId: '1-7-3-29', extraData })
  }
}

export const click_findsimilar_button = (data, callback) => {
  const defaultData = {
    main_goods_id: data?.product?.goods_id,
  }
  const isReport = callback?.(defaultData) !== null
  const extraData = callback?.(defaultData) ?? defaultData
  if (isReport) {
    daEventCenter.triggerNotice({ daId: '1-7-3-30', extraData })
  }
}

export const expose_findsimilar = (data, callback) => {
  const defaultData = {
    goods_id: data.product.goods_id,
    activeFrom: 'cart',
    isOutOfStock: '1',
    style: 'popup'
  }
  const isReport = callback?.(defaultData) !== null
  const extraData = callback?.(defaultData) ?? defaultData
  if (isReport) {
    daEventCenter.triggerNotice({ daId: '1-7-3-4', extraData })
  }
}


export const click_findsimilar = (data, callback) => {
  const defaultData = {
    goods_id: data.product.goods_id,
    activeFrom: 'cart',
    isOutOfStock: '1',
    style: 'popup'
  }
  const isReport = callback?.(defaultData) !== null
  const extraData = callback?.(defaultData) ?? defaultData
  if (isReport) {
    daEventCenter.triggerNotice({ daId: '1-7-3-5', extraData })
  }
}
