import commentAdapter from './utils.js'
import schttp from 'public/src/services/schttp'
import { getGoodsReviewUrl } from 'public/src/pages/common/utils/index.js'

const { 
  abcCommentAdapter, 
  freeTrailAdapter, 
  makeFreeList
} = commentAdapter

export default {
  namespaced: true,
  state: {
    comment: {},
    free_trial_list: {},
    comment_rank_average: '', // 评论平均分
    reviews_tags: [], // 评论标签

    isLoginChange: false,
    loginModal: {
      // 登陆弹窗
      show: false,
      type: '',
      originRef: null,
      likeType: '',
      callback: null
    },
    isLike: false,

    commentTranslateCache: {}, // 已翻译的评论
    commentTranslateConfig: {},
    translateloading: [],
    currTranslateLang: '',
    translateModel: {
      show: false,
      lang: '',
      comment_id: '',
      cb: null
    },

    isBatchTranslate: false,
    default_lang: '',
    isAutoTranslate: true,
    tempComment: {},

    // checkNotSelectedTips: 0,

    only_local_status: false, // 本地评论显示状态
  },
  getters: {
    comment(state, getters, rootState, rootGetters) {
      return rootGetters['Adaptor/comment'] || {}
    },
    show_reviews(state, getters) {
      const { comment_buy_box_total, store_product_comments } = getters
      return comment_buy_box_total  > 0 || !!store_product_comments.length
    },
    goods_sn(state, getters, rootState, rootGetters) {
      const base_info = rootGetters['Adaptor/base_info'] || {}
      return base_info?.goods_sn
    },
    goods_id(state, getters, rootState, rootGetters) {
      const base_info = rootGetters['Adaptor/base_info'] || {}
      return base_info?.goods_id
    },
    /** 首屏评论 */
    comments(state, getters,) {
      const data = abcCommentAdapter({
        info: {
          comment_info: getters.comment.product_comments 
        }
      }) || []
      return data.info.commentInfo || []
    },
    /** 首屏试用报告 */
    trail_data(state, getters) {
      const data = freeTrailAdapter({
        info: {
          free_trial_list: getters.comment.trail_data?.reportList 
        }
      }) || []
      return makeFreeList(data.info.free_trial_list) || []
    },
    /** 首屏站外评论 */
    store_product_comments(state, getters) {
      const store_product_comments = getters.comment.store_product_comments || []
      const out_store_review_list = store_product_comments.map((comment, index) => ({
        ...(comment || {}),
        isOutStoreReview: true,
        isFirstOne: index === 0,
        comment_id: comment?.store_comment_id || 0,
        // page: Math.ceil((index + 1) / 20)
      }))

      return out_store_review_list || []
    },
    comments_overview(state, getters) {
      return getters.comment.comments_overview || {}
    },
    comment_overview_local(state, getters) {
      const { comments_overview } = getters
      const { localSiteScore, localSiteNumShow, percent_overall_fit } = comments_overview
      // const local_percent_overall_fit = rootState.newProductDetail.CommentPopup.local_percent_overall_fit || {}
      if(+localSiteNumShow == 0 && !localSiteScore) return null
  
      return {
        comment_num: localSiteNumShow,
        comment_rank_average: localSiteScore,
        percent_overall_fit: {
          ...percent_overall_fit,
          // ...local_percent_overall_fit
        }
      }
    },
    only_show_same_item(state, getters){ 
      const { comments } = getters
      if(!comments.length) return false
      // connetLabelTips不存在或为0为普通评论, 找不到说明全是同款组评论
      return !(comments.findIndex(item => !item.connetLabelTips) > -1)
    },
    // 是否展示评论时间新样式
    comment_date_display_new(state, getters, rootState, rootGetters) {
      const screen_abt_config = rootGetters['Adaptor/screen_abt_config']
      return screen_abt_config.Reviewchange?.param?.Datedisplay === 'New'
    },
    /**
     * @var LocalReviews
     * type=A | 不展示本地评论
     * type=B | 展示本地评论
     * type=C | 三期，展示本地评论，展示单条评论本地标识
     * type=D | 三期，展示本地评论，不展示单条评论本地标识，后端评论排序策略调整
     * type=E | 三期，同C，后端评论排序策略调整
     * 目前只有 A B 两种状态 cde 删除了
     */
    show_local_reviews(state, getters, rootState, rootGetters) {
      const screen_abt_config = rootGetters['Adaptor/screen_abt_config']

      return screen_abt_config?.LocalReviews?.param === 'type=B'
    },
    // 是否只显站外评论，即使comment_sum_show：0 && store_product_comments.length > 0
    only_store_comment(state, getters) {
      const { comment_sum_show, store_product_comments } = getters

      return !parseInt(comment_sum_show) && store_product_comments.length > 0
    },
    // 首屏评论标签
    comment_tags(state, getters) {
      return getters.comment.comment_tags || []
    },
    translate_language(state, getters) {
      return getters.comment.translate_language || []
    },
    language_config_set(state, getters) {
      const { translate_language } = getters
      return translate_language.map(item => item.translate_language) || []
    },
    // 商品评论数量具体展示，包含试用报告的数量，不含站外
    comment_num(state, getters) {
      const { comments_overview = {}, comment_sum_show } = getters
  
      return comments_overview.comment_num || parseInt(comment_sum_show)
    },
    // 商品评论数量具体展示，包含buybox, 包含试用报告的数量，不含站外
    comment_buy_box_total(state, getters) {
      const { comments_overview = {}, comment_num } = getters
  
      return comments_overview.commentBuyBoxTotal || comment_num
    },
    // 商品评论数量模糊化展示  包含试用报告的数量
    comment_sum_show(state, getters) {
      const { comments_overview = {} } = getters
  
      return comments_overview.commentNumShow || ''
    },
    // 商品评论数量模糊化展示 不包括试用报告
    only_comment_num_show(state, getters) {
      const { comments_overview = {} } = getters
  
      return comments_overview.onlyCommentNumShow
    },
    // 是否批量翻译
    is_batch_translate(state, getters, rootState, rootGetters) {
      const screen_abt_config = rootGetters['Adaptor/screen_abt_config']
      const isBatchTranslate = +getters.comment.trans_mode === 1
      const isNotBatchTranslate = screen_abt_config?.Reviewchange?.param?.Batch_translate === 'No'
      return isBatchTranslate && !isNotBatchTranslate
    },
    local_rating(state, getters) {
      const { comment_overview_local } = getters
      return comment_overview_local && comment_overview_local.comment_rank_average
    },
    has_fit(state, getters) {
      const { comments_overview } = getters || {}
      return +comments_overview?.hasFit
    },
    // 多维评分
    sel_tag_score(state, getters) {
      const { comments_overview } = getters
      return comments_overview?.sel_tag_score || []
    },
    bffStaticData(state) {
      return state.bffStaticData?.info || {}
    },
    skc_sale_attr(state, getters, rootState, rootGetters) {
      return rootGetters['Adaptor/comment_filter_attr_size'] || []
    },
    // 评论筛选Size数据源
    comment_filter_attr_size(state, getters) {
      const { skc_sale_attr = [] } = getters || {}
      const { attr_value_list, attr_id, attr_name } = skc_sale_attr[0] || {}
      if(!attr_value_list || +attr_id !== 87) return {}
      const newAttr = []
      // 目前只有 size 的筛选
      attr_value_list.forEach(({ attr_value_id, attr_value_name, attr_value_name_en }) => {
        newAttr.push({
          attr_id,
          attr_name,
          attr_value: attr_value_name,
          attr_value_en: attr_value_name_en,
          attr_value_id
        })
      })
  
      return newAttr
    },
    // color筛选
    colors_info(state, getters, rootState, rootGetters) {
      return rootGetters['Adaptor/colors_info'] || []
    },
    // 是否显示付费评论
    show_point_program(state, getters, rootState, rootGetters) {
      const screen_abt_config = rootGetters['Adaptor/screen_abt_config']

      return screen_abt_config?.PointProgram?.param === 'type=A'
    },
    fit_map(state, getters, rootState, rootGetters) {
      const language_v2  = rootGetters['Adaptor/language_v2'] || {}
      return {
        1: language_v2.SHEIN_KEY_PC_14650,
        2: language_v2.SHEIN_KEY_PC_14652,
        3: language_v2.SHEIN_KEY_PC_14651,
      }
    },
    productInfo(state, getters) {
      return getters.bffStaticData?.productInfo || {}
    },
    cateInfos(state, getters) {
      const { productInfo } = getters
      const { cateIds = '', cateInfos = {} } = productInfo || {}
      
      if(!cateIds) return Object.values(cateInfos)
      // 根据cateIds排序
      const cateInfoList = []
      const cateIdsList = cateIds.split(',')
      while (cateIdsList.length) {
        cateInfoList.push(cateInfos[cateIdsList.pop()])
      }
  
      return cateInfoList || {}
    },
    sku_list(state, getters) {
      return getters.bffStaticData?.saleAttr?.multiLevelSaleAttribute?.sku_list
    },
    // 政策落地页
    review_policy_info(state, getters) {
      const comment = getters.comment
      return {
        reviewPolicyHasPopover: comment.reviewPolicyStyle === '1' || false,
        reviewPolicyUrl: comment.reviewPolicyUrl,
      }
    },
  },
  mutations: {
    assignState(state, payload) {
      Object.assign(state, payload)
    },
    update_only_show_report(state, payload) {
      state.only_show_report = payload
    },
    update_default_lang(state, payload) {
      state.default_lang = payload
    },
    update_is_auto_translate(state, payload) {
      state.is_auto_translate = payload
    },
    update_reviews_tags(state, payload) {
      state.reviews_tags = payload
    },
    update_comment_rank_average(state, payload) {
      state.comment_rank_average = payload
    },
  },

  actions: {
    // 点赞
    async like_click_action(
      { rootGetters },
      { comment_id, current_member_zan, sku }
    ) {
      let url = ''
      let method = 'GET'
      let params = {}
      const base_info = rootGetters['Adaptor/base_info'] || {}
      const { goods_sn } = base_info || {}
      const isRp = comment_id.toString().indexOf('rp') > -1
      const currThumbsUp = Number(current_member_zan) == 1 ? 0 : 1
      if (isRp) {
        url = 'user/trial/like_report'
        params = {
          report_id: comment_id.replace('rp', ''),
          action: currThumbsUp,
          sku: sku || goods_sn
        }
        method = 'POST'
      } else {
        url = '/product/like_goods_review'
        params = {
          comment_id,
          thumbs_up: currThumbsUp,
          sku: sku || goods_sn
        }
      }
      return await schttp({
        url,
        params,
        useBffApi: true,
        method
      })
    },
    // 批量翻译
    async fetch_batch_translate(
      { state, rootGetters },
      comment_info_data = []
    ) {
      const comment_infos = []
      const { default_lang } = state
      comment_info_data.length &&
        comment_info_data.forEach(i => {
          if (
            !i.reportId &&
            (!i.translateText || i.current_lang !== default_lang)
          ) {
            comment_infos.push({
              comment_id: i.comment_id,
              src_language: i.language_flag
            })
          }
        })
      if (comment_infos.length) {
        const base_info = rootGetters['Adaptor/base_info'] || {}
        const { productRelationID } = base_info
        let data = {}
        const comment_ids = []
        const srcLanguages = []
        comment_infos.slice(0, 20).forEach(item => {
          const { comment_id, src_language } = item || {}
          comment_ids.push(comment_id)
          srcLanguages.push(src_language)
        })
        data = await schttp({
          url: '/product/comment/batch_translate',
          method: 'POST',
          useBffApi: true,
          params: {
            dest_language: default_lang,
            goods_spu: productRelationID,
            comment_ids: comment_ids.join(','),
            src_languages: srcLanguages.join(',')
          }
        })
  
        if (data.code == 0 && data.info?.comment_infos?.length) {
          let translateComments = data.info.comment_infos
  
          let commentInfoDataForAutoTranslate =
            Array.isArray(comment_info_data) &&
            comment_info_data.map(i => {
              const hitTransComment = translateComments.find(
                j => j.comment_id === i.comment_id
              )
              const translateText =
                hitTransComment?.dest_text ||
                (i.current_lang !== default_lang &&
                  default_lang === i.language_flag &&
                  i.content) ||
                ''
              const translateTags = hitTransComment?.dest_content_tag || []
              const translateSelectTags = hitTransComment?.dest_select_tag || []
              let obj = {
                ...i,
                showOrigin:
                  typeof i.showOrigin === undefined
                    ? i.showOrigin
                    : !state.is_auto_translate
              }
              if (obj.translateText === undefined || translateText) {
                obj.translateText = translateText
                obj.translateTags = translateTags
                obj.translateSelectTags = translateSelectTags
                obj.current_lang = default_lang
              }
              return obj
            })
          return commentInfoDataForAutoTranslate
        }
        return comment_info_data
      }
      return comment_info_data
    },
    router_to_reviews_detail(
      { state, rootGetters }, 
      params = {}
    ){
      // mall_code=1&goods_id=29127155&goods_spu=a23120534505&local_site_abt_flag=1&local_site_query_flag=&size=&sku=sa2312053450545363&sort=&store_code=&tag_id=&tag_rule_id=type%3DA&isLowestPriceProductOfBuyBox=1&mainProductSameGroupId=&cat_id=6447&noCache=1
      const base_info = rootGetters['Adaptor/base_info'] || {}
      const skuInfo = rootGetters['Adaptor/skuInfo'] || {}
      const is_retention_product = rootGetters['Adaptor/is_retention_product']
      const main_product_same_group_id = rootGetters['Adaptor/main_product_same_group_id']

      const {
        goods_id,
        goods_sn,
        store_code,
        cat_id,
        goods_url_name,
        productRelationID,
        mall_code,
      } = base_info  || {}
      const paramsObj = {
        mall_code,
        // goods_id,
        goods_spu: productRelationID,
        local_site_query_flag: state.only_local_status ? 1 : '',
        sku: goods_sn,
        store_code,
        // tag_rule_id: this.fetchParams.rule_id,
        isLowestPriceProductOfBuyBox: +is_retention_product,
        mainProductSameGroupId: main_product_same_group_id,
        cat_id,
        sku_code: skuInfo?.sku_code || '',
        ...params
      }

      let str = ''
      Object.keys(paramsObj).forEach(key => {
        if (paramsObj[key] !== undefined) {
          str += `${key}=${paramsObj[key]}&`
        }
      })

      const routerName = getGoodsReviewUrl(goods_url_name, goods_id) 
      window.location.href = `${routerName}?${str.slice(0, -1)}`
    },
  },
}
