import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
export { default, default as BBindGiftCard } from './bind-gift-card.vue'
import type { DS_BindGiftCard } from '../../types'
export type * from '../../types'
export type * from '../lang'

import { getFsDataRunner } from '../../common/dataSource'

export function getBindGiftCardFsDataRunner(sourceParams?: Partial<DS_BindGiftCard.SourceParams>) {
  return getFsDataRunner({ appConfigs: useAppConfigs(), ...sourceParams })
}
