/**
 * 负向信息
 */

const actions = {
  showNegativeInfoDialog({ state }, mallCode) {
    if (mallCode) {
      state.negativeInfo.showNegativeInfoDialog = true
      state.negativeInfo.showNegativeInfoMallCode = mallCode
      state.asyncComponentLoadedStatus.negativeInfoDialogIsLoaded = true
    }
  },
  hideNegativeInfoDialog({ state }) {
    state.negativeInfo.showNegativeInfoDialog = false
  }
}

export default actions
