<script setup>
import { ref, computed, toRef } from 'vue'
import { BMiniCartItem, getConstant } from '@shein-aidc/bs-cart-item-laptop'
import QuickViewPopover from 'public/src/pages/common/quickView/index.js'
import { getImageRatio } from 'public/src/pages/common/utils/index.js'
import { SToast } from '@shein-aidc/sui-toast'
import { batchAddMall } from 'public/src/pages/cart_new/utils/fetcher.js'
import { updateCart } from 'public/src/pages/cart_new/utils/fetcher.js'
import Loading from './Loading.vue'
import { exposeCancelledGoodsPopupReport, clickCancelledGoodsPopupAllReport, clickSelectSomeProductReport, clickCancelledGoodsPopupCloseReport, clickGoodsOneMoreReport, clickAddBagReport, exposeOutOfStockReport } from './utils/analysis.js'
import { MESSAGE_DATE } from '@/public/src/pages/common/biz_helper/timer_format/dictionary.js'
import { timeTransformer } from '@shein/common-function'
import { SMessage  } from '@shein-aidc/sui-message'

// emit
const emit = defineEmits(['update:visible', 'addCartSuccess', 'refreshData', 'addMoreSuccess'])

// props
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  language: {
    type: Object,
    default: () => ({}),
  },
  itemLanguage: {
    type: Object,
    default: () => ({}),
  },
  data: {
    type: Object,
    default: () => ({}),
  },
  isPlatform: {
    type: Boolean,
    default: false,
  },
  orderId: {
    type: String,
    default: '',
  }
})

// data
// 商品行常量配置
const constant = ref(getConstant())
// 商品行配置
const use = computed(() => [
  'GoodsImg',
  'GoodsDiscountFlag',
  'GoodsBelt',
  'GoodsTitle',
  'GoodsSaleAttr',
  'AddMore',
  'GoodsPrice',
  'IncentiveReducePrice',
])
const config = ref({
  // 商品图片
  GoodsImg: {
    props({ data }) {
      return {
        isClick: !data.value.soldOutStatus,
        imgUrl: data.value.goodsImg,
        isShowMask: data.value.soldOutStatus,
      }
    },
    events: {
      onClick({ data }) {
        data.value.isChecked = !data.value.isChecked
        clickSelectSomeProductReport(data.value)
        handleCheckedChange({
          checked: data.value.isChecked,
          label: data.value.id,
        })
      }
    }
  },
  // 折扣角标
  GoodsDiscountFlag: {
    props({ data }) {
      return {
        isShow: !!data.value.discountSubscript?.value,
        isShowMask: data.value.soldOutStatus,
        bgColor: data.value?.discountSubscript?.backgroundColor,
        fontColor: data.value?.discountSubscript?.textColor,
        unitDiscount: data.value?.discountSubscript?.value,
        saleDiscount: data.value.additionalValue,
        isShowSaleDiscount: data.value.additionalValue,
      }
    },
  },
  // 商品腰带
  GoodsBelt: {
    props({ data }) {
      const hexToRGBA = (hex, alpha) => {
        let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
        hex = hex?.replace(shorthandRegex, (m, r, g, b) => {
          return r + r + g + g + b + b
        })
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
        const r = parseInt(result[1], 16)
        const g = parseInt(result[2], 16)
        const b = parseInt(result[3], 16)
        const a = alpha || 1
        return `rgba(${r}, ${g}, ${b}, ${a})`
      }
      return {
        isShow: !!data.value?.productImgBelt?.text,
        isShowMask: false,
        bgColor: data.value?.productImgBelt?.bgColor ? hexToRGBA(data.value?.productImgBelt?.bgColor, data.value?.productImgBelt?.bgColorAlpha) : '',
        fontColor: data.value?.productImgBelt?.textColor,
        beltText: data.value?.productImgBelt?.text,
      }
    }
  },
  // 商品标题
  GoodsTitle: {
    props({ data }) {
      return {
        isClick: false,
        fontSize: '14px',
        lineHeight: '18px',
        title: data.value.goodsName,
        isShowMask: data.value.soldOutStatus,
      }
    }
  },
  // 商品属性
  GoodsSaleAttr: {
    props({ data }) {
      return {
        type: 'text',
        height: '15px',
        isShow: !!data.value?.skuAttrInfo?.goodsAttr,
        mode: 'attr',
        isShowMask: data.value.soldOutStatus,
        colorImage: '',
        goodsAttr: data.value?.skuAttrInfo?.goodsAttr,
        color: '#767676',
        fontWeight: 'normal',
        isClick: false,
        hideArrow: true,
      }
    }
  },
  // 快加车
  AddMore: {
    props({ data }) {
      return {
        isShow: true,
        isLoading: data.value.isOpenQuickView,
      }
    },
    events: {
      async onClick({ data, event }) {
        event.stopPropagation()
        clickGoodsOneMoreReport()
        const toggleLoading = () => {
          data.value.isOpenQuickView = !data.value.isOpenQuickView
        }
        toggleLoading()
        const getSelectedAttrIndex = () => {
          const selectedAttrIndex = []
          const skuSaleAttr = data.value.skuSaleAttr || []
          skuSaleAttr && skuSaleAttr.forEach(item => {
            selectedAttrIndex[item.attr_id] = { attr_value_id: item.attr_value_id }
          })
          return selectedAttrIndex
        }
        const handleUpdateOrDone = async (res) => {
          if (res.code == 0) {
            SMessage({
              offset: 136,
              type: 'success',
              message: props.language.SHEIN_KEY_PC_17621,
            })
            handleAddMoreSuccess(res)
          }
        }
        QuickViewPopover.show({
          goodsId: data.value.goodsId,
          imgRatio: await getImageRatio(data.value.goodsImg),
          mallCode: data.value.mall_code,
          showPromotionTagInfo: false,
          extendForm: {
            quantity: 1,
            selectedAttrIndex: getSelectedAttrIndex(),
          },
          config: {
            isUpdateButton: false,
            hideViewFullDetail: true,
            hideWishlistBtn: true,
            showOneClickPay: false,
            showPromotionTagInfo: false,
          },
          callback: {
            handleOpenQuickViewDone() {
              toggleLoading()
            },
            handleAddToCartDone: handleUpdateOrDone,
          },
          analysis: {
            pageType: 'cartPage',
            index: data.value.index,
            sa: {
              activity_from: 'cancelled_goods_multiple'
            },
          },
        })
      }
    }
  },
  // 商品价格
  GoodsPrice: {
    props({ data }) {
      return {
        salePrice: data.value.priceData?.unitPrice?.price?.amountWithSymbol,
        salePriceFontSize: '14px',
        salePriceColor: data.value?.priceData?.unitPrice?.color,
        isShowMask: data.value.soldOutStatus,
        isShowOriginalPrice: !!data.value?.priceData?.originalPrice,
        isShowOriginalPriceText: false,
        isShowOriginalPricePopover: false,
        originalPrice: data.value?.priceData?.originalPrice?.price?.amountWithSymbol,
        originPriceText: '',
        originPricePopoverText: '',
        isShowSuggestPrice: false,
        suggestPrice: '',
        suggestPriceText: '',
        isShowSheinVipIcon: false,
        isShowRomweVipIcon: false,
        isShowPromotionIcon: false,
      }
    }
  },
  // 降价利诱点
  IncentiveReducePrice: {
    props({ data }) {
      return {
        isShow: !!data.value?.productTags?.find(item => item.type === 'priceReduction') && !data.value.soldOutStatus
      }
    }
  },
})
// 商品行数据
const data = toRef(props, 'data')
const isLoading = ref(false)
const checkedIds = ref([])

// computed
const isOpen = computed({
  get() {
    return props.visible
  },
  set(val) {
    emit('update:visible', val)
  }
})
const goods = computed(() =>  {
  const products = data.value.canceledProductList ?? []
  products.forEach(item => {
    item.isChecked = !item.soldOutStatus ? item.isChecked ?? checkedIds.value.includes(item.id) : false
  })
  return products
})
const checkedGoods = computed(() => goods.value.filter(item => item.isChecked))
const disabledAll = computed(() => goods.value.every(item => item.soldOutStatus))
const bottomTip = computed(() => {
  const date = timeTransformer({ time: data.value.timestamp * 1000, sDateMap: MESSAGE_DATE })
  return `${data.value.bottomTip} ${date}`
})
const buttonTip = computed(() => checkedGoods.value.length ? props.language.SHEIN_KEY_PC_31417.replace('{0}', checkedGoods.value.length) : props.language.SHEIN_KEY_PC_31417.replace('({0})', ''))
const checkedAll = computed({
  get() {
    return goods.value.filter(item => !item.soldOutStatus).every(item => item.isChecked) 
  },
  set(checked) {
    data.value.canceledProductList.forEach(item => {
      if (!item.soldOutStatus) {
        item.isChecked = checked
      }
    })
  }
})

// methods
const showLoading = () => {
  isLoading.value = true
}
const hideLoading = () => {
  isLoading.value = false
}
const handleOpen = () => {
  exposeCancelledGoodsPopupReport({ orderId: props.orderId })
}
const handleClose = () => {
  clickCancelledGoodsPopupCloseReport()
}
const handleClickChecked = (item) => {
  clickSelectSomeProductReport(item)
}
const handleCheckedChange = ({ checked, label }) => {
  if (checked) {
    checkedIds.value.push(label)
  } else {
    checkedIds.value = checkedIds.value.filter(id => id !== label)
  }
}
const handleExposeItem = (item) => {
  if (item.soldOutStatus) {
    exposeOutOfStockReport(item)
  }
}
const handleAddCartSuccess = (res) => {
  emit('addCartSuccess', res)
}
const handleAddMoreSuccess = (res) => {
  emit('addMoreSuccess', res)
}
const handleEventRefresh = () => {
  emit('refreshData')
}
const addStrategy = {
  0: ({ cartInfo, updateGoods }) => {
    const count = updateGoods.length
    SToast({ content: props.language.SHEIN_KEY_PC_31418.replace('{0}', count), duration: 3000 })
    handleAddCartSuccess(cartInfo)
  },
  300402: () => {
    handleEventRefresh()
    SToast({ content: props.language.SHEIN_KEY_PC_31732, duration: 3000 })
  },
  300407: () => {
    SToast({ content: props.language.SHEIN_KEY_PC_31419, duration: 3000 })
  },
  default: ({ res }) => {
    res.msg && SToast({ content: res.msg, duration: 3000 })
  }
}

const handleAddStrategy = (code, data) => {
  const handler = addStrategy[code] || addStrategy.default
  if (handler) {
    handler(data)
  }
}

const handleAdd = async () => {
  if (checkedGoods.value.length === 0) {
    clickAddBagReport(checkedGoods.value, { code: -1, msg: props.language.SHEIN_KEY_PC_31420 })
    return SToast({ content: props.language.SHEIN_KEY_PC_31420, duration: 3000 })
  }
  try {
    showLoading()
    const res = await batchAddMall(checkedGoods.value.map(item => ({
      mall_code: item.mallCode,
      sku_code: item.skuCode,
      quantity: 1,
    })))
    const cartInfo = await updateCart()
    handleAddStrategy(res.code, {
      cartInfo,
      res,
      updateGoods: checkedGoods.value,
    })
    clickAddBagReport(checkedGoods.value, res)
  } finally {
    hideLoading()
  }
}

defineExpose({
  showLoading,
  hideLoading,
})
</script>

<template>
  <SDrawer
    v-model:visible="isOpen"
    :size="'400px'"
    :title="data.topTip"
    class="cancel-order-drawer"
    append-to-body
    direction="rtl"
    @open="handleOpen"
    @close="handleClose"
  >
    <div class="cancel-order-drawer__wrapper">
      <div class="cancel-order-drawer__scroll">
        <div class="cancel-order-drawer__goods">
          <div 
            v-for="item in goods"
            :key="item.goodsId"
            class="cancel-order-drawer__item"
          >
            <div class="cancel-order-drawer__checked">
              <SCheckbox
                v-model="item.isChecked"
                :label="item.id"
                :disabled="item.soldOutStatus"
                :gap="'0px'"
                @click="handleClickChecked(item)"
                @change="handleCheckedChange"
              />
            </div>
            <div class="cancel-order-drawer__content">
              <BMiniCartItem
                :key="item.id"
                :data="item"
                :use="use"
                :config="config"
                :constant="constant"
                :language="itemLanguage"
                @expose="handleExposeItem(item)"
              />
            </div>
          </div>
        </div>
        <p class="cancel-order-drawer__tip">
          <span></span>
          <span>{{ bottomTip }}</span>
          <span></span>
        </p>
      </div>
      <div class="cancel-order-drawer__footer">
        <div class="cancel-order-drawer__checkedAll">
          <SCheckbox
            v-model="checkedAll"
            :gap="'0px'"
            :disabled="disabledAll"
            @change="clickCancelledGoodsPopupAllReport"
          >
            {{ language.SHEIN_KEY_PC_14484 }}
          </SCheckbox>
        </div>
        <SButton
          class="cancel-order-drawer__add"
          :type="['H44PX', 'primary']"
          role="button"
          @click="handleAdd"
        >
          {{ buttonTip }}
        </SButton>
      </div>
    </div>
    <Loading v-show="isLoading" />
  </SDrawer>
</template>

<style lang="less" scoped>
.cancel-order-drawer {
  /deep/ .sui-drawer__container {
    background-color: #F6F6F6;
  }
  /deep/ .sui-drawer__header {
    display: flex;
    align-items: center;
    height: 47px;
    padding: 12px 20px;
    border-bottom: 1px solid #F6F6F6;
    background-color: #FFF;
  }
  /deep/ .sui-drawer__title {
    padding: 0;
    margin-right: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex: 1;
    font-size: 16px;
    font-weight: 900;
    text-align: left;
  }
  /deep/ .sui-drawer__body {
    padding: 0;
    height: 100%;
  }
  /deep/ .sui-drawer__close-btn {
    flex-shrink: 0;
    position: unset;
    cursor: pointer;
    font-size: 0;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
  }
  &__scroll {
    flex: 1;
    overflow-y: auto;
    background: #F6F6F6;
    &::-webkit-scrollbar { display: none; }
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  &__goods {
    background: #FFF;
    padding: 16px 20px;
  }
  &__tip {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 10px;
    color: #B9B9B9;
    padding: 12px 20px;
    :nth-child(odd) {
      height: 1px;
      flex: 1;
      background: #D9D9D9;
    }
    :nth-child(2) {
      padding: 0 8px;
      max-width: 253px;
      text-align: center;
    }
  }
  &__checked {
    margin-top: 35px;
  }
  &__item {
    display: flex;
    padding: 10px 0;

    + .cancel-order-drawer__item {
      margin-top: 16px;
    }
  }
  &_checked {
    flex-shrink: 0;
  }
  &__content {
    flex: 1;
  }
  &__footer {
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 73px;
    background-color: #FFF;
    border-top: 1px solid #E5E5E5
  }
  &__checkedAll {
    min-width: 91px;
    text-align: left;
  }
  &__add {
    margin-left: 10px;
    flex: 1;
  }
}
</style>
