import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

const appConfigs = useAppConfigs()

export const getEntranceGuide = async (data) => {
   const res: any = await appConfigs.$schttp({
    method: 'POST',
    url: '/order/cart/get_entrance_guide',
    data,
    headers: {
      AppCurrency: appConfigs.$envs.currency,
      AppLanguage: appConfigs.$envs.appLanguage,
      'frontend-scene': 'page_web_common',
    },
  })
  return res.data
}
