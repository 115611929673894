/**
 * @description: 控制布局相关的计算，需要上升到这里, 在入口处统一控制
 */

export default {
  namespaced: true,
  getters: {
    show_ccc_x_banner(state, getters, rootState, rootGetters) {
      const screen_abt_config = rootGetters['Adaptor/screen_abt_config']
      const is_third_party_store = rootGetters['Adaptor/is_third_party_store']
      const has_src = rootGetters['BTF/GoodsDetailCccXBanner/has_src']
      const is_third_party_merchant_banner =
        rootGetters['BTF/GoodsDetailCccXBanner/is_third_party_merchant_banner']
      const { detailbanner } = screen_abt_config
      const IS_HIT_STATUS = detailbanner?.p?.detailbanner !== 'notshow'
      if (!IS_HIT_STATUS) return false
      if (!has_src) return false
      if (is_third_party_store && !is_third_party_merchant_banner) return false
      return true
    },
    show_details_pictures(state, getters, rootState, rootGetters) {
      return !rootGetters['Adaptor/detail_imgs_not_show'] && rootState.BTF?.GoodsDetailPictures?.detail_pictures_info?.length
    },
    show_style_gallery(state, getters, rootState, rootGetters) {
      return rootGetters['BTF/GoodsDetailStyleGallery/style_gallery_image_list']?.length
    },
    show_combo_buy(state, getters, rootState, rootGetters) {
      const { isShowComboBuy = false } = rootGetters['BTF/ComboBuy/combo_buy_abt']
      return isShowComboBuy
    },
    show_new_outfit_module(state, getters, rootState, rootGetters) {
      const screen_abt_tsp_config = rootGetters['Adaptor/screen_abt_tsp_config']
      const newoutfit = screen_abt_tsp_config?.newoutfit?.param || {}
      const showNewOutfit = newoutfit.newoutfit === 'new' // 展示新搭配模块
      return showNewOutfit && ['onlymodule', 'both'].includes(newoutfit.pctype) // 展示独立模块
    },
  },
}
