const getPrefix = () => {
  if (typeof window === 'undefined') return
  const WEB_CLIENT = window?.gbCommonInfo?.WEB_CLIENT || ''
  const webBrand = WEB_CLIENT === 'romwe' ? 'R' : 'S'
  const terminal = 'Pc'
  const prefix = `${webBrand}${terminal}`
  return prefix
}

export function addPrefixPoskey (poskeys = []) {
  return Object.keys(getPrefixPoskeyObj(poskeys)).join(',')
}

export function getPrefixPoskeyObj(poskeys) {
  if (!Array.isArray(poskeys)) {
    poskeys = poskeys.split(',')
  }
  
  function _getPosKey(posKey) {
    if (!posKey) return
    const prefix = getPrefix()
    if (posKey.indexOf(prefix) === 0) return posKey
    return `${prefix}${posKey}`
  }
  
  return poskeys.reduce((prev, curr) => {
    const addKey = _getPosKey(curr)
    if (addKey) {
      prev[addKey] = curr
    }
    return prev
  }, {})
}


export const romwePoskey = (key) => {
  const prefix = getPrefix()
  return key.indexOf(prefix) === 0 ? key.replace(prefix, '') : key
}
