/**
 * @description 商详所有接口路由文件
 */

const DETAIL_API_LOCATION = {
  // ================== 以下为商品详情主接口 ==================
  PRODUCT_STATIC_INFO: '/product/get_goods_detail_static_data', // 商品详情静态信息

  // ================== 以下为基础数据接口 ==================
  MULTI_LANGUAGE: '/system/configs/page_multi_language_mapping', // 多语言接口
  PRODUCT_DETAIL_DETAIL_IMAGE: '/product/get_goods_detail_image', // 细节图
  BATCH_CHECK_GOODS_STOCK: '/product/batch_check_goods_stock',    // 批量校验商品库存数据
  GOODS_ADD_TO_CART_BATCH: '/order/cart/batch_add', // 批量加车接口
  BATCH_BIND_COUPON: '/product/coupon/batch_bind_coupon', // 批量绑劵
  BUYER_SHOW_FLOOR: '/product/comment/get_buyer_show_floor', //买家秀接口

  // ================== 店铺接口 ==================
  STORE_UPDATE_FOLLOWING: '/product/store/update_following', // 店铺关注


  // ================== 以下为推荐接口 ==================
  GET_DETAIL_RECOMMEND_PRODUCT: '/product/get_detail_recommend_product', // 底部推荐位接口
  PRODUCT_DETAIL_RECOMMEND_INFO: '/product/detail/recommend/info', // 商详推荐信息
  RECOMMEND_COLLECT_SIMILAR: '/product/detail/recommend/collect_similar', // 商详收藏后弹出similar推荐
  RECOMMEND_GET_TOGETHER_BETTER: '/product/get_together_better_deals_price', // 组合购 / gift 价格
  RECOMMEND_SIMILAR_LIST: '/product/recommend/get_similar_list', // 找相似接口
  BUYBOX_RECOMMEND: '/detail/recommend/multi_store_products', // buybox推荐
  PRODUCT_GET_NEW_COMPANION: '/product/get_new_companion_module', // 新搭配数据

  // ================== 以下CCC相关接口 ==================
  CCC_FEED_BACK: '/ccc/communal/switch', // ccc负反馈配置
  CCCX_AD_BANNER: '/ccc/common_component', // cccx广告banner
  PRODUCT_RANKING_INFO: '/ccc/rec/goods', // 榜单信息
}


export default DETAIL_API_LOCATION
