const action = {
  handlePromotionInfo({ commit, state, dispatch }, integrityPromotionInfo) {
    let all_promotion_info = integrityPromotionInfo || {}
    let fullPromotionList = []
    let promotionList = [].concat(state.promotionState.bottomPromotionData || [])
    state.mallCartList?.forEach(mall => {
      promotionList = promotionList.concat(mall?.promotionData || [])
    })
    promotionList.forEach(promotion => {
      promotion.contentData?.forEach(content => {
        fullPromotionList.push(content.groupHeadInfo)
        if(content.groupHeadInfo?.data?.promotion_id) {
          if(!(all_promotion_info[content.groupHeadInfo.data.promotion_id])){
            all_promotion_info[content.groupHeadInfo.data.promotion_id] = { data: content.groupHeadInfo.data }
          } else {
            all_promotion_info[content.groupHeadInfo.data.promotion_id].data = content.groupHeadInfo.data
          }
        }
      })
    })
    commit('updatePromotionState', { integrityPromotionInfo: all_promotion_info, fullPromotionList: fullPromotionList })
    if (typeof window !== 'undefined') { //不依赖ssr的逻辑才放在这里
      dispatch('handleCartPromotionAnalysis')
    }
  }
}

export default action
