import GoodsDetailCccXBanner from './innerComponents/GoodsDetailCccXBanner.js'
import GoodsDetailNewOutfit from './innerComponents/GoodsDetailNewOutfit.js'
import GoodsDetailPictures from './innerComponents/GoodsDetailPictures.js'
import GoodsDetailStyleGallery from './innerComponents/GoodsDetailStyleGallery.js'
import ComboBuy from './innerComponents/ComboBuy.js'
import Layout from './Layout.js'
import Reviews from './innerComponents/Reviews/Reviews.js'

export default {
  GoodsDetailCccXBanner,
  GoodsDetailNewOutfit,
  GoodsDetailPictures,
  GoodsDetailStyleGallery,
  ComboBuy,
  Layout,
  Reviews,
}
