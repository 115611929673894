<!-- 一排一，只有付费会员卡，无超省卡 -->
<template>
  <div
    v-if="!isShowSaver"
    class="prime-pannel_wrapper fsp-element"
    @click.stop="jumpTo"
  >
    <div
      class="prime-pannel"
      :style="{
        backgroundImage: `url(${
          IMG_LINK[GB_cssRight ? 'pannel-bg-no_prime-ar' : 'pannel-bg']
        })`
      }"
    >
      <div class="pannel-main">
        <div class="prime-hd">
          <div class="prime-hd__left">
            <img
              class="prime-hd_img"
              :src="primeLogo"
            />
            <div
              v-if="isExpireStatus"
              class="prime-hd__expire"
            >
              {{ statusTip }}
            </div>
          </div>
          <div
            class="prime-hd_right"
            @click.stop="jumpTo"
          >
            <div class="prime-pannel2-btn">
              <div
                class="prime-pannel2__footer__btn prime-pannel2__footer__btn_line"
              >
                <div
                  v-if="bobbleTip"
                  class="prime-pannel2__footer__btn_limited"
                  v-html="bobbleTip"
                ></div>
                {{ buttonTip }}
                <sui_icon_more_right_14px
                  :is-rotate="GB_cssRight"
                  size="14px"
                  is-responsive-name
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="prime-pannel2__content"
          :class="{ 'single-content': false }"
        >
          <div
            class="prime-pannel2__text"
            v-html="richTextContent"
          ></div>
        </div>

        <!-- 权益列表 -->
        <div class="prime-content right-config-list">
          <template v-for="(item, index) in currentPrimeRightConfig">
            <div
              v-if="index < 3"
              :key="index"
              class="equity-item"
              :class="{ 'more-item': currentPrimeRightConfig?.length >= 3 }"
            >
              <img :src="item?.right_type_icon" />
              <span v-if="currentPrimeRightConfig?.length == 1">
                {{ language.SHEIN_KEY_PC_22939 }}
              </span>
              <span
                v-if="currentPrimeRightConfig?.length > 1"
                v-html="item?.right_type_desc || ''"
              ></span>
            </div>
            <div
              v-if="currentPrimeRightConfig?.length < 3"
              :key="index"
              class="equity-item_line"
            ></div>
          </template>
          <template v-if="currentPrimeRightConfig.length > 3">
            <img :src="IMG_LINK['fengge']" />
            <div class="more-equity">
              {{ language.SHEIN_KEY_PC_22940 }}
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>

  <!-- 一排二 -->
  <div
    v-if="isShowSaver"
    class="prime-pannel2 fsp-element"
    :class="{ 'prime-pannel2__ar': GB_cssRight }"
    @click.stop="jumpTo"
  >
    <div class="prime-pannel2__header">
      <div class="prime-pannel2__logo-wrap">
        <img
          class="logo"
          :src="primeLogo"
        />
        <div
          v-if="isExpireStatus"
          class="status"
        >
          {{ statusTip }}
        </div>
      </div>
      <div class="prime-pannel2__icons">
        <div
          v-for="(item, index) in currentPrimeRightConfig"
          :key="index"
          class="prime-pannel2__icon-wrap"
        >
          <img :src="item.right_type_icon" />
        </div>
      </div>
    </div>

    <div
      class="prime-pannel2__content"
      :class="{ 'single-content': false }"
    >
      <div
        class="prime-pannel2__text"
        v-html="richTextContent"
      ></div>
    </div>

    <div class="prime-pannel2__footer">
      <div class="prime-pannel2-btn">
        <div class="prime-pannel2__footer__btn prime-pannel2__footer__btn_line">
          <div
            v-if="bobbleTip"
            class="prime-pannel2__footer__btn_limited"
            v-html="bobbleTip"
          ></div>
          {{ buttonTip }}
          <sui_icon_more_right_14px
            :is-rotate="GB_cssRight"
            size="14px"
            is-responsive-name
          />
        </div>
      </div>
    </div>
  </div>

  <ClientOnly>
    <!-- 回本动效弹窗 -->
    <div @click.stop="">
      <pay-back-dialog
        v-if="!isShowRenewPopUpDialogV2 && !isShowRenewPopUpDialog && !isShowFreeGiftDialog && isShowPayBackDialog"
        :is-show="isShowPayBackDialog"
        :payback-info="paybackInfo"
        @dialog-opened="openedCallback"
      />
    </div>

    <!-- 续费弹窗 -->
    <div @click.stop="">
      <renew-dialog
        v-if="!isShowRenewPopUpDialogV2 && isShowRenewPopUpDialog"
        :is-show="isShowRenewPopUpDialog && !isShowRenewPopUpDialogV2"
        :config-data="formatRenewPopUpInfo"
        @dialog-opened="openedCallback"
      />
      <renew-dialog-v2
        v-if="isShowRenewPopUpDialogV2"
        :is-show="isShowRenewPopUpDialogV2"
        :config-data="formatRenewPopUpInfo"
        @dialog-opened="openedCallback"
      />
    </div>

    <!-- 免费礼弹窗 -->
    <div @click.stop="">
      <free-gift-dialog
        v-if="!isShowRenewPopUpDialogV2 && !isShowRenewPopUpDialog && isShowFreeGiftDialog"
        :is-show="isShowFreeGiftDialog"
        :free-gift-popup-info="freeGiftPopupInfo"
        @dialog-opened="openedCallback"
      />
    </div>
  </ClientOnly>
</template>
<script>
import { getLocalStorage, setLocalStorage, removeLocalStorage, windowCacheDeleteCb } from '@shein/common-function'
import { sui_icon_more_right_14px } from '@shein-aidc/icon-vue3'
import { ClientOnly } from '@sheinfe/vue-client-only'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { htmlDecodeUtil } from '../../utils/index'
import PayBackDialog from './PayBackDialog.vue'
import RenewDialog from './RenewDialog.vue'
import RenewDialogV2 from './RenewDialogNew.vue'
import FreeGiftDialog from './FreeGiftDialog.vue'

daEventCenter.addSubscriber({ modulecode: '1-22-8' })

export default {
  name: 'PrimePannel',
  components: {
    sui_icon_more_right_14px,
    ClientOnly,
    PayBackDialog,
    RenewDialog,
    RenewDialogV2,
    FreeGiftDialog
  },
  emits: ['dialog-opened'],
  inject: ['commonState'],
  props: {
    apolloConfig: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    primeInfo: {
      type: Object,
      default: () => ({})
    },
    isShowSaver: {
      type: Boolean,
      default: false
    },
    paybackInfo: {
      type: Object,
      default: () => ({})
    },
    renewPopUpInfo: {
      type: Object,
      default: () => ({})
    },
    renewPopUpInfoV2: {
      type: Object,
      default: () => ({})
    },
    freeGiftPopupInfo: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    const { RENEW_DIALOG_DELAY = {} } = this.commonState.apolloConfig
    const {
      PUBLIC_CDN = '',
      GB_cssRight = false,
      IMG_LINK = {},
      langPath,
    } = this.commonState.constantData || {}

    return {
      RENEW_DIALOG_DELAY,
      PUBLIC_CDN,
      GB_cssRight,
      IMG_LINK,
      langPath,
      htmlDecodeUtil,
      primeLogo:
        '//img.ltwebstatic.com/images3_ccc/2024/05/30/c1/1717050669a2edef94954af1bd6e16e2f914672796.webp',
    }
  },
  computed: {
    isExpireStatus() {
      return this.primeInfo?.status == 2 //会员状态:1-临期，2-过期
    },
    statusTip() {
      return this.primeInfo?.statusTip || ''
    },
    currentPrimeRightConfig() {
      // 权益列表：包邮、折扣、积分等
      return this.primeInfo?.rightInfo || []
    },
    richTextContent() {
      // 中间具体权益文案
      return this.primeInfo?.richText || ''
    },
    buttonTip() {
      // 底部按钮文案
      return this.primeInfo?.buttonTip || ''
    },
    bobbleTip() {
      return this.primeInfo?.bobbleTip || ''
    },
    // 有数据就展示v2, 没有就展示老弹窗
    isShowRenewPopUpDialogV2() {
      if(!this.renewPopUpInfoV2 || !Object.keys(this.renewPopUpInfoV2)?.length)  return false
      // 2. 判断是临期还是逾期，
      // 若差值≥0，则为临期状态，将差值换算为临期天数
      // 若临期天数为0~3天，用户每天首次访问个人中心，均弹出1次
      // 若临期天数为4~30天，用户每间隔5天 首次访问个人中心，弹出1次
      // 若差值<0，则为逾期状态，将差值换算为逾期天数
      // 若逾期天数为0~7天，用户每间隔2天 首次访问个人中心，弹出1次
      // 若逾期天数为8~21天，用户每间隔5天 首次访问个人中心，弹出1次
      // 若逾期天数为22~60天，用户每间隔10天 首次访问个人中心，弹出1次
      const currentTime = new Date().getTime()
      const time = this.renewPopUpInfoV2?.totalEndTime
      const intervalTime = this.renewPopUpInfoV2?.intervalTime
      // let days = time / 1000 / 60 / 60 / 24
      let flag
      
      flag = this.timeCheck(
        intervalTime * 1000,
        currentTime,
        time >= 0 ? 'nearExpired' : 'expired'
      )
      return flag && this.renewPopUpInfoV2

      // 证明有续费弹窗
    },
    isShowRenewPopUpDialog() {
      if(!this.renewPopUpInfo || !Object.keys(this.renewPopUpInfo)?.length)  return false
      // 插入场景
      // 当以下条件同时满足时，不弹付费会员续费引导弹窗：
      // ① 无省额
      // /v2/User/prime/paidDetail接口的req_currency_price字段 < 0
      // ② 无会费价&会费券
      // /v2/User/prime/queryMemberBuyProduct接口的total_discount_price < 0
      // ③ 无买赠活动
      // /v2/User/prime/queryMemberBuyProduct接口的gift_type≠1
      const req_currency_price = this.renewPopUpInfo?.req_currency_price || 0
      const total_discount_price =
        this.renewPopUpInfo?.total_discount_price || 0
      const flag1 = req_currency_price <= 0
      const flag2 = total_discount_price <= 0
      let flag3 = this.renewPopUpInfo?.have_gift_flag

      if (flag1 && flag2 && flag3) {
        return false
      }

      // 2. 判断是临期还是逾期，
      // 若差值≥0，则为临期状态，将差值换算为临期天数
      // 若临期天数为0~3天，用户每天首次访问个人中心，均弹出1次
      // 若临期天数为4~30天，用户每间隔5天 首次访问个人中心，弹出1次
      // 若差值<0，则为逾期状态，将差值换算为逾期天数
      // 若逾期天数为0~7天，用户每间隔2天 首次访问个人中心，弹出1次
      // 若逾期天数为8~21天，用户每间隔5天 首次访问个人中心，弹出1次
      // 若逾期天数为22~60天，用户每间隔10天 首次访问个人中心，弹出1次
      // const { overdue_rules = [], near_expired_rules = [] } =
      //   this.RENEW_DIALOG_DELAY
      const currentTime = new Date().getTime()
      const time = this.renewPopUpInfo?.total_end_time
      let days = time / 1000 / 60 / 60 / 24
      let flag 
      if (days >= 0) {
        // currentRule = near_expired_rules.find(
        //   rule => days >= rule.left_bound && days < rule.right_bound
        // )
      } else {
        days = -days
        // currentRule = overdue_rules.find(
        //   rule => days >= rule.left_bound && days < rule.right_bound
        // )
      }
      // if (currentRule) {
      flag = this.timeCheck(
        this.renewPopUpInfo?.interval_time * 1000,
        currentTime,
        time >= 0 ? 'nearExpired' : 'expired'
      )
      // } else {
      //   flag = false
      // }
      return !!flag

      // 证明有续费弹窗
    },
    isShowPayBackDialog() {
      if(typeof window === 'undefined' || (this.isShowRenewPopUpDialog || this.isShowRenewPopUpDialogV2) || this.isShowFreeGiftDialog) return false

      const { SiteUID } = gbRawData || {}
      const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'userCenter/PrimePannel' })
      const cacheKey = `pay_back_cache_${memberId || ''}_${SiteUID}`

      // payback_info为null 清除当前(站点+id)缓存
      if (!this.paybackInfo) {
        removeLocalStorage(cacheKey)
        return false
      }
      const cacheRoi = getLocalStorage(cacheKey)
      const newRoi = Number(this.paybackInfo.popups_info?.roi)
      if (cacheRoi) {
        if (Number(cacheRoi) !== newRoi) {
          setLocalStorage({ key: cacheKey, value: newRoi })
          return true
        }
        return false
      }
      setLocalStorage({ key: cacheKey, value: newRoi })
      return true
    },
    isShowFreeGiftDialog() {
      if(typeof window === 'undefined' || (this.isShowRenewPopUpDialog || this.isShowRenewPopUpDialogV2)) return false
      if(!this.freeGiftPopupInfo || !this.freeGiftPopupInfo.giftInfoList || !this.freeGiftPopupInfo?.giftInfoList?.length) {
        return false
      }
      // 清除sw缓存
      windowCacheDeleteCb({ keys: ['pcMeHtml'] })
      return true
    },
    formatRenewPopUpInfo() {
      if(this.renewPopUpInfoV2 && Object.keys(this.renewPopUpInfoV2)?.length) {
        return this.renewPopUpInfoV2
      }
      // 是否过期
      const isExpired = this.renewPopUpInfo.total_end_time < 0
      return {
        isExpired,
        isCoupons: this.renewPopUpInfo?.total_discount_price > 0,
        seconds: this.renewPopUpInfo.total_end_time,
        ...this.renewPopUpInfo
      }
    }
  },
  methods: {
    // 跳转到付费会员落地页
    jumpTo() {
      daEventCenter.triggerNotice({
        daId: '1-22-8-2',
        extraData: this.getExposeParams()
      })
      // let query = 'productsource=me'
      // location.href = `${this.langPath}/user/prime?${query}`
      if (this.primeInfo?.appRoute) {
        location.href = this.langPath + this.primeInfo.appRoute
      } else {
        location.href = this.langPath + this.primeInfo.url
      }
      return
    },
    getExposeParams() {
      return this.primeInfo?.buriedObject[0]?.defaultParams || {}
    },
    // 校验缓存时间是否失效
    timeCheck(time, currentTime, type) {
      const SiteUID = this.commonState?.constantData?.SiteUID || ''
      const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'userCenter/PrimePannel' })
      const cacheKey =
        SiteUID + '-' + (memberId || '') + '-'
      // 临期key
      const nearExpiredKey = cacheKey + 'primeV2RenewDialog'
      // 逾期key
      const ExpiredKey = cacheKey + 'primeV2ExpiredRenewDialog'

      let cacheTimeKey
      if (type == 'nearExpired') {
        cacheTimeKey = nearExpiredKey
      } else {
        cacheTimeKey = ExpiredKey
      }
      const cacheTime = +getLocalStorage(cacheTimeKey)

      if (!cacheTime) {
        // 不存在缓存，触发弹窗
        return true
      } else {
        // 先判断缓存是否失效，如果未失效，不触发弹窗,返回false
        // 如果缓存失效,触发弹窗，存储当前时间，返回true

        // 当前时间 2024年10月12日 10：20:30
        // 0天失效，2024年10月13日 10:20:30
        // 24 小时/天 × 60 分钟/小时 × 60 秒/分钟 × 1000 毫秒/秒
        // 缓存的开始时间
        const cacheDayStartTime = new Date(cacheTime).getTime()
        // 当天的开始时间
        const currentDayStartTime = new Date(currentTime).getTime()
        // 命中缓存
        if (currentDayStartTime - cacheDayStartTime < time) {
          return false
        } else {
          return true
        }
      }
    },
    openedCallback(dialogType) {
      this.$emit('dialog-opened', dialogType)
    }
  }
}
</script>
<style lang="less">
.prime-pannel_wrapper {
  margin-top: 20px;
  border-radius: 3px;
  flex-grow: 1;
}
.prime-pannel {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 3px;
  // overflow: hidden;
  cursor: pointer;
  .pannel-main {
    border-radius: 3px;
    background: linear-gradient(
      272.5deg,
      rgba(255, 186, 151, 0.4) 4.35%,
      rgba(255, 186, 151, 0.192) 100%
    );
  }
  .prime-hd {
    width: 100%;
    height: 44px;
    border-radius: 3px 3px 0 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .prime-hd_img {
      height: 20px;
      // .margin-l(14px);
    }
    &__left {
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(
        180deg,
        rgba(255, 204, 178, 0.25) 0%,
        rgba(255, 175, 134, 0.25) 100%
      );
      // background: linear-gradient(180deg, rgba(255, 204, 178, 0.25) 0%, rgba(255, 175, 134, 0.25) 100%);
      height: 100%;
      border-radius: 0px 0px 12px 0px;
      padding: 0 8px;
    }
    &__expire {
      background: #e5e5e5;
      border: 1px solid rgba(187, 187, 187, 0.5);
      border-radius: 21px;
      padding: 3px 6px;
      color: #767676;
      font-size: 12px;
      .margin-l(4px);
      transform: scale(0.8333);
    }
    &__trial {
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      padding: 2px 6px;
      background: rgba(255, 186, 151, 0.3);
      border: 1px solid rgba(135, 60, 0, 0.2);
      border-radius: 21px;
      color: rgba(135, 60, 0, 0.8);
      transform: scale(0.8333);
    }
  }
  .prime-hd_right {
    max-width: 60%;
    text-align: right;
    cursor: pointer;
    color: rgba(159, 79, 45, 1);
    font-size: 13px;
    line-height: 13px;
    display: flex;
    align-items: center;
    .padding-r(12px);
    .join-btn {
      position: relative;
      padding: 6px 12px;
      background: linear-gradient(270deg, #ffd3bc 0%, #ffe2d3 100%);
      border: 1px solid rgba(196, 74, 1, 0.5);
      border-radius: 81px;
      color: #c44a01;
      font-weight: 700;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
    .trial-price {
      color: #da7e50;
      font-size: 12px;
      font-weight: 400;
      text-decoration: line-through;
    }
  }
  .prime-content {
    width: 100%;
    padding: 16px 8px;
    font-size: 14px;
    color: rgba(159, 79, 45, 0.8);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border-radius: 0 0 3px 3px;
  }
  .right-config-list {
    background: linear-gradient(
      180deg,
      rgba(255, 186, 151, 0.33) 0%,
      rgba(255, 186, 151, 0) 100%
    );
    padding: 12px;
  }
  .equity-item {
    flex: 1;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 24px;
      height: 24px;
      .margin-r(8px);
    }
  }
  .more-item {
    // flex-direction: column;
    text-align: center;
    padding: 0 8px;
    img {
      .margin-r(4px);
      // margin-bottom: 6px;
    }
  }
  .equity-item_line {
    width: 1px;
    height: 24px;
    background-color: rgba(159, 79, 45, 0.15);
    &:last-child {
      width: 0;
    }
  }
  .more-equity {
    text-align: center;
    cursor: pointer;
    margin: 0 8px;
  }
  .use-prime {
    padding: 16px;
    justify-content: space-between;
    p {
      font-size: 14px;
      display: flex;
      align-items: center;
    }
    .use-prime_amount {
      font-size: 24px;
      font-weight: 700;
      color: #873c00;
      .margin-l(2px);
    }
  }
  .start-shipping {
    min-width: 114px;
    cursor: pointer;
    text-align: center;
    font-size: 13px;
    padding: 6px 12px;
    color: #873c00;
    border: 1px solid rgba(135, 60, 0, 0.5);
    border-radius: 20px;
    .margin-l(16px);
  }
}
.prime__renew-wrapper {
  font-size: 14px;
  line-height: 17px;
  padding: 16px;
  background-color: #fff7f2;
  color: rgba(201, 110, 63, 0.6);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0 0 3px 3px;
  span {
    font-weight: 700;
    color: @sui_color_discount;
  }
  .renew-btn {
    position: relative;
    padding: 5px 8px;
    border: 1px solid #c44a01;
    border-radius: 20px;
    .margin-l(20px);
    white-space: nowrap;
    cursor: pointer;
    span {
      font-weight: 400;
      color: #c44a01;
    }
  }
}
.prime__renew-trial {
  background: rgba(255, 241, 213, 0.6);
  color: #c96e3f;
}

.renew-expire__pannel {
  font-size: 14px;
  margin: 0 16px;
  padding: 16px 0;
  color: #767676;
  border-bottom: 1px solid rgba(135, 60, 0, 0.1);
  span {
    font-weight: 700;
    color: @sui_color_discount;
  }
}
.prime-tips {
  font-size: 14px;
  line-height: 17px;
  padding: 16px 0;
  margin: 0 16px;
  color: #873c00;
  border-bottom: 1px solid rgba(135, 60, 0, 0.1);
  span {
    font-size: 14px;
    font-weight: 700;
    color: #fa6338;
  }
}

.prime-pannel2 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 274px;
  border-radius: 6px;
  overflow: hidden;
  background: linear-gradient(
    270deg,
    rgba(255, 186, 151, 0.4) 0%,
    rgba(255, 186, 151, 0.19) 100%
  );
  cursor: pointer;
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 274px;
    height: 116px;
    background: url('/she_dist/images/prime2-bg-88beb5e6f1.png') no-repeat;
    background-size: 274px 116px;
    pointer-events: none; /* 让事件透传 */
  }
  &__ar::after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 274px;
    height: 116px;
    background: url('/she_dist/images/prime2-ar-bg-d7a6ce35a4.png') no-repeat;
    background-size: 274px 116px;
    pointer-events: none; /* 让事件透传 */
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__logo-wrap {
    display: inline-flex;
    position: relative;
    z-index: 2;
    white-space: nowrap;
    height: 34px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 0px 0px 12px 0px;
    background: linear-gradient(
      180deg,
      rgba(255, 204, 178, 0.25) 0%,
      rgba(255, 175, 134, 0.25) 100%
    );
    .logo {
      height: 20px;
    }
    .status {
      display: flex;
      padding: 2px 6px;
      margin-left: 6px;
      align-items: center;
      border-radius: 20px;
      border: 1px solid #d0d0d0;
      background: #e5e5e5;
      color: #767676;
      font-family: SF UI Text;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .free {
      display: flex;
      padding: 2px 6px;
      margin-left: 6px;
      align-items: center;
      border-radius: 28px;
      background: rgba(255, 186, 151, 0.3);
      border: 1px solid rgba(135, 60, 0, 0.2);
      color: rgba(135, 60, 0, 0.8);
    }
  }
  &__icons {
    position: absolute;
    right: 4px;
    z-index: 3;
    display: flex;
  }
  &__icon-wrap {
    margin-left: 2px;
    img {
      width: 24px;
      height: 24px;
    }
  }
  &__content {
    display: flex;
    align-items: center;
    padding: 12px;
    box-sizing: border-box;
    min-height: 72px;
  }
  &__text {
    color: #873c00;
    font-family: SF UI Display;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    span {
      color: #fa6338;
      font-weight: bold;
    }
    .amount {
      color: #873c00;
      font-size: 24px;
      font-weight: bold;
    }
  }
  &__footer {
    flex: 1;
    display: flex;
    width: 274px;
    min-height: 60px;
    padding: 4px 12px;
    align-items: center;
    background: var(--sui-color-club-rosegold-light-2, #ffe2d3);
  }
}
</style>
<style lang="less" scoped>
.prime-pannel2 {
  &__content {
    display: flex;
    align-items: center;
    padding: 12px;
    box-sizing: border-box;
    min-height: 72px;
  }
  &__text {
    color: #873c00;
    font-family: SF UI Display;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    span {
      color: #fa6338;
      font-weight: bold;
    }
    .amount {
      color: #873c00;
      font-size: 24px;
      font-weight: bold;
    }
  }
}
.single-content {
  padding: 10px;
  box-sizing: border-box;
  min-height: 54px;
}
</style>
<style lang="less" scoped>
/* stylelint-disable */
.prime-pannel2-btn {
  width: 100%;
}
.prime-pannel2 {
  &__footer {
    &__tip {
      color: var(--sui-color-club-rosegold-dark-1, #873c00);
      font-family: SF UI Display;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      flex: 1;
      margin-right: 4px;
      span {
        color: #fa6338;
        font-weight: bold;
      }
      p {
        word-break: break-all;
      }
    }
    &__btn {
      position: relative;
      display: flex;
      padding: 6px;
      justify-content: center;
      align-items: center;
      border-radius: 81px;
      border: 1px solid #873c00;
      color: var(--sui-color-club-rosegold-dark-1, #873c00);
      text-align: center;
      font-family: Arial;
      font-size: 13px;
      font-style: normal;
      font-weight: bold;
      line-height: normal;
    }
    &__btn_line {
      width: 100%;
    }
    &__btn_limited {
      position: absolute;
      right: 0;
      top: -27px;
      width: max-content;
      display: inline-flex;
      padding: 2px 4px;
      justify-content: flex-end;
      align-items: center;
      color: #fff;
      font-family: SF UI Display;
      font-size: 12px;
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      border-radius: 2px;
      background: linear-gradient(90deg, #c96e3f 48.82%, #eaa27e 100%);
      &::after {
        content: '';
        position: absolute;
        bottom: -4px;
        right: 14px;
        width: 6px;
        height: 4px;
        background: url('/she_dist/images/prime2-tip-0ff8be5ccc.png') no-repeat;
        background-size: contain;
      }
    }
  }
}
</style>
