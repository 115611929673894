<template>
  <!-- 部分quickShip，不需要qs运费 -->
  <div
    v-if="quickShipFreeShow"
    class="bag-quick-time"
    @click.stop="showQuickPop"
  >
    <div class="bag-quick-time_container">
      <div class="qsLabel-container">
        <quickShipLabel 
          :style-config="{
            'font-size': '12px',
            'font-weight': '700',
          }"
          :show-line="true"
        />
      </div>
      <span
        class="qsLabel-text"
        v-html="quickShipFreeShow"
      ></span>
    </div>
    <div
      v-if="qsMallList && qsMallList.qs_time_same == 0"
      class="dialog-detail-bag"
      @click.prevent="dialogDetail"
    >
      {{ language.SHEIN_KEY_PC_33030 }}
      <Icon 
        name="sui_icon_more_right_16px_2" 
        size="18px" 
      />
    </div>
  </div> 
</template>
<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import quickShipLabel from 'public/src/pages/checkout/components/atom/quickShipLabel.vue'
import { useGetGuickShip } from 'public/src/pages/checkout/hooks/useQuickShip.js'
import { Icon } from '@shein-aidc/icon-vue3'
// import { template } from '@shein/common-function'

export default {
  name: 'QuickShipFreeTips',
  components: {
    quickShipLabel,
    Icon
  },
  props: {
    cartsData: {
      type: Array,
      default: () => []
    },
    shippingMethod: {
      type: Object,
      default: () => {}
    },
    descQuickShipTime: {
      type: String,
      default: ''
    },
  },
  setup() {
    const { qsReplenishDes } = useGetGuickShip()
    return { qsReplenishDes }
  },
  computed: {
    ...mapGetters(['iconPrefix', 'quickShipCheckoutStatus']),
    ...mapState(['checkout', 'language', 'descQuickShipTime', 'qsMallList', 'defaultShipping', 'dayTimeMap']),
    getDayTimeTip() {
      const { transportList = [], selectTransportType } = this.dayTimeMap || {}
      return transportList?.find(({ transportType, isHl, isQuickShip }) => transportType === selectTransportType && !isHl && isQuickShip)?.tip || ''
    },
    quickShipFreeShow(){
      const isAllCartsQuickShip = this.cartsData?.every((item) => +item?.quick_ship && item?.real_quick_ship == 1)
      const qsMakeup = this.checkout?.checkoutBFFAbtInfo?.quickshipFeeSwitch?.param?.qsMakeup || 0
      const showQsFreight = this.shippingMethod?.qs_freight_status == 1 && qsMakeup != 0
      const { quickShipStyle } = this.quickShipCheckoutStatus || {}
      // const { qs_time_same, last_quickship_tag  } = this.qsMallList
      // const qsReplenishDesAbt = this.checkout.checkoutBFFAbtInfo?.TradeTransStoreTest?.param?.TradeTransStoreTestStatus || 0

      // let result = ''
      // const item = this.defaultShipping?.[0] || {}
      // if(item.desc_quick_ship_time){
      //   result = item.desc_quick_ship_time.replace(/script/g, '').replace(/\s/g, ' ').replace(/&nbsp;/g, ' ')
      //   last_quickship_tag && (result = item.desc_quick_ship_time.replace(/{QSname}/g, `${last_quickship_tag}`)
      //     .replace(/\[time\]/g, `${item.quick_ship_time}`))
      // }
      // // 对部分qs时效提示进行内容组装
      // if(qs_time_same == 1 && qsReplenishDesAbt == 0){
      //   result = template(last_quickship_tag, this.language.SHEIN_KEY_PC_33031)
      // }
      return (!isAllCartsQuickShip && !showQsFreight && quickShipStyle) && `${this.qsReplenishDes} ${this.getDayTimeTip}`
    }
  },
  methods: {
    ...mapMutations(['assignState']),
    showQuickPop() {
      this.assignState({
        isShowQuickPop: true
      })
    },
    dialogDetail(){
      event.stopPropagation()
      this.$emit('openModal')
    },
  },
  emits: ['openModal']
}
</script>
<style lang="less" scoped>
.bag-quick-time{
    display: flex;
    // padding: 10px 12px;
    // height: 50px;
    padding: 5px;
    align-items: center;
    align-self: stretch;
    background: var(---sui_color_safety_bg, #F5FCFB);
    border: 1px solid #BADECD;
    border-radius: 3px;
    margin-bottom: 8px;
    cursor: pointer;
    justify-content: space-between;
    .bag-quick-time_container{
      display: flex;
      align-items: center;
    }
    .qsLabel-container{
      display: flex;
      align-items: center;
      margin-right: 5px;
      max-width: 40%;
    }
    // 不用border-right，高度不好匹配UI稿
    .qsLabel-line {
      width: 1px;
      height: 14px;
      background: #8DC2AB;
      margin-left: 6px;
    }
    .qsLabel-text{
      margin-left: 3px;
      overflow: hidden;  
      text-overflow: ellipsis; 
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 12px;
      font-weight: 400;
      // color:#666;
      color: #198055;
    }
    .dialog-detail-bag{
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      margin-left: 3px;
      cursor: pointer;
      display: flex;
      align-items: center;
      color: #666;
    }
}
</style>
