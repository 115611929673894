import { createApp } from 'public/src/pages/detail/main/app.js'
import router from 'public/src/pages/detail/main/router/index.js'
import infiniteScroll from '@sheinfe/vue-infinite-scroll'
import { businessLibInitializeForCSR } from 'public/src/pages/common/business-lib-initialize/csr.js'
import { getPreFetchDetailRealTimeData } from 'public/src/services/pre_requests/detail'
import { markPoint } from 'public/src/services/mark'
const { app, store } = createApp()

const clientInit = () => {
  if (window?.gbRawData) {
    if (window?.gbRawData.headerVueSsr) {
      businessLibInitializeForCSR(app, { bsLibsEnvs: window?.gbRawData.bsLibsEnvs })
    }
    app.use(infiniteScroll)
    store.replaceState({ ...store.state, ...window.gbRawData })
    
  }
  app.use(router)
  markPoint({ eventName: 'createApp', measureFrom: 'realTime' })
  router.isReady().then(() => {
    getPreFetchDetailRealTimeData().then((data) => {
      markPoint({ eventName: 'beforeAppMount', measureFrom: 'createApp' })
      if (data) {
        store.dispatch('initDetailSreamPart', data)
        // AGE_LIMIT_HOOK
        const { showAgeLimit } = data.category || {}
        if (!showAgeLimit && window?.gbRawData?.productIntroData?.hasLimitGoods) {
          document.getElementById('AGE_LIMIT_HOOK') && (document.getElementById('AGE_LIMIT_HOOK').style.display = 'none')
        }
      }
    }).finally(() => {
      app.mount('#goods-detail-v3') // vue3会多加一层div，  不会替换div
      if(typeof window !== 'undefined' && location?.search?.includes('detail_debug')) {
        window.__detail_store__ = store
      }
    })
  
  })
}

export default clientInit

