
import i18n from 'public/src/pages/common/biz_helper/i18n'

export const EItemConfigType = {
  /**
     * 一行一图
     */
  Pic1: 'Pic1',
  /**
     * 一行多图
     */
  Pic3: 'Pic3',
  /**
   * 一行四/五图
   */
  Pic45: 'Pic45',
}

/**
 * @description: 获取商品语言
 */
export const getItemLanguage = async () => {
  return i18n.loadByQueue('item')
}

/**
 * 商卡客户端预处理bff依赖数据
 */
export const getProductBffDependData = async ({
  isPaidUser = false,
} = {}) => {
  const { SiteUID, shein_suggested_sale_price, lang } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
  // 当前站点合规配置 'normal', 'special','special_de'
  const suggestedSaleType = shein_suggested_sale_price?.[SiteUID]
  const language = await getItemLanguage()

  return {
    lang: lang || 'us',
    language,
    isPaid: isPaidUser,
    suggestedSaleType,
  }
}
