<template>
  <div class="common-image">
    <img
      class="common-image__img"
      :class="imgClass"
      :src="baseCutImg"
    />

    <img 
      v-if="imageMaskType && item.product.maskLayerImage && item.product.maskLayerImage[imageMaskType]" 
      class="common-image_container" 
      :src="item.product.maskLayerImage[imageMaskType]"
    />

    <slot name="others"></slot>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { getMainImageUrl } from 'public/src/pages/cart_new/utils/index.js'
import { transfromAndCutImg } from 'public/src/services/resource/index'

const props = defineProps({
  item: {
    type: Object,
    default: () => {},
  },
  cutImgWidth: {
    type: Number,
    default: 90,
  },
  imgClass: {
    type: String,
    default: '',
  },
  imageMaskType: {
    type: String,
    default: '',
  },
})

const store = useStore()
const IS_RW = computed(() => store?.state?.locals?.IS_RW ?? gbCommonInfo?.IS_RW)
const RESOURCE_SDK = computed(() => store?.state?.locals?.RESOURCE_SDK ?? gbCommonInfo?.RESOURCE_SDK)
const customServiceImgDomains = computed(() => store?.getters?.customServiceImgDomains)
const thirdPartyServiceImgDomains = computed(() => store?.getters?.thirdPartyServiceImgDomains)

const baseCutImg = computed(() => {
  const url = getMainImageUrl({ 
    item: props.item, 
    IS_RW: IS_RW.value,
    customServiceImgDomains: customServiceImgDomains.value,
    thirdPartyServiceImgDomains: thirdPartyServiceImgDomains.value
  })
  const cutImgUrl = cutImg(
    url, 
    1440, 
    { 
      cutTo: +props.cutImgWidth * 2, 
      max: +props.cutImgWidth * 3
    }
  )
  return cutImgUrl
})

const cutImg = (imgUrl, designWidth = 1440, exp) => {
  const { deviceData = '', isSupportWeb = '', isSupprotCut = false } = RESOURCE_SDK.value || {}

  const cutData = {
    deviceData,
    isSupportWebp: Boolean(isSupportWeb),
    isSupprotCut,
    imgUrl,
    designWidth: Number(designWidth),
    exp,
  }

  return transfromAndCutImg(cutData)
}
</script>

<style lang="less" scoped>
.common-image {
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .03);
  overflow: hidden;
  flex-shrink: 0;
  position: relative;

  &__img {
    width: 100%;
  }

  .common-image_container{
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    width: 100%;
  }
}
</style>
