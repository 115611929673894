import { getLocalStorage } from '@shein/common-function'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { SIMetric } from 'public/src/pages/common/monitor/index.js'

export const timeCheck = (time, currentTime, type) => {
  const SiteUID = window?.gbCommonInfo?.SiteUID ?? ''
  const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'userCenter/PrimePannel' })
  const cacheKey =
      SiteUID + '-' + (memberId || '') + '-'
  // 临期key
  const nearExpiredKey = cacheKey + 'primeV2RenewDialog'
  // 逾期key
  const ExpiredKey = cacheKey + 'primeV2ExpiredRenewDialog'

  let cacheTimeKey
  if (type == 'nearExpired') {
    cacheTimeKey = nearExpiredKey
  } else {
    cacheTimeKey = ExpiredKey
  }
  const cacheTime = +getLocalStorage(cacheTimeKey)

  if (!cacheTime) {
    // 不存在缓存，触发弹窗
    return true
  } else {
    const cacheDayStartTime = new Date(cacheTime).getTime()
    const currentDayStartTime = new Date(currentTime).getTime()
    // 命中缓存
    if (currentDayStartTime - cacheDayStartTime < time) {
      return false
    } else {
      return true
    }
  }
}

export const isShowRenewPopUpDialogV2 = renewPopUpInfoV2 => {
  if(!renewPopUpInfoV2 || !Object.keys(renewPopUpInfoV2)?.length)  return false
  const currentTime = new Date().getTime()
  const time = renewPopUpInfoV2?.totalEndTime
  const intervalTime = renewPopUpInfoV2?.intervalTime
  // let days = time / 1000 / 60 / 60 / 24
  let flag
      
  flag = timeCheck(
    intervalTime * 1000,
    currentTime,
    time >= 0 ? 'nearExpired' : 'expired'
  )
  return !!(flag && renewPopUpInfoV2)
}

export const isShowRenewPopUpDialog = renewPopUpInfo => {
  if(!renewPopUpInfo || !Object.keys(renewPopUpInfo)?.length)  return false
  
  const req_currency_price = renewPopUpInfo?.req_currency_price || 0
  const total_discount_price =
        renewPopUpInfo?.total_discount_price || 0
  const flag1 = req_currency_price <= 0
  const flag2 = total_discount_price <= 0
  let flag3 = renewPopUpInfo?.have_gift_flag

  if (flag1 && flag2 && flag3) {
    return false
  }

  const currentTime = new Date().getTime()
  const time = renewPopUpInfo?.total_end_time
  let days = time / 1000 / 60 / 60 / 24
  let flag 
  if (days >= 0) {
    // currentRule = near_expired_rules.find(
    //   rule => days >= rule.left_bound && days < rule.right_bound
    // )
  } else {
    days = -days
    // currentRule = overdue_rules.find(
    //   rule => days >= rule.left_bound && days < rule.right_bound
    // )
  }
  flag = timeCheck(
    renewPopUpInfo?.interval_time * 1000,
    currentTime,
    time >= 0 ? 'nearExpired' : 'expired'
  )
  return !!flag
}

export const isShowFreeGiftDialog = freeGiftPopupInfo => {
  if (!freeGiftPopupInfo || !freeGiftPopupInfo.giftInfoList || !freeGiftPopupInfo?.giftInfoList?.length) {
    return false
  }
  return true
}

export const isShowPayBackDialog = paybackInfo => {
  const { SiteUID } = gbRawData || {}
  const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'userCenter/PrimePannel' })
  const cacheKey = `pay_back_cache_${memberId || ''}_${SiteUID}`

  // payback_info为null 清除当前(站点+id)缓存
  if (!paybackInfo) {
    return false
  }
  const cacheRoi = getLocalStorage(cacheKey)
  const newRoi = Number(paybackInfo?.popups_info?.roi)
  if (cacheRoi) {
    if (Number(cacheRoi) !== newRoi) {
      return true
    }
    return false
  }
  return true
}

// 上报新续费弹框/旧续费弹框/免费礼弹框/回本弹框四种弹框弹出成功率
export const reportUserCenterDialogSuccess = params => {
  const { status } = params.tags
  const result = status === '1' ? 'success' : 'failed'
  SIMetric.metricCount({
    metric_name: 'PC_USER_CENTER:' + params.metricName,
    tags: {
      ...params.tags,
      tag: 'web_client_userCenter_dialogOpenedMonitor',
      page_name: 'page_me',
    },
    params,
    message: `${params.metricName}: dialog opened ${result}`
  })
}
