
// bffFilterInfo
// 修改请同步到中间层ssr services/product_list_bff/utlis/index.js

export function generateRealSelectBffFilterInfo ({
  bffCateAttrResult,
  bffPicNavResult,
  bffCloudTagResult,
  bffDailyDatesResult,
}, requesInfoQuery = {}) {
  // pc没有用图文导航，留着方便与pwa逻辑一致
  const bffPicNavInfo = bffPicNavResult?.code === '0' ? bffPicNavResult.info : {}

  const bffCateAttrInfo = bffCateAttrResult?.code === '0' ? bffCateAttrResult.info : {}
  const bffCloudTagInfo = bffCloudTagResult?.code === '0' ? bffCloudTagResult.info : {}
  const bffDailyDatesInfo = bffDailyDatesResult?.code === '0' ? bffDailyDatesResult.info : {}

  const bffFilterInfo = {
    cateId: bffCateAttrInfo?.cateId ?? '',
    show_adult_tip: bffCateAttrInfo?.show_adult_tip || bffCateAttrInfo?.showAdultTip,
    tagSlotConfigs: bffCloudTagInfo?.tagSlotConfigs ?? [],
    tags: bffCloudTagInfo?.tags ?? [],
    filterList: bffCateAttrInfo?.filterList ?? [],
    filterSortConfigs: bffCateAttrInfo?.filterSortConfigs ?? [],
    attribute: bffCateAttrInfo?.attribute ?? [],
    navs: bffPicNavInfo?.navs ?? [],
    list_nav_source: bffPicNavInfo?.list_nav_source ?? '',
    tabs: bffPicNavInfo?.tabs ?? [],
    min_price: bffCateAttrInfo?.min_price ?? 0,
    max_price: bffCateAttrInfo?.max_price ?? 0,
    cat_path: bffCateAttrInfo?.cat_path ?? [],
    categories: bffCateAttrInfo?.categories ?? [],
    all_categories: bffCateAttrInfo?.all_categories ?? [],
    bannerTag: bffCloudTagInfo?.bannerTag ?? {},
    tagChild: bffCloudTagInfo?.tagChild ?? {},
    dailyDates: bffDailyDatesInfo?.daily ?? [],
  }

  if (requesInfoQuery?.type === 'picks') {
    // shein picks 当前的真实或选品 id
    bffFilterInfo.picksCatId = bffCateAttrInfo.selectId
  }

  return bffFilterInfo
}

