<template>
  <div class="image-view__galleryWrap">
    <!-- 左边小图 -->
    <div class="image-view__thumbs">
      <div class="image-view__thumbs-wrap">
        <div
          v-for="(item, index) in resizeUrls"
          :key="item.id"
          class="image-view__thumbs-item"
          :class="{ active: activeIndex == index }"
          @click="clickThumbnail(index)"
        >
          <img
            class="material-detail-item lazyload"
            :data-src="item.url"
          />
        </div>
      </div>
    </div>

    <div
      class="image-view__main"
      tabindex="0"
    >
      <!-- 主图 -->
      <swiper-container
        ref="image-view__main-swiper"
        class="main-image"
        init="false"
        destroy-on-disconnected="false"
        :dir="GB_cssRight ? 'rtl' : 'ltr'"
      >
        <swiper-slide
          v-for="item in originUrls"
          :key="item.id"
          class="image-view__main-item"
          :class="{ 'swiper-no-swiping': originUrls.length === 1 }"
        >
          <div class="image-view__main-item-wrap">
            <div class="image-view__main-item-wrap-content">
              <CropImageContainer
                :img-src="transformImg({ img: item.url })"
                :fixed-ratio="fixedRatio"
                :transform-img-props="{
                  isFirstPage: false,
                  loadImg: LAZY_IMG,
                }"
              />
            </div>
          </div>
        </swiper-slide>
      </swiper-container>
      <p class="image-view__main-tips">
        {{ mainTips }}
      </p>
      <!-- pre -->
      <div
        v-show="originUrls.length > 1"
        ref="swiperPre"
        class="image-view__main-pre image-view__main-arrow"
      >
        <Icon
          name="sui_icon_more_left_18px"
          size="16px"
          :is-rotate="GB_cssRight"
        />
      </div>
      <!-- next -->
      <div
        v-show="originUrls.length > 1"
        ref="swiperNext"
        class="image-view__main-next image-view__main-arrow"
      >
        <Icon
          name="sui_icon_more_right_18px"
          size="16px"
          :is-rotate="GB_cssRight"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { transformImg } from '@shein/common-function'
import { CropImageContainer } from 'public/src/pages/components/CropImageContainer/index.js'
import { Icon } from '@shein-aidc/icon-vue3'
import { register } from 'swiper/element'
import { Navigation } from 'swiper/modules'

typeof window !== 'undefined' && register()
const { GB_cssRight = false, PUBLIC_CDN = '' } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

export default {
  name: 'ImageViewSwiper',
  components: {
    CropImageContainer,
    Icon
  },
  props: {
    language: {
      type: Object,
      default: () => ({}),
    },
    fixedRatio: {
      type: String,
      default: '3-4',
    },
    resizeUrls: {
      type: Array,
      default: () => [],
    },
    originUrls: {
      type: Array,
      default: () => [],
    },
    effectsTip: {
      type: String,
      default: '',
    },
    imagesTip: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      activeIndex: 0,
      gallerySwiper: null,
      LAZY_IMG: window.LAZY_IMG,
      GB_cssRight
    }
  },
  computed: {
    mainTips() {
      const currentItem = this.originUrls[this.activeIndex]
      if (currentItem) {
        return currentItem.type === 1
          ? this.effectsTip
          : this.imagesTip
      }
      return ''
    },
  },
  watch: {
    originUrls: {
      deep: true,
      handler(data) {
        if (!data.length) return
        this.$nextTick(() => {
          this.initSwiper()
          if (typeof GB_SHEIN_lazyLoadInstance != 'undefined') {
            GB_SHEIN_lazyLoadInstance.update()
          }
        })
      },
      immediate: true,
    },
  },
  methods: {
    scrollIntoView(index) {
      const thumbs = this.$el.querySelectorAll('.image-view__thumbs-item')
      const thumbsItem = thumbs[index]
      if (thumbsItem) {
        thumbsItem.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    },
    initSwiper() {
      if (this.gallerySwiper) {
        this.activeIndex = 0
        this.gallerySwiper.init()
        return
      }

      const swiperEl = this.$refs['image-view__main-swiper']
      const swiperParams = {
        modules: [Navigation],
        slidesPerView: 1,
        slidesPerGroup: 1,
        loop: true,
        observer: true,
        observeParents: true,
        noSwiping: true,
        injectStylesUrls: [
          PUBLIC_CDN + '/she_dist/libs/swiper/modules/navigation-element.min.css',
        ],
        navigation: {
          prevEl: this.$refs['swiperPre'],
          nextEl: this.$refs['swiperNext'],
        },
        on: {
          slideChangeTransitionStart: (swiper) => {
            this.activeIndex = swiper.realIndex
            this.scrollIntoView(swiper.realIndex)
          },
        }
      }

      Object.assign(swiperEl, swiperParams)
      swiperEl.initialize()
      this.gallerySwiper = swiperEl.swiper
    },
    // 点击小图
    clickThumbnail(index) {
      if (this.gallerySwiper) {
        this.gallerySwiper.slideToLoop(index, 300, true)
      }
    },
    slideToIndex(index) {
      setTimeout(() => {
        this.activeIndex = index
        if (this.gallerySwiper) {
          this.gallerySwiper.slideToLoop(index, 0, false)
          this.scrollIntoView(index)
        }
      }, 200)
    },
    transformImg,
  },
}
</script>
<style lang="less">
.image-view {
  &__galleryWrap {
    font-size: 0;
    position: relative;
    padding-left: 52px;
  }

  &__thumbs {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 100%;
    overflow: hidden;
  }
  &__thumbs-wrap {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    vertical-align: top;
  }
  &__thumbs-item {
    width: 50px;
    height: 50px;
    margin-bottom: 14px;
    cursor: pointer;

    &.active, &:hover {
      border: 1px solid #222;
      padding: 2px;
      img {
        width: 44px;
        height: 44px;
      }
      /* rw:begin */
      border: 1px solid rgba(255, 105, 110, 1);
    }
    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
    }
  }

  &__main {
    vertical-align: top;
    position: relative;
    overflow: hidden;

    /* stylelint-disable-next-line selector-nested-pattern */
    &-tips {
      position: absolute;
      bottom: 24px;
      left: 50%;
      z-index: 100;
      display: inline-flex;
      padding: 2px 11px;
      justify-content: center;
      align-items: center;
      transform: translateX(-50%);
      font-family: 'SF UI Display';
      font-size: 14px;
      word-break: break-all;
      border-radius: 20px;
      color: @sui_color_white;
      background: @sui_color_gray_dark2;
    }

    &:hover {
      .image-view__main-arrow {
        opacity: 1;
      }
    }
    img {
      width: 100%;
    }
  }

  &__main-item-wrap {
    display: flex;
    justify-content: center;

    /* stylelint-disable-next-line */
    &-content {
      width: 467px;
    }
  }
  &__main-arrow {
    position: absolute;
    font-size: 16px;
    opacity: 0;
    cursor: pointer;
    top: 50%;
    z-index: @zindex-swiper-arrow + 1;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    background: rgba(255, 255, 255, 0.94);
    border-radius: 50%;
    box-shadow: 0 2px 5px fade(#000, 8%);
  }

  &__main-pre {
    left: 29px;
  }
  &__main-next {
    right: 29px;
  }
}
</style>
