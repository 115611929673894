/**
 * @description: 买家秀 数据中心
 */
export default {
  namespaced: true,
  state: {
    buyer_show_floor_info: {},
  },
  mutations: {
    update_buyer_show_floor_info(state, paylod) {
      state.buyer_show_floor_info = paylod
    },
  },
  getters: {
    style_gallery_image_list(state) {
      const { 
        styleGalleryInfos,
        fineCommentInfos,
      } = state.buyer_show_floor_info || {}
      const list = []
      // 顺序： 红人图 => 精选评论图
      list.push(...(styleGalleryInfos || []))
      list.push(...(fineCommentInfos || []))
      return list
    },
    lang_map(state, getters, rootState, rootGetters) {
      const language_v2 = rootGetters['Adaptor/language_v2']
      const { SHEIN_KEY_PC_14635, SHEIN_KEY_PC_20211 } = language_v2 || {}
      return {
        titleLang: SHEIN_KEY_PC_14635,
        pictureLang: SHEIN_KEY_PC_20211,
      }
    },
  },
  actions: {
    init_buyer_show_floor_info({ commit }, _) {
      _.then((res = {}) => {
        commit('update_buyer_show_floor_info', res?.info || {})
      })
    }
  },
}
    
  
