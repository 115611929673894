<template>
  <SDialog
    v-if="!isFirstShow"
    v-model:visible="showUnFollowDialog"
    show-close
    :append-to-body="appendToBody"
    :close-on-click-modal="false"
    class="un-follow-dialog"
    @close-from-icon="_cancel"
    @click.stop
  >
    <template #title>
      <p class="un-follow-dialog__title">
        {{ dialogLanguage?.SHEIN_KEY_PC_33449 || 'Are you sure you want to unfollow?' }}
      </p>
    </template>
    <div class="un-follow-dialog__content">
      <p class="un-follow-dialog__content-desc">
        {{ dialogLanguage?.SHEIN_KEY_PC_33448 || 'After unfollowing, you will no longer receive these notifications:' }}
      </p>
      <div class="un-follow-dialog__label-group">
        <div class="un-follow-dialog__label-item">
          <Icon
            name="sui_icon_coupon_discount_12px_color"
            size="24px"
            class="un-follow-dialog__label-icon"
          />
          <p class="un-follow-dialog__label-text">
            {{ dialogLanguage?.SHEIN_KEY_PC_33451 || 'Promotions' }}
          </p>
        </div>
        <div class="un-follow-dialog__label-item">
          <i
            class="un-follow-dialog__label-icon un-follow-dialog__label-new-arrival"
          ></i>
          <p class="un-follow-dialog__label-text">
            {{ dialogLanguage?.SHEIN_KEY_PC_33452 || 'New Arrivals' }}
          </p>
        </div>
      </div>

      <div class="un-follow-dialog__footer">
        <SButton
          width="100%"
          :type="['primary', 'H72PX']"
          @click="_confirm"
        >
          {{ dialogLanguage?.SHEIN_KEY_PC_33450 || 'Unfollow' }}
        </SButton>

        <SButton
          width="100%"
          :type="['ghost', 'H72PX']"
          class="un-follow-dialog__cancel-btn"
          @click="_cancel"
        >
          {{ dialogLanguage?.SHEIN_KEY_PC_33447 || 'Think again' }}
        </SButton>
      </div>
    </div>
  </SDialog>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'UnFollowDialog',
})
</script>
<script setup>
/**
 * 取消关注挽留弹窗
 * @description 提供 店铺场景、商详场景 的取消关注操作使用
 */
import { ref, defineExpose, onDeactivated } from 'vue'
import { SDialog } from '@shein-aidc/sui-dialog'
import { SButton } from '@shein-aidc/sui-button'
import { Icon } from '@shein-aidc/icon-vue3'

const props = defineProps({
  language: {
    type: Object,
    default: () => ({}),
  },
  appendToBody: {
    type: Boolean,
    default: false,
  },
})
const emits = defineEmits(['show', 'confirm', 'cancel'])

const isFirstShow = ref(true)
const showUnFollowDialog = ref(false)
const confirmResolve = ref(null)
const confirmReject = ref(null)
const dialogLanguage = ref({})

const show = params => {
  if (showUnFollowDialog.value && !isFirstShow.value) return

  dialogLanguage.value =
    {
      ...params?.language,
      ...props.language,
    } || {}
  isFirstShow.value = false
  showUnFollowDialog.value = true
  emits('show')
  return new Promise((resolve, reject) => {
    confirmResolve.value = resolve
    confirmReject.value = reject
  })
}
const hide = () => {
  showUnFollowDialog.value = false
}
const _confirm = () => {
  hide()
  emits('confirm')
  typeof confirmResolve.value === 'function' &&
    confirmResolve.value({
      type: 'confirm',
    })
}
const _cancel = () => {
  hide()
  emits('cancel')
  typeof confirmReject.value === 'function' &&
    confirmReject.value({
      type: 'cancel',
    })
}

onDeactivated(() => {
  hide()
})

defineExpose({
  showUnFollowDialog,
  show,
  hide,
})
</script>

<style lang="less" scoped>
.un-follow-dialog {
  :deep(.sui-dialog__wrapper)  {
    padding: 32px 32px 20px;
  }

  &__title {
    font-size: 20px;
    line-height: 23px;
    .line-camp(3);
  }

  &__content {
    padding-top: 8px;
    color: var(---sui_color_gray_dark2, #666);
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
  }

  &__content-desc {
    .line-camp(4);
  }

  &__label-group {
    margin-top: 16px;
    margin-bottom: 32px;
    padding: 8px 4px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    background-color: #f6f6f6;
  }

  &__label-item {
    position: relative;
    flex-shrink: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(50% - 8px);

    &:nth-of-type(n + 2) {
      margin: 0 0 0 8px;
    }
    &:nth-of-type(n + 2)::before {
      content: '';
      position: absolute;
      left: -5px;
      right: auto;
      top: 50%;
      bottom: auto;
      transform: translateY(-50%);
      width: 2px;
      height: 24px;
      background-color: rgba(0, 0, 0, 0.12);
    }
  }

  &__label-icon {
    width: 24px;
    height: 24px;
  }

  // new arrival icon
  &__label-new-arrival {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIj48cGF0aCBkPSJNMTUuMjk1IDMuNjI4Yy4wNi0uMzQuNTUtLjM0LjYwOSAwbC40MjUgMi40MThhNi40ODIgNi40ODIgMCAwIDAgNC44NzQgNS4xODJsMS4yNTIuM2MuMzE2LjA3NS4zMTYuNTI1IDAgLjZsLTEuMjk1LjMxYTYuNDgyIDYuNDgyIDAgMCAwLTQuODYzIDUuMTJsLS4zOTQgMi4xMmMtLjA2Mi4zMzYtLjU0NS4zMzYtLjYwNyAwbC0uMjg4LTEuNTUyYTYuNDgyIDYuNDgyIDAgMCAwLTUuNjMtNS4yNTVsLTcuNzc3LS44OTljLS4xNy0uMDItLjE3LS4yNjggMC0uMjg4bDcuNzMtLjg5M2E2LjQ4MiA2LjQ4MiAwIDAgMCA1LjY0LTUuMzE3bC4zMjQtMS44NDZ6IiBmaWxsPSIjM0NCRDQ1Ii8+PHBhdGggZD0iTTQuNjk0IDYuNzlzNC40LS40IDYtLjRjLjc0MiAwIDEuMjI2LjA4NyAxLjUzMi4xOC4yNzYuMDgzLjI3Ni4zNiAwIC40NDMtLjMwNi4wOTItLjc5LjE3OC0xLjUzMi4xNzgtMS42IDAtNi0uNC02LS40ek00IDE2LjRzMi42NC0uNCAzLjYtLjRjLjQ4MiAwIC43ODMuMS45NjMuMi4xNjguMDk1LjE2OC4zMDUgMCAuMzk5LS4xOC4xLS40OC4yLS45NjIuMi0uOTYgMC0zLjYtLjQtMy42LS40eiIgZmlsbD0iIzNDQkQ0NSIvPjwvc3ZnPg==');
  }

  &__label-text {
    padding-top: 2px;
    color: #000;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    word-break: break-all;
    .line-camp(2);
  }

  &__cancel-btn {
    margin-top: 6px;
    color: #2d68a8;
    border-color: transparent;
  }
}
</style>
