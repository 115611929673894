/**
 * @description: ccxbanner 数据中心
 */

export default {
  namespaced: true,
  state: {
    context: {},
  },
  mutations: {
    update_context(state, paylod) {
      const { pageType, id, content } = paylod || {}
      state.context = {
        content,
        pageType,
        id,
      }
    },
  },
  getters: {
    ccc_app_content(state) {
      const { content } = state.context
      return content || []
    },
    cate_links(state, getters) {
      const { cateLinks } = getters.ccc_app_content
      return cateLinks
    },
    scene_data(state) {
      const { pageType, id } = state.context
      return {
        sceneName: pageType, // 埋点需要
        pageType: pageType, // 埋点需要
        pageFrom: pageType, // 标识应用在哪个页面，你也可以直接取ccc后台返的pageType
        id,
      }
    },
    has_src(state, getters) {
      const { ccc_app_content = [] } = getters
      const bannerItem = ccc_app_content[0]?.props?.items || []
      const hasSrc = bannerItem.some(item => item.image.src)
      return hasSrc
    },
    is_third_party_merchant_banner(state, getters) {
      const { ccc_app_content = [] } = getters
      const { isThirdPartyMerchantBanner = false } =
        ccc_app_content?.[0]?.props?.metaData || {}
      return isThirdPartyMerchantBanner
    },
  },
  actions: {
    init_ccc_x_banner({ commit }, _) {
      _.then((res = {}) => {
        const context = res.info || {}
        commit('update_context', context)
      })
    }
  },
}
