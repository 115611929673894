import { click_estimated_price, expose_estimated_price } from 'public/src/pages/cart_v2/common/analysis/item/estimatedPrice.js'

/**
 * EstimatedPrice 原子组件操作
 */
export function useEstimatedPriceEvent() {
  /**
   * 点击 
   */
  const onOpened = ({ itemOpts: { data }, analysis = {} }) => {
    click_estimated_price(data.value, analysis.click_estimated_price)
  }

  const onExposeChange = ({ itemOpts: { data, event }, analysis = {} }) => {
    const getIsChecked = (event) => {
      const beforePrice = event?.o?.estimatedPrice?.amount
      const price = event?.n?.estimatedPrice?.amount
      if (!beforePrice || !price) return '-'
      if (+beforePrice === +price) return 'remain'
      if (+beforePrice - +price > 0) return 'smaller'
      return 'increase'
    }
    expose_estimated_price(data.value, (defaultData) => {
      const data = {
        ...defaultData,
        is_change: getIsChecked(event),
      }
      return analysis?.expose_estimated_price?.(data) ?? data
    })
  }

  return {
    onOpened,
    onExposeChange,
  }
}
