<template>
  <s-popover
    v-if="show"
    trigger="hover"
    :placement="placement"
    :append-to-body="appendToBody"
    :fix-disappear-position="fixDisappearPosition"
    hide-close-icon
    :prop-style="{
      width: '360px',
      maxWidth: 'unset',
    }"
    :class="[
      'cart-alldiscountdetail-popover',
      {
        'cart-alldiscountdetail-popover__open': isOpen,
      }
    ]"
    @before-open="onPopoverOpen"
    @closed="onPopoverClose"
  >
    <div class="cart-alldiscountdetail-popover__wrap">
      <CartAllDiscountDetailContent
        v-if="isOpen"
        v-bind="allDiscountDetailContentProps"
        @modify-cart-check-status="onModifyCartCheckStatus"
      />
    </div>

    <div
      v-if="loading"
      class="cart-alldiscountdetail-popover__loading"
    >
      <div
        class="la-ball-pulse la-ball-pulse-1x la-ball-pulse-black"
      >
        <div></div><div></div><div></div>
      </div>
    </div>

    <template #reference>
      <div class="cart-alldiscountdetail-popover__reference">
        <slot></slot>
        <sui_icon_more_down_12px
          v-expose="{
            id: '1-7-2-1',
          }"
          size="12px"
          class="cart-alldiscountdetail-popover__reference-icon"
        />
      </div>
    </template>
  </s-popover>
  <slot v-else></slot>
</template>

<script setup>
import { ref, computed, defineAsyncComponent } from 'vue'
import { sui_icon_more_down_12px } from '@shein-aidc/icon-vue3'
import useModifyCartCheckStatus from 'public/src/pages/cart_v2/hooks/useModifyCartCheckStatus'
import { useCartAllDiscountDetail } from './useCartAllDiscountDetail'
import { sendSaClickPromotiondetails, sendSaExposePromotiondetailsbox, sendSaExposePromotiondetailsOriginalPrice } from './utils/analysis'

const CartAllDiscountDetailContent = defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-all-discount-detail-content" */'./CartAllDiscountDetailContent.vue'))

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  popoverMode: {
    type: String,
    default: '',
  },
  placement: {
    type: String,
    default: 'bottom-end',
  },
  appendToBody: {
    type: Boolean,
    default: true,
  },
  fixDisappearPosition: {
    type: Boolean,
    default: false,
  },
})

const { allDiscountDetailMode, allDiscountDetailContentProps, hasFlashSale } = useCartAllDiscountDetail({ mode: props.popoverMode })

const show = computed(() => allDiscountDetailMode.value === 'popover' && props.disabled === false)

const loading = ref(false)
const isOpen = ref(false)
const onPopoverOpen = () => {
  isOpen.value = true

  sendSaClickPromotiondetails({ isOpen: true })
  sendSaExposePromotiondetailsbox()
  if (hasFlashSale.value) sendSaExposePromotiondetailsOriginalPrice()
}
const onPopoverClose = () => {
  isOpen.value = false

  sendSaClickPromotiondetails({ isOpen: false })
  sendSaExposePromotiondetailsbox()
}

const { modifyCartCheckStatus } = useModifyCartCheckStatus()
const onModifyCartCheckStatus = ({ info, checked, callback }) => {
  modifyCartCheckStatus({
    type: 'item',
    cartIdList: [info.id],
    operationType: checked ? 1 : 2,
    isShowLoading: false,
    onBeforeModify() {
      loading.value = true
    },
    onAfterModify() {
      loading.value = false
      callback()
    }
  }, {
    click_select_someproduct(data) {
      return {
        position: 'popup',
        select_type: data.select_type,
        is_select: data.is_select,
        scene: data.scene,
      }
    }
  })
}
</script>

<style lang="less">
.cart-alldiscountdetail-popover {
  &__open {
    .cart-alldiscountdetail-popover__reference-icon {
      transform: rotate(180deg);
    }
  }
  &__wrap {
    position: relative;
    max-height: 50vh;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    overscroll-behavior: contain;
    -ms-scroll-chaining: contain;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__reference {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }

  &__reference-icon {
    margin-left: 4px;
    transition: transform 0.3s;
  }

  &__loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 10;
  }
}
</style>
