const state = {
  originCartNum: 0,
  showSkeleton: 0,
  originCartInfo: {},
  cartAbtInfo: {},
  logoList: [],
  locals: {},
  isSSR: false,
  loginStatus: false,
  isPlatForm: false, // 是否是平台化多mall
  language: {},
  cartInfo: {}, // 购物车信息
  carts: [], // 购物车列表
  cartIds: [],
  cartItemMap: {},
  cartSumQuantity: 0, //购物车数量
  soldoutIds: [],
  soldoutItems: [],
  batchActive: false, // 批量
  loadingShow: 0,
  outStockCarts: [], // 缺货商品
  showSoldOutDialog: false, // 售罄商品弹窗
  lastCheckStockTime: 0,
  mallCartList: [],
  mallShippingInfo: {},
  noticeMessage: '',
  // 购物车使用bff接口开关
  useBffApi: {
  },
  // BFF 接口返回的购物车超限拦截信息
  interceptResultInfo: {},
  showPromotionInterception: false,
  showPromotionInterceptionV2: false,
  showCartIncentiveDrawer: false,
  cartIncentiveDrawerInfo: {},
  topBannerInfo: {},
  savedPrice: {},
  priceMode: {},
  mallCartBiData: {},
  defaultAddress: {},
  batchDeleteStayPopUp: {}, // 批量删除弹窗数据
  modalOptions: {
    show: false,
    titleText: '',
    cancelText: '',
    okText: '',
    okCallback: () => {},
    cancelCallback: () => {},
  },
  batchEditItemIds: [],
  asyncComponentLoadedStatus: {
    // cartFullGiftsDrawIsLoaded: false,
    cartAddBuyDrawIsLoaded: false,
    // cartBuyGiftDrawIsLoaded: false,
    batchActiveIsLoaded: false,
    // cartClubGiftDrawIsLoaded: false,
    soldOutItemDialogIsLoaded: false,
    soldOutDialogIsLoaded: false,
    negativeInfoDialogIsLoaded: false,
    lowPriceUserDialogIsLoaded: false,
  },
  showSoldOutItemDialog: false, // 失效商品组弹窗
  showLowPriceUser: false, // 低价用户弹窗
  drawerGetCoupon: false, // 打开领券弹窗
  mallInfoForGetCoupon: {}, // 传给领券列表接口的店铺数据
  couponInfo: {}, // 券信息
  cartIsFreeShipping: false, // 购物车是否满足免邮，仅在未登录场景下唤起登录弹窗用，登录场景此字段一直为false
  mallShippingAnalysisParam: {
    platform: null, // 平台级免邮
  }, // 商城级别的免邮运费分析参数
}

export default state
