<template>
  <div
    v-expose="{
      id: '1-7-3-53',
      data: {
        coupon_type: filterData?.labelId,
        is_add: filterData?.isCanAdd ? 1 : 0
      }
    }"
    class="cart-filter-coupon"
  >
    <Icon
      class="icon"
      name="sui_icon_coupon_discount_24px" 
      size="18px" 
    />
    <div class="coupon-tip-left">
      <div
        ref="couponTip"
        :class="isOverflow ? 'coupon-tip-overflow' : 'overflow-line2'"
      >
        <div
          class="text"
          :class="{ 'overflow-line2': isOverflow }"
          v-html="filterData?.addTip"
        ></div>
        <s-popover
          ref="popover"
          v-model="showPop"
          trigger="hover"
          class="pop-icon"
          theme="dark"
          placemen="bottom"
          :content="filterData?.questionMark"
        >
          <template #reference>
            <Icon
              name="sui_icon_doubt_12px_2"
              size="12px"
              color="rgba(0, 0, 0, 0.6)"
            />
          </template>
        </s-popover>
      </div>
      <div
        v-if="filterData?.endTimeStamp"
        class="count-down"
      >
        <span class="end-in">{{ filterData?.endTip }}</span>
        <count-down-time
          class="time"
          font-size="12px"
          not-hide
          not-reload
          :time-stamp="+filterData?.endTimeStamp"
          :show-type="'inline-promotion'"
        />
      </div>
    </div>
    <div
      v-if="filterData?.isCanAdd == '1'"
      class="add"
      @click="handleAddOn"
    >
      {{ language.SHEIN_KEY_PC_15215 }}
      <Icon 
        name="sui_icon_more_right_12px"
        :is-rotate="locals.GB_cssRight"
        size="12px"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CouponTips'
}
</script>

<script setup>
import { computed, ref, watch, nextTick, onMounted, defineProps } from 'vue'
import { useStore } from 'vuex'
import { Icon } from '@shein-aidc/icon-vue3'
import { useCartAddItem } from 'public/src/pages/cart_v2/hooks/useCartAddItem.js'
import countDownTime from 'public/src/pages/cart_v2/components/functional/common/CartCountDown.vue'

const AddItem = useCartAddItem()

const props = defineProps({
  filterData: {
    type: Object,
    default: () => {}
  },
  language: {
    type: Object,
    default: () => {}
  }
})

const store = useStore()
const locals = computed(() => store?.state?.locals)

// 达到最高门槛，触发一次气泡自动弹出
watch(props.filterData?.isCanAdd, (n, o) => {
  if (o == '1' && n !== '1') {
    if (window?.sessionStorage.getItem('CartCouponSatisfiedPop')) return
    autoShowPopover()
    window?.sessionStorage.setItem('CartCouponSatisfiedPop', true)
  }
})

const popover = ref(null)
const couponTip = ref(null)
const isOverflow = ref(false)
// 监听couponTip是否溢出
watch(() => props.filterData?.addTip, async(n, o) => {
  await nextTick()
  const popoverRect = popover.value?.$el.getBoundingClientRect()
  const couponTipRect = couponTip.value?.getBoundingClientRect()
  isOverflow.value = popoverRect.bottom > couponTipRect.bottom
})

// 气泡相关
const showPop = ref(false)
watch(showPop, (newVal) => {
  if (newVal) {
    daEventCenter.triggerNotice({ 
      daId: '1-7-3-55' 
    })
  }
}, { immediate: true })
/**
 * 气泡自动弹出逻辑
 * 一次会话最多弹两次，一次是选中标签时的， 一次是达到最高门槛时
 */
const autoShowPopover = () => {
  let countdown = 3
  showPop.value = true
  let intervalId = setInterval(() => {
    countdown -= 1
    if (countdown <= 0) {
      clearInterval(intervalId)
      showPop.value = false
    }
  }, 1000)
}

// 调用凑单组相关
const handleAddOn = () => {
  AddItem.show({
    coupon: props.filterData?.coupon,
    state: 'cart_filter_coupon_add',
    isClickToDetail: false,
  })
  daEventCenter.triggerNotice({
    daId: '1-7-3-54',
    extraData: {
      coupon_type: props.filterData?.labelId,
      level: props.filterData?.level
    }
  })
}

onMounted(async () => {
  await nextTick()
  if (window?.sessionStorage.getItem('CartCouponFirstPop')) return
  autoShowPopover()
  window?.sessionStorage.setItem('CartCouponFirstPop', true)
})

</script>

<style lang="less" scoped>
.cart-filter-coupon {
  width: 100%;
  display: inline-flex;
  align-items: flex-start;
  padding: 12px 16px;
  border: 0.5px solid rgba(250, 99, 56, 0.30);
  background: #FFF6F3;
  margin-top: 10px;
  .coupon-tip-left {
    flex: 1;
    margin: 0 8px;
    .text {
      display: inline;
      font-size: 13px;
      line-height: 15px;
    }
    .count-down {
      margin-top: 4px;
      display: flex;
      align-items: center;
    }
    .end-in {
      margin-right: 4px;
      font-size: 12px;
    }
    .cart__down-time {
      margin-top: 0
    }
  }
  .overflow-line2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    .text {
      margin-right: 4px;
    }
  }
  .coupon-tip-overflow {
    display: inline-flex;
    align-items: flex-end;
    .text {
      flex: 1;
      margin-right: 4px;
    }
    .pop-icon {
      width: 12px;
    }
  }
  .add {
    cursor: pointer;
    min-width: 44px;
    font-size: 13px;
    font-weight: 700;
    color: #222;
    font-family: Arial;
  }
}
.text {
  :deep(strong) {
    font-weight: normal;
    font {
      font-weight: bold;
    }
  }
}
</style>
