<template>
  <div
    :class="[className, 'shop-entry__storeFollowBtnBox']"
    @click="onClick"
  >
    <slot name="button"></slot>

    <!-- follow success popup dialog -->
    <SDialog
      v-model:visible="popupShow"
      show-close
      append-to-body
      type="W720"
    >
      <template #title>
        {{ langSource.SHEIN_KEY_PC_25387 }}
      </template>
      <template
        #subtitle
      >
        {{ langSource.SHEIN_KEY_PC_25388 }}
      </template>
      <div class="shop-entry__storeFollowTipsImgBox">
        <img
          :src="`${PUBLIC_CDN}/she_dist/images/store/follow-store-tips-af43b52732.png`"
        />
        <div class="shop-entry__modalBtnBox">
          <SButton
            :type="['primary']"
            @click="() => popupShow = false"
          >
            {{ langSource.SHEIN_KEY_PC_25376 }}
          </SButton>
        </div>
      </div>
    </SDialog>
    <!-- 取消关注挽留弹窗 -->
    <UnFollowDialog 
      ref="refUnFollowDialog"
      :append-to-body="true"
      :language="unFollowDialogLang"
      @confirm="unFollowDialogConfirm"
      @cancel="unFollowDialogCancel"
    />
  </div>
</template>

<script setup>
/**
 * @component StoreFollowBtnBox 店铺关注按钮外壳
 * * 处理店铺关注和取消关注逻辑，无vuex依赖
 */
import schttp from 'public/src/services/schttp'
import qs from 'qs'
import { isLogin as isLoginFn } from 'public/src/pages/common/utils/index.js'
import {
  ref,
  toRefs,
  defineEmits,
  computed,
  onMounted,
  nextTick,
} from 'vue'
import { SDialog } from '@shein-aidc/sui-dialog'
import { SButton } from '@shein-aidc/sui-button'
import { SMessage } from '@shein-aidc/sui-message'
import UnFollowDialog from 'public/src/pages/store_pages/components/follow/UnFollowDialog.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
const { PUBLIC_CDN, language: commonLang } = gbCommonInfo

// 是否展示过关注成功弹窗的key
const BEEN_POPUP_SHOW_KEY = 'beenPopupStoreFollowSuccess'

const emit = defineEmits([
  'stateChanged',
  'stateChanging',
  'stateChangeFail',
  'showLoginModal',
])

/**
 * props
 */
const props = defineProps({
  // 盒子类名
  className: {
    type: String,
    default: '',
  },
  // 场景
  // 店铺页: StoreDetail 商品详情页: ProductDetail
  scene: {
    type: String,
    default: '',
  },
  // 店铺关注状态
  followState: {
    type: Boolean,
    default: false,
  },
  // 店铺id
  storeId: {
    type: [String, Number],
    default: '',
  },
  // 未登录时是否自动登录
  autoLogin: {
    type: Boolean,
    default: false,
  },
  // 是否开启取消关注挽留弹窗
  hasUnFollowDialog: {
    type: Boolean,
    default: false,
  },
  language: {
    type: Object,
    default: () => ({}),
  }
})
const {
  // scene,
  followState,
  storeId,
  language,
} = toRefs(props)

/**
 * data
 */
const loading = ref(false)
const popupShow = ref(false)
const beenPopupShow = ref(false)
const unFollowDialogReady = ref(false)
const refUnFollowDialog = ref(null)

/**
 * computed
 */
// 多语言
const langSource = computed(() => {
  return language.value || commonLang || {}
})
// 挽留弹窗多语言
const unFollowDialogLang = computed(() => {
  return {
    'SHEIN_KEY_PC_33447': langSource.value.SHEIN_KEY_PC_33447 || 'Think again',
    'SHEIN_KEY_PC_33448': langSource.value.SHEIN_KEY_PC_33448 || 'After unfollowing, you will no longer receive these notifications:',
    'SHEIN_KEY_PC_33449': langSource.value.SHEIN_KEY_PC_33449 || 'Are you sure you want to unfollow?',
    'SHEIN_KEY_PC_33450': langSource.value.SHEIN_KEY_PC_33450 || 'Unfollow',
    'SHEIN_KEY_PC_33451': langSource.value.SHEIN_KEY_PC_33451 || 'promotions',
    'SHEIN_KEY_PC_33452': langSource.value.SHEIN_KEY_PC_33452 || 'new arrivals',
    'SHEIN_KEY_PC_25391': langSource.value?.SHEIN_KEY_PC_25391 || 'Unfollow Success',
  }
})
/**
 * methods
 */
// 点击关注
const onClick = () => {
  if (typeof window !== 'undefined' && typeof SHEIN_LOGIN !== 'undefined') {
    if(followState.value && props.hasUnFollowDialog){
      // 取消关注挽留弹窗
      unFollowDialogReady.value = true
      nextTick(() => {
        refUnFollowDialog.value?.show?.()
        daEventCenter.triggerNotice({
          daId: '1-8-6-259',
        })
      })
    }else{
      handleIsLoginFn()
    }
    
  }
}
const handleIsLoginFn = () => {
  const isLogin = isLoginFn() || false
  // 已登录直接调用接口
  if (isLogin) return handleOnClick()
  
  // 处理未登录
  handleNotLogin()
}
// 处理登录回调
const loginCallback = state => {
  if (String(state?.code) === '0') {
    setTimeout(() => {
      handleOnClick()
    }, 3e2)
  }
}
// 未登录逻辑处理
const handleNotLogin = () => {
  if (typeof window !== 'undefined' && window?.gbCommonInfo?.OPEN_DETAIL_LOGINED_RELOAD && window?.gbCommonInfo?.WEB_CLIENT === 'shein') {
    emit('showLoginModal', {
      type: 'StoreFollowBtnBox',
      callback: loginCallback,
    })
  }else {
    SHEIN_LOGIN?.show({
      cb: loginCallback,
      from: 'other',
    })
  }
}

// 点击关注
const handleOnClick = async function () {
  if (loading.value) return
  if (!storeId.value) return

  loading.value = true
  emit('stateChanging', true)
  const targetState = !followState.value
  try {
    const result = await schttp({
      url: '/product/store/update_following',
      method: 'POST',
      headers: { 
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: qs.stringify({
        action: followState.value ? 'unfollow' : 'follow',
        scene: 'detail',
        store_code_list: storeId.value,
      }),
      useBffApi: true
    })
    const { code } = result
    if (code === '0') {
      emit('stateChanged', targetState)
      // 首次关注成功，则弹出关注成功弹窗
      updateBeenPopupShow()
      if (targetState && !beenPopupShow.value) {
        popupShow.value = true
        beenPopupShow.value = true
        window.localStorage?.setItem(BEEN_POPUP_SHOW_KEY, '1')
      }

      // 取消关注提示
      if(!targetState && props.hasUnFollowDialog){
        SMessage({
          message: unFollowDialogLang.value?.SHEIN_KEY_PC_25391,
          type: 'success'
        })
      }
    } else {
      throw result
    }
  } catch (err) {
    const toastContent = targetState
      ? (String(err?.code) === '400429'
        ? langSource.value?.SHEIN_KEY_PC_25394 // 关注失败，关注数量超出上限
        : langSource.value?.SHEIN_KEY_PC_25393) // 关注失败，请稍后重试
      : langSource.value?.SHEIN_KEY_PC_25392 // 取消关注失败，请稍后重试
    nextTick(() => {
      SMessage({
        message: toastContent,
        type: 'error',
      })
    })
    emit('stateChangeFail', err)
  }
  if (loading.value) {
    loading.value = false
    emit('stateChanging', false)
  }
}
// 更新是否展示过关注成功弹窗状态
function updateBeenPopupShow () {
  if (typeof window === 'undefined') return
  beenPopupShow.value = !!window.localStorage?.getItem(BEEN_POPUP_SHOW_KEY)
}

const unFollowDialogConfirm = () => {
  handleIsLoginFn()
  daEventCenter.triggerNotice({
    daId: '1-8-6-260',
    extraData: {
      position: 'confirm'
    },
  })
}
const unFollowDialogCancel = () => {
  daEventCenter.triggerNotice({
    daId: '1-8-6-260',
    extraData: {
      position: 'cancal'
    },
  })
}
/**
 * hooks
 */
onMounted(() => {
  updateBeenPopupShow()
})
</script>

<style lang="less">
.shop-entry__storeFollowBtnBox {
  display: block;
  cursor: pointer;
}

.shop-entry__storeFollowTipsImgBox {
  width: 100%;

  img {
    display: block;
    width: 100%;
  }

  .shop-entry__modalBtnBox {
    margin-top: 32px;
    display: flex;
    justify-content: center;
  }
}
</style>
