<template>
  <div>
    <CouponRecommend
      v-if="isCoupon"
      v-show="recommendModel == '152'"
      :extend-params="extendParams"
      @change-model="changeModel"
    />
    <DefaultRecommend
      v-show="recommendModel == '128'"
      :extend-params="extendParams"
    />
  </div>
</template>

<script>
export default {
  name: 'RecommendCcc'
}
</script>

<script setup>
import DefaultRecommend from './DefaultRecommend.vue'
import CouponRecommend from './CouponRecommend.vue'
import { useStore } from 'vuex'
import { defineProps, ref, computed, watch } from 'vue'

const { state, getters }  = useStore()

defineProps({
  extendParams: {
    type: Object,
    default: () => ({})
  }
})

const isCartSelectRecommend = computed(() => getters.isCartSelectRecommend)
const selectFilterLabel = computed(() => state.filterState.selectFilterLabel)
let recommendModel = ref('128')

/**
 * 是否走券推荐
 * 命中abt开启状态 && 选中的筛选项为券标签
 */
const isCoupon = computed(() => {
  return isCartSelectRecommend.value && selectFilterLabel.value?.groupClassId == 'coupon'
})
watch(() => isCoupon.value, (newVal) => {
  recommendModel.value = newVal ? '152' : '128'
  // eslint-disable-next-line no-console
  console.log('[cart&recommend] recommendModel = ', recommendModel.value)
})

const changeModel = (model) => {
  recommendModel.value = model
}
</script>
