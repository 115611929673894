<template>
  <!-- 骨架屏 -->
  <div 
    class="list-skeleton"
    :class="type"
  >
    <div 
      v-for="i in num"
      :key="i"
      class="list-skeleton__item"
      :style="itemStyle"
    >
      <!-- 多列商品 -->
      <template v-if="type === 'goodsBroadwise'">
        <div 
          class="item__main skeleton-bg"
          :style="bgStyle"
        ></div>
        <div class="item__info-one skeleton-bg"></div>
        <div class="item__info-two skeleton-bg"></div>
      </template>

      <!-- 单列商品 -->
      <template v-else-if="type === 'goodsOne'">
        <div class="item__main skeleton-bg border-r-4"></div>
        <div class="item__info">
          <div class="item__info-title skeleton-bg border-r-4"></div>
          <div class="item__info-price skeleton-bg border-r-4"></div>
          <div class="item__info-service">
            <div class="skeleton-bg border-r-4"></div>
            <div class="skeleton-bg border-r-4"></div>
            <div class="skeleton-bg border-r-4"></div>
          </div>

          <div class="item__info-bottom">
            <div class="item__info-select skeleton-bg border-r-4"></div>
            <div class="item__info-btn skeleton-bg border-r-4"></div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ProductListSkeleton',
  props: {
    // goodsOne 单列商品
    // goodsBroadwise 多列商品
    type: {
      type: String,
      default: 'goodsBroadwise'
    },
    num: {
      type: Number,
      default: 1
    },
    itemStyle: {
      type: Object,
      default: () => {}
    },
  },
  computed: {
    ...mapState(['IS_RW', 'LAZY_IMG_SOLID_COLOR']),
    bgStyle () {
      return {
        backgroundImage: `url(${this.LAZY_IMG_SOLID_COLOR})`,
        backgroundRepeat: 'repeat',
      }
    }
  }
}
</script>

<style lang="less">
@skeleton-bg: @sui_color_gray_weak1a;
@rw-skeleton-bg: #fafafa;

.list-skeleton {

  .skeleton-bg {
    background: @skeleton-bg;
    /* rw:begin */
    background: @rw-skeleton-bg;
  }

  .border-r-4 {
    border-radius: 4px;
  }
}

// 多列商品
.goodsBroadwise {

  margin: 0 -10px;

  .flexbox();
  flex-wrap: wrap;

  .list-skeleton__item {
    padding: 0 10px 48px;
    @media (max-width: 768px) {
      width: var(--list-skeleton-width-max-768, 50%);
    }
    @media (min-width: 768px) {
      width: var(--list-skeleton-width-min-768, 33.33%);
    }
    @media (min-width: 1200px) {
      width: var(--list-skeleton-width-min-1200, 25%);
    }
    @media (min-width: 1800px) {
      width: var(--list-skeleton-width-min-1800, 20%);
    }
    @media (min-width: 2300px) {
      width: var(--list-skeleton-width-min-2300, 16.66%);
    }
  }
  .item {
    &__main {
      width: 100%;
      height: 0;
      padding-bottom: 133.082%;
    }
    &__info-one {
      width: 100%;
      height: 20px;
      margin-top: 8px;
    }
    &__info-two {
      width: 40%;
      height: 20px;
      margin-top: 6px;
    }
  }
}

// 单列商品
.goodsOne {
  .list-skeleton__item {
    display: flex;
    height: 160px;
  }

  .item__main {
    flex: 0 0 120px;
    flex-basis: var(--product-card-top-wrapper-flex-basis, 120px);

    margin-left: 12px;
    margin-right: 12px;
  }

  .item__info {
    flex: 1 1 auto;

    display: flex;
    flex-direction: column;
  }

  .item__info-title {
    height: 14px;
  }

  .item__info-price {
    margin-top: 4px;

    width: 120px;
    height: 20px;
  }

  .item__info-service {
    margin-top: 6px;
    display: flex;
    div {
      margin-right: 4px;
      width: 60px;
      height: 20px;
    }
  }

  .item__info-bottom {
    flex: 1 1 auto;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .item__info-select {
    width: 40px;
    height: 14px;
  }

  .item__info-btn {
    width: 44px;
    height: 28px;

    border-radius: 14px;
  }
}

</style>
