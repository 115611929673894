import { template } from '@shein/common-function'

// https://wiki.dotfashion.cn/pages/viewpage.action?pageId=1554861702
export const genStockTips = (currentMall = {}, fsAbt = {}, fsTspAbt = {}, language = {}, config = {}) => {
  const { 
    stock, // 库存数
    is_absolute_low_inventory, // 商品实际库存数 <= 低库存阈值(绝对低库存)
    is_relatively_low_inventory, // 实际可售天数 <= 可售天数阈值(相对低库存)
    can_sale_days, // 实际可售天数
  } = currentMall || {}

  if (stock <= 0) return ''

  const { detailsalmostsoldout = 14 } = fsAbt?.outofstocktips?.param || {}
  // 可售天数阈值(取不到则默认为-1, 即永远不为相对低库存)
  const almost_sold_out_threshold = config?.almostSoldOutThreshold || (detailsalmostsoldout !== 'off' ? Number(detailsalmostsoldout) || -1 : -1)

  const { sold_out_in_X_days } = fsTspAbt?.Detailsalmostsoldout?.param || {}
  const matchA = (sold_out_in_X_days || '').match(/^A:(\d+)$/) 
  const matchB = (sold_out_in_X_days || '').match(/^B:(\d+)$/) 
  const soldOutDaysA = +matchA?.[1]
  const soldOutDaysB = +matchB?.[1]
  const isAType = !isNaN(soldOutDaysA)
  const isBType = !isNaN(soldOutDaysB)
  const genTips = (leftTips = '') => {
    const soldOutDays = isBType ? soldOutDaysB : isAType ? (soldOutDaysA || soldOutDaysB) : undefined

    if (is_relatively_low_inventory && 
      !isNaN(soldOutDays) && can_sale_days > 0 &&
      soldOutDays >= can_sale_days && almost_sold_out_threshold >= soldOutDays  
    ) {

      const genResult = (almostText) => {
        if (!leftTips) return almostText
        if (isAType) return leftTips
        if (isBType) return leftTips + (!config?.isFromAddCartBtn ? `, ${almostText}` : '')
      }

      if (can_sale_days <= 1) {
        const almostText = `${language.SHEIN_KEY_PC_33513 || 'May Sell Out Today!'}`
        return genResult(almostText)
      }
      if (can_sale_days <= 2 && can_sale_days > 1) {
        const almostText = `${language.SHEIN_KEY_PC_33514 || 'May Sell Out Tomorrow!'}`
        return genResult(almostText)
      }
      if (can_sale_days > 2) {
        const almostText = `${template(Math.ceil(can_sale_days) + '', language.SHEIN_KEY_PC_33515 || 'Gone in {0} Days!')}`
        return genResult(almostText)
      }
    }
    return ''
  }

  if (is_absolute_low_inventory) {
    const leftTips = template(stock, language.SHEIN_KEY_PC_16400)
    // 加车按钮里的文案不拼接
    const stockTips = genTips(leftTips)
    return stockTips || leftTips
  } 

  if (is_relatively_low_inventory) {
    const stockTips = genTips('')
    return stockTips || language.SHEIN_KEY_PC_33510 || 'Almost sold out！'
  }

  return ''
}
