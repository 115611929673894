<template>
  <span
    data-v-4d906883=""
    class="sui-icon-common__wrap icon__container__preview__container"
    style="vertical-align: middle; display: inline-block; line-height: 0; font-size: 24px;">
    <svg
      style="width: auto; height: 1em;"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.46026 6.50512C4.46026 5.79424 5.03655 5.21795 5.74744 5.21795H10.2526C10.9635 5.21795 11.5397 5.79424 11.5397 6.50512V9.07948C11.5397 9.79037 10.9635 10.3667 10.2526 10.3667H5.74744C5.03655 10.3667 4.46026 9.79037 4.46026 9.07948V6.50512ZM5.10385 6.50512C5.10385 6.14968 5.39199 5.86154 5.74744 5.86154H10.2526C10.608 5.86154 10.8962 6.14968 10.8962 6.50512V9.07948C10.8962 9.43493 10.608 9.72307 10.2526 9.72307H5.74744C5.39199 9.72307 5.10385 9.43493 5.10385 9.07948V6.50512Z"
        fill="#666666" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.57383 7.71701C8.57383 7.86773 8.6337 8.01228 8.74028 8.11886C8.84685 8.22544 8.9914 8.28531 9.14212 8.28531C9.29285 8.28531 9.43739 8.22544 9.54397 8.11886C9.65055 8.01228 9.71042 7.86773 9.71042 7.71701C9.71042 7.64238 9.69572 7.56848 9.66716 7.49953C9.6386 7.43058 9.59674 7.36794 9.54397 7.31516C9.4912 7.26239 9.42855 7.22053 9.3596 7.19197C9.29065 7.16341 9.21675 7.14871 9.14212 7.14871C9.06749 7.14871 8.99359 7.16341 8.92465 7.19197C8.8557 7.22053 8.79305 7.26239 8.74028 7.31516C8.6875 7.36794 8.64564 7.43058 8.61708 7.49953C8.58853 7.56848 8.57383 7.64238 8.57383 7.71701Z"
        fill="#666666" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.06923 7.71701C6.06923 7.86773 6.12911 8.01228 6.23568 8.11886C6.34226 8.22544 6.48681 8.28531 6.63753 8.28531C6.78825 8.28531 6.9328 8.22544 7.03938 8.11886C7.14595 8.01228 7.20583 7.86773 7.20583 7.71701C7.20583 7.56629 7.14595 7.42174 7.03938 7.31516C6.9328 7.20859 6.78825 7.14871 6.63753 7.14871C6.48681 7.14871 6.34226 7.20859 6.23568 7.31516C6.12911 7.42174 6.06923 7.56629 6.06923 7.71701Z"
        fill="#666666" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.8143 3.53976C12.8031 4.49329 13.3977 5.7812 13.4846 7.14871H13.8564C14.2119 7.14871 14.5 7.43686 14.5 7.7923V9.72307C14.5 10.0785 14.2119 10.3667 13.8564 10.3667L13.5376 10.3667C13.4767 10.7052 13.3273 11.0392 13.1379 11.3449C12.8844 11.754 12.5355 12.1515 12.1401 12.4991C11.579 12.9923 10.8762 13.4256 10.1619 13.6265C10.1799 13.5929 10.1955 13.5577 10.2084 13.5213C10.102 13.8206 9.81609 14.035 9.48025 14.035H8.45051C8.02398 14.035 7.67821 13.6892 7.67821 13.2627C7.67821 12.8362 8.02398 12.4904 8.45051 12.4904H9.48025C9.82512 12.4904 10.1174 12.7164 10.2166 13.0284C10.1722 12.8887 10.089 12.7661 9.97979 12.6735C10.4804 12.5163 11.0236 12.1952 11.5027 11.774C11.8395 11.4779 12.1222 11.1514 12.3172 10.8365C12.4467 10.6275 12.5281 10.4393 12.5692 10.2789V7.18411C12.4907 6.02758 11.9768 4.94365 11.1308 4.15101C10.282 3.35582 9.16269 2.91334 7.9998 2.91334C6.83691 2.91334 5.71757 3.35582 4.86885 4.15101C4.02425 4.94233 3.5106 6.02397 3.43077 7.17834L3.43077 10.3667H2.14359C1.78814 10.3667 1.5 10.0785 1.5 9.72307V7.7923C1.5 7.43686 1.78814 7.14871 2.14359 7.14871H2.51504C2.60187 5.7812 3.19655 4.49329 4.18531 3.53976C5.20961 2.55195 6.57698 2 7.9998 2C9.42262 2 10.79 2.55195 11.8143 3.53976Z"
        fill="#666666" />
    </svg>
  </span>
</template>
