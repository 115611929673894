<template>
  <div class="product-card__price">
    <!-- 价格组件主体 -->
    <div 
      class="product-card__prices-info fsp-element" 
    >
      <ProductCardNormalPrice
        v-if="showNormalPrice"
        :is-promo-price="isPromoPrice"
        :sold-out="soldOut"
        :belt-label="beltLabel"
      />

      <ProductCardPriceV2BottomLabel
        v-if="belowPriceLabel.show && priceV2LabelPosition === 'priceRight'"
      />

      <!-- 价格右侧标签 -->
      <ProductCardPriceRight />
    </div>

    <!-- 价格下方各种标签 start -->
    <div class="product-card__prices-bottom">
      <!-- 新划线价（合规价） -->
      <SuggestedSalePrice
        v-if="finalPriceInfo.priceBottom.show"
        :style="{ maxWidth: '100%', minWidth: 0 }"
        :value="finalPriceInfo.priceBottom.priceData"
        :label-text="finalPriceInfo.priceBottom.labelText"
        :flip-text="finalPriceInfo.priceBottom.flipText"
        :price-delete-style="finalPriceInfo.priceBottom.priceDeleteStyle"
      />

      <!-- 建议零售价 RRP价
          竖版商卡 为保持加车按钮居中，合规价显示时此处不显示
          横版不影响 -->
      <SuggestedSalePriceContainer
        v-if="finalPriceInfo.priceBottomRrp.show"
        :goods-info="goodsInfo"
        :price-data="finalPriceInfo.priceBottomRrp"
      />

      <ProductCardPriceV2BottomLabel
        v-if="belowPriceLabel.show && priceV2LabelPosition === 'priceBottom'"
      />

      <!-- 价格优势组件 -->
      <PriceDiscountIntro
        v-else-if="showPriceDiscountDesc"
        :config="config"
        :historical-price="historicalPrice"
        :follow-price="followPrice"
        :bff-goods-info="bffGoodsInfo"
      />

      <!-- vip price -->
      <section
        v-if="exclusivePromotionPrice"
        class="vip-price"
        tabindex="0"
      >
        <span>{{ exclusivePromotionPrice }}</span>
        <i
          :class="[
            'vip-price__icon',
            cssRight ? 'vip-price__icon_ar' : '',
          ]"
        ></i>
      </section>
    </div>
    <!-- 价格下方各种标签 end -->
  </div>
</template>

<script>
import ProductCardNormalPrice from './ProductCardNormalPrice.vue'
import ProductCardPriceRight from './ProductCardPriceRight.vue'
import PriceDiscountIntro from './PriceDiscountIntro.vue'
import ProductCardPriceV2BottomLabel from './ProductCardPriceV2BottomLabel.vue'
import SuggestedSalePriceContainer from './SuggestedSalePriceContainer.vue'
import SuggestedSalePrice from './SuggestedSalePrice.vue'

import { views } from '../../utils/constant.js'

export default {
  name: 'ProductCardPricesContainer',
  components: {
    PriceDiscountIntro,
    ProductCardNormalPrice,
    ProductCardPriceV2BottomLabel,
    ProductCardPriceRight,
    SuggestedSalePrice,
    SuggestedSalePriceContainer
  },
  inject: ['constantData'],
  props: {
    showSelect: {
      type: Boolean,
      default: false,
    },
    isPromoPrice: {
      type: Boolean,
      default: false
    },
    soldOut: {
      type: Boolean,
      default: true,
    },
    beltLabel: {
      type: [Object, Boolean],
      default: () => ({}),
    }
  },
  data() {
    return {
      cssRight: false
    }
  },
  created() {
    this.cssRight = this.constantData.GB_cssRight

  }
}
</script>

<script setup>

import { inject, computed } from 'vue'

const goodsInfo = inject('goodsInfo')
const finalPriceAndDiscountInfo = inject('finalPriceAndDiscountInfo')
const labelsFromKey = inject('labelsFromKey')
const config = inject('config')

const exclusivePromotion = computed(() => {
  return goodsInfo.value?.exclusivePromotionPrice || {}
})

// 旧版s3会员标签
const exclusivePromotionPrice = computed(() => {
  // 合规站点不展示s3会员标签
  const show = !config.showS3VipPriceOnSale && !goodsInfo.value?.pretreatInfo?.suggestedSalePriceConfigInfo?.type && !showNewStyleEstimated.value  && exclusivePromotion.value?.amountWithSymbol
  return show ? exclusivePromotion.value?.amountWithSymbol : ''
})

const historicalPrice = computed(() => {
  return goodsInfo.value[labelsFromKey]?.historicalPrice
})

const followPrice = computed(() => {
  return goodsInfo.value[labelsFromKey]?.followPrice
})

const showPriceDiscountDesc = computed(() => {
  if ( hideTargetBySuggestedSalePrice.value ) {
    return false
  }
  const { showPriceDiscountDesc, showPriceDiscountDescInDiscountLabel, showDiscountLabelCountdownStyle } = config || {}

  const { estimatedPriceInfo } = goodsInfo.value || {}
  const { finalPriceType, priceStateCollection } = finalPriceAndDiscountInfo.value || {}

  // 没命中到手价倒计时展示到商品名称前，并且最低价在折扣标签里展示
  const priceDiscountDescBeforeGoodsName = historicalPrice.value?.length && showPriceDiscountDescInDiscountLabel &&
    !(finalPriceType === 'estimatedPrice' && !priceStateCollection?.hasFlashPromotion && estimatedPriceInfo.endTime &&
    showDiscountLabelCountdownStyle)

  return (historicalPrice.value?.length || followPrice.value) &&
    !belowPriceLabel.value.show && showPriceDiscountDesc && !priceDiscountDescBeforeGoodsName
})

const showNormalPrice = computed(() => {
  return !config.hideNormalPrices
})

const showNewStyleEstimated = computed(() => {
  const { priceStateCollection } = finalPriceAndDiscountInfo.value || {}

  return config.showNewStyleEstimated && priceStateCollection?.hasEstimatedPrice
})

const priceV2LabelPosition = computed(() => {
  if (showNewStyleEstimated.value) {
    return 'priceBottom'
  } else {
    const priceV2LabelPosition = config.PriceV2LabelPosition || 'priceBottom'
    return priceV2LabelPosition
  }
})

const belowPriceLabel = computed(() => {
  if (hideTargetBySuggestedSalePrice.value) return { show: false }

  const { priceLabelText, finalPrice } = finalPriceAndDiscountInfo.value || {}

  return {
    show: showNewStyleEstimated?.value || priceLabelText || finalPrice?.flashTypeText
  }
})

const hideTargetBySuggestedSalePrice = computed(() => {
  const { suggestedSalePriceData } = finalPriceAndDiscountInfo.value || {}

  return suggestedSalePriceData?.config?.hideTargetBySuggestedSalePrice
})

const bffGoodsInfo = computed(() => {
  return goodsInfo.value.bffGoodsInfo
})

const finalPriceInfo = computed(() => {
  const { priceBottom, priceBottomRrp } = finalPriceAndDiscountInfo.value || {}

  return {
    priceBottom,
    priceBottomRrp: priceBottomRrp?.show && (!priceBottom?.show || labelsFromKey === views.LIST_PC_BROADWISE_VIEW) ? priceBottomRrp : {}
  }
})

</script>

<style lang="less">
.product-card__prices-info {
  & > div:not(.normal-price-ctn__prices), & > p {
    margin-right: 6px;
  }
}
</style>

<style lang="less" scoped>
@s3_exclusive: '/she_dist/images/sheinvip2/s3_exclusive-3286f50076.png';
@s3_exclusive_ar: '/she_dist/images/sheinvip2/s3_exclusive_ar-7c299f990d.png';


.product-card {
  /* stylelint-disable-next-line block-no-empty */
  &__price {
    min-width: 0;
  }
  &__prices-info {
    .flexbox();

    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    &_camel {
      height: 23px;
    }
  }
  &__prices-bottom {
    max-width: 100%;
    width: fit-content;

    // 合规价组件超长样式适配
    :deep(.prices-info__suggested-sale-price) {
      max-width: 100%;

      /* stylelint-disable-next-line selector-max-specificity */
      .popover-container.sui-popover__trigger {
        display: flex;
      }
      /* stylelint-disable-next-line selector-max-specificity */
      .suggested-popover {
        min-width: 0
      }
    }
  }

  .price-overflow-hidden {
    overflow: hidden;
  }

  .price-height-21 {
    height: 21px;
  }
}

// 付费会员 start
.shein-club {
  .flexbox();
  .align-center();
  flex-wrap: wrap;
  margin-top: 4px;
  &__prime-price-ctn {
    .flexbox();
    .align-center();
  }
  &__logo {
    width: 15px;
    margin-right: 3px;
  }
  &__prime-price {
    font-size: 14px;
    line-height: 16px;
    margin-right: 4px;
    color: #ffba97;
    font-weight: bold;
  }
  &__sale-price {
    font-size: 12px;
    line-height: 14px;
    color: @sui_color_gray_light1;
    text-decoration: line-through;
  }
}
// 付费会员 end

// vip price start
.vip-price {
  margin-top: 4px;
  padding-bottom: 12px;
  height: 14px;
  .txt-l();

  > span {
    font-size: 12px;
    font-weight: bold;
    color: #a78a45;
  }

  &__icon {
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 34px 10px;
    width: 34px;
    height: 10px;
    background-image: url(@s3_exclusive);

    &_ar {
      background-image: url(@s3_exclusive_ar);
      vertical-align: -1px;
    }
  }
}
.rw-vip-price {
  margin-top: 4px;
  padding-bottom: 4px;
}

.last-price {
  font-size: 12px;
  line-height: 12px;
  color: #fff;
  transform: scale(0.84);
}
</style>
