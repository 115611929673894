<template>
  <div class="reviews-skeleton">
    <s-skeleton
      animated
      :rows="8"
    >
      <template #template>
        <s-skeleton-item
          variant="h1"
          style="width: 40%"
        />
        <s-skeleton-item
          variant="p"
          style="height: 150px"
        />

        <s-skeleton-item
          variant="p"
          style="width: 30%;"
        />
        <s-skeleton-item
          variant="p"
        />
        <s-skeleton-item
          variant="p"
        />
        <div class="reviews-skeleton__comment">
          <s-skeleton-item
            variant="p"
            style="width: 20%; display: block;"
          />
          <s-skeleton-item
            variant="p"
            style="width: 70%;"
          />
          <div class="flex">
            <div class="reviews-skeleton__content">
              <s-skeleton-item
                variant="p"
                style="width: 80%;"
              />
              <s-skeleton-item
                variant="p"
                style="width: 80%;"
              />
              <s-skeleton-item
                variant="p"
                style="width: 70%;"
              />
            </div>
            <s-skeleton-item
              variant="image"
              style="width: 98px; height: 98px;flex-shrink: 0"
            />
          </div>
        </div>
      </template>
    </s-skeleton>
  </div>
</template>
<script setup>
import { SSkeleton } from '@shein-aidc/sui-skeleton/laptop'

const { SSkeletonItem } = SSkeleton
</script>
<style lang="less" scoped>
.reviews-skeleton {
  padding-top: 50px;
  .sui-skeleton__item {
    margin-bottom: 16px;
  }
  .flex {
    display: flex;
  }
  &__content {
    width: 100%;
  }
}

</style>
