import { daEventCenter } from 'public/src/services/eventCenter/index'

export const expose_delete_tips = (data, callback) => {
  const defaultData = {
    actual_point: '-',
    type_id: data.promotion_type_id || '',
    save_money: data.single_discount_price?.usdAmountWithSymbol || '-',
    available_point: data.single_discount_price?.amount > 0,
    sence: data.is_checked == '1' ? 1 : 2
  }
  daEventCenter.triggerNotice({ daId: '1-7-3-37', extraData: callback?.(defaultData) ?? defaultData })
}

const getExtraData = (data) => {
  const { singleDeleteStayPopUp } = data?.aggregateProductBusiness || {}
  let actual_point = '-'
  let available_point = []
  let sence = ''
  if (singleDeleteStayPopUp?.save_money) {
    actual_point = 1
  } else if (singleDeleteStayPopUp?.stayType == '4') {
    actual_point = 2
  }

  if (singleDeleteStayPopUp?.save_money) {
    available_point.push(1)
  }
  if (singleDeleteStayPopUp?.stayType == '4') {
    available_point.push(2)
    sence = 3
  } else {
    sence = data.is_checked == '1' ? 1 : 2
  }
  return {
    actual_point,
    type_id: singleDeleteStayPopUp?.type_id || '',
    save_money: singleDeleteStayPopUp?.save_money?.usdAmountWithSymbol || '-',
    available_point: available_point.join(',') || '-',
    sence
  }
}
export const expose_ckretaindelete_tips = (data, callback) => {
  const defaultData = getExtraData(data)
  daEventCenter.triggerNotice({ daId: '1-7-3-41', extraData: callback?.(defaultData) ?? defaultData })
}

export const click_ckretaindelete_tips = (data, callback) => {
  const defaultData = getExtraData(data)
  daEventCenter.triggerNotice({ daId: '1-7-3-42', extraData: callback?.(defaultData) ?? defaultData })
}

export const click_goods_delete = (data, callback) => {
  const defaultData = getExtraData(data)
  daEventCenter.triggerNotice({ daId: '1-7-3-38', extraData: callback?.(defaultData) ?? defaultData })
}

export const click_deleteproductsno = (data, callback) => {
  const defaultData = {}
  daEventCenter.triggerNotice({ daId: '1-7-7-4', extraData: callback?.(defaultData) ?? defaultData })
}

export const click_deleteproductsyes = (data, callback) => {
  const defaultData = {
    carts: '',
    batchIds: '',
    result: ''
  }
  daEventCenter.triggerNotice({ daId: '1-7-7-5', extraData: callback?.(defaultData) ?? defaultData })
}

export const expose_popup_itemdeleted = (data, callback) => {
  const defaultData = {
    // 整车商品行列表
    carts: '',
    // 批量选择的商品行id
    batchIds: '',
  }
  daEventCenter.triggerNotice({ daId: '1-7-7-6', extraData: callback?.(defaultData) ?? defaultData })
}

export const expose_popup_deletefail = (data, callback) => {
  const defaultData = {
    // 整车商品行列表
    carts: '',
    // 批量选择的商品行id
    batchIds: '',
  }
  daEventCenter.triggerNotice({ daId: '1-7-7-1', extraData: callback?.(defaultData) ?? defaultData })
}
