import schttp from 'public/src/services/schttp/index'
import { checkFirstPopup } from './utils'

export function getCouponPackageDataApi(params) {
  return schttp({
    url: '/promotion/coupon/combine_coupon',
    method: 'GET',
    useBffApi: true,
    params: {
      firstPopup: checkFirstPopup(),
      blackBox: window?._fmOpt?.__blackbox || '',
      version: 'v2',
      ...params
    }
  })
}

export function bindCouponPackageApi(params) {
  return schttp({
    url: '/promotion/coupon/bind_coupon',
    method: 'POST',
    useBffApi: true,
    data: params
  })
}

export function getRemindCouponPackageDataApi(params) {
  return schttp({
    url: '/promotion/member_coupon_list',
    method: 'GET',
    useBffApi: true,
    params
  })
}

export function getLanguageByKeysApi(keys) {
  return schttp({
    url: '/system/configs/multi_language',
    method: 'POST',
    data: {
      languageKeys: keys
    },
    useBffApi: true
  })
}

export function getLanguageByPagesApi(pages) {
  return schttp({
    url: '/system/configs/page_multi_language_mapping',
    method: 'POST',
    data: {
      pages
    },
    useBffApi: true
  })
}

export function getAbtDataApi(posKeys) {
  return schttp({
    url: '/abt/merge/get_abt_by_poskey',
    params: {
      posKeys
    },
    useBffApi: true
  })
}

export const fetchAnimationDataApi = async CouponLottieUrl => {
  if (!CouponLottieUrl) return Promise.resolve()
  const res = await fetch(CouponLottieUrl)
  return res.json()
}
