<template>
  <div
    class="warehouse-container"
  >
    <div class="warehouse-content">
      <slot></slot>
      <div class="warehouse-title">
        <span
          class="cart__mall-name"
          :title="mallName"
        >
          {{ mallName }}
        </span>
        <s-label
          v-if="mallDesc"
          type="success"
          style="font-weight: normal"
        >
          {{ mallDesc }}
        </s-label>
      </div>
    </div>
    <mall-shipping
      :mall-code="mallCode"
      :language="language"
      :shipping-activity-data="shippingActivityData"
      :locasl="locals"
      class="mall-section-item"
    />
    <FullPromotionInfoTip
      v-if="fullPromotion.fullCourtMap && fullPromotion.fullCourtMap.length"
      :mall-code="mallCode"
      :promotions="fullPromotion.fullCourtMap"
      root-class-name="mall-section-item"
      class="mall-section-item"
    />
    <template v-for="promotion in fullPromotion.additionGoodsPromotionList">
      <full-gift-header
        v-if="promotion.data.type_id == 4"
        :key="promotion.data.promotion_id"
        :promotion-info="promotion"
        :is-single-mall="false"
        class="mall-full-promotion"
      />
      <full-add-on-header
        v-if="promotion.data.type_id == 22"
        :key="promotion.data.promotion_id"
        :promotion-info="promotion"
        :is-single-mall="false"
        class="mall-full-promotion"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'MallHeader',
}
</script>
<script setup>
import MallShipping from './MallShipping.vue'
import FullPromotionInfoTip from '../cart-top/FullPromotionInfoTip.vue'
import FullGiftHeader from './FullGiftHeader.vue'
import FullAddOnHeader from './FullAddOnHeader.vue'
import { computed, defineProps } from 'vue'
const props = defineProps({
  mallName: {
    type: String,
    default: ''
  },
  mallDesc: {
    type: String,
    default: ''
  },
  promotionData: {
    type: Array,
    default: () => ([])
  },
  shippingActivityData: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  },
  locals: {
    type: Object,
    default: () => ({})
  },
  mallCode: {
    type: String,
    default: ''
  }
})

const fullPromotion = computed(() => {
  let promotionList = []
  let additionGoodsPromotionList = []
  props.promotionData?.forEach(item => {
    item?.contentData?.forEach(content => {
      if([14, 21, 26].includes(+content?.groupHeadInfo?.data?.type_id)){
        promotionList.push(content.groupHeadInfo)
      } else {
        additionGoodsPromotionList.push(content.groupHeadInfo)
      }
    })
  })
  return {
    fullCourtMap: promotionList,
    additionGoodsPromotionList
  }
})
</script>

<style lang="less">
.warehouse-container{
  margin-top: 5px;
  .warehouse-title{
    font-weight: 900;
    color: #222222;
    font-size: 20px;
    display: flex ;
    flex: 1;
    align-items: center;
    .cart__mall-name{
      font-size: 18px;
      font-weight: 700;
      .clamp(1);
      margin-right: 4px;
    }
  }
  .warehouse-content{
    display: flex;
    align-items: center;
    padding: 16px 16px 8px;
    background-image: url("/she_dist/images/cart/sui_img_mallbg_checkout@2x-79fcffa40a.png") /* rtl: url("/she_dist/images/cart/sui_img_mallbg_checkout_ar@2x-1a97bcbc9d.png") */;
    background-size: 100%;
  }
  .mall-full-promotion{
    padding: 0;
  }
  .platform-header-section{
    padding: 12px 12px 12px 16px;
    .operation{
      color: #222222;
      font-weight: 700;
      font-size: 12px;
      cursor: pointer;
    }
  }
  .mall-section-item{
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 1px;
    /* stylelint-disable-next-line selector-max-specificity */
    section.section.promotion-sec {
      padding: 12px 0;
    }
    .full-activity-contain {
      margin-top: unset;
    }
    // &:last-child {
    //   padding-bottom: unset;
    // }
  }
  .mall-section-item:not(.shipping-header-container) {
    background:@sui_color_promo_bg;
  }
}
</style>

