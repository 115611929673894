<template>
  <div
    v-if="isShowFilterTips"
    class="voucher-filter-tip"
  >
    <div
      class="voucher-tip-content"
      v-html="filterData?.addTip"
    >
    </div>
    <div
      class="filter-close-icon"
      @click="handleClose"
    >
      <Icon
        name="sui_icon_close_16px_1"
        size="16px"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VoucherTips',
}
</script>

<script setup>
import { defineProps, onMounted, ref } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'

defineProps({
  filterData: {
    type: Object,
    default: () => {}
  },
})

const isShowFilterTips = ref(false)

onMounted(() => {
  if (typeof window !== 'undefined') {
    let voucherFilterTipShowed = Boolean(localStorage.getItem('voucherFilterTipShowed'))
    if (voucherFilterTipShowed) { // show only once
      isShowFilterTips.value = false
    } else { // show only once
      isShowFilterTips.value = true
    }
  }
})

const handleClose = () => {
  isShowFilterTips.value = false
  localStorage.setItem('voucherFilterTipShowed', true) // 关闭后不再展示
}
</script>
<style lang="less" scoped>
.voucher-filter-tip {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  position: relative;
  margin-top: 10px;
  padding: 1px 12px;
  border-radius: 4px;
  background: linear-gradient(72deg, rgba(255, 255, 255, 0.20) 75%, rgba(236, 215, 253, 0.20) 95.75%), linear-gradient(180deg, #E5F1FF -21.59%, #F7FAFF 100%);
  box-shadow: 0px 1px 1px 0px #FFF inset;
  color: #959595;
  .voucher-tip-content {
    margin: 10px 0;
    font-size: 12px;
    color: #767676;
    .line-camp(2);
  }
  .filter-close-icon {
    cursor: pointer;
  }
}
</style>
