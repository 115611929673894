import qs from 'query-string'
// 设置 曲奇
import { isFunction, isUnDef, setCookie, removeCookie, getCookie } from '@shein/common-function'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { abtservice } from '@/public/src/services/abt'
import MD5 from 'md5'

/**
 * 获取商品详情URL
 */
export async function getProductDetailUrl ({ langPath = '', imgSrc, goods_url_name = 'product', goods_id, cat_id, mall_code, productDetailUrl = '', urlQuery = {}, getUrlWithImgRatio = false } = {}) {
  // 商详url
  let url = langPath + (productDetailUrl || getGoodsUrl(goods_url_name, goods_id, cat_id))

  let imgRatioString = ''

  try {
    imgRatioString = await getImageRatio(imgSrc)
  } catch (error) {
    console.error(error)
  }

  // query
  const queryArr = []
  // 1. 固定的
  if (mall_code) queryArr.push(`mallCode=${mall_code}`)
  // 2. 图片比例
  queryArr.push(`imgRatio=${imgRatioString}`)

  // 3. 自定义的
  if (urlQuery && Object.prototype.toString.call(urlQuery) === '[object Object]') {
    Object.keys(urlQuery).forEach((field) => {
      if(urlQuery[field]) {
        let params = urlQuery[field]
        params = String(params).replace(/&gt;/g, '>')
        queryArr.push(`${field}=${params}`)
      }
    })
  }

  // 拼上query
  if (queryArr.length > 0) {
    url += `${url.indexOf('?') > -1 ? '&' : '?'}${queryArr.join('&')}`
  }

  if (getUrlWithImgRatio) {
    return {
      url,
      imgRatio: imgRatioString
    }
  }

  return url
}

/**
 * 根据图片地址获取图片匹配的字符串比率
 * 注意这里是 宽/高 的比率
 */
export function getImageRatio(imgSrc = '') {
  return new Promise((resolve, reject) => {
    const image = new Image()
    // Apollo
    const { RESOURCE_SDK } = gbCommonInfo
    if  (RESOURCE_SDK && RESOURCE_SDK.isSupportCropImage) {
      imgSrc = imgSrc.replace(/(_square)|(_squfix)/ig, '')
    }
    image.src = imgSrc
    image.onload = (e) => {
      const width = e.target.width
      const height = e.target.height
      const ratioString = closestRatio(width / height)

      resolve(ratioString)
    }

    image.onerror = reject
  })
}

/**
 * 根据真实图片的比率映射比例字符串
 * 注意这里是 宽/高 的比率
 */
export function closestRatio(imgRatio = 0) {
  const imgRatioMaps = [
    { key: 3 / 4, value: '3-4' },
    { key: 4 / 5, value: '4-5' },
    { key: 13 / 16, value: '13-16' },
    { key: 1 / 1, value: '1-1' }
  ]

  const closestObj = imgRatioMaps.reduce(function(prev, curr) {
    return (Math.abs(curr.key - imgRatio) < Math.abs(prev.key - imgRatio) ? curr : prev)
  })

  return closestObj.value
}

/**
 * robot link common
 */
export function robotLinkCommon(name = 'navigationBar', other = '', page_name = '') {
  page_name = page_name || window.SaPageInfo?.page_name || window.getSaPageInfo?.page_name || 'page_others'
  if (page_name === 'page_order_list') name = 'orderListPage'
  if (page_name === 'page_order_detail') name = 'orderDetailPage'
  if (page_name === 'page_contact_us') name = 'supportPage'
  if (page_name === 'page_order_track') name = 'trackPage'
  return `/robot?page=${name}&page_name=${page_name}${other}`
}

/**
 * 添加时间戳刷新页面,用于需要清除浏览器的情况
 */
export function reloadByNoCache({ langZero = false } = {}) {
  let searchParams = { ...queryString.parse(queryString.extract(location.href)), ...{ _t: Date.now() } }
  if( langZero && searchParams.lang ){
    searchParams.lang = '0'
  }
  window.location.href = `${window.location.pathname}?${queryString.stringify(searchParams)}`
}

/**
 * 异步加载文件
 */
export function asyncLoadFile({ type, src, attrs = {}, el = 'body' }) {
  return new Promise((resolve, reject) => {
    let tag = document.createElement(type)
    tag.src = src
    Object.keys(attrs).forEach(key => {
      tag.setAttribute(key, attrs[key])
    })
    if (type === 'iframe') {
      tag.style.display = 'none'
    }
    if (type === 'script') {
      tag.crossOrigin = 'anonymous'
    }
    tag.onload = resolve
    tag.onerror = reject
    document[el].appendChild(tag)
  })
}

/**
 * 获取url参数
 */
export function convertUrl(obj, _url = '') {
  const url = _url.split('?')
  let search
  let pathname
  if (url) {
    pathname = url[0]
    search = url[1]
  }
  let queryObj = qs.parse(search)
  queryObj = { ...queryObj, ...obj }
  return Object.keys(queryObj).length ? pathname + '?' + qs.stringify(queryObj) : pathname
}

/**
 * 用户路径相关
 */
export const userPath = {
  getTitle(url) {
    const vscReg = /([^\/]+)-[vs]?c-\d+\.html/
    const promoReg = /\/?(shein-picks|promo-discount)\.html/
    const dailyReg = /\/?daily-new\.html.*daily=(\d+-\d+-\d+)/
    if (vscReg.test(url)) return url.match(vscReg)[1]
    else if (promoReg.test(url)) return url.match(promoReg)[1]
    else if (dailyReg.test(url)) return url.match(dailyReg)[1]
    else if (/\/?daily-new\.html/.test(url)) return 'whats new'
    else return ''
  },
  replaceSpeCha: str => typeof str === 'string' && str.replace(/\s*[#%&]\s*/g, '-')
}

/** query字符串工具 */
export const queryString = {
  extract: function(str) {
    return str.split('?')[1] || ''
  },
  parse: function(str) {
    // Create an object with no prototype
    // https://github.com/sindresorhus/query-string/issues/47
    var ret = Object.create(null)

    if (typeof str !== 'string') {
      return ret
    }

    str = str.trim().replace(/^(\?|#|&)/, '')

    if (!str) {
      return ret
    }

    str.split('&').forEach(function(param) {
      var parts = param.replace(/\+/g, ' ').split('=')
      // Firefox (pre 40) decodes `%3D` to `=`
      // https://github.com/sindresorhus/query-string/pull/37
      var key = parts.shift()
      var val = parts.length > 0 ? parts.join('=') : undefined

      key = decodeURIComponent(key)

      // missing `=` should be `null`:
      // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
      // val = val === undefined ? null : decodeURIComponent(val)

      if (val === undefined) {
        val = null
      } else {
        try {
          val = decodeURIComponent(val)
        } catch (error) { console.error(error) }
      }

      if (ret[key] === undefined) {
        ret[key] = val
      } else if (Array.isArray(ret[key])) {
        ret[key].push(val)
      } else {
        ret[key] = [ret[key], val]
      }
    })
    return ret
  },
  stringify: function(obj) {
    return obj
      ? Object.keys(obj)
        .sort()
        .map(function(key) {
          var val = obj[key]

          if (val === undefined) {
            return ''
          }

          if (val === null) {
            return key
          }

          if (Array.isArray(val)) {
            var result = []

            val.slice().forEach(function(val2) {
              if (val2 === undefined) {
                return
              }

              if (val2 === null) {
                result.push(key)
              } else {
                result.push(key + '=' + val2)
              }
            })

            return result.join('&')
          }

          return key + '=' + val
        })
        .filter(function(x) {
          return x.length > 0
        })
        .join('&')
      : ''
  }
}

/** 时期数据格式化 */
export function dateFormat(fmt, d) {
  var o = {
    'M+': d.getMonth() + 1, //月份
    'd+': d.getDate(), //日
    'h+': d.getHours(), //小时
    'm+': d.getMinutes(), //分
    's+': d.getSeconds(), //秒
    'q+': Math.floor((d.getMonth() + 3) / 3), //季度
    S: d.getMilliseconds() //毫秒
  }
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (d.getFullYear() + '').substr(4 - RegExp.$1.length))
  for (var k in o) if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
  return fmt
}

/**
 * 是否手机端
 */
export function isMobile() {
  let ua = navigator.userAgent.toLowerCase()
  return ua.match(/phone|pad|pod|iphone|ipod|ios|ipad|android/) || ua.indexOf('macintosh') > -1 && 'ontouchend' in document
}

/**
 * 获取商品URL
 */
export function getGoodsUrl(name, id) {
  //拼接商品详情路由
  name = replaceHrefSpeCha(name)
  name = (name == '-' || ! name) ? 'shein-product' : name
  var url = (
    '/' +
          name
            .replace('/', '-')
            .split(' ')
            .join('-') +
          '-p-' +
          id +
          '.html'
  )
  return replaceHrefSpeCha(url)
}

/**
 * 获取商品评论URL
 */
export function getGoodsReviewUrl(name, id) {
  //拼接商品详情路由
  name = replaceHrefSpeCha(name)
  name = (name == '-' || ! name) ? 'shein-product' : name
  var url = (
    '/' +
          name
            .replace('/', '-')
            .split(' ')
            .join('-') +
          '-review-' +
          id +
          '.html'
  )
  return replaceHrefSpeCha(url)
}


/**
 * 非商详 & 加车场景 cdn cookie 种植 ｜ apollo 版本 Key: OPEN_COMMON_DETAIL_CDN_STATUS
 */
export async function setDetailCdnKeyCommon(abts) {
  try {
    const { 
      OPEN_COMMON_DETAIL_CDN_STATUS, 
      DETAIL_SSR_CDN_VERSION, 
      DETAIL_SSR_CDN_SITE_STATUS, 
      DETAIL_SSR_NO_CDN_SITE } = gbCommonInfo || {}
    if (!!!OPEN_COMMON_DETAIL_CDN_STATUS) return false

    // eslint-disable-next-line @shein-aidc/common/notDirectUseCookie
    const HAS_COOKIE = getCookie('D_CDN_KEY')
    if (HAS_COOKIE) return false

    await setDetailCacheCookie({
      DETAIL_SSR_CDN_VERSION, 
      DETAIL_SSR_CDN_SITE_STATUS, 
      DETAIL_SSR_NO_CDN_SITE
    }, abts)


  } catch (error) {
    console.error(error)
  }
}


/**
 * 商详 cdn cookie 种植 ｜ apollo 版本 Key: DETAIL_SSR_CDN_VERSION
 * 不开启 CDN 的站点：DETAIL_SSR_NO_CDN_SITE 默认：['uk', 'fr', 'ph', 'kr', 'jp', 'pl'] 
 * @param {*} apollos : { DETAIL_SSR_CDN_VERSION, DETAIL_SSR_CDN_SITE_STATUS, DETAIL_SSR_NO_CDN_SITE }
 * @param {*} abts : { 'CccGoodsdetail', 'goodsPicAb', 'PerformanceLogNodeTestFetch' }
 * @returns
 */
// 客户端 apollo & ab
export async function setDetailCacheCookie(apollos, abts) {
  try {
    const DEFAULT_NO_CDN_SITE = ['uk', 'fr', 'ph', 'kr', 'jp', 'pl']
    const { SiteUID } = window.gbCommonInfo || {}
    let version = '', cdn_site_status = [], no_cdn_site = DEFAULT_NO_CDN_SITE

    if (!apollos) {
      console.error('缺少 apollo 参数: DETAIL_SSR_CDN_VERSION,DETAIL_SSR_CDN_SITE_STATUS,DETAIL_SSR_NO_CDN_SITE')
      return
    }

    const abtValue = await getDetailAbtCacheValue(abts)

    version = apollos.DETAIL_SSR_CDN_VERSION || ''
    no_cdn_site = apollos.DETAIL_SSR_NO_CDN_SITE || []
    cdn_site_status = apollos.DETAIL_SSR_CDN_SITE_STATUS || []
    let detailSsrNoCdnSite = Array.isArray(no_cdn_site) ? no_cdn_site : DEFAULT_NO_CDN_SITE
    const IS_NO_CDN_SITE = detailSsrNoCdnSite.includes(SiteUID)
    const HIT_SITE_STATUS = cdn_site_status.includes(SiteUID) || cdn_site_status.includes('OPEN_ALL')

    if (IS_NO_CDN_SITE || !version || !!!HIT_SITE_STATUS) {
      removeCookie({ key: 'D_CDN_KEY' })
      return
    }

    const KEY_VALUE = getDetailCdnKeyValue(
      abtValue, 
      version
    )

    // eslint-disable-next-line @shein-aidc/common/notDirectUseCookie
    setCookie({
      key: 'D_CDN_KEY',
      value: KEY_VALUE,
      end: 60 * 60 * 24 * 7 // 7d
    })
  } catch (error)  {
    console.error(error)
  }
}


function getDetailCdnKeyValue (ABT_CACHE_VALUE = '', version) {
  const { SiteUID, lang } = window.gbCommonInfo || {}
  const params = ['GOODS_DETAIL_CDN', version, SiteUID, lang,  ABT_CACHE_VALUE]
  const keys = MD5(params.join('_'))
  return keys
}


/**
 * 获取商详情缓存的 KEY
 * @param {*} abtMap 存在缓存纬度的abt
 */
async function getDetailAbtCacheValue(ayncMap) {
  const keys = [
    'CccGoodsdetail',
    'goodsPicAb',
    'PerformanceLogNodeTestFetch'
  ] // 目前只有这两个 KEY

  // eslint-disable-next-line @shein-aidc/abt/abt
  const abtMap = ayncMap || await abtservice.getUserAbtResult({ newPosKeys: keys.join(',') })

  let branchIds = []
  let result = ''
  try {
    keys?.forEach?.(key => {
      const abt = abtMap?.[key] || {}
      const { child = [] } = abt
      if (child?.length) {
        child?.forEach?.(i => {
          i?.bid && branchIds.push(i?.bid)
        })
      }
    })

    if (branchIds?.length) {
      branchIds = branchIds.sort((a, b) => a - b)
      result = branchIds.join('_')
    }
  } catch (error) {
    console.error(error)
  }

  return result
}



/**
 * 是否登录
 */
export function isLogin() {
  return UserInfoManager.isLogin()
}

export function uniteJump(e) {
  // 适配后台配置三端统一跳转的链接
  // 参考：wiki.pageId=499254870
  e?.preventDefault?.()
  e?.stopPropagation?.()
  var dom = e.target
  if (!dom.getAttribute('weblink') && dom.closest('a') && !dom.getAttribute('href')) {
    dom = dom.closest('a')
  }
  var weblink = dom.getAttribute('weblink')
  var jumpType = dom.target
  if (!weblink) {
    weblink = dom.getAttribute('href')
  }
  if (isFunction(window.sa)) {
    let welcomeDom = dom.closest('.j-welcome-data')
    let welcomeData = {}
    if (welcomeDom && welcomeDom.length) {
      const contact_us_type = welcomeDom.dataset.contact_us_type
      welcomeData = {
        dialog_id: welcomeDom.dataset.dialog_id || '',
        chat_id: welcomeDom.dataset.chat_id || '',
        repository_id: welcomeDom.dataset.repository_id || '',
        standard_question: welcomeDom.dataset.standard_question || '',
        related_question_type: welcomeDom.dataset.related_question_type || '',
        contact_us_type: isUnDef(contact_us_type) ? '' : contact_us_type,
        is_in_tag: welcomeDom.dataset.is_in_tag || 0,
        is_default_tag: welcomeDom.dataset.is_default_tag || 0,
        is_user_tag: '',
      }
    }
    const activity_param = {
      link_name: dom.getAttribute('pageto') || '',
      welcomeData,
    }
    sa('send', {
      activity_name: 'click_sheinlink',
      activity_param
    })
  }
  if (jumpType) {
    window.open(weblink)
  } else {
    location.href = weblink
  }
}

/**
 * 替换地址问号前特殊字符
 */
export function replaceHrefSpeCha(href){
  if(typeof href != 'string') return href
  let hrefOrigin = href && href.match(/^http(s)?:\/\/(.*?)\//g)
  if (hrefOrigin) {
    hrefOrigin = hrefOrigin[0]
    if (hrefOrigin && !hrefOrigin.match('\.shein\.') && !hrefOrigin.match('\.romwe\.')){
      return href
    }
  }
  //搜索排除
  if(/pdsearch/.test(href)) return href
  var pathBegin = href.indexOf('://') > 0 ? href.indexOf('://') + 2 : 0
  var seachBegin = href.indexOf('?', pathBegin) > 0 ? href.indexOf('?', pathBegin) : href.length
  var protocol = href.substring(0, pathBegin)
  var pathName = href.substring(pathBegin, seachBegin)
  var search = href.substring(seachBegin, href.length)
  pathName = pathName.replace(/[><#@$\s'"%+&]|[\u4e00-\u9fa5]/g, '-')
  href = (protocol + pathName + search).replace(/-{2,}/g, '-')
  return href
}

/**
 * 价格转换
 */
export function transformPriceSymbol (price, currencyTitle, currency) {
  let { currencies } = window.gbCommonInfo || {}
  price = Number(price)
  if (isNaN(price)) {
    return price
  }
  if (!currency) {
    if (!currencyTitle) {
      currencyTitle = UserInfoManager.get({ key: 'currency' })
      //currencyTitle = getCookie('currency') || gbCommonInfo.default_currency
      if (!currencyTitle) {
        return price
      }
    }
    currency = currencies?.[currencyTitle]
    if (!currency) {
      return price
    }
  } else {
    if (currency.code) {
      // 以currency里的货币为准，但没啥用
      currencyTitle = currency.code
    }
  }
  var formatObj = {
    decimalPlace: typeof currency.decimal_place != 'undefined' && currency.decimal_place !== null ? Number(currency.decimal_place) : 2,
    decimalPoint: typeof currency.dec_point != 'undefined' && currency.dec_point !== null ? currency.dec_point : '.',
    thousandPoint: typeof currency.thousands_sep != 'undefined' && currency.thousands_sep !== null ? currency.thousands_sep : '',
  }
  if (['VND', 'IDR'].indexOf(currencyTitle) >= 0) {
    price = (price / 100).toFixed(0) * 100
  }
  // 保留位数
  price = price.toFixed(formatObj.decimalPlace)
  var priceArr = price.toString().split('.')
  price = priceArr[0].split('')
  for (var i = 1; i < price.length - 2; i++) {
    if ((price.length - i) % 3 == 0) {
      price.splice(i, 0, formatObj.thousandPoint)
      i++
    }
  }
  // 拼接千分号
  price = price.join('') + (priceArr.length == 2 ? formatObj.decimalPoint + priceArr[1] : '')
  // 拼接左右符号
  price = currency.symbol_left + price + currency.symbol_right
  return price
}

// 执行图片懒加载
export function imgLazyLoad() {
  if(typeof GB_SHEIN_lazyLoadInstance !== 'undefined') {
    GB_SHEIN_lazyLoadInstance.update()
  }
}

export function onWindowLoad(handler, delay = 0) {
  // 如果在添加事件监听器前 load 事件已经被触发
  if (document.readyState === 'complete') {
    setTimeout(handler, delay)
  } else {
    window.addEventListener('load', handler)
  }
}

/**
 * 替换jquery写法函数
 * @param {object} options
 * @parameter selector类型为string或Array<element>,用于告知当前需要查找的元素
 * @parameter cb类型为Function,用于传入事件回调或者遍历元素使用的回调函数
 * @parameter type类型为string,用于传入事件名或者需要的操作名称,例如:click或者addClass或者setCSS/getCSS
 * @parameter className类型为string,用于传入需要添加/删除/查找的类名
 * @parameter styleProp类型为string,用于传入需要修改/读取的样式属性名
 * @parameter styleValue类型为string,用于传入需要修改的样式属性值
 * @returns 返回undefined或者查找结果boolean或者读取样式结果string
 */

export function eachHandler(options){
  const newOptions = { ...options }
  const { selector, cb, type } = newOptions
  if(typeof options === 'function'){
    document.addEventListener('DOMContentLoaded', options)
    return
  }
  if(typeof options !== 'object' || !selector) return


  let elements = selector
  if(typeof selector === 'string'){
    elements = [...document.querySelectorAll(selector)]
  }
  if(typeof selector === 'object' && !Array.isArray(selector)){
    elements = [selector]
  }
  if(!type && cb){
    return elements.forEach(cb)
  }

  newOptions.elements = elements

  return eachTypeMap[type] ? eachTypeMap[type](newOptions) : elements.forEach((element)=>{
    element?.addEventListener(type, cb)
  })
}

const eachTypeMap = {
  hasClass({ elements, className }){
    return elements.some((element)=>{
      if(!element.classList)return
      return element.classList.contains(className)
    })
  },
  addClass({ elements, className }){
    return elements.forEach((element)=>{
      if(!element.classList)return
      element.classList.add(className)
    })
  },
  removeClass({ elements, className }){
    return elements.forEach((element)=>{
      if(!element.classList)return
      element.classList.remove(className)
    })
  },
  setCSS({ elements, styleProp, styleValue }){
    return elements.forEach((element)=>{
      if(!element.style)return
      element.style[styleProp] = styleValue
    })
  },
  getCSS({ elements, styleProp }){
    return window.getComputedStyle(elements[0]).getPropertyValue(styleProp)
  },
  getOffset({ elements }){
    return elements?.[0].getBoundingClientRect()
  },
  forEach({ elements, cb }){
    return elements.forEach(cb)
  }
}
