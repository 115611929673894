import { LibsRouter } from './libsRouter'
export { ProxyHost } from './libsProxyHost'
import { getLibsLangs } from './libsLangs'
export { getLangs, getLibsLangs } from './libsLangs'
import { getLibsSchttp, createLibsSchttpInOne, getLibsSchttpSync } from './libsSchttp'
export { createLibsSchttpInOne }
import type { Libs, Manager } from '../types'
export type * from '../types'

class LibsManager {
  #appConfigs = {} as Libs.Mutable<Libs.AppConfigs>
  #proxyHosts: Partial<
    Record<Manager.ProxyHostNames, Manager.ProxyHostTrigger>
  > = {}
  #initialized: boolean = false

  public get appConfigs() {
    return this.#appConfigs
  }

  public get proxyHosts() {
    return this.#proxyHosts
  }

  private _initializeSui(envs: Manager.InitConfigs['envs'], suiVehicle: any) {
    if (suiVehicle?.version?.startsWith('2.7.')) {
      this.#appConfigs.vueVersion = 2
      suiVehicle.prototype.$SHEIN = {
        lang: envs.lang,
        siteUID: envs.siteUID,
        appLanguage: envs.appLanguage,
        cssRight: envs.cssRight,
        webClient: 'shein',
        isRomwe: false,
      }
    } else if (suiVehicle?.version?.startsWith('3.')) {
      this.#appConfigs.vueVersion = 3
      if (suiVehicle.config?.globalProperties) {
        suiVehicle.config.globalProperties.$SHEIN = {
          mir: envs.cssRight ? 'rtl' : 'ltr',
          brd: 'sh',
        }
      }
    } else {
      console.warn('Unexpected SuiVehicle')
    }
  }

  private _buildGetters(getters: Manager.InitConfigs['getters']) {
    return Object.keys(getters).reduce((curr, next) => {
      Object.defineProperty(curr, next, {
        get: getters[next],
      })
      return curr
    }, {} as Libs.GetterEnvs)
  }

  public initialize(
    { envs, getters, languages, langPackages = {}, schttp, schttpSync, router }: Manager.InitConfigs,
    suiVehicle: any,
  ) {
    if (typeof window !== 'undefined') {
      if (this.#initialized) {
        console.warn('Already initialized')
        return
      }
      envs.requestInServer = false
    }

    this._initializeSui(envs, suiVehicle)
    this.#appConfigs.$envs = { ...envs }
    this.#appConfigs.$getters = this._buildGetters(getters)
    this.#appConfigs.$router = new LibsRouter(router)
    this.#appConfigs.$schttp = getLibsSchttp(schttp, envs)
    this.#appConfigs.$schttpSync = getLibsSchttpSync(schttpSync, envs)
    this.#appConfigs.$schttpInOne = createLibsSchttpInOne(this.#appConfigs.$schttp, this.#appConfigs.$schttpSync)
    this.#appConfigs.$language = getLibsLangs({ languages, langPackages, appConfigs: this.#appConfigs, fromInitialize: true }).langPool
    this.#initialized = true
  }

  public assignGlobalEventProxyHost(
    proxyHostName: Manager.ProxyHostNames,
    proxyHost: Manager.ProxyHostTrigger,
  ) {
    this.#proxyHosts[proxyHostName] = proxyHost
  }
}

const _LibsManager: LibsManager =
  // @ts-ignore
  globalThis.__LibsManager__ || (globalThis.__LibsManager__ = new LibsManager())

export function initialize(
  configs: Manager.InitConfigs,
  suiVehicle: { version: string },
) {
  _LibsManager.initialize(configs, suiVehicle)
}

export function assignGlobalEventProxyHost(
  proxyHostName: Manager.ProxyHostNames,
  proxyHost: Manager.ProxyHostTrigger,
) {
  _LibsManager.assignGlobalEventProxyHost(proxyHostName, proxyHost)
}

export function useAppConfigs() {
  return _LibsManager.appConfigs
}

export async function injectLanguages({ langPackages }) {
  if (typeof window === 'undefined') return
  return await getLibsLangs({ languages: _LibsManager.appConfigs.$language, langPackages, appConfigs: _LibsManager.appConfigs }).request
}

export function useProxyHosts() {
  return _LibsManager.proxyHosts
}
