<template>
  <div class="bsc-gc-checkout">
    <SDrawer
      v-expose="{
        id: 'expose_giftcard_pop:simple',
      }"
      class="bsc-gc-checkout__drawer"
      :direction="'rtl'"
      size="440px"
      append-to-body
      v-bind="props.drawerProps"
      @close-from-icon="onClose"
      @close-from-mask="onClose"
    >
      <template #title>
        <div
          class="bsc-gc-checkout__title"
          @click="() => emits('goScroll', 0)">
          {{ language?.SHEIN_KEY_PWA_24759 }}
        </div>
        <TabList
          ref="tabListRef"
          class="bsc-gc-checkout__tablist"
          tabJustifyContent="center"
          :is-show-no-card-icon="!isAvailableCard && cardList?.length === 0"
          @updateTabIndex="updateTabIndex"
          @updateCardList="updateCardList"
        />
      </template>

      <div
        :class="['bsc-gc-checkout__card--block', props.scrollWrapClass]"
      >
        <BBindGiftCard
          ref="bsBindGiftCardRef"
          :is-form-inline="true"
          :is-show-switch="props?.extraData?.isShowSwitch"
          :is-show-step-text="false"
          :is-need-drawer="false"
          :gift-card-list="cardAllData"
          :visible="true"
          :errorTip="bindCardErrorTips"
          sence="checkout"
          link-btn-txt=""
          @handle-bind-card="() => {}"
          @handle-close="() => {}"
          @bind-card-cb="handleBindCard"
        />

        <div
          v-if="cardList.length > 0"
          class="title">
          {{ isAvailableCard ? language?.SHEIN_KEY_PWA_22586 : language?.SHEIN_KEY_PWA_35099 }}
        </div>
        <SFeedback
          v-else
           class="bsc-gc-checkout__feedback"
          :content="isAvailableCard ? language?.SHEIN_KEY_PWA_35143 : language?.SHEIN_KEY_PWA_35144"
        />
        <Card
          v-for="(item, index) in cardList"
          :key="`${item.card_no}${item.card_status_tip}${index}`"
          v-tap="{
            id: 'click_listgiftcard:simple',
            data: {
              sequence: index,
              is_available: isAvailableCard ? 1 : 0,
              is_linked: !!item?.bind_card_tip ? 1 : 0
            }
          }"
          v-expose="{
            id: 'expose_listgiftcard:simple',
            data: {
              sequence: index,
              is_available: isAvailableCard ? 1 : 0,
              is_linked: !!item?.bind_card_tip ? 1 : 0
            }
          }"
          :enableCopy="false"
          :isSelect="item.card_no === selectItem?.card_no"
          :enableGoDetail="false"
          :readonly="!isAvailableCard"
          :class="['card', props.scrollItemClass]"
          :item="item"
          cardHeight="164px"
          @click="() => selectItem = item.card_no === selectItem?.card_no ? null : item"
        />
      </div>

      <div class="bsc-gc-checkout__footer">
        <SButton
          :type="['primary']"
          width="100%"
          @click="handleConfirm"
        >
          {{ language.SHEIN_KEY_PWA_28846 }}
        </SButton>
      </div>
    </SDrawer>
  </div>
</template>
<script setup lang="ts">
import { inject, ref, computed, watch } from 'vue'
import { SDrawer } from '@shein-aidc/sui-drawer/laptop'
import { SButton } from '@shein-aidc/sui-button/laptop'
import { SFeedback } from '@shein-aidc/sui-feedback/laptop'
import BBindGiftCard from '@shein-aidc/bs-bind-gift-card-laptop'
// import BBindGiftCard from '../../../../bind-gift-card/laptop/src/index'
import Card from './Card.vue'
import TabList from './TabList.vue'
import { type C_GiftCard } from '../../../types'

const {triggerNotice } = inject('analysisInstance') as any

const selectItem = ref<{
  card_no: string
  card_pin: string
} | null>(null) // 特殊情况用于临时勾选
const cardList = ref<C_GiftCard.CardItem[]>([])
const cardAllData = ref([])
const tabIndex = ref<number>(0)
const bsBindGiftCardRef = ref(null)
const bindCardErrorTips = ref()

const props = defineProps<{
  drawerProps: Record<string, string>
  scrollWrapClass: string
  scrollItemClass: string,
  extraData?: Record<string, any>
}>()

const emits = defineEmits(['goScroll', 'custom-event'])

const language: C_GiftCard.language= inject('language')!

const updateCardList = (data) => {
  cardAllData.value = data.allList
  cardList.value = data.currentList
}
const updateTabIndex = (index: number) => {
  tabIndex.value = index
}
const isAvailableCard = computed(() => tabIndex.value === 0)


const onClose = () => {
  emits('custom-event', { key: 'close' })
}

const handleConfirm = () => {
  emits('custom-event',
  {
    key: 'confirm',
    data: {
      item: selectItem.value,
    },
  })
}
const handleBindCard = (v) => {
  const { resData: { code } = {}, msg, isBindCard } = v
  if (isBindCard) {
    const isSuccess = [0].includes(+code)
    const extraData = {
      result: isSuccess ? 1 : 0,
    }
    if (!isSuccess) {
      extraData['result_reason'] = code
      bindCardErrorTips.value = msg
    }
    triggerNotice?.({
      id: 'click_card_confirm_result:simple',
      extraData,
    })
  }
  emits('custom-event', {
    key: 'bind-card',
    data: v,
  })
}

watch(() => props.drawerProps.visible, (v) => {
  if (!v) {
    selectItem.value = null
  }
})

watch(() => props?.extraData?.selectedItem, (v) => {
  selectItem.value = v
}, { immediate: true })

</script>

<style lang="less">
.bsc-gc-checkout {
  &__drawer {
    .sui-drawer__title {
      margin: 32px 40px 0;
      border-bottom: 1px solid #E5E5E5;
      padding: 0;
    }
    .sui-drawer__body {
      padding: 0;
      overflow: hidden;
      height: 100%;
    }
  }
  &__title {
    color:  #000;
    text-align: center;
    font-family: "Arial Black";
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: 33px;
  }
  &__tablist {
    margin-top: 10px;
  }
  &__card--block{
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    height: 90%;
    padding: 32px 30px 0 30px;
    margin-bottom: 90px;
    .title {
      margin: 16px 0px 12px 0px;
      color: #000;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
    }
    .card {
      margin-bottom: 16px;
    }
  }
  &__feedback {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
  }
  &__footer {
    position: absolute;
    left: 0;
    bottom: 32px;
    width: 100%;
    padding: 0 30px;
  }
}
</style>
