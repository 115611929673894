import { ref, onMounted, computed } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter'

daEventCenter.addSubscriber({ modulecode: '1-7-8' })
daEventCenter.addSubscriber({ modulecode: '1-7-4' })
daEventCenter.addSubscriber({ modulecode: '1-7-5' })
export function useAppendageHandle(store, modalType){
  let isLoaded = ref(false)
  
  let isShow = computed({
    get() {
      return isLoaded.value && store.state.appendageDrawer?.[modalType]
    },
    set() {
      handleClose()
    }
  })
  let promotionId = computed(() => {
    return store.state.appendageDrawer.promotionId
  })
  let promotionItem = computed(() => {
    return store.state.promotionState?.integrityPromotionInfo?.[promotionId.value]?.data || {}
  })
  const attachmentInfo = computed(() => {
    return promotionItem.value.promotionPopupInfo?.attachmentInfo || {}
  })
  const tabs = computed(() => {
    return (attachmentInfo.value.attachmentChooseHeadList || []).map(v => ({
      ...v,
      id: v.range - 1,
      isSatisfied: currentRange.value >= v.range,
    }))
  })
  const currentRange = computed(() => {
    return promotionItem.value.range
  })
  const fulfillStatus = computed(() => {
    if (currentRange.value == tabs.value.length) {
      return 1 // 全满足
    } else if (currentRange.value > 0) {
      return 2 // 部分满足
    }
    return 0 // 不满足
  })
  const typeId = computed(() => {
    return promotionItem.value.type_id || ''
  })

  let handleClose = () => {
    daEventCenter.triggerNotice({
      daId: '1-7-8-5',
      extraData: {
        promotion_typeid: typeId.value,
        promotion_code: promotionId.value,
      }
    })
    store.commit('resetAppendageDrawerStatus')
  }

  onMounted(() => {
    isLoaded.value = true
  })

  return {
    isLoaded,
    isShow,
    promotionId,
    promotionItem,
    typeId,
    tabs,
    currentRange,
    fulfillStatus,
    attachmentInfo,
    handleClose
  }
}
