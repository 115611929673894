<template>
  <div
    class="recommend-bottom"
  >
    <div
      class="recommend-bottom_title"
      style="visibility: hidden;"
    >
      Customers Also Viewed
    </div>

    <!-- 商品项loading骨架屏 -->
    <RecommendBottomListSkeleton
      :num="recommendConfig.limit"
      :item-style="cItemStyle"
    />
  </div>
</template>

<script setup>
import RecommendBottomListSkeleton from './RecommendBottomListSkeleton.vue'
import { useRecommendConfig } from './utils.js'
const { recommendConfig, cItemStyle } = useRecommendConfig()

</script>
<style lang="less" scoped>
@import './styles.less';

</style>
