<template>
  <div 
    class="c-check c-check__cart-right c-check__new-cart-right"
  >
    <div class="c-check__cart-summary">
      <div class="check-title">
        <h4
          tabindex="0"
          class="cart-summary-title fsp-element"
        >
          {{ language.SHEIN_KEY_PC_14764 }}
        </h4>
        <div
          v-if="CheckTitleSubText"
          class="check-title__sub"
        >
          {{ CheckTitleSubText }}
        </div>
      </div>
      <CartAllDiscountDetailExpose />
      <CartCheckout />
    </div>
    <LazyHydrationWrapper>
      <div
        class="accept-bank"
      >
        <p>{{ language.SHEIN_KEY_PC_14761 && language.SHEIN_KEY_PC_14761.replace(':','') }}</p>
        <div class="bank-img">
          <img
            v-for="item in logoList"
            :key="item.url"
            :src="cutImg(item.url, 0, {})"
            class="c-img__con-m fsp-element"
          />
        </div>
      </div>
    </LazyHydrationWrapper>
    <UnpaidOrderTip
      v-if="showUnpaidOrderTip"
      :unpaid-order-info="unpaidOrderInfo"
      :language="language"
    />
    <ClientOnly>
      <!-- 利诱抽屉 -->
      <CartIncentiveDrawer />
      <!-- 取消订单 -->
      <SummaryCancelOrder
        v-if="showSummaryCancelOrderTip"
      />
      <!-- 超限弹窗 -->
      <CartPromotionInterception />
      <!-- 超限弹窗V2 -->
      <CartPromotionInterceptionV2 />
    </ClientOnly>
  </div>
</template>

<script>
export default {
  name: 'CartSummary'
}
</script>

<script setup>
import { useStore } from 'vuex'
import { computed, defineAsyncComponent } from 'vue'
import CartAllDiscountDetailExpose from '../../functional/cart-discount-detail/CartAllDiscountDetailExpose.vue'
import { useCartAllDiscountDetail } from '../../functional/cart-discount-detail/useCartAllDiscountDetail.js'
import CartCheckout from '../../functional/cartCheckout/CartCheckout.vue'
import UnpaidOrderTip from 'public/src/pages/cart_v2/components/functional/unpaid-order-tip/UnpaidOrderTip.vue'
import { LazyHydrationWrapper } from '@shein-aidc/shein-lazy-hydration'
import { transfromAndCutImg } from 'public/src/services/resource/index'

const CartIncentiveDrawer = defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-cart-incentive-drawer" */ 'public/src/pages/cart_v2/components/functional/cart-incentive-drawer/CartIncentiveDrawer.vue'))
const CartPromotionInterception = defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-promotion-interception-dialog" */ 'public/src/pages/cart_v2/components/functional/cartPromotionInterception/PromotionInterception.vue'))
const CartPromotionInterceptionV2 = defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-promotion-interception-dialog-v2" */ 'public/src/pages/cart_v2/components/functional/cartPromotionInterceptionV2/PromotionInterceptionV2.vue'))
const SummaryCancelOrder = defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-summary-cancel-order" */ 'public/src/pages/cart_v2/components/functional/cartCancelOrderTip/SummaryCancelOrder.vue'))

const { state, getters } = useStore()
const cartSumQuantity = computed(() => state.cartInfo?.cartSumQuantity || 0)
const language = computed(() => state.language)
const unpaidOrderInfo = computed(() => state.orderState?.unpaidOrderInfo)
const loginStatus = computed(() => state.loginStatus)
const showCancelOrderTip = computed(() => getters.showCancelOrderTip)

// 是否展示非空车状态待支付订单
const showUnpaidOrderTip = computed(() => {
  return unpaidOrderInfo.value && loginStatus.value
})
// 是否展示非空车取消订单
const showSummaryCancelOrderTip = computed(() => {
  return cartSumQuantity.value > 0 && showCancelOrderTip.value
})
const { estimatedAmountSub } = useCartAllDiscountDetail()

const CheckTitleSubText = computed(() => {
  if (estimatedAmountSub.value) return estimatedAmountSub.value
  return ''
})
const logoList = computed(() => state.logoList)

const cutImg = (imgUrl, designWidth, exp) => {
  const { deviceData = '', isSupportWeb = '', isSupprotCut = false } = state.locals.RESOURCE_SDK || {}

  const cutData = {
    deviceData,
    isSupportWebp: Boolean(isSupportWeb),
    isSupprotCut,
    imgUrl,
    designWidth: Number(designWidth),
    exp,
  }

  return transfromAndCutImg(cutData)
}
</script>

<style lang="less">
.c-check__new-cart-right {
  width: 100%;
  padding: 0 10px;
}
.c-check {
  margin-bottom: 30px;

  .check-title {
    margin-bottom: 10px;
  }

  .check-title__sub {
    margin-top: 2px;
    line-height: normal;
    font-size: 10px;
    color: rgba(#000, 0.3);

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 定义显示的行数 */
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cart-summary-title {
    display: inline-block;
    font-family: Arial-Black, Arial /* rw: Adieu*/;
    font-size: 20px;
    text-transform: capitalize;
  }
}
.accept-bank{
  background: #fff;
  padding-top: 16px;
  padding-left: 16px;
  margin-top: 10px;
  >p{
    margin-bottom: 15px;
    font-size: 20px;
    color: #222;
    text-transform: capitalize;
    font-weight: bold;
    font-family: Arial-Black, Arial;
  }
  .c-img__con-m{
    width: 51px;
    height: 34px;
  }
  .bank-img{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    padding-bottom: 15px;
  }
}
</style>
