import { stringifyQueryString } from '@shein/common-function'
import type { Libs, Manager } from '../types'

export class LibsRouter implements Libs.Router {
  constructor(private readonly router?: Manager.RouterInterface) {}

  private _resolveUrl(to: Libs.Route) {
    if (typeof to === 'string') {
      return to
    } else {
      return to.query ? `${to.path}?${stringifyQueryString({ queryObj: to.query })}` : to.path
    }
  }

  private _matchRoute(
    url: string,
    router?: Manager.RouterInterface,
    force?: boolean,
  ): router is Manager.RouterInterface {
    try {
      return !force && !!router && !!(router.resolve(url).matched?.length || router.resolve(url).route?.matched.length)
    } catch (error) {
      return false
    }
  }

  public push(to: Libs.Route, force = false) {
    const url = this._resolveUrl(to)
    if (this._matchRoute(url, this.router, force)) {
      this.router.push(url)
    } else {
      location.assign(url)
    }
  }

  public replace(to: Libs.Route, force = false) {
    const url = this._resolveUrl(to)
    if (this._matchRoute(url, this.router, force)) {
      this.router.replace(url)
    } else {
      location.replace(url)
    }
  }

  public back(force = false) {
    this.router?.back && !force ? this.router.back() : history.go(-1)
  }
}
