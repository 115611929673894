import * as api from 'public/src/pages/cart_v2/services/api.js'
import { handleCartList } from 'public/src/pages/cart_v2/utils/handleCartList.js'
import { carouselInfo, topBannerInfo } from 'public/src/pages/cart_v2/mocks/index.js'
import { setLocalStorage } from '@shein/common-function'
import { isSwitchBffApiVersion } from '@/public/src/pages/common/bff-new/index.js'
import analysisAction from './analysisAction' 
import promotionAction from './promotionAction' 
import filterAction from './filterAction'
import overLimitAction from './overLimitAction'
import negativeInfoAction from './negativeInfoAction'
import checkoutIncentive from '../../components/functional/cart-checkout-incentive/checkoutIncentive.js'
import { handlerCouponSaveToast } from 'public/src/pages/cart_v2/utils/coupon.js'
import { HtmlCacheClass } from 'public/src/js/utils/htmlCacheClass'
import { getToCheckoutUrl } from 'public/src/pages/cart_v2/utils/getToCheckoutUrl'

const HtmlCacheClassInstance = HtmlCacheClass.getInstance()
/**
 * 计算跳转下单页的url
 * @param {prime_product_code} 似乎已不用 
 * @param {coupon_infos} 优惠券hash值 
 */
const prefetchHTML = (props) => {
  let source = getToCheckoutUrl(props)
  const carts = props?.carts
  const prefetchFlag = Array.isArray(carts) && carts?.length > 0 && carts.some(item => item?.is_checked == 1)
  source && HtmlCacheClassInstance.loopFn({ source, carts, prefetchFlag })
}

const actions = {
  ...negativeInfoAction,
  ...overLimitAction,
  ...analysisAction,
  ...promotionAction,
  ...filterAction,
  initSsrData({ dispatch, state, commit }) {
    let originCartInfo = state.originCartInfo || {}
    commit('updateState', { key: 'auto_use_coupon', value: (state.cartAbtInfo.Cartshowcoupon?.param?.cart_show_coupon_switch == 'on' ? 1 : 0) })
    commit('updateState', { key: 'isPlatForm', value: originCartInfo?.isMultiMall == '1' ? true : false })
    commit('updateState', { key: 'originCartNum', value: originCartInfo?.cartSumQuantity || 0 })
    if(!state.isSSR){
      commit('updateState', { key: 'showSkeleton', value: originCartInfo?.cartSumQuantity || 0 })
    }
    dispatch('handleCartRes', { code: 0, info: originCartInfo, isSSR: true })
    dispatch('initOverLimitState')
  },
  handleCartRes({ commit, dispatch }, res) {
    if(res.code == 0) {
      if(typeof window != 'undefined'){
        let lastCheckStockTime = Date.now()
        commit('updateState', { key: 'lastCheckStockTime', value: lastCheckStockTime })
      }
      let { mallCartInfo = {} } = res.info || {}
      let mallCarts = mallCartInfo?.mallCarts || []
      let { cartList, mallShippingInfo, integrityPromotionInfo } = handleCartList(mallCarts)
      // cartList = cartList.concat(soldoutItemsMock)
      commit('updateState', { key: 'carts', value: cartList })
      commit('updateState', { key: 'cartIds', value: cartList.map(item => item.id) })
      commit('updateCartItemMap', { carts: cartList })
      // commit('initLocalChecked')
      commit('updateState', { key: 'mallShippingInfo', value: mallShippingInfo })
      let soldoutItems = cartList.filter(item => item.isInvalid == '1' && !item.canChangeMallInfo && !item.canChangeGoodInfo)
      commit('updateState', { key: 'soldoutItems', value: soldoutItems })
      commit('updateState', { key: 'soldoutIds', value: soldoutItems.map(item => item.id) })
      commit('updateState', { key: 'mallCartList', value: mallCarts.filter((item) => item.type == 1) || [] })
      commit('updateCarouselState', { usefulLabels: mallCartInfo?.carouselInfo || [] })
      // commit('updateCarouselState', { usefulLabels: carouselInfo })
      commit('updateState', { key: 'topBannerInfo', value: mallCartInfo?.topBannerInfo || {} })
      commit('updateState', { key: 'savedPrice', value: mallCartInfo?.savedPrice || {} })
      commit('updateState', { key: 'priceMode', value: mallCartInfo?.priceMode || {} })
      commit('updateState', { key: 'mallCartBiData', value: mallCartInfo?.mallCartBiData || {} })
      // commit('updateState', { key: 'topBannerInfo', value: topBannerInfo })
      commit('updatePromotionState', { 
        promotionCouponMix: mallCartInfo?.promotionCouponMix || null,
        bottomPromotionData: mallCartInfo?.bottomPromotionData || [],
        bottomPromotionConfig: mallCartInfo?.bottomPromotionConfig || {},
        sortDoubleFreeShippingPriorityInAbt: mallCartInfo?.sortDoubleFreeShippingPriorityInAbt,
        cartPopUpInfoData: mallCartInfo?.cartPopUpInfoData,
      })
      commit('updateCheckoutState', { 
        checkoutLabelList: mallCartInfo?.checkoutLabelList || [],
        cartLureInfo: mallCartInfo?.cartLureInfo || {},
        overLimitTipOnBottom: mallCartInfo?.overLimitTipOnBottom || '',
        mallCartPointData: mallCartInfo?.mallCartPointData || {},
      })
      checkoutIncentive.updateCartLureList(mallCartInfo?.cartLureInfo?.cartLureList)
      commit('updateState', { key: 'defaultAddress', value: res.info?.defaultAddress || {} })
      commit('updateState', { key: 'batchDeleteStayPopUp', value: mallCartInfo?.batchDeleteStayPopUp || {} })
      dispatch('handleCartInfo', res)
      dispatch('handleFilterLabel', mallCartInfo?.filterLabel)
      dispatch('handlePromotionInfo', integrityPromotionInfo)
      if (typeof window !== 'undefined') { //不依赖ssr的逻辑才放在这里
        dispatch('handleMallShippingAnalysisParam')
        prefetchHTML({ prime_product_code: '', coupon_infos: mallCartInfo?.mallCartBiData?.autoUseCouponCodes || '', carts: cartList })
        dispatch('getSiteFreeMall')
      }
    }
  },
  handleCartInfo({ commit }, res) {
    let cartInfo = { 
      effectiveProductLineSumQuantity: res.info?.effectiveProductLineSumQuantity || 0,
      allProductLineSumQuantity: res.info?.allProductLineSumQuantity || 0,
      cartSumQuantity: res.info?.cartSumQuantity || 0,
      taxInclusiveTip: res.info?.taxFarmingTips || '',
      promotionEventTrackingPoint: res.info?.promotionEventTrackingPoint || [],
      checkedInterceptTip: res.info?.checkedInterceptTip || '',
      withCouponTip: res.info?.withCouponTip || '',
      showPromotionDetailPopUp: res.info?.showPromotionDetailPopUp,
      promotionDetailPopUp: res.info?.promotionDetailPopUp,
      mallCartPriceData: res.info?.mallCartPriceData || {},
      cartInterceptions: res.info?.cartInterceptions || {},
      checkedCartSumQuantity: res.info?.checkedCartSumQuantity || 0,
      interceptResultInfo: res.info?.interceptResultInfo,
      auto_use_points_info: res.info?.auto_use_points_info || {},
      totalPrice: res.info?.totalPrice || {},
    }
    commit('updateState', { key: 'cartInfo', value: cartInfo })
  },
  async fetchCartIndex({ dispatch, state }, otherParams) {
    if ( typeof window == 'undefined' ) return
    const reqParams = {
      auto_use_coupon: state.auto_use_coupon,
      mixSelectedCartIds: state.promotionState.mixSelectedCartIds,
      ...otherParams,
    }
    reqParams.holdTopFilterTagIds = state.filterState.holdTopFilterTagIds
    const res = await api.getCartIndex(reqParams)
    dispatch('handleCartRes', res)
    if(res.code == 0 && reqParams?.coupon_code){
      handlerCouponSaveToast(res.info?.mallCartInfo?.couponAddToCartToastTip, res.info?.mallCartInfo?.mallCartBiData?.couponAddToCartToastType)
    }

    return res
  },
  async fetchModifyCartCheckStatus({ dispatch, state }, params) {
    if (state.filterState.selectFilterLabel?.filterTagId !== 'all') {
      params.filterTagId = state.filterState.selectFilterLabel?.filterTagId
    }
    params.holdTopFilterTagIds = state.filterState.holdTopFilterTagIds
    let res = await api.modifyCartCheckStatus({ ...params, auto_use_coupon: state.auto_use_coupon })
    dispatch('handleCartRes', res)
    dispatch('fetchCouponInfo')
    return res
  },
  async fetchCartDelete({ dispatch, state }, { params, events }) {
    const { onSuccess } = events || {}
    let res = await api.cartDelete({ ...params, auto_use_coupon: state.auto_use_coupon })
    if(res.code == 0) {
      onSuccess && await onSuccess()
    }
    dispatch('handleCartRes', res)
    dispatch('fetchCouponInfo')
    return res
  },
  async fetchCartUpdate({ dispatch, state }, params) {
    if (state.filterState.selectFilterLabel?.filterTagId !== 'all') {
      params.filterTagId = state.filterState.selectFilterLabel?.filterTagId
    }
    params.holdTopFilterTagIds = state.filterState.holdTopFilterTagIds
    let res = await api.cartUpdate({ ...params, auto_use_coupon: state.auto_use_coupon })
    dispatch('handleCartRes', res)
    dispatch('fetchCouponInfo')
    return res
  },
  async fetchCartUpdateAttr({ dispatch, state }, params) {
    const reqParams = {
      auto_use_coupon: state.auto_use_coupon,
      ...params
    }
    if (state.filterState.selectFilterLabel?.filterTagId !== 'all') {
      reqParams.filterTagId = state.filterState.selectFilterLabel.filterTagId
    }
    params.holdTopFilterTagIds = state.filterState.holdTopFilterTagIds
    let res = await api.cartUpdateAttr(reqParams)
    dispatch('handleCartRes', res)
    dispatch('fetchCouponInfo')
    return res
  },
  async fetchCartBatchWish({ dispatch, state }, { params, events }) {
    const { onSuccess } = events || {}
    let res = await api.cartBatchWish({ ...params, auto_use_coupon: state.auto_use_coupon })
    if(res.code == 0) {
      onSuccess && await onSuccess()
      await dispatch('fetchCartIndex')
    }
    return res
  },
  async fetchCartBatchAdd({ dispatch }, params) {
    let res = await api.cartBatchAdd(params)
    if(res.code == 0) {
      dispatch('fetchCartIndex')
      dispatch('fetchCouponInfo')
    }
    return res
  },
  /**
   * 购物车登录
   * @param {String} type 登录类型
   * @param {Function} updateSingelDataCb 登录状态更新前需要单独更新的数据, 或需要执行的方法
   */
  async handleCartLogin({ commit, state, dispatch }, {
    type = '',
    updateSingelDataCb = () => {},
  } = {}) {
    const result = {
      emptyCart: false,
      equalCart: false
    }
    const beforeLoginCartSumQuantity = state.cartInfo.cartSumQuantity

    let promotion_price
    if (Number(state.savedPrice?.amount) > 0) {
      promotion_price = state.savedPrice.amountWithSymbol
    }
    let loginRes = await api.cartLoginPromise({ type, config: {
      retainData: {
        // 空购物车 不免邮
        free_shipping: beforeLoginCartSumQuantity && state.cartIsFreeShipping ? true : false,
        promotion_price
      }
    } })
    try {
      await updateSingelDataCb()
    } catch ( error ) {
      console.error('updateSingelDataCb Error')
    }

    window.cartCheckLogin = new Promise((resolve) => {
      resolve({ member_id: loginRes?.info?.member?.member_id || false })
    })

    commit('updateState', { key: 'loginStatus', value: true })
    const res = await api.getCartIndex({ auto_use_coupon: state.auto_use_coupon })
    await dispatch('handleCartRes', res)
    if ( res?.code == 0 ) {
      const afterLoginCartSumQuantity = res.info?.cartSumQuantity
      result.emptyCart = afterLoginCartSumQuantity == 0 || beforeLoginCartSumQuantity == 0
      result.equalCart = afterLoginCartSumQuantity == beforeLoginCartSumQuantity
    }

    if ( (typeof SaPageInfo !== 'undefined') && (typeof SaPageInfo.page_param == 'object') && !result.equalCart ) {
      SaPageInfo.page_param.is_combine = 0
      setLocalStorage({ key: 'is_combine', value: '0', expire: 1000 * 60 })
    }
    dispatch('fetchCouponInfo')
    return { result, res: res }
  },
  async fetchUnpaidOrdersInfo({ commit, state }, { force = false } = {}) {
    if(state.orderState.unpaidOrderInfo && !force) return
    if(!state.loginStatus) return
    const res = await api.getCanceledOrderInfo()
    if (res.type == '0') {
      commit('updateOrderState', { unpaidOrderInfo: res })
    }
  },
  checkSoldOut({ commit, state }, { outStockCarts = null }) {
    if ( outStockCarts ) {
      let outStockCartsId = outStockCarts.map(item => item.id)
      const soldoutCarts = state.carts.filter(item => outStockCartsId.indexOf(item.id) > -1)
      commit('updateState', { key: 'outStockCarts', value: soldoutCarts })
    } 
  },
  // 获取券数据
  async fetchCouponInfo({ commit, state }) {
    const res = await (state.loginStatus ? api.geCartCouponList() : Promise.resolve({}))
    if (res.code == '0') {
      commit('updateState', { key: 'couponInfo', value: res?.info || {} })
    }
  },
  async getUseBffApi({ commit }, keys) {
    const value = await isSwitchBffApiVersion(keys)
    commit('updateState', { key: 'useBffApi', value })
  },
  // 仅在未登录非空车场景下获取免邮信息
  async getSiteFreeMall({ commit, state }) {
    if (!(state.cartInfo?.cartSumQuantity) || state.loginStatus) return
    const res = await api.getCartEntranceGuideApi({ auto_use_coupon: state.auto_use_coupon })
    if (res.code == 0) {
      commit('updateState', { key: 'cartIsFreeShipping', value: res.info?.is_free_shipping == 1 })
    }
  },
  /**
   * 切换 低库存 / 利诱点 商品列表弹窗
   * @param {*} param0 
   * @param {object} param1 
   * @param {boolean} param1.show 是否展示
   * @param {object} param1.info 弹窗信息
   */
  // toggleIncentiveProductListDialog({ commit }, { show, info }) {
  //   if (typeof window === 'undefined') return
  //   commit('updateState', { key: 'showIncentiveProductListDialog', value: show })
  //   if (show) {
  //     checkoutIncentive.pause()
  //     commit('updateState', { key: 'incentiveProductListDialogInfo', value: info })
  //   } else {
  //     checkoutIncentive.restart()
  //   }
  // }
}
export default actions
