<template>
  <component 
    :is="priceComponent"
    :pre-pay-un-pay-params="prePayUnPayParams"
    :is-discount-total-color="isDiscountTotalColor"
  />
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import PriceDetail from './PriceDetail.vue'
import PriceDetailV2 from './PriceDetailV2.vue'
import PriceDetailV3 from './PriceDetailV3.vue'

defineProps({
  prePayUnPayParams: {
    type: Object,
    default: () => ({})
  },
  isDiscountTotalColor: {
    type: Boolean,
    default: false,
  },
})

const store = useStore()

const orderConfirmDiscountSwitchByAbt = computed(() => store.getters.orderConfirmDiscountSwitchByAbt)
const showNewPriceDetailByAbt = computed(() => store.getters.showNewPriceDetailByAbt)
const isBrSite = computed(() => !!~store.getters.SiteUID.indexOf('br'))

const priceComponent = computed(() => {
  if (isBrSite.value) return PriceDetail
  if (showNewPriceDetailByAbt.value) return PriceDetailV3
  if (orderConfirmDiscountSwitchByAbt.value) return PriceDetailV2
  return PriceDetail
})
</script>
