/**
 * @description: 公共弹窗状态
 */
export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
}
