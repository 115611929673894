<template>
  <div
    class="cart_item-header sold-out-group new-soldout-header"
  >
    <section class="sold-out__item-header">
      <span
        tabindex="0"
      >
        {{ language.SHEIN_KEY_PC_30886 }} ({{ soldoutItems.length }})
      </span>
      <div
        v-if="!isBatchActivePage && soldoutItems.length > 1"
        v-expose="{id: '1-7-3-8', data: {goods_num: soldoutItems.length}}"
        v-tap="{id: '1-7-3-9', data: {goods_num: soldoutItems.length}}"
        class="sold-out__view-all"
        @click="handleViewAll"
      >
        <span>{{ language.SHEIN_KEY_PC_25798 }}</span>
        <sui_icon_more_right_14px
          :is-rotate="locals.GB_cssRight"
          size="14px"
        />
      </div>
      <div
        v-else-if="!isBatchActivePage && soldoutItems.length === 1"
        v-expose="{id: '1-7-3-8', data: {goods_num: soldoutItems.length}}"
        v-tap="{id: '1-7-3-9', data: {goods_num: soldoutItems.length}}"
        class="sold-out__view-all"
        @click="handleSaveAll"
      >
        {{ language.SHEIN_KEY_PC_16398 }}
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: 'CartSoldOutHeader',
}
</script>
<script setup>
import { template } from '@shein/common-function'
import { SMessage } from '@shein-aidc/sui-message'
import { useStore } from 'vuex'
import { computed } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter'
import { sui_icon_more_right_14px } from '@shein-aidc/icon-vue3'

daEventCenter.addSubscriber({ modulecode: '1-7-3' })

const { state, commit, dispatch } = useStore()
defineProps({
  isBatchActivePage: {
    type: Boolean,
    default: false,
  },
})

const soldoutItems = computed(() => state.soldoutItems)
const locals = computed(() => state.locals)
const language = computed(() => state.language)
const loginStatus = computed(() => state.loginStatus)

const handleViewAll = () => {
  commit('updateState', { key: 'showSoldOutItemDialog', value: true })
}
const handleSaveAll = () => {
  const payload = {
    titleText: language.value.SHEIN_KEY_PC_17955,
    okCallback: () => {
      commit('hideCommonModal')
      saveAllSoldOut()
    },
    cancelCallback: () => {
      commit('hideCommonModal')
    }
  }
  commit('showCommonModal', payload)
}
const saveAllSoldOut = async () => {
  const reqBatchIds = soldoutItems.value.map(item => item.id)
  if(loginStatus.value){
    commit('updateState', {
      key: 'loadingShow',
      value: true
    })
    dispatch('fetchCartBatchWish', { params: { id: reqBatchIds } })
      .then(async (res) => {
        commit('updateState', {
          key: 'loadingShow',
          value: false
        })
        if (res.code == '0') {
          SMessage({
            offset: 136,
            message: language.value.SHEIN_KEY_PC_16402,
            type: 'info'
          })
          sa('send', {
            activity_name: 'expose_save_tips',
            activity_param: ''
          })
        } else {
          if (res.code == -4 || res.code == -2) { // 触发容灾
            SMessage({
              offset: 136,
              type: 'error',
              message: language.value.SHEIN_KEY_PC_17753,
            })
          } else if (Number(res.code) === 400427 && res.info){ // 24小时内收藏数超过限制
            SMessage({
              offset: 136,
              type: 'info',
              message: template(res.info.limit, language.value.SHEIN_KEY_PC_19347),
            })
          } else {
            SMessage({
              offset: 136,
              type: 'error',
              message: language.value.SHEIN_KEY_PC_17737,
            })
          }
        }
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
        commit('updateState', {
          key: 'loadingShow',
          value: false
        })
      })
  } else {
    await dispatch('handleCartLogin', { type: 'wishlist' })
    saveAllSoldOut()
  }
}
</script>

<style lang="less" scoped>
.new-soldout-header {
  margin-top: 5px;
  .sold-out__item-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 0;
    color: #222;
    font-weight: bold;
    >span {
      font-size: 18px;
    }
  }
  &.sold-out-group.cart_item-header {
    margin-top: 5px;
    padding: 16px 24px 0 16px;
  }
}
.sold-out__view-all {
  font-size: 13px;
  display: flex;
  &:hover {
    cursor: pointer;
  }
}
</style>
