import { metricPageSuccess } from 'public/src/pages/common/business-monitor/common.js'
import HomeSlslog from 'public/src/pages/common/home_common/slslog.js'
import Monitor from 'public/src/pages/common/home_common/monitor.js'
import { ref } from 'vue'

export function useBCategory() {
  const pageStatus = status => {
    metricPageSuccess({
      page: 'page_category',
      status
    })
  }
  const noData = () => {
    HomeSlslog.slsCompFilter(
      {
        logLevel: 2,
        tag: 'web_client_home_warn_total',
        message: 'CategoryDataWarn',
        reason: 'NodeEmpty',
        module: 'RightNode',
        pageName: 'page_category'
      },
      false
    )
  }
  const clickCate = (item) => {
    Monitor.metricCategoryClick({
      tags: {
        page: 'page_category',
        cate_level: '2'
      },
      message: 'Right level second category click'
    })
    Monitor.metricCategoryType({
      tags: { 
        page: 'page_category',
        type: item?.categoryType?.hrefType
      },
      message: 'Right level second category click by bs'
    })
  }
  const navClick = (item) => {
    Monitor.metricCategoryType({
      tags: { 
        page: 'page_category',
        type: item?.categoryType?.hrefType
      },
      message: 'Left level first category click by bs'
    })
  }
  const paddingRight = ref('0px')
  function changeScrollBar(pr){
    paddingRight.value = pr
  }
  return {
    paddingRight,
    pageStatus,
    noData,
    clickCate,
    navClick,
    changeScrollBar
  }
}
