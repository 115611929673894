<template>
  <s-dialog
    :visible="visible"
    :append-to-body="true"
    :show-close="true"
    class="image-view-dialog"
    type="W720"
    @update:visible="updateVisible"
    @open="onOpen"
    @closed="onClosed"
  >
    <ImageViewSwiper
      v-if="render"
      ref="swiper"
      :effects-tip="effectsTip"
      :images-tip="imagesTip"
      :resize-urls="resizeUrls"
      :origin-urls="originUrls"
    />
  </s-dialog>
</template>

<script>
import ImageViewSwiper from './ImageViewSwiper.vue'

export default {
  name: 'ImageViewDialog',
  emits: ['update:visible'],
  components: {
    ImageViewSwiper,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    activeIndex: {
      type: Number,
      default: 0,
    },
    resizeUrls: {
      type: Array,
      default: () => [],
    },
    originUrls: {
      type: Array,
      default: () => [],
    },
    effectsTip: {
      type: String,
      default: '',
    },
    imagesTip: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      render: false,
    }
  },
  methods: {
    updateVisible(event) {
      this.$emit('update:visible', event)
    },
    slideToIndex() {
      this.$refs.swiper?.slideToIndex(this.activeIndex)
    },
    onOpen() {
      this.render = true
      if (this.$refs.swiper) {
        this.slideToIndex()
      } else {
        this.$nextTick(() => this.slideToIndex())
      }
    },
    onClosed() {
      this.render = false
    },
  },
}
</script>

<style lang="less">
.image-view-dialog {
  .sui-dialog__wrapper .sui-dialog__body {
    padding: 0 29px;
  }
}
</style>
