<template>
  <section class="section promotion-sec hover-bubble">
    <div class="section-l">
      <sui_icon_activity_24px :size="size" />
    </div>
    <div class="section-r">
      <p 
        v-if="showPosition == 'top'"
        class="title"
      >
        {{ language.SHEIN_KEY_PC_30662 }}{{ fullCourtMap.length > 1 ? `(${fullCourtMap.length})` : '' }}
        <a
          v-if="fullCourtMap.length == 1 && fullCourtMap[0].data.isMeet != 1"
          class="operation"
          @click="handleSupplement(fullCourtMap[0])"
        >
          {{ language.SHEIN_KEY_PC_15215 }}
          <sui_icon_more_right_12px
            :is-rotate="locals.GB_cssRight"
            size="12px"
          />
        </a>
      </p>
      <ul
        class="full-activity-contain"
      >
        <div>
          <li
            v-for="(item) in fullCourtMap"
            :key="'promotion-slide-item-' + item.data.promotion_id"
            class="promotion-slide-item"
          >
            <p class="promotion-slide-content">
              <section>
                <span 
                  class="promotion-full-tip"
                  v-html="item.promotionTips && item.promotionTips.html_text || ''"
                ></span>
                <div
                  v-if="processCount(item) < 100 && item.data.type_id == '21'"
                  class="item-content-tip__process"
                >
                  <div
                    class="item-content-tip__process-bg"
                    :style="{ width: `${processCount(item)}%` }"
                  ></div>
                </div>
              </section>
              <a
                v-if="fullCourtMap.length > 1 && item.data.isMeet != 1"
                class="operation"
                @click="handleSupplement(item)"
              >
                {{ language.SHEIN_KEY_PC_15215 }}
                <sui_icon_more_right_12px
                  :is-rotate="locals.GB_cssRight"
                  size="12px"
                />
              </a>
            </p>
            <count-down-time
              v-if="item.data.is_count_down"
              :time-stamp="Number(item.data.end_time)"
              show-type="inline-promotion"
              :show-end-tips="true"
            />
          </li>
        </div>
      </ul>
    </div>
  </section>
</template>
<script>
export default {
  name: 'HoverPromotionTopTip',
}
</script>
<script setup>
import { defineProps, defineEmits } from 'vue'
import { sui_icon_activity_24px, sui_icon_more_right_12px } from '@shein-aidc/icon-vue3'
import countDownTime from 'public/src/pages/cart_v2/components/functional/common/CartCountDown.vue'
defineProps({
  language: {
    type: Object,
    default: ()=>{
      return  {}
    }
  },
  locals: {
    type: Object,
    default: ()=>{
      return  {}
    }
  },
  fullCourtMap: {
    type: Array,
    default: ()=>{
      return  []
    }
  },
  showPosition: {
    type: String,
    default: 'mall'
  },
  size: {
    type: String,
    default: '18px'
  }
})

const emits = defineEmits(['handle-supplement'])
const handleSupplement = (promotion) => {
  emits('handle-supplement', promotion)
}

const processCount = (item) => {
  return +(item.data?.promotionPopupInfo?.progressPercent) * 100
}
</script>
