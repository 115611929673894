import { ref, onMounted, onUnmounted } from 'vue'

export function usePageVisibility() {
  const visibility = ref('visible') // visible, hidden
  const onPageVisibilityChange = () => {
    visibility.value = document.visibilityState
  }

  onMounted(() => {
    onPageVisibilityChange()
    document.addEventListener('visibilitychange', onPageVisibilityChange)
  })
  onUnmounted(() => {
    document.removeEventListener('visibilitychange', onPageVisibilityChange)
  })

  return visibility
}
