

// topBanner 跳转处理
export const preFetchOrGetPageUrl = ({ url, options = {} } = {}) => {
  if (!url) return
  try {
    const { isReturnUrl = false } = options || {}
    url = `${url}${url.indexOf('?') > -1 ? '&hasTopBanner=1' : '?hasTopBanner=1' }`
    // sw页面代理url
    const requestUrl = `${url}${url.indexOf('?') > -1 ? '&proxy_enable=1' : '?proxy_enable=1' }`
    if(isReturnUrl) return requestUrl
  } catch (error) {
    return url
  }
}
