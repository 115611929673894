<template>
  <s-popover
    v-model="showMoreFilterPop"
    trigger="hover"
    placemen="bottom-end"
    :prop-style="{
      marginTop: '-4px',
      width: '403px',
      maxWidth: '403px',
      maxHeight: '40vh',
      padding: '16px 5px',
      zIndex: '3999',
      boxShadow: '0px 2px 16px 0px rgba(0, 0, 0, 0.16)',
    }"
  >
    <div
      v-if="showMoreFilterPop"
      class="filter-more__content"
    >
      <div
        v-for="(group, i) in groupLabels"
        :key="i"
        class="category-filter"
      >
        <span class="category">{{ group.titleTip }}</span>
        <s-checkbox-group
          v-model="checkValue"
          margin-r="0.213rem"
          class="check-groups"
          @change="slideTagsChange"
        >
          <filter-check-tag
            v-for="labelItem in group.items"
            :key="labelItem.filterTagId"
            v-tap="sendDaEvent(item, 'default_outside')"
            :label-info="labelItem"
            :checked="checkValue == labelItem.filterTagId"
            :show-prefix-icon="false"
            :show-suffix-icon="true"
            :in-modal="true"
            class="filter-item__in__popover"
          />
        </s-checkbox-group>
      </div>
    </div>
    <template #reference>
      <div
        v-expose="{ id: '1-7-3-56' }"
        class="more-entry"
        :class="{
          'entry-checked': showMoreFilterPop,
          'style-a_entry': filterStyle == '1',
          'style-b_entry': filterStyle == '0',
        }"
      >
        <div
          v-if="entryStyle == 'logo'"
          class="logo-entry"
        >
          <Icon
            name="sui_icon_filter_16px"
            size="16px"
            class="logo-icon"
            :is-rotate="GB_cssRight"
          />
        </div>
        <div
          v-if="entryStyle == 'text'"
          class="text-entry"
        >
          <span class="more-text">More</span>
          <Icon
            name="sui_icon_more_down_12px_1"
            size="12px"
            class="arrow-icon"
            :is-rotate="GB_cssRight"
          />
        </div>
      </div>
    </template>
  </s-popover>
</template>

<script setup>
import { computed, ref, watch, defineEmits } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import FilterCheckTag from './FilterCheckTag.vue'

const { GB_cssRight } = gbCommonInfo

const props = defineProps({
  modelValue: {
    type: [ Boolean, Array, String ],
    default: false
  },
  labelsInMorePop: {
    type: Array,
    default: () => []
  },
  entryStyle: {
    type: String,
    default: 'text'
  },
  filterStyle: {
    type: String,
    default: '0'
  }
})

const emit = defineEmits(['update:modelValue', 'analysis17325', 'slide-tags-change'])
const checkValue = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

const slideTagsChange = (v) => {
  emit('slide-tags-change', v)
}

const sendDaEvent = (label, showType) => {
  return {
    id: '1-7-3-26',
    data: {
      label_id: label?.labelId,
      is_cancel: label?.isSelect == '1' ? 0 : 1,
      show_type: showType,
    },
  }
}

const groupLabels = computed(() => {
  // 将平铺的标签转化成分组格式[{ groupClassId: "coupon", titleTip: 'Coupon Eligible', items: [{}]}]
  let group = []
  props.labelsInMorePop?.forEach(labels => {
    const { groupClassId = '', titleTip = '' } = labels
    if (group[group?.length - 1]?.groupClassId == labels.groupClassId) {
      group[group?.length - 1]?.items?.push(labels)
    } else {
      group.push({
        groupClassId,
        titleTip,
        items: [labels]
      })
    }
  })
  return group
})

const showMoreFilterPop = ref(false)
watch(showMoreFilterPop, (val) => {
  if (!val) return
  daEventCenter.triggerNotice({
    daId: '1-7-3-57'
  })
  emit('analysis17325', props.labelsInMorePop, 'filter_menu_inside')
})
</script>

<style lang="less" scoped>
.more-entry {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 47px;
  height: 28px;
  box-shadow: -16px 0 10px -10px rgba(204, 204, 204, 0.7);
  cursor: pointer;
  .logo-entry {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    border: solid 1px #E5E5E5;
    .logo-icon {
      font-weight: bold;
      color: #959595!important;/* stylelint-disable-line declaration-no-important */
    }
  }
  .text-entry {
    padding: 0 10px;
    display: flex;
    align-items: center;
    .more-text {
      font-size: 11px;
      color: #666666;
    }
    .arrow-icon {
      color: #bbb!important;/* stylelint-disable-line declaration-no-important */;
    }
  }
  &.entry-checked {
    .logo-entry {
      border-color: #000;
    }
    .logo-icon {
      color: #000!important;/* stylelint-disable-line declaration-no-important */
    }
    .more-text {
      color: #000;
      font-weight: 510;
    }
    .arrow-icon {
      color: #000!important;/* stylelint-disable-line declaration-no-important */
      transform: rotate(180deg);
    }
  }
}
.entry-checked {
  &.style-a_entry {
    .logo-entry {
      border-color: #FA6338;
    }
    .more-text {
      color: #FA6338;
    }
    .logo-icon,
    .arrow-icon {
      color: #FA6338!important;/* stylelint-disable-line declaration-no-important */
    }
  }
}
.filter-more__content {
  max-height: calc(40vh - 32px);
  padding: 0 11px; // 与父级分担16px的padding，是为了定位滚动条的位置
  overflow-x: hidden;
  overflow-y: auto;
  /* 滚动条整体样式 */
  &::-webkit-scrollbar {
    width: 6px; //滚动条宽度
  }
  /* 滚动条轨道样式 */
  &::-webkit-scrollbar-track {
    background-color: #fff; // 滚动条轨道背景色
  }
  /* 滚动条滑块样式 */
  &::-webkit-scrollbar-thumb {
    background-color: #ababab; // 滚动条滑块背景色
    border-radius: 12px; // 滚动条滑块圆角
  }
}
/* stylelint-disable-next-line selector-class-pattern */
.filter-item__in__popover {
  margin-right: 8px;
  margin-bottom: 8px;
  display: inline-flex;
  /* stylelint-disable-next-line selector-class-pattern */
  &.style-a {
    /* stylelint-disable-next-line selector-class-pattern */
    &.cart-checkbox-tag_checked {
      border: 1px solid #FA6338;
    }
  }
  /* stylelint-disable-next-line selector-class-pattern */
  &.style-b {
    /* stylelint-disable-next-line selector-class-pattern */
    &.cart-checkbox-tag_checked {
      color: #000;
      border: 1px solid #000;
      background: #fff;
    }
  }
  .filter-label {
    max-width: 154px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 26px;
    line-height: 26px;
  }
}
.category-filter {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  .category {
    display: block;
    height: 14px;
    line-height: 14px;
    color: #000;
    font-family: "SF Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 510;
    margin-bottom: 8px;
  }
}
</style>
