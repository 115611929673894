<template>
  <div
    class="empty-cart-container"
  >
    <EmptyCartUnpaidOrderTip
      v-if="showEmptyCartUnpaidOrderTip"
      :unpaid-order-info="unpaidOrderInfo"
      :language="language"
    />
    <ClientOnly>
      <EmptyCancelOrder
        v-if="showEmptyCancelOrderTip"
      />
    </ClientOnly>
    <sh-empty-icon />
    <p class="empty-tip bold">
      {{ (language.SHEIN_KEY_PC_14562 || '').toUpperCase() }}
    </p>
    <div
      v-if="!loginStatus"
      class="empty-cart-nologin"
    >
      <slot name="unlogin">
        <p class="unlogin-tip">
          {{ (language.SHEIN_KEY_PC_18187 || '') }}
        </p>
        <s-button-group
          class="mt-40"
          :width="isClient ? '' : 240"
        >
          <s-button-group-item
            v-tap="{
              id: '1-7-1-40'
            }"
            :type="['primary', 'H44PX']"
            class="empty-cart-btn"
            @click="handleToSign"
          >
            {{ (language.SHEIN_KEY_PC_15740 || '').toUpperCase() }}
          </s-button-group-item>
          <s-button-group-item 
            v-expose="{
              id: '1-7-1-15',
              data: {
                go_to_page: goToPageType
              }
            }"
            v-tap="{
              id: '1-7-1-14',
              data: {
                go_to_page: goToPageType
              }
            }" 
            :type="['default', 'H44PX']"
            class="empty-cart-btn"
            @click="handleToShop"
          >
            {{ language.SHEIN_KEY_PC_16337 }}
          </s-button-group-item>
        </s-button-group>
      </slot>
    </div>
    <div
      v-else
      class="emprt-cart-operate"
    >
      <s-button
        v-expose="{
          id: '1-7-1-15',
          data: {
            go_to_page: goToPageType
          }
        }" 
        v-tap="{
          id: '1-7-1-14',
          data: {
            go_to_page: goToPageType
          }
        }"
        :type="['primary', 'H44PX']"
        class="empty-cart-btn"
        @click="handleToShop"
      >
        {{ language.SHEIN_KEY_PC_16337 }}
      </s-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EmptyCart'
}
</script>

<script setup>
import { computed } from 'vue'
import { SButton } from '@shein-aidc/sui-button'
import { SButtonGroup } from '@shein-aidc/sui-button-group'
import { SButtonGroupItem } from '@shein-aidc/sui-button-group-item'
import { transformPromodiscountUrl } from 'public/src/pages/common/biz_helper/shein_picks'
import { useStore } from 'vuex'
import EmptyCartUnpaidOrderTip from 'public/src/pages/cart_v2/components/functional/unpaid-order-tip/EmptyCartUnpaidOrderTip.vue'
const { langPath, forceHttp } = gbCommonInfo
import ShEmptyIcon from './ShEmptyIcon.vue'
import EmptyCancelOrder from '../cartCancelOrderTip/EmptyCancelOrder.vue'

const { state, getters } = useStore()

let goToPageType = 'shopnow'

const cartSumQuantity = computed(() => state.cartInfo?.cartSumQuantity || 0)
const loginStatus = computed(() => state.loginStatus)
const language = computed(() => state.language)
const isClient = computed(() => typeof window !== 'undefined')
const unpaidOrderInfo = computed(() => state.orderState?.unpaidOrderInfo)
const showCancelOrderTip = computed(() => getters.showCancelOrderTip)

// 是否展示空车状态待支付订单提示
const showEmptyCartUnpaidOrderTip = computed(() => {
  return loginStatus.value && unpaidOrderInfo.value
})
// 是否展示空车状态取消订单提示
const showEmptyCancelOrderTip = computed(() => {
  return cartSumQuantity.value == 0 && showCancelOrderTip.value
})

const handleToSign = () => {
  location.href = `${langPath}/user/auth/login?redirection=` + encodeURIComponent('/cart')
}

const handleToShop = () => {
  location.href = forceHttp + transformPromodiscountUrl(`/shein-picks.html`)
}
</script>

<style lang="less">
.empty-cart-container {
  min-height: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .empty-cart-btn {
    min-width: 270px;
    height: 42px;
    line-height: 40px;
  }
  .emprt-cart-operate {
    margin-top: 30px;
    padding-bottom: 50px;
    .flexbox();
    .align-center();
    flex-direction: column;
  }
  .empty-tip {
    &.bold {
      font-size: 18px;
      font-weight: 800;
      color: #060606;
    }
  }
  .unlogin-tip {
    font-size: 14px;
    color: #666;
    margin-top: 8px;
    font-weight: 400;
  }

  .mt-40 {
    margin-top: 40px;
  }
  .empty-cancel-order {
    margin-bottom: -26px;
    margin-top: 50px;
  }
}
</style>
