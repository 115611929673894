<template>
  <div class="detail-btf__loading">
    <!-- 这是 细节图 的骨架图 -->
    <div class="item-details__picture">
      <div class="item-details__picture-img"></div>
      <div class="item-details__picture-img"></div>
      <div class="item-details__picture-img"></div>
    </div>
    <!-- 这是 新搭配的骨架图 -->
    <div class="item-details__outfit">
      <div class="item-details__outfit-title"></div>
      <div class="item-details__outfit-content">
        <div class="outfit-left">
          <div class="outfit-left__img"></div>
          <div class="outfit-left__img"></div>
          <div class="outfit-left__img"></div>
        </div>
        <div class="outfit-center"></div>
        <div class="outfit-right"></div>
      </div>
    </div>
    <!-- 这是 组合购的骨架图 -->
    <div class="item-details__comboBuy">
      <div class="item-details__comboBuy-title"></div>
      <ProductListSkeleton
        class="item-details__comboBuy-list"
        type="goodsBroadwise"
        :num="6"
        :item-style="{ width: `${(100 / 6).toFixed(7)}%` }"
      />
    </div>
    <!-- 这是 styleGallery 的骨架图 -->
    <div class="item-details__styleGallery">
      <div class="item-details__styleGallery-img"></div>
      <div class="item-details__styleGallery-img"></div>
      <div class="item-details__styleGallery-img"></div>
      <div class="item-details__styleGallery-img"></div>
      <div class="item-details__styleGallery-img"></div>
      <div class="item-details__styleGallery-img"></div>
    </div>

    <!-- 底部推荐位 骨架屏 -->
    <RecommendBottomSkeleton />
    <!-- 这是 CCCXBanner 的骨架图 -->
    <div class="item-cccx"></div>
  </div>
</template>
<script setup>
import { defineComponent } from 'vue'
import ProductListSkeleton from 'public/src/pages/product_list_v2/components/ProductListSkeleton.vue'
import RecommendBottomSkeleton from './innerComponents/Recommend/RecommendBottom/RecommendBottomSkeleton.vue'
defineComponent({
  name: 'BTFLoading',
})
</script>
<style lang="less" scoped>
@skeleton-bg: @sui_color_gray_weak1a;
.detail-btf__loading {
  padding: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px + 80px;
  opacity: 1;
  animation: 700ms loading ease-in-out infinite;
  .item-cccx {
    background: @skeleton-bg;
    width: 100%;
    height: 300px;
  }
  .item-details {
    &__outfit {
      margin-top: 75px;
      width: 100%;
    }
    &__outfit-title {
      width: 30%;
      height: 24px;
      margin: 25px 0;
      background: @skeleton-bg;
    }
    &__outfit-content {
      display: flex;
      height: 312px;
      width: 100%;
      .outfit-left {
        width: 78px;
        display: flex;
        flex-direction: column;
        margin-right: 15px;
        &__img {
          width: 100%;
          padding-bottom: 100%;
          background: @skeleton-bg;
          margin-bottom: 15px;
        }
      }
      .outfit-center {
        width: 312px;
        height: 312px;
        flex-shrink: 0;
        margin-right: 16px;
        background: @skeleton-bg;
      }
      .outfit-right {
        width: 100%;
        flex: 1;
        background: @skeleton-bg;
      }
    }
    &__comboBuy {
      margin-top: 75px;
    }
    &__comboBuy-title {
      width: 30%;
      height: 24px;
      margin:24px 0;
      background: @skeleton-bg;
    }
  }
  .item-details {
    &__picture {
      margin-top: 75px;
      width: 100%;
      display: flex;
      & > *:not(:last-child) {
        margin-right: 10px;
      }
    }
    &__picture-img {
      width: 32.85%;
      padding-bottom: 43.8%;
      background: @skeleton-bg;
    }    
    &__styleGallery {
      margin-top: 75px;
      width: 100%;
      display: flex;
      overflow: hidden;
      & > *:not(:last-child) {
        margin-right: 10px;
      }
    }
    &__styleGallery-img {
      width: 18.4166666%;
      height: 294px;
      background: @skeleton-bg;
    }
  }
}


@keyframes loading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

</style>
