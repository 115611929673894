<template>
  <li 
    v-expose="{
      id: '1-7-4-11',
      once: false,
      data: commonAnalysis
    }"
    v-tap="{
      id: '1-7-4-12',
      data: commonAnalysis
    }"
    class="promotion-product_item"
    @click="!isDisabled ? handleOpenQuickAdd($event) : null"
  >
    <!-- S: Product Image -->
    <div class="promotion-product__item-img">
      <PromotionImg
        :ref="`cartPromotionImgRef-${item.goods_id}`"
        :item="item"
        :class="{'cart-item__img-sold-out': isSoldOut}"
      />
      <!-- Sold Out -->
      <div
        v-if="isSoldOut"
        class="cart-item__stock"
      >
        <span>{{ language.SHEIN_KEY_PC_14553 }}</span>
      </div>
      <div
        v-else-if="[2, 4, 28].includes(+promotionItem.type_id)"
        v-tap="{
          id: '1-7-4-9',
          data: commonAnalysis
        }"
        :class="[
          'cart-item__free',
          {
            'cart-item__free_club': isClubGift
          }
        ]"
        @click.stop.prevent="isDisabled ? handleBtnClick() : handleOpenQuickAdd($event)"
      >
        <span class="free-tip">
          {{ language.SHEIN_KEY_PC_25239 }}
        </span>
        <div
          class="pick-btn"
          :class="{'pick-btn-disabled': isDisabled}"
        >
          {{ isPicked ? language.SHEIN_KEY_PC_15969 : language.SHEIN_KEY_PC_15968 }}
        </div>
      </div>
    </div>
    <!-- E: Product Image -->

    <!-- S: Product Name -->
    <div class="promotion-product__item-name">
      {{ item.goods_name }}
    </div>
    <!-- E: Product Name -->

    <!-- S: Product Price -->
    <div
      :class="[
        'promotion-product__item-price-content',
        {
          'price_save': [2, 4, 28].includes(+promotionItem.type_id)
        }
      ]"
    >
      <div
        :class="[
          'promotion-product__item-price',
          'item-price-content',
          {
            'item-price-content_save': [2, 4, 28].includes(+promotionItem.type_id)
          }
        ]"
      >
        <Icon
          v-if="isClubGift"
          class="price__icon"
          name="sui_icon_club_logo_fill_15px"
          size="15px"
          color="#873C00"
        />
        <span
          class="promotion-product__item-salePrice"
          :style="{
            '--color': unitPrice.color
          }"
        >
          {{ unitPrice.price.amountWithSymbol }}
        </span>
        <del
          v-if="originalPrice"
          :class="[
            'promotion-product__item-retailPrice',
            originalPrice.crossed == '1' && 'crossed'
          ]"
        >
          {{ originalPrice.price.amountWithSymbol }}
        </del>
        <span
          v-if="originalPrice && originalPrice.description"
          class="description"
        >{{ originalPrice.description }}</span>
        <SPopover
          v-if="originalPrice && originalPrice.tip && originalPrice.tip.desc"
          :content="originalPrice.tip.desc || language.SHEIN_KEY_PC_31070"
          trigger="hover"
          theme="dark"
          :show-close-icon="true"
          :outside-close="false"
          :append-to-body="true"
          :prop-style="{
            width: '240px'
          }"
          :delay-render="100"
        >
          <template #reference>
            <span class="lowest-price-tip">
              <Icon
                name="sui_icon_info_12px_1"
                size="12px"
              />
            </span>
          </template>
        </SPopover>
      </div>
      <SaveAmountTag
        v-if="[2, 4, 28].includes(+promotionItem.type_id)"
        v-show="!isSuggested"
        :item="item"
      />
      <div
        v-else
        v-tap="{
          id: '1-7-4-9',
          data: commonAnalysis
        }"
        class="pick-btn addon-pick-btn"
        :class="{'pick-btn-disabled': isDisabled || isSoldOut}"
        @click.stop.prevent="isDisabled ? handleBtnClick() : handleOpenQuickAdd($event)"
      >
        {{ isPicked ? language.SHEIN_KEY_PC_15969 : language.SHEIN_KEY_PC_15968 }}
      </div>
    </div>
    <div 
      v-if="lowestPrice"
      class="promotion-product__item-price item-retail-price"
    >
      <del
        :class="[
          'promotion-product__item-retailPrice',
          lowestPrice.crossed == '1' && 'crossed'
        ]"
      >
        {{ lowestPrice.price.amountWithSymbol }}
      </del>
      <span
        v-if="lowestPrice.description"
        class="description"
      >{{ lowestPrice.description }}</span>
      <SPopover
        v-if="lowestPrice.tip && lowestPrice.tip.desc"
        :content="lowestPrice.tip.desc"
        trigger="hover"
        theme="dark"
        :show-close-icon="true"
        :outside-close="false"
        :append-to-body="true"
        :prop-style="{
          width: '240px'
        }"
        :delay-render="100"
      >
        <template #reference>
          <span class="lowest-price-tip">
            <Icon
              name="sui_icon_info_12px_1"
              size="12px"
            />
          </span>
        </template>
      </SPopover>
    </div>
    <!-- E: Product Price -->
    <SaveAmountTag
      v-if="!isSuggested && [13, 22].includes(+promotionItem.type_id)"
      class="promotion-product__addon-save-amount"
      :item="item"
    />
    <SFSTag
      v-if="showSfsTag"
      class="promotion-product__item-sfs"
      :language="language"
      :item="item"
      :show-extend-text="CartOrderSFSVisual.visual_benefit_point"
    />
    <QuickShipLabel
      v-if="showQuickShip"
      class="promotion-product__item-quick-ship"
      :info="quickShipLabel"
    />
    <EvoluTag
      v-if="evoluTag"
      class="promotion-product__item-evolu"
      :info="evoluTag"
    />
    <ActTag
      v-if="isShowActTag"
      class="promotion-product__behavior-row"
      :info="actTag"
    />
  </li>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import PromotionImg from './PromotionImg.vue'
import SaveAmountTag from './SaveAmountTag.vue'
import ActTag from './ActTag.vue'
import { scrollToCartItem } from 'public/src/pages/cart_v2/utils/index.js'
import AddOnItem from 'public/src/pages/cart_v2/components/business/add-on/index.js'
import { getImageRatio } from 'public/src/pages/common/utils/index.js'
import QuickShipLabel from './QuickShipLabel.vue'
import { Icon } from '@shein-aidc/icon-vue3'
import EvoluTag from './EvoluTag.vue'
import SFSTag from './SFSTag.vue'
let QuickViewPopover = null

export default {
  name: 'PromotionProductItem',
  // 组件注册
  components: {
    PromotionImg,
    ActTag,
    SaveAmountTag,
    QuickShipLabel,
    Icon,
    EvoluTag,
    SFSTag,
  },
  // 组件传参
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    promotionItem: {
      type: Object,
      default(){
        return {}
      }
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    fulfillStatus: {
      type: Number,
      default: -1
    },
    index: {
      type: Number,
      default: -1
    },
    disabledTip: {
      type: String,
      default: ''
    },
    page: {
      type: Number,
      default: 1
    },
    promotionType: {
      type: String,
      default: ''
    }
  },

  // 计算属性
  computed: {
    ...mapState(['language', 'appendageDrawer']),
    ...mapGetters(['showQuickShipTag', 'isSuggested', 'CartOrderSFSVisual']),
    isSoldOut() {
      return [2, 3].includes(+this.item.on_sale_status) || this.item.stock_status == '2'
    },
    showQuickShip(){
      return this.showQuickShipTag && this.item.skc_quick_ship ? true : false && this.quickShipLabel.tag_val_name_lang
    },
    promotionProductType(){
      return [13, 22].includes(+this.promotionItem.type_id) ? '2' : '1'
    },
    isPicked() {
      if(this.appendageDrawer.fromType.includes('addItem')) return false
      return this.promotionItem.isPicked == '1'
    },
    commonAnalysis() {
      return {
        abtStr: '',
        item: {
          ...this.item,
          productRelationID: this.item.productRelationID,
          salePrice: this.item.sale_price,
          retailPrice: this.item.retail_price,
          quickship: this.showQuickShip,
          showEvoluShein: this.evoluTag,
        }, 
        index: this.index,
        activity_from: 'cart_pick_add',
        page: this.page,
        behaviorText: this.actTag.appTraceInfo,
        is_member_gift: +this.isClubGift,
      }
    },
    appendagePromotionType() {
      let type = {}
      if([13, 22].includes(+this.promotionItem.type_id)) {
        type = { 
          code: 'cartAddBuys',
          // name: `购物车-加价购页-推荐列表-${this.tabName}`
        }
      } else if([4, 28].includes(+this.promotionItem.type_id)) {
        type = { 
          code: 'cartFullGifts',
          // name: `购物车-满赠购页-推荐列表-${this.tabName}`
        }
      } else {
        type = { 
          code: 'cartBuyGifts',
          // name: '购物车-买赠购页-推荐列表'
        }
      }
      return type
    },
    // 是否是付费会员赠品
    isClubGift(){
      return this.promotionType === 'clubGift'
    },
    actTag() {
      return this.item.actTag || {}
    },
    isShowActTag() {
      return !this.isSoldOut && this.actTag.actionDataTagShow === '1' && this.actTag.hasAvailableTag === '1'
    },
    quickShipLabel() {
      return this.item?.productInfoLabels?.quickShipLabel || {}
    },
    originalPrice() {
      return this.item.priceData?.originalPrice
    },
    lowestPrice() {
      return this.item.priceData?.lowestPrice
    },
    unitPrice() {
      return this.item.priceData?.unitPrice
    },
    showSfsTag() {
      return this.CartOrderSFSVisual.visual_tabs && this.item?.sfs_flag == 1
    },
    evoluTag() {
      return this.item.productTags?.find(tag => tag.type === 'evoluShein')
    }
  },
  // 方法实现
  methods: {
    ...mapActions(['fetchCartIndex']),
    ...mapMutations(['resetAppendageDrawerStatus']),
    handleBtnClick(){
      if (this.isDisabled && this.disabledTip) {
        return this.$message({ htmlString: this.disabledTip })
      }
    },
    async getImageRatio() {
      return (await getImageRatio(this.item.goods_img)) || '3-4'
    },
    async handleOpenQuickAdd (event) {
      if(this.isSoldOut || this.isDisabled) return
      let item = this.item
      const imgRatio = await this.getImageRatio(item)

      const quickViewData = {
        goodsId: item.goods_id,
        mallCode: item.mall_code,
        imgRatio,
        lockmall: true,
        extendQvData: {
          salePrice: item.sale_price
        },
        extendForm: {
          promotion_id: this.promotionItem.promotion_id,
          promotion_type: this.promotionItem.type_id
        },
        config: {
          hidePromotionInfo: true,
          hideViewFullDetail: true,
          hideWishlistBtn: true,
          hideColorGroup: true,
          promotion_state: this.fulfillStatus,
          promotion_typeid: this.promotionItem.type_id,
          // 不展示一键购
          showOneClickPay: false,
        },
        callback: {
          handleAddToCartDone: (res) => {
            if (res.code == 0) {
              this.$message({
                message: [13, 22].includes(+this.promotionItem.type_id) ? this.language.SHEIN_KEY_PC_28560 : this.language.SHEIN_KEY_PC_28559,
                type: 'success'
              })
              this.fetchCartIndex()
              this.$nextTick(() => {
                scrollToCartItem(res.cart?.id)
              })
              this.resetAppendageDrawerStatus()
              AddOnItem?.close()
            }  else {
              this.$message({
                message: res.tips || this.language.SHEIN_KEY_PC_15805,
                type: 'error'
              })
            }
          },
        },
        analysis: {
          pageType: 'cartPage',
          sourceTarget: event.target || null,
          index: this.index || 0,
          sa: {
            code: this.appendagePromotionType.code,
            activity_from: this.isClubGift ? 'cart_membergift_pick_add' : 'cart_pick_add'
          },
        }
      }

      if (QuickViewPopover) {
        QuickViewPopover.show(quickViewData)
        return
      }

      import('public/src/pages/common/quickView').then(_ => {
        QuickViewPopover = _.default
        QuickViewPopover.show(quickViewData)
      })
    },
  },
}
</script>

<style lang='less' scoped>
.promotion-product_item{
  width: calc(20% - 20px);
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  margin-right: 20px;
  margin-bottom: 32px;
}
.promotion-product__item-img{
  position: relative;
  max-height: 217px;
  width: 100%;
  cursor: pointer;
  overflow-y: hidden;
  > img {
    width: 100%;
  }
}
.cart-item__img-sold-out{
  opacity: 0.4;
}
.cart-item__stock{
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, .6);
  padding: 5px 12px;
  line-height: 1;
  color: #fff;
  font-size: 12px;
  width: 100%;
  text-align: center;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
.cart-item__free{
  position: absolute;
  bottom: 0;
  background: linear-gradient(90deg, #FA6338 0%, #FA6338 100%);
  padding: 2px 6px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* rw:begin */
  background: #F82854;
  .free-tip{
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    flex: 1;
    margin-right: 4px;
  }
  .pick-btn{
    max-width: 54px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.cart-item__free_club {
    background: linear-gradient(90deg, rgba(159, 79, 45, 0.80) 0%, rgba(203, 132, 97, 0.80) 100%);
  }
}
.pick-btn{
    color: #222;
    height: 20px;
    min-width: 40px;
    padding: 0 8px;
    border-radius: 24px;
    background: #FFF;
    text-align: center;
    font-family: "SF UI Text";
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    cursor: pointer;
    &.addon-pick-btn{
      max-width: 54px;
      color: #fff;
      background: #FA6338;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
.pick-btn-disabled{
  opacity: 0.4;
}
.promotion-product__item-name{
  position: relative;
  padding-top: 10px;
  color: @sui_color_gray_dark3;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-size: 12px;
  cursor: pointer;
}
.promotion-product__item-price-content{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.price_save {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}
.price__icon {
  margin-right: 4px;
}
.promotion-product__item-price {
  font-size: 12px;
  direction: ltr;
  .promotion-product__item-salePrice {
    font-weight: bold;
    font-size: 14px;
    color: var(--color);
    white-space: nowrap;
  }
  .promotion-product__item-retailPrice {
    color: @sui_color_gray_light1;
    margin-left: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-decoration: none;
    &.crossed {
      text-decoration: line-through;
    }
  }
}
.item-price-content{
  display: inline-flex;
  margin-right: 4px;
  align-items: baseline;
  overflow: hidden;
  &.item-price-content_save{
    width: fit-content;
    align-items: center;
  }
}
.promotion-product__item-quick-ship,
.promotion-product__item-sfs,
.promotion-product__item-evolu {
  margin-top: 4px;
  margin-right: 8px;
  padding-top: 1px;
  padding-bottom: 1px;
  font-size: 10px;
  line-height: normal;
  vertical-align: middle;
}
.promotion-product__behavior-row{
  margin-top: 4px;

}
.promotion-product__addon-save-amount{
  margin-left: 0;
}
.item-retail-price{
  color: @sui_color_gray_light1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lowest-price-tip{
  color: @sui_color_gray_dark3;
  margin-left:4px;
  cursor: pointer;
  vertical-align: text-bottom;
}
.description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
