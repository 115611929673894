import HolidayDeliveryTag from './holiday-delivery-tag.vue'
import { getProps } from './helper'
import { createHelper } from '../../../hooks/useHelper'

const useHolidayDeliveryTag = createHelper('HolidayDeliveryTag', getProps)

export {
  HolidayDeliveryTag,
  useHolidayDeliveryTag,
}
