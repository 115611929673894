import { abtservice } from 'public/src/services/abt'

/**
 * 追加abt信息到埋点
 */
export function assignAnalysis({ SaPageInfo, abtInfo }) {
  const pageInfo = SaPageInfo || window.SaPageInfo

  if (pageInfo && abtInfo) {
    if (!pageInfo.page_param) pageInfo.page_param = {}
    let pageInfoAbtest = pageInfo.page_param.abtest || ''

    // eslint-disable-next-line @shein-aidc/abt/abt
    let cartAbtest = abtservice.getUserAbtResultForAnalysis({ abtInfo: abtInfo }).sa
    window.SaPageInfo.page_param.abtest = pageInfoAbtest ? `${pageInfoAbtest},${cartAbtest}` : cartAbtest
  }
}
