import { SIMetric } from 'public/src/pages/common/monitor/index.js'

const metricCount = function ({ name, tags = {} }) {
  SIMetric.metricCount({
    metric_name: name,
    tags,
  })
}

// 非应发用户
export function Metric_Coupon_Non_Targeted_Users(isLogin) {
  metricCount({
    name: 'coupon_popup_non_targeted_users',
    tags: {
      is_login: isLogin ? '1' : '0',
    }
  })
}
// 应发用户
export function Metric_Coupon_Targeted_Users(isLogin) {
  metricCount({
    name: 'coupon_popup_targeted_users',
    tags: {
      is_login: isLogin ? '1' : '0',
    }
  })
}
// 命中 10min 缓存
export function Metric_Coupon_Popup_Cache_10m(isLogin) {
  metricCount({
    name: 'coupon_popup_cache_10m',
    tags: {
      is_login: isLogin ? '1' : '0',
    }
  })
}
// 命中互斥弹窗
export function Metric_Coupon_Mutual_Exclusion(isLogin) {
  metricCount({
    name: 'coupon_popup_mutual_exclusion',
    tags: {
      is_login: isLogin ? '1' : '0',
    }
  })
}
// 命中屏蔽页面
export function Metric_Coupon_Popup_Hide_Page(isLogin) {
  metricCount({
    name: 'coupon_popup_hide_page',
    tags: {
      is_login: isLogin ? '1' : '0',
    }
  })
}
// 券包曝光
export function Metric_Coupon_Package_Exposure(isLogin) {
  metricCount({
    name: 'coupon_popup_exposure',
    tags: {
      is_login: isLogin ? '1' : '0',
    }
  })
}
// 活动曝光
export function Metric_Coupon_Activity_Exposure({ isLogin, activityType }) {
  metricCount({
    name: 'coupon_popup_activity_exposure',
    tags: {
      is_login: isLogin ? '1' : '0',
      activity_type: activityType,
    }
  })
}
// 弹窗点击
export function Metric_Coupon_Click({ isLogin, clickType }) {
  metricCount({
    name: 'coupon_popup_click',
    tags: {
      is_login: isLogin ? '1' : '0',
      click_type: clickType
    }
  })
}
// 弹窗 shop now 链接
export function Metric_Coupon_Link({ isLogin, LinkType }) {
  metricCount({
    name: 'coupon_popup_link',
    tags: {
      is_login: isLogin ? '1' : '0',
      link_type: LinkType
    }
  })
}
// 弹窗登录成功刷新券包
export function Metric_Coupon_LoginBack() {
  metricCount({
    name: 'coupon_popup_login_back',
  })
}
