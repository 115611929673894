<template>
  <div
    v-expose="{
      id: '1-7-2-20',
      data: {
        order_id: unpaidOrderInfo.billno,
        benefit_list: savedPrice ? 'discount' : 'common',
      }
    }"
    class="cart-unpaid-tip"
  >
    <imgStackGallery
      v-if="imgList.length"
      :img-list="imgList"
      :count="totalNums"
    />
    <div class="unpaid-content">
      <div class="content-up">
        <span class="unpaid-tip">{{ unpaidOrderInfo.waitingPaymentTip }}</span>
        <span
          v-if="showCountDown"
          class="unpaid-countdown"
        >
          <sui_icon_hourglass_12px_1
            size="12px"
            color="#FA6338"
          />
          <CartCountDown
            :time-stamp="unpaidOrderInfo.expireCountdown"
            show-type="inline-promotion"
            not-hide
            not-reload
            class="count-down"
          />
        </span>
      </div>
      <div class="content-down">
        <span class="order-total">
          <span class="order-amount-tip">{{ language.SHEIN_KEY_PC_30611 }}</span>
          <span class="order-amount">{{ ': ' + total }}</span>
        </span>
        <span
          v-if="savedPrice"
          class="order-saved"
        >
          <span class="order-split-line">|</span>
          <span class="order-saved-tip order-amount-tip">{{ language.SHEIN_KEY_PC_30673 }}</span>
          <em class="order-saved-price order-amount">{{ ':' + savedPrice }}</em>
        </span>
      </div>
    </div>
    <div
      v-tap="{
        id: '1-7-2-21',
        data: {
          order_id: unpaidOrderInfo.billno,
          benefit_list: savedPrice ? 'discount' : 'common',
          button_type: 'view'
        }
      }"
      class="view-button"
      @click="handleViewOrder"
    >
      <span class="button-text">{{ language.SHEIN_KEY_PC_19825 }}</span>
      <sui_icon_more_right_12px_1
        size="12px"
        color="#fff"
        :is-rotate="locals.GB_cssRight"
      />
    </div>
  </div>
</template>
<script setup>
import { computed, watch } from 'vue'
import imgStackGallery from './imgStackGallery.vue'
import { sui_icon_hourglass_12px_1, sui_icon_more_right_12px_1 } from '@shein-aidc/icon-vue3'
import CartCountDown from 'public/src/pages/cart_v2/components/functional/common/CartCountDown.vue'
import { stringifyQueryString } from '@shein/common-function'
import { usePageVisibility } from 'public/src/pages/cart_v2/hooks/usePageVisibility'
import { useStore } from 'vuex'
const { state, dispatch } = useStore()

const locals = computed(() => state.locals)

const props = defineProps({
  unpaidOrderInfo: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  }
})

const pageVisibility = usePageVisibility()
watch(pageVisibility, (val) => {
  if(val == 'visible'){
    dispatch('fetchUnpaidOrdersInfo', { force: true })
  }
})

const imgList = computed(() => {
  return props.unpaidOrderInfo.goodsImgList?.slice?.(0, 3) || []
})
const totalNums = computed(() => {
  let count = props.unpaidOrderInfo?.goodsNum
  if(props.unpaidOrderInfo?.goodsImgList?.length == 1) return 0
  if(count > 99) return '99+'
  if(count > 1) return count // 仅有1张图不展示下标
  return 0
})
const total = computed(() => {
  if(props.unpaidOrderInfo?.totalPrice?.amount <= 0) return ''
  return props.unpaidOrderInfo?.totalPrice?.amountWithSymbol
})
const savedPrice = computed(() => {
  if(props.unpaidOrderInfo?.savedPrice?.amount <= 0) return ''
  return props.unpaidOrderInfo?.savedPrice?.amountWithSymbol
})
const showCountDown = computed(() => {
  return props.unpaidOrderInfo?.expireCountdown >= (new Date().getTime()) / 1000
})
function handleViewOrder() {
  const billno = props.unpaidOrderInfo?.billno
  let queryParams = {
    from_type: 7
  }
  if(props.unpaidOrderInfo?.paymentMethod != 'cod'){
    queryParams.show_selected_pay_method = 1
  }
  let url = `/checkout/checkout_again/${billno}?${stringifyQueryString({ queryObj: queryParams })}`
  window.open(`${location.origin}${url}`)
}
</script>
<style lang="less" scoped>
.cart-unpaid-tip {
  display: flex;
  padding: 12px 16px;
  gap: 10px;
  align-items: center;
  border: 1px solid rgba(250, 99, 56, 0.30);
  background: var(---sui_color_promo_bg, #FFF6F3);
  margin-top: 10px;
}
.unpaid-content {
  flex: 1;
  overflow: hidden;
}
.content-up, .content-down {
  display: flex;
  align-items: center;
  width: 100%;
}
.content-up {
  gap: 8px;
  .unpaid-tip {
    font-size: 14px;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .unpaid-countdown {
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }
}
.count-down {
  display: inline-block;
  margin-left: 2px;
  margin-top: unset;
  &::v-deep span.count-number {
    background: unset;
    font-weight: 500;
  }
  &::v-deep span.time-content {
    color: @sui_color_discount;
    /* stylelint-disable-next-line selector-max-type */
    span { color: @sui_color_discount; }
  }
}
.content-down {
  font-size: 12px;
  margin-top: 2px;
  .order-total {
    color: rgba(0, 0, 0, 0.6);
    flex-shrink: 0;
    overflow: hidden;
    display: flex;
    max-width: 100%;
  }
  .order-saved {
    color: @sui_color_discount;
    overflow: hidden;
    display: flex;
    .order-split-line {
      color: rgba(34, 34, 34, 0.30);
      margin-left: 6px;
      margin-right: 6px;
    }
    .order-saved-price {
      font-weight: 700;
    }
  }
  .order-amount-tip {
    flex-shrink: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: 15px;
  }
  .order-amount {
    flex-shrink: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.view-button {
  display: flex;
  align-items: center;
  min-width: 40px;
  max-width: 66px;
  padding: 5px 8px;
  border-radius: 28px;
  background: #FA6338;
  height: 24px;
  cursor: pointer;
  .button-text {
    font-size: 11px;
    font-weight: 590;
    color: @sui_color_white;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>
