import schttp from 'public/src/services/schttp'
import { abtservice } from 'public/src/services/abt'
import { dealProductsPretreatInfo } from 'public/src/services/goodsItemInfo/goodsPretreatInfo.js'

export const getPageAbt = async () => {
  // 凑单 相关
  const newKeys = ['addnewhotJson', 'Cartshowcoupon', 'orderCouponNewUser', 'CartaddCouponBottomStyle', 'CartaddPromotionBottomStyle', 'cartautopoints']
  const [abtInfo] = await Promise.all([
    // eslint-disable-next-line @shein-aidc/abt/abt
    abtservice.getUserAbtResult({ newPosKeys: newKeys.join(',') }),
  ])
  return {
    ...abtInfo,
  }
}

export const getLanguage = async () => {
  const res = await schttp({ 
    url: '/api/common/language/get', 
    params: { page: 'filter' }
  })
  return res.language || {}
}

/* 获取推荐列表数据 */
export const getLists = (params) => {
  return schttp({
    url: `/api/recommend/facadeAtom/get`,
    method: 'POST',
    data: params
  })
}

/* 兜底推荐容错列表接口 */
export const getInsuranceList = (params) => {
  return schttp({
    url: `/api/recommend/faultFacade/query`,
    method: 'POST',
    data: params
  })
} 

const getIntegrityPromotionInfo = async (products) => {
  const ids = []
  const asyncPromotionIds = ['2', '14', '15', '24', '28']
  const new_products = []

  products?.forEach(item => {
    let new_item = { promotionInfo: [] }
    item.promotionInfo?.forEach(info => {
      const id = String(info.id)
      const typeId = String(info.typeId)
      if (asyncPromotionIds.includes(typeId) && !ids.includes(id)) {
        ids.push(id)
        new_item.promotionInfo.push({
          id,
          typeId,
        })
      }
    })
    if(new_item.promotionInfo.length) {
      new_products.push(new_item)
    }
  })
  
  if(!new_products.length) { 
    return {} 
  }
  const { data: promotionInfoFromServer = {} } = await schttp({
    method: 'POST',
    url: '/api/productInfo/postIntegrityPromotionInfo/get',
    data: {
      products: new_products,
    }
  })
  return promotionInfoFromServer
}

export const getInterfaceList = (params, itemConfig) => {
  return new Promise((resolve, reject) => {
    getLists(params)
      .then(async (res) => {
        let products = res?.info?.products
        try {
          // 实时获取促销信息
          let promotionInfoFromServer = {}
          try {
            promotionInfoFromServer = await getIntegrityPromotionInfo(products)
          } catch (error) {
            console.error('getIntegrityPromotionInfo', error)
          }
          
          // const { data: promotionInfoFromServer = {} } = await schttp({
          //   method: 'POST',
          //   url: '/api/productInfo/postIntegrityPromotionInfo/get',
          //   data: {
          //     products,
          //   },
          // })
          products = dealProductsPretreatInfo({
            products,
            itemConfig: itemConfig,
            promotionInfoFromServer,
          })
        } catch (error) {
          // console.log('promotionInfoFromServer', error)
        }

        resolve({ ...res, products, total: res?.info?.total })
      })
      .catch(() => {
        getInsuranceList({
          ...params,
          rec_type: 1, // 推荐类型: (1:商品  4:filter类目)
          pagename: 'page_for_freeshipping',
        })
          .then(async (res) => {
            resolve({ ...res, products: res?.info?.products, total: res?.info?.total, isFaultTolerant: true })
          })
          .catch(reject)
      })
  })
}

/* 获取券数据 */
export const getCouponInfo = (data) => {
  const { currency, appLanguage } = window.gbCommonInfo
  return schttp({
    method: 'POST',
    url: '/coupon/addItemInfo',
    data: data,
    headers: {
      'AppCurrency': currency,
      'AppLanguage': appLanguage
    },
    useBffApi: true
  })
}
