import { bffVerifyTest } from './core.js'
import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new/index'

function idleAbtCheck() {
  const ABT_BFF_DIFF_KEY = 'abt_bff_diff'
  const data = isSwitchBffApiVersion([ABT_BFF_DIFF_KEY])
  if (!data[ABT_BFF_DIFF_KEY]) return
  setTimeout(() => {
    requestIdleCallback(() => {
      bffVerifyTest()
    })
  }, 3000)
}

export default idleAbtCheck
