<template>
  <!-- csr 占位 -->
  <div
    v-if="needSkeleton"
    :style="{ minHeight: `${bannerHeight}px` }"
    class="bsc-top-banner-csr-placeholder">
  </div>
  <div
    v-if="topBannerContent && topBannerContent.length"
    class="bsc-publicbanner-config-top-banners not-fsp-element"
  >
    <div
      class="j-da-event-box bsc-publicbanner-topbanner-container"
    >
      <div
        class="j-config-top-banner config-top-banner"
        role="link"
        :tabindex="0"
      >
        <div class="config-wrap j-topbanner-fixer">
          <AppCcc
            :content="topBannerContent"
            :context="context"
            :style="{ paddingRight: `${paddingRight}` }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script name="BTopBanner" setup lang="ts">
import { ref, onMounted, onUnmounted, provide, nextTick } from 'vue'
import { debounce } from '@shein/common-function'
import { transfromAndCutImg } from './ccc/common/utils.js'
import AppCcc from './ccc/CccContainer.vue'
// 数据源：
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import type { AS_TopBanner, DS_TopBanner } from '../../types'
import { getSource } from '../../common/dataSource'

const appConfigs = useAppConfigs()
const apis = getSource({ appConfigs })
const { RESOURCE_SDK, project } = appConfigs.$envs || {}

const props = defineProps<{
  fsData?: DS_TopBanner.FS_DATA
  dataSource?: DS_TopBanner.DataSource
  analysisSource?: AS_TopBanner.AnalysisSource
  bannerContent?: Array<any>
  // 不支持 ssr 的接入方, 可以通过 showSkeleton 控制是否展示骨架屏
  showSkeleton?: boolean
}>()

import { useAnalysis } from '../../common/analysisSource'
const analysisInstance = useAnalysis(props.analysisSource)
provide('analysisInstance', analysisInstance)

provide('cutImg', (imgUrl, designWidth, exp) => {
  const {
    deviceData = '',
    isSupportWeb = '',
    isSupprotCut = false,
    sceneMap,
  } = RESOURCE_SDK || {}

  const cutData = {
    deviceData,
    isSupportWebp: Boolean(isSupportWeb),
    isSupprotCut,
    imgUrl,
    designWidth: Number(designWidth),
    sceneMap,
    exp,
  }

  return transfromAndCutImg(cutData)
})

const context = appConfigs.$envs
const bannerHeight = ref(48)
const paddingRight = ref('')
const needSkeleton = ref(props.showSkeleton || false)
const hasTopbanner = ref(true)

interface BannerContent {
  styleType: string;
  hotZones?: {
    standard?: any[];
    timeTransform?: any[];
    label?: any[];
  };
  props?: {
    items?: {
      image?: {
        ratio?: [number,string];
      };
    }[];
  };
}
const topBannerContent = ref<BannerContent[]>(props.bannerContent || [])
const showHotZone = ref(false)
const bannerRatio = ref(1)

provide('getBannerHeight', () => bannerHeight.value)

const getTopBannerContent = async () => {
  const res = await apis.topBannerFetch()
  topBannerContent.value = res?.info?.content || []
  needSkeleton.value = false
  if (topBannerContent.value && topBannerContent.value?.length) {
    for (const key in topBannerContent.value) {
      if (Object.prototype.hasOwnProperty.call(topBannerContent.value, key)) {
        const element = topBannerContent.value[key]
        if (element.styleType === 'ONE_IMAGE_COMPONENT') {
          showHotZone.value = element.hotZones?.standard?.length || element.hotZones?.timeTransform?.length || element.hotZones?.label?.length ? true : false
          if (element?.props?.items?.[0]?.image?.ratio) {
            bannerRatio.value = Number(element?.props?.items?.[0]?.image?.ratio)
          }
        }
      }
    }
  } else {
    hasTopbanner.value = false
  }
  resizeHandle()
}

const showHotZoneMounted = (windowWidth) => {
  nextTick(() => {
    const hotzoneElement = document.querySelector('.j-topbanner-fixer .bsc-ccc-hotzone') as HTMLElement
    if (hotzoneElement) {// 热区宽高自适应
      if (windowWidth < 1446) {
        // 小屏实时赋值宽高
        hotzoneElement.style.width = (bannerHeight.value * bannerRatio.value) + 'px'
        hotzoneElement.style.height = '100%'
      } else {
        // 大屏仅赋值一次
        const width = hotzoneElement.offsetWidth
        if (width !== 2560) {
          hotzoneElement.style.width = '2560px'
          hotzoneElement.style.height = '48px'
        }
      }
    }
  })
}

const resizeHandle = () => {
  let widthWithoutScrollbar = document.documentElement.clientWidth
  const scrollbarWidth = window.innerWidth - widthWithoutScrollbar
  paddingRight.value = `${scrollbarWidth ? scrollbarWidth + 'px' : 'none'}`
  widthWithoutScrollbar = widthWithoutScrollbar - 1 // 解决页面稍宽出现底部滚动条
  if (widthWithoutScrollbar < 1446) {
    // 小于1446时，与首页内容对其，减首页内容边距后计算高度
    const imgWidth = widthWithoutScrollbar - 48 - 48
    bannerHeight.value = imgWidth / 1350 * 48
  } else {
    bannerHeight.value = 48
  }
  if (showHotZone.value) {
    showHotZoneMounted(widthWithoutScrollbar)
  }
}

onMounted(() => {
  // pc 项目已经在 ssr 时请求过数据，csr 时不再请求
  if (project !== 'pc') {
    getTopBannerContent()
  }
  window.addEventListener('resize', debounce({
    func: () => {
      resizeHandle()
    },
    wait: 200,
  }))
})

onUnmounted(() => {
  window.removeEventListener('resize', resizeHandle)
})

defineExpose({
  hasTopbanner,
})
</script>

<style lang="less">
.bsc-top-banner-csr-placeholder {
  background: #f5f5f5;
}

.bsc-publicbanner-topbanner-container {
  position: relative;
  cursor: default;
  z-index: 701;
}
.bsc-publicbanner-config-top-banners {  /* stylelint-disable-line */
  .config-top-banner {
    cursor: pointer;
    .config-wrap {
      position: fixed;
      left: 0 /* rtl:ignore */;
      z-index: 400;
      width: 100vw;
      height: 48px;
      transition: transform 0.3s;
      overflow: hidden;
      background-color: #fff;
      box-sizing: content-box;
      .bsc-ccc-hotzone { // topbanner图片 固定2560*48大小，热区以图片大小为边界
        position: absolute;
        width: 2560px;
        height: 48px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .bsc-static-image {
      min-height: 48px;
      .sui-image__inner {
          margin: auto;
          width: 100%;
          height: calc(48 * (100vw - 97px) / 1350);
          max-height: 48px;
          max-width: 2560px;
          object-fit: cover;
          display: block;
      }
      @media (min-width: 2560px) {
        .sui-image__inner {
            object-fit: contain;
        }
      }
    }
    /* stylelint-disable-next-line no-invalid-double-slash-comments */
    .bsc-static-image,
    // 倒计时banner
    .bsc-banner-countdown {
      height: 100%;
      @media (min-width: 2560px) {
        // 大于2560，两侧留白
        /* stylelint-disable-next-line selector-max-specificity */
        .sui-image__inner {
          object-fit: contain
        }
      }
    }
  }
  /* stylelint-disable-next-line selector-class-pattern */
  .j-config-top-banner.config-top-banner {
    display: block;
    width: 100%;
    height: 100%;
    height: 48px;
    transition: height 0.5s;
    background-color: #fff;
  }
  [tabindex='0'] {
    outline: 0;
  }
}
</style>
