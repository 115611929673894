<template>
  <div class="bsg-useage-container">
    <p class="bsg-useage-container__title">{{ language?.SHEIN_KEY_PC_33273 }}</p>
    <div class="bsg-useage-container__steps">
      <div
        v-for="(item, index) in steps"
        :key="index"
        class="bsg-useage-container__step-item"
        :style="{'flex': (index === steps.length-1) ? 1 : 2}"
      >
        <div class="bsg-useage-container__step-item_container">
          <img
            :src="item?.imgUrl"
            :alt="item?.desc"
            class="bsg-useage-container__step-item_img"
          />
          <div class="bsg-useage-container__step-item_desc">{{ item?.desc }}</div>
        </div>
        <div
          v-if="index != steps.length-1 "
          class="bsg-useage-container__step-item_line"
        ></div>
      </div>
    </div>

    <div class="bsg-useage-container__step-desc">
      <p class="bsg-useage-container__step-desc_title">{{ language?.SHEIN_KEY_PC_33276 }}</p>
      <p class="bsg-useage-container__step-desc_text">{{ language?.SHEIN_KEY_PC_33277 }}</p>
      <p class="bsg-useage-container__step-desc_text">{{ language?.SHEIN_KEY_PC_33278 }}</p>
      <p class="bsg-useage-container__step-desc_text">{{ language?.SHEIN_KEY_PC_33279 }}</p>
    </div>
  </div>
</template>

<script name="BBindGiftCard" setup lang="ts">

const props = defineProps<{
  language?: any
}>()

const steps = [
  {
    imgUrl: 'https://img.ltwebstatic.com/images3_ccc/2024/11/28/b2/17327821904d9c7e9ec5ee40992365d285bf8cf92b.webp',
    desc: props.language?.SHEIN_KEY_PC_33274,
  },
  {
    imgUrl: 'https://img.ltwebstatic.com/images3_ccc/2024/11/28/a9/17327822242bd670729abfa07c95a69f397b93b179.webp',
    desc: props.language?.SHEIN_KEY_PC_33272,
  },
  {
    imgUrl: 'https://img.ltwebstatic.com/images3_ccc/2024/11/28/fc/173278213831788ee8692686743d1a267e74991bf4.webp',
    desc: props.language?.SHEIN_KEY_PC_33275,
  },
]


</script>
<style lang="less">
.bsg-useage-container {
  background: #FFF9F8;
  padding: 18px 32px;
  font-family: "SF Pro";
  margin-top: 20px;
  &__title {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  &__steps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    gap: 10px;
  }
  &__step-item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex: 2;
    &_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 3px;
      width: 100px;
      height: 66px;
      border-radius: 50% 50% 0 0;
      background: linear-gradient(to bottom, #FFFFFF, #FFF9F8);
      padding: 12px 0 3px;
    }
    &_line {
      flex-grow: 1;
      height: 1px;
      background-image: repeating-linear-gradient(
        to right,
        transparent,
        transparent 5px,
        #FF4238 5px,
        #FF4238 10px
      );
    }
    &_img {
      width: 34px;
    }
    &_desc {
      color: #7F4343;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 510;
      line-height: normal;
    }
  }
  &__step-item-flex1 {
    flex: 1;
  }
  &__step-desc {
    margin-top: 16px;
    text-align: left;
    &_title {
      color: #7F4343;
      font-size: 12px;
      font-style: normal;
      font-weight: 510;
      line-height: normal;
      margin-bottom: 6px;
    }
    &_text{
      color: #976D6D;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
    }
  }
}
</style>
