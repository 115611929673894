<template>
  <div
    :class="[
      rootClassName,
      showPosition,
      {
        'hover-bubble': showPromotionBubble,
      }
    ]"
  >
    <!-- 促销全场活动：满折、满减、返券 -->
    <!-- PS:根节点层级的注释会影响测试环境打包时, 父组件透传属性如class之类 -->
    <section
      class="section promotion-sec new-promotion-sec"
      :class="[showPosition == 'top' ? 'promotion-top' : 'mall-promotion']"
      @mouseenter="handlePromotionBubble(true)"
      @mouseleave="handlePromotionBubble(false)"
    >
      <div
        class="section-l"
        :class="{ 'section-l__center': isSingleTips }"
      >
        <!-- Icon组件不支持响应式name -->
        <span style="width: 18px">
          <component
            :is="fullPromotionIconName"
            :size="showPosition == 'top' ? '18px' : '16px'" 
          />
        </span>
      </div>
      <div
        class="section-r"
      >
        <p
          v-if="showPosition == 'top' && !isSingleTips"
          class="title"
        >
          {{ language.SHEIN_KEY_PC_30662 }}({{ promotions.length }})
          <a
            v-if="promotions.length == 1 && promotions[0].data.isMeet != 1"
            class="operation"
            tabindex="0"
            role="button"
            @keydown.stop.enter="handleSupplement(promotions[0])"
            @click="handleSupplement(promotions[0])"
          >
            {{ language.SHEIN_KEY_PC_15215 }}
            <sui_icon_more_right_12px
              :is-rotate="locals.GB_cssRight"
              size="12px"
            />
          </a>
        </p>
        <swiper-container
          ref="swiperContainer"
          init="false"
          class="full-activity-contain"
          :class="['j-swiper-container-promotion-'+mallCode]"
        >
          <swiper-slide
            v-for="(item, index) in promotions"
            :key="'promotion-slide-item-' + index"
            class="promotion-slide-item"
            :class="`j-promotion-header-${item.data.promotion_id}`"
          >
            <div
              v-expose="analysisData('1-7-5-1', item.data)"
              class="promotion-slide-content"
              :style="{ alignItems: isSingleTips ? 'center' : 'initial' }"
            >
              <section
                :class="{ 'promotion-slide-content__section': isSingleTips }"
              >
                <div class="promotion-full-tip">
                  <span
                    v-if="isShowNewUserTag && item.data?.newUserReturnCouponTips"
                    class="promotion-full-tip__tag"
                  >
                    {{ item.data.newUserReturnCouponTips }}
                  </span>
                  <span
                    class="promotion-full-tip__text"
                    v-html="item.promotionTips && item.promotionTips.html_text || ''"
                  ></span>
                </div>
                <div
                  v-if="processCount(item) < 100 && item.data.type_id == '21' && isSingleTips"
                  class="item-content-tip__process"
                >
                  <div
                    class="item-content-tip__process-bg"
                    :style="{ width: `${processCount(item)}%` }"
                  ></div>
                </div>
              </section>

              <a
                v-if="((showPosition == 'top' && promotions.length > 1) || isSingleTips || (showPosition == 'mall' && promotions.length == 1)) && item.data.isMeet != 1"
                class="operation"
                tabindex="0"
                role="button"
                @keydown.stop.enter="handleSupplement(item)"
                @click="handleSupplement(item)"
              >
                {{ language.SHEIN_KEY_PC_15215 }}
                <sui_icon_more_right_12px
                  :is-rotate="locals.GB_cssRight"
                  size="12px"
                />
              </a>
              <sui_icon_more_24px
                v-else-if="showPosition == 'mall' && promotions.length > 1"
                :size="showPosition == 'top' ? '18px' : '16px'"
              />
            </div>
            <div
              v-if="processCount(item) < 100 && item.data.type_id == '21' && !isSingleTips"
              class="item-content-tip__process"
            >
              <div
                class="item-content-tip__process-bg"
                :style="{ width: `${processCount(item)}%` }"
              ></div>
            </div>
            <ClientOnly>
              <count-down-time
                v-if="item.data.is_count_down"
                :time-stamp="Number(item.data.end_time)"
                show-type="inline-promotion"
                :show-end-tips="true"
              />
            </ClientOnly>
          </swiper-slide>
        </swiper-container>
      </div>

      <!-- hover气泡 -->
      <HoverPromotionTopTip
        v-if="showPromotionBubble && promotions.length > 1"
        :language="language"
        :full-court-map="fullCourtBubbleMap"
        :show-position="showPosition"
        tip-type="bubble"
        :size="showPosition == 'top' ? '18px' : '16px'"
        :locals="locals"
        @handle-supplement="handleSupplement"
      />
    </section>
  </div>
</template>
<script>
export default {
  name: 'FullPromotionInfoTip'
}
</script>
<script setup>
import { useStore } from 'vuex'
import { Autoplay } from 'swiper/modules'
import { computed, ref, watch, nextTick, onMounted } from 'vue'
import countDownTime from 'public/src/pages/cart_v2/components/functional/common/CartCountDown.vue'

import HoverPromotionTopTip from './HoverPromotionTopTip.vue'
import { sui_icon_activity_24px, sui_icon_more_right_12px, sui_icon_selected_24px, sui_icon_couponbundle_18px, sui_icon_coupon_discount_24px, sui_icon_more_24px } from '@shein-aidc/icon-vue3'
import { useCartAddItem } from 'public/src/pages/cart_v2/hooks/useCartAddItem.js'

import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
daEventCenter.addSubscriber({ modulecode: '1-7-5' })

const { state, getters } = useStore()
const AddItem = useCartAddItem()

const props = defineProps({
  promotions: {
    type: Array,
    default: () => ([])
  },
  // 是否仅促销一个提示
  isSingleTips: {
    type: Boolean,
    default: true
  },
  rootClassName: {
    type: String,
    default: ''
  },
  showPosition: {
    type: String,
    default: 'mall' // 默认值为mall, 展示在顶部时为top
  },
  showBubbleFn: {
    type: Function,
    default: () => {}
  },
  mallCode: {
    type: String,
    default: ''
  }
})

let showPromotionBubble = ref(false)
let navSwiper = null
let swiperContainer = ref(null)
let fullCourtBubbleMap = ref([])

const language = computed(() =>state.language)
const locals = computed(() => state.locals)
const isShowNewUserTag = computed(() => getters.isShowNewUserTag)
const fullPromotionIconName = computed(() => {
  const fullReturnCoupon = props.promotions.find(item => item.data.type_id == 21)
  const { isMeet, type_id, coupon_num } = fullReturnCoupon?.data || {}
  return props.promotions.length > 1 
    ? sui_icon_activity_24px
    : isMeet == 1
      ? sui_icon_selected_24px
      : (type_id == 21 && coupon_num > 1 ? sui_icon_couponbundle_18px : sui_icon_coupon_discount_24px)
})

watch(() => props.promotions, (n) => {
  if(n?.length > 1){
    if(typeof window == 'undefined') return
    nextTick(()=>{
      if(navSwiper){
        resizeSwiper()
      } else {
        initPromtionSwiper()
      }
    })
  }
}, { immediate: true })

const processCount = (item) => {
  return +(item.data?.promotionPopupInfo?.progressPercent) * 100
}

onMounted(() => {
  if(props.promotions.length > 1){
    nextTick(()=>{
      initPromtionSwiper()
      setTimeout(()=>{
        resize()
      }, 1000)
    })
  }
})

const resize = () => {
  if(navSwiper){
    resizeSwiper()
  } else {
    initPromtionSwiper()
  }
}

const initPromtionSwiper = () => {
  resizeSwiper()

  const config = {
    modules: [Autoplay],
    slidesPerView: 'auto',
    direction: 'vertical',
    allowTouchMove: false,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    observer: true,
    observeParents: true,
  }
  Object.assign(swiperContainer.value, config)
  swiperContainer.value?.initialize?.()
  navSwiper = swiperContainer.value?.swiper
}

const resizeSwiper = () => {
  let maxSliderHeight = 0
  const sliders = document.querySelectorAll(`.j-swiper-container-promotion-${props.mallCode} .promotion-slide-item`)
  sliders.forEach(item => {
    const promotionContentHeight = item.querySelector('.promotion-slide-content')?.getBoundingClientRect().height || 0
    const $countDownEl = item.querySelector('.c-count-down-time')
    let countDownHeight = 0
    let countDownMarginTop = 0
    if($countDownEl) {
      let computedStyle = window.getComputedStyle($countDownEl)
      let displayValue = computedStyle.getPropertyValue('display')
      if (displayValue !== 'none') {
        countDownHeight = $countDownEl.getBoundingClientRect().height || 0
        countDownMarginTop = parseInt(computedStyle.getPropertyValue('margin-top'), 10) || 0
      }
    }

    let processHeight = 0
    if(!props.isSingleTips) { // not single tip, process is outside of promotion content
      const processEle = item.querySelector('.item-content-tip__process')
      if(processEle) {
        let computedStyle = window.getComputedStyle(processEle)
        processHeight = (processEle.getBoundingClientRect().height || 0) + (parseInt(computedStyle.getPropertyValue('margin-top'), 10) || 0)
      }
    }

    const computedHeight = promotionContentHeight + countDownHeight + countDownMarginTop + processHeight

    if (computedHeight > maxSliderHeight) maxSliderHeight = computedHeight
  })

  let swiperContainerPromotionDom = document.querySelector(`.j-swiper-container-promotion-${props.mallCode}`)
  if(swiperContainerPromotionDom) {
    swiperContainerPromotionDom.style.height = `${maxSliderHeight}px`
  }
}

const handlePromotionBubble = (showBubble) => {
  if(showBubble && props.showPosition != 'top') return 
  if (navSwiper) {
    if (showBubble) {
      navSwiper.autoplay?.stop?.()
      fullCourtBubbleMap.value = arrStep(props.promotions, navSwiper.activeIndex)
    } else {
      navSwiper.autoplay?.start?.()
    }
  }
  showPromotionBubble.value = showBubble
  props.showBubbleFn(showBubble)
}

const arrStep = (arr, step) => {
  if (step >= arr.length) return step
  const arrClone = [].concat(arr)
  const stepItems = arrClone.splice(0, step)
  return arrClone.concat(stepItems)
}

const handleSupplement = (item) => {
  daEventCenter.triggerNotice(analysisData('1-7-5-2', item.data))
  AddItem.show({
    popupInfoId: item.data.popupInfoId,
    state: 'promotion_add',
  })
}

const analysisData = (id, data) => {
  let { promotion_id, type_id, isMeet, newUserReturnCouponTips } = data
  if(isMeet == 1) {
    return {}
  }
  const { isFullPromotion } = state.promotionState.cartPromotionAnalysis?.[promotion_id] || {}
  return {
    id,
    data: {
      id: promotion_id,
      typeId: type_id,
      isFullPromotion: isFullPromotion,
      newuser_label: type_id == 21 && newUserReturnCouponTips ? 1 : 0,
      is_member_gift: 0,
    }
  }
}
</script>
<style lang="less">
.operation{
  &:hover{
    text-decoration: none;
  }
}
.promotion-full-tip{
  .promotion-full-tip__tag {
    display: inline-block;
    padding: 0 4px;
    margin-right: 4px;
    max-width: 86px;
    border-radius: 2px;
    background: linear-gradient(93deg, #FF858F -10.15%, #FF6A76 -10.14%, #FF3351 33.79%, #FF3351 75.48%, #EF2649 113.06%), linear-gradient(90deg, #FF858F 0%, #FF3351 100%), linear-gradient(57deg, #FF63C2 -35.79%, #FF4142 41.52%, #FFFC33 108.14%);
    font-size: 10px;
    color: @sui_color_white;
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .promotion-full-tip__text {
    em{
      color: @sui_color_welfare_dark;
    } 
  }
}
.section{
  position: relative;
  flex: 1;
  padding: 12px;
  .flexbox();
  .margin-r(12px);
  word-break: break-word;
  max-width: 100%;
  .section-l{
    width: 18px;
    .margin-r(8px);
    &__center {
      display: flex;
      align-items: center;
    }
  }
  &.mall-promotion{
    margin-right: 0;
    padding: 12px 12px 12px 16px;
  }
  .section-r{
    flex: 1;
    overflow: hidden;
    .operation {
      white-space: nowrap;
      color: #222222;
      font-weight: bold;
      font-size: 12px;
      cursor: pointer;
      /* stylelint-disable-next-line selector-max-specificity */
      &:hover{
        text-decoration: none;
      }
    }
  }
  .title{
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
    line-height: 25px;
    .flexbox();
    .space-between();
  }
  &:last-child{
    .margin-r(0);
  }
  &.shipping-sec{
    background:@sui_color_safety_bg;

    .subtitle {
      line-height: 20px;
      color: #666;
      font-size: 13px;
      margin-top: 4px;
      /* stylelint-disable-next-line selector-max-specificity */
      .subtitle-tip {
        vertical-align: middle;
      }
    }
  }
  &.promotion-sec{
    background:@sui_color_promo_bg;
  }
}
.hover-bubble{
  position: absolute;
  .left(0px);
  top: 0px;
  background: #FFF4EB;
  z-index: @zindex-hover;
  box-shadow: 0px 2px 12px 0px rgba(111, 50, 0, 0.12);
  width: 100%;
  .full-activity-contain{
    width: calc(~"100% + 1px");
    .promotion-slide-item{
      margin-top: 24px;
      /* stylelint-disable-next-line selector-max-specificity */
      &:first-child{
        margin-top: 0px;
      }
    }
  }
}
.full-activity-contain{
  .promotion-slide-item{
    position: relative;
    font-size: 13px;
    line-height: 14px;
    span{
      vertical-align:middle;
    }
    .operation{
      white-space: nowrap;
      display: inline-block;
      cursor: pointer;
      /* stylelint-disable-next-line selector-max-specificity */
      &:hover{
        text-decoration: none;
      }
    }
    .cart__down-time{
      font-weight: normal;
      margin-top: 8px;
      font-size: 12px;
      color: @sui_color_highlight;
      /* stylelint-disable-next-line selector-max-specificity */
      .time-content{
        font-weight: normal;
        vertical-align: baseline;
        color: @sui_color_highlight;
        /* stylelint-disable-next-line selector-max-specificity */
        span{
          display: inline;
          font-weight: normal;
          color: @sui_color_highlight;
        }
      }
    }
    .promotion-slide-content{
      .flexbox();
      .space-between();
      span{
        flex: 1;
      }
      /* stylelint-disable-next-line selector-max-specificity */
      .promotion-slide-content__section {
        max-width: 100%;
        .margin-r(12px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      /* stylelint-disable-next-line selector-max-specificity */
      section:not(.promotion-slide-content__section) {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
      }
    }
    .item-content-tip__process {
      width: 100%;
      height: 3px;
      background-color: @sui_color_gray_light2;
      border-radius: 2px;
      margin-top: 4px;
    }
    .item-content-tip__process-bg {
      height: 3px;
      background-color: @sui_color_main;
      border-radius: 2px;
      transition: width .5s linear;
    }
  }
}

</style>

<style lang="less" scoped>
div.section.top {
  padding: 0;
  &.hover-bubble {
    overflow: unset;
  }
}
</style>
