import API_LOCATION from 'public/src/pages/detail/main/services/apis.js'
import schttp from 'public/src/services/schttp'
import qs from 'qs'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'

/**
 * @description 商详 xhr 请求发起处
 */

/**
 * 获取 CCCX banner
 * @param {*} goods_sn
 * @returns 
 */
export const getCCCXBanner = (goods_sn) => {
  const PAGE_TYPE = 'productDetailsPageNew'
  const CATE_TYPE = 3
  return schttp({
    method: 'GET',
    url: API_LOCATION.CCCX_AD_BANNER,
    useBffApi: true,
    params: {
      cccPageType: PAGE_TYPE,
      cateType: CATE_TYPE,
      cateId: goods_sn
    },
  })
}

/**
 * 获取 细节图
 * @param {*} goods_id
 * @returns 
 */
export const getDetailsPictures = (goods_id) => {
  return schttp({
    method: 'GET',
    url: API_LOCATION.PRODUCT_DETAIL_DETAIL_IMAGE,
    useBffApi: true,
    params: {
      goods_id
    },
  })
}

/**
 * 获取 买家秀
 * @param {*} 
 * @returns 
 */
export const getBuyerShowFloor = (args) => {
  const { goods_sn = '', goods_id = '', page = 1, pageSize = 20, product_relation_id = '' } = args || {}
  return schttp({
    method: 'GET',
    url: API_LOCATION.BUYER_SHOW_FLOOR,
    useBffApi: true,
    params: {
      goodsId: goods_id,
      goodsSn: goods_sn,
      page,
      pageSize,
      spu: product_relation_id,
    },
  })
}

/**
 * 获取 新搭配
 * @param {*} 很多
 * @return
 */
export const getNewOutfitInfo = (args) => {
  const { 
    goods_id,
    cat_id,
    goods_sn,
    is_paid_user,
    mall_code,
    is_sold_out,
    attribute = '', 
    isAddCart = '0',
    isEnterDetailCall = '1',
    isSelectCollocationGood,
    relationCatId,
    relationGoodsId,
    selectedGoodsIdSkus,
    updateGoodsId,
    isSwitchRec,
    switchDetailSeriesNo,
  } = args || {}

  const params = {
    goodsId: goods_id,
    catId: cat_id,
    goodsSn: goods_sn,
    isPaidMember: !!is_paid_user ? '1' : '0',
    isMainGoodsSoldOut: !!is_sold_out ? '1' : '0',
    mallCode: mall_code,
    attribute,
    isAddCart,
    isEnterDetailCall,
    isSelectCollocationGood,
    relationCatId,
    relationGoodsId,
    selectedGoodsIdSkus,
    updateGoodsId,
    isSwitchRec,
    switchDetailSeriesNo,
  }
  return schttp({
    url: API_LOCATION.PRODUCT_GET_NEW_COMPANION,
    params: params,
    useBffApi: true
  })
}

/**
 * 底部推荐位接口
 */
export const getBottomRecommendDataApi = async ({
  limit = 20,
  page = 1,
  recommendScene,
  shopRecProductIds,
  goodsSn,
  cat_id,
  goods_id,
  pageListType,
  attribute,
  categoriesType,
  filter_cate_id,
  isAddCart,
  pcReqNum,
  featureKey, // 是否夹带特征值
} = {}) => {

  if (!goods_id || !cat_id || !goodsSn) throw 'params error'

  const params = {
    isAppRollBack: 0, // 使用新链路
    /** 页码 */
    page,
    /** 每页多少 */
    limit,
    /** 需要透传给推荐的参数 */
    pageListType,
    /** 筛选条件 */
    attribute,
    /** 商品品类id */
    cat_id,
    /** 类目类型 */
    categoriesType,
    /** 筛选品类id */
    filter_cate_id,
    /** 商品goodsSn */
    goodsSn,
    /** 商品id */
    goods_id,
    recommendScene,
    /** 来源 */
    caller: 'sheinPc',
    shopRecProductIds,
    /** 当前商品是否有加车操作 */
    isAddCart,
    /** 请求推荐总数，pc底部推荐位使用 */
    pcReqNum,
  }

  const res = await schttp({
    method: 'POST',
    url: API_LOCATION.GET_DETAIL_RECOMMEND_PRODUCT,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: qs.stringify(params),
    useBffApi: true,
    featureKey,
  })

  // console.log('getBottomRecommendData', res)
  return res
}

/**
  return res
 * @description: 获取组合购数据
 * @param {*} 
 * @return {*}
 */
export const getTogetherBetterDealsPrice = (params = {}) => {
  const { 
    goods_id, 
    cat_id, 
    goods_sn,
    mall_code, 
    isEnterDetailCall, 
    is_sold_out,
    is_paid_user,
    selectedGoodsIdSkus,
    updateGoodsId
  } = params
  
  return schttp({
    method: 'GET',
    url: API_LOCATION.RECOMMEND_GET_TOGETHER_BETTER,
    params: {
      catId: cat_id,
      goodsId: goods_id,
      goodsSn: goods_sn,
      mallCode: mall_code,
      isAddCart: '1',
      isEnterDetailCall,
      isMainGoodsSoldOut: is_sold_out ? '1' : '0',
      isPaidMember: is_paid_user ? '1' : '0',
      selectedGoodsIdSkus,
      updateGoodsId,
    },
    useBffApi: true
  })
}

/**
 * 批量加车接口
 * @param {object} params
 * @param {array} product_list[必填] 商品id
 */
export const batchAddToCart = (params) => {
  return schttp({
    url: API_LOCATION.GOODS_ADD_TO_CART_BATCH,
    method: 'POST',
    data: params,
    useBffApi: true,
  })
}

/**
 * 批量绑劵接口
 * @param {object} params
 * @param {array} coupon_code[必填] 商品id
 */
export const batchBindCoupon = (params) => {
  return schttp({
    url: API_LOCATION.BATCH_BIND_COUPON,
    method: 'POST',
    data: params,
    useBffApi: true,
  })
}

/**
 * 商品相似推荐列表
 * 商品和分类id， eg：991471_2301	
 */
export const getSimilarListRecommendDataApi = async ({ limit, page = 1, goods_id, cat_id }) => {

  if (!goods_id || typeof cat_id === 'undefined') return
  const goods_list = `${goods_id}_${cat_id}`
  const abtInfo = await getUserAbtData()
  const { similaritemsnew } = abtInfo || {}

  let rule_id = ''
  const matchResult = similaritemsnew?.param?.match(/rule_id=([^&]+)/)
  rule_id = matchResult && matchResult.length >= 2 ? matchResult[1] || '' : ''

  return schttp({
    method: 'POST',
    url: API_LOCATION.RECOMMEND_SIMILAR_LIST,
    params: {
      limit,
      rule_id,
      page,
      goods_list,
    },
    useBffApi: true
  })
}

// 商详静态接口
export const getDetailStaticDataApi = async ({ goods_id, mall_code } = {}) => {
  if (!goods_id) return null
  const result = await schttp({
    url: API_LOCATION.PRODUCT_STATIC_INFO,
    params: {
      goods_id,
      mall_code,
      isUserSelectedMallCode: 1,
    },
    useBffApi: true,
  })
  return result?.info || {}
}

/**
 * 批量校验商品库存数据
 */
export const batchCheckGoodsStockDataApi = async (productCheckData = []) => {
  if (!productCheckData?.length) return
  return schttp({
    method: 'POST',
    url: API_LOCATION.BATCH_CHECK_GOODS_STOCK,
    data: {
      productCheckData,
    },
    useBffApi: true
  })
}
