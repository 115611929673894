<template>
  <ul class="soldbybox-popover__content">
    <li>
      <span class="cell-title">{{ sellerInfoBox.entityNameTitle }}</span>
      <span class="cell-content">{{ sellerInfoBox.entityName }}</span>
    </li>
    <li>
      <span class="cell-title">{{ sellerInfoBox.representativeTitle }}</span>
      <span class="cell-content ltr">{{ sellerInfoBox.representative }}</span>
    </li>
    <li>
      <span class="cell-title">{{ sellerInfoBox.businessAddressTitle }}</span>
      <span class="cell-content ltr">{{ sellerInfoBox.businessAddressValue }}</span>
    </li>
    <li>
      <span class="cell-title">{{ sellerInfoBox.phoneNumberTitle }}</span>
      <span class="cell-content ltr">{{ sellerInfoBox.phoneNumber }}</span>
    </li>
    <li>
      <span class="cell-title">{{ sellerInfoBox.emailTitle }}</span>
      <span class="cell-content ltr">{{ sellerInfoBox.email }}</span>
    </li>
    <li>
      <span class="cell-title">{{ sellerInfoBox.hostingServiceProviderTitle }}</span>
      <span class="cell-content ltr">{{ sellerInfoBox.hostingServiceProvider }}</span>
    </li>
    <li>
      <span class="cell-title">{{ sellerInfoBox.onlineRetailerReportNumberTitle }}</span>
      <span class="cell-content ltr">{{ sellerInfoBox.onlineRetailerReportNumber }}<br /> {{ sellerInfoBox.businessInfomationText }}</span>
    </li>
    <li>
      <span class="cell-title">{{ sellerInfoBox.paymentGuaranteeTitle }}</span>
      <span class="cell-content ltr">{{ sellerInfoBox.paymentGuarantee }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SoldBySellerKr',
  props: {
    sellerInfoBox: {
      type: Object,
      default: (() => {})
    }
  }
}
</script>
