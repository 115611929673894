import { expose_left_findsimilar, click_left_findsimilar, expose_findsimilar, click_findsimilar, expose_findsimilar_button, click_findsimilar_button } from 'public/src/pages/cart_v2/common/analysis/item/findSimilar'
import SimilarPopover from 'public/src/pages/common/similar_products/index'

export function useFindSimilar() {
  const onExpose = ({ itemOpts: { data }, analysis = {} }) => {
    expose_left_findsimilar(data.value, analysis?.expose_left_findsimilar)
    expose_findsimilar(data.value, analysis?.expose_findsimilar)
    expose_findsimilar_button(data.value, analysis?.expose_findsimilar_button)
  }
  const onReport = ({ itemOpts: { data }, analysis = {} }) => {
    click_left_findsimilar(data.value, analysis?.click_left_findsimilar)
    click_findsimilar(data.value, analysis?.click_findsimilar)
    click_findsimilar_button(data.value, analysis?.click_findsimilar_button)
  }
  const onClick = ({ itemOpts: { data }, options: { similarPopoverConfig = {} } = {} }) => {
    SimilarPopover.showModal(data.value.product, similarPopoverConfig)
  }

  return {
    onExpose,
    onReport,
    onClick,
  }
}
