const DEFAULT_CONFIG = {
  couponIsSorted: false,
  isCloseIncentivePoint: false,
  isCloseBusinessCartEntry: false,
  isBuyNow: false,
  casualCheckoutNo: '',
  continueBtnToClose: false,
  isClickToDetail: false,
}
const DEFAULT_QUERY_INFO = {
  addOnType: 2,
  sceneId: 152,
  cateIds: [],
  goodsIds: [],
  mallCode: '',
  quickship_prior: 0,
  location: 'addnewhotJson',
  adp: [],
  mainGoodsIds: [],
  // goodsPrice: '0',
  // includeTspId: '',
  // excludeTspId: '',
  // jsonRuleId: {},
}

const DEFAULT_STORE_INFO = {
  id: '',
  name: '',
}

const Helper = {
  getCouponProps: ({ 
    coupon, 
    secondaryCoupon, 
    config = {}, 
    queryInfo = {}, 
    storeInfo = {},
    saInfo = {} 
  }) => {
    return {
      type: 'coupon',
      coupon,
      secondaryCoupon,
      storeInfo: {
        ...DEFAULT_STORE_INFO,
        ...storeInfo,
      },
      config: {
        ...DEFAULT_CONFIG,
        ...config,
      },
      queryInfo: {
        ...DEFAULT_QUERY_INFO,
        ...queryInfo,
      },
      saInfo: {
        activity_from: 'coupon_add', // wiki.pageId=1162458400
        state: 'coupon_add',
        ...saInfo,
      }
    }
  },
  getFreeShipProps: ({
    promotion = {},
    config = {},
    queryInfo = {},
    saInfo = {},
  }) => {
    return {
      type: 'freeShipping',
      promotion,
      config: {
        ...DEFAULT_CONFIG,
        ...config,
      },
      queryInfo: {
        ...DEFAULT_QUERY_INFO,
        ...queryInfo,
      },
      saInfo: {
        activity_from: 'shipping_add', // wiki.pageId=1162458400
        state: 'shipping_add',
        ...saInfo,
      }
    }
  },
  getPromotionProps: ({
    promotion = {},
    config = {},
    queryInfo = {},
    saInfo = {},
  }) => {
    return {
      type: 'promotion',
      promotion,
      config: {
        ...DEFAULT_CONFIG,
        ...config,
      },
      queryInfo: {
        ...DEFAULT_QUERY_INFO,
        ...queryInfo,
      },
      saInfo: {
        activity_from: 'promotion_add', // wiki.pageId=1162458400
        state: 'promotion_add',
        ...saInfo,
      }
    }
  },
}

export default Helper
