<template>
  <div
    :class="{
      'c-cart-top': num4components,
      'cart-top_show-bubble': showBubble,
    }"
  >
    <div
      v-if="num4components"
      class="top-container j-shoppromo-tip fsp-element"
    >
      <swiper-container
        v-if="switch2Swiper"
        ref="swiperContainer"
        init="false"
        class="top-wrapper"
      >
        <!-- eslint-disable vue/no-deprecated-slot-attribute -->
        <div slot="container-start">
          <div
            ref="swiperButtonPrev"
            class="swiper-button-prev j-r-prev swiper-button-disabled"
            tabindex="0"
            aria-label="prev page"
          >
            <sui_icon_more_left_18px
              size="18px"
            />
          </div>
        </div>
        <swiper-slide
          v-for="comp in topComps"
          :key="comp.name"
          class="cart-top-swiper-slide j-cart-top-swiper-slide"
        >
          <component
            :is="comp.name"
            v-bind="comp.bind"
          />
        </swiper-slide>
        <div slot="container-end">
          <div
            ref="swiperButtonNext"
            class="swiper-button-next j-r-next"
            tabindex="0"
            aria-label="next page"
          >
            <sui_icon_more_right_18px
              size="18px"
            />
          </div>
        </div>
      </swiper-container>
      <div
        v-else
        class="top-wrapper"
      >
        <template
          v-for="comp in topComps"
          :key="comp.name"
        >
          <component
            :is="comp.name"
            v-bind="comp.bind"
          />
        </template>
      </div>
    </div>
  </div>
  <CartQuickShipTop
    v-if="showQuickShipOnTop"
    :cart-qs-tag-info="cartQsTagInfo"
  />
</template>

<script>
export default {
  name: 'CartTopActivity',
}
</script>
<script setup>
import { useStore } from 'vuex'
import { computed, ref, watch, nextTick } from 'vue'
import { sui_icon_more_left_18px, sui_icon_more_right_18px } from '@shein-aidc/icon-vue3'
import { Navigation } from 'swiper/modules'
import NewShippingInfoTip from 'public/src/pages/cart_v2/components/functional/cart-top/NewShippingInfoTip.vue'
import ShippingInfoTip from 'public/src/pages/cart_v2/components/functional/cart-top/ShippingInfoTip.vue'
import CouponAddon from 'public/src/pages/cart_v2/components/functional/cart-top/CouponAddon.vue'
import FullPromotionInfoTip from 'public/src/pages/cart_v2/components/functional/cart-top/FullPromotionInfoTip.vue'
import CartQuickShipTop from 'public/src/pages/cart_v2/components/functional/cart-top/CartQuickShipTop.vue'
const { state, getters } = useStore()

let swiper = null
let showBubble = ref(false)
let swiperContainer = ref(null)
let fullPromotionTip = ref(null)
let swiperButtonPrev = ref(null)
let swiperButtonNext = ref(null)

const isPlatForm = computed(() => state.isPlatForm)
const num4components = computed(() => topComps.value.length)
const switch2Swiper = computed(() => num4components.value > 2)
const singleMallCode = computed(() => getters.singleMallCode)
const shippingInfo = computed(() => state.mallShippingInfo[`${singleMallCode.value}`] || null)
const fullPromotions = computed(() => state.promotionState.fullPromotionList?.filter(item => [14, 21, 26].includes(+(item.data.type_id))))
const topComps = computed(() => {
  let comps = []
  let sectionLength = 0
  let shippingComp = null
  let promotionComp = null
  let couponComp = null
  if(isPlatForm.value) {
    if(shippingInfo.value?.tip) {
      sectionLength++
      shippingComp = {
        name: ShippingInfoTip,
        bind: {
          shippingInfo: shippingInfo.value,
          mallCode: singleMallCode.value,
        }
      }
    }
  } else {
    if(shippingInfo.value?.freeShippingStrategy?.strategyPkData?.tip) {
      sectionLength++
      shippingComp = {
        name: NewShippingInfoTip,
        bind: {
          shippingInfo: shippingInfo.value,
          mallCode: singleMallCode.value,
        }
      }
    }
  }
  if(fullPromotions.value?.length) {
    promotionComp = {
      name: FullPromotionInfoTip,
      bind: {
        ref: 'fullPromotionTip',
        rootClassName: 'section',
        promotions: fullPromotions.value,
        mallCode: singleMallCode.value,
        showPosition: 'top',
        showBubbleFn: handlerShowBubble
      } 
    }
  }
  let couponInfo = state.promotionState.fullPromotionList?.find(item => item.data.type_id == 10000)
  if(couponInfo) {
    sectionLength++
    couponComp = {
      name: CouponAddon,
      bind: {
        couponInfo: couponInfo,
      }
    }
  }

  if(shippingComp){
    comps.push(shippingComp)
  }
  if(promotionComp){
    promotionComp.bind.isSingleTips = sectionLength == 0 // 是否只有促销活动
    comps.push(promotionComp)
  }
  if(couponComp){
    comps.push(couponComp)
  }
  
  return comps
})

const cartQsTagInfo = computed(() => {
  return state.topBannerInfo || {}
})
// 购物车顶部qs信息栏展示条件:
// 开启quickship标签展示abt && qs时效信息不为空 && 非空车
const showQuickShipOnTop = computed(() => {
  return (
    getters.showQuickShipTag &&
    cartQsTagInfo.value?.view?.text
  )
})

watch(() => topComps.value.length, (val) => {
  if (!val) return
  if (fullPromotions.value?.length) {
    nextTick(() => {
      fullPromotionTip.value?.resize?.()
    })
  }
})

watch(() => switch2Swiper.value, (val) => {
  if (typeof window === 'undefined') return
  if (val) {
    nextTick(() => {
      setTimeout(() => {
        initSwiper()
      }, 0)
    })
  } else {
    swiper?.destroy()
    swiper = null
  }
}, { immediate: true })

function handlerShowBubble(show) {
  showBubble.value = show
}

const initSwiper = ()=>{
  if (!swiperContainer.value) return

  let swiperConfig = {
    modules: [Navigation],
    navigation: {
      prevEl: swiperButtonPrev.value,
      nextEl: swiperButtonNext.value,
    },
    direction: 'horizontal',
    slidesPerView: 'auto',
    slidesPerGroup: 1,
    roundLengths: false,
    observer: true,
    observeParents: true,
    injectStyles: [
          `
            .swiper-wrapper slot {
              display: flex;
              align-items: stretch;
            }
          `
    ],
  }
  Object.assign(swiperContainer.value, swiperConfig)
  swiperContainer.value.initialize?.()
  swiper = swiperContainer.value.swiper
}
</script>
<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.top-container{
  padding: 0 0 10px 0;
  background-color: #f4f4f4;
  .cart_item-header.platform-full-promotion{
      padding: 0;
      margin-bottom: 12px;
      & + .cart_item-header{
        margin-bottom: 0;
      }
    &:last-child{
      margin-bottom: 0;
    }

  }
}
.c-cart-top{
  position: relative;
  width: 100%;
  overflow: hidden;
  .top-wrapper{
    display: flex;
    width: 100%;
    .cart-top-swiper-slide {
      flex: 0 0 308px;
      & + .cart-top-swiper-slide {
        margin-left: 12px;
      }
      > .section {
        height: 100%;
      }
    }
  }
  .top-wrapper:not(swiper-container) {
    justify-content: space-between;
    gap: 10px;
    .section {
      width: unset;
      flex: 1;
      margin-right: unset;
    }
  }
  &.cart-top_show-bubble{
    overflow: initial;
  }
}
.qs-tag__wrapper {
  margin-bottom: 10px;
}
// 购物全场提示
.c-cart-top{
  .top-wrapper{
    .section{
      position: relative;
      padding: 12px 16px;
      .flexbox();
      word-break: break-word;
      flex: 0 0 308px;
      height: unset;
      width: 308px;
      .section-l{
        margin-right: 8px;
      }
      .section-r{
        flex: 1;
        .operation {
          color: #222222;
          font-weight: bold;
          font-size: 12px;
          cursor: pointer;
          &:hover{
            text-decoration: none;
          }
        }
      }
      .title{
        font-size: 14px;
        font-weight: bold;
        line-height: 25px;
        .flexbox();
        .space-between();
      }
      .contain{
        margin-top: 5px;
        li{
          position: relative;
          font-size: 13px;
          line-height: 14px;
          span{
            vertical-align:middle;
          }
          .operation{
            display: inline-block;
            cursor: pointer;
            &:hover{
              text-decoration: underline;
            }
          }
          .c-count-down-time{
            font-weight: normal;
            margin-top: 8px;
            font-size: 12px;
            color: @sui_color_highlight;
            .time-content{
              font-weight: normal;
              vertical-align: baseline;
              color: @sui_color_highlight;
              span{
                display: inline;
                font-weight: bold;
                color: @sui_color_highlight;
              }
            }
          }
          .promotion-slide-content{
            .flexbox();
            .space-between();
            span{
              flex: 1;
            }
          }
        }
      }
      &:last-child{
        .margin-r(0);
      }
      &.shipping-sec{
        background:@sui_color_safety_bg;

        .subtitle {
          line-height: 20px;
          color: #666;
          font-size: 13px;
          margin-top: 4px;
          .subtitle-tip {
            vertical-align: middle;
          }
        }
      }
      &.promotion-sec{
        border: 0.5px solid rgba(250, 99, 56, 0.3);
        background:@sui_color_promo_bg;
        &.hover-bubble{
          position: absolute;
          .left(0px);
          top: 0px;
          background: #FFF4EB /* rw: rgba(255, 153, 153, 0.08) */;
          z-index: @zindex-hack;
          box-shadow: 0px 2px 12px 0px rgba(111, 50, 0, 0.12);
          width: 100%;
          transform: translateZ(9px);
          .contain{
            width: calc(~"100% + 1px");
            li{
              margin-top: 24px;
              &:first-child{
                margin-top: 0px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="less" scoped>
swiper-container.top-wrapper {
  .swiper-button-prev,
  .swiper-button-next {
    top: 55%;
    width: 32px;
    height: 32px;
    background: rgba(255,255,255,.94);
    border-radius: 18px;
    font-size: 12px;
    text-align: center;
    vertical-align: unset;

    display: flex;
    align-items: center;
    justify-content: space-around;
    font-weight: normal;

    pointer-events: auto !important; /* stylelint-disable-line declaration-no-important */
    cursor: pointer;
    font-size: 18px;
    color: #222;
  }
  .swiper-button-prev {
    .left(5px);
  }
  .swiper-button-next {
    .right(5px);
  }
}
</style>

