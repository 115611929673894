import schttp from 'public/src/services/schttp'
const BFF_ABT_URL = '/abt/merge/get_abt_by_poskey'
import { getPrefixPoskeyObj } from './utils.js'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { getCookie } from '@shein/common-function'

async function nodeAbtReqeust(data) {
  const result = await schttp({
    url: '/api/abt/userinfo/query',
    method: 'post',
    data,
  })
  return result.params
}

/**
 * @description: bffAbtReqeust
 * @param {string} posKeys 新的posKeys
 * @param {string} oldPsoKeys 旧的posKeys
*/
async function bffAbtReqeust(posKeys = '', oldPsoKeys = '') {
  if (typeof posKeys !== 'string') {
    throw new Error('posKeys must be a string')
  }

  let oldPoskeyMap = {}
  if (oldPsoKeys) {
    const oldPosKeys = oldPsoKeys.split(',')
    oldPoskeyMap = getPrefixPoskeyObj(oldPosKeys)
    posKeys = `${posKeys},${Object.keys(oldPoskeyMap).join(',')}`
  }

  const formData = new FormData()
  const ugid = await UserInfoManager.get({ key: 'ugid' })
  const uid = UserInfoManager.get({ key: 'memberId', actionType: 'editPassword' })
  const query = {
    posKeys,
    cid: getCookie('cookieId'),
    ugid,
    uid,
    terminal: 'Shein-Pc',
    site: gbCommonInfo?.SiteUID, // "pwus"
    lan: gbCommonInfo?.appLanguage,
    ua: `${navigator?.userAgent}`.toLowerCase(),
    ip: gbCommonInfo?.ip
  }
  Object.keys(query).forEach(key => {
    query[key] && formData.append(key, query[key])
  })

  const res = await schttp({
    url: BFF_ABT_URL,
    data: formData,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    useBffApi: true,
  })
  const abtInfo = res?.info?.pos || {}
  return Object.keys(abtInfo).reduce((prev, key) => {
    const item = abtInfo[key]
    const originKey = oldPoskeyMap[key] || key // 如果是旧的poskey，就替换成新的poskey
    if (!item) {
      prev[originKey] = {}
      return prev
    }
    const value = item.p || item.param
    prev[originKey] = {
      sceneStr: key,
      posKey: originKey,
      poskey: originKey,
      param: value,
      p: value,
      child: item.child || [],
      version: item.version,
    }
    return prev
  }, {})
}
export {
  nodeAbtReqeust,
  bffAbtReqeust,
}
