<template>
  <div
    v-expose="getExposeData"
    class="pay-btn__wrapper"
    :class="{
      'pay-btn__small': size === 'small',
      'pay-btn__animation': animation
    }"
  >
    <div
      class="pay-btn__text"
      @click="handlePay"
      v-html="payBtnHtml"
    ></div>
    <div
      v-if="freeShippingText"
      class="free-shipping__wrapper"
    >
      <img
        v-if="freeShippingIcon"
        class="free-shipping__icon"
        :src="freeShippingIcon"
      />
      <div class="free-shipping__text">
        <ScrollingText :text="freeShippingText" />
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="5"
        height="5"
        viewBox="0 0 5 5"
        fill="none"
        class="free-shipping__badge"
      >
        <path
          d="M0 0H5L2.5 2.5L0 5V0Z"
          fill="#16533A"
        />
      </svg>
    </div>
    <div
      v-if="endTime"
      class="pay-btn__end-time"
    >
      <Icon
        name="sui_icon_hourglass_12px_1"
        size="12"
        color="#FA6338"
      />
      <span class="pay-btn__end-time-text">
        {{ endTimeStr }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { Icon } from '@shein-aidc/icon-vue3'
import { watch, ref } from 'vue'
import { CountDown } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter'
import { getBenefitType, getScene } from './analysis'
import ScrollingText from './ScrollingText.vue'
import { useCommonState } from '../../../utils/useCommonState'

const props = defineProps({
  orderItem: {
    type: Object,
    default: () => ({})
  },
  payBtnHtml: {
    type: String,
    default: ''
  },
  freeShippingText: {
    type: String,
    default: ''
  },
  freeShippingIcon: {
    type: String,
    default: ''
  },
  size: {
    type: String,
    default: 'default'
  },
  animation: {
    type: Boolean,
    default: false
  },
  endTime: {
    type: Number,
    default: 0
  },
  inFloor: {
    type: Boolean,
    default: false
  },
  discount: {
    type: String,
    default: ''
  }
})

const { abtInfo } = useCommonState()

const getExposeData = () => ({
  daId: '1-28-1-1010',
  data: {
    scene: getScene(props.orderItem, abtInfo.value, props.inFloor)
  }
})
const handlePay = () => {
  daEventCenter.triggerNotice({
    daId: '1-22-2-1006',
    extraData: {
      order_id: props.orderItem.billNo,
      scene: getScene(props.orderItem, abtInfo.value, props.inFloor),
      benefit_type: props.inFloor
        ? props.discount
          ? 'discount_show'
          : 'discount_not_show'
        : getBenefitType(props.orderItem, abtInfo.value)
    }
  })
  location.href = `/checkout/checkout_again/${props.orderItem.billNo}`
}

const endTimeStr = ref('')
const initCountDown = seconds => {
  new CountDown({
    seconds,
    countFunc: data => {
      endTimeStr.value = `${data.H}:${data.M}:${data.S}`
    }
  }).start()
}
watch(
  () => props.endTime,
  val => {
    if (!val) return
    initCountDown(val)
  },
  { immediate: true }
)
</script>

<style lang="less">
.pay-btn {
  &__wrapper {
    position: relative;
    display: inline-block;
    min-width: 250px;

    @keyframes breath {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.04);
      }
      100% {
        transform: scale(1);
      }
    }

    .free-shipping {
      &__wrapper {
        direction: ltr /* rtl: ignore */;
        position: absolute;
        left: unset /* rtl: -5px */;
        right: -5px /* rtl: unset */;
        top: -12px;
        display: flex;
        align-items: center;
        height: 20px;
        padding: 0 12px;
        background: #198055;
      }

      &__icon {
        width: 14px;
        height: 14px;
        margin-right: 4px;
      }

      &__text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 225px;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
      }

      &__badge {
        position: absolute;
        left: unset /* rtl: 0 */;
        right: 0 /* rtl: unset */;
        bottom: -5px;
      }
    }
  }

  &__text {
    height: 44px;
    padding: 0 24px;
    background: var(---sui_color_gray_dark1, #222);
    color: var(---sui_color_white, #fff);
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 44px;
    cursor: pointer;
  }

  &__animation {
    animation: breath 2s ease-in-out infinite;
  }

  &__small {
    min-width: 0;

    .free-shipping {
      &__wrapper {
        top: -10px;
        height: 14px;
        padding: 0 4px;
      }

      &__text {
        max-width: 107px;
        font-size: 10px;
      }
    }

    .pay-btn__text {
      text-align: center;
      height: 28px;
      padding: 0 14.5px;
      font-size: 12px;
      line-height: 28px;
    }
  }

  &__end-time {
    display: flex;
    align-items: center;
    height: 13px;
    margin: 4px 0 8px 0;
    padding-left: 12.5px;
  }

  &__end-time-text {
    margin: 0 8.5px 0 2px;
    color: #fa6338;
    font-size: 11px;
    font-weight: 700;
  }
}
</style>
