import { Abt } from '@shein-aidc/basis-abt'
import { nodeAbtReqeust } from './abt_fetch.js'

export default function createInstance() {
  const abt = new Abt({ schttp: async (data) => {
    try {
      if (typeof window === 'undefined') return {}
      return await nodeAbtReqeust(data)
    } catch(e) {
      console.log(e)
      return {}
    }
  } })
  return abt
}
