/**
 * @description: 细节图 数据中心
 */
export default {
  namespaced: true,
  state: {
    detail_pictures_info: {},
  },
  mutations: {
    update_detail_pictures_info(state, paylod) {
      state.detail_pictures_info = paylod
    },
  },
  getters: {
    lang_map(state, getters, rootState, rootGetters) {
      const language_v2 = rootGetters['Adaptor/language_v2']
      const { 
        SHEIN_KEY_PC_14620,
        SHEIN_KEY_PC_20211,
      } = language_v2 || {}
      return {
        titleLang: SHEIN_KEY_PC_14620,
        titleText: `${SHEIN_KEY_PC_14620}(${state.detail_pictures_info?.length})`,
        imgAriaLabel: `${SHEIN_KEY_PC_14620}${SHEIN_KEY_PC_20211}`,
      }
    },
    show_new_displayadaptive(state, getters, rootState, rootGetters)  {
      const screen_abt_config = rootGetters['Adaptor/screen_abt_config']
      return screen_abt_config?.Displayadaptive?.p?.Displayadaptive === 'adaptive'
    },
  },
  actions: {
    init_detail_pictures_info({ commit }, _) {
      _.then((res = {}) => {
        commit('update_detail_pictures_info', res?.info?.goods_images || [])
      })
    }
  },
}
  
