<template>
  <p
    v-if="totalPoints"
    class="point-tip"
    tabindex="0"
  >
    <span>{{ language.SHEIN_KEY_PC_14748 + ' ' }}</span>
    <span>
      <span :class="[primePoints ? (cartDiscountLogoA ? 'point-prime-vip_a' : 'point-prime-vip') : 'point-red']"> {{ totalPoints }}</span>
      <template v-if="rewardPoints && (getDoublePoints || primePoints)">
        <span
          class="prime-point"
          :class="[primePoints ? (cartDiscountLogoA ? 'point-prime-vip_a' : 'point-prime-vip') : 'point-red']"
        >({{ getDoublePoints && primePoints ? getDoublePoints + rewardPoints : rewardPoints }}+
          <Icon
            v-if="primePoints"
            name="sui_icon_club_logo_fill_14px"
            size="16px"
            :color="cartDiscountLogoA ? '#873C00' : '#C96E3F'"
          />
          {{ primePoints ? primePoints : getDoublePoints }})
        </span>
      </template>
      <s-popover
        class="not-fsp-element"
        placemen="bottom-end"
        trigger="hover"
        :append-to-body="false"
        :prop-style="{
          width: '240px',
          top: '32px',
          lineHeight: '14px',
          right:locals.GB_cssRight ? 'auto' : 0,
          left:locals.GB_cssRight ? 0 : 'auto'
        }"
      >
        <span class="point-tip-content">
          <span v-if="!primePoints">{{ language.SHEIN_KEY_PC_33037 }}</span>
          <span v-if="primePoints">{{ template(pointGrantAmountInfo.cycle_used_grant_amount ,pointGrantAmountInfo.cycle_grant_amount,primePoints, language.SHEIN_KEY_PC_26101) }}</span>
        </span>
        <template #reference>
          <span
            class="point-tip-enter"
          >
            {{ language.SHEIN_KEY_PC_15742 }}
            <sui_icon_doubt_14px_1
              class="icon_doubt"
              size="14px"
            />
          </span>
        </template>
      </s-popover>
    </span>
  </p>
</template>

<script>
export default {
  name: 'CartPointsTip'
}
</script>
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { Icon, sui_icon_doubt_14px_1 } from '@shein-aidc/icon-vue3'
import { template } from '@shein/common-function'
const { state, getters } = useStore()

const locals = computed(() => state.locals)
const mallCartPointData = computed(() => state.checkoutState.mallCartPointData)
const language = computed(() => state.language)

const totalPoints = computed(() => {
  return mallCartPointData.value?.totalPoints || 0
})

const primePoints = computed(() => {
  return mallCartPointData.value?.prime_points || 0
})

const rewardPoints = computed(() => {
  return mallCartPointData.value?.rewardPoints || 0
})

const getDoublePoints = computed(() => {
  return mallCartPointData.value?.getDoublePoints || 0
})

const pointGrantAmountInfo = computed(() => {
  return mallCartPointData.value?.point_grant_amount_info || {}
})

const cartDiscountLogoA = computed(() => {
  return getters.cartDiscountLogoA
})
</script>
<style lang="less" scoped>
.point-tip {
  color: #222222;
  text-align: right;
  font-size: 14px;
  margin-bottom: unset;
  padding-top: 8px;
  cursor: pointer;
  .point-tip-content{
    color: @sui_color_gray_dark3;
    span{
      display: block;
      margin-bottom: 8px;
    }
  }
  .point-tip-enter{
    color: #222222;
    position: relative;
    display: inline-flex;
    padding: 5px;
    align-items: center;
    border: none;
  }
  .prime-point {
    display: inline-flex;
    align-items: center;
  }
  .point-prime-vip{
    color: #C96E3F;
  }
  .point-prime-vip_a {
    color: #873c00;
  }
  .point-red {
    color: @sui_color_highlight;
  }
  .point-black {
    color: #222;
  }
}
</style>
