import { computed } from 'vue'
import { useStore } from 'vuex'
import { useCartDeleteDialog } from 'public/src/pages/cart_v2/components/functional/cart-delete-dialog/useCartDeleteDialog'
import { click_goods_delete } from 'public/src/pages/cart_v2/common/analysis/deleteDialog'

export function useGoodsDeleteButton() {
  const { state, commit, dispatch } = useStore()
  const language = computed(() => state.language)
  const { showCartDeleteDailog } = useCartDeleteDialog()
  const showOverLimitToast = (id, isShowOverLimitToast) => dispatch('showOverLimitToast', { id, isShowOverLimitToast })

  const onClick = ({ itemOpts: { data } = {}, options: { events = {}, isShowOverLimitToast = false } = {}, analysis }) => {
    
    const onAfterDelete = events.onAfterDelete
    events.onAfterDelete = ({ res } = {}) => {
      if (res?.code == '0') {
        showOverLimitToast(data.value.id, isShowOverLimitToast)
      }
      onAfterDelete && onAfterDelete({ res })
    }

    const onAfterDeleteAndSimilar = events.onAfterDeleteAndSimilar
    events.onAfterDeleteAndSimilar = ({ res } = {}) => {
      if (res?.code == '0') {
        showOverLimitToast(data.value.id, isShowOverLimitToast)
      }
      onAfterDeleteAndSimilar && onAfterDeleteAndSimilar({ res })
    }

    const onAfterM2W = events.onAfterM2W
    events.onAfterM2W = ({ res } = {}) => {
      if (res?.code == '0') {
        showOverLimitToast(data.value.id, isShowOverLimitToast)
      }
      onAfterM2W && onAfterM2W({ res })
    }

    const onAfterUnChecked = events.onAfterUnChecked
    events.onAfterUnChecked = ({ res } = {}) => {
      if (res?.code == '0') {
        showOverLimitToast(data.value.id, isShowOverLimitToast)
      }
      onAfterUnChecked && onAfterUnChecked({ res })
    }
    
    showCartDeleteDailog({
      type: 'single',
      goodsData: data.value,
      language: language.value,
      events,
      analysis
    })
  }

  const onButtonClick = async ({ itemOpts: { data, event } = {}, options: { events = {}, isShowOverLimitToast = false } = {}, analysis }) => {
    if (event === 'yes') {
      try {
        commit('updateState', {
          key: 'loadingShow',
          value: true
        })
        const reqData = {
          params: { ids: [data.value.id] }
        }
        if (events?.onDeleteSuccess) {
          reqData.events = {
            onSuccess: async () => {
              commit('updateState', {
                key: 'loadingShow',
                value: false
              })
              await events.onDeleteSuccess()
            }
          }
        }
        const res = await dispatch('fetchCartDelete', reqData)
        if (res?.code == '0') {
          showOverLimitToast(data.value.id, isShowOverLimitToast)
        }
        click_goods_delete(
          data.value,
          (defaultData) => {
            const data = {
              ...defaultData,
              result: res.code == 0 ? 1 : 0
            }
            if (analysis?.click_goods_delete) {
              return analysis.click_goods_delete(data)
            }
            return data
          }
        )
        return res
      } finally {
        commit('updateState', {
          key: 'loadingShow',
          value: false
        })
      }
    } else {
      click_goods_delete(data.value, (defaultData) => {
        const data = {
          ...defaultData,
          result: 0
        }
        if (analysis?.click_goods_delete) {
          return analysis.click_goods_delete(data)
        }
        return data
      })
    }
  }

  return {
    onClick,
    onButtonClick
  }
}
