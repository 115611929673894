<template>
  <div
    v-if="cartQsTagInfo.view"
    class="qs-tag__wrapper"
    :style="style"
  >
    <sui_icon_qucikship_flat_24px
      size="16px"
      class="qs-tag__icon"
    />
    <span class="qs-tag__text">{{ cartQsTagInfo.view.text }}</span>
    <span class="divider"></span>
    <span class="qs-tag__tip">{{ cartQsTagInfo.view.suffixText }}</span>
  </div>
</template>
<script>
export default {
  name: 'CartQuickShipTop',
}
</script>
<script setup>
import { sui_icon_qucikship_flat_24px } from '@shein-aidc/icon-vue3'
import { defineProps, computed } from 'vue'

const props = defineProps({
  styleConfig: {
    type: Object,
    default: () => {}
  },
  cartQsTagInfo: {
    type: Object,
    default: () => {}
  }
})

const style = computed(() => {
  return {
    ...props.styleConfig,
    '--bg-color': props.cartQsTagInfo?.view?.bgColor || '#ECFCF3',
    '--text-color': props.cartQsTagInfo?.view?.textColor || '#198055',
  }
})
</script>

<style lang="less" scoped>
.qs-tag__wrapper {
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding: 12px 16px;
  background: var(--bg-color);
  border: 0.5px solid rgba(25, 128, 85, 0.20);
  color: var(--text-color);
  line-height: 17px;
  font-size: 13px;
  .qs-tag__icon {
    margin-right: 2px;
  }
  .qs-tag__text {
    font-style: italic;
    font-weight: 590;
  }
  .divider {
    display: inline-block;
    width: .5px;
    height: 12px;
    background: rgba(25, 128, 85, 0.40);
    margin: 0 8px;
  }
  .qs-tag__tip {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    text-overflow: ellipsis;
    font-style: normal;
    font-weight: 400;
    color: var(--text-color);
  }
}
</style>
