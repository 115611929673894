export default function (extraData = {}) {
  function GetQueryString(name, referer) {
    let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    let r = referer.match(reg)
    if (r != null) return unescape(r[2])
    return ''
  }

  let scene_from = ''
  let page_from_key = ''
  if (window.sessionStorage) {
    let sessionVisitTrajectory = window.sessionStorage.getItem('visit_trajectory')
    let visitTrajectory = JSON.parse(sessionVisitTrajectory)
    if (visitTrajectory && visitTrajectory.length > 1) {
      page_from_key = visitTrajectory[visitTrajectory.length - 1]
    }
    scene_from = window.sessionStorage.getItem('cart_scene_from') || ''
  }

  let shop_id = ''
  if (document.referrer) {
    let referrerSearch = document.referrer.split('?')[1] || ''
    shop_id = GetQueryString('store_code', referrerSearch)
  }

  const handlerSa = () => {
    window.SaPageInfo.page_param.page_from = page_from_key
    window.SaPageInfo.page_param.store_code = shop_id
    // 主购物车曝光上报增加scene_from参数，标识跳转来源
    // entrance_click 表示入口点击，mini_cart 标识从mini车点击
    window.SaPageInfo.page_param.scene_from = scene_from
    Object.assign(window.SaPageInfo.page_param, extraData)
    sa('set', 'setPageData', SaPageInfo)
    sa('send', 'pageEnter', { start_time: new Date().getTime() })
    sa('send', {
      activity_name: 'expose_page_loadtime',
      activity_param: { time: new Date().getTime() - window.performance.timing.navigationStart }
    })
    //退出页面
    window.onbeforeunload = function () {
      sa('send', 'pageLeave', { end_time: new Date().getTime() })
    }
  }
  handlerSa()
}
