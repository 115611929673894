<template>
  <s-label
    v-if="getDayTimeTip"
    ref="marqueeBlockRef"
    type="success"
    :style="{ width: 'fit-content', maxWidth: '100%' }"
  >
    <RowMarquee
      ref="dayTimeMarqueeRef"
      :speed="0.5"
      gap="10px"
      manual
    >
      <span ref="dayTimeMarqueeTextRef">{{ getDayTimeTip }}</span>
    </RowMarquee>
  </s-label>
</template>

<script setup name="DayTimeTag">
import { computed, watch, nextTick, ref } from 'vue'
import { useMapState } from 'public/src/pages/store_pages/store/mapHook'
import RowMarquee from 'public/src/pages/cart_v2/components/business/add-on/comps/RowMarquee.vue'

const props = defineProps({
  cardId: {
    type: String,
    default: ''
  }
})

let observer = null
const dayTimeMarqueeRef = ref(null)
const dayTimeMarqueeTextRef = ref(null)

const { dayTimeMap } = useMapState(['dayTimeMap'])

const getDayTimeTip = computed(() => dayTimeMap.value?.cartList?.find(({ cartId }) => cartId === props.cardId)?.tip || '')

const init = (observerDom) => {
  observer = new IntersectionObserver((entries) => {
    const dayTimeMarqueeTextEl = dayTimeMarqueeTextRef.value || null
    const dayTimeMarqueeEl = dayTimeMarqueeRef.value?.$el || null
    const isShow = (entries[0]?.isIntersecting || false)
    if (dayTimeMarqueeTextEl && dayTimeMarqueeEl) {
      if (dayTimeMarqueeTextEl.offsetWidth > dayTimeMarqueeEl.offsetWidth && isShow) {
        dayTimeMarqueeRef.value?.start()
      }
    }
  }, {
    threshold: 1
  })
  observer.observe(observerDom)
}

watch(() => getDayTimeTip.value && (dayTimeMarqueeTextRef.value || dayTimeMarqueeRef.value), () => {
  nextTick(() => {
    if (getDayTimeTip.value) {
      const dayTimeMarqueeEl = dayTimeMarqueeRef.value?.$el || null
      if (dayTimeMarqueeEl) {
        init(dayTimeMarqueeEl)
      }
    }
    
  })
})
</script>

