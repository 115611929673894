// 上报数据
// 订单状态，Unpaid-待支付，Processing-运送中，Shipped-已送达，Returns-退换

export const REPORT_ORDER_STATUS = {
  'unpaid': 'unpaid',
  'review': 'review',
  'processing': 'processing',
  'proccess': 'processing',
  'shipped': 'shipped',
  'returns': 'returns',
}

// export const TYPE_2_POSITION = {
//   prime_freetrial: 1,
//   free_trail_report: 2,
//   survey: 3,
//   membership: 4,
//   myreference: 5,
//   affiliate: 6,
//   ambassador: 7,
//   notification: 8,
//   friendship_coupon: 9,
//   exchange: 10,
//   news: 11,
//   shareandearn: 12,
// }


export const REPORT_ORDER_STATUS_2_ID = {
  click: {
    'unpaid': '1-28-1-1017',
    'processing': '1-28-1-1017',
    'shipped': '1-28-1-1017',
    'returns': '1-28-1-1017',
    'review': '1-28-1-1027',

    'all': '1-22-2-38',

    'wishlist': '',
  },

  expose: {
    'all': '',
    'unpaid': '',
    'processing': '',
    'shipped': '',
    'returns': '',
    'review': '1-28-1-1018', 
    
    'wishlist': '',
  }
}
