import { daEventCenter } from 'public/src/services/eventCenter/index'

export const expose_disable_wish = (data, callback) => {
  const defaultData = {}
  daEventCenter.triggerNotice({
    daId: '1-7-3-32',
    extraData: callback?.(defaultData) ?? defaultData
  })
}

export const expose_wishlist_toast = (data, callback) => {
  const defaultData = {}
  daEventCenter.triggerNotice({
    daId: '1-7-3-36',
    extraData: callback?.(defaultData) ?? defaultData
  })
}

export const click_add_collect = (data, callback) => {
  const defaultData = {
    code: 'cart',
    result: '',
    mall_code: data.mall_code,
    postData: {
      action: 1, // 选中发1, 取消发0
      goods_id: data.product.goods_id,
      sku: data.product.goods_sn,
      detail: data.product,
      // 商品所在位置
      index: '',
      // 是否展示QS标签
      quickShip: ''
    },
    from: 'cart',
    eventCategory: '购物车页'
  }
  daEventCenter.triggerNotice({
    daId: '1-7-1-2',
    extraData: callback?.(defaultData) ?? defaultData
  })
}

export const click_movewishlistyes = (data, callback) => {
  const defaultData = {}
  daEventCenter.triggerNotice({
    daId: '1-7-7-10',
    extraData: callback?.(defaultData) ?? defaultData
  })
}

export const expose_popup_itemwishlist = (data, callback) => {
  const defaultData = {}
  daEventCenter.triggerNotice({
    daId: '1-7-7-7',
    extraData: callback?.(defaultData) ?? defaultData
  })
}

export const expose_popup_wishlistfail = (data, callback) => {
  const defaultData = {}
  daEventCenter.triggerNotice({
    daId: '1-7-7-2',
    extraData: callback?.(defaultData) ?? defaultData
  })
}
