<template>
  <div
    class="user-index__wrapper"
    is-use-bff="1"
  >
    <template v-for="item in notifyList">
      <CCCNotificationComponent
        v-if="item.type === 'cccMsg' && handleShowCccNotice(item)"
        :key="item.type"
        :is-rtl="isRtl"
        :data="item"
      />
    </template>
    <div class="user-index__content">
      <!-- 主内容 -->
      <MainContent
        :expire-point-coupon-info="expirePointCouponInfo"
        :personal-center-entrance-info="personalCenterEntranceInfo"
        :dynamic-service="dynamicService"
      />
      <!-- 右侧栏 -->
      <AsideContent
        :to-link="toLink"
        :center-news="context.centerNews"
        :wish-follow-list="context.wishFollowList"
        :recommend-store-list="context.recommendStoreList"
      />
      <!-- 未支付订单弹框提示 -->
      <component
        :is="isShowNewUnpaidDialog ? UnpaidDialogV2 : UnpaidDialog"
        :order-item="unpaidOrderDialogData"
        :configs="commonState.apolloConfig && commonState.apolloConfig.USER_CENTER_UNPAID_ORDER_CONFIG"
      />
    </div>
  </div>
</template>

<script setup name="UserCenterContainer">

import {
  computed,
  defineAsyncComponent,
  onBeforeMount,
  onMounted,
  provide,
  ref
} from 'vue'
import mitt from 'mitt'
import { getLocalStorage, setLocalStorage } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter'
import { metricPageSuccess } from 'public/src/pages/common/business-monitor/common.js'
import CCCNotificationComponent from './newComponents/header/CCCNotification.vue'
import MainContent from './newComponents/mainContent/MainContent.vue'
import AsideContent from './newComponents/aside/AsideContent.vue'
import { analysisEvent, triggerPageMeEnter } from './analysis'
import { useCommonState, useRecentViews } from './hooks'

const UnpaidDialog = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "UnpaidDialog" */ './newComponents/unpaid-dialog/UnpaidDialog.vue'
  )
)
const UnpaidDialogV2 = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "UnpaidDialog" */ './newComponents/unpaid-dialog/unpaid-dialog-v2/UnpaidDialogV2.vue'
  )
)

const props = defineProps({
  context: {
    type: Object,
    default: () => ({})
  }
})

const { recentViewList } = useRecentViews()

provide('USER_CENTER_CONTEXT', {
  getUserData: () => ({
    unreadTicketNum: props.context.unreadTicketNum,
    unreadMsg: props.context.unreadMsg,
    userOrderList: props.context.userOrderList,
  }),
  getRecentViews: () => recentViewList.value,
})

daEventCenter.addSubscriber({ modulecode: '1-22-2' })

const { commonState } = useCommonState(props)

const toLink = (link, id, activityParams = {}) => {
  if (commonState.isRisk) {
    window.userIndexEventCenter.emit('riskDialog', true)
    return
  }
  analysisEvent(id, activityParams)
  window.location.href = link
}

provide('commonState', commonState)
provide('toLink', toLink)

const isRtl = ref(props.context.GB_cssRight)
const notifyList = ref([])
const expirePointCouponInfo = ref({})
const dynamicService = ref({})
const personalCenterEntranceInfo = ref({})
// 未支付挽留弹框数据
const unpaidOrderDialogData = ref({})
const isShowNewUnpaidDialog = computed(() =>
  ['A', 'B'].includes(commonState.abtInfo.MeOrderUnpaid?.p?.PopType)
)

const handleShowCccNotice = data => {
  const hideCccNotice = getLocalStorage('hideCccNotice')
  if (!hideCccNotice && data.content) {
    // 公告优化 产品新加的埋点 担心之前的埋点没有被使用了 保留两份
    daEventCenter.triggerNotice({
      daId: '1-22-2-7'
    })
    daEventCenter.triggerNotice({
      daId: '1-22-2-16'
    })
    return true
  }
  return false
}

const handleUnpaidDialog = (data = []) => {
  if (!data.length) return
  const newBillNos = data.map(item => item.billNo)
  // 埋点上报
  daEventCenter.triggerNotice({
    daId: '1-22-2-1009',
    extraData: {
      scenes: 'unpaidOrderMe',
      order_id_list: newBillNos.join('`')
    }
  })
  if (commonState.abtInfo.unpaidOrderMe?.p?.unpaidOrderMe != 'TypeB') return
  const SiteUID = commonState.constantData.SiteUID
  const cacheKey = `unpaid_dialog_cache_${SiteUID}_${commonState.userInfo.member_id}`
  const cache = getLocalStorage(cacheKey) || { billNos: [], count: 0 }
  const cacheBillNos = cache.billNos || []
  const unpaidOrder = data.find(item => !cacheBillNos.includes(item.billNo))
  // 次数限制 频率限制
  const USER_CENTER_UNPAID_ORDER_CONFIG = commonState.apolloConfig.USER_CENTER_UNPAID_ORDER_CONFIG
  if (
    !USER_CENTER_UNPAID_ORDER_CONFIG.times ||
    cache.count >= USER_CENTER_UNPAID_ORDER_CONFIG.times ||
    !USER_CENTER_UNPAID_ORDER_CONFIG.intervel ||
    cache.time >
      Date.now() - USER_CENTER_UNPAID_ORDER_CONFIG.intervel * 60 * 1000 ||
    !unpaidOrder
  ) {
    return
  }
  cacheBillNos.push(unpaidOrder.billNo)
  unpaidOrderDialogData.value = unpaidOrder
  // 设置缓存
  setLocalStorage({
    key: cacheKey,
    value: {
      count: cache.count + 1,
      time: Date.now(),
      billNos: cacheBillNos
    }
  })
}
const initData = () => {
  const firstPartInfo = props.context.firstPartInfo?.info || {}
  notifyList.value = firstPartInfo.notifyService?.notifyList || []
  expirePointCouponInfo.value = firstPartInfo.expirePointCouponInfo || {}
  personalCenterEntranceInfo.value = firstPartInfo.personalCenterEntranceInfo || {}
  dynamicService.value = firstPartInfo.dynamicService || {}
}
// 业务埋点监控
const setMonitor = () => {
  metricPageSuccess({ 
    page: 'page_user_index',
    status: (props.context?.apiInfo?.firstPartInfo?.code == 0 && props.context?.apiInfo?.secondPartInfo?.code == 0) ? '1' : '0',
  }, {
    firstPartInfoCode: props.context?.apiInfo?.firstPartInfo?.code, 
    secondPartInfoCode: props.context?.apiInfo?.secondPartInfo?.code,
    firstPartInfoMsg: props.context?.apiInfo?.firstPartInfo?.msg,
    secondPartInfoMsg: props.context?.apiInfo?.secondPartInfo?.msg,
    original_url: window?.location?.href,
  })
}
initData()

onBeforeMount(() => {
  const secondPartInfo = props.context.secondPartInfo?.info || {}
  // 处理待支付挽留弹框数据
  handleUnpaidDialog(secondPartInfo.orderRetention?.orderList)
  triggerPageMeEnter({ commonState })
})
onMounted(() => {
  //如果是爆破黑名单用户或者泄露名单用户
  window.userIndexEventCenter = window.userIndexEventCenter || mitt()
  setMonitor()
})
</script>

<style lang="less">
.user-remind {
  background: rgba(217, 234, 255, 1);
  margin-bottom: 10px;
  padding: 20px 0 20px 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  .user-remind-left {
    color: #666666;
    font-size: 18px;
  }
  .content {
    font-size: 14px;
    color: rgba(34, 34, 34, 1);
    flex: 1;
    margin: 0 10px;
  }
  .user-remind-right {
    color: #999;
    font-size: 14px;
    .margin-r(16px);
    .margin-l(24px);
  }
}
.user-risk-dialog {
  .sui-dialog__wrapper {
    .sui-dialog__body {
      padding: 0;
      margin: 0;
    }
  }
}
.user-index__content {
  display: flex;
  justify-content: space-between;
}
</style>
