<template>
  <div
    v-if="showAppCcc !== null"
    class="mgds-recommend"
  >
    <!-- 推荐位 start-->
    <RecommendCcc
      v-if="showAppCcc"
      :extend-params="extendParams"
    />
    <!-- 推荐位 end-->
  </div>
</template>

<script>
export default {
  name: 'RecommendWrapper',
}
</script>

<script setup>
import RecommendCcc from './RecommendCcc.vue'
import { computed } from 'vue'
import { useStore } from 'vuex'

const { state } = useStore()

const carts = computed(() => state.carts)
const cartAbtInfo = computed(() => state.cartAbtInfo)
const showAppCcc = computed(() => {
  return cartAbtInfo.value?.componentswitch?.param?.shopbagrecstyle == '1'
})
// 推荐位需要过滤的商品id
const filterData = computed(() => {
  const goodsIds = []
  const cateIds = []
  if (carts.value && carts.value?.length) {
    carts.value?.forEach(item => {
      if (item.product) {
        goodsIds.push(item.product.goods_id)
        cateIds.push(item.product.cat_id)
      }
    })
  }
  return {
    goodsIds,
    cateIds
  }
})
const extendParams = computed(() => {
  return {
    cate_ids: filterData.value?.cateIds.join(','),
    goods_ids: filterData.value?.goodsIds?.join(',')
  }
})

</script>
