<template>
  <!-- current price (everybody price) -->
  <div 
    class="checkout-summary-promotion__everybody"
    :class="{'checkout-summary-promotion__everybody-body': showUnderline}"
  >
    <span class="price-detail-left">{{ language.SHEIN_KEY_PC_30551 + ':' }}</span>
    <span 
      class="price-detail-right"
    >
      {{ caculateInfo.every_body_price.amountWithSymbol }}
    </span>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'

const store = useStore()

const language = computed(() => store.state.language)
const checkout = computed(() => store.state.checkout)

const caculateInfo = computed(() => checkout.value?.mall_caculate_info || {})

const showUnderline = computed(() => {
  return +caculateInfo.value?.every_body_promotion_discount?.amount > 0 && +caculateInfo.value?.exclusive_promotion_discount?.amount > 0
})
</script>

<style lang="less" scoped>
.checkout-summary-promotion__everybody {
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
}
.checkout-summary-promotion__everybody-body {
  padding-bottom: 6px;
  margin-bottom: 6px;
  border-bottom: 1px solid #e5e5e5;
}
</style>
