import { debounce } from 'lodash'
import { htmlDecode, template } from '@shein/common-function'

export const scrollToCartItem = (cartId, highlightAnimate = true) => {
  const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
  if(cartId) {
    setTimeout(()=>{
      let $target = document.querySelector(`.j-cart-item-${cartId}`)
      if(!$target) return
      const { top, height } = $target.getBoundingClientRect()
      const elCenter = top + height / 2
      const center = window.innerHeight / 2
      window.scrollTo({ top: scrollTop - (center - elCenter), behavior: 'smooth' })
      if(highlightAnimate) {
        if($target) {
          const excuteAnimate = debounce(function() {
            triggerHighlightAnimation($target, [cancelListener])
          }, 300)
          function cancelListener() {
            window.removeEventListener('scroll', excuteAnimate)
          }
          window.addEventListener('scroll', excuteAnimate)

          // 兜底 手动执行一次，防止滚动事件未触发
          excuteAnimate()
        } else {
          // eslint-disable-next-line no-console
          console.log('.mshe-item not found')
        }
      }
    }, 500)
  }
}

function triggerHighlightAnimation(element, callbacks) {
  callbacks.forEach(cb => cb())
  // 添加高亮动画类
  element.classList.add('highlight-animate')
  // 让浏览器有时间渲染动画，然后移除类以便之后可以重新触发动画
  setTimeout(() => {
    element.classList.remove('highlight-animate')
  }, 800) // 动画总时间是 800ms
}

/**
 * 处理更新以及更新属性接口异常
 * @param res 接口返回信息
 * @param language
 * @param item 当前更新的商品
 * @return Object
 */
export const handleUpdateAndUpdateAttrErr = (res = {}, language = {}, item = {} )=> {
  let errorMessage = ''
  if (res.code == 300402) {
    //限时买折超过限购数
    errorMessage = language.SHEIN_KEY_PC_15071
  } else if (['200401', '500301'].indexOf(res.code + '') >= 0) {
    //闪购限购
    errorMessage = language.SHEIN_KEY_PC_14661
  } else if (['300714', '500302', '500306', '302444', '300417', '500303'].includes(res.code + '')) {
    let id = item.promotionGroupId
    errorMessage = template(res?.info?.limitCount, res?.info?.resultLimit, res?.info?.remainNum, htmlDecode({ text: res.tips }))
    if (id) {
      cartEmphasizePromotion(id, { shake: true, changeColor: true, scroll: true })
    }
    if(errorMessage) {
      daEventCenter.triggerNotice({
        daId: '1-7-7-13',
      })
    }
  } else if (res.code == 500304 || res.code == 500305) { //打折限购  500304: 单品限购     500305：订单限购
    let id = item.promotionGroupId
    if(id){
      cartEmphasizePromotion(id, { shake: true, changeColor: true, scroll: true })
    }
  } else if (res.code == 300405) {
    errorMessage = template(item.product.limit_count, htmlDecode({ text: language.SHEIN_KEY_PC_14560 }))
  } else if (['302411', '302412', '300415'].includes(res.code)) {
    errorMessage = res.tips
  } else if ( res.code == 300470 ) {
    errorMessage = res.tips.replace('{0}', res.info?.single_row_capacity_limit)
  } else {
    errorMessage = res.tips || language.SHEIN_KEY_PC_15805
  }
  return { msg: errorMessage, times: ['500302', '500306', '300417', '500303'].includes(res.code + '') ? 4000 : 0 }
}

export const cartEmphasizePromotion = (id, options = {}) =>{

  const { shake = false, changeColor = false, scroll = false } = options
  const containerDom = document.querySelector(`.j-promotion-header-${id}`)
  if (!containerDom) return
  let PAGE_HEAD_HEIGHT = 146
  let topTplH = document.querySelector('.j-top-tpl')?.getBoundingClientRect()?.height || 0
  let $bagTitleH = document.querySelector('.j-shopbag-title')?.getBoundingClientRect()?.height || 0
  const nowScrollTop = window.scrollY
  const containerTop = containerDom.getBoundingClientRect().top
  const cartHeaderHeight = topTplH + $bagTitleH + PAGE_HEAD_HEIGHT

  // 是否滚动到标题处
  if (scroll) {
    window.scrollTo({
      top: nowScrollTop + containerTop - cartHeaderHeight,
      behavior: 'smooth'
    })
  }

  // 颜色变红
  if (changeColor) {
    setTimeout(() => {
      const $target = containerDom.querySelector('.cart_item-tip')
      $target && $target.classList.add('cart-red-dark')
    }, 50)
  }

  // 是否抖动
  if (shake) {
    const $target = containerDom.querySelector('.cart_item-banner')
    $target && $target.classList.add('promotion-header-shake')
    setTimeout(() => {
      $target && $target.classList.remove('promotion-header-shake')
    }, 1000)
  }
}

// 删除动画
export const animationDelete = ($el) => {
  return new Promise(resolve => {
    if (!$el) resolve()
    const h = $el.clientHeight || 0
    $el.style.overflow = 'hidden'
    $el.style.opacity = 0
    // 默认 60fps/s
    const fps = 60
    // 花费时间
    const times = 0.5
    const distance = Math.ceil(h / (fps * times) )
    let transitionItem  = ($el, h, distance) => {
      window.requestAnimationFrame(() => {
        let vh = h - distance
        vh = vh < 0 ? 0 : vh
        $el.style.height = `${vh}px`
        if (vh > 0) return transitionItem($el, vh, distance)
        $el.style.display = 'none'
        return resolve()
      })
    }
    transitionItem($el, h, distance)
  })
}

export const cartEmphasizePromotionAll = (ids = [], options = {}) =>{
  ids.forEach((id, index) => {
    if (index > 0) options.scroll = false // 只滚动到第一个活动
    cartEmphasizePromotion(id, options)
  })
}
