import sa from './sa'
import sc from './sc'
import searchWordsGlobalAnalysis from 'public/src/pages/common/biz_helper/gb_sw_analysis'

export default {
  sa,
  sc,
  setGlobalTrackInfo({ full_name, word_type, result_type, search_redir }) {
    if (full_name && result_type) {
      searchWordsGlobalAnalysis.set({
        result_type,
        word_type,
        result_word: full_name,
        d: search_redir,
        result_content: window?.argResultContent || '-',
      })
    }
    return { ici: searchWordsGlobalAnalysis.getPageFrom() }
  },
  doFocusAnalysis(vm) {
    // sa
    this.sa.searchInput('click_search', {
      currentWord: (typeof vm.currentWord.guideObj === 'object')
        ? vm.currentWord.guideObj.keywords
        : vm.currentWord.keywords,
      placeholder: vm.placeholder,
    }, { Searchboxform: 2 })

    if (vm.associationNum) {
      this.sa.searchInput('expose_top_site_search', {
        currentWord: vm.currentWord.keywords,
        correction: vm.correction,
        associationWords: vm.associationWords,
      }, {})
    } else {
      this.sa.searchInput('expose_top_site_search', {
        historyWords: vm.historyWords,
        hotWords: vm.hotWords,
        hotImages: vm.hotImages,
        crowdId: [
          ...new Set(
            [
              ...vm.defaultWords,
              ...vm.hotWords,
              ...vm.hotImages 
            ]
              .map(_ => _.crowdId)
              .filter(_ => +_)
          )
        ].join(),
      }, {})
    }
    // sc
    if (vm.status.saFirstView) {
      vm.status.saFirstView = false
      vm.$nextTick(() => this.sc.firstView())
    }
  },
  exposeAssoc(vm) {
    // 参见wiki：pageId=1262278302
    let associationWordsLen = (vm?.associationWords?.word?.length ?? 0) + (vm?.associationWords?.guideWords?.length ?? 0)
    let currentWordLen = vm.currentWord.keywords?.length || 0
    // sa
    this.sa.searchInput('expose_top_site_search', {
      currentWord: vm.currentWord.keywords,
      correction: vm.correction,
      associationWords: vm.associationWords,
    }, {
      // 输入内容为空时不上报, 输入内容超过50字符时不上报, 无联想词数据也不给上报. 其它情况直接返回联想词结果个数
      suggest_result_count: (currentWordLen === 0 || currentWordLen >= 50) ? 0 : associationWordsLen,
    })
    // sc
    this.sc.exposeAssoc()

  },
  search({ keyword, full_name, result_type, search_redir, data, crowdId, originWord, wordTagType, reportExtendData }) {
    this.sa.doSearch({ keyword, result_type, data, crowdId, originWord, wordTagType, reportExtendData })
    return this.setGlobalTrackInfo({ full_name, result_type, search_redir, word_type: data?.type || '' })
  }
}
