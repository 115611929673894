<template>
  <div
    v-expose="{
      id: '1-7-1-33',
      data: { mall_code: mallCode, store_code: storeCode }
    }"
    class="get-coupon-entry"
    da-event-click="1-7-1-34"
    :data-mall_code="mallCode"
    :data-store_code="storeCode"
    @click="showGetStoreCoupon"
  >
    {{ language.SHEIN_KEY_PC_22086 }}
  </div>
</template>

<script>
export default {
  name: 'GetCoupon'
}
</script>

<script setup>
import { computed, defineProps } from 'vue'
import { useStore } from 'vuex'
const { state, commit } = useStore()

const props = defineProps({
  mallCode: { // mall编码
    type: [String, Number],
    default: ''
  },
  storeCode: { // 店铺编码
    type: [String, Number],
    default: ''
  },
})


const language = computed(() => state.language)
const carts = computed(() => state.carts)

const showGetStoreCoupon = () => {
  const products = []
  carts.value?.map(item => {
    if (item.store_code == props.storeCode) {
      products.push({
        skc: item?.product?.goods_sn || ''
      })
    }
  })
  commit('updateState', {
    key: 'mallInfoForGetCoupon',
    value: {
      mall_code: props.mallCode,
      store_code: props.storeCode,
      products
    }
  })
  commit('toggleCartGetCouponDrawer', { value: true })
}
</script>

<style lang="less" scoped>
.get-coupon-entry {
  position: relative;
  color: #2D68A8;
  cursor: pointer;
  font-size: 16px;
}
</style>
