<template>
  <EntryBox
    v-if="brandSeriesDetail && storeDataReady"
    :title="brandHeadTitle"
    :relative-info="brandSeriesDetail"
    :report-immediately="reportImmediately"
    :ext-analysis-info="extAnalysisInfo"
    :is-show-recommend="isShowRecommend"
    :extend-collapse-type="extendCollapseType"
    :is-store="isStore"
    :is-alone-store-brand="!isStore && isStoreBrand"
  >
    <template #content>
      <StoreEntry
        v-if="shouldStoreRelativeShow"
        :store-info="brandSeriesDetail"
        :ext-analysis-info="extAnalysisInfo"
        :is-show-recommend="isShowRecommend"
      />
      <div
        v-else
        :class="[
          'product-intro__brand-table',
          { center: !isShowDesc },
        ]"
      >
        <div 
          v-if="showBrandLogo" 
        >
          <div
            v-if="showBrandLogo && brandSeriesDetail.logo"
            :class="['product-intro__brand-logo', {'product-intro__new-brand-logo': isStoreBrand, 'abt-new-store-brand-logo': isStoreBrand}]"
          >
            <img :src="brandSeriesDetail.logo" />
          </div>
        </div>
        <div
          class="product-intro__brand-table-item"
        >
          <div class="product-intro__brand-detail">
            <div :class="['product-intro__brand-name', {'product-intro__new-brand-name': isStoreBrand }]">
              <div
                class="product-intro__brand-title"
                :class="{ 'mt': bestSeller }"
              >
                {{ brandSeriesDetail.name }}
              </div>
              <div
                v-if="isBrandHasTargetTag"
                class="product-intro__brand-storebrandlabel"
              >
                <i class="svgicon svgicon-gd-store-brand-tips-icon"></i>
                <div
                  v-expose="{ id: '1-8-6-180', data: authenticbrandShowAnalysisData }"
                  class="authentic"
                >
                  {{ language.SHEIN_KEY_PC_27679 }}
                </div>
              </div>
              <!-- 品牌集成店铺 有描述的时候 view product 要在标题右边，新样式没有描述时也在标题最右边 -->
              <JumpToStoreBtn
                v-if="brandSeriesDetail.link && isStoreBrand"
                class="product-intro__view-product"
                :store-info="brandSeriesDetail"
                :detail="productIntroData?.detail"
                :button-text="`${language.SHEIN_KEY_PC_25646} >`"
                :type="'inline-link'"
                :is-store="isStore"
                :ext-analysis-info="extAnalysisInfo"
              />
            </div>
            <div
              v-if="isShowDesc"
              :class="['product-intro__brand-des product-intro__new2-brand-des', {'product-intro__new-brand-des': isStoreBrand }]"
              v-html="brandSeriesDetail.desc"
            ></div>
            <div
              v-if="shouldStoreRelativeShow"
              class="product-intro__brand-footer"
            >
              <div
                v-if="brandSeriesDetail.totals"
                class="item mr"
              >
                {{ language.SHEIN_KEY_PC_21781 }}:
                {{ brandSeriesDetail.totals }}
              </div>
              <div
                v-if="storeRate"
                class="item"
              >
                {{ language.SHEIN_KEY_PC_21810 }}: {{ formatShopScore }}
              </div>
            </div>
            <div
              v-if="showBrandsZone"
              class="product-intro__storebrand-zone"
              @click="goToBrandZone"
            >
              <span class="product-intro__storebrand-zone-title">{{ language.SHEIN_KEY_PC_28141 }}</span>
              <span class="product-intro__storebrand-zone-desc">{{ language.SHEIN_KEY_PC_28142 }}</span>
              <Icon
                name="sui_icon_more_right_12px_1"
                size="12px"
                class="product-intro__storebrand-zone-icon"
              />
            </div>
          </div>

          <JumpToStoreBtn
            v-if="!isStoreBrand && brandSeriesDetail.link"
            :store-info="brandSeriesDetail"
            :button-text="bottomBtnText"
            :detail="productIntroData?.detail"
            :type="'link'"
            :is-store="isStore"
            :ext-analysis-info="extAnalysisInfo"
          />
        </div>
      </div>
    </template>
  </EntryBox>
</template>

<script>
import 'public/src/icon/jianhao.svg'
import 'public/src/icon/jiahao.svg'
import 'public/src/icon/gd-store-brand-tips-icon.svg'
import { mapState, mapMutations, mapGetters } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { transformImg } from '@shein/common-function'
import EntryBox from './ShopEntry/EntryBox'
import StoreEntry from './ShopEntry/StoreEntry'
import JumpToStoreBtn from './ShopEntry/JumpToStoreBtn'
import { CccInit } from 'public/src/pages/common/ccc_public_v2.js'
import { Icon } from '@shein-aidc/icon-vue3'
const { host, langPath, PUBLIC_CDN, SiteUID } = gbCommonInfo

daEventCenter.addSubscriber({ modulecode: '1-8-6' })

export default {
  name: 'ProductIntroBrandSeriesDetail',
  components: {
    EntryBox,
    StoreEntry,
    JumpToStoreBtn,
    Icon
  },
  props: {
    brandSeriesDetail: {
      type: Object,
      default: () => ({})
    },
    isStoreBrand: { // 是否品牌集成店铺
      type: Boolean,
      default: false
    },
    isShowRecommend: { // 是否在下方展示推荐商品入口
      type: Boolean,
      default: false
    },
    /* 控制两种品牌展示折叠扩展 */
    extendCollapseType: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      host,
      langPath,
      SiteUID,
      PUBLIC_CDN,
      cccPublic: null,
    }
  },
  computed: {
    ...mapState([
      'productIntroData',
      'unfold',
      'language',
      'storeRate',
      'storeFollowInfo',
      'storeAndBrandDataReady',
      'reviewBaseDataReady',
    ]),
    ...mapGetters(['goodsSn', 'optionsData', 'isBrandHasTargetTag', 'screenAbtConfig']),
    // 店铺/品牌区数据是否已准备好
    // TODO-staging 由于店铺评分数据来源于评论接口，需要等待评论数据加载完毕
    // TODO-staging 后续在后端提供接口支持后，单独拉取店铺评分（由tsp，减少消耗）
    storeDataReady() {
      const { storeAndBrandDataReady } = this
      return storeAndBrandDataReady
    },
    hitUnfold() {
      const { unfold, brandSeriesDetail } = this
      return unfold?.includes(brandSeriesDetail?.type)
    },
    bestSeller() {
      const { isStore, brandSeriesDetail } = this
      if (!isStore) return null
      return brandSeriesDetail.labelList[0]
    },
    formatShopScore() {
      return (+this.storeRate).toFixed(2)
    },
    // 第三方本地平台店铺
    isThirdPartyStore() {
      return this.brandSeriesDetail?.storeType == 1
    },
    // 自营店铺 / 1P店铺
    isOBMStore() {
      return [2, 6].includes(this.brandSeriesDetail?.storeType)
    },
    // 是否是店铺
    isStore(){
      return this.brandSeriesDetail?.type === 'store'
    },
    // 是否隐藏店铺相关信息
    // * 22-10-27【美国平台化-店铺展示为品牌】需求，将店铺相关信息隐藏展示为品牌
    // hideStoreRelative() {
    //   const { isStore, brandSeriesDetail, isThirdPartyStore, isOBMStore } = this
    //   if (!isStore) return false
    //   const { extAbt } = brandSeriesDetail || {}
    //   const { brandstore, brandstoreobm } = extAbt || {}
    //   if (isThirdPartyStore && brandstore?.param === 'brand') {
    //     return true
    //   }
    //   if (isOBMStore && brandstoreobm?.param === 'brand') {
    //     return true
    //   }
    //   return false
    // },
    // 是否是品牌系列
    // * 品牌系列默认展开，所以立即曝光，如果是店铺，判断是否默认展开
    reportImmediately() {
      return ['brand', 'series'].includes(this.brandSeriesDetail?.type) || (this.isStore && this.unfold?.includes(this.brandSeriesDetail?.type))
    },
    // 店铺相关标签、评分、商品数是否展示
    shouldStoreRelativeShow() {
      const { isStore } = this
      if (!isStore) {
        return false
      }
      return true
    },
    // 品牌logo展示规则
    showBrandLogo() {
      return this.isStoreBrand ? this.brandSeriesDetail.originLogo : true
    },
    brandExpand(){
      return this.brandSeriesDetail?.type == 'brand'
    },
    brandHeadTitle() {
      const { isStore, language } = this
      if (isStore) {
        return language.SHEIN_KEY_PC_21175
      } else {
        return this.brandSeriesDetail?.title
      }
    },
    // 底部跳转按钮文案
    bottomBtnText() {
      const { isStoreBrand, language, brandSeriesDetail } = this
      return isStoreBrand
        ? `${language.SHEIN_KEY_PC_25646} >`
        : `${brandSeriesDetail.desc_bak} >`
    },
    // 额外埋点信息
    extAnalysisInfo() {
      const { isStore, storeFollowInfo, formatShopScore, brandSeriesDetail } = this
      const { salesVolume, followCount } = storeFollowInfo || {}
      const brandInfo = isStore
        ? [
          `ratings_${formatShopScore}`,
          (salesVolume && salesVolume !== '0')
            ? `sold_in_30d_${salesVolume}`
            : '-',
          (followCount && followCount !== '0')
            ? `followers_${followCount}`
            : '-',
        ]
        : []
      const { labelList, salesArea } = brandSeriesDetail || {}
      const isBestSeller = !!labelList?.[0]
      const hasSaleLabel = salesArea == 1 || salesArea == 2
      const brandLabel = isStore
        ? [
          isBestSeller ? 'label_id_-\'label_content_Star Seller' : '',
          hasSaleLabel ? 'label_id_-\'label_content_Local Seller' : '',
        ]
        : []
      return {
        abtAnaInfo: storeFollowInfo?.abtAnaInfo || '',
        brandInfo: brandInfo.join('`') || '',
        brandLabel: brandLabel.filter(i => !!i).join(',')
      }
    },
    authenticbrandShowAnalysisData() {
      const { productIntroData } = this
      return {
        // 商品id 提交的goods_id
        goods_id: productIntroData?.detail?.goods_id,
        // 当前的品牌模块的brand_code
        brand_code: productIntroData?.detail?.brandInfo?.brand_code,
      }
    },
    isShowDesc() {
      return !!this.brandSeriesDetail.desc
    },
    isShowLogoTips() {
      return !!this.brandSeriesDetail?.logoTips
    },
    showBrandsZone() {
      const { brandzone = {} } = this.screenAbtConfig || {}
      return brandzone?.p?.brandzone === 'show' && this.isBrandHasTargetTag && this.productIntroData?.cccBrandZoneLink?.content?.hrefType
    },
  },
  methods: {
    ...mapMutations(['assignState']),
    goToBrandZone() {
      const { productIntroData } = this
      daEventCenter.triggerNotice({
        daId: '1-8-6-194',
        extraData: {
          goods_id: productIntroData?.detail?.goods_id,
          brand_code: productIntroData?.detail?.brandInfo?.brand_code,
        },
      })
      if(!this.cccPublic) {
        this.cccPublic = new CccInit({
          context: productIntroData.cccBrandZoneLink,
          propData: productIntroData.cccBrandZoneLink.content,
          index: 1,
        })
      }
      const linkUrl = this.cccPublic.getCccLink({
        item: productIntroData.cccBrandZoneLink.content,
      })
      linkUrl && (location.href = linkUrl)
    },
    transformImg,
  }
}
</script>

<style lang="less" scoped>

.fn_ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.product-intro {
  &__brand-table {
    display: flex;
    width: 100%;
    overflow: hidden;
    &.center {
      .product-intro__brand-table-item {
        align-items: center;
      }
    }
  }
  &__brand-table-item {
    flex: 1;
    min-width: 0;
    // display: table-cell;
    // vertical-align: top;
  }
  &__brand-detail {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__brand-logo {
    .margin-r(8px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    padding: 3px;
    border: 1px solid #e5e5e5;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  &__new-brand-logo {
    .margin-r(12px);
    width: 52px;
    height: 52px;
    padding: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    &.abt-new-store-brand-logo {
      width: 40px;
      height: 40px;
      border: 0.5px solid #E5E5E5;
      border-radius: 0;
    }
  }

  &__brand-name {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: #000;
  }
  &__new-brand-name {
    display: flex;
    flex-wrap: nowrap;
    color: #222;

    .product-intro__brand-title {
      .fn_ellipsis();
    }
  }

  &__brand-title {
    margin-right: 8px;

    &.mt {
      margin-bottom: 4px;
    }
  }
  &__brand-label {
    background: linear-gradient(128.2deg, #E2EDF3 19.13%, #EBF7FD 36.51%, #F9FCFF 45.85%, #EDF5FF 56.12%, #DAE7F6 82.16%);
    padding: 2px;
    font-size: 12px;
    font-weight: 500;
    border: 1px solid #BCC8D3;
    border-radius: 2px;
    line-height: 1;
    text-wrap: nowrap;
  }
  &__view-product {
    margin-left: auto;
    text-wrap: nowrap;
    padding-left: 13px;
    &.hidden {
      display: none;
    }
  }
  &__brand-des {
    margin-top: 8px;
    line-height: 18px;
    color: #000;
    .clamp(2);
    word-break: normal;
  }
  &__new-brand-des {
    margin-top: 6px;
    line-height: 14px;
    font-size: 12px;
    color: #999;
  }
  &__new2-brand-des {
    margin-top: 8px;
    line-height: 14px;
    font-size: 12px;
    color: #999;
    .clamp(3);
  }
  &__brand-footer {
    display: inline-flex;
    margin-top: 10px;
    .item {
      color: #666;
    }
    .mr {
      margin-right: 25px;
    }
  }

  &__brand-storebrandlabel {
    display: flex;
    align-items: center;
    white-space: nowrap;
    /* stylelint-disable-next-line */
    .svgicon-gd-store-brand-tips-icon {
      width: 14px;
      height: 14px;
    }
    .authentic {
      color: #000;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      &::before {
        content: "";
        display: inline-block;
        width: 0.5px;
        height: 8px;
        opacity: 0.4;
        background: #000;
        margin: 0 8px;
      }
    }

  }
  &__storebrand-zone {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px;
    border-radius: 2px;
    margin-top: 8px;
    cursor: pointer;
    background: linear-gradient(90deg, #E5EEF9 0.01%, rgba(229, 238, 249, 0.29) 48.74%, rgba(229, 238, 249, 0.00) 82.65%);
  }
  &__storebrand-zone-title {
    border-radius: 2px;
    padding: 2px 4px;
    flex: 0 0 auto;
    background: linear-gradient(90deg, #739BD8 0%, #9CBBEB 100%);
    color: #FFF;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &__storebrand-zone-desc {
    color: #243F6A;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &__storebrand-zone-icon {
    color: #243F6A;
    margin-left: 2px;
  }
}
</style>
