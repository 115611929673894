// 预请求数据
const prefetchRequestMap = new Map()

class DetailPrefetch {

  #prefetchRequestMap = prefetchRequestMap

  constructor() {
    this.prefetchRequestMap = this.#prefetchRequestMap
  }

  register({ key, value }) {

    if(this.prefetchRequestMap.has(key)) {
      console.warn(`The key ${key} has been registered`)
      return Promise.resolve()
    }

    if (value && typeof value.then === 'function') {
      return new Promise((resolve) => {
        this.prefetchRequestMap.set(key, value)
        value.then(res => {
          resolve(res)
        })
      })
    } else {
      console.warn('Value must be a Promise')
      return Promise.resolve()
    }
  }

  use(key) {
    const value = this.prefetchRequestMap.get(key)

    if (!value) {
      console.warn(`Check the key ${key} has registered!`)
      return Promise.resolve()
    }

    this.#remove(key)
    return value
  }

  clear() {
    this.prefetchRequestMap.clear()
  }

  #remove(key) {
    this.prefetchRequestMap.delete(key)
  }
}

export default new DetailPrefetch()
