import { computed } from 'vue'
import { useStore } from 'vuex'
import { SMessage } from '@shein-aidc/sui-message'
import { expose_quantity_edit_Input_button, click_update_quantity, click_quantity_edit_select_button, click_quantity_edit_Input_button, expose_reachedflashsalelimit } from 'public/src/pages/cart_v2/common/analysis/item/goodsQty'

export function useGoodsQtyEvent() {
  const { state, commit, dispatch } = useStore()
  const batchActive = computed(() => state.batchActive)

  const handleError = (res, analysis) => {
    if (['300714', '500302', '500306', '302444', '300417', '500303'].includes(res.code)) {
      expose_reachedflashsalelimit({}, analysis?.expose_reachedflashsalelimit)
    }
    SMessage({
      message: res.msg,
      type: 'warning',
      offset: 180,
    })
  }

  const onChange = async ({ itemOpts: { data, event } = {}, options: { isShowOverLimitToast = false } = {}, analysis = {} }) => {
    if (batchActive.value) return
    const { quantity, action } = event
    const oldQuantity = data.value.quantity
    data.value.quantity = String(quantity)
    const onError = () => {
      data.value.quantity = oldQuantity
    }
    try {
      commit('updateState', { key: 'loadingShow', value: true })
      const reqParam = {
        quantity: String(quantity),
        cart_id: data.value.id,
        append_id_list: data.value.appendIds || [],
        is_checked: data.value.is_checked,
      }
      const res = await dispatch('fetchCartUpdate', reqParam)
      if (res.code == '0') {
        const result = res.code == 0 ? 1 : 0
        if (action === 'input') {
          click_update_quantity(data.value, (defaultData) => {
            const data = {
              ...defaultData,
              result,
            }
            if (analysis?.click_update_quantity) {
              return analysis.click_update_quantity(data)
            }
            return data
          })
        } else {
          click_quantity_edit_select_button(data.value, (defaultData) => {
            const data = {
              ...defaultData,
              result
            }
            if (analysis?.click_quantity_edit_select_button) {
              return analysis.click_quantity_edit_select_button(data)
            }
            return data
          })
        }
        dispatch('showOverLimitToast', { id: data.value.id, isShowOverLimitToast })
      } else {
        handleError(res, analysis)
        onError()
      }
    } catch {
      onError()
    } finally {
      commit('updateState', { key: 'loadingShow', value: false })
    }
  }
  const onReport = ({ itemOpts: { data, event } = {}, analysis = {} }) => {
    const { type } = event
    if (type === 'clickInput') {
      click_quantity_edit_Input_button(data.value, analysis?.click_quantity_edit_Input_button)
    } else if (type === 'clickNullValueOption') {
      click_quantity_edit_select_button(data.value, analysis?.click_quantity_edit_select_button)
    }
  }
  const onExpose = ({ itemOpts: { data }, analysis = {} }) => {
    expose_quantity_edit_Input_button(data.value, analysis?.expose_quantity_edit_Input_button)
  }

  return {
    onChange,
    onReport,
    onExpose,
  }
}
