import type { AxiosRequestConfig, AxiosSyncRequestConfig } from '@shein-aidc/basis-schttp'
import type { Libs, Manager } from '../types'

function _ensureEnvironment<T>(type: '$schttp' | '$schttpSync', envs: Manager.EnsureEnvs, configs: AxiosRequestConfig, instance?: T): instance is T {
  let message = ''
  if (typeof window === 'undefined' && !envs.requestInServer) {
    message = `should not run ${type} in server environment`
  } else if (!instance) {
    message = `${type} is missing, checkout your initialize configs`
  }
  if (message) {
    console.error(message, configs)
    return false
  }
  return true
}

function _ensureConfigs(config: AxiosRequestConfig, envs: Manager.EnsureEnvs) {
  const baseURL = config.baseURL ?? (envs.requestInServer ? '' : `${envs.langPath}/bff-api`)
  config = {
    ...config,
    baseURL,
    headers: {
      ['bff-env']: envs.bffEnv || '',
      ['webVersion']: envs.webVersion || '',
      ['x-requested-with']: 'XMLHttpRequest',
      ...config.headers,
    },
    // @ts-ignore
    __fromBSLibs__: true,
  }
  if (!config.headers?.['bff-env']) delete config.headers?.['bff-env']
  if (!config.headers?.['webVersion']) delete config.headers?.['webVersion']

  return config
}

export function getLibsSchttp(schttp: Manager.InitConfigs['schttp'], envs: Manager.EnsureEnvs) {
  return function <T>(configs: AxiosRequestConfig) {
    if (!_ensureEnvironment('$schttp', envs, configs, schttp)) throw new Error('_ensureEnvironment')
    return schttp<T>(_ensureConfigs(configs, envs))
  }
}
export function getLibsSchttpSync(schttpSync: Manager.InitConfigs['schttpSync'], envs: Manager.EnsureEnvs) {
  return function <T>(configs: AxiosSyncRequestConfig) {
    if (!_ensureEnvironment('$schttpSync', envs, configs, schttpSync)) throw new Error('_ensureEnvironment')
    return schttpSync<T>(_ensureConfigs(configs, envs))
  }
}

export function createLibsSchttpInOne(schttp: Libs.AppConfigs['$schttp'], schttpSync: Libs.AppConfigs['$schttpSync']) {
  return function <T>({ isAsync, opt, successCb, errorCb, completeCb }: Parameters<Libs.AppConfigs['$schttpInOne']>[0]) {
    if (isAsync) {
      schttp<T>({
        ...opt,
      }).then(res => {
        successCb ? successCb(res) : ''
      }).catch(err => {
        errorCb ? errorCb(err) : ''
      }).finally(() => {
        completeCb ? completeCb() : ''
      })
    } else {
      schttpSync<T>({
        ...opt,
        success: (res => {
          successCb ? successCb(res) : ''
        }),
        error: (err => {
          errorCb ? errorCb(err) : ''
        }),
        complete: (res => {
          completeCb ? completeCb(res) : ''
        }),
      })
    }
  }
}
