<template>
  <div
    v-expose="analysisData('1-7-3-31')"
    class="cart-soldout-items"
  >
    <!-- 单商品行 -->
    <div
      v-if="soldoutItems.length == 1"
      class="cart-soldout-items__normal"
    >
      <CartItem
        :item="soldoutItems[0]"
        :index="cartIds.indexOf(soldoutItems[0].id)"
      />
    </div>
    <!-- 一行多图 -->
    <div
      v-if="soldoutItems.length > 1"
      class="cart-soldout-items__multi"
    >
      <CartSwiper
        :items="soldoutItems"
        :locals="locals"
        :ui-config="uiConfig"
        class="cart-soldout-items__multi-swiper"
      >
        <template #default="{ item }">
          <CartSoldOutItem
            :item="item"
            :index="cartIds.indexOf(item.id)"
          />
        </template>
      </CartSwiper>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter'
import CartItem from 'public/src/pages/cart_v2/components/functional/cartList/CartItem.vue'
import CartSoldOutItem from './CartSoldOutItem.vue'
import CartSwiper from 'public/src/pages/cart_v2/components/functional/common/CartSwiper.vue'

daEventCenter.addSubscriber({ modulecode: '1-7-3' })

const { state } = useStore()
const locals = computed(() => state.locals)
const soldoutItems = computed(() => state.soldoutItems)
const cartIds = computed(() => state.cartIds)
const uiConfig = ref({
  iconSize: '18px',
  buttonSize: '32px',
  buttonMarginTop: '-28px',
})
const analysisData = (id) => {
  const goodsInfo = []
  soldoutItems.value.forEach((item) => {
    const showReselect = item.aggregateProductBusiness?.changeGoodInfo?.isOtherAttr === '1'

    let saleStatus = ''
    if (showReselect) {
      saleStatus = 0
    } else {
      saleStatus = +item.quantity > +item.realTimeInventory ? 2 : 1
    }

    let member_gift_info = '-'
    if (item.isPayMemberGift == 1) {
      member_gift_info = item.isInvalid == '1' ? 'invalid' : 'no_stock'
    }

    goodsInfo.push(`${item.product.goods_id}\`${saleStatus}\`${member_gift_info}\`${item.aggregateProductBusiness?.productRowBiData?.goodsStatus}`)
  })
  return {
    id: id,
    data: {
      goods_info: goodsInfo.join(','),
    }
  }
}
</script>

<style lang="less">
.cart-soldout-items {
  background-color: #fff;
  &__normal {
    padding: 12px 16px;
  }
  &__multi {
    padding: 12px 16px;
  }
  &__multi-swiper {
    display: flex;
    gap: 5px;
  }
}
</style>
