import { computed } from 'vue'
import { useStore } from 'vuex'

export const useCartAllDiscountDetail = ({ mode } = {}) => {
  const { state, getters } = useStore()
  const isSuggested = computed(() => getters.isSuggested)
  const showPromotionDetailPopUp = computed(() => state.cartInfo.showPromotionDetailPopUp === '1')
  const promotionDetailPopUp = computed(() => state.cartInfo.promotionDetailPopUp || {})
  const isExposePriceDetail = computed(() => getters.isExposePriceDetail)

  const allDiscountDetailMode = computed(() => {
    if (mode) return mode
    if (!showPromotionDetailPopUp.value) return 'none'
    return isExposePriceDetail.value ? 'expose' : 'popover'
  })

  const allDiscountDetailContentProps = computed(() => {
    const props = {
      data: promotionDetailPopUp.value,
      isCacheProduct: allDiscountDetailMode.value === 'popover',
    }
    if (allDiscountDetailMode.value === 'expose') {
      props.discountProductWidth = '60px'
      props.discountProductHeight = '60px'
      const hideBenefitTypes = ['title']
      if (!isSuggested.value) {
        hideBenefitTypes.push('estimatedAmount')
      }
      props.hideBenefitTypes = hideBenefitTypes
    }
    return props
  })

  // 是否存在闪购超限提示
  const hasFlashSale = computed(() => {
    const aClassPromotions = promotionDetailPopUp.value?.items?.find(item => item.benefitType === 'aClassPromotions')
    const bClassPromotions = promotionDetailPopUp.value?.items?.find(item => item.benefitType === 'bClassPromotions')
    return aClassPromotions?.data?.twoLevelData?.some(item => item.typeId === '10' && !!item.questionMark) || bClassPromotions?.data?.twoLevelData?.some(item => item.typeId === '10' && !!item.questionMark)
  })

  // 整车优惠明细外漏 且 到手价不展示时，需要把到手价提示语放到 Order Summary 下边
  const estimatedAmountSub = computed(() => {
    if (allDiscountDetailContentProps.value.hideBenefitTypes?.includes('estimatedAmount')) return promotionDetailPopUp.value.items?.find(item => item.benefitType === 'estimatedAmount')?.data.additionalDesc
    return ''
  })

  return {
    allDiscountDetailMode,
    allDiscountDetailContentProps,
    hasFlashSale,
    estimatedAmountSub,
  }
}
