export const STYLE_TYPE = {
  'DEFAULT': 'default',                   // 黑色样式
  'ORANGE': 'orange',                     // 橙色样式
  'ORANGE_SCREEN': 'orange_screen',       // 橙色压缩样式
}
/**
 * 曝光埋点数据
 */
export const getExposeAnalysisData = (options = {}) => {
  const { isPopup, abtest } = options
  const data = {
    abtest,
    activity_from: 'togetherbuy',
    style: isPopup ? 'popup' : 'detail',
    tab_list: '-',
    location: isPopup ? 'popup' : 'page',
  }
  return {
    id: '1-8-10-2',
    code: 'Togetherbuy',
    data
  }
}

export const setComboBuyTapAnalysisData = ({ target, isPopup, click_type, isClickCheckbox = false, tspABTest = '', fromSize }) => {
  const extraData = {
    abtest: tspABTest,
    activity_from: 'togetherbuy',
    tab_list: '-',
    location: isPopup ? 'popup' : 'page',
  }

  if (isClickCheckbox) {
    daEventCenter.triggerNotice({
      daId: '1-8-10-6',
      extraData: {
        ...extraData,
        click_type,
      },
      target
    })
  } else {
    daEventCenter.triggerNotice({
      daId: '1-8-10-3',
      extraData: {
        ...extraData,
        style: isPopup ? 'popup' : 'detail',
        click_position: fromSize ? 'size' : 'image'
      },
      target
    })
  }

}
