<template>
  <div class="checkout-order-summary">
    <h4 class="checkout-order-summary__title fsp-element">
      {{ language.SHEIN_KEY_PC_14764 }}
    </h4>
    <div 
      v-if="mxTaxNotice.fixedNoticeVisible && mxTaxNoticeDesc"
      class="checkout-order-summary__notice"
      :style="`height: ${taxNoticeHeight};transition-duration:${duration}`"
    >
      <div 
        ref="taxNoticeRef"
        class="checkout-order-summary__notice-inner"
      >
        <Icon 
          name="sui_icon_activity_orange_18px_color" 
          size="16px"
          color="#FA6338"
          style="line-height: 1;"
        />
        <div v-html="mxTaxNoticeDesc"></div>
      </div>
    </div>
    <div class="checkout-order-summary__body">
      <!-- 价格明细 -->
      <CheckoutSummaryPrice 
        v-expose="{
          id: '1-11-1-201',
        }"
        :is-discount-total-color="isDiscountTotalColor"
        :pre-pay-un-pay-params="prePayUnPayParams"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { template, times } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue3'
import { daEventCenter as daEventCenterV2 } from '@shein-aidc/basis-sa-event-center'

import CheckoutSummaryPrice from 'public/src/pages/checkout/components/summary/price/PriceInfo.vue'

export default {
  name: 'OrderSummaryEffiency',
  components: {
    CheckoutSummaryPrice,
    Icon
  },
  props: {
    isDiscountTotalColor: Boolean,
    prePayUnPayParams: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      taxNoticeHeight: '0px',
      duration: '0.8s'
    }
  },
  computed: {
    ...mapState([
      'checkout',
      'language',
      'locals',
      'subtotalPriceDetail',
      'mxTaxNotice'
    ]),
    caculateInfo() {
      return this.checkout?.mall_caculate_info || {}
    },
    SiteUID() {
      return this.locals?.SiteUID ?? gbCommonInfo?.SiteUID
    },
    isBrSite() {
      return !!~this.SiteUID?.indexOf('br')
    },
    isMxSite() {
      return !!~this.SiteUID?.indexOf('mx')
    },
    mxTaxNoticeDesc() {
      const { personal_tax, total_tax, tax_subsidy_list, tax } = this.caculateInfo || {}
      let noticeDescText = ''
      if(this.isMxSite) {
        // 个人税金 = 0，代表公司 100% 补贴，否则公司部分补贴
        if (+personal_tax?.amount === 0) {
          return template(
            // 空格占位符
            '&nbsp;',
            total_tax?.amountWithSymbol,
            this.language.SHEIN_KEY_PC_32451
          )
        } 
        
        const subsidyItem = tax_subsidy_list?.find(item => +item.tax_type === 7)
        if (!subsidyItem) return ''
        // 实际补贴（公司支付）
        const actualSubsidy = tax?.find(item => item.taxType == 1 && item.taxPayType == 1) || {}
        noticeDescText = template(
          `${times(+subsidyItem?.actual_subsidy_ratio, 100)}%`,
          // 空格占位符
          '&nbsp;',
          actualSubsidy.taxPrice.amountWithSymbol,
          this.language.SHEIN_KEY_PC_32452
        )
      }
      if(this.isBrSite) {
        if(this.mxTaxNotice.isGoodsVatType) {
          const subsidyItem = tax_subsidy_list?.find(item => +item.tax_type === 3)
          if (!subsidyItem) return ''
          // 实缴个人增值税税金 
          const acturalPersonVat = tax?.find(item => item.taxPayType == 2 && item.taxType == 2)?.taxPrice
          // 公司补贴增值税
          const companyVat = tax?.find(item => item.taxPayType == 1 && item.taxType == 3)?.taxPrice
          // 公司100%补贴增值税
          if(this.subtotalPriceDetail?.ICMSAmount.amount > 0 && (acturalPersonVat?.amount == 0 || !acturalPersonVat)) {
            noticeDescText = template(
              // 空格占位符
              '&nbsp;',
              companyVat?.amountWithSymbol,
              this.language.SHEIN_KEY_PC_33786
            )
          }
          // 公司部分补贴增值税
          if(this.subtotalPriceDetail?.ICMSAmount.amount > 0 && acturalPersonVat?.amount > 0) {
            noticeDescText = template(
              `${times(+subsidyItem?.actual_subsidy_ratio, 100)}%`,
              // 空格占位符
              '&nbsp;',
              companyVat?.amountWithSymbol,
              this.language.SHEIN_KEY_PC_33785
            )
          }
          
        } else {
          noticeDescText = this.language.SHEIN_KEY_PC_32813
        }
      }
      return noticeDescText
    },
  },
  watch: {
    'mxTaxNotice.fixedNoticeVisible': {
      handler() {
        if (typeof window === 'undefined') return
        const { dialogVisible, fixedNoticeVisible } = this.mxTaxNotice
        // 如果仅展示政策条，不展示弹窗，直接展示政策条，不需要动效
        if (!dialogVisible && fixedNoticeVisible && this.mxTaxNoticeDesc) {
          this.duration = '0s'
          this.calculateHeight()
        }
      }
    },
    'mxTaxNotice.dialogVisible': {
      handler(dialogVisible) {
        if (typeof window === 'undefined') return
        // 通知栏需要等弹窗关闭再出现
        if (!dialogVisible) this.calculateHeight()
      }
    },
    caculateInfo: {
      handler(newVal = {}, oldVal = {}) {
        if (typeof window === 'undefined' || JSON.stringify(newVal) === JSON.stringify(oldVal)) return
        this.updateMxTaxStatus()
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations(['updateMxTaxStatus']),
    calculateHeight() {
      this.$nextTick(() => {
        if (!this.$refs?.taxNoticeRef) return
        const { clientHeight } = this.$refs.taxNoticeRef
        this.taxNoticeHeight = `${clientHeight + 16}px`
        if(!sessionStorage.getItem('is_tax_notice_showed')){
          daEventCenterV2.triggerNotice({
            id: 'expose_tax_show.page_checkout',
            extraData: {
              location: 'bar',
            }
          })
          sessionStorage.setItem('is_tax_notice_showed', 'true')
        }
      })
    }
  }
}
</script>

<style lang="less">
.checkout-order-summary {
  padding: 16px;
  margin-bottom: 10px;
  background: #fff;

  &__title {
    font-family: Arial Black;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 8px;
    color: @sui_color_gray_dark1;
    text-transform: capitalize;

    & when (@IS_RW) {
      font-family: Adieu;
    }
  }

  &__notice {
    margin: 0 -16px;
    overflow: hidden;
    transition: height 0.8s;
  }

  &__notice-inner {
    padding: 10px;
    background: #FFF3D3;
    font-size: 12px;
    font-family: Arial;
    color: #000;
    display: flex;
    align-items: center;
    line-height: 1.2;
    gap: 4px;
  }
  

  &__body {
    .checkout-summary-price {
      margin: -4px 0;
    }
    .summary-item {
      line-height: 15px;
      font-size: 13px;
      margin-bottom: 0;

      padding: 4px 0;

      &.total-item-wrap {
        line-height: 25px;
        .flexbox();
        .item-left {
          font-family: Arial;
        }

        .item-right {
          text-align: right;
          flex-grow: 1;
          flex-shrink: 0;
          flex-basis: max-content;
          font-family: Arial Black;
          font-size: 18px;
        }
      }

      &.gov-tips {
        margin-top: 2px;
        color: @sui_color_gray_dark2;
      }
    }

    .checkout-summary-price .summary-item del {
      color: @sui_color_gray_light1; 
    }

    .order-summary-taxes__wrap {
      margin: 4px 0;
    }

    .point-tip {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 0;
      margin-top: 8px;
      color: @sui_color_gray_dark1;

      .point-shein {
        border: none;
        padding: 0 0 0 5px;
      }
    }

    .summary-coupon-txt {
      text-align: left;
      margin-top: 2px;
    }

    .checkout-options__alert-effiency {
      padding: 10px;
      margin-top: 8px;
    }

    .order-summary-taxes {
      padding-bottom: 8px;
    }

    .prime-discount-info {
      margin: 2px 0 0;
      color: @sui_color_gray_light1;
    }

    .text-red {
      color: @sui_color_welfare_dark;
    }

    [class*="sui_icon_doubt"] {
      font-size: 14px;
      color: @sui_color_gray_dark3;
    }
  }
}
</style>
