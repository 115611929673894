// import { getSaGoodsList } from 'public/src/services/eventCenter/analysis/config/components/product-item.js'
// import { isLogin } from 'public/src/pages/common/utils/index.js'

// export const getReportGoodsList = (items) => {
//   if(!items || !items.length) return ''
//   items.map((item, index) => {
//     const itemStr = getSaGoodsList({
//       goodsId: item.goodsId,
//       sku: item.sku,
//       index,
//       price: '',
//       originPrice: ''
//     })
//     return itemStr
//   }).join(',')
// }


export const getReportItemGoodsId = (item) => {
  if(!item) return ''
  const itemStr = item.goods_id
  return itemStr
}

export const getNum = (val, isLogin = true) => {
  if(isLogin) {
    return val || 0
  }
  return val || null
} 

