import { ref } from 'vue'
const millSecCountDownTimer = ref(null)
const secondHundred = ref(0)

const initMillSecCountDown = () => {
  millSecCountDownTimer.value = setInterval(() => {
    secondHundred.value--
    if (secondHundred.value < 0) secondHundred.value = 9
  }, 100)
}

export function useMillSecCountDown () {
  if(!millSecCountDownTimer.value) {
    initMillSecCountDown()
  }
  return { secondHundred }
}
