/**
 * 新ccc数据 生成埋点相关的工具函数
 * 这个文件的方法 - 可能是业务中也会用到（比如跳转链接），埋点文件中也会用到
 */
// ============================================= Begin
// import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'

// import{ abtservice }from'public/src/services/abt'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

const getTabPageId = () => {
  if (typeof window === 'undefined') return ''
  const { SaPageInfo } = useAppConfigs().$getters || {}
  return SaPageInfo && SaPageInfo.page_name ? (SaPageInfo.page_name + SaPageInfo.start_time) : ''
}

/**
 * 获取SA的资源位信息拼接
 * @param {*} sceneName 场景名称
 * @param {*} componentName 运营名称（组件名称）
 * @param {*} operationComponentName 运营组件名称（运营自己想要什么名字就要什么名字 props.name）
 * @param {*} comIndex 组件位置
 * @param {*} positionIndex 当前资源在组件中的位置
 * @param {*} hotZoneParams 热区信息
 * @param {*} item 当前资源的信息
 * @param {*} extra  拓展字段--- 不再新增参数， 通过extra拓展
 * @returns
 */
export const getBiCccSourceFrom = ({ sceneName = '', pageFrom, activityId, activityNameEn, componentName = '', operationComponentName = '', isSuspension = false, comIndex = 1, positionIndex = 1, item = {}, hotZoneParams = {}, extra = {} }) => {
  const { hrefType = 'noJump', hrefTarget = '' } = item || {}

  const getContentList = () => {
    if (hrefType === 'webLink') {
      if (item.isInternalLink == 1 && hrefTarget) {
        return `${hrefType}_${location.protocol}//${location.hostname}${hrefTarget}`
      }
    }
    return `${hrefType ? hrefType : 'noJump' }_${hrefTarget || 0}` // 跳转类型英文标识_跳转内容 hrefType_hrefTarget
  }

  const jc = getContentList()
  const localtionIndex = positionIndex
  // 热区名称
  let { hot_area = '' } = hotZoneParams
  const srcIdentifierList = [`on=${componentName}`, `cn=${operationComponentName}`, `hz=${hot_area || 0}`, isSuspension ? '`ps=' : `ps=${comIndex}_${localtionIndex}`, `jc=${decodeURIComponent(jc)}`]
  pageFrom == 'campaigns' && srcIdentifierList.push(...[`ai=${activityId}`, `an=${activityNameEn}`])
  let srcIdentifier = srcIdentifierList.join('`')
  if(extra.extraSrcIdentifier){
    srcIdentifier += '`' + extra.extraSrcIdentifier
  }
  const tabPageId = getTabPageId()
  const urlParams = [`src_module=${sceneName}`, `src_identifier=${encodeURIComponent(srcIdentifier)}`, `src_tab_page_id=${tabPageId}`].join('&')

  return {
    src_module: sceneName,
    src_identifier: srcIdentifier,
    src_tab_page_id: tabPageId,
    url_params: urlParams,
  }
}


/**
 * 生成ga的 name/ici
 * @param {Object} propData 组件ccc数据
 * @param {String} sceneName 场景名称
 * @param {String|Number} tagId 人群Id
 * @param {String} abtInfo abt信息， poskey_分支类型，多个实验使用逗号分割
 * @param {String} placeholder gaName的差异化 TY/CS, 不传会有一个{-}的占位
 */
export const getGaCccParams = ({ propData = {}, sceneName = 0, poskey = '', position = null, aodId = 0 }) => {
  // http://wiki.dotfashion.cn/pages/viewpage.action?pageId=205784109
  // CCCSN={场景名称}_TN={tab名称}（m和pwa首页有多语言，写死的上报HomePage-tab id）_ON={运营位名称}_OI={运营位id}_CN={组件名称}_TI={人群id }_aod={精投标识}_TY={跳转id/跳转类型/跳转链接}_ABT={poskey_分支类型}
  const { componentKey = 0, id = 0, styleType = 0 } = propData || {}

  // const tagId = UserInfoManager.get({ key: 'memberId', actionType: 'ccc-analysis' }) ? 50000 : 50001
  const tagId = 50000
  let abtInfo = ''
  if (poskey) {
    // const abtInfoResult = abtservice.getUserAbtResultForAnalysis({ posKeys: poskey })
    // abtInfo = abtInfoResult?.ga || ''
    abtInfo = abtInfo?.replaceAll?.('-', ',')
  }

  const iciParams = [
    `CCCSN=${sceneName}`,
    `ON=${componentKey}`,
    `OI=${id}`,
    `CN=${styleType}`,
    `TI=${tagId}`,
    `aod=${aodId}`,
  ]

  const gaName = iciParams.concat([
    position ? position : 'PS={-}',
    `ABT=${abtInfo || 0}`,
  ])

  return {
    name: gaName.join('_'),
    ici: iciParams.join('_'),
  }
}
