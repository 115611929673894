 
export const DAILY_KEY = 'daily'
export const CATE_KEY = 'cate'
export const TSP_KEY = 'tsp'
export const ATTR_KEY = 'attr'
export const PRICE_KEY = 'priceRange'
export const BRAND_KEY = 'brand'

export function getPlaceholderHeight (width, height) {
  return `${((height / width) * 100).toFixed(2)}%`
}

// 页面标识映射pageName -> ccc pageType
export const PAGE_NAME_MAP_PAGE_KEY = {
  page_real_class: 'page_real_class',
  page_select_class: 'page_select_class',
  page_daily_new: 'daily_new',
  page_selling_point: 'page_selling_point',
  page_goods_group: 'shein_picks',
  page_picked: 'page_picked',
  page_search: 'page_search'
}


export function getIsCustomAttr (attr, key = 'attr_values') {
  return attr?.[key]?.some(item => item?.[key]?.length > 0)
}

/**
 * filterBar queryParams
 */
export const filterBarQueryParams = [
  'sort',
  'daily',
  'navId',
  'child_cat_id',
  'child_cat_id_void',
  'attr_values',
  'tsp_ids',
  'attr_ids',
  'tag_ids',
  'min_price',
  'max_price',
  'pic_nav_id',
  'pic_nav_type',
  'pic_cate_id',
  'pic_tab_id',
  'kid_id',
  'brand_ids',
]

/**
 * url 的 search 是否有 filterBar的queryParams
 * @param {Object} catInfo catInfo 对象包含了 url 的 search
 * @returns { Boolean }
 */
export function hasFilterBarQueryParams(catInfo) {
  return filterBarQueryParams.some(key => !!catInfo[key])
}

// 是否有筛选条件
export function getIsHasFilter (queryParams) {
  return [
    'child_cat_id',
    'attr_ids',
    'brand_ids',
    'tag_ids',
    'min_price',
    'max_price',
    'pic_nav_id',
    'tsp_ids'
  ].some(key => queryParams[key] || queryParams[key] === 0)
}

export const getListItems = ({
  productList = [],
  catInfo,
  goodsItemInfo,
  $isServer,
  goodsMap,
  flowSlot,
  calcProductPos,
  dealListBannerComp,
}) => {
  let index = 0
  const results = []
  for (let i = 0; i < productList.length; i++) {
    const item = productList[i]
    item.index = index
    const {
      src_module,
      src_identifier,
      src_tab_page_id,
      attr_ids = '',
      url_from,
      store_code,
      search_type,
      keywords,
      pageListType
    } = catInfo

    let biQueryParams = {}

    // 门店搜索直接使用
    if (store_code && search_type === 'store' && keywords) {
      biQueryParams = {
        src_identifier: src_identifier,
        src_module: src_module,
        src_tab_page_id,
        attr_ids,
      }
    } else {
      biQueryParams = {
        src_identifier: url_from
          ? `uf=${url_from}`
          : encodeURIComponent(src_identifier || ''),
        src_module: url_from ? 'ads' : src_module,
        src_tab_page_id,
        attr_ids,
      }
    }

    const mallCode = item.mall_code ? { mallCode: item.mall_code } : {}
    goodsItemInfo.dealGoodsDetailUrl({
      goodsItem: item,
      query: {
        ...biQueryParams,
        ...mallCode,
        pageListType: pageListType
      },
    })
    if (!$isServer) {
      item.relatedColor &&
            item.relatedColor.forEach((relateColorItem) => {
              const mallCode = relateColorItem.mall_code
                ? { mallCode: relateColorItem.mall_code }
                : {}
              relateColorItem.index = index
              goodsItemInfo.dealGoodsDetailUrl({
                goodsItem: relateColorItem,
                query: {
                  ...biQueryParams,
                  ...mallCode,
                  pageListType: pageListType
                },
              })
            }) 
      goodsMap[item.goods_id] = item
    }
    index += 1

    const len = results.length
    const slotIndex = calcProductPos(len - 1)
    if (flowSlot && flowSlot[slotIndex]) {
      results.push(flowSlot[slotIndex])
    } else {
      const listBanner = dealListBannerComp(len)
      listBanner && results.push(listBanner)
    }
    results.push(item)
  }
  return results
}
