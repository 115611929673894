<template>
  <div
    id="productIntroPrice"
    class="ProductIntroHeadPrice"
  >
    <div
      class="ProductIntroHeadPrice__PriceBannerType"
      :class="[
        {'ProductIntroHeadPrice__PriceBannerType_normalFlash': priceBannerTypeData.isPriceBannerTypeAbtNew && normalFlashStyle && !isBrandFlash},
        {'ProductIntroHeadPrice__PriceBannerType_specialFlash': priceBannerTypeData.isPriceBannerTypeAbtNew && isSpecialFlash},
        {'ProductIntroHeadPrice__PriceBannerType_brandSpecialFlash': priceBannerTypeData.isPriceBannerTypeAbtNew && isBrandFlash},
        {'ProductIntroHeadPrice__PriceBannerType_hide': !priceBannerTypeData.isPriceBannerTypeShow}
      ]"
    >
      <div 
        v-if="priceBannerTypeData.isPriceBannerTypeShow"
        :class="[
          {'ProductIntroHeadPrice__PriceBannerType-lightningImg': !isBrandFlash},
          {'ProductIntroHeadPrice__PriceBannerType-brandSpecialFlashImg': isBrandFlash},
        ]"
      >
        <img
          :src="isBrandFlash ? '/she_dist/images/product_detail/flash-belt-brand-be0881bbaa.png' : '/she_dist/images/product_detail/flash-belt-3700a8f0a6.png'"
        />
      </div>
      <div
        v-if="priceBannerTypeData.isPriceBannerTypeShow"
        class="ProductIntroHeadPrice__PriceBannerType-head"
        :style="{cursor: isBrandFlash ? '' : 'pointer'}"
        @click="handlePriceBannerTypeHead"
      >
        <div
          class="ProductIntroHeadPrice__PriceBannerType-headLeft"
          :style="{'color': isBrandFlash ? '#FFF' : (normalFlashStyle ? '#000' : '#FA6338')}"
        >
          <Icon
            v-if="!isBrandFlash"
            name="sui_icon_flashsale_12px"
            size="15px"
          />
          <div 
            v-if="isBrandFlash"
            class="ProductIntroHeadPrice__PriceBannerType-headLeftBrandIcon"
          >
            <svg
              width="9"
              height="12"
              viewBox="0 0 9 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.33333 0.0834961C3.49238 0.0834961 2 1.57588 2 3.41683V6.30573H0.781853C0.428587 6.30573 0.235606 6.71775 0.461761 6.98913L4.05892 11.3057L7.65608 6.98914C7.88223 6.71775 7.68925 6.30573 7.33599 6.30573H6.16667V2.16683C6.16667 1.01624 7.09941 0.0834961 8.25 0.0834961H5.33333Z"
                fill="white"
              />
            </svg>
          </div>
          <span>{{ priceBannerTypeData.title }}</span>
          <Icon
            v-if="isSpecialFlash"
            class="ProductIntroHeadPrice__PriceBannerType-headLeftSpecialMoreIcon"
            :name="GB_cssRight ? 'sui_icon_more_left_14px_1' : 'sui_icon_more_right_14px_1'"
            size="12px"
          />
        </div>
        <div
          class="ProductIntroHeadPrice__PriceBannerType-headRight"
          :style="{ color: isBrandFlash ? '#FFF' : '' }"
        >
          <template v-if="priceBannerTypeNormalFlashData.showCountTime">
            <span>{{ globalTempalte('', language.SHEIN_KEY_PC_17111) }}&ensp;</span>
            <span
              v-if="GB_cssRight"
              class="ProductIntroHeadPrice__PriceBannerType-headRightHundred"
              :style="isBrandFlash ? { color: '#FA6338', background: '#FFF' } : {}"
            >{{ priceBannerTypeNormalFlashData.secondHundred }}</span>
            <span>{{ Number(priceBannerTypeNormalFlashData.countdownStr.D) > 0 ? (priceBannerTypeNormalFlashData.countdownStr.D + 'd') : '' }} {{ priceBannerTypeNormalFlashData.countdownStr.H }}h : {{ priceBannerTypeNormalFlashData.countdownStr.M }}m : {{ priceBannerTypeNormalFlashData.countdownStr.S }}s</span>
            <span
              v-if="!GB_cssRight"
              class="ProductIntroHeadPrice__PriceBannerType-headRightHundred"
              :style="isBrandFlash ? { color: '#FA6338', background: '#FFF' } : {}"
            >{{ priceBannerTypeNormalFlashData.secondHundred }}</span>
          </template>
          <span v-else-if="isFlashSaleCountDownAbtHide && !isBrandFlash && !isSpecialFlash">{{ language.SHEIN_KEY_PC_28779 }}</span>
          <Icon
            v-if="priceBannerTypeData.showRightIcon"
            class="ProductIntroHeadPrice__PriceBannerType-headRightIcon"
            :style="{color: isBrandFlash ? '#FFF' : ''}"
            :name="GB_cssRight ? 'sui_icon_more_left_14px_1' : 'sui_icon_more_right_14px_1'"
            size="12px"
          />
        </div>
      </div>

      <div
        :class="[
          { 'ProductIntroHeadPrice__PriceBannerType-priceWraper': priceBannerTypeData.isPriceBannerTypeShow },
          { 'ProductIntroHeadPrice__PriceBannerType-priceWraper_brandFlash': isBrandFlash && !isFloorType }
        ]"
      >
        <div 
          v-if="!isPercentNewStyle"
          class="ProductIntroHeadPrice__head-price j-expose__ProductIntroHeadPrice__head-price"
        >
          <!-- 新型闪购新样式 -->
          <div
            v-if="isSpecialFlash"
            :class="{'ProductIntroHeadPrice__head-pricespecialFlash': !priceBannerTypeData.isPriceBannerTypeShow && !isFloorType}"
          >
            <div 
              class="ProductIntroHeadPrice__head-pricespecialFlashLeft"
              :class="{
                'ProductIntroHeadPrice__head-pricespecialFlashLeft_has-estiamted': hasEstimated,
                'ProductIntroHeadPrice__head-pricespecialFlashLeft_bigSmall': isSheinappwordsizeNew
              }"
            >
              <PromotionIcon 
                :show="promotionInfo && promotionInfo.length && !priceBannerTypeData.isPriceBannerTypeShow && !isInversion && !isInversionDe"
                :promotion-info="promotionInfo"
                :normal-flash-style="normalFlashStyle"
                :is-rw="IS_RW"
                :product-info-price="productInfoPrice"
                :price="price"
                :realtime-price-promotion-status="realtimePricePromotionStatus"
              />
              <p
                :class="{'goods-price__small-size': isSmallStyle}"
                :style="{'line-height': isSheinappwordsizeNew ? '1' : '1.2'}"
                v-html="mainPriceWordSizeFormater(newFlashPrice)"
              >
              </p>

              <div style="width: 2px;"></div>
              <PricePopover 
                v-if="pricePopoverInfo && realTimeReady"
                :price-popover-info="pricePopoverInfo"
              />
              <div style="width: 2px;"></div>
              <div
                v-if="hasEstimated"
                style="width: 2px;"
              ></div>

              <template v-if="hasEstimated && !isShowCountdown && !showEstimatedNoThresholdNew">
                <template v-if="complianceModeDe">
                  <div
                    v-if="deDiscountInfo.retailPrice.show"
                    class="ProductIntroHeadPrice__de-discount"
                  >
                    <span class="ProductIntroHeadPrice__de-discountText">{{ language.SHEIN_KEY_PC_31021 }}</span>
                    <del class="ProductIntroHeadPrice__de-discountDel">{{ deDiscountInfo.retailPrice.value }}</del>
                    <span
                      v-if="deDiscountInfo.retailDiscountPercent.show"
                      :class="discountClassMap"
                      :style="{
                        'transform': isSheinappwordsizeNew ? 'translateY(-2px)' : ''
                      }"
                    >
                      {{ formatPercentText(deDiscountInfo.retailDiscountPercent.value) }}
                    </span>
                  </div>
                  <div
                    v-if="deDiscountInfo.suggestedSalePrice.show"
                    class="ProductIntroHeadPrice__de-discount"
                    :style="{
                      width: deDiscountInfo.retailPrice.show ? '100%' : '',
                      margin: deDiscountInfo.retailPrice.show ? '10px 0' : ''
                    }"
                  >
                    <span class="ProductIntroHeadPrice__de-discountText">{{ language.SHEIN_KEY_PC_31022 }}</span>
                    <del class="ProductIntroHeadPrice__de-discountDel">{{ deDiscountInfo.suggestedSalePrice.value }}</del>
                    <span
                      v-if="deDiscountInfo.suggestedDiscountPercent.show"
                      :class="discountClassMap"
                      :style="{
                        'transform': isSheinappwordsizeNew ? 'translateY(-2px)' : '',
                        'margin-left': !!suggestedSalePrice.tip ? '2px': ''
                      }"
                    >
                      {{ formatPercentText(deDiscountInfo.suggestedDiscountPercent.value) }}
                    </span>
                  </div>
                </template>
                <span v-else-if="suggestedSalePrice.show">
                  <del
                    class="ProductIntroHeadPrice__head-pricespecialRetail"
                    style="margin-right: 8px"
                  >
                    {{ suggestedSalePrice.value }}
                  </del>
                  <span
                    v-if="!!suggestedSalePrice.tip"
                    class="suggestedSalePrice-tip"
                  >
                    {{ suggestedSalePrice.tip }}
                  </span>
                </span>
                <template v-else-if="hasRetailPrice && price.retailPrice && isSatisfiedOldStyle">
                  <div style="width: 4px;"></div>
                  <del
                    class="del-price"
                    :style="specialFlashDelPriceStyles"
                  >
                    {{ price.retailPrice.amountWithSymbol }}
                  </del>
                </template>
                <div class="ProductIntroHeadPrice__head-pricespecialEstimated">
                  <EstimatedTag 
                    :analysis-data="{ location: 'page' }"
                    :language="language"
                    :estimated-info="getEstimatedInfo"
                    :promotion-info="promotionInfo" 
                    :goods-id="productInfo.goods_id"
                    :is-belt-type="priceBannerTypeData.isPriceBannerTypeShow"
                    :is-floor-type="isFloorType"
                    :is-special-flash="isSpecialFlash"
                    :has-link="true"
                    :is-show-count-down-mill-sec="isShowCountDownMillSec"
                    @click-link="clickEstimatedTag"
                  />
                </div>
              </template>
              <template v-else>
                <template v-if="complianceModeDe">
                  <div
                    v-if="deDiscountInfo.retailPrice.show"
                    class="ProductIntroHeadPrice__de-discount"
                  >
                    <span class="ProductIntroHeadPrice__de-discountText">{{ language.SHEIN_KEY_PC_31021 }}</span>
                    <del class="ProductIntroHeadPrice__de-discountDel">{{ deDiscountInfo.retailPrice.value }}</del>
                    <span
                      v-if="deDiscountInfo.retailDiscountPercent.show"
                      :class="discountClassMap"
                      :style="{
                        'transform': isSheinappwordsizeNew ? 'translateY(-2px)' : ''
                      }"
                    >
                      {{ `${lang != 'il' ? '-' : ''}${deDiscountInfo.retailDiscountPercent.value}%` }}
                    </span>
                  </div>
                  <div
                    v-if="deDiscountInfo.suggestedSalePrice.show"
                    class="ProductIntroHeadPrice__de-discount"
                    :style="{
                      width: deDiscountInfo.retailPrice.show ? '100%' : '',
                      margin: deDiscountInfo.retailPrice.show ? '10px 0' : ''
                    }"
                  >
                    <span class="ProductIntroHeadPrice__de-discountText">{{ language.SHEIN_KEY_PC_31022 }}</span>
                    <del class="ProductIntroHeadPrice__de-discountDel">{{ deDiscountInfo.suggestedSalePrice.value }}</del>
                    <span
                      v-if="deDiscountInfo.suggestedDiscountPercent.show"
                      :class="discountClassMap"
                      :style="{
                        'transform': isSheinappwordsizeNew ? 'translateY(-2px)' : '',
                        'margin-left': !!suggestedSalePrice.tip ? '2px': ''
                      }"
                    >
                      {{ formatPercentText(deDiscountInfo.suggestedDiscountPercent.value) }}
                    </span>
                  </div>
                </template>
                <template v-else>
                  <del
                    v-if="showSpecialFlashDelPrice"
                    class="ProductIntroHeadPrice__head-pricespecialRetail"
                  >
                    {{ specialFlashRetailPrice }}
                  </del>
                  <del
                    v-if="suggestedSalePrice.show && !suggestedSalePrice.tip"
                    class="ProductIntroHeadPrice__head-pricespecialRetail"
                  >
                    {{ suggestedSalePrice.value }}
                  </del>
                  <div
                    v-if="specialFlashDiscountPercent"
                    :style="{ 'margin-left': !!suggestedSalePrice.tip ? '0px' : '' }"
                    class="ProductIntroHeadPrice__head-pricespecialDiscountPercent"
                  >
                    {{ formatPercentText(specialFlashDiscountPercent) }}
                  </div>
                  <span>
                    <del
                      v-if="suggestedSalePrice.show && !!suggestedSalePrice.tip"
                      class="ProductIntroHeadPrice__head-pricespecialRetail"
                      style="margin-left: 4px"
                    >
                      {{ suggestedSalePrice.value }}
                    </del>

                    <span
                      v-if="!!suggestedSalePrice.tip"
                      class="suggestedSalePrice-tip"
                    >
                      {{ suggestedSalePrice.tip }}
                    </span>
                  </span>
                </template>
              </template>
            </div>
            <div
              v-if="!priceBannerTypeData.isPriceBannerTypeShow && !isFloorType"
              class="ProductIntroHeadPrice__head-pricespecialFlash-right"
              @click="handleLinkSpecialFlash"
            >
              <p>
                {{
                  flashType === 2
                    ? language.SHEIN_KEY_PC_23050
                    : language.SHEIN_KEY_PC_23051
                }}
              </p>
              <Icon 
                name="sui_icon_more_right_14px_1" 
                size="14px" 
                :is-rotate="GB_cssRight" 
              />
            </div>
          </div>
          <div
            v-else
            class="ProductIntroHeadPrice__head-mainprice"
            :class="{'ProductIntroHeadPrice__head-mainprice_bigSmall': isSheinappwordsizeNew}"
          >
            <PromotionIcon 
              :show="promotionInfo && promotionInfo.length && !priceBannerTypeData.isPriceBannerTypeShow && !isInversion && !isInversionDe"
              :promotion-info="promotionInfo"
              :normal-flash-style="normalFlashStyle"
              :is-rw="IS_RW"
              :product-info-price="productInfoPrice"
              :price="price"
              :realtime-price-promotion-status="realtimePricePromotionStatus"
            />
            <div
              class="from original"
              :class="{'goods-price__small-size': isSmallStyle}"
              :style="mainPriceStyle"
              tabindex="0"
              :aria-label="salePriceText.adaPrice"
              v-html="mainPriceWordSizeFormater(salePriceText.price)"
            ></div>
            <div style="width: 2px;"></div>
            <PricePopover 
              v-if="pricePopoverInfo && realTimeReady"
              :price-popover-info="pricePopoverInfo"
            />
            <div style="width: 2px;"></div>
            <img 
              v-if="s3ClubPriceInfo"
              class="ProductIntroHeadPrice__s3-tag"
              src="https://img.ltwebstatic.com/images3_ccc/2024/09/06/2d/17256037293379c1b812e0bbcbed140c7dd8a0d490.png"
            />
            <template v-if="complianceModeDe">
              <div
                v-if="deDiscountInfo.retailPrice.show"
                class="ProductIntroHeadPrice__de-discount"
              >
                <span class="ProductIntroHeadPrice__de-discountText">{{ language.SHEIN_KEY_PC_31021 }}</span>
                <del class="ProductIntroHeadPrice__de-discountDel">{{ deDiscountInfo.retailPrice.value }}</del>
                <span
                  v-if="deDiscountInfo.retailDiscountPercent.show"
                  :class="discountClassMap"
                  :style="{
                    'transform': isSheinappwordsizeNew ? 'translateY(-2px)' : ''
                  }"
                >
                  {{ formatPercentText(deDiscountInfo.retailDiscountPercent.value) }}
                </span>
              </div>
              <div
                v-if="deDiscountInfo.suggestedSalePrice.show"
                class="ProductIntroHeadPrice__de-discount"
                :style="{
                  width: deDiscountInfo.retailPrice.show ? '100%' : '',
                  margin: deDiscountInfo.retailPrice.show ? '10px 0' : ''
                }"
              >
                <span class="ProductIntroHeadPrice__de-discountText">{{ language.SHEIN_KEY_PC_31022 }}</span>
                <del class="ProductIntroHeadPrice__de-discountDel">{{ deDiscountInfo.suggestedSalePrice.value }}</del>
                <span
                  v-if="deDiscountInfo.suggestedDiscountPercent.show"
                  :class="discountClassMap"
                  :style="{
                    'transform': isSheinappwordsizeNew ? 'translateY(-2px)' : '',
                    'margin-left': !!suggestedSalePrice.tip ? '2px': ''
                  }"
                >
                  {{ formatPercentText(deDiscountInfo.suggestedDiscountPercent.value) }}
                </span>
              </div>
            </template>
            <template v-else-if="!hasEstimated || (hasEstimated && showEstimatedNoThresholdNew)">
              <template v-if="suggestedSalePrice.show && !suggestedSalePrice.tip">
                <div style="width: 4px;"></div>
                <del
                  class="del-price"
                  :style="specialFlashDelPriceStyles"
                >
                  {{ suggestedSalePrice.value }}
                </del>
              </template>
              <template v-if="((hasRetailPrice && price.retailPrice) || (sheinClubPromotionInfo && isPaidUser)) && !complianceMode">
                <div style="width: 4px;"></div>
                <del
                  class="del-price"
                  :style="specialFlashDelPriceStyles"
                >
                  {{ price.retailPrice.amountWithSymbol }}
                </del>
              </template>
              <!-- 闪购标签（默认分支） / 折扣标签 -->
              <span
                v-if="discountShowed && discountPercent"
                :class="discountClassMap"
                :style="{
                  'transform': isSheinappwordsizeNew ? 'translateY(-2px)' : '',
                  'margin-left': !!suggestedSalePrice.tip ? '2px': ''
                }"
              >
                {{ formatPercentText(discountPercent) }}
              </span>
              <span>
                <template v-if="suggestedSalePrice.show && !!suggestedSalePrice.tip">
                  <div style="width: 4px;display: inline-block;"></div>
                  <del
                    class="del-price"
                    :style="specialFlashDelPriceStyles"
                    style="margin-left: 4px"
                  >
                    {{ suggestedSalePrice.value }}
                  </del>
                </template>
                <span
                  v-if="!!suggestedSalePrice.tip"
                  class="suggestedSalePrice-tip"
                >
                  {{ suggestedSalePrice.tip }}
                </span>
              </span>
            </template>
          </div>

          <template v-if="recommendRetailPrice && !hasEstimated">
            <RecommendRetailPrice
              :recommend-retail-price="recommendRetailPrice"
              :is-floor-type="isFloorType"
            />
            <div style="width: 4px;"></div>
          </template>

          <!-- 单品直降活动倒计时（非到手价，非闪购） -->
          <template v-if="isShowPromotionCountDown">
            <PromotionCountDown
              style="height: 28.8px; align-self: flex-start;"
              :end-time="promotionEndTime"
              :language="language"
              :is-show-count-down-mill-sec="isShowCountDownMillSec"
            />
          </template>

          <template v-if="hasEstimated && isShowCountdown && isSpecialFlash">
            <span>
              <template v-if="suggestedSalePrice.show && !!suggestedSalePrice.tip">
                <div style="width: 4px;display: inline-block;"></div>
                <del
                  class="del-price"
                  :style="specialFlashDelPriceStyles"
                  style="margin-left: 4px"
                >
                  {{ suggestedSalePrice.value }}
                </del>
              </template>
              <span
                v-if="!!suggestedSalePrice.tip"
                class="suggestedSalePrice-tip"
              >
                {{ suggestedSalePrice.tip }}
              </span>
              <template v-else-if="hasRetailPrice && price.retailPrice && isSatisfiedOldStyle">
                <div style="width: 4px;display: inline-block;"></div>
                <del
                  class="del-price"
                  :style="specialFlashDelPriceStyles"
                >
                  {{ price.retailPrice.amountWithSymbol }}
                </del>
              </template>
            </span>

            <template v-if="recommendRetailPrice">
              <RecommendRetailPrice
                :recommend-retail-price="recommendRetailPrice"
                :is-floor-type="isFloorType"
              />
              <div style="width: 4px;"></div>
            </template>
            <div class="ProductIntroHeadPrice__head-pricespecialEstimatedout">
              <EstimatedTag
                :analysis-data="{ location: 'page' }"
                :language="language"
                :estimated-info="getEstimatedInfo"
                :promotion-info="promotionInfo" 
                :goods-id="productInfo.goods_id"
                :is-belt-type="priceBannerTypeData.isPriceBannerTypeShow"
                :is-floor-type="isFloorType"
                :is-special-flash="isSpecialFlash"
                :has-link="true"
                :is-show-count-down-mill-sec="isShowCountDownMillSec"
                @click-link="clickEstimatedTag"
              />
            </div>
          </template>
          <template v-if="!isSpecialFlash && hasEstimated && !showEstimatedNoThresholdNew">
            <span
              v-if="discountPercent && complianceTipsMode"
              :class="discountClassMap"
              :style="{
                'transform': isSheinappwordsizeNew ? 'translateY(-2px)' : '',
                'margin-left': !!suggestedSalePrice.tip ? '2px': ''
              }"
            >
              {{ formatPercentText(discountPercent) }}
            </span>
            <span>
              <template v-if="suggestedSalePrice.show">
                <div style="width: 4px;display: inline-block"></div>
                <del
                  class="del-price"
                  :style="specialFlashDelPriceStyles"
                >
                  {{ suggestedSalePrice.value }}
                </del>
              </template>
              <span
                v-if="!!suggestedSalePrice.tip"
                class="suggestedSalePrice-tip"
                style="margin-right: 8px"
              >
                {{ suggestedSalePrice.tip }}
              </span>
              <template v-else-if="hasRetailPrice && price.retailPrice && isSatisfiedOldStyle">
                <div style="width: 4px;display: inline-block;"></div>
                <del
                
                  class="del-price"
                  :style="specialFlashDelPriceStyles"
                >
                  {{ price.retailPrice.amountWithSymbol }}
                </del>
              </template>
            </span>
            <template v-if="recommendRetailPrice">
              <RecommendRetailPrice
                :recommend-retail-price="recommendRetailPrice"
                :is-floor-type="isFloorType"
              />
              <div style="width: 4px;"></div>
            </template>
            <div class="ProductIntroHeadPrice__head-priceEstimated">
              <EstimatedTag
                :analysis-data="{ location: 'page' }"
                :language="language"
                :estimated-info="getEstimatedInfo"
                :promotion-info="promotionInfo" 
                :goods-id="productInfo.goods_id"
                :is-belt-type="priceBannerTypeData.isPriceBannerTypeShow"
                :is-floor-type="isFloorType"
                :is-special-flash="isSpecialFlash"
                :is-belt-type-brand="isBrandFlash"
                :has-link="true"
                :is-show-count-down-mill-sec="isShowCountDownMillSec"
                @click-link="clickEstimatedTag"
              />
            </div>
          </template>
        </div>
        <div 
          v-if="isPercentNewStyle"
          class="percent-newstyle"
        >
          <!-- 新型闪购新样式 -->
          <PromotionIcon 
            :show="promotionInfo && promotionInfo.length && !priceBannerTypeData.isPriceBannerTypeShow && !isInversion"
            :promotion-info="promotionInfo"
            :normal-flash-style="normalFlashStyle"
            :is-rw="IS_RW"
            :product-info-price="productInfoPrice"
            :price="price"
            :realtime-price-promotion-status="realtimePricePromotionStatus"
          />
          <div
            v-if="isSpecialFlash"
            :class="[{'percent-newstyle__pricespecial-flash': !priceBannerTypeData.isPriceBannerTypeShow}]"
          >
            <div
              v-if="!priceBannerTypeData.isPriceBannerTypeShow"
              class="percent-newstyle__head-pricespecialFlash-top"
              @click="handleLinkSpecialFlash"
            >
              <p>
                {{
                  flashType === 2
                    ? language.SHEIN_KEY_PC_23050
                    : language.SHEIN_KEY_PC_23051
                }}
              </p>
              <Icon 
                name="sui_icon_more_right_14px_1" 
                size="14px" 
                :is-rotate="GB_cssRight" 
              />
            </div>
            <EstimatedTag
              :analysis-data="{ location: 'page' }"
              :language="language"
              :estimated-info="getEstimatedInfo"
              :promotion-info="promotionInfo" 
              :goods-id="productInfo.goods_id"
              :is-special-flash="isSpecialFlash"
              :is-show-from="showFrom"
              :is-belt-type="priceBannerTypeData.isPriceBannerTypeShow"
              :is-floor-type="isFloorType"
              :is-belt-type-brand="isBrandFlash"
              :has-link="true"
              :is-show-count-down-mill-sec="isShowCountDownMillSec"
              @click-link="clickEstimatedTag"
            />
          </div>
          <EstimatedTag
            v-else
            :analysis-data="{ location: 'page' }"
            :language="language"
            :estimated-info="getEstimatedInfo"
            :promotion-info="promotionInfo" 
            :goods-id="productInfo.goods_id"
            :is-show-from="showFrom"
            :is-belt-type="priceBannerTypeData.isPriceBannerTypeShow"
            :is-belt-type-brand="isBrandFlash"
            :is-floor-type="isFloorType"
            :has-link="true"
            :is-show-count-down-mill-sec="isShowCountDownMillSec"
            @click-link="clickEstimatedTag"
          />
        </div>
      </div>
    </div>
    <!-- 价格下方的统一提示 -->
    <ProductIntroPriceTips
      v-if="priceBannerTypeData.isPriceBannerTypeShow && showPriceTips" 
      :price-tips-config="priceTipsConfig"
    />
    <!-- 凑单弹窗 -->
    <AddOnDialog
      v-model:visible="addMoreDialogVisible"
      :query-params="preParams"
      :sa-info="saInfo"
      :config="{
        isClickToDetail: true
      }"
      :coupon="optimalCouponCode"
      type="coupon"
    />
  </div>
</template>

<script>
import 'public/src/icon/flash_sale_black.svg'
import 'public/src/icon/apponly.svg'
import 'public/src/icon/limitedoff.svg'
import 'public/src/icon/head-help.svg'
import 'public/src/icon/gd-more.svg'
import 'public/src/icon/arrow-down.svg'
import { Icon } from '@shein-aidc/icon-vue3'
import ProductIntroPriceTips from '../ProductIntroPriceTips'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { template, CountDown } from '@shein/common-function'
import { expose } from 'public/src/pages/common/analysis/directive.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import schttp from 'public/src/services/schttp'
import EstimatedTag from 'public/src/pages/goods_detail_v2/components/EstimatedTag/index.vue'
import salePriceWordSize from 'public/src/pages/goods_detail_v2/utils/salePriceWordSize'
import PromotionIcon from 'public/src/pages/goods_detail_v2/components/ProductIntroHeadPrice/UI/PromotionIcon.vue'
import { parsePercentText } from 'public/src/pages/goods_detail_v2/utils/ar.js'
import { defineAsyncComponent } from 'vue'
import getQuickView from '../../utils/getQuickViewPopover'
import { markPoint } from 'public/src/services/mark'
import RecommendRetailPrice from './UI/RecommendRetailPrice'
import PricePopover from './UI/PricePopover.vue'
import PromotionCountDown from './UI/PromotionCountDown.vue'

daEventCenter.addSubscriber({ modulecode: '1-8-6' })

export default {
  name: 'ProductIntroHeadPrice',
  directives: { expose },
  components: {
    AddOnDialog: defineAsyncComponent(() => import('public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog.vue')),
    Icon,
    EstimatedTag,
    ProductIntroPriceTips,
    PromotionIcon,
    RecommendRetailPrice,
    PricePopover,
    PromotionCountDown
  },
  props: {
    isFloorType: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      addMoreDialogVisible: false,
      discountShowed: false,
      priceBannerTypeNormalFlashData: {
        showCountTime: false,
        flashTimer: null,
        secondInterval: null,
        countdownStr: {
          D: '',
          H: '',
          M: '',
          S: ''
        },
        secondHundred: 9,
      },
      priceReportList: []
    }
  },
  computed: {
    ...mapState([
      'productIntroData',
      'skuInfo',
      'language',
      'realtimePricePromotionStatus',
      'langPath',
      'lang',
      'IS_RW',
      'GB_cssRight',
      'SiteUID',
      'brandDetail',
      'quickShip',
      'realTimeReady'
    ]),
    ...mapGetters([
      'optimalCoupon',
      'priceDetect',
      'promotionInfo',
      'mallCode',
      'sheinClubPromotionInfo',
      'screenAbtConfig',
      'retentionPositionConfig',
      'isShowFollowBelt',
      'getEstimatedInfo',
      'bestDealConfig',
      'taxIncludedText',
      'flashGoods',
      'flashType',
      'isSpecialFlash',
      'complianceMode',
      'complianceModeDe',
      'isHitComplianceMode',
      'complianceTipsMode',
      'isInversion',
      'newFlashPromotion',
      'suggestedSalePrice',
      'deDiscountInfo',
      'isInversionDe',
      'isPaidUser',
      's3ClubPriceInfo',
      'pspcPriceInfo',
      'recommendRetailPrice',
      'isShowPromotionCountDown',
      'promotionEndTime',
      'isShowCountDownMillSec'
    ]),
    preParams() {
      const { goods_id, cat_id } = this.productIntroData?.detail
      const isTopGoods = this.screenAbtConfig?.Gatheringorders?.p?.Gatheringorders === 'Topping'
      const isOpenRecommend = this.screenAbtConfig?.EstimatedNothreShowType?.p?.recommendtype == '1'
      let params = {
        sceneId: 152, // 凑单列表值
        addOnType: 2,
        cateIds: [cat_id] // 类目ID

      }
      if(isTopGoods) {
        params.adp = [goods_id]
      }else{
        params.goodsIds = [goods_id] // 商品ID
      }
      if(isOpenRecommend) {
        // 基于商详商品推出凑单商品
        params.mainGoodsIds = [goods_id]
      }
      return params
    },
    optimalCouponCode(){
      return this.optimalCoupon?.coupon_code
    },
    saInfo() {
      return {
        promotion_code: this.optimalCouponCode,
        state: 'goods_detail_coupon_add',
        activity_from: 'goods_detail_coupon_add',
        abtest: 'addnewhotJson'
      }
    },
    isShowDiscountPriceStyle () {
      // 保留款 + 跟价款腰带 展示折扣样式
      const isRetention = this.retentionPositionConfig?.show || false // 保留款
      const { isShowFollowBelt } = this // 跟价款
      return isRetention || isShowFollowBelt
    },
    cccFlashZoneData () {
      return this.productIntroData?.cccFlashZoneData || {}
    },
    flashSaleDiff() {
      return !this.IS_RW
    },
    productInfo() {
      return this.productIntroData.detail
    },
    productInfoPrice() {
      const { flashType, newFlashPromotion } = this
      let mallPrice = this.productInfo.mall[this.mallCode] || {}
      if (flashType == 4 && newFlashPromotion?.price?.amountWithSymbol) {
        mallPrice = {
          ...mallPrice,
          salePrice: newFlashPromotion?.price || {},
          discountPrice: newFlashPromotion?.discount_value || {},
          unit_discount: newFlashPromotion?.discount_percent || '',
        }
      }
      return mallPrice
    },
    price() {
      let result
      if (this.skuInfo && this.skuInfo.mall) {
        result = this.skuInfo.mall[this.mallCode]
      } else {
        result = this.productInfoPrice
      }
      let isCloseEstimatedPriceWithPromo32 = this.productIntroData.closeEstimatedPrice && this.promotionInfo?.find?.(item => item.typeId == 32)
      return {
        ...result,
        ...( isCloseEstimatedPriceWithPromo32 ? { salePrice: result.retailPrice, unit_discount: 0 } : {})
      }
    },
    showDiscount() {
      // if(this.showFrom) return false
      if (this.isInversion || this.isInversionDe) return false
      if (this.isSpecialFlash) return false
      return ((this.hasRetailPrice || this.suggestedSalePrice.show) && !this.isFlashGoods) ||
        (!this.flashSaleDiff && this.isFlashGoods && !this.showFrom) ||
        (this.priceBannerTypeData.isPriceBannerTypeShow && this.showFlashSaleDiffData.isShow) ||
        (this.isFloorType && this.showFlashSaleDiffData.isShow)
    },
    discountPercent() {
      if (this.s3ClubPriceInfo) return this.s3ClubPriceInfo?.retail_discount_value
      if (this.isPaidUser && this.sheinClubPromotionInfo) return this.price.discountValue || this.price.unit_discount
      return this.price.unit_discount
    },
    flashGoods() {
      if (this.isInversion || this.isInversionDe) return {}
      return this.promotionInfo.find(item => item.typeId == 10)
    },
    flashType() {
      return this.flashGoods?.flashType
    },
    normalFlashStyle() {
      const { flashType, newFlashPromotion } = this
      return [1, 5, 6].includes(flashType) || (flashType == 4 && newFlashPromotion)
    },
    showFlashCountBelt () {
      return this.normalFlashStyle && !this.priceBannerTypeData.isPriceBannerTypeAbtNew
    },
    // 是否为普通闪购商品
    isFlashGoods() {
      return !!this.flashGoods && this.normalFlashStyle
    },
    isBrandFlash() {
      return this.flashType == 6
    },
    newFlashPrice() {
      return this.newFlashPromotion?.price?.amountWithSymbol || ''
    },
    // 是否有零售价
    hasRetailPrice() {
      if (this.complianceMode || this.complianceModeDe) return false
      const { flashType, newFlashPromotion } = this
      return (
        this.price?.salePrice?.amountWithSymbol !== this.price?.retailPrice?.amountWithSymbol
        || (flashType == 4 && newFlashPromotion?.price?.amountWithSymbol)
        || (this.s3ClubPriceInfo && this.price?.showPrice?.amountWithSymbol !== this.price?.retailPrice?.amountWithSymbol)
      )
    },
    showFrom() {
      // 同一个skc下有多个sku价格并且没有选中尺码时展示from
      return this.priceDetect && !this.skuInfo.sku_code
    },
    salePriceText() {
      const price = this.s3ClubPriceInfo ? 
        this.s3ClubPriceInfo?.show_price?.amount_text : // 新增主价格类型 - S3会员
        ((this.isPaidUser && this.sheinClubPromotionInfo) ? this.sheinClubPromotionInfo.price.amountWithSymbol : this.salePrice)
      if (this.showFrom) {
        let fromText = this.globalTempalte('', this.language.SHEIN_KEY_PC_17875)
        let spanFromText = !this.isSheinappwordsizeNew ? `<span style="font-size: 16px;font-weight: normal;margin-right: 2px;">${fromText}</span>` : fromText
        return {
          price: `${spanFromText}${price}`,
          adaPrice: this.language.SHEIN_KEY_PC_17875 + ' ' + price
        }
      }
      return {
        price: `<span>${price}</span>`,
        adaPrice: price
      }
    },
    retailPriceText() {
      return this.hasRetailPrice
        ? this.price?.retailPrice.amountWithSymbol
        : ''
    },
    salePrice() {
      return this.price?.salePrice?.amountWithSymbol
    },
    showSpecialFlashDelPrice () {
      if (this.complianceMode) return null
      return this.price?.retailPrice.amount != this.newFlashPromotion?.price?.amount
    },
    specialFlashDelPriceStyles() {
      let result = {
        color: this.isBrandFlash ? '#666' : '#999'
      }
      return result
    },
    specialFlashRetailPrice () {
      return this.price?.retailPrice.amountWithSymbol
    },
    specialFlashDiscountPercent () {
      return this.newFlashPromotion?.discount_percent
    },
    isShowSheinX() {
      return !!this.productIntroData?.designerInfo?.designer_name
    }, 
    isNewDiscountLabel() {
      if (this.isFlashGoods || this.IS_RW) return false
      return this.screenAbtConfig?.discountLabel?.param?.discountLabel == 'Label'
    },
    isEstimatedMatch () {
      let { EstimatedPrice } = this.screenAbtConfig || {}
      let E_Price_Cal = EstimatedPrice?.p?.E_Price_Cal
      return ['A', 'B', 'C', 'D', 'Non_Threshold', 'Have_Threshold'].includes(E_Price_Cal)
    },
    estimatedCompProps() {
      return this.getEstimatedInfo?.estimatedCompProps || null
    },
    isPercentNewStyle(){
      return this.getEstimatedInfo?.value && this.getEstimatedInfo?.discountPercent
    },
    hasEstimated() {
      return !!this.estimatedCompProps && !!this.estimatedCompProps?.config
    },
    isShowCountdown(){
      return this.estimatedCompProps?.config?.isShowCountdown
    },
    priceBannerTypeData() {
      let isPriceBannerTypeAbtNew = !this.IS_RW
      let isPriceBannerTypeShow = false
      isPriceBannerTypeShow = isPriceBannerTypeAbtNew && !!(this.normalFlashStyle || this.isSpecialFlash) && !this.isFloorType
      isPriceBannerTypeShow = isPriceBannerTypeAbtNew && !!(this.normalFlashStyle || this.isSpecialFlash) && !this.isFloorType
      const { language = {}, flashType = {}, isSpecialFlash, normalFlashStyle } = this
      let title = ''
      if (isSpecialFlash) {
        let specialFlashTitleMap = {
          '2': language.SHEIN_KEY_PC_23050,
          '3': language.SHEIN_KEY_PC_23051
        }
        title = specialFlashTitleMap[flashType]
      }
      if (!isSpecialFlash && normalFlashStyle) {
        title = language.SHEIN_KEY_PC_15950
        if (flashType == 1) {
          title = language.SHEIN_KEY_PC_15950 // 常规闪购
        } else if ([4, 5].includes(Number(flashType))) {
          const { titleKey } = this.cccFlashZoneData || {} 
          title = language[titleKey] || language?.SHEIN_KEY_PC_27483 || language?.SHEIN_KEY_PC_15950 // 专区兜底文案
        } else if (this.isBrandFlash) {
          title = language.SHEIN_KEY_PC_29165
        }
      }

      let showRightIcon = false
      if (this.normalFlashStyle && !this.isBrandFlash) showRightIcon = true
      // if (this.isBrandFlash && this.screenAbtConfig.brandskip?.p == 'Jumpable') showRightIcon = true

      return {
        isPriceBannerTypeAbtNew,
        isPriceBannerTypeShow,
        title,
        showRightIcon
      }
    },
    showFlashSaleDiffData() {
      let isShow = false
      let {
        flashSaleDiff,
        isFlashGoods,
        isSpecialFlash,
        hasEstimated,
        showEstimatedNoThresholdNew
      } = this
      isShow = flashSaleDiff && isFlashGoods && !isSpecialFlash
      if (hasEstimated) {
        isShow = showEstimatedNoThresholdNew ? isShow : false
      }
      return {
        isShow,
        text: this.globalTempalte(
          this.productInfoPrice?.discountPrice?.amountWithSymbol,
          this.language.SHEIN_KEY_PC_17461
        ),
        discount: parsePercentText(`${this.lang != 'il' ? '-' : ''}${this.price.unit_discount}%`)
      }
    },
    bestDealTipsAnalysis() {
      const { mallCode, bestDealConfig, price, productIntroData, quickShip } = this
      const { retailPrice, salePrice } = price || {}
      const { tipsType } = bestDealConfig || {}
      const { goods_id, goods_sn, productRelationID } = productIntroData.detail || {}
      const goods_list = `${goods_id}\`${goods_sn}\`${productRelationID}\`1\`1\`1\`\`\`pri_${salePrice?.usdAmount}|pri_${retailPrice?.usdAmount}\`${quickShip == 1 ? 'quickship_1' : ''}\`\`mall_${mallCode}\``
      return {
        pricestatus: tipsType,
        location: 'detail',
        goods_list
      }
    },
    priceTipsConfig() {
      const { text, type } = this.bestDealConfig
      const showBestDeal = text && !!~type.indexOf('Text')
      return {
        best_deal: {
          show: showBestDeal,
          content: text,
          analysis: this.bestDealTipsAnalysis
        }
      }
    },
    pricePopoverInfo() {
      const { taxIncludedText, recommendRetailPrice, isFloorType } = this
      let taxTip = taxIncludedText
      let deSspTip = ''
      let sspTip = ''
      let rrpTip = ''
      if (this.deDiscountInfo?.suggestedSalePrice?.show && this.complianceModeDe) deSspTip = template(this.deDiscountInfo?.suggestedSalePrice.value, this.language.SHEIN_KEY_PC_31023)
      if (this.suggestedSalePrice.show && this.complianceTipsMode) sspTip = this.language.SHEIN_KEY_PC_32912 || 'The crossed-out price is the lowest price of the product on pl.shein.com within the 30 days prior to the price reduction.'
      if (recommendRetailPrice) rrpTip = this.language.SHEIN_KEY_PC_32911 || 'The recommended retail price (RRP) refers to the price that is set by the manufacturer and provided by a manufacturer, supplier or seller. The display here represents a price comparison between the RRP and our current selling price.'
      if (!taxTip && !deSspTip && !sspTip && !rrpTip) return null
      return {
        isFloorType,
        taxTip,
        deSspTip,
        sspTip,
        rrpTip,
        skuCode: this.skuInfo.sku_code
      }
    },
    showPriceTips() {
      const { best_deal } = this.priceTipsConfig
      return best_deal.show
    },
    showEstimatedNoThresholdNew() {
      let { EstimatedNothreShowType, isSatisfied, isSatisfiedBuyMultiple } = this.getEstimatedInfo || {}
      return ['New1', 'New2'].includes(EstimatedNothreShowType) && !isSatisfied && !isSatisfiedBuyMultiple
    },
    isSmallStyle(){
      // 当middleast=new，且属于 满足门槛到手价+老样式的时候，主价格缩小，到手价放大且改为驼峰样式
      const isMiddleastNew = this.screenAbtConfig?.middleast?.p?.middleast === 'new'
      return isMiddleastNew && this.isSatisfiedOldStyle
    },
    isSatisfiedOldStyle(){ // 满足门槛到手价+老样式+非合规
      let { isSatisfied } = this.getEstimatedInfo || {}
      const showEstimatedTagOld = !this.showEstimatedNoThresholdNew && !(['New1', 'New2'].includes(this.estimatedCompProps?.config?.EstimatedNothreShowType) && !!this.estimatedCompProps?.price?.amountWithSymbol) && !this.estimatedCompProps?.price?.discountPercent
      return this.hasEstimated && isSatisfied && showEstimatedTagOld && !this.isHitComplianceMode
    },
    isSheinappwordsizeNew() {
      return this.screenAbtConfig?.sheinappwordsize?.p?.sheinappwordsize === 'newprice' && !this.isSmallStyle
    },
    isFlashSaleCountDownAbtHide() {
      return this.screenAbtConfig?.FlashSaleCountDown?.p?.FlashSaleCountDown === 'Hide'
    },
    mainPriceStyle() {
      let { isSheinappwordsizeNew, hasRetailPrice, isShowDiscountPriceStyle, isBrandFlash, isFloorType, isFlashGoods, sheinClubPromotionInfo, isPaidUser, priceBannerTypeData, suggestedSalePrice, isInversion, isInversionDe, deDiscountInfo, complianceModeDe, s3ClubPriceInfo } = this
      let result = {
        'line-height': isSheinappwordsizeNew ? '1' : '1.2'
      }
      let discountColor = this.IS_RW ? '#fc4070' : '#fa6338'
      if (isBrandFlash) {
        result.color = discountColor
      }
      if (isShowDiscountPriceStyle) {
        result.color = discountColor
      }
      if (hasRetailPrice || suggestedSalePrice.show) {
        result.color = discountColor
      }
      if (complianceModeDe && (deDiscountInfo.retailDiscountPercent.show || deDiscountInfo.suggestedDiscountPercent.show)) {
        result.color = discountColor
      }
      if (isInversion || isInversionDe) result.color = '#000'

      if (priceBannerTypeData.isPriceBannerTypeShow && !isBrandFlash) {
        result.color = '#000'
      }
      if (isFloorType) {
        if (isFlashGoods) {
          result.color = '#000'
          if (isBrandFlash) {
            result.color = discountColor
          }
        }
      }
      if (sheinClubPromotionInfo && isPaidUser) {
        result.color = '#873C00'
      }
      if (s3ClubPriceInfo) {
        result.color = '#806208'
      }
      return result
    },
    discountClassMap() {
      let {
        isFlashGoods,
        priceBannerTypeData,
        isNewDiscountLabel,
      } = this
      return {
        'flashsale-label': isFlashGoods && !priceBannerTypeData.isPriceBannerTypeShow,
        'discount-label': !isFlashGoods,
        'new-discount-label': isNewDiscountLabel,
        'flashsale-label-newbanner': isFlashGoods,
      }
    }
  },
  watch: {
    'productInfo.goods_id'(val){
      if(val){
        setTimeout(() => {
          this.estimatedPriceReport(val)
        }, 3000)
        this.priceReportList = []
      }
    },
    'skuInfo.sku_code'(){
      this.estimatedPriceReport()
    },
    showDiscount: {
      handler(val) {
        this.discountShowed = !!val
      },
      immediate: true
    },
    normalFlashStyle: {
      handler(val) {
        if (typeof window !== 'undefined' && val) {
          this.initPriceBannerNormalFlashCountDown()
        }
      },
      immediate: true,
    },
    isSpecialFlash: {
      handler(val) {
        if (typeof window !== 'undefined' && val) {
          this.triggerExposeSpecialFlash()
        }
      },
    },
    'suggestedSalePrice.show': {
      handler(val) {
        if (val) daEventCenter.triggerNotice({ daId: '1-8-6-224' })
      },
      immediate: true,
    },
    'deDiscountInfo.suggestedSalePrice.show': {
      handler(val) {
        if (val) daEventCenter.triggerNotice({ daId: '1-8-6-224' })
      },
      immediate: true,
    }
  },
  async mounted() {
    this.triggerExposeNormalFlash()
    this.$nextTick(() => {
      markPoint({ eventName: 'realTimeNextTick', measureFrom: 'appMounted' })
      setTimeout(() => {
        this.estimatedPriceReport(this.productInfo.goods_id)
      }, 3000)
    })
  },
  methods: {
    ...mapMutations(['assignState']),
    ...mapActions(['getRealtimePrice', 'getProductBadges', 'getCCCDescConfig', 'fetchCouponList', 'updateEstimatedCouponList', 'showLoginModal']),
    globalTempalte: template,
    estimatedPriceReport(goods_id) {
      // 需要上报染色埋点
      const skc_sku = (goods_id || this.productInfo.goods_id) + '_' + (this.skuInfo.sku_code || '')
      console.log('===>detail.estimatedPriceReport.skc_sku', skc_sku)
      if(this.priceReportList.includes(skc_sku)){
        console.log(this.priceReportList, '===>detail.estimatedPriceReport.已经上报过一次了', skc_sku)
        return
      }
      this.priceReportList.push(skc_sku)
      console.log('===>detail.estimatedPriceReport.priceReportList', this.priceReportList)

      const buriedPrice = this.getEstimatedInfo?.buriedPrice || null
      console.log('===>detail.estimatedPriceReport.buriedPrice', buriedPrice)
      if(!buriedPrice){
        return
      }
      const param = {
        daId: '1-8-6-266', // 凑单到手价上报染色埋点showaddprice
        extraData: {
          estimated_price: buriedPrice?.price?.usdAmountWithSymbol || '0',
          sku_code: buriedPrice?.skuCode || '',
          location: 0
        }
      }
      if(buriedPrice?.type == 2){  // N件到手价上报染色埋点shownprice
        param.daId = '1-8-6-267'
        param.extraData.estimated_price_num = buriedPrice?.purchasePcs || 'n'
      }
      daEventCenter.triggerNotice(param)
    },
    clickEstimatedTag() {
      const clickjumpath = this.screenAbtConfig?.collestrategy?.p?.clickjumpath || ''
      let { isSatisfied } = this.getEstimatedInfo || {}

      if (this.getEstimatedInfo?.isSatisfiedBuyMultiple) {
        if (this.screenAbtConfig.Nestprice?.p?.Nestprice === 'addcartpopup') {
          let params = {
            goodsId: this.productInfo.goods_id,
            mainGoodsId: this.productInfo.goods_id,
            extendForm: {
              quantity: this.getEstimatedInfo.buyMultiplePic
            },
            config: {
              addSource: 'DetailPage',
              showBestDealLabel: true,
              showFollowBeltByOrigin: true,
              showEstimatedPrice: true,
              showNoSatisfied: true,
              reportData: {},
              forbidAtmosphereFlow: false,
            },
            'callback': {}
          }
          getQuickView().then((QuickViewPopover) => {
            QuickViewPopover.show(params)
          })

          return
        } else {
          return this.assignState({ viewPromotion: true })
        }
      }
     
      if (clickjumpath !== 'gatherpopup' || isSatisfied) {
        this.assignState({ viewPromotion: true })
        return
      }
      this.addMoreDialogVisible = true
    },
    handleLinkSpecialFlash () {
      window.open(`${this.langPath}/flash-sale.html?ici=GoodsDetail_FlashSale&flash_type=${this.flashType}`)
    },
    async initPriceBannerNormalFlashCountDown() {
      if (this.isFlashSaleCountDownAbtHide) return
      if (!this.priceBannerTypeData.isPriceBannerTypeAbtNew) return
      const { currTime: serverTime } = await schttp({ url: '/api/productInfo/currentTime/get' })
      if (this.flashGoods?.endTimestamp && this.flashGoods?.endTimestamp < serverTime ) return
      this.priceBannerTypeNormalFlashData.flashTimer = new CountDown({
        countFunc: (timeObj) => {
          const { D } = timeObj || {}
          // 倒计时小于72小时 = 3 天 时展示倒计时文案
          let minDay = this.isBrandFlash ? 1 : 3
          if (Number(D) < minDay && this.priceBannerTypeNormalFlashData.showCountTime === false) {
            this.priceBannerTypeNormalFlashData.showCountTime = true
            // 额外百毫秒展示
            this.priceBannerTypeNormalFlashData.secondInterval = setInterval(() => {
              this.priceBannerTypeNormalFlashData.secondHundred --
              if (this.priceBannerTypeNormalFlashData.secondHundred < 0) this.priceBannerTypeNormalFlashData.secondHundred = 9
            }, 100)
          }
          if (this.priceBannerTypeNormalFlashData.showCountTime) {
            Object.assign(this.priceBannerTypeNormalFlashData.countdownStr, timeObj)
          }
        },
        endFunc: () => {
          this.priceBannerTypeNormalFlashData.showCountTime = false
          this.priceBannerTypeNormalFlashData.showFlashBelt = false
          this.priceBannerTypeNormalFlashData.flashTimer && this.priceBannerTypeNormalFlashData.flashTimer?.clear()
          this.priceBannerTypeNormalFlashData.secondInterval && clearInterval(this.priceBannerTypeNormalFlashData.secondInterval)
        }
      })
      this.priceBannerTypeNormalFlashData.flashTimer.start({
        dayMode: true,
        seconds: this.flashGoods?.endTimestamp - serverTime, // 单位是秒
      })
    },
    handlePriceBannerTypeHead() {
      if (this.normalFlashStyle) {
        if (this.isBrandFlash) {
          // daEventCenter.triggerNotice({
          //   daId: '1-8-6-201',
          //   extraData: {
          //     location: 'page'
          //   }
          // })
          return
        } else {
          daEventCenter.triggerNotice({
            daId: '1-8-6-46',
            extraData: {
              flash_location: 2
            }
          })
          window.open(`${this.langPath}/flash-sale.html?ici=GoodsDetail_FlashSale&flash_type=${this.flashType}`)
        }
      } else {
        this.handleLinkSpecialFlash()
      }
    },
    triggerExposeSpecialFlash() {
      let daId = this.flashType == 2 ? '1-8-6-102' : '1-8-6-101'
      let exclusvieshowtype = this.priceBannerTypeData.isPriceBannerTypeAbtNew ? 3 : 2
      daEventCenter.triggerNotice({
        daId,
        extraData: {
          exclusvieshowtype
        }
      })
    },
    // 价格大小实验
    mainPriceWordSizeFormater(priceStr) {
      return salePriceWordSize({
        priceStr,
        gate: this.isSheinappwordsizeNew
      })
    },
    triggerExposeNormalFlash() {
      if (!this.priceBannerTypeData.isPriceBannerTypeAbtNew) return
      if (!this.normalFlashStyle) return
      if (this.isBrandFlash) {
        daEventCenter.triggerNotice({
          daId: '1-8-6-200',
          extraData: {
            location: 'page'
          }
        })
      } else {
        daEventCenter.triggerNotice({
          daId: '1-8-6-45',
          extraData: {
            flash_location: 2
          }
        })
      }
    },
    formatPercentText(num) {
      return parsePercentText(`${this.lang != 'il' ? '-' : ''}${num}%`)
    }
  }
}
</script>

<style lang="less">
.ProductIntroHeadPrice {
  &__head-mainprice {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-right: 6px;
    line-height: 1.2;
    .inclusive-tip {
      font-size: 12px;
      line-height: 14px;
      vertical-align: middle;
      color: @sui_color_gray_dark2;
      margin-right: 4px;
    }
    .from {
      .flexbox();
      .align-center();
      font-size: 24px;
      color: inherit;
      font-family: "Arial";
      font-weight: normal;
      white-space: nowrap;
      span {
        color: inherit;
        font-family: "Arial";
        font-weight: 700;
        &.ltr {
          margin-left: 5px;
        }
      }
      &.special {
        span {
          font-size: 20px;
        }
      }
    }

    .del-price {
      color: #666;
      vertical-align: middle;
      margin-right: 4px;
    }
  }
  .flashsale-label-newbanner {
    background-color: #FA6338;
    color: #fff;
    padding: 2px 3px;
    border-radius: 2px;
    /* rw:begin*/
    padding: 0 3px;
  }
  &__head-mainprice_bigSmall {
    align-items: flex-end;
  }
  &__head-priceEstimated {
    max-width: 100%;
    padding: 6px 0;
  }
  &__head-pricespecialFlash {
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding: 6px;
    background: linear-gradient(180deg, #FFF6F3 0%, rgba(255, 246, 243, 0) 100%);
  }
  &__head-pricespecialFlashLeft {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px 0;

    & > p:nth-child(1) {
      font-family: Arial;
      font-size: 24px;
      font-weight: 900;
      color: #FA6338;
      margin-right: 6px;
      line-height: 1.4;
      &.goods-price__small-size{
        font-size: 16px;
      }
    }
  }
  .del-price {
    color: #666;
    vertical-align: middle;
    margin-right: 4px;
  }
  &__head-pricespecialFlashLeft_bigSmall {
    align-items: flex-end;
  }
  &__head-pricespecialFlashLeft_has-estiamted {
    padding: 0 0;
  }
  &__head-pricespecialEstimated {
    padding: 4px 0;
  }
  &__head-pricespecialEstimatedout{
    display: flex;
    padding: 0 6px 6px;
    overflow: hidden;
  }
  &__head-pricespecialRetail {
    color: #999;
    vertical-align: middle;
    font-size: 14px;
    line-height: 1;
  }
  &__head-pricespecialDiscountPercent {
    padding: 0 8px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    display: inline-block;
    font-size: 12px;
    background-color: #FA6338;
    border-radius: 2px;
    margin-left: 5px;
    color: #fff;
  }

  &__head-pricespecialFlash-right {
    display: flex;
    align-items: center;
    font-family: Arial;
    align-self: flex-start;
    padding-top: 11px;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    color: #FA6338;
    cursor: pointer;
    white-space: nowrap;
  }
  &__head-paidvip.paidvip {
    margin-top: 20px;
  }
  &__head-paidvip-estimated {
    margin-top: 10px;
  }
  &__new-no-threshold {
    margin-top: 15px;
  }

  &__head-price {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    // * {
    //   direction: ltr /*rtl:ignore*/;
    // }
    .original {
      color: #222;
      font-size: 24px;
      font-weight: 700;
      font-family: "Arial";
      &.goods-price__small-size{
        font-size: 16px;
        line-height: 22px !important; /* stylelint-disable-line declaration-no-important */
      }
    }
  }
  &__s3-tag{
    width: 27px;
    margin-right: 2px;
  }
  
  &__PriceBannerType {
    position: relative;
    box-sizing: border-box;
    padding: 4px;
    border-radius: 4px;
  }
  &__PriceBannerType_hide {
    padding: 0;
    background: none !important; /* stylelint-disable-line declaration-no-important */
  }
  &__PriceBannerType_normalFlash {
    background: #FACF19;
  }
  &__PriceBannerType_specialFlash {
    background: #FFDED6;
  }
  &__PriceBannerType_brandSpecialFlash {
    background: linear-gradient(89.2deg, #FF3D00 0.69%, #FA7731 93.86%);
  }

  &__PriceBannerType-lightningImg {
    width: 100px;
    height: calc(100% + 4px);
    right: -2px;
    top: -2px;
    position: absolute;
    z-index: @zindex-hack;
    overflow: hidden;
    & > img {
      width: 100%;
      position: absolute;
      top: -10px;
    }
  }
  &__PriceBannerType-brandSpecialFlashImg {
    width: 64px;
    right: 32px;
    height: calc(100% + 4px);
    top: -2px;
    position: absolute;
    z-index: @zindex-hack;
    overflow: hidden;
    & > img {
      width: 100%;
    }
  }
  
  &__PriceBannerType-head {
    position: relative;
    z-index: @zindex-out;
    display: flex;
    justify-content: space-between;
    padding: 6px 0;
  }

  &__PriceBannerType-headLeft {
    display: flex;
    align-items: center;
    & > span {
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
    }
  }
  &__PriceBannerType-headLeftBrandIcon {
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    margin-right: 2px;
  }
  &__PriceBannerType-headLeftSpecialMoreIcon {
    align-self: center;
    margin-left: 5px;
  }

  &__PriceBannerType-headRight {
    position: relative;
    z-index: @zindex-out;
    font-size: 12px;
    font-weight: 400;
    color: #000;
    display: flex;
    align-items: center;
  }

  &__PriceBannerType-headRightHundred {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #000;
    min-width: 16px;
    height: 16px;
    border-radius: 2px;
    margin-left: 4px /* rtl: ignore */;
  }

  &__PriceBannerType-headRightIcon {
    margin-right: 8px;
  }

  &__PriceBannerType-priceWraper {
    position: relative;
    z-index: @zindex-out;
    background: #fff;
    border-radius: 2px;
    padding: 8px 12px;
  }
  &__PriceBannerType-priceWraper_brandFlash {
    background: rgba(255, 255, 255, 0.96);
  }
  .suggestedSalePrice-tip {
    font-family: SF Pro;
    font-size: 12px;
    font-weight: 400;
    color: #959595;
  }
  &__de-discount {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    color: #999;
    font-size: 14px;
  }
  &__de-discountText {
    margin-right: 2px;
  }
  &__de-discountDel {
    margin-right: 4px;
  }
  .flashsale-label,
    .discount-label {
      //min-width: 47px;
      display: inline-block;
      margin-left: 5px;
      padding: 0 8px;
      height: 18px;
      line-height: 18px;
      text-align: center;
      display: inline-block;
      font-size: 12px;

      /* rw:begin*/
      font-family: "Adieu";
      font-weight: 400;
    }
    .flashsale-label {
      background-color: #ffd665;
      /* rw:begin*/
      background-color: @sui_color_flash;
      color: #fff;
    }
    .discount-label {
      background-color: #222;
      color: @sui_color_white;
      /* rw:begin*/
      background-color: @sui_color_main;
    }
    .new-discount-label {
      background-color: #FA6338;
      border-radius: 2px;
    }
}
.percent-newstyle {
  display: flex;
  align-items: center;
  &__pricespecial-flash {
    padding: 8px 8px 0;
    width: 100%;
    background: linear-gradient(180deg, #FFF7F4 0%, rgba(255, 255, 255, 0.00) 100%);
  }
  &__head-pricespecialFlash-top {
    display: flex;
    align-items: center;
    font-family: Arial;
    align-self: flex-start;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    color: #FA6338;
    cursor: pointer;
    white-space: nowrap;
    margin-bottom: 7px;
  }

}

</style>
