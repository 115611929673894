import { ref } from 'vue'
import schttp from 'public/src/services/schttp'
import { fetchTelCountryListApi } from 'public/src/services/api/pay/checkout.js'

export const defaultColloctUserInfoDialog = {
  visible: false,
  billno: '', // 缓存订单号，切换订单号时需要重置
  recommendPhoneVisible: false,
  recommendPhone: '',
  phone: '',
  needCollectPhoneNumber: false,
  phoneRules: [],
  paymentCode: '',
  countryCode: '',
  countryId: '',
  phoneCountryNum: '',
}

// 推荐手机号显示定时器
let recommendPhoneShowTimer = null
// 倒计时1s显示推荐手机号
const recommendPhoneShowTime = 1000
// 推荐手机号隐藏定时器
let recommendPhoneHideTimer = null
// 倒计时4s隐藏推荐手机号
const recommendPhoneHideTime = 4000
const collectUserDialog = ref(
  JSON.parse(JSON.stringify(defaultColloctUserInfoDialog)),
)

export default () => {
  const initColloctUserInfoDialog = () => {
    collectUserDialog.value = JSON.parse(
      JSON.stringify(defaultColloctUserInfoDialog),
    )
  }

  const updateColloctUserInfoDialog = payload => {
    collectUserDialog.value = payload
  }
  const mergeColloctUserInfoDialog = payload => {
    const newValue = {
      ...collectUserDialog.value,
      ...payload,
    }
    collectUserDialog.value = newValue
  }

  const setUserPaymentrelatedInfo = async ({
    orderInfo = {},
    selectedPayment = {},
    extraData = {},
  }) => {
    const {
      billno = '',
      countryCode = '',
      countryId = '',
      paymentCode = '',
      currencyCode = '',
      recommendPhone = '',
    } = orderInfo

    // 切换订单号时需要重置
    if (
      collectUserDialog.value.billno &&
      collectUserDialog.value.billno !== billno
    ) {
      initColloctUserInfoDialog()
    }

    // 缓存订单号
    billno && mergeColloctUserInfoDialog({ billno })

    const dialogData = {
      ...collectUserDialog.value,
      needCollectPhoneNumber: selectedPayment?.collect_phone_number == 1,
      phoneRules: [],
      paymentCode,
      countryId,
      countryCode,
      phoneCountryNum: '',
      ...extraData,
    }
    const { appLanguage } = gbCommonInfo
    try {
      const [relatedInfo, telCountryInfo] = await Promise.all([
        schttp({
          url: '/pay/payment/get_user_payment_related_info',
          method: 'POST',
          data: {
            countryCode: countryCode,
            paymentCode: paymentCode,
          },
          headers: {
            AppCurrency: currencyCode,
            AppLanguage: appLanguage,
          },
          useBffApi: true,
        })
          .then(res => (res.code === '0' ? res.info : {}))
          .catch(() => {}),
        fetchTelCountryListApi({
          country_id: countryId,
        })
          .then(res => (res.code === '0' ? res.info : {}))
          .catch(() => {}),
      ])

      dialogData.isNewPhoneShow = relatedInfo?.isNewPhoneShow
      dialogData.bubbleTip = relatedInfo?.bubbleTip
      dialogData.endorsementSafetyTips = relatedInfo?.endorsementSafetyTips
      dialogData.recommendPhone =
        relatedInfo?.paymentRelatedInfo?.phoneNumber || recommendPhone
      dialogData.phoneRules =
        relatedInfo?.phoneCheckRule?.result?.regex_list || []
      dialogData.countryCode = telCountryInfo?.value
      dialogData.phoneCountryNum = telCountryInfo?.countrynum

      // isNewPhoneShow 等于 1 ，使用新手机号推荐交互
      if (relatedInfo?.isNewPhoneShow == '1') {
        if (dialogData.recommendPhone && !dialogData.phone) {
          // 自动显示
          clearTimeout(recommendPhoneShowTimer)
          recommendPhoneShowTimer = setTimeout(() => {
            mergeColloctUserInfoDialog({
              recommendPhoneVisible: true,
            })
          }, recommendPhoneShowTime)
          // 自动隐藏
          clearTimeout(recommendPhoneHideTimer)
          recommendPhoneHideTimer = setTimeout(() => {
            mergeColloctUserInfoDialog({
              recommendPhoneVisible: false,
            })
          }, recommendPhoneShowTime + recommendPhoneHideTime)
        }
      } else {
        dialogData.phone = dialogData.recommendPhone || ''
      }
    } catch (error) {
      //
    }

    mergeColloctUserInfoDialog(dialogData)
  }

  return {
    collectUserDialog,
    initColloctUserInfoDialog,
    updateColloctUserInfoDialog,
    mergeColloctUserInfoDialog,
    setUserPaymentrelatedInfo,
  }
}
