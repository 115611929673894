import { createStore } from 'vuex'

// TODO ⬇️ 旧的数据流，后面要统一删除
import state from 'public/src/pages/goods_detail_v2/store/state.js'
import getters from 'public/src/pages/goods_detail_v2/store/getters.js'
import actions from 'public/src/pages/goods_detail_v2/store/actions.js'
import mutations from 'public/src/pages/goods_detail_v2/store/mutations.js'
import modules from 'public/src/pages/goods_detail_v2/store/modules/index.js'
// TODO ⬆️ 以上都是旧的数据流，后面要统一删除


const GOODS_DETAIL_STORE = () => createStore({
  modules,
  state,
  getters,
  actions,
  mutations,
})

export { GOODS_DETAIL_STORE }
