import { ClientOnly } from '@sheinfe/vue-client-only'
import { hydrateWhenVisible } from '@shein-aidc/shein-lazy-hydration'

const componentsMap = {
  // 不贵组件
  SuperDeals: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-ccc-duper-deals" */'../components/super-deals/super-deals.vue')),
  // 轮播图、横滑 3、4、5、6 多图
  SideslipImage: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-ccc-sideslip-image" */'../components/sideslip-image/sideslip-image-bff.vue')),
  // 静态图
  StaticImage: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-ccc-static-image" */'../components/static-image/static-image-bff.vue')),
  // 纵向商品列表
  VerticalItems: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-ccc-vertical-items" */'../components/vertical-items/VerticalItemsEntry.vue')),
  // 横向滑动商品组件
  HorizontalItems: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-ccc-horizontal-items" */'../components/horizontal-items/Index.vue')),
  // 排行榜入口组件
  RankingEntrance: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-ccc-ranking-entrance" */'../components/rank/ranking-entrance.vue')),
  // 1/3组件
  OneThirdContainer: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-ccc-one-third" */'../components/one-third-container/Index.vue')),
}
componentsMap['ClientOnly'] = ClientOnly

export default componentsMap
