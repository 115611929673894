import { template } from '@shein/common-function'

export const useHeaderTitle = ({
  carts,
  lowStockProducts,
  languge
}) => {
  const result = []
  if(lowStockProducts?.length){
    const text  = template( lowStockProducts.length, languge?.SHEIN_KEY_PC_32234 )
    result.push({
      'text': {
        'label': text,
        'color': '#FA6338'
      },
      'icon': {
        'name': '',
        'color': ''
      },
      'img': {
        'url': 'https://img.ltwebstatic.com/images3_ccc/2024/08/06/93/17229502797fa39b7866719ef2e0edb3e3c397883e.png',
        'style': {
          'width': '12px'
        }
      },
      type: `soldout\`${lowStockProducts.length}`,
    })
  }
  const reduceAmount = carts.filter(f => f?.reduce_price?.reduceAmount?.amount > 0)
  if(reduceAmount?.length){
    const text  = template( reduceAmount.length, languge?.SHEIN_KEY_PC_33590 )
    result.push({
      'text': {
        'label': text,
        'color': '#FA6338'
      },
      'icon': {
        'name': '',
        'color': ''
      },
      'img': {
        'url': 'https://img.ltwebstatic.com/images3_ccc/2024/08/06/b2/1722950302b5a4cb3821c7c46781aeb62e9d64442f.png',
        'style': {
          'width': '12px'
        }
      },
      type: `cheaprice\`${reduceAmount.length}`,
    })
  }
  return result
}
