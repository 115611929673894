const getters = {
  isSuggested(state) {
    return state.locals?.IS_SUGGESTED || false
  },
  // 整车明细是否外露
  isExposePriceDetail(state) {
    const ControlPriceDetail = state.cartAbtInfo?.PriceDetail?.param?.ControlPriceDetail  || 'show'
    return ControlPriceDetail === 'show'
  },
  // 购物车折扣角标样式是否为a方案，默认为onA，即不配置为off时都为onA，若新增其他样式方案，要按需修改此逻辑
  cartDiscountLogoA (state) {
    return state.cartAbtInfo?.Discountlogo?.param?.add_discount_logo_switch != 'off'
  },
  // 展示obm店铺头
  showOBMStore (state) {
    return state.cartAbtInfo?.Cartshowobmtitle?.p == 'conceal_OBM' ? false : true
  },
  // 展示第三方店铺头
  showThreeStore (state) {
    return state.cartAbtInfo?.Showplatformtitle?.p == 'conceal' ? false : true
  },
  singleMallCode(state) {
    return state.isPlatForm ? 'platform' : state.carts[0]?.mall_code || ''
  },
  underPriceAbtValue (state) {
    return state.cartAbtInfo.UnderPrice?.param?.UnderPriceShow == 'A' ?  true : false
  },
  isShowNewUserTag (state) {
    return state.cartAbtInfo.orderCouponNewUser?.param?.orderCoupon_newUser === 'new'
  },
  // 是否展示取消订单ABT
  cartcancelled_tip(state) {
    return state.cartAbtInfo?.CancelledorderTip?.param?.cartcancelled_tip || 1
  },
  // 是否展示支付订单
  showCancelOrderTip(state, getters) {
    return state.loginStatus && getters.cartcancelled_tip == 1 && !state.orderState?.unpaidOrderInfo
  },
  showQuickShipTag(state) {
    return !state.isPlatForm && state.cartAbtInfo['TradeQuickship']?.p == 'show_QuickShipping' ? true : false
  },
  isNewReturnFlag(state) {
    return state.cartAbtInfo?.Cartrefund?.param?.cart_nonrefundable === 'on'
  },
  /**
   * 负向信息聚合（不可退商品、不可参与积分活动商品、不可参与包邮商品、不可参与优惠券的商品）
   * return {} {mall_code: {noReturn:{ title: 'xxx', list: []},noPoint:{ title: 'xxx', list: []},noFreeShipping:{ title: 'xxx', list: []},noCoupon:{ title: 'xxx', list: []}}}
   */
  negativeInformation(state, getters){
    let negativeInfo = {}
    let normalCarts = state.carts.filter(item => item.isInvalid != '1')

    const negativeInfoTypeHandle = ({ cartItem, type, title }) => {
      if(!negativeInfo[cartItem.mall_code]) negativeInfo[cartItem.mall_code] = {}
      if(!negativeInfo[cartItem.mall_code][type])  {
        negativeInfo[cartItem.mall_code][type] = {
          title: title,
          list: []
        }
      }
      negativeInfo[cartItem.mall_code][type].list.push(cartItem)
    }

    normalCarts.forEach(cartItem => {
      if(cartItem.product.double_status == 0 && cartItem.product.doubleStatusTip){
        negativeInfoTypeHandle({ cartItem: cartItem, type: 'noPoint', title: cartItem.product.doubleStatusTip })
      }
      if (cartItem.aggregateProductBusiness?.notUseCoupon) {
        negativeInfoTypeHandle({ cartItem: cartItem, type: 'noCoupon', title: cartItem.aggregateProductBusiness.notUseCoupon })
      }
      
      if(cartItem.product.noFreeShipping){ // 平台级物流信息
        negativeInfoTypeHandle({ cartItem: cartItem, type: 'noFreeShipping', title: cartItem.product.noFreeShipping })
      }

      if(!getters.isNewReturnFlag && cartItem.return_flag && cartItem.return_flag != 0 && cartItem.return_flag_msg){
        negativeInfoTypeHandle({ cartItem: cartItem, type: 'noReturn', title: cartItem.return_flag_msg })
      }
    })

    return negativeInfo
  },
  // 商品折扣是否以商品行折扣标签展示（TR-37526）
  isNewCartDiscountStyle(state, getters) {
    if (getters.isSuggested) return false
    const cart_discount_style = state.cartAbtInfo?.cartDiscountStyle?.param?.cart_discount_style || 'new'
    return cart_discount_style === 'new'
  },
  // 购物车筛选标签样式，off-线上平铺，logo-logo样式，text-文字样式
  filterSelectLogo(state) {
    return state.cartAbtInfo?.cartSelect?.param?.ControlSelectlogo || 'off'
  },
  // 凑单组件点击商品是否跳转商详
  cartAddTradeGoodsDetailSwitch(state) {
    return state.cartAbtInfo?.CartaddGoodsdetailSwitch?.param?.cartadd_trade_goodsdetail_switch === 'on'
  },
  // 是否开启购物车券筛选与底部feed流联动
  isCartSelectRecommend(state) {
    return state.cartAbtInfo?.cartSelect?.param?.cartSelect_recommend == 'on'
  },
  // SFS商品标签及利益点 pageId=1492949172
  CartOrderSFSVisual(state) {
    return {
      visual_tabs: state.cartAbtInfo?.CartOrderSFSVisual?.param?.visual_tabs == 'show_tabs',
      visual_benefit_point: state.cartAbtInfo?.CartOrderSFSVisual?.param?.visual_benefit_point == 'show_benefit_point'
    }
  },
  // 购物车筛选是否常驻置顶
  isFilterControlTop(state) {
    return state.cartAbtInfo?.cartSelect?.param?.control_top == 'on'
  },
}
export default getters
