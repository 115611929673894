<template>
  <div
    class="cart-soldout-item"
    :style="itemStyle"
  >
    <BSingleImageItem
      :data="item"
      :language="language"
      :constant="constant"
      :use="use"
      :config="config"
    />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { BSingleImageItem, getConstant } from '@shein-aidc/bs-cart-item-laptop'
import { useItemEvents } from 'public/src/pages/cart_v2/hooks/item/index'
import { useGoodsJump } from 'public/src/pages/cart_v2/hooks/useGoodsJump'

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
    required: true,
  },
  index: {
    type: Number,
    default: 0,
    required: true,
  }
})

const { jumpDetail } = useGoodsJump()
const { findSimilarEvent } = useItemEvents()
const { state } = useStore()
const language = computed(() => state.language)
const constant = computed(() => getConstant())
const isNewCartDiscountStyle = computed(() => state.isNewCartDiscountStyle)
const use = computed(() => {
  const defaultUse = [
    'GoodsImg',
    'GoodsBelt',
    'GoodsSeriesFlag',
    'FindSimilarText',
  ]
  if (isNewCartDiscountStyle.value) {
    defaultUse.push('GoodsDiscountFlag')
  }
  return defaultUse
})
const itemStyle = ref({
  '--template-width': '120px',
  '--template-top-height': '120px'
})
const config = ref({
  GoodsImg: {
    props() {
      return {
        isLazyLoad: props.index >= 6,
      }
    },
    events: {
      onClick({ data }) {
        const analysis = {
          click_goods_list(defaultData) {
            return {
              ...defaultData,
              index: props.index,
            }
          }
        }
        jumpDetail({ itemOpts: { data }, analysis })
      }
    }
  },
  FindSimilarText: {
    props() {
      return {
        type: 'square',
      }
    },
    events: {
      onExpose(itemOpts) {
        const analysis = {
          expose_left_findsimilar: () => null,
          expose_findsimilar: () => null,
        }
        findSimilarEvent.onExpose({ itemOpts, analysis })
      },
      onReport(itemOpts) {
        const analysis = {
          click_left_findsimilar: () => null,
          click_findsimilar: () => null,
        }
        findSimilarEvent.onReport({ itemOpts, analysis })
      },
      onClick(itemOpts) {
        const options = {
          similarPopoverConfig: {
            similarFrom: 'out_of_stock',
          }
        }
        findSimilarEvent.onClick({ itemOpts, options })
      }
    }
  }
})
</script>

<style lang="less">
.cart-soldout-item {
  width: 100%;
}
</style>
