<template>
  <div class="empty-container-wrap">
    <!-- 空购物袋 -->
    <div class="empty-bag fsp-element">
      <EmptyCart />
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyCart',
}
</script>
<script setup>
import EmptyCart from 'public/src/pages/cart_v2/components/functional/cartEmpty/index.vue'
</script>

<style lang="less">
.empty-container-wrap {
  width: 100%;
  padding-bottom: 30px;
  text-align: center;
  .flexbox();
  .empty-bag {
    background-color: #fff;
    width: 100%;
    padding-bottom: 30px;
  }
}
</style>
