<template>
  <div
    v-if="show"
    class="cart-alldiscountdetail_expose"
  >
    <CartAllDiscountDetailContent
      v-bind="allDiscountDetailContentProps"
      @modify-cart-check-status="onModifyCartCheckStatus"
    />
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useCartAllDiscountDetail } from './useCartAllDiscountDetail'
import useModifyCartCheckStatus from 'public/src/pages/cart_v2/hooks/useModifyCartCheckStatus'

const CartAllDiscountDetailContent = defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-all-discount-detail-content" */'./CartAllDiscountDetailContent.vue'))

const { allDiscountDetailMode, allDiscountDetailContentProps } = useCartAllDiscountDetail()

const show = computed(() => allDiscountDetailMode.value === 'expose')

const { modifyCartCheckStatus } = useModifyCartCheckStatus()
const onModifyCartCheckStatus = ({ info, checked, callback }) => {
  modifyCartCheckStatus({
    type: 'item',
    cartIdList: [info.id],
    operationType: checked ? 1 : 2,
    onAfterModify() {
      callback()
    }
  }, {
    click_select_someproduct(data) {
      return {
        position: 'popup',
        select_type: data.select_type,
        is_select: data.is_select,
        scene: data.scene,
      }
    }
  })
}
</script>

<style lang="less">
.cart-alldiscountdetail_expose {
  flex: 1;
  margin-bottom: 8px;
  border-bottom: 1px solid @sui_color_gray_weak1;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overscroll-behavior: contain;
  -ms-scroll-chaining: contain;

  &::-webkit-scrollbar {
    display: none;
  }

  .cart-alldiscountdetail-content__products {
    /* stylelint-disable-next-line selector-max-type */
    swiper-slide + swiper-slide {
      padding-left: 7.6px;
    }
  }
}
</style>
