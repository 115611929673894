<template>
  <div
    class="shop-entry__storeEntry"
  >
    <div 
      ref="storeHeaderRef"
      class="top-level"
      :class="{'top-link-pointer': canJumpToStore }"
      :data-is-store="true"
      :data-brand-code="storeInfo.id"
      :data-brand-type="storeInfo.type"
      :data-name="storeInfo.name"
      :data-designer-id="storeInfo.designerId"
      :data-id="storeInfo.id"
      :data-sc-id="storeInfo.scId"
      :data-abt_ana_info="extAnalysisInfo.abtAnaInfo"
      :data-brand_info="extAnalysisInfo.brandInfo"
      :data-brand_label="extAnalysisInfo.brandLabel"
      :data-store_qualitylabel="storeQualityLabel"
      :data-flash_sale_stype="flashEntryType === 'showflashsale' ? 2 : (flashEntryType === 'showflashbutton' ? 3 : 0)"
      data-page-nm="page_goods_detail"
      @click="goToStore"
    >
      <!-- logo -->
      <div class="logo-box">
        <img :src="storeInfo.logo" />
      </div>

      <!-- split whitespace -->
      <div class="whitespace"></div>

      <!-- store info -->
      <div class="info-box">
        <!-- name line -->
        <div class="name-line">
          <!-- store name -->
          <div class="title">
            {{ storeInfo.name }}
          </div>
          <!-- FASHION -->
          
          <div
            v-if="showFashion"
            v-expose="{ id: '1-8-6-187' }"
          >
            <TrendsLabel only-label-style />
          </div>
          <!-- 品质店铺 -->
          <img 
            v-if="isQualityStore"
            :style="showFashion ? {marginLeft: '0px'} : null"
            :src="qualityStoreIconSrc"
            style="height: 20px;"
          />
          <!-- "Brand Seller" | storeBrand only -->
          <div
            v-if="isStoreBrand"
            class="brand-label"
          >
            <img
              class="barnd-icon"
              src="https://img.ltwebstatic.com/images3_ccc/2024/12/03/70/1733215576e015bd4e33d214c688fe369ea8d990ba.png"
            />
            {{ language.SHEIN_KEY_PC_25621 }}
            <img
              class="brand-angle"
              :src="storeBrandImg"
            />
          </div>
          <!-- third party labels | hide in storeBrand -->
          
          <template v-if="!isStoreBrand && isThirdPartyStore">
            <div
              v-if="bestSeller"
              class="best-seller-label"
            >
              <img
                class="icon"
                :src="`${PUBLIC_CDN}/she_dist/images/store/store-preferred-seller-bb758e2772.png`"
              />
              <div class="whitespace"></div>
              <span class="text">
                {{ language.SHEIN_KEY_PC_21837 }}
              </span>
            </div>
            <div
              v-if="saleTagText"
              :class="{ 'sale-label': true }"
            >
              {{ saleTagText }}
            </div>
          </template>
        </div>

        <!-- store description line -->
        <div
          v-if="storeInfo.desc"
          :class="['desc-line', { 'brand-type': isStoreBrand }]"
          v-html="storeInfo.desc"
        ></div>

        <!-- store follow info line -->
        <!-- TODO-staging 新样式 abt全量后去除v-if -->
        <div
          v-if="storeFollowInfo && storeFollowInfo.show && storehotnewsType !== 'showscroll'"
          v-show="storeFollowInfoLineShow"
          class="follow-info-line"
        >
          <!-- 店铺评分 -->
          <div
            v-if="storeRate"
            class="item with-split-line"
          >
            <em>{{ formatShopScore }}</em>&nbsp;{{ language.SHEIN_KEY_PC_21810 }}
          </div>
          <!-- 30日销量 -->
          <div
            v-if="storeSales && storeSales !== '0'"
            class="item with-split-line"
          >
            <em>{{ storeSales }}</em>&nbsp;{{ language.SHEIN_KEY_PC_21781 }}
          </div>
          <!-- 店铺关注数 -->
          <div
            v-if="followCount && followCount !== '0'"
            class="item with-split-line"
          >
            <em>{{ followCount }}</em>&nbsp;{{ language.SHEIN_KEY_PC_25386 }}
          </div>
        </div>
        <!-- 店铺人气氛围&店铺评分信息滚动显示 -->
        <StoreAtmosphereSwiper
          v-else-if="storehotnewsType === 'showscroll'"
          class="store-follow__atmosphere"
          :has-follow-info="storeFollowInfo && storeFollowInfo.show"
          position="showscroll"
        >
          <template #followInfo>
            <div
              class="follow-info-line"
            >
              <!-- 店铺评分 -->
              <div
                v-if="storeRate"
                class="item with-split-line"
              >
                <em>{{ formatShopScore }}</em>&nbsp;{{ language.SHEIN_KEY_PC_21810 }}
              </div>
              <!-- 30日销量 -->
              <div
                v-if="storeSales && storeSales !== '0'"
                class="item with-split-line"
              >
                <em>{{ storeSales }}</em>&nbsp;{{ language.SHEIN_KEY_PC_21781 }}
              </div>
              <!-- 店铺关注数 -->
              <div
                v-if="followCount && followCount !== '0'"
                class="item with-split-line"
              >
                <em>{{ followCount }}</em>&nbsp;{{ language.SHEIN_KEY_PC_25386 }}
              </div>
            </div>
          </template>
        </StoreAtmosphereSwiper>
        <!-- store rate and products line -->
        <!-- TODO-staging-remove -->
        <div
          v-else
          class="rate-line"
        >
          <div
            v-if="storeInfo.totals"
            class="item with-split-line"
          >
            {{ language.SHEIN_KEY_PC_21781 }}:&nbsp;
            <em>{{ storeInfo.totals }}</em>
          </div>
          <div
            v-if="storeRate"
            class="item with-split-line"
          >
            {{ language.SHEIN_KEY_PC_21810 }}:&nbsp;<em>{{ formatShopScore }}</em>
          </div>
        </div>

        <!-- "View Products" button line -->
        <div
          v-if="linkBtnShow"
          class="button-line"
        >
          <JumpToStoreBtn
            :store-info="storeInfo"
            :store-rate="storeRate"
            :detail="detail"
            :button-text="linkBtnText"
            :type="'link'"
            :ext-analysis-info="extAnalysisInfo"
            :store-quality-label="storeQualityLabel"
          />
        </div>
      </div>
    </div>
    <StoreQualityTags
      v-if="isShowStoreQualityList"
      :store-quality-list="storeQualityList"
      @go-to-store="goToStore"
    />
    <div
      v-if="descriptionsShow"
      class="store-descriptions"
    >
      {{ storeInfo.descriptions }}
    </div>
    <div
      v-if="showBtnLevel"
      :class="{
        'btn-level': true,
        'weak': storeFollowRelativeShow
      }"
    >
      <!-- View More button -->
      <JumpToStoreBtn
        :store-tab="flashEntryType === 'showflashsale' || flashEntryType === 'showflashbutton' ? 'promo' : 'items'"
        :store-info="storeInfo"
        :store-rate="storeRate"
        :detail="detail"
        :button-text="language.SHEIN_KEY_PC_27102"
        :type="'button'"
        :ext-analysis-info="extAnalysisInfo"
        :flash-entry-type="flashEntryType"
        :language="language"
        :store-quality-label="storeQualityLabel"
      />

      <!-- split whitespace -->
      <div
        v-if="storeFollowRelativeShow && storeInfo.link"
        class="whitespace"
      ></div>

      <!-- Follow button -->
      <StoreFollowBtnBox
        v-if="storeFollowRelativeShow"
        :class-name="'shop-entry__storeFollowBtn'"
        :scene="'ProductDetail'"
        :store-id="storeId"
        :follow-state="storeFollowState"
        :has-un-follow-dialog="hasUnFollowDialog"
        :auto-login="true"
        :language="language"
        @state-changed="handleFollowStateChange"
        @state-changing="handleFollowStateChanging"
        @state-change-fail="handleFollowStateChangeFail"
        @show-login-modal="showLoginModal"
      >
        <template #button>
          <div
            v-expose="{ id: '1-6-1-145', data: followBtnAnalysisData }"
            :class="{
              'follow-btn': true,
              'show-symbol': followBtnShowSymbol,
              'followed': storeFollowState
            }"
            @click="followBtnClick"
          >
            <FollowStoreIcon 
              :is-followed="storeFollowState" 
            />
            <span>{{ storeFollowBtnText }}</span>
          </div>
        </template>
      </StoreFollowBtnBox>
    </div>
    <div 
      v-if="storehotnewsType === 'showalone' && isShowRecommend && isRecommendAbt"
      class="shop-entry__atmosphere"
    >
      <span class="shop-entry__atmosphere-title">{{ language.SHEIN_KEY_PC_31834 || 'Store Recommend' }}</span>
      <StoreAtmosphereSwiper />
    </div>
    <div 
      v-if="flashEntryType === 'showbelt'"
      class="signboard-flash-entry"
      @click="clickFlashBeltEntry"
    >
      <div class="flash-entry-iconwrap">
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          width="16" 
          height="16" 
          viewBox="0 0 16 16" 
          fill="none"
        >
          <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M10.8792 1.6001H6.39922L3.19922 7.46676H7.03922L6.07922 14.4001L12.7992 4.8001H8.95922L10.8792 1.6001Z" 
            fill="black"
          />
        </svg>
        <span class="flash-entry-text">{{ language.SHEIN_KEY_PC_31586 || 'This store is in Flash Sale' }}</span>
      </div>

      <div
        v-if="oneLineShow"
        ref="rightText"
      >
        <div
          v-if="flashEntryCountdown"
          class="flash-entry-countdown" 
        >
          <p v-html="countdownTips"></p>
          <span class="countdown-second-hundred">{{ secondHundred }}</span>
        </div>
        <div v-else>
          {{ language.SHEIN_KEY_PC_31585 || 'Don’t miss it' }}
        </div>
      </div>
      <Icon
        class="flash-entry-rightarrow"
        name="sui_icon_more_right_16px_2" 
        size="16px" 
        :is-rotate="GB_cssRight"
      />
    </div>
  </div>
</template>

<script setup>
/**
 * @component StoreEntry 商详页店铺入口
 * * 依赖商详vuex
 */
import { toRefs, computed, nextTick, ref, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import StoreFollowBtnBox from './StoreFollowBtnBox'
import JumpToStoreBtn from './JumpToStoreBtn'
import FollowStoreIcon from './FollowStoreIcon'
import StoreAtmosphereSwiper from './StoreAtmosphereSwiper'
import StoreQualityTags from './StoreQualityTags'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { Icon } from '@shein-aidc/icon-vue3'
import { CountDown, debounce, stringifyQueryString } from '@shein/common-function'
import TrendsLabel from '../TrendsLabel.vue'
import { goToStoreCommon } from './utils'

const { PUBLIC_CDN, SiteUID, GB_cssRight, appLanguage } = gbCommonInfo
/**
 * data
 */
const beginTime = ref(0)
const countTimer = ref(null)
const secondInterval = ref(null)
const secondHundred = ref(9)
const oneLineShow = ref(true)
const rightText = ref(null)
const storeHeaderRef = ref(null)

/**
 * props
 */
const props = defineProps({
  // 店铺信息
  storeInfo: {
    type: Object,
    default: () => ({}),
  },
  // 是否为品牌集成店铺
  isStoreBrand: {
    type: Boolean,
    default: false,
  },
  // 额外埋点信息，用于店铺模块跳转事件
  extAnalysisInfo: {
    type: Object,
    default: () => ({}),
  },
  isShowRecommend: { // 是否在下方展示推荐商品入口
    type: Boolean,
    default: false
  },
})
const { storeInfo, isStoreBrand, extAnalysisInfo } = toRefs(props)

/**
 * computed
 */
const vuexStore = useStore()
// 多语言
const language = computed(() => vuexStore.state.language)
// 店铺评分
const storeRate = computed(() => vuexStore.state.storeRate)
// 店铺关注状态
const storeFollowInfo = computed(() => vuexStore.state.storeFollowInfo)
// 店铺id
const storeId = computed(() => {
  return vuexStore.state.productIntroData?.detail?.store_code || ''
})
const detail = computed(() => {
  return vuexStore.state.productIntroData?.detail
})
// 展示的分数
const formatShopScore = computed(() => {
  return (+storeRate.value).toFixed(2)
})
// 是否第三方本地平台店铺
const isThirdPartyStore = computed(() => {
  return storeInfo.value?.storeType == 1
})
// 展示店铺描述需满足四个条件，1.有描述 2.命中“展示店铺描述”的标签  3.普通店铺 4.shein
const descriptionsShow = computed(() => {
  const showDescription =  storeInfo.value?.labelList?.some(item => item.labelCode === 'Show_Description')
  return storeInfo.value?.descriptions && showDescription && !isShowStoreQualityList.value
})
// 第三方店铺降价标签文案
const saleTagText = computed(() => {
  const storeInfoValue = storeInfo.value
  const langValue = language.value
  if (storeInfoValue?.salesArea == 1){
    return SiteUID == 'us'
      ? (langValue?.SHEIN_KEY_PC_24729 || '')
      : (langValue?.SHEIN_KEY_PC_24433 || '')
  } else if (storeInfoValue?.salesArea == 2) {
    return SiteUID == 'us'
      ? (langValue?.SHEIN_KEY_PC_24729 || '')
      : (langValue?.SHEIN_KEY_PC_24434 || '')
  }
  return ''
})

// 显示时尚店铺
const showFashion = computed(() => {
  return vuexStore.getters.isInFashionStore
})

const storeQualityList = computed(() => vuexStore.getters.storeQualityList)
const isShowStoreQualityList = computed(() => vuexStore.getters.isShowStoreQualityList)
const storeQualityLabel = computed(() => vuexStore.getters.storeQualityLabel)

const flashEntryType = computed(() => {
  return vuexStore.getters?.flashEntryType
})

const flashEntryCountdown = computed(() => {
  return vuexStore.getters?.flashEntryCountdown
})

const storehotnewsType = computed(() => {
  return vuexStore.getters?.storehotnewsType
})

const storeBrandImg = computed(() => {
  return GB_cssRight ? 
    'https://shein.ltwebstatic.com/svgicons/2024/12/18/17345111681339560952.svg' : 
    'https://shein.ltwebstatic.com/svgicons/2024/12/18/17345111683378229675.svg'
})

const isRecommendAbt = computed(() => {
  return vuexStore.getters?.isRecommendAbt
})

const countdownTips = computed(() => {
  const { H, M, S } = countTimer.value?.timeObj || {}
  let spanTxt = txt => `<span>${txt}</span>`
  return spanTxt(H) + 'h : ' + spanTxt(M) + 'm : ' + spanTxt(S) + 's'
})

// 品质店铺
const isQualityStore = computed(() => {
  return vuexStore.getters?.isQualityStore
})

const qualityStoreIconSrc = computed(() => {
  let link = 'https://shein.ltwebstatic.com/svgicons/2024/11/27/1732699041484821193.svg'
  if(appLanguage === 'ja') {
    link = 'https://shein.ltwebstatic.com/svgicons/2024/11/27/17327119212512363276.svg'
  }else if(SiteUID === 'br' && appLanguage === 'pt-br') {
    link = 'https://shein.ltwebstatic.com/svgicons/2024/11/27/17327109463685091627.svg'
  } else if(SiteUID === 'mx' && appLanguage === 'es') {
    link = 'https://shein.ltwebstatic.com/svgicons/2024/11/27/17327108881915893421.svg'
  }
  return link
})
// 优质卖家标签
const bestSeller = computed(() => {
  return storeInfo.value?.labelList?.find(item => item.labelCode == 'preferredSeller')
})

// 店铺关注信息是否展示
// TODO-remove 对应店铺230620新样式，abt全量后永远返回true，相关逻辑需要移除
const storeFollowRelativeShow = computed(() => {
  if (!storeInfo.value) {
    return false
  }
  return storeFollowInfo.value?.show 
})
// 店铺30天销量
const storeSales = computed(() => {
  return storeFollowInfo.value?.salesVolume || ''
})
// 店铺关注数
const followCount = computed(() => {
  return storeFollowInfo.value?.followCount || ''
})
// 店铺关注信息行是否展示
const storeFollowInfoLineShow = computed(() => {
  return storeRate.value
    || (storeSales.value && storeSales.value !== '0')
    || (followCount.value && followCount.value !== '0')
})
// 用户店铺关注状态
const storeFollowState = computed(() => {
  if (!storeFollowRelativeShow.value) {
    // TODO-remove abt全量后删除此逻辑
    return false
  }
  return storeFollowInfo.value?.userFollowState === 1
})
// 是否展示独立一层按钮
const showBtnLevel = computed(() => {
  return isStoreBrand.value || storeFollowRelativeShow.value
})
// 店铺信息中间处跳转按钮是否展示
const linkBtnShow = computed(() => {
  return !showBtnLevel.value && storeInfo.value?.link
})
// 店铺信息中间处跳转按钮文案
const linkBtnText = computed(() => {
  return `${storeInfo.value?.desc_bak} >`
})
// 店铺关注按钮是否展示符号
const followBtnShowSymbol = computed(() => {
  const { followStateChanging } = storeFollowInfo.value || {}
  return !followStateChanging
})
const hasUnFollowDialog = computed(() => {
  return vuexStore.getters?.hasUnFollowDialog
})
// 店铺关注按钮文案
const storeFollowBtnText = computed(() => {
  const { followStateChanging, userFollowState } = storeFollowInfo.value || {}
  if (followStateChanging) return '...'
  if (userFollowState === -1) {
    return language.value?.SHEIN_KEY_PC_25385
  }
  return userFollowState === 1
    ? language.value?.SHEIN_KEY_PC_25389
    : language.value?.SHEIN_KEY_PC_25385
})
// 店铺关注按钮埋点信息
const followBtnAnalysisData = computed(() => {
  const { userFollowState } = storeFollowInfo.value || {}
  return {
    action_tp: userFollowState === 1 ? 'followed' : 'unfollowed',
    brand_code: storeId.value,
    brand_type: 'store',
  }
})
// 是否店铺热曲能跳转
const canJumpToStore = computed(() => {
  return storeInfo.value?.link && showBtnLevel.value
})

/**
 * hooks
 */
onMounted(() => {
  initTimer()
  // TODO BFF - Delete - 后期pc开始中间层融合时，此处将删除
  // vuexStore.dispatch('updateRealBffInfo')
})

watch(flashEntryCountdown, () => {
  initTimer()
})

/**
 * methods
 */
// 关注按钮点击
// * 此处只处理埋点事件，关注状态的切换冒泡至 StoreFollowBtnBox 组件进行处理
function initTimer() {
  nextTick(()=>{
    let el = rightText.value
    if(el?.offsetHeight > 20){ // 一行展示不下，则隐藏右测内容
      oneLineShow.value = false
    }
  })
  if (!flashEntryCountdown.value) return
  // 倒计时开始时间
  countTimer.value = new CountDown()
  beginTime.value = flashEntryCountdown.value
  if (beginTime.value <= 0) {
    beginTime.value = 0
  }
  secondInterval.value = setInterval(() => {
    secondHundred.value --
    if (secondHundred.value < 0) secondHundred.value = 9
  }, 100)

  // 初始化倒计时
  countTimer.value.start({
    seconds: beginTime.value / 1000,
    endFunc: debounce({
      func: () => {
        clearTimeAndInterval()
        window?.location.reload()
      }
    })
  })
}
function clearTimeAndInterval () {
  // 清除定时器
  countTimer.value && countTimer.value.clear()
  secondInterval.value && clearInterval(secondInterval.value)
}

// 跳转到店铺页
function clickFlashBeltEntry() {
  const { type, name, designerId, id, link } = storeInfo.value || {}
  let url = ''
  const common_params = {
    src_module: 'DetailBrand',
    src_identifier: [
      `on=${type}`,
      `cn=${name}`,
      `hz=0`,
      `ps=1_1`,
      `jc=${
        designerId
          ? `sheinxDesignerInformation_${designerId}`
          : `thirdPartyStoreHome_${id}`
      }`
    ].join('`'),
    src_tab_page_id: getSaPageInfo.tab_page_id || '',
    main_cate_id: detail.value.cat_id,
    main_goods_id: detail.value.goods_id,
  }
  common_params.tab = 'promo'
  const split = !!~link.indexOf('?') ? '&' : '?'
  const store_params = stringifyQueryString({
    queryObj: {
      ici: 'PageGoodsDetail',
      rule_poskey: 'DetailShopItemList',
      ...common_params
    }
  })
  clickStoreReports({ type, id, src_identifier: common_params.src_identifier })
  url = `${link}${split}${store_params}`
  location.href = url
}

function clickStoreReports({ type, id, src_identifier }) {
  const { brandInfo, brandLabel, buttonLabel } = extAnalysisInfo.value || {}
  daEventCenter.triggerNotice({
    daId: '1-8-6-231',
    extraData: { 
      brand_type: type,
      brand_code: id,
      brand_info: brandInfo,
      brand_label: brandLabel,
      button_label: buttonLabel,
      page_nm: 'page_goods_detail',
      src_identifier,
      flash_sale_stype: 1
    }
  })
}

function followBtnClick() {
  const { userFollowState } = storeFollowInfo.value || {}
  const notLogin = (typeof SHEIN_W !== 'undefined')
    && (isLogin() === false)
  daEventCenter.triggerNotice({
    daId: '1-6-1-146',
    extraData: {
      ...(followBtnAnalysisData.value),
      action_tp: notLogin
        ? 'follow'
        : !userFollowState ? 'follow' : 'cancel',
    },
  })
}
// 更改关注状态成功
function handleFollowStateChange(targetState) {
  vuexStore.commit('assignState', {
    storeFollowInfo: {
      ...(storeFollowInfo.value || {}),
      userFollowState: targetState ? 1 : 0,
    }
  })
  daEventCenter.triggerNotice({
    daId: '1-6-1-147',
    extraData: {
      ...(followBtnAnalysisData.value),
      result: 1,
      reason_tp: '-',
      action_tp: targetState ? 'follow' : 'cancel',
    },
  })
}
// 关注状态变更中
function handleFollowStateChanging(state) {
  vuexStore.commit('assignState', {
    storeFollowInfo: {
      ...(storeFollowInfo.value || {}),
      followStateChanging: state,
    }
  })
}
// 变更关注状态失败
function handleFollowStateChangeFail(err) {
  const { userFollowState } = storeFollowInfo.value || {}
  // 埋点
  daEventCenter.triggerNotice({
    daId: '1-6-1-147',
    extraData: {
      ...(followBtnAnalysisData.value),
      result: 0,
      reason_tp: err?.code || '-1',
      action_tp: !userFollowState ? 'follow' : 'cancel',
    },
  })
}
// 打开登录弹窗
function showLoginModal(paylod) {
  vuexStore.commit('showLoginModal', paylod)
}

// 点击去店铺页
function goToStore() {
  if(!canJumpToStore.value) return
  const url = goToStoreCommon({
    storeInfo: storeInfo.value || {},
    isStore: true,
    storeTab: flashEntryType.value === 'showflashsale' || flashEntryType.value === 'showflashbutton' ? 'promo' : 'items',
    detail: detail.value,
  })
  daEventCenter.triggerNotice({
    daId: '1-8-6-60',
    target: storeHeaderRef.value
  })
  location.href = url
}
</script>

<style lang="less" scoped>
.shop-entry__storeEntry {
  display: block;
  .top-link-pointer {
    cursor: pointer;
  }
  .shop-entry__atmosphere{
    display: flex;
    justify-content: space-between;
    height: 16px;
    line-height: 16px;
    margin-top: 15px;
    font-size: 14px;
  }
  .shop-entry__atmosphere-title{
    .text-overflow();
    margin-right: 24px;
    max-width: 241px;
    flex-shrink: 0;
  }
  .store-follow__atmosphere{
    margin-top: 9px;
    .follow-info-line{
      margin-top: 0;
    }
  }
  .signboard-flash-entry{
    width: 100%;
    height: 30px;
    margin: 15px auto 13px;
    background-image: url('/she_dist/images/flash-sale/flash-belt-bg-0b33a05097.png');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    cursor: pointer;
  }
  .flash-entry-iconwrap{
    display: flex;
    align-items: center;
    font-size: 12px;
    flex: 1;
    max-width: calc(100% - 20px);
    svg{
      flex: 0 0 auto;
    }
    .flash-entry-text{
      margin-left: 4px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .flash-entry-rightarrow{
    margin-left: 4px;
  }
  .flash-entry-countdown{
    display: flex;
    align-items: center;
    font-size: 11px;
    direction: ltr /* rtl:ignore */; 
  }
  .countdown-second-hundred {
    font-size: 12px;
    line-height: 14px;
    padding: 0 3.5px;
    background: #000;
    color: #fff;
    border-radius: 2px;
    margin-left: 4px /* rtl:ignore */;
  }
  & > .top-level {
    width: 100%;
    display: flex;
    // align-items: center;

    .whitespace {
      width: 8px;
      height: 8px;
    }
  }

  .logo-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    padding: 1px;
    border: 1px solid #e5e5e5;
    text-align: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .info-box {
    width: calc(100% - 56px);
    box-sizing: border-box;
  }
  .name-line, .rate-line, .follow-info-line {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px 8px;
  }
  .name-line {
    width: 100%;
    max-height: 44px;
    overflow: hidden;
    .title {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .brand-label{
      height: 20px;
      padding: 0 1px 0 3px;
      display: flex;
      align-items: center;
      color: #265999;
      font-size: 13px;
      font-weight: bold;
      font-style: italic;
      border-radius: 2px 0px 0px 2px;
      background: #E0F1FF;
      position: relative;
    }
    .barnd-icon{
      width: 14px;
      height: 14px;
      margin-right: 2px;
    }
    .brand-angle{
      position: absolute;
      right: -9px;
      height: 100%;
    }
    .sale-label {
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      color: #198055;
      -webkit-box-align: center;
      align-items: center;
      font-weight: 400;
      white-space: nowrap;
      padding: 0 4px;
      border-radius: 2px;
      border: 0.5px solid rgba(25, 128, 85, 0.15);
      background: #F5FCFB;
      font-family: 'SF-UI-Text-Regular';
      &.width-margin {
        margin: 0 10px;
      }
    }
  }
  .rate-line, .follow-info-line {
    margin-top: 9px;
    flex-wrap: nowrap;

    .item {
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: #767676;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      em {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #000;
      }
    }
  }

  .desc-line {
    margin-top: 8px;
    line-height: 18px;
    color: #000;
    .clamp(2);
    word-break: normal;

    &.brand-type {
      margin-top: 6px;
      line-height: 14px;
      font-size: 12px;
      color: #999;
    }
  }

  .follow-info-line {
    margin-top: 9px;
  }

  .with-split-line {
    display: flex;
    align-items: center;

    &::before {
      content: "";
      width: 1px;
      height: 10px;
      display: inline-block;
      margin: 0 8px;
      background-color: #e5e5e5;
    }
  }
  .with-split-line:first-child::before {
    display: none;
  }

  .btn-level {
    display: flex;
    align-items: center;
    &.weak {
      .button {
        border-color: @sui_color_gray_light3;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .button {
      display: block;
      line-height: 36px;
      margin-top: 12px;
      width: 100%;
      height: 36px;
      background: #FFFFFF;
      border: 1px solid #222222;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      text-align: center;
      color: #222;
      &:hover {
        cursor: pointer;
      }
    }

    .whitespace {
      width: 20px;
      height: 20px;
      flex: 0 0 auto;
    }
  }
  
  .best-seller-label {
    display: flex;
    align-items: center;
    height: 20px;
    padding: 0 4px;
    border-radius: 2px;
    border: .5px solid rgba(168, 97, 4, 0.15);
    background: #FFF8EB;
    color: #A86104;
    .icon, .whitespace, .text {
      display: block
    }
    .icon {
      width: 14px;
      height: 14px;
    }
    .whitespace {
      width: 4px;
      height: 4px;
    }
    .text {
      font-family: "SF UI Text";
      font-size: 12px;
      color: #a86104;
    }
  }

  .shop-entry__storeFollowBtn {
    margin-top: 12px;
    background: #fff;
    border: 1px solid @sui_color_gray_light3;
    height: 36px;
    width: 100%;
  }
  .follow-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    &.followed {
      color: @sui_color_gray_light1;
    }

    span {
      display: inline-block;
    }
    .follow-store-icon {
      display: none;
    }
    .whitespace {
      width: .106667rem;
      height: .106667rem;
    }
  }
  .show-symbol {
    .follow-store-icon {
      display: flex;
    }
  }
  .store-descriptions {
    font-size: 14px;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #666;
  }
}
</style>
