<template>
  <div class="xtra-radio-selected">
    <!-- 外页展示两个产品包的选项 -->
    <div 
      v-if="isHasAutoRenewedBag && !isShowOptionsInDrawer"
      class="mutiple-selected-wrapper"
    >
      <swiper-container class="j-mutiple-selected-radio mutiple-selected-swipper">
        <swiper-slide
          v-for="(item, index) in xtraProductBagList"
          :key="item.productCode"
          class="item-selected"
          :style="index == 0 ? tips1 : tips2 ? {
            '--price-color': styleConfig?.[item.productCode]?.priceColor,
            '--line-price-color': styleConfig?.[item.productCode]?.linePriceColor,
          } : {
            '--price-color': styleConfig?.[item.productCode]?.priceColor,
            '--line-price-color': styleConfig?.[item.productCode]?.linePriceColor,
            '--label-bg-color': styleConfig?.[item.productCode]?.labelBgColor,
            '--label-border-color': styleConfig?.[item.productCode]?.labelBorderColor,
            '--label-color': styleConfig?.[item.productCode]?.labelColor,
          }"
          @click.stop.prevent="handleXtraItemClick($event, item, index, 'selectSubmit')"
        >
          <!-- 自动续费包引导切换快捷支付方式 -->
          <AutoPayBubble
            v-show="isShowAutoRenewdDubble && !bubbleDown && item.activityType == 1"
            ref="autoPaymenodsGuideRef"
            :product-code="item.productCode"
            :logo-list="logoList"
            :bubble-text="bubbleText"
            :style="{
              'opacity': notSupport ? '0.3' : 1,
              position: 'absolute',
              bottom: '35px'
            }"
            @bubble-close="() => { bubbleDown = true }"
            @handle-pay-methods-list-drawer-show="autoRenewdDrawerShow"
          />
          <div class="container_content">
            <s-radio
              :model-value="usedXtraCode"
              :label="item.productCode"
              :disabled="notSupport"
              theme="icon"
              class="item-selected__radio-wrapper"
            />
            <div :class="[notSupport ? 'item-selected__right-wrapper-disabled': '']">
              <div class="item-selected__right-price-wrapper">
                <span :class="['special-price', { 'special-price-highlight': item.isShowSpecialPriceHighColor }]">{{ item.specialPrice }}</span>
                <del
                  v-if="item.isShowDelPrice" 
                  class="del-price" 
                >{{ item.originPrice }}</del>
              </div>
              <div
                v-if="item.isShowLabel" 
                :class="['discount-label', {'active-label': index == 0 ? tips1 : tips2}]"
                @click="clickDiscountTips($event, item.productCode)"
              >
                {{ item.labelText }}
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper-container>
    </div>
    <!-- 内页展示的两个产品包的选项，上下排列 -->
    <div
      v-else-if="isHasAutoRenewedBag && isShowOptionsInDrawer"
      class="select-xtra-mode"
    >
      <div
        v-for="(item, index) in xtraProductBagList"
        :key="item.productCode"
        :class="[
          'select-xtra-mode__item',
          isSamllFixationHeight ? 'select-xtra-mode__item-fixation-height' : '',
          xtraProductCodeInDrawer == item.productCode ? 'select-xtra-mode__selected-item' : '', 
          notSupport ? 'select-xtra-mode__item-disabled': '' 
        ]"
        :style="item.scenceResult.isTips ? {
          '--price-color': styleConfig?.[item.productCode]?.priceColor,
          '--line-price-color': styleConfig?.[item.productCode]?.linePriceColor,
        } : {
          '--price-color': styleConfig?.[item.productCode]?.priceColor,
          '--line-price-color': styleConfig?.[item.productCode]?.linePriceColor,
          '--label-bg-color': styleConfig?.[item.productCode]?.labelBgColor,
          '--label-border-color': styleConfig?.[item.productCode]?.labelBorderColor,
          '--label-color': styleConfig?.[item.productCode]?.labelColor,
        }"
        @click.stop.prevent="handleXtraItemClick($event, item, index)"
      >
        <div class="mode-item-left">
          <div class="mode-item-left__explain">
            {{ item.productBagExplain }}
          </div>
          <div class="mode-item-left__subhead">
            {{ item.productBagSubhead }}
          </div>
        </div>
        <div class="mode-item-right">
          <div class="mode-item-right__price-wrap">
            <div :class="['mode-item-right__arrive-price', { 'special-price-highlight': item.isShowSpecialPriceHighColor }]">
              {{ item.specialPrice }}
            </div>
            <del
              v-if="item.isShowDelPrice"
              class="mode-item-right__origin-price"
            >
              {{ item.originPrice }}
            </del>
          </div>
          <s-radio
            :model-value="xtraProductCodeInDrawer"
            :label="item.productCode"
            :disabled="notSupport"
            theme="icon"
            class="mode-item-right__radio-wrapper"
          />
        </div>
        <div
          v-if="item.isShowLabel"
          class="select-xtra-mode__item-tips"
          :style="{'top': GB_cssRight ? '-22px' : '-15px'}"
          @click="clickDiscountTips($event, item.productCode)"
        >
          <div :class="['triangle-border', GB_cssRight ? 'right' : '']">
            <div class="triangle"></div>
            <div class="triangle-line"></div>
          </div>
          {{ item.labelText }}
        </div>
      </div>
    </div>
    <!-- 单个产品包的选项，新旧样式都有用到 -->
    <div
      v-else-if="!isShowOptionsInDrawer"
      :class="['single-selected-wrapper', 'saver-new-style']"
      :style="tips ? {
        '--price-color': styleConfig?.[xtraProductBagList.productCode]?.priceColor,
        '--line-price-color': styleConfig?.[xtraProductBagList.productCode]?.linePriceColor,
      } : {
        '--price-color': styleConfig?.[xtraProductBagList.productCode]?.priceColor,
        '--line-price-color': styleConfig?.[xtraProductBagList.productCode]?.linePriceColor,
        '--label-bg-color': styleConfig?.[xtraProductBagList.productCode]?.labelBgColor,
        '--label-border-color': styleConfig?.[xtraProductBagList.productCode]?.labelBorderColor,
        '--label-color': styleConfig?.[xtraProductBagList.productCode]?.labelColor,
      }"
      @click.stop.prevent="handleXtraItemClick($event, xtraProductBagList)"
    >
      <s-radio
        :model-value="xtraSeleted"
        :disabled="notSupport"
        :label="true"
        theme="icon"
      />
      <div :class="['single-selected-wrapper__right', {'right-wrapper-disabled': notSupport}]">
        <span :class="['special-price', { 'special-price-highlight': xtraProductBagList.isShowSpecialPriceHighColor }]">{{ xtraProductBagList.specialPrice }}</span>
        <del
          v-if="xtraProductBagList.isShowDelPrice" 
          class="del-price" 
        >{{ xtraProductBagList.originPrice }}</del>
        <span
          v-if="xtraProductBagList.isShowLabel" 
          :class="['discount-label', {'active-label': tips}]"
          @click="clickDiscountTips($event, xtraProductBagList.productCode)"
        >
          {{ xtraProductBagList.labelText }}
        </span>
      </div>
    </div>
  </div>
</template>
  
  
<script setup>
import { computed, onMounted, nextTick, ref, watch } from 'vue'
import { template } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index.js'
import AutoPayBubble from './AutoPayBubble.vue'

import { scenceBuildStatus } from './shared/utils.js'
import { register } from 'swiper/element'
typeof window !== 'undefined' && register()
daEventCenter.addSubscriber({ modulecode: '2-43' })

const emit = defineEmits(['click-discount-tips', 'click-radio', 'dialog-change-event', 'auto-renewd-drawer-show', 'update-product-code-in-drawer', 'product-bag-click-event'])
  
const props = defineProps({
  xtraSeleted: {
    type: Boolean,
    default: false,
  },
  notSupport: {
    type: Boolean,
    default: false,
  },
  isHasAutoRenewedBag: {
    type: Boolean,
    default: false,
  },
  isShowOptionsInDrawer: {
    type: Boolean,
    default: false,
  },
  autoPaymentVisible: {
    type: Boolean,
    default: false
  },
  paymentMethodsProps: {
    type: Object,
    default: () => {}
  },
  clickXtra: {
    type: Function,
    default: () => {}
  },

  isShowAutoRenewdDubble: {
    type: Boolean,
    default: false
  },
  language: {
    type: Object,
    default: () => {}
  },
  xtraAllProductBagInfo: {
    type: Object,
    default: () => {}
  },
  usedXtraCode: {
    type: String,
    default: ''
  },
  limitedPayTypeList: {
    type: Array,
    default: () => []
  },
  bubbleText: {
    type: String,
    default: ''
  },
  logoList: {
    type: Array,
    default: () => []
  },
  xtraProductCodeInDrawer: {
    type: String,
    default: ''
  },
  styleConfig: {
    type: Object,
    default: () => {},
  },
})

const autoPaymenodsGuideRef = ref(null)
const rememberProductCode = ref('')
const bubbleDown = ref(false)

const xtraAllProductBagData = computed(() => Object.values(props.xtraAllProductBagInfo) || [])
const SiteUID = ref(gbCommonInfo?.SiteUID || '')
const GB_cssRight = ref(gbCommonInfo?.GB_cssRight || false)
  
// 当存在自动续费产品包的时候，即存在两种，获取两种包的信息
const xtraDiffProductBagData = computed(() => {
  const bagInfo = {}
  for (const item of xtraAllProductBagData.value) {
    if (!!bagInfo.normal && (props.isHasAutoRenewedBag ? !!bagInfo.autoRenewed : true) ) {
      break
    }
    if (item?.productInfo?.product_activity_type == 0 && !bagInfo.normal) {
      bagInfo.normal = item.productInfo // 普通包
    }
    if (item?.productInfo?.product_activity_type == 1 && !bagInfo.autoRenewed) {
      bagInfo.autoRenewed = item.productInfo // 自动续费产品包
    }
  }
  return bagInfo
})

const xtraAllproduct = computed(() => Object.values(props.xtraAllProductBagInfo).map(m => m.productInfo))

// 需要做合规处理的国家
const isSpecialCountry = computed(() => SiteUID.value == 'fr' || ['pl', 'de'].includes(SiteUID.value))

const normalShowDiscount = computed(() => {
  const priceInfo = xtraDiffProductBagData.value?.normal?.product_price_info
  return (priceInfo?.price_local && priceInfo?.price_local_with_symbol) && 
      (priceInfo?.special_price && priceInfo?.special_price_with_symbol) &&
      (+priceInfo?.price_local > +priceInfo?.special_price) || (!!priceInfo?.product_dues_coupon_rights?.coupon_code)
})
  
const autoRenewedDiscount = computed(() => {
  const priceInfo = xtraDiffProductBagData.value?.autoRenewed?.product_price_info
  return (priceInfo?.price_local && priceInfo?.price_local_with_symbol) && 
      (priceInfo?.special_price && priceInfo?.special_price_with_symbol) &&
      (+priceInfo?.price_local > +priceInfo?.special_price) || (!!priceInfo?.product_dues_coupon_rights?.coupon_code)
})
  
// 获取超省卡模块需要展示的数据，分为正常包（normal）、自动续费包（autoRenewed）
const xtraProductBagList = computed(() => {
  const { autoRenewed, normal } = xtraDiffProductBagData.value || {}
  const normalProductPriceInfo = normal?.product_price_info || {}
  
  const scenceResultObj = scenceBuildStatus(xtraAllproduct.value)
  const normalScence = scenceResultObj[normal?.product_code]
  const showLinePrice = props.styleConfig?.[normal?.product_code]?.showLinePrice
  const showLabel = normalScence.isTips && normalProductPriceInfo?.product_dues_coupon_rights?.discount_value_with_symbol
    ? true
    : props.styleConfig?.[normal?.product_code]?.showLabel
  // const diffNormalPrice = +normalProductPriceInfo?.special_price < +normalProductPriceInfo?.price_local
  
  const normalBagData = {
    productBagExplain: props.language?.SHEIN_KEY_PC_31078, // 产品包解释（当存在两种产品包的时候，内页会用到）
    productBagSubhead: '', // 产品包解释（当存在两种产品包的时候，内页会用到）
    productName: normal?.product_name_language, // 产品包名称
    productCode: normal?.product_code, // 产品包code
    specialPrice: normalProductPriceInfo?.special_price_with_symbol, // 产品包的到手价
    originPrice: normalProductPriceInfo?.price_local_with_symbol, // 产品包的原价（划线价）
    labelText: discountTipsText({ scenceResult: normalScence, productPriceInfo: normalProductPriceInfo, isAuto: 0 }), // 标签的文案
    isShowDelPrice: normalShowDiscount.value && showLinePrice, // 是否需要展示划线价钱
    isShowLabel: ((showLinePrice || normalScence.isTips) && 
      (props.isShowOptionsInDrawer ? props.isHasAutoRenewedBag && props.xtraProductCodeInDrawer == normal?.product_code : normalShowDiscount.value)) && showLabel, // 是否需要展示标签
    isShowSpecialPriceHighColor: isSpecialCountry.value ? false : normalShowDiscount.value, // 是否到手价需要高亮
    activityType: normal?.product_activity_type,
    scenceResult: normalScence
  }
    
  if (props.isHasAutoRenewedBag) {
    const autoRenewProductPriceInfo = autoRenewed?.product_price_info || {}
    const autoRenewScence = scenceResultObj[autoRenewed?.product_code]
    const showLinePrice = props.styleConfig?.[autoRenewed?.product_code]?.showLinePrice
    // const diffRenewedPrice = +autoRenewProductPriceInfo?.special_price < +autoRenewProductPriceInfo?.price_local

    return([
      {
        productBagExplain: props.language?.SHEIN_KEY_PC_31074,
        productBagSubhead: template(normalProductPriceInfo.subscription_price_with_symbol, props.language?.SHEIN_KEY_PC_31075),
        productName: autoRenewed?.product_name_language,
        productCode: autoRenewed?.product_code,
        specialPrice: autoRenewProductPriceInfo?.special_price_with_symbol,
        originPrice: autoRenewProductPriceInfo?.price_local_with_symbol,
        labelText: discountTipsText({ scenceResult: autoRenewScence, productPriceInfo: autoRenewProductPriceInfo, isAuto: 1, productCode: autoRenewed?.product_code }), // 标签的文案
        isShowDelPrice: autoRenewedDiscount.value && showLinePrice,
        isShowLabel: (props.isShowOptionsInDrawer ? props.isHasAutoRenewedBag && props.xtraProductCodeInDrawer == autoRenewed?.product_code : true),
        isShowSpecialPriceHighColor: isSpecialCountry.value ? false : autoRenewedDiscount.value,
        activityType: autoRenewed?.product_activity_type,
        scenceResult: autoRenewScence
      }].concat(normalBagData))
  }
  
  return normalBagData
})

// 内页两个产品包选项的高度是否相等
const isSamllFixationHeight = computed(() => {
  return props.isShowOptionsInDrawer && props.isHasAutoRenewedBag && xtraProductBagList.value?.some(_ => _.isShowDelPrice || !!_.productBagSubhead)
})
/**
 * @description: 获取标签文案
 * @param {Obejct} scenceResult
 * @param {Object} productPriceInfo 产品包的信息
 * @param {number} isAuto 1是自动续费产品包 0是非自动续费产品包
 * @return {String} 标签文案 
 */
const discountTipsText = ({ scenceResult, productPriceInfo, isAuto, productCode = '' }) => {
  const isShowLabel = props.styleConfig?.[productCode]?.showLabel
  const hideSaverStrikethroughPrice = !props.styleConfig?.[productCode]?.showLinePrice
  
  // isShowLabel 标签配置项，遇到自动续费需要让 Auto-Renew 标签常驻;
  if (isAuto == 1) {
    // 当场景是1、2，或者是需要合规处理的国家并且不能跳转到券列表
    if ((isSpecialCountry.value && !scenceResult.isTips) || (hideSaverStrikethroughPrice && !scenceResult.isTips) || [1, 2].includes(scenceResult._xtraSceneDiscount)) {
      return `${props.language?.SHEIN_KEY_PC_31089}`
    }

    // 通过场景 是否需要展示带有'>'多语言key => 点击展开会费券弹窗
    if (scenceResult.isVisible || scenceResult.isLeg) {
      return isShowLabel ? `${template(productPriceInfo?.reduce_price_with_symbol, props.language?.SHEIN_KEY_PC_31076)} >` :
      `${props.language?.SHEIN_KEY_PC_31089}`
    }
    // 场景7,8,11,14，引导用会费券提示
    if (scenceResult.isTips) {
      return `${template(productPriceInfo?.product_dues_coupon_rights?.discount_value_with_symbol, props.language?.SHEIN_KEY_PC_31077)} >`
    }
    return isShowLabel ? `${template(productPriceInfo?.reduce_price_with_symbol, props.language?.SHEIN_KEY_PC_31076)}` :
    `${props.language?.SHEIN_KEY_PC_31089}`
  }

  let tips = props.language?.SHEIN_KEY_PC_28826
  // 通过场景 是否需要展示带有'>'多语言key => 点击展开会费券弹窗
  if(scenceResult.isVisible || scenceResult.isLeg){
    tips = props.language?.SHEIN_KEY_PC_28826 + ' >'
  }
  // 场景8,11,14，引导用会费券提示
  if(scenceResult.isTips){
    daEventCenter.triggerNotice({
      daId: '2-43-15',
      extraData: {
        type: 'saver',
        position: 'page'
      }
    })
    return `${template(productPriceInfo?.product_dues_coupon_rights?.discount_value_with_symbol, props.language?.SHEIN_KEY_PC_28827)} >`
  }
  return `${template(productPriceInfo?.reduce_price_with_symbol, tips)}`
}
  
/**
   * @description: 点击超省卡模块的标签
   * @param {*} event
   * @return {*}
   */
const clickDiscountTips = (event, productCode) => {
  event.stopPropagation()
  if (props.isShowOptionsInDrawer && props.isHasAutoRenewedBag) {
    emit('dialog-change-event', event, productCode)
  } else {
    emit('click-discount-tips', event, productCode)
  }
}
  
// 包选中事件、更新产品包信息
// const handleXtraSeleted = (item) => {

// }
/**
   * @description: 如果是存在自动续费产品包的时候，超省卡单选项就会有两个，处理选项的点击
   * @return {*}
   */
const handleXtraItemClick = (event, item, index, type = 'init') => {
  if (props.notSupport) {
    return
  }
  if(type == 'selectSubmit' && props.isShowAutoRenewdDubble && item?.activityType == 1){
    emit('auto-renewd-drawer-show', item.productCode, false)
    rememberProductCode.value = item.productCode
    return
  }

  if (!props.isShowOptionsInDrawer) {
    if (props.usedXtraCode != item.productCode) {
      const selectProductInfo = xtraAllProductBagData.value?.find(i => i?.productInfo?.product_code == item.productCode) || {}
      const coreInfo = {
        selectProductInfo,
      }
      emit('product-bag-click-event', coreInfo)
      emit('click-radio', { xtraProductCodeInDrawer: coreInfo.selectProductInfo.productInfo.product_code })
    } else {
      const coreInfo = {
        usedXtraCode: !!props.usedXtraCode && item.productCode == props.usedXtraCode ? '' : item.productCode
      }
      emit('product-bag-click-event', coreInfo)
      emit('click-radio', { xtraProductCodeInDrawer: coreInfo.usedXtraCode })
    }
    // 半弹窗的点击事件
  } else if(props.isShowOptionsInDrawer) {
    // if (props.xtraProductCodeInDrawer != item.productCode) {
    //   const selectProductInfo = xtraAllProductBagData.value?.find(i => i?.productInfo?.product_code == item.productCode) || {}
    //   const coreInfo = {
    //     selectProductInfo,
    //   }
    // emit('product-bag-click-event', coreInfo)
    // }
    emit('update-product-code-in-drawer',  !!props.xtraProductCodeInDrawer && item.productCode == props.xtraProductCodeInDrawer ? '' : item.productCode)
  }
}
  
/**
   * @description: 初始化swiper，如果不初始化，增加初始化属性，会导致滑动计算的时候，出现不符合预期的问题
   * @return {*}
   */
const initSwiper = () => {
  if (props.isHasAutoRenewedBag) {
    const xtraMutipleRadioSelectSwiper = document.querySelector('.j-mutiple-selected-radio')
    if (xtraMutipleRadioSelectSwiper) {
      Object.assign(xtraMutipleRadioSelectSwiper, {
        direction: 'horizontal',
        slidesPerView: 'auto',
        injectStyles: [
          `.swiper{
            overflow: visible;
          }`,
        ],
      })
      xtraMutipleRadioSelectSwiper.initialize()
    }
  }
}
const autoRenewdDrawerShow = (productCode, isOnCouponDrawer) => {
  emit('auto-renewd-drawer-show', productCode, isOnCouponDrawer)
}
onMounted(() => {
  nextTick(() => {
    initSwiper()
  })
})

const tips1 = ref(false)
const tips2 = ref(false)
const tips = ref(false)
watch(() => xtraProductBagList, (val) => {
  if (val.value?.length > 0) {
    tips1.value = val.value[0].scenceResult.isTips
    tips2.value = val.value[1].scenceResult.isTips
  } else {
    tips.value = val.value?.scenceResult?.isTips
  }
}, { deep: true })
</script>
  
<style lang="less" scoped>
.xtra-radio-selected {
  position: relative;
  line-height: normal;
  .special-price {
    margin-right: 4px;
    color: @sui_color_gray_dark1;
    font-size: 16px;
    font-weight: 700;
  }
  .special-price-highlight {
    color: var(--price-color, #F82854);
  }
  .del-price {
    margin-right: 8px;
    color: var(--line-price-color, #959595);
    font-size: 12px;
  }
  .discount-label {
    padding: 2px 6px;
    border-radius: 6px 0px;
    color: var(--label-color, #FFF);
    font-size: 10px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: 1px solid;
    border-color: var(--label-border-color);
    background: var(--label-bg-color, linear-gradient(270deg, #F82854 0%, #DE00F1 100%));
  }
  .active-label {
    background: #FEE8ED;
    border: 1px solid #F74D81;
    color: #EB3662;
  }
  .right-wrapper-disabled {
    opacity: 0.3;
  }
  .mutiple-selected-wrapper {
    margin-right: -12px;
    overflow-x: clip;
  }
  .mutiple-selected-swipper {
    margin: 6px 0 0 0;
    margin-right: -12px;
    .item-selected {
      display: flex;
      align-items: flex-start;
      max-width: 233px;
      /* stylelint-disable-next-line declaration-no-important */
      width: auto !important; // swiper需要定义一个宽度，覆盖原来swiper单项的宽度
      position: relative;
      margin-right: 24px;
      &__right-wrapper-disabled {
        opacity: 0.3;
      }
      &__right-price-wrapper {
        height: 24px;
        line-height: 24px;
        max-width: 205px;
      }
      &__radio-wrapper {
        padding-top: 2px;
        margin-right: 0;
      }
    }
    .discount-label {
      max-width: 205px;
    }
    /* stylelint-disable-next-line selector-max-specificity */
    :deep(.item-selected__radio-wrapper > .sui-radio__check) {
      margin-right: 6px;
    }
  }
  .single-selected-wrapper {
    display: flex;
    &__right {
      display: flex;
      align-items: center;
    }
  }
  .saver-new-style {
    margin-top: 16px;
  }
  .select-xtra-mode {
    margin-bottom: 8px;
    line-height: normal;
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding: 8px 12px;
      border-radius: 4px;
      border: 1px solid var(---sui_color_gray_light3, #CCC);
      margin-bottom: 8px;
      background: #FFF;
    }
    & > :last-child {
      margin-bottom: 0;
    }
    &__selected-item {
      border: 1px solid #F82854;
      background: #FFEEF3;
    }
    &__item-disabled {
      opacity: 0.3;
    }
    &__item-fixation-height {
      height: 53px;
    }
    &__item-tips {
      position: absolute;
      right: 0;
      top: -15px;
      border-radius: 4px;
      border: 1px solid #F7B432;
      padding: 4px 6px;
      text-align: center;
      font-size: 12px;
      font-weight: 600;
      background: var(--label-bg-color, #FFF1BE);
      border-color: var(--label-border-color, #F7B432);
      color: var(--label-color, #421609);

      .triangle-border {
        position: absolute;
        width: 100%;
        height: 120%;
        top: 0;
        left: 0;
        z-index: 0;

        /* stylelint-disable-next-line selector-max-specificity */
        &.right {
          left: calc(100% - 40px);
        }
      }
      .triangle-line {
        position: absolute;
        top: 2/75rem;
        width: 100%;
        height: 100%;
        background: var(--label-border-color, #F7B432);
        clip-path: polygon(calc(100% - 26px) 83%, calc(100% - 14px) 83%, calc(100% - 20px) calc(100% + 1px), calc(100% - 20px) calc(100% + 1px));
        z-index: -1;
      }
      .triangle {
        width: 0;
        height: 0;
        width: 100%;
        height: 100%;
        background: var(--label-bg-color, #FFF1BE);
        clip-path: polygon(calc(100% - 25px) 82%, calc(100% - 15px) 82%, calc(100% - 20px) 100%, calc(100% - 20px) 100%);
      }
    }
  }
  .mode-item-left {
    text-align: left;
    margin-right: 13px;
    &__explain {
      color: #111;
      font-size: 16px;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &__subhead {
      margin-top: 2px;
      color: var(---sui_color_gray_dark3, #767676);
      font-size: 10px;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .mode-item-right {
    display: flex;
    align-items: center;
    &__price-wrap {
      text-align: left;
      margin-right: 10px;
    }
    &__arrive-price {
      font-size: 18px;
      font-weight: 700;
      color: var(--price-color, #f82854)
    }
    &__origin-price {
      margin-top: 2px;
      font-size: 12px;
      color: var(--line-price-color, #959595);
    }
    &__radio-wrapper {
      margin-right: 0;
    }
      /* stylelint-disable-next-line selector-max-specificity */
      :deep(.sui-radio__check) {
        margin-right: 0px;
      }
  }
}
/* stylelint-disable-next-line selector-class-pattern */
.j-mutiple-selected-radio::part(container){
  /* stylelint-disable-next-line declaration-no-important */
  overflow: visible !important;
}

.container_content{
  display: flex;
  width: auto;
  }
</style>

