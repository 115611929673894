//@ts-ignore
import { DaEventCenter, createSaDirectives } from '@shein-aidc/basis-sa-event-center'
import { useAppConfigs, type Libs } from '@shein-aidc/bs-sdk-libs-manager'
const appConfigs = useAppConfigs()

class LibsDaEventCenter extends DaEventCenter {
  declare saDirectives: { tap: any; expose: any }
  declare addSubscriberObject: any
  declare triggerNotice: any
  declare getExposeInstance: any

  constructor() {
    super('initial')
  }

  public addSubscribe<T extends string>(origin: Libs.AnalysisFuncs<T>, input?: Libs.AnalysisSourceInterface<T>): Libs.AnalysisInstance<T> {
    if (input && Object.keys(input).length && !input?.eId) {
      console.warn('eId missing')
    }
    if (!input || !input.eId) {
      this.addSubscriberObject(origin)
      return this._createAnalysisIns()
    }

    const configs = (Object.keys(origin) as T[]).reduce((curr, next) => {
      const eventName = `${next}.${input.eId}`
      const dealingFunc = input.overrides?.[next] || origin[next]
      const beforeSend = input.beforeSend?.[next]

      if (beforeSend || input.handler) {
        curr[eventName] = async function (...args: any[]) {
          // @ts-ignore
          let result = await dealingFunc(...args)
          result = beforeSend ? await beforeSend(result) : result

          if (input.handler) {
            input.handler(result)
            return null
          }
          return result
        }
      } else {
        curr[eventName] = dealingFunc
      }

      return curr
    }, {} as Libs.AnalysisFuncs<T>)

    this.addSubscriberObject(configs)
    return this._createAnalysisIns(input.eId)
  }

  private _createAnalysisIns<T extends string>(eId?: Libs.AnalysisSourceInterface<T>['eId']): Libs.AnalysisInstance<T> {
    if (!eId) {
      if (!this.saDirectives) {
        this.saDirectives = createSaDirectives(this, { vueVersion: appConfigs.vueVersion })
      }
      return {
        eId: undefined,
        vTap: this.saDirectives.tap,
        vExpose: this.saDirectives.expose,
        triggerNotice: this.triggerNotice.bind(this),
        getExposeInstance: this.getExposeInstance.bind(this),
      }
    }

    const { tap: vTap, expose: vExpose } = createSaDirectives(this, { vueVersion: appConfigs.vueVersion, eId })
    return {
      eId,
      vTap,
      vExpose,
      triggerNotice: (params) => {
        this.triggerNotice({
          ...params,
          id: `${params?.id}.${eId}`,
        })
      },
      getExposeInstance: this.getExposeInstance.bind(this),
    }
  }
}

const libsDaEventCenter: LibsDaEventCenter =
  typeof window !== 'undefined'
    ? // @ts-ignore
    window.__LibsDaEventCenter__ || (window.__LibsDaEventCenter__ = new LibsDaEventCenter())
    : null

export function addSubscribe<T extends string>(origin: Libs.AnalysisFuncs<T>, input?: Libs.AnalysisSourceInterface<T>): Libs.AnalysisInstance<T> {
  if (libsDaEventCenter) {
    return libsDaEventCenter.addSubscribe(origin, input)
  } else {
    return {
      eId: undefined,
      vTap: {} as any,
      vExpose: {} as any,
      triggerNotice() { },
      getExposeInstance() { },
    }
  }
}
