<script setup>
import { onMounted, computed } from 'vue'
import { useCancelOrderTip } from './utils/useCancelOrderTip'
import CancelOrderDrawer from './CancelOrderDrawer.vue'
import { exposeCancelledTipReport } from './utils/analysis.js'

// hooks
const {
  refDrawer,
  isOpen,
  data,
  language,
  popupInfo,
  isShow,
  isShowIncentive,
  goodsImg,
  goodsNum,
  titleText,
  btnText,
  incentiveIcon,
  incentiveText,
  isPlatform,
  getData,
  handleToggleDrawer,
  handleAddCartSuccess,
  handleRefresh,
  handleAddMoreSuccess,
} = useCancelOrderTip()

// data
const maskGoodsNum = computed(() => goodsNum.value - 3)
const isShowGoodsMask = computed(() => goodsNum.value >= 5)

// methods
onMounted(() => {
  getData()
})
</script>

<template>
  <div>
    <div
      v-if="isShow"
      v-expose="exposeCancelledTipReport()"
      class="empty-cancel-order"
      @click="handleToggleDrawer"
    >
      <div class="empty-cancel-order__tip">
        <div class="empty-cancel-order__title">
          {{ titleText }}
        </div>
        <div
          v-if="isShowIncentive"
          class="empty-cancel-order__incentive"
        >
          <img :src="incentiveIcon" />
          <p class="text">
            <span>
              {{ incentiveText }}
            </span>
          </p>
        </div>
      </div>
      <div class="empty-cancel-order__bottom">
        <div class="empty-cancel-order__img">
          <div 
            v-for="(img, index) in goodsImg.slice(0, 4)"
            :key="index"
            class="img"
          >
            <img :src="img" />
          </div>
          <div
            v-if="isShowGoodsMask"
            class="mask"
          >
            {{ maskGoodsNum > 99 ? '99+' : `+${maskGoodsNum}` }}
          </div>
        </div>
        <div class="empty-cancel-order__btn">
          {{ btnText }}
        </div>
      </div>
    </div>
    <CancelOrderDrawer
      ref="refDrawer"
      v-model:visible="isOpen"
      :order-id="data.billno"
      :data="popupInfo"
      :language="language"
      :is-platform="isPlatform"
      @add-cart-success="handleAddCartSuccess"
      @add-more-success="handleAddMoreSuccess"
      @refresh-data="handleRefresh"
    />
  </div>
</template>

<style lang="less" scoped>
.empty-cancel-order {
  width: 351px;
  padding: 10px;
  background-color: #F6F6F6;
  cursor: pointer;
  &__tip {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
  }
  &__title {
    font-size: 14px;
    line-height: 17px;
    color: #000;
    font-weight: 700;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 8px;
    max-width: 220px;
    min-width: 122px;
  }
  &__incentive {
    display: flex;
    align-items: center;
    margin-top: 2px;
    height: 14px;
    color: #FA6338;
    img {
      flex-shrink: 0;
      width: 12px;
      height: 12px;
    }
    .text {
      margin-left: 2px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 12px;
    }
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__img {
    display: flex;
    gap: 6px;
    position: relative;
    .img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 54px;
      height: 54px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .mask {
      position: absolute;
      right: 0;
      right: 0;
      top: 0;
      width: 54px;
      height: 54px;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.5);
    }
  }
  &__btn {
    padding: 0 8px;
    line-height: 24px;
    text-align: center;
    margin-right: 21px;
    width: 56px;
    height: 24px;
    border: 0.5px solid #222;
    border-radius: 12px;
    font-size: 11px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 590;
  }
}
</style>
