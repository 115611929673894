import { ref, onMounted } from 'vue'
import { getLocalStorage } from '@shein/common-function'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import { cartTagTip, useCartNum } from '@shein-aidc/bs-sdk-cart-tag-tip'
import { useProxyHosts } from '@shein-aidc/bs-sdk-libs-manager'

export const useCartTagTip = ({ isNewCartEntrance }) => {
  const autoUseCoupon = ref(false)

  const { update: updateCartNum } = useCartNum()

  const getAbt = async () => {
    try {
      const { Cartshowcoupon } = await getUserAbtData()
      autoUseCoupon.value = Cartshowcoupon?.param?.cart_show_coupon_switch === 'on'
    } catch (error) {
      // ...
    }
  }
  
  const initialize = () => {
    cartTagTip.initialize({
      disabledFirstTimeTip: true,
      disabledScroolEventListener: true,
      disabledCache: true,
      disabledTagTip: !isNewCartEntrance,
      autoUseCoupon: autoUseCoupon.value,
      getCountryId() {
        const addressCookie = getLocalStorage('addressCookie')
        let countryId = ''
        try {
          const addressJson = typeof addressCookie === 'string' ?  JSON.parse(addressCookie) : addressCookie
          countryId = addressJson.countryId
        } catch (err) {
          //
        }
        return countryId
      },
    })
  }

  const updateCartTagTip = (config) => {
    cartTagTip.update(config)
  }

  const updateCartData = (data) => {
    useProxyHosts().CommonHeader?.trigger('updateCart', data)
  }

  const initCartModule = () => {
    if (!window?.cart_module) {
      window.cart_module = {}
    }
    if (!window.cart_module.reload) {
      window.cart_module.reload = (type, callback) => {
        if (isNewCartEntrance) {
          updateCartTagTip({
            needUpdateCartNum: true,
          })
        } else {
          cartTagTip.updateCartNum()
        }
        const miniCartCallback = () => {
          const res = window.cart_module.cartVue.res || {}
          callback && callback(res)
        }
        updateCartData({ showMiniCart: type == 1 && !isNewCartEntrance, miniCartCallback, showMiniCartOptions: { autoHide: true, sceneFrom: 'added_success' } })
      }
    }
    if (!window.cart_module.recart) {
      window.cart_module.recart = (res, notShowMiniCart = false) => {
        if (res.code == 0) {
          let cartInfo = res.info
          // 部分加车场景，res 多了一个 info 层级
          if (cartInfo?.info) cartInfo = cartInfo.info

          if (isNewCartEntrance) {
            updateCartTagTip({
              needUpdateCartNum: true,
              cartInfo: cartInfo,
            })
          } else {
            updateCartNum({ num: cartInfo.cartSumQuantity })
          }

          updateCartData({ miniCartRes: res, showMiniCart: !notShowMiniCart && !isNewCartEntrance, showMiniCartOptions: { autoHide: true, sceneFrom: 'added_success' } })
        }
      }
    }
    if (!window.cart_module.analysis) {
      window.cart_module.analysis = {
        async checkout ({ val = 1, carts = [], msg = '' }) {
          if (typeof sa == 'function') {
            let goods_list = ''
            let activity_param = {}
            let _reason = ''
    
            if (msg == 'sold out') {
              _reason = 2
            } else if (msg == 'Not login') {
              _reason = 1
            }
    
            goods_list = carts.reduce((result, item) => {
              const goods_id = item.attr.goods_id || ''
              const attr_value_id = item.attr.attr_value_id || ''
              const quantity = item.quantity || ''
              result.push(`${goods_id}\`${attr_value_id}\`${quantity}`)
              return result
            }, []).join(',')
    
            activity_param = {
              goods_list,
              result_mini: val ? 1 : 2,
              result_reason: _reason,
              // abtest: `${poskey || '-'}\`${expid || ''}\`${id || '-'}`
            }
            sa('send', {
              activity_name: 'click_checkout',
              activity_param: activity_param
            })
          }
        },
      }
    }
  }

  onMounted(async () => {
    initCartModule()

    await getAbt()

    initialize()
  })

  return {
    autoUseCoupon,
    isNewCartEntrance,
  }
}
