const robotLinkCommon = (SaPageInfo, name = 'navigationBar', options = {}) => {
  let params = ''

  const robotStoreData = window?.gbCommonInfo?.robotStoreData || null
  if (robotStoreData) {
    let { storeCode = '', pageName = '', storeType, business_model, skc } = robotStoreData
    if (name != 'NoLoginPage') name = pageName ? pageName : 'storePage'
    params = `&storecode=${storeCode}&business=${typeof business_model != 'undefined' ? business_model : (storeType == 1 ? 1 : 0)}`
    if(options?.toSeller && skc) params += `&skc=${skc}`
  }

  if (options?.billno) params += `&billno=${options?.billno}`

  const page_name = SaPageInfo?.page_name || 'page_others'
  if (page_name === 'page_order_list') name = 'orderListPage'
  if (page_name === 'page_order_detail') name = 'orderDetailPage'
  if (page_name === 'page_contact_us') name = 'supportPage'
  if (page_name === 'page_order_track') name = 'trackPage'
  const uri = options?.toSeller ? `/robot/business?page=${name}&page_name=${page_name}${params}` : `/robot?page=${name}&page_name=${page_name}${params}`
  return {
    uri,
    name,
    page_name,
    params,
  }
}

const getOrderJumpInfo = (SaPageInfo, toSeller = false) => {
  let { page_name, page_param = {} } = SaPageInfo
  let isOrderModule = ['page_order_list', 'page_order_detail', 'page_order_track']?.includes(page_name)
  if (isOrderModule) {
    let textPageMap = {
      'page_order_list': {
        'name': 'orderListPage',
        'billno': window?.ORDER_LIST?.orders?.[0]?.billno || '',
      },
      'page_order_detail': {
        'name': 'orderDetailPage',
        'billno': window?.ORDER_DETAIL?.order?.billno || '',
      },
      'page_order_track': {
        'name': 'trackPage',
        'billno': page_param?.order_no || '',
      },
    }
    let { name, billno } = textPageMap?.[page_name] || {}
    window.open(robotLinkCommon(SaPageInfo, name, { billno, toSeller }).uri)
  } else {
    window.open(robotLinkCommon(SaPageInfo, 'navigationBar', { toSeller }).uri)
  }
}

export {
  robotLinkCommon,
  getOrderJumpInfo,
}
