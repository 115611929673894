import { getSessionStorage, setSessionStorage } from '@shein/common-function'

// 以下case
// 1.首页 => 列表 => 首页 =》 end  无法知道是返回操作还是输入地址操作
// 2.整个会话没有关闭 会一直存储下去。


class RouterControl {
  #keyLocal = `s_router_history_data`

  constructor() {
    this.toData = {}
    this.fromData = {}
  }

  init() {
    const locationSearch = new URLSearchParams(window.location.search)
    const query = {}
    locationSearch.forEach((value, key) => {
      query[key] = value
    })

    this.toData = {
      historyLength: window.history.length,
      path: window.location.pathname,
      query,
    }
    try {
      const fromData = getSessionStorage(this.#keyLocal) || {}
      const historyLength = fromData?.historyLength || 0
      if (historyLength < this.toData.historyLength - 1 || fromData?.path === this.toData?.path) {
        this.fromData = {} // 此时是打开了新窗口, 不应该算来源页面了 || 刷新页面
      } else {
        this.fromData = Object.assign({}, fromData)
      }
    } catch (e) {
      //
    }
   
    this.updateSessionStorage()
    this.addEvent()
    this.handleTrigger()
  }

  addEvent() {
    // window.addEventListener('popstate', () => {
    //   debugger
    // })
  }

  updateSessionStorage() {
    setSessionStorage({
      key: this.#keyLocal,
      value: this.toData
    })
  }

  handleTrigger() {
    if (this.handler) {
      this.handler(this.toData, this.fromData)
    }
  }

  registerHandler(handler) {
    this.handler = handler
  }
}

export default RouterControl
