const enImg = 'https://shein.ltwebstatic.com/svgicons/2024/12/12/1733973213154820092.svg'
export default {
  default: 'https://shein.ltwebstatic.com/svgicons/2024/12/12/17339895931838889472.svg',
  ar: 'https://shein.ltwebstatic.com/svgicons/2024/12/30/17355397532123035891.svg',
  en: enImg,
  de: enImg,
  es: enImg,
  fr: 'https://shein.ltwebstatic.com/svgicons/2024/12/12/17339732132501636630.svg',
  it: 'https://shein.ltwebstatic.com/svgicons/2024/12/12/17339732131586500991.svg',
  'pt-pt': 'https://shein.ltwebstatic.com/svgicons/2024/12/12/17339732131446941901.svg',
  'pt-br': 'https://shein.ltwebstatic.com/svgicons/2024/12/12/17339732131446941901.svg',
  pl: 'https://shein.ltwebstatic.com/svgicons/2024/12/12/17339732134066346901.svg',
  ja: 'https://shein.ltwebstatic.com/svgicons/2024/12/12/17339732133000626866.svg',
  'el-gr': 'https://shein.ltwebstatic.com/svgicons/2024/12/12/17339732131256960141.svg',
  'cs-cz': 'https://shein.ltwebstatic.com/svgicons/2024/12/12/17339732134251474928.svg',
  'ro': 'https://shein.ltwebstatic.com/svgicons/2024/12/12/17339732131773702315.svg',
  'es-mx': enImg,
  bg: 'https://shein.ltwebstatic.com/svgicons/2024/12/12/17339732132709217183.svg',
  sk: 'https://shein.ltwebstatic.com/svgicons/2024/12/12/17339732133323266046.svg',
  hu: 'https://shein.ltwebstatic.com/svgicons/2024/12/12/1733973213375334053.svg',
  nl: enImg,
  sv: 'https://shein.ltwebstatic.com/svgicons/2024/12/26/17351849941177572961.svg',
  th: 'https://shein.ltwebstatic.com/svgicons/2024/12/27/1735280604374455917.svg',
  he: 'https://shein.ltwebstatic.com/svgicons/2024/12/12/173398907380548591.svg',
  'zh-tw': enImg,
  'ko': enImg,
  'ru': enImg,
}
