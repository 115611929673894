/**
 * type:
 *  freeshipping: 免邮
 *  save: 优惠
 *  gift: 赠品
 *  mark_down: 加车后降价
 *  oos: 低库存
 *  flash_sale: 闪购
 *  coupon: 最优券
 *
 * freeShippingType:
 *  activity: 免邮活动
 *  coupon: 免邮券
 */
import { TagTip, LurePoint } from '../types'

const normalType = ['freeshipping', 'save', 'gift']

const TypeMap = {
  freeshipping: 'freeshipping',
  save: 'save',
  gift: 'gift',
  mark_down: 'lowerafteradd',
  oos: 'lowstock',
  flash_sale: 'flash',
  coupon: 'cartcoupon',
  order_return_coupon: 'returncoupon',
}

const isNormalType = (type: string) => normalType.includes(type)

const getTagByLabel = (label: LurePoint): boolean | string | null => {
  if (!label) return false

  if (label.type === 'save') {
    if (label.text && label.text.length > 7) return 'SAVE'
    return label.text
  }
  return isNormalType(label.type)
}

const getTagTextByLabel = (label: LurePoint): boolean | string | null => {
  if (!label) return false

  if (label.type === 'save') {
    return label.text
  }
  return isNormalType(label.type)
}

const getTipsByBubble = (bubble: LurePoint): TagTip.Step | string | null => {
  if (bubble.lurePointItem) {
    return {
      tag: false,
      tagText: '',
      type: TypeMap[bubble.type],
      realType: bubble.type,
      totalPrice: '',
      category: null,
      tip: getTipByBubble(bubble),
      tips: bubble.lurePointItem.bubble?.clickText,
      isPopover: true,

      clickBtnText: bubble.lurePointItem.bubble?.clickBtnText,
      goods: bubble.lurePointItem.bubble?.goods,
      goodsNum: bubble.lurePointItem.bubble?.goodsNum,
      cartIds: bubble.lurePointItem.cartIds,

      iconText: bubble.lurePointItem.bubble?.iconText,
      iconTextOff: bubble.lurePointItem.bubble?.iconTextOff,

      lurePointItemType: bubble.lurePointItem.type,
      lurePointItemHasChecked: bubble.lurePointItem.hasChecked,
      couponNum: bubble.lurePointItem.bubble?.couponNum,
      identify: bubble.lurePointItem.identify,
    }
  } else {
    return getTipByBubble(bubble)
  }
}

const getTipByBubble = (bubble: LurePoint) => {
  let text = bubble.htmlText || ''
  if (!text) {
    text = bubble.text || ''
    if (bubble.desc) {
      if (text) text += '<br/>'
      text += bubble.desc
    }
  }
  return text
}

const getTipsByLabel = (label: LurePoint, bubble?: LurePoint): TagTip.Step | string | null => {
  if (bubble && label.type === bubble.type) {
    return getTipsByBubble(bubble)
  }
  return ''
}

const getTipByLabel = (label: LurePoint, bubble?: LurePoint): string => {
  if (bubble && label.type === bubble.type) {
    return getTipByBubble(bubble)
  }
  return ''
}

export const getStepsByApiData: (data: { newLabel?: LurePoint, showLabel?: LurePoint, bubble?: LurePoint }, totalPrice?: { amountWithSymbol }) => TagTip.Step[] = (data, totalPrice) => {
  if (!data) return []

  const { newLabel, showLabel, bubble } = data
  const steps: TagTip.Step[] = []

  // 高-低-高
  if (
    newLabel?.type &&
    showLabel?.type &&
    newLabel.type !== showLabel.type &&
    isNormalType(newLabel.type)
  ) {
    steps.push({
      tag: getTagByLabel(newLabel),
      tagText: getTagTextByLabel(newLabel),
      totalPrice: totalPrice?.amountWithSymbol,
      type: TypeMap[newLabel.type],
      realType: newLabel.type,
      category: newLabel.freeShippingType,
      lurePointItemType: newLabel.lurePointItem?.type,
      lurePointItemHasChecked: newLabel.lurePointItem?.hasChecked,
      couponNum: newLabel.lurePointItem?.bubble?.couponNum,
      identify: newLabel.lurePointItem?.identify,
      tip: getTipByLabel(newLabel, bubble),
      tips: getTipsByLabel(newLabel, bubble),
    })
  }

  let tip: string | null = ''
  let tips: TagTip.Step | string | null = ''

  if (showLabel?.type || newLabel?.type || bubble?.type) {
    const label = showLabel || newLabel

    // 有气泡
    if (!steps.length && bubble?.type) {
      tip = getTipByBubble(bubble)
      tips = getTipsByBubble(bubble)
    }

    if (label) {
      // 无-有，低到高
      steps.push({
        tag: getTagByLabel(label),
        tagText: getTagTextByLabel(label),
        totalPrice: totalPrice?.amountWithSymbol,
        type: TypeMap[label.type],
        realType: label.type,
        category: label.freeShippingType,
        lurePointItemType: label.lurePointItem?.type,
        lurePointItemHasChecked: label.lurePointItem?.hasChecked,
        couponNum: label.lurePointItem?.bubble.couponNum,
        identify: label.lurePointItem?.identify,
        tip,
        tips,
      })
    } else if (tips && typeof tips === 'object') {
      steps.push({
        ...tips,
        totalPrice: totalPrice?.amountWithSymbol,
      })
    }
  }

  if (!steps.length) {
    return [
      {
        tag: false,
        tagText: '',
        totalPrice: totalPrice?.amountWithSymbol,
        type: '',
        realType: '',
        category: null,
        tip,
        tips,
        noTips: true,
        excludeScroll: false,
      },
    ]
  }

  return steps
}
