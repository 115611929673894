import { isLogin } from 'public/src/pages/common/utils/index.js'
import { batchAddToCart, batchBindCoupon, batchCheckGoodsStockDataApi } from 'public/src/pages/detail/main/services/index.js'
import {
  template,
  getCookie,
} from '@shein/common-function'
import { onMounted, ref } from 'vue'

export function isAmountEmpty(amount) {
  return !amount || amount === '0.00' || Number(amount) === 0
}

/**
 * 获取套装价 BFF
 * @returns 
 */
export const getCalcBundlePrice = (bffInfo) => {

  const res = {
    // amount: 0,
    amountWithSymbol: '0.00',
    /**
     * 折扣率
     */
    discount: 0,
    /**
     * 价差
     */
    // discountAmount: 0,
    discountAmountWithSymbol: '',
    /**
     * 原价
     */
    retailAmountWithSymbol: '',
    /**
     * 券列表，包含AB伪造券
     */
    couponList: [],

    /**
     * 展示的Activity价差
     */
    activityAmountWithSymbol: '',
    /**
     * 倒计时结束时间
     */
    couponEndDate: 0
  }

  const { bundlePrice, bundleRetailPrice, bundleSavedPrice, bundleUnitDiscount, bundlePromotionDetail } = bffInfo || {}


  if (!bundlePrice || Object.keys(bundlePrice).length === 0) return res

  const _getValidAmout = (d) => {
    return !isAmountEmpty(d?.amount) ? d?.amountWithSymbol : ''
  }
  const _getFormatCouponList = (list = []) => {
    return (list || []).map(d => {
      return {
        ...d,
        isBind: d.isBind, // 是否是绑定类型,0-否，1-是
        couponCode: d.couponCode, // 券码
        bindStatus: d.bindStatus, // 绑定状态 '1' 已绑定 '0' 未绑定
        _couponName: d.couponDiscountMultiLang,
        _discountAmountWithSymbol: d.couponDiscountPrice?.amountWithSymbol,
      }
    })
  }

  res.amountWithSymbol = _getValidAmout(bundlePrice) || '0.00',
  res.discount = Number(bundleUnitDiscount)
  res.discountAmountWithSymbol = _getValidAmout(bundleSavedPrice),
  res.retailAmountWithSymbol = _getValidAmout(bundleRetailPrice)
  res.activityAmountWithSymbol = _getValidAmout(bundlePromotionDetail?.specialPrice),
  res.couponEndDate = bundlePromotionDetail?.countdownEndTime || 0 // 倒计时结束时间，10位时间戳
  res.couponList = _getFormatCouponList(bundlePromotionDetail?.bundleCouponInfos) || []
  return res
}

/**
 * 批量加车后领券
 */
export async function fetchBatchBindCoupon(coupon_code) {
  try {
    if (!isLogin()) return
    if (!coupon_code.length) return
    const res = await batchBindCoupon({ coupon_code })
    const failure =
      !res ||
      res.code != 0 ||
      !res.info?.successList?.find?.(item => coupon_code.includes(item.couponCode))

    return failure ? false : true
  } catch(e) {
    return false
  }
}

/**
 * 检查是否符合加车条件
 */
export const checkConditionsOfAddToCart = async (products = [], languageText = {}) => {

  const res = {
    status: false,
    msg: '',            // 埋点使用
    toastMsg: '',            // 埋点使用
    isSoldOutList: [],
    isNoSkuList: [],
  }

  // 是否购选
  if (!products.length) {
    res.toastMsg = languageText.noSelectText || 'not select'
    res.msg = 'Please select size'
    return res
  }

  // 检验sku 
  products.forEach(item => {
    /**
     * 未选择sku不能加车
     */
    if (!item.sku_code) {
      res.isNoSkuList.push(item)
    }
  })

  if (res.isNoSkuList.length) {
    res.msg = 'Please select size'
    res.toastMsg = languageText.noSkuText || 'not sku'
    return res
  }

  // 检验库存
  try {
    const stockCheckParams = products?.map(d => {
      return {
        goodsId: d.goods_id,
        goodsSn: d.goods_sn,
        mallCode: d.mall_code,
        skuCode: d.sku_code,
      }
    })
    const stockRes = await batchCheckGoodsStockDataApi(stockCheckParams)
    const productDetailData = stockRes?.info?.productDetailData || []
    productDetailData.forEach(item => {
      /** 
       * 售罄不能加车
       */
      if (+item.isOnSale !== 1 || +item.stock === 0) {
        res.isSoldOutList.push(item)
      }
    })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('🚀 ~ checkConditionsOfAddToCart ~ stockRes:', error)
  }

  // 提示
  if (res.isSoldOutList.length) {
    res.msg = 'Goods sold out'
    res.toastMsg = languageText.soldOutText || 'sold out'
  }

  res.status = res.isNoSkuList.length === 0 && res.isSoldOutList.length === 0
  return res
}

/**
 * 批量加车
 */
export const fetchBatchAddToCart = async (list = []) => {
  const product_list = list
    .map(d => ({
      mall_code: d.mall_code,
      sku_code: d.sku_code,
      quantity: 1
    }))
    .filter(d => d.sku_code)
  if (!product_list.length) return
  const result = await batchAddToCart({ product_list })
  return result
}

/**
 * 加车失败提示
 */
export const getFormatBatchAddErrorResult = (data = {}, languageText = {}) => {
  const { code, info = {}, tips } = data

  let _errorTips = ''
  if (code == 0) {
    /**
     * 加车成功
     */
    return data
  } else {
    const limitCount = info?.limitCount || ''
    if (code == '300402') {
      _errorTips = languageText.outOfStockText || 'Out of Stock'
    } else if (['200401', '500301'].includes(code)) {
      _errorTips = languageText.limitQuantityText || 'limit quantity'
    } else if (['500306', '500302', '300417', '500303'].includes(code)) {
      _errorTips = template(
        limitCount,
        info?.resultLimit || 0,
        info?.remainNum || 0,
        tips
      )
    } else if (code == '300407') {
      _errorTips = tips
    } else {
      _errorTips = languageText.errorText || 'error'
    }
    return {
      _errorTips
    }
  }
}

const sendAddToCartEvent = ({
  from,
  result,
  item = {},
  click_id,
  location,
  result_reason,
  tspABTest = '',
  series_no = ''
}) => {
  const extraData = {
    code: 'goodsDetail',
    result,
    postData: {
      goods_id: item.goods_id || '',
      sku: item.goods_sn || '',
      spu: item.productRelationID || '',
      sku_code: item?._cartInfo?.skuCode || '',
      mallCode: item.mall_code || '',
      location,
      click_id,
      series_no,
      quickShip: item.quickship || ''
    },
    from,
    review_location: '',
    traceid: window.SaPageInfo?.page_param?.traceid,
    faultReason: result_reason
  }
  if(tspABTest) {
    extraData.tspABTest = tspABTest
  }
  daEventCenter.triggerNotice({
    daId: '1-7-1-1',
    extraData,
    target: item.target || ''
  })
}

/**
  加车一件商品就报一条
  图示是4件商品，就是4条
  1、前端校验出现错误时，上报加车失败（只上报前端校验失败情况）
  2、前端校验成功，待接口返回结果后，上报（可能成功，可能失败，都上报）
 */
export const handleExposeAddCart = (options = {}) => {
  const {
    from = 'togetherbuy',
    list = [],
    result,
    location = 'popup',
    result_reason,
    tspABTest = '',
    series_no = ''
  } = options
  const cookieId = getCookie('cookieId') || ''
  const click_id = `${cookieId}${Date.now()}`
  if (!list.length) {
    sendAddToCartEvent({
      from,
      result,
      item: {},
      click_id,
      location,
      result_reason,
      tspABTest,
      series_no
    })
  } else {
    list.forEach(d => {
      sendAddToCartEvent({
        from,
        result,
        item: d,
        click_id,
        location,
        result_reason,
        tspABTest,
        series_no
      })
    })
  }
}

/**
 * 批量加车前请求售罄状态
 */
export const setProductListSoldStatus = async (options = {}) => {
  const { goods } = options
  if (!goods.length) return

  const stockCheckParams = goods?.map(d => {
    return {
      goodsId: d.goods_id,
      goodsSn: d.goods_sn,
      mallCode: d.mall_code,
      skuCode: d._cartInfo.skuCode,
    }
  })
  let productDetailData = []
  try {
    const stockRes = await batchCheckGoodsStockDataApi(stockCheckParams)
    productDetailData = stockRes?.info?.productDetailData || []
  } catch(err) {
    console.error('batchCheckGoodsStockDataApi', err)
  }

  if (!productDetailData.length) return []

  // 每次点击加车更新库存数据
  const stockProductMap = productDetailData
    .reduce((map, d) => {
      map[d.goodsId] = d
      return map
    }, {})

  const selectSoldOut = []
  goods.forEach((d) => {
    const _stockItem = stockProductMap[d.goods_id]
    if (!_stockItem) return
    const _isSoldOut = +_stockItem?.isOnSale !== 1 || +_stockItem.stock === 0
    const _isSelect = d._cartInfo.selectStatus
    const _isSkcSoldOut = d._cartInfo.isSoldOut && !d._cartInfo.isOnlySkuSoldOut
    const _hasDiffSoldOut = _isSkcSoldOut !== _isSoldOut

    updateItemStockByBff(d, _stockItem)

    if (_isSelect && (_hasDiffSoldOut || _isSoldOut)) {
      selectSoldOut.push(d)
    }
  })

  return selectSoldOut
}

const updateItemStockByBff = (item, { stock, isOnSale }) => {
  const _isSoldOut = +isOnSale !== 1 || +stock === 0
  item._cartInfo.isSoldOut = _isSoldOut

  item.is_on_sale = +isOnSale
  item.stock = +stock

  if (_isSoldOut) {
    item._cartInfo.selectStatus = false // 售罄时选中状态要清空
  }
}

export function provideQuickViewPopover() {
  let QuickViewPopover = ref(null)
  onMounted(() => {
    if(!QuickViewPopover.value) {
      import('public/src/pages/common/quickView').then(res => {
        QuickViewPopover.value = res.default
      })
    }
  })
  return QuickViewPopover
}
