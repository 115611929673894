import { computed } from 'vue'
import { getImageRatio } from 'public/src/pages/common/utils/index'
import { useStore } from 'vuex'
import { SMessage } from '@shein-aidc/sui-message'
import { expose_update_popup, click_update_popup_changemall, click_goods_size_select } from 'public/src/pages/cart_v2/common/analysis/item/goodsSaleAttr'
import { handleUpdateAndUpdateAttrErr } from 'public/src/pages/cart_v2/utils/index'

let QuickViewPopover
const initQuickViewPopover = () => {
  return new Promise(resolve => {
    import('public/src/pages/common/quickView/index.js').then(module => {
      QuickViewPopover = module.default
      resolve()
    })
  })
}

export function useGoodsSaleAttrEvent() {
  const { state, commit, dispatch } = useStore()
  const language = computed(() => state.language)

  const getSelectedAttrIndex = (data) => {
    const selectedAttrIndex = {}
    const attr = data.value.attr || null
    const sku_sale_attr = data.value.product?.sku_sale_attr || []
    if(attr) {
      selectedAttrIndex[attr.attr_id] = { attr_value_id: attr.attr_value_id }
    }
    sku_sale_attr && sku_sale_attr.forEach(item => {
      if(item.is_main != '1' && !selectedAttrIndex[item.attr_id]) {
        selectedAttrIndex[item.attr_id] = { attr_value_id: item.attr_value_id }
      }
    })
    return selectedAttrIndex
  }
  const onClick = async (
    {
      itemOpts: { data, event },
      options: {
        index = 0, 
        from = 'cart',
        source = 'page',
        events
      } = {},
      analysis = {}
    }
  ) => {
    const toggleLoading = ( ) => {
      data.value.isOpenQuickView = !data.value.isOpenQuickView
    }
    const handleUpdateDone = async (res) => {
      if (res.code == '0') {
        try {
          commit('updateState', { key: 'loadingShow', value: true })
          await dispatch('fetchCartIndex')
          dispatch('showOverLimitToast', { id: data.value.id })
          GB_analysis_obj.editUpdate(data.value, res)
          events?.onUpdated?.()
        } finally {
          commit('updateState', { key: 'loadingShow', value: false })
        }  
      } else {
        let errorMessage = handleUpdateAndUpdateAttrErr(res, language.value, data.value)
        errorMessage.msg && SMessage({
          message: errorMessage.msg,
          type: errorMessage.times ? 'error' : 'warning',
          offset: 180,
          duration: errorMessage.times || 3000
        })
      }
    }
    if (event.isCustomGoods && event.event === 'attr') {
      SMessage({
        message: language.value.SHEIN_KEY_PC_27388,
        type: 'warning',
      })
      return
    }
    // 付费会员赠品重选
    const payMemberGiftView = data.value.aggregateProductBusiness?.cartRowViewControl?.payMemberGiftView
    if (payMemberGiftView?.promotionId && event.event === 'reselect') {
      commit('changeAppendageDrawerStatus', { promotionId: payMemberGiftView.promotionId })
      return
    }
    click_goods_size_select(data.value, analysis?.click_goods_size_select)
    toggleLoading()
    if(!QuickViewPopover) {
      await initQuickViewPopover()
    }
    const imgRatio = await getImageRatio(data.value.product.goods_img)

    // 新quickview
    QuickViewPopover.show({
      goodsId: data.value.product.goods_id,
      imgRatio,
      mallCode: data.value.mall_code,
      extendForm: {
        id: data.value.id,
        quantity: Number(data.value.quantity),
        selectedAttrIndex: getSelectedAttrIndex(data),
      },
      config: {
        isUpdateButton: true,
        hideViewFullDetail: true,
        // 不展示收藏功能按钮
        hideWishlistBtn: true,
        showOneClickPay: false,
        showPromotionTagInfo: false,
      },
      callback: {
        handleUpdateDone,
        handleAddToCartDone: handleUpdateDone,
        handleOpenQuickViewDone: () => {
          toggleLoading()
          expose_update_popup(data.value, analysis?.expose_update_popup)
        },
        handleOpenLogin: () => {
        },
        handleMallClick: () => {
          click_update_popup_changemall(data.value, analysis?.click_update_popup_changemall)
        }
      },
      analysis: {
        pageType: 'cartPage',
        sourceTarget: null,
        index: index,
        source: source,
        sa: {
          code: '',
          activity_from: from
        },
      }
    })
  }

  return {
    onClick
  }
}
