import schttp from 'public/src/services/schttp'
import { isSwitchBffApiVersion } from '@/public/src/pages/common/bff-new/index'

const webRquset = async ({ products }) => {
  const { list } = await schttp({
    method: 'POST',
    url: '/api/productList/buyBoxPrice/get',
    data: { products }
  })

  return list
}


// java中间层请求
const bffRquset = async ({ products, sheinClubInfo }) => {
  const res = await schttp({
    useBffApi: true,
    method: 'POST',
    url: '/recommend/buybox/buybox_entrance',
    data: { 
      goodsInfos: products,
      isPaidMember: sheinClubInfo?.isPaid ? 1 : 0,
    }
  })

  const buyBoxEntranceList = res?.info?.buyBoxEntranceList || []
  if (buyBoxEntranceList.length > 0) {
    const product = buyBoxEntranceList.map((item) => {
      return {
        goods_id: Number(item.goodsId),
        buyBoxPrice: {
          price: item.buyBox?.lowestPrice
        }
      }
    }) 
    return product || []
  }
  return []
}


export default {
  methods: {
    async getBuyBoxPrices (goods, sheinClubInfo) {
      if (typeof window === 'undefined') return

      const useBffApi = isSwitchBffApiVersion(['selection_v3'])?.selection_v3

      const products = goods
        .filter(item => item.buyBoxCount?.count)
        .map(item => {
          if (useBffApi) {
            return {
              // java中间层用
              goodsId: item.goods_id,
              goodsSn: item.goods_sn,
              mallCode: item.mall_code
            }
          } else {
            // web中间层用
            return {
              goods_id: item.goods_id,
              goods_sn: item.goods_sn,
              mall_code: item.mall_code,
              group_id: item.buyBoxCount.group_id,
            }
          }
        })
    
      if (!products.length) return

      await this.$nextTick()
      window?.requestIdleCallback(async() => {
        const request = useBffApi ? bffRquset : webRquset

        const list = await request({
          products, 
          sheinClubInfo
        })

        list?.forEach(item => {
          const curGoods = this?.goods?.find(obj => Number(obj.goods_id) === Number(item.goods_id))
          if (!curGoods) return
          curGoods.buyBoxPrice = item.buyBoxPrice
        })
      }, {
        timeout: 1500,
      })
      
    },
  },
}
