
// 价格
function priceAdapter ({ min_price = '', max_price = '' }) {
  return {
    filterPrices: { min_price, max_price }
  }
}

// category 格式化children为空场景
function formatCategoriesChildren (categories = []) { 
  categories.forEach(cate => { 
    cate.children = cate.children ?? []
    if (!cate.children.length) return
    formatCategoriesChildren(cate.children)
  })
}

function formatCateData({ cat_info, results }) {
  if (cat_info.search_id || !results.filterCates) return 

  let tempCates = Array.isArray(results.filterCates) && results.filterCates || []

  if (tempCates.length) {
    tempCates = (function filterCate(_fromCates) {
      if (!_fromCates.length) return _fromCates
      const cat_id = _fromCates[0].cat_id
      const isSelected = cat_id && cat_info.child_cat_id == cat_id
      return _fromCates.length === 1 && !isSelected ? filterCate(_fromCates[0].children) : _fromCates
    })(tempCates)
  }

  let lastSelectedIndex = -1
  results.selectedCate = []
  let cateTree = (function iterateCate(_fromCates) {
    const _toCates = []
    let result = { isShowChild: 0, children: _toCates, lastSelectedIndex: -1 }
    _fromCates.forEach(function (item, index) {
      _toCates[index] = {
        cat_id: item.cat_id || '',
        parent_id: item.parent_id || '',
        cat_name: (item.multi && item.multi.cat_name) || item.cat_name || '',
        isSelected: false,
        isShowChild: 0,
      }
      const isSelect = item.cat_id && cat_info.child_cat_id == item.cat_id
      if (isSelect) {
        _toCates[index].isSelected = true
        result.lastSelectedIndex = index
        lastSelectedIndex = index
        if (item.children && item.children.length) {
          _toCates[index].isShowChild = 1
        }
        result.isShowChild = 1
        results.selectedCate.push(_toCates[index])
      }
      if (item.children?.length) {
        let childData = iterateCate(item.children)
        _toCates[index].children = childData.children
        _toCates[index].isShowChild = _toCates[index].isShowChild || childData.isShowChild
        if (_toCates[index].isShowChild) {
          result.isShowChild = 1
        }
        if (result.lastSelectedIndex == -1 && lastSelectedIndex !== -1) {
          result.lastSelectedIndex = index
          lastSelectedIndex = index
        }
      }
    })
    return result
  })(tempCates)

  cateTree.isShowChild = 1
  if (cateTree.children.length > 6 && cateTree.lastSelectedIndex < 6) { cateTree.showViewMore = true }
  results.filterCates = cateTree
}

// category
function categoryAdapter ({ cat_info = {} }, { cat_path = [], categories = [], all_categories = [] }) {
  const getCate = () => {
    if (cat_info?.showAllCategory === '1' && all_categories?.length) { 
      formatCategoriesChildren(all_categories)
      return {
        filterCates: all_categories
      }
    }
    let filterCates = []
    const catLength = cat_path.length
    if (!catLength) { 
      filterCates = categories
    } else {
      const root = { children: [] }
      cat_path.reduce((acc, cat, index) => {
        cat.children = index == catLength - 1 ? categories : []
        acc.children = [cat]
        filterCates.push(cat)
        return cat
      }, root)
    }
    formatCategoriesChildren(filterCates)
    return {
      filterCates
    }
  }
  const results = getCate()
  formatCateData({ cat_info, results })
  return results
}

// filterList -> filterAttrs + filterTsps
function filterListAdapter ({ filterList, tsp_ids, attr_ids, brand_ids, attr_node_ids }) {
  const { tsps, attrs } = filterList.reduce((acc, attr) => {
    const isTsp = attr.nodeType.toString() === '4' && attr.children.every(child => child.nodeType.toString() === '7')
    if (isTsp) {
      attr.children.length && acc.tsps.push(attr)
    } else {
      attr.children.length && acc.attrs.push(attr)
    }
    return acc
  }, {
    tsps: [],
    attrs: []
  })

  return {
    ...formatAttr(attrs, { attr_ids, brand_ids, attr_node_ids, tsp_ids }),
    ...formatTsp(tsps, tsp_ids),
  }
}

const formatTsp = (tsps = [], selectTspIds = '') => {
  const tsp_ids_arr = `${selectTspIds}`.split(',')
  const selectedTsps = []
  const filterTsps = tsps.map((tsp, index) => {
    let open = index <= 1  // 前两个属性组默认展开
    let selectedNum = 0
    const getName = tsp => tsp.nodeName
    let lastSelectedIndex = -1
    const tagList = tsp.children.map((tag, itemIndex) => {
      const curTagId = `${tsp.nodeId}_${tag.nodeId}`
      const isSelected = tsp_ids_arr.includes(curTagId)
      const tagName = getName(tag)
      const item = {
        isSelected,
        tagId: curTagId,
        tagName,
        nodeType: tag.nodeType,
        nodeId: tag.nodeId,
        tagValue: tagName,
      }
      if (isSelected) {
        open = true
        selectedNum += 1
        lastSelectedIndex = itemIndex
        selectedTsps.push(item)
      }
      return item
    })
    return {
      tagGroupId: tsp.nodeId,
      tagGroupName: getName(tsp),
      nodeType: tsp.nodeType,
      nodeId: tsp.nodeId,
      open,
      selectedNum,
      lastSelectedIndex,
      tagList
    }
  })
  return { filterTsps, selectedTsps }
}

const getI18nName = (node) => {
  return node.nodeName
}

const isNodeType = (node, type) => {
  if (Array.isArray(type)) {
    return type.some(t => node.nodeType.toString() === t.toString())
  }
  return node.nodeType.toString() === type.toString()
}

// type=2, 属性项id_属性值id(78_781); type=3, 属性项id_属性组id(27_5)。
const getAttrId = (value) => isNodeType(value, 2) ? value.nodeId.split('_')[0] : value.nodeId
const getAttrFilter = (node) => {
  if (isNodeType(node, 3)) {
    return node.attr_filter
  }
  return node.nodeId
}

const formatAttr = (attrs, { brand_ids = '', attr_node_ids = '', tsp_ids = '' }) => {
  const attr_node_ids_attr = attr_node_ids.toString().split('-') || []
  const brand_ids_arr = brand_ids.toString().split(',') || []
  const selectedAttrs = []

  const formatAttrValues = (values, { selectChange } = {}) => {
    return values.map((value, index) => {
      const attr_id = parseInt(getAttrId(value))
      const item = {
        attr_filter: getAttrFilter(value),
        attr_id,
        attr_value: getI18nName(value),
        attr_values: getAttrValue(value, { selectChange }),
        attr_image: value?.nodeImg || '',
        nodeType: value.nodeType,
        nodeId: value.nodeId,
        isSelected: false,
      }

      if (item.nodeType == 7) { // 看起来是tsp的值
        const isSelectTsp = tsp_ids.indexOf(item.attr_filter) > -1
        if (isSelectTsp) {
          item.isSelected = true
          selectChange && selectChange(item, index)
        }
      } else {
        const isSelectBrand = item.nodeType == 9 && brand_ids_arr.includes(item.nodeId)
        if (isSelectBrand || attr_node_ids_attr.includes(item.nodeId)) {
          item.isSelected = true
          selectChange && selectChange(item, index)
        }
      }

      return item
    })
  }
  
  const formatAttrGroups = (groups, { selectChange } = {}) => {
    return groups.map((group, index) => {
      const attr_id = parseInt(getAttrId(group))
      const item = {
        attr_filter: getAttrFilter(group),
        attr_id,
        group_image: group.nodeImg,
        group_name: getI18nName(group),
        nodeType: group.nodeType,
        nodeId: group.nodeId,
        isSelected: false,
      }
      
      const isSelectBrand = item.nodeType == 9 && brand_ids_arr.includes(item.nodeId)
      if (isSelectBrand || attr_node_ids_attr.includes(item.nodeId)) {
        item.isSelected = true
        selectChange && selectChange(item, index)
      }
      return item
    })
  }

  const getAttrValue = (node, options) => {
    if (Array.isArray(node.children) && !isNodeType(node, 3)) {
      return formatAttrValues(node.children, options)
    }
    return []
  }
  const filterAttrs = attrs.map(attr => {
    const oldAttr = {
      attr_id: attr.nodeId,
      attr_name: getI18nName(attr),
      show_group: attr.showGroup,
      nodeType: attr.nodeType,
      nodeId: attr.nodeId,
      attr_values: [],
      groups: [],
      selectedNum: 0,
      lastSelectedIndex: -1,
    }
    const isGroup = attr.showGroup && attr.nodeType.toString() === '1'

    function handleSelect (selectItem, selectIndex) {
      oldAttr.selectedNum++
      oldAttr.lastSelectedIndex = selectIndex
      selectedAttrs.push(selectItem)
    }

    if (isGroup) {
      // only group got this field
      oldAttr.show_group_txt = false
      oldAttr.groups = formatAttrGroups(attr.children || [], { selectChange: handleSelect })
    } else {
      oldAttr.attr_values = formatAttrValues(attr.children || [], { selectChange: handleSelect })
    }

    return oldAttr
  })
  return { filterAttrs, selectedAttrs }
}

// bannerTag 
function bannerTagAdapter ({ bannerTag = {} }) { 
  return {
    bannerTag: {
      'tag_id': bannerTag?.tag_id ?? '',
      'showTag': bannerTag?.hasOneClickTag === '1' ? 1 : 0,
      'billno': bannerTag?.billno ?? '',
      'type': 'oneClickPay'
    }
  }
}

// attribute -> filterAttrs + filterTsps
function attributeAdapter ({ attribute = [], selectTspIds = '', attr_ids = '' }) { 
  const filterAttrs = []
  const filterTsps = []
  const selectedTsps = []
  const selectedAttrs = []
  const attr_ids_arr = attr_ids.toString().split('-') || []

  attribute.forEach(item => { 
    // tsp
    if (item.attrType == 1) {
      const tsp_ids_arr = `${selectTspIds}`.split(',')
      const index = filterTsps.findIndex(obj => obj.tagGroupId == item.attr_id)
      const tsp = filterTsps[index]
        || {
          tagGroupId: item.attr_id,
          tagGroupName: item.attr_name,
          tagList: []
        }
      const _item = {
        tagId: item.attr_filter,
        tagName: item.attr_name,
        tagValue: item.attr_name,
      }
      const isSelected = tsp_ids_arr.includes(_item.tagId)
      if (isSelected) {
        _item.isSelected = true
        selectedTsps.push(_item)
      }
      tsp.tagList.push(_item)
      index < 0 && filterTsps.push(tsp)
    } else if (item.show_group == 1) {
      // group 
      const index = filterAttrs.findIndex(obj => obj.attr_id == item.attr_id)
      const attr = filterAttrs[index]
        || {
          attr_id: item.attr_id,
          attr_name: item.attr_name,
          attr_values: [],
          groups: [],
          show_group: 1
        }
      const _item = {
        'attr_filter': item.attr_filter,
        'attr_id': item.attr_id,
        'group_name': item.group_name,
        'group_image': item.group_image,
      }
      
      if (_item.attr_filter && _item.attr_filter.split('-').some(_ => attr_ids_arr.includes(_))) {
        _item.isSelected = true
        selectedAttrs.push(_item)
      }

      attr.groups.push(_item)
      index < 0 && filterAttrs.push(attr)
    } else {
      // attr
      const index = filterAttrs.findIndex(obj => obj.attr_id == item.attr_id)
      const attr = filterAttrs[index]
        || {
          attr_id: item.attr_id,
          attr_name: item.attr_name,
          attr_values: [],
          groups: [],
          show_group: 0
        }
      const _item = {
        'attr_filter': `${item.attr_id}_${item.attr_value_id}`,
        'attr_id': item.attr_id,
        'attr_value': item.attr_value,
        'attr_image': '',
      }
      if (_item.attr_filter && attr_ids_arr.includes(_item.attr_filter)) {
        _item.isSelected = true
        selectedAttrs.push(_item)
      }
      attr.attr_values.push(_item)
      index < 0 && filterAttrs.push(attr)
    }
  })

  return {
    filterAttrs,
    filterTsps,
    selectedTsps,
    selectedAttrs,
  }
}

// tags -> cloudTags
function tagsAdapter ({ tags = [] }) { 
  const cloudTags = []
  const filterIds = ['60007129'] 

  tags?.forEach(tag => { 
    !filterIds.includes(tag.tag_id) && cloudTags.push({
      'tag_id': tag.tag_id,
      'tag_name': tag.tag_name,
      'type': tag.type,
      'tag_type': tag.tag_type,
      'is_sale_tag': tag.is_sale_tag,
      'promotionInfo': tag.promotionInfo || {},
      'value': tag.tag_id,
      'label': tag.tag_name,
      'local_mall': tag.is_local_mall,
      'quickship_tag': tag.tag_id == 'quickship',
      quickShipText: tag.tag_id == 'quickship' && tag.tag_name,
    })
  })

  return {
    cloudTags
  }
}

// cccxFilterConfig
function cccxFilterConfigAdapter (shouldUseCccxFilterChain, { filterSortConfigs = [] }) { 
  return {
    cccxFilterConfig: {
      filterRule: shouldUseCccxFilterChain ? filterSortConfigs : []
    }
  }
}

// cccConfig: tagCloudSlotConfig
function tagCloudSlotConfigAdapter ({ tagSlotConfigs = [] }) { 
  tagSlotConfigs?.forEach(item => { 
    item.tagId = item.tag_id
    item.tagName = item.tag_name
  })
  return {
    tagCloudSlotConfig: tagSlotConfigs ?? []
  }
}

// cccfilter: productTypeSortConfig
function productTypeSortConfigAdapter ({ tags = [] }) { 
  const productTypeSortConfig = []
  tags?.forEach(tag => {
    let tagType = tag.tag_type
    let isPush = true // 是否push到productTypeSortConfig[]
    if (tag.type === 'quickship') {
      tagType = 12
      isPush = productTypeSortConfig.findIndex(obj => obj.tagType == 12) < 0
    } else if (tag.type === 'mall') { 
      tagType = 11
      isPush = productTypeSortConfig.findIndex(obj => obj.tagType == 11) < 0
    } else if (tag.is_sale_tag === 100) { 
      tagType = 13
      isPush = productTypeSortConfig.findIndex(obj => obj.tagType == 13) < 0
    }

    const obj = {
      ...tag,
      tagType,
      tagId: tag.tag_id,
      tagName: tag.tag_name,
    }

    isPush && productTypeSortConfig.push(obj)
  })

  return {
    productTypeSortConfig
  }
}

// kids
function kidTagAdapter ({ tagChild = {} }) { 
  if (tagChild?.isShowKidsProfile !== '1') return {}
  const { child = [], defaultChild = {} } = tagChild


  return {
    defaultChild,
    kidsTag: {
      child_list: child.map(item => ({
        'child_id': item.child_id,
        'nickname': item.nickname,
        'birthday': item.birthday,
        'sex': item.sex
      }))
    },
  }
}

function adaptDailyDates(data) {
  const daily = data?.dailyDates || []
  if (!daily?.length) return
  let dailyDates = {}
  daily.forEach(item => {
    dailyDates[item.date] = item.show_date
  })
  return dailyDates
}

export default function bffFilterAdapter (sourceData, bffFilterInfo) {
  // 优先处理cccConfig / cccxFilterConfig，避免node层相关配置影响
  sourceData.cccConfig = Object.assign(
    sourceData.cccConfig || {},
    tagCloudSlotConfigAdapter(bffFilterInfo),
    productTypeSortConfigAdapter(bffFilterInfo),
  )
  
  const { cat_info = {} } = sourceData
  const { filterList, filterSortConfigs } = bffFilterInfo
  const shouldUseCccxFilterChain = filterList?.length && filterSortConfigs?.length
  const { filterAttrs, selectedAttrs, filterTsps, selectedTsps } = shouldUseCccxFilterChain
    ? filterListAdapter({
      filterList: bffFilterInfo?.filterList ?? [],
      tsp_ids: cat_info?.tsp_ids ?? '',
      attr_ids: cat_info?.attr_ids ?? '',
      brand_ids: cat_info?.brand_ids ?? '',
      attr_node_ids: cat_info?.attr_node_ids ?? ''
    })
    : attributeAdapter({
      attribute: bffFilterInfo?.attribute ?? [],
      selectTspIds: cat_info?.tsp_ids ?? '',
      attr_ids: cat_info?.attr_ids ?? ''
    })

  Object.assign(
    sourceData,
    kidTagAdapter(bffFilterInfo),
    priceAdapter(bffFilterInfo),
    categoryAdapter(sourceData, bffFilterInfo),
    bannerTagAdapter(bffFilterInfo),
    tagsAdapter(bffFilterInfo),
    cccxFilterConfigAdapter(shouldUseCccxFilterChain, bffFilterInfo),
    {
      filterTsps,
      selectedTsps,
      filterAttrs,
      selectedAttrs,
      shouldUseCccxFilterChain,
      dailyDates: adaptDailyDates(bffFilterInfo)
    },
  )
}
