import { getLocalStorage } from '@shein/common-function'
import { web_modify_check_click, web_modify_check_response, web_cart_entry_click } from 'public/src/pages/cart_v2/utils/metricTagsConfig.js'
import { prefetchResource } from 'public/src/services/prefetchResource'
import { windowCacheDeleteCb } from '@shein/common-function'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
const isEnable = typeof window !== 'undefined' && typeof navigator !== 'undefined' && 'serviceWorker' in navigator && typeof fetch !== 'undefined' && typeof caches !== 'undefined' && location.pathname.indexOf('/user/auth/login') == -1

const getAbt = async () => {
  const { PcCartPrefetch } = await getUserAbtData()
  return PcCartPrefetch?.param?.cart_prefetch_switch === 'on'
}
const prefetchCart = () => {
  if (!isEnable) {
    windowCacheDeleteCb({ key: 'cartDynamicData' })
    return
  }

  getAbt().then( enable => {
    if(enable) {
      windowCacheDeleteCb({ key: 'cartDynamicData', cb: () => fetch(location.origin + '/cart', { headers: { 'prefetch': '1' } }) })
    }
  })
}
const prefetchResources = () => {
  prefetchResource.prefetchJs({
    prefetchList: [
      {
        chunkName: 'cart_new',
        relType: 'prefetch'
      },
      {
        chunkName: 'cart_top_part',
        relType: 'prefetch'
      },
      {
        chunkName: 'checkout',
        relType: 'prefetch'
      },
      {
        chunkName: 'cart_v2',
        relType: 'prefetch'
      },
      {
        chunkName: 'cartv2_bottom_content',
        relType: 'prefetch'
      }
    ]
  })
}

const getCartInfo = (cartRes) => {
  const mallCartInfo = cartRes?.mallCartInfo || {}
  let carts = []
  let mallCarts = mallCartInfo.mallCarts || []
  mallCarts.forEach(mall => {
    mall.shops?.forEach(shop => {
      shop.contentData?.forEach(content => {
        carts = carts.concat(content.productLineInfoList || [])
      })
    })
  })
  return {
    code: '0',
    info: {
      carts
    }
  }
}

export const useMiniCart = () => {
  const getAddressInfo = () => {
    const addressCookie = getLocalStorage('addressCookie')
    let addressInfo = {}
    if (addressCookie) {
      try {
        addressInfo = typeof addressCookie === 'string' ? JSON.parse(addressCookie) : addressCookie
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('获取地址缓存失败', error)
      }
    }
    return addressInfo
  }
  const getUserLocalSizeCountry = () => {
    if (typeof window !== 'undefined') {
      return window.localStorage.getItem('last_select_country') || ''
    }
    return ''
  }
  // 修改勾选状态
  const onBeforeClickModifyCheckbox = (checked, type) => {
    web_modify_check_click(checked, type)
  }
  // 修改勾选状态结果
  const onAfterClickModifyCheckbox = (checked, type, code) => {
    web_modify_check_response(checked, type, code)
  }
  // 购物车更新
  const onMiniCartUpdate = ({ cartRes } = {}) => {
    prefetchCart()
    if (!window?.cart_module) {
      window.cart_module = {}
    }
    if (!window.cart_module.cartVue) {
      window.cart_module.cartVue = {}
    }
    !window.cart_module.cartVue.res && (window.cart_module.cartVue.res = getCartInfo(cartRes))
    !window.cart_module.cartVue.carts && (window.cart_module.cartVue.carts = getCartInfo(cartRes).info.carts)
  }
  const onMiniCartMounted = () => {
    requestIdleCallback(() => {
      prefetchResources()
    })
  }

  const onClickViewCart = () => {
    web_cart_entry_click(4)
  }

  const onClickCartBag = () => {
    web_cart_entry_click(3)
  }

  return {
    getAddressInfo,
    getUserLocalSizeCountry,
    onBeforeClickModifyCheckbox,
    onAfterClickModifyCheckbox,
    onMiniCartUpdate,
    onMiniCartMounted,
    onClickViewCart,
    onClickCartBag
  }
}
