import { daEventCenter } from 'public/src/services/eventCenter/index'

export const click_select_someproduct = (data, callback) => {
  const defaultData = {
    select_type: 1,
    is_select: data.is_checked,
    position: 'page',
    // 勾选商品时在哪个筛选标签下。注意券需要上报couponType
    label_id: '',
  }
  const isReport = callback?.(defaultData) !== null
  const extraData = callback?.(defaultData) ?? defaultData
  if (isReport) {
    daEventCenter.triggerNotice({ daId: '1-7-3-10', extraData })
  }
}
