import { BFF_APOLLO_CONFIG, SSR_BFF_APOLLO_CONFIG } from './config'
// bff融合判断
import { isSwitchBffApiVersion, isSwitchBffSsr } from 'public/src/pages/common/bff-new/index.js'
const useBffApi = isSwitchBffApiVersion(BFF_APOLLO_CONFIG) || {}
const ssrUserBffApi = isSwitchBffSsr(SSR_BFF_APOLLO_CONFIG) || {}

// 客户端渲染控制
export const getBffVersion = key => useBffApi?.[key] // || ['localhost'].includes(gbCommonInfo?.NODE_SERVER_ENV) || false

// 配合服务端渲染的控制
export const getBffVersionBySsr = key => ssrUserBffApi?.[key] || false
