const { timeLimit = 120000, rowsLimit = 80 } = gbCommonInfo?.CHECK_CART_STOCK_CONFIG ?? {}

/**
 * 计算是否获取库存条件
 * @param {carts} 购物车列表数据
 * @param {lastCheckStockTime} 上次操作时间戳
 */
export const getNoCheckStockCondition = (props) => {
  // 默认请求
  let res = false
  try {
    if (typeof window !== 'undefined') {
      const { carts, lastCheckStockTime } = props ?? {}
      const cartsCheckedNum = Array.isArray(carts) ? carts?.filter(item => item?.is_checked == 1)?.length : 0
      // 时间戳差值小于{timeLimit}ms且勾选行数小于等于{rowsLimit}则不检查库存接口
      res = (Date.now() - lastCheckStockTime) < timeLimit && cartsCheckedNum <= rowsLimit
    }
  } catch (e) {
    console.error('getNoCheckStockCondition Error', e)
    res = false
  } finally {
    return res
  }
}
