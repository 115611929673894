<template>
  <div
    v-if="isShowRobot"
    class="bs-c-header-robot__wrap"
    @mouseenter="isShowPop = true"
    @mouseleave="isShowPop = false"
  >
    <Supports
      v-tap="{ id: 'click_support.comp_header-robot' }"
      @click="navTo(false)" />

    <!-- 小红点 -->
    <em
      v-if="isShowUnRead"
      v-tap="{ id: 'click_support.comp_header-robot' }"
      class="bs-c-header-robot__unread"
      @click="navTo(false)"
    ></em>

    <!-- popup -->
    <div
      v-if="isShowPop"
      class="bs-c-header-robot__wrap-dropdown bsc-header-dropdown bsc-header-container"
    >
      <!-- contact seller -->
      <div
        v-if="isShowSeller"
        v-tap="triggerSeller()"
        class="bs-c-header-robot__btn"
        @click="navTo(true)">
        <Seller />
        <div>
          <p class="bs-c-header-robot__customer-subtitle">
            {{ language.Title }}
            <span
              v-if="isShowSellerUnRead"
              v-tap="triggerSeller()"
              class="bs-c-header-robot__btn-unread"
              @click="navTo(true)"
            ></span>
          </p>
          <p
            v-if="language.Text"
            class="bs-c-header-robot__customer-des">
            {{ language.Text }}
          </p>
        </div>
      </div>
      <div
        v-tap="{ id: 'click_support.comp_header-robot' }"
        class="bs-c-header-robot__btn"
        style="padding-top: 0;"
        @click="navTo(false)">
        <Supports />
        <div>
          <p class="bs-c-header-robot__customer-subtitle">
            {{ robotInfo.contact_agent_title }}
            <span
              v-if="isShowRobotUnRead"
              v-tap="{ id: 'click_support.comp_header-robot' }"
              class="bs-c-header-robot__btn-unread"
              @click="navTo(false)"
            ></span>
          </p>
          <p
            v-if="robotInfo.contact_agent_content"
            class="bs-c-header-robot__customer-des">
            {{ robotInfo.contact_agent_content }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script name="BHeaderRobot" setup lang="ts">
import { ref, onBeforeMount, computed } from 'vue'
import Supports from './icons/Supports.vue'
import Seller from './icons/Seller.vue'
import { getOrderJumpInfo, robotLinkCommon } from './jumpToRobot'
import { template as _commonTemplate } from '@shein/common-function'
// 数据源：
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { getSource } from '../../common/dataSource'
import type { AS_HeaderRobot, DS_HeaderRobot } from '../../types'

const appConfigs = useAppConfigs()
const props = defineProps<{
  fsData?: DS_HeaderRobot.FS_DATA
  dataSource?: DS_HeaderRobot.DataSource
  analysisSource?: AS_HeaderRobot.AnalysisSource
}>()
const apis = getSource({ appConfigs, dataSource: props.dataSource })
// 数据源：END
const storeCode = ref<any>('')
const isShowRobot = ref<Boolean>(true)
const isShowSeller = ref<Boolean>(true)
const isShowPop = ref<Boolean>(false)
const robotInfo = ref<any>({})
const SaPageInfo = appConfigs?.$getters?.SaPageInfo || {}
const isRefeshUnread = appConfigs?.$getters?.isLogin || false
const isShowSellerUnRead = ref<Boolean>(false)
const isShowRobotUnRead = ref<Boolean>(false)
const language = ref<any>({})
const emit = defineEmits<{
  (e: 'unLoginJump', robotInfo: any): void
  (e: 'onLoaded', isShowPop: any): void
}>()

const isShowUnRead = computed(()=>{
  return isShowRobotUnRead.value || isShowSellerUnRead.value
})


const langsMap = {
  Title: 'SHEIN_KEY_PC_33522',
  Text: 'SHEIN_KEY_PC_33523',
}

onBeforeMount(async () => {
  storeCode.value = getStoreCode()
  const [unRead, chanRes, storeRes, lan] = await Promise.all([
    isRefeshUnread ? apis.getChatUnreadCount() : Promise.resolve({}),
    apis.getRobotCustomerChan(),
    typeof storeCode.value != 'undefined' ? apis.getStoreChan(storeCode.value) : Promise.resolve({}),
    apis.fetchLanguage(langsMap),
  ])
  language.value = lan
  const entrance = chanRes?.info?.entrance || []
  const { tk_unread_count = 0, total_unread_count = 0 } = unRead?.info || {}
  isShowRobotUnRead.value = total_unread_count || tk_unread_count
  const hasConfig = entrance?.find(_ => _?.position == 'navigationBar') || null
  if (hasConfig && hasConfig.open === 0) {
    isShowRobot.value = false
  } else {
    isShowRobot.value = true
  }
  robotInfo.value = chanRes?.info?.['v2'] || {}
  const storeInfo = storeRes?.info || {}
  language.value.Text = storeInfo?.store_name ? _commonTemplate(storeInfo?.store_name, language.value.Text) : ''
  isShowSeller.value = storeInfo?.storeEntranceList?.some(_ => _?.position == 'navigationBar' && _?.open == 1)
  isShowSellerUnRead.value = storeInfo?.storeUnReadMsgTotal
  emit('onLoaded', isShowRobot)
})

const navTo = (toSeller = false) => {
  if (appConfigs?.$getters?.isLogin) {
    !isRefeshUnread && !toSeller && apis.getChatUnreadCount().then((unRead) => {
      const { tk_unread_count = 0, total_unread_count = 0 } = unRead?.info || {}
      isShowRobotUnRead.value = total_unread_count || tk_unread_count
    })
    !isRefeshUnread && toSeller && apis.getStoreChan(storeCode.value).then((res) => {
      const { storeUnReadMsgTotal = 0, storeEntranceList = [] } = res?.info || {}
      isShowSellerUnRead.value = storeUnReadMsgTotal
      isShowSeller.value = storeEntranceList?.some(_ => _?.position == 'navigationBar' && _?.open == 1)
    })
    getOrderJumpInfo(SaPageInfo, toSeller)
  } else {
    const redirectionUrl = robotLinkCommon(SaPageInfo, 'navigationBar', { toSeller })
    // 登录页直接打开弹窗
    if (SaPageInfo?.page_name == 'page_login') {
      emit('unLoginJump', robotInfo.value)
      return
    }
    location.href = `/user/auth/login?redirection=${encodeURIComponent(redirectionUrl.uri)}&robotScene=${redirectionUrl.name}_${robotInfo.value?.no_login_second_confirm ? 1 : 0}`
  }
}

const getStoreCode = () => {
  let val = undefined
  const robotStoreData = window?.gbCommonInfo?.robotStoreData || null
  if(robotStoreData) {
    val = robotStoreData?.storeCode
  }
  return val
}

// 埋点源:
import { useAnalysis } from '../../common/analysisSource'
import { provide } from 'vue'
const analysisInstance = useAnalysis(props.analysisSource)
provide('analysisInstance', analysisInstance)
const { vTap } = analysisInstance
// 埋点源: END

const triggerSeller = () => {
  return { id: 'click_store_service.comp_header-robot',data: {
          storeCode: storeCode.value,
        } }
}
</script>

<style lang="less">
.bs-c-header-robot {
  &__wrap {
    font-size: 14px;
    line-height: 1.15;
    position: relative;
    vertical-align: middle;
    display: inline-block;
    cursor: pointer;
  }
  &__wrap-dropdown {
    position: absolute;
    right: -26px;
    background: #fff;
    z-index: 600;
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, .08);
    transition: transform .3s;
    min-width: 236px;
    white-space: nowrap;
    display: flex;
  }
  &__btn{
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 12px;
    position: relative;
  }
  &__btn-unread{
    width: 6px;
    height: 6px;
    background: #fe3b30;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
  }
  &__customer-subtitle {
    font-size: 14px;
    font-weight: 700;
    color: #000;
    line-height: 24px;
  }
  &__customer-des {
    font-size: 12px;
    color: #767676;
    line-height: 17px;
  }
  &__unread {
    width: 6px;
    height: 6px;
    background: #fe3b30;
    position: absolute;
    right: 0;
    top: -4px;
    border-radius: 50%;
  }
}
.bsc-header-container{
  display: flex;
  flex-direction: column;
}
</style>
