/**
 * @description 适配器模块, 二期需要删除
 */

export default {
  namespaced: true,
  getters: {
    base_info(state, getters, rootState) { // 逐步丰富替代旧数据流 detail
      const mall_code = rootState.productIntroData?.mallCode
      const detail = rootState.productIntroData?.detail || {}
      const { goods_id, goods_sn, goods_img, cat_id, goods_name, store_code, goods_url_name, productRelationID } = detail
      return {
        cat_id,
        goods_id,
        goods_sn,
        goods_img,
        goods_name,
        mall_code,
        store_code,
        goods_url_name,
        productRelationID
      }
    },
    language_v2(state, getters, rootState, rootGetters) {
      const { language } = rootGetters
      return language
    },
    screen_abt_config(state, getters, rootState, rootGetters) {
      // TODO screen_abt_config 暂时放这里，后面放在公共中
      const { screenAbtConfig } = rootGetters
      return screenAbtConfig
    },
    screen_abt_tsp_config(state, getters, rootState, rootGetters) {
      const { screenAbtTspConfig } = rootGetters
      return screenAbtTspConfig
    },
    detail_imgs_not_show(state, getters, rootState, rootGetters) {
      // TODO: 展示细节图配置，后面要删除，detailImg = 2 的时候不展示
      return !!rootGetters['pageComponents']?.moreDetailConf?.notShow
    },
    is_third_party_store(state, getters, rootState, rootGetters) {
      // TODO 第三方店铺，BFF 中只要判断 this.sign_data?.storeType == 1
      // 后续接入品牌系列后，这个数据要迁移到品牌系列中
      // 以下是旧数据流，二期要删除
      const { showStore, storeData = {} } = rootGetters
      if (!showStore) return false
      const { storeStatus = '', storeType = '' } = storeData
      return showStore && storeStatus == 1 && storeType == 1
    },
    // TODO 评论相关adaptor
    comment(state, getters, rootState) {
      return rootState.realtimeBffInfo?.comment || {}
    },
    is_retention_product(state, getters, rootState, rootGetters) {
      // 后期接中间层coldModules?.productLabel?.isRetentionProduct
      const { isMainGoodsLowestPrice } = rootGetters
      return isMainGoodsLowestPrice
    },
    main_product_same_group_id(state, getters, rootState, rootGetters) {
      // 后期接中间层buyBoxRecommendInfo取
      const { buyBoxSameId } = rootGetters
      return buyBoxSameId
    },
    // 颜色筛选，后期接中间层后期接中间层coldModules.saleAttr?.mainSaleAttribute?.info
    colors_info(state, getters, rootState) {
      const { detail = {}, relation_color = [] } = rootState?.productIntroData || {}
      const temp = [{ ...detail }, ...relation_color] 
      function filterAttrValue (colors) {
        if(colors?.mainSaleAttribute?.[0]) {
          const color = colors.mainSaleAttribute[0]
          return { title: color.attr_value }
        }
        return { title: '' }
      }
      const reslut = temp.map(item => {
        const { title } = filterAttrValue(item)
        return {
          goods_sn: item.goods_sn,
          attr_value: title,
          goods_id: Number(item.goods_id),
          goods_color_image: item.color_image,
        }
      })

      return reslut
    },
    //尺码筛选，后期接中间层后期接中间层 coldModules.saleAttr?.multiLevelSaleAttribute?.skc_sale_attr
    comment_filter_attr_size(state, getters, rootState) {
      const { polyAttrSize = [] } = rootState?.productIntroData?.sizeFormatData?.saleAttrList || {}
      const skc_sale_attr = [{
        attr_id: 87,
        attr_value_list: polyAttrSize
      }]
      return skc_sale_attr
    },
    is_sold_out(state, getters, rootState, rootGetters) {
      const { isSoldOut } = rootGetters
      return isSoldOut
    },
    is_hit_compliance_mode(state, getters, rootState, rootGetters) {
      const { isHitComplianceMode } = rootGetters
      return isHitComplianceMode
    },
    comp_liance_mode(state, getters, rootState, rootGetters) {
      const { complianceMode } = rootGetters
      return complianceMode
    },
    get_estimated_info(state, getters, rootState, rootGetters) {
      const { getEstimatedInfo } = rootGetters
      return getEstimatedInfo
    },
    sku_code(state, getters, rootState) {
      return rootState.skuInfo?.sku_code || ''
    },
    is_paid_user(state, getters, rootState, rootGetters) {
      const { isPaidUser } = rootGetters
      return isPaidUser
    },
    // 底部推荐位请求参数
    ymal_rec_params(state, getters, rootState) {
      const detail = rootState.productIntroData?.detail || {}
      const { goods_id, goods_sn, cat_id } = detail
  
      return {
        attribute: rootState.mainAttribute || rootState.attribute || '',
        // isAddCart: rootState.newProductDetail.common.addSkcCompleteStatus ? '1' : '0',
        goods_id,
        goods_sn,
        cat_id,
      }
    },
    show_black_friday_belt(state, getters, rootState, rootGetters) {
      const { showBlackFridayBelt } = rootGetters
      return showBlackFridayBelt
    },
    is_forward_category(state, getters, rootState, rootGetters) {
      const { isForwardCategory } = rootGetters
      return isForwardCategory
    },
    skuInfo(state, getters, rootState,){
      return rootState.skuInfo
    },
    country: (state, getters, rootState) => {
      return rootState.country
    },
  },
  actions: {
    show_login_modal({ dispatch }, payload) {
      dispatch('showLoginModal', payload, { root: true })
    },
    update_estimated_coupon_list({ dispatch }, payload) {
      dispatch('updateEstimatedCouponList', payload, { root: true })
    }
  }
}
