<template>
  <div class="checkout-place-btn">
    <s-popover
      class="checkout-activity-popover__wrap"
      theme="dark"
      placemen="top-end"
      :append-to-body="false"
      :show-close-icon="true"
      :model-value="showActivityPopover"
      trigger="unset"
      :prop-style="{ 
        minWidth: '132px', 
        maxWidth: isShowNewActivityPopverLayout ? '300px' :'260px', 
        padding: isShowNewActivityPopverLayout ? '6px 12px 6px 6px' : '12px',
        background: `rgba(0, 0, 0, 0.8)`,
      }"
      :outside-close="false"
      @opened="openActivityPopover"
      @before-close="closeActivityPopover"
    >
      <ActivityPopover 
        class="not-fsp-element"
        :checkout="checkout"
        :language="language"
        :activity-popover-type="activityPopoverType"
        :integrity-promotion-info="integrityPromotionInfo"
        :promotion-list="promotionList"
        :under-price-abt-value="underPriceAbtValue"
        :flash-sale-group-list="flashSaleGroupList"
        :earliest-expiry-coupon="earliestExpiryCoupon"
        :lowest-price-by-some-days="lowestPriceBySomeDays"
        :low-stock-carts-data="lowStockCartsInfo"
        :prime-club-promotion="primeClubPromotion"
        :saver-card-promotion="saverCardPromotion"
        :bubble-copy-writing-by-abt="bubbleCopyWritingByAbt"
        @handle-activity-popover-behavior="handleActivityPopoverBehavior"
      />

      <template #reference>
        <SummaryCheckoutButton 
          :paypal-btn-show="paypalBtnShow"
          :paypal-venmo-btn-show="paypalVenmoBtnShow"
          :paypal-bnpl-btn-show="paypalBnplBtnShow"
          :installment-info="installmentInfo"
          :paypal-ga-vault="paypalGaVault"
          @create-order="createOrder"
        />
      </template>
    </s-popover>

    <p
      v-if="status.cartStock"
      class="sold-out-tip"
      style="word-break: break-word"
      v-html="language.SHEIN_KEY_PC_14712"
    ></p>
    <!-- 同意 tc&pp 协议 -->
    <AgreePolicyTip :language="language" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'

import AgreePolicyTip from 'public/src/pages/checkout/vue_tpls/AgreePolicyTip.vue'
import ActivityPopover from 'public/src/pages/checkout/vue_tpls/activity_popover/Index.vue'
import SummaryCheckoutButton from './SummaryCheckoutButton.vue'
import { exposeScenesAbtAggregator } from 'public/src/pages/checkout/hooks/EventAggregator.js'


daEventCenter.addSubscriber({ modulecode: '1-11-1' })

export default {
  name: 'CheckoutPlaceOrder',
  components: { 
    AgreePolicyTip, 
    ActivityPopover,
    SummaryCheckoutButton,
  },
  props: {
    paypalBtnShow: {
      type: Boolean,
      default: () => {
        return false
      },
    },
    paypalBnplBtnShow: {
      type: Boolean,
      default: () => {
        return false
      },
    },
    paypalVenmoBtnShow: {
      type: Boolean,
      default: () => {
        return false
      },
    },
    installmentInfo: {
      type: Object,
      default: function () {
        return {}
      },
    },
    paypalGaVault: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isCloseActivityPopover: false,
      activityPopoverCache: [],
      alreadyExposeScene: false,
      isClient: false,
      isManualOprateClosePopover: false, // 用户手动关闭的气泡
      isTimerCloseFlag: false, // 定时器关闭的标志，用于触发closeActivityPopover方法，区分用户手动触发还是定时器触发
      activePopoverTimer: null, // 用于引用定时器，重新计时使用
      activityPopoverType: ''
    }
  },
  computed: {
    ...mapState([
      'status',
      'checkout',
      'integrityPromotionInfo',
      'promotionList',
      'language',
      'usedPrimeIndex',
      'usedXtraCode',
    ]),
    ...mapGetters([
      'showOrderRulesByAbt',
      'showOrderBubbleSort',
      'underPriceAbtValue',
      'flashSaleGroupList',
      'earliestExpiryCoupon',
      'lowestPriceBySomeDays',
      'primeClubPromotion',
      'saverCardPromotion',
      'bubbleCopyWritingByAbt',
      'lowStockCartsInfo'
    ]),
    isShowNewActivityPopverLayout() {
      return this.checkout?.checkoutBFFAbtInfo?.BubbleStyle?.param?.BubbleStyle == 'new'
    },
    // 积分
    taskPoints(){
      const isOpenTaskPoints = this.checkout?.TASK_POINTS_START == 1
      const taskPoints = isOpenTaskPoints ? this.checkout?.results.carts.data?.ug_benefit_info?.prizeValue : 0
      return !!+taskPoints ? +taskPoints : 0
    },
    // 添加对应利诱点
    activityPopoverInfo() {
      const { promotion_discount_info = [] } = this.checkout.results?.carts?.data || {}

      // 当上次命中的是超省卡、付费会员的时候，又取消掉的时候，需要关闭气泡。如果命中其他，重新唤醒 
      if (this.bubbleCopyWritingByAbt && (this.activityPopoverType == 'sheinclubpromotion' && this.usedPrimeIndex == -1) || (this.activityPopoverType == 'saverpromotion' && !this.usedXtraCode)) {
        this.handleActivityPopoverBehavior(true)
      }

      return {
        promotion: !!promotion_discount_info.find(promotion => +promotion.discount_price?.amount > 0),
        inventory: !!this.lowStockCartsInfo.lowStockProducts?.length,
        coupon: !!this.earliestExpiryCoupon,
        taskpoints: !!this.taskPoints,
        lowestprice: this.bubbleCopyWritingByAbt && !!this.lowestPriceBySomeDays?.lowestPriceData?.length,
        saverpromotion: this.bubbleCopyWritingByAbt && !!this.usedXtraCode && !!this.saverCardPromotion?.length,
        sheinclubpromotion: this.bubbleCopyWritingByAbt && this.usedPrimeIndex != -1 && !!this.primeClubPromotion?.length,
      }
    },
    activityPopoverList() {
      if(!this.showOrderRulesByAbt) return []

      let popoverList = []
      Object.entries(this.activityPopoverInfo).forEach(([key, val]) => {
        if(val) popoverList.push(key)
      })

      return popoverList
    },
    showActivityPopover() {
      if (!this.isClient) {
        return false
      }

      // 当用户手动关闭，并且ABT bubbleCopyWriting打开 的时候，即使 activityPopoverList 有数值，也不会展示
      const closeStatus =  this.bubbleCopyWritingByAbt ? (this.isManualOprateClosePopover || this.isCloseActivityPopover) : this.isCloseActivityPopover
      return !!this.activityPopoverList.length && 
        !closeStatus && 
        !!this.activityPopoverType
    },
  },
  watch: {
    activityPopoverList: {
      handler(newVal, oldVal) {
        if(!!newVal?.length && JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.getActivityPopoverCache()
          // this.handleActivityPopoverBehavior()
        }
      },

      deep: true
    },
    activityPopoverInfo: {
      handler(newVal, oldVal) {
        if(newVal && JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.handleActivityPopoverType()
          this.exposeScene()
        }
      },
      deep: true,
      immediate: true
    },
  },
  mounted() {
    this.isClient = true
    this.getActivityPopoverCache()
    if(!!this.activityPopoverCache.length) this.isCloseActivityPopover = true
  },
  methods: {
    handleActivityPopoverType() {
      if (this.bubbleCopyWritingByAbt && this.isManualOprateClosePopover) {
        return
      }

      let type = ''

      // 当超省卡、付费会员满足展示条件，优先级最高
      if (this.activityPopoverInfo.sheinclubpromotion && this.showOrderBubbleSort.includes('sheinclubpromotion') && !this.activityPopoverCache.includes('sheinclubpromotion')) {
        type = 'sheinclubpromotion'
      } else if (this.activityPopoverInfo.saverpromotion && this.showOrderBubbleSort.includes('saverpromotion') && !this.activityPopoverCache.includes('saverpromotion')) {
        type = 'saverpromotion'
      } else{
        let popoverList = []
        Object.entries(this.activityPopoverInfo).forEach(([key, val]) => {
          if (val && (!this.bubbleCopyWritingByAbt || (this.bubbleCopyWritingByAbt && !this.activityPopoverCache.includes(key)))) {
            popoverList.push(key)
          }
        })

        // 当同时满足多个利益点，则按 abt 优先级进行展示
        type = this.showOrderBubbleSort.find(value => popoverList.includes(value)) || ''
      }

      if (this.bubbleCopyWritingByAbt) {
        if (this.showActivityPopover) {
          // 当前气泡是其他的，命中超省卡、付费会员的时候，需要关闭气泡，重新打开
          if(['sheinclubpromotion', 'saverpromotion'].includes(type)) {
            this.handleActivityPopoverBehavior(true)
          } else if (!['sheinclubpromotion', 'saverpromotion'].includes(type)) {
            return
          }
        }
      }
      this.activityPopoverType = type
      this.$nextTick(() => {
        this.handleActivityPopoverBehavior()
      })
    },

    // 注意！！！此处不要有任何return事件，统一收口至最尾节点处理！！！下单按钮点击事件需要上报埋点及监控！！！
    createOrder() {
      this.$emit('createOrder')
    },
    setActivityPopoverCache() {
      let popoverList = typeof sessionStorage.getItem('checkout_activity_popover') === 'string' ? JSON.parse(sessionStorage.getItem('checkout_activity_popover')) : []
      if(!!this.activityPopoverType && !popoverList.includes(this.activityPopoverType)) {
        popoverList.push(this.activityPopoverType)
        sessionStorage.setItem('checkout_activity_popover', JSON.stringify(popoverList))
        this.activityPopoverCache = popoverList
      }
    },
    getActivityPopoverCache() {
      if(typeof window == 'undefined') return

      let popoverList = typeof sessionStorage.getItem('checkout_activity_popover') === 'string' ? JSON.parse(sessionStorage.getItem('checkout_activity_popover')) : []
      this.activityPopoverCache = popoverList
    },
    /**
     * @description: 控制气泡是否是打开还是关闭
     * @param {Boolean} flag 标志位，用于及时关闭气泡，清除定时器
     * @return {*}
     */    
    handleActivityPopoverBehavior(flag = false) {
      if (flag) {
        this.isCloseActivityPopover = true
        clearTimeout(this.activePopoverTimer)
        return
      }
      if (!!this.activityPopoverCache.length && !this.activityPopoverCache.includes(this.activityPopoverType)) {
        if (this.bubbleCopyWritingByAbt) {
          this.isTimerCloseFlag = false
          this.isCloseActivityPopover = false
        } else {
          this.isCloseActivityPopover = true
        }
      }
    },

    openActivityPopover() {
      daEventCenter.triggerNotice({
        daId: '1-11-1-179',
        extraData: {
          benefits_type: this.handleBenefitsReflectionType(),
          promotiontype: this.handlePromotionBenefitsType()
        }
      })

      this.setActivityPopoverCache()

      const { CHECKOUT_ACTIVITY_POPOVER_TIME = 8 } = this.checkout || {}
      this.activePopoverTimer = setTimeout(() => {
        this.isCloseActivityPopover = true
        this.isTimerCloseFlag = true
      }, +CHECKOUT_ACTIVITY_POPOVER_TIME * 1000)
    },
    closeActivityPopover() {
      if (this.bubbleCopyWritingByAbt && !this.isTimerCloseFlag) {
        this.isManualOprateClosePopover = true
      }
      this.isCloseActivityPopover = true

      daEventCenter.triggerNotice({
        daId: '1-11-1-180',
      })
    },
    exposeScene() {
      if(typeof window == 'undefined') return
      if(this.alreadyExposeScene) return

      if(!!this.activityPopoverType) {
        exposeScenesAbtAggregator.addEvent({
          daId: '1-11-1-169',
          extraData: {
            scenes: 'placeorderBenefits',
            benefits_type: this.handleBenefitsReflectionType(),
          }
        })
        this.alreadyExposeScene = true
      }
    },
    /**
     * @description: 处理利诱点类型的映射，主要用在埋点
     * @param {String} activityPopoverType 当前的利诱点类型
     * @return {String} 利益点类型
      */
    handleBenefitsReflectionType() {
      const benefitMapType = {
        'promotion': 'huodong',
        'inventory': 'kucun',
        'coupon': 'quan',
        'taskpoints': 'TaskPoints',
        'lowestprice': 'LowestPrice',
        'saverpromotion': 'SaverPromotion',
        'sheinclubpromotion': 'SHEINClubPromotion',
      }

      return benefitMapType[this.activityPopoverType] || ''
    },
    /**
     * @description: 当前利诱点类型是促销活动的时候，做活动映射，主要用在埋点
     * @return {String} 映射的埋点促销活动类型
     */
    handlePromotionBenefitsType() {
      let promotiontype = ''

      if (this.activityPopoverType == 'promotion') {
        if (this.promotionList?.length == 1) {
          const { typeId = -1, id } = this.promotionList[0]
          const { rules = [], flash_type, goods = {} } = this.integrityPromotionInfo?.[id]

          switch(+typeId) {
            case 1: promotiontype = 'promotion_1';break // 买折
            case 9: {
              const type = rules[0]?.type || -1

              if (type == 1) promotiontype = 'promotion_2' // 买N折N-按照原价折扣
              else if (type == 3) promotiontype = 'promotion_3' // 买N折N-按照特价折扣
              break
            }
            case 14: {
              const type = rules[0]?.type || -1
              const isAllDecrease = goods.type == 3 // goods?.type != 3 非全场 goods?.type == 3 全场
              const discountType = rules[0]?.discount?.type

              if (isAllDecrease && discountType == 6 && [3, 4].includes(+type)) promotiontype = 'promotion_4' // 全场满减-满元
              else if (isAllDecrease && discountType == 6 && [1, 2].includes(+type)) promotiontype = 'promotion_5' // 全场满减-满件
              else if (!isAllDecrease && discountType == 6 && [3, 4].includes(+type)) promotiontype = 'promotion_6' // 满减-满元
              else if (!isAllDecrease && discountType == 6 && [1, 2].includes(+type)) promotiontype = 'promotion_7' // 满减-满件
              break
            }
            case 15: promotiontype = 'promotion_8';break // X元N件-满N元
            case 16: promotiontype = 'promotion_9';break // X元N件-每满N元
            case 26: promotiontype = 'promotion_10';break // 全场满折
            case 25: promotiontype = 'promotion_11';break // 满折
            case 10: {
              if (flash_type == 1) promotiontype = 'promotion_12' // flash Sale（普通闪购
              else if (flash_type == 2) promotiontype = 'promotion_13' // New Users Only（新人闪购）
              else if (flash_type == 3) promotiontype = 'promotion_14' // EXCLUSIVE（特殊闪购
              else if (flash_type == 4) promotiontype = 'promotion_15' // 分人群定价专区闪购
              else if (flash_type == 5) promotiontype = 'promotion_16' // 不分人群定价专区闪购
              break
            }
            case 11: promotiontype = 'promotion_17';break // 直播闪购
            case 8: promotiontype = 'promotion_18';break // 专享折扣
            case 3: promotiontype = 'promotion_19';break // 限时折扣
            case 12: promotiontype = 'promotion_20';break // 会员专属折
            case 29: promotiontype = 'promotion_21';break // 付费会员
            case 30: promotiontype = 'promotion_22';break // 一口价
            case 31: promotiontype = 'promotion_23';break // 新人专享价
            case 32: promotiontype = 'promotion_24';break // AB价
            default: promotiontype = ''
          }
        } else {
          promotiontype = 'promotion_25' // 多个活动
        }
      }
      return promotiontype
    }
  },
  emits: ['createOrder'],
}
</script>

<style lang="less" scoped>
.checkout-place-btn {
  padding: 0 16px 16px;
  background: #fff;
}
</style>

<style lang="less">
.checkout-activity-popover__wrap {
  display: block;
  width: 100%;
  z-index: 199 !important;
  transform: translateZ(199px) !important;
  .sui-popover__content {
    right: 0;
    bottom: 67px;
    /* stylelint-disable-next-line declaration-no-important */
    left: auto !important;
    /* stylelint-disable-next-line declaration-no-important */
    top: auto !important;

    .sui-popover__content-arrow {
      /* stylelint-disable-next-line declaration-no-important */
      left: auto !important;
      /* stylelint-disable-next-line declaration-no-important */
      top: auto !important;
      right: 8px;
    }
    .sui-popover__close-arrow {
      right: 3px;
      top: 3px;
    }
  }
}
.c-order-summary{
	.sold-out-tip{
		margin-top: 10px;
		border: 1px solid #d53333;
    padding: 4px 10px;
    color: red;
    background-color: fade(#d53333,10%);
    line-height: 18px;
    /* stylelint-disable-next-line selector-max-type */
    strong a {
      text-transform: capitalize;
      color: red;
      text-decoration: underline;
    }
  }
}
</style>

