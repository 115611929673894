import { click_goods_list } from 'public/src/pages/cart_v2/common/analysis/item/index'
import { SToast } from '@shein-aidc/sui-toast'
import { getProductDetailUrl } from 'public/src/pages/common/utils/index'

const jumpUrls = {}
/**
 * 点击商品跳转
 */
export function useGoodsJump() {
  const jumpDetail = async ({ itemOpts: { data }, analysis = {} }) => {
    click_goods_list(data.value, analysis?.click_goods_list)
    // 付费会员赠品售罄提示语
    const payMemberGiftStockOutTips = data.aggregateProductBusiness?.productTags?.find(tag => tag.type === 'payMemberGift' && tag.style == '-1')?.view?.text || ''
    if (payMemberGiftStockOutTips) {
      SToast(payMemberGiftStockOutTips)
      return
    }
    // 事先打开一个新的窗口，Safari 不会阻止这个行为
    const newWin = window.open('', '_blank')
    let url = jumpUrls[data.value.id] || ''
    if (!url) {
      // eslint-disable-next-line require-atomic-updates
      url = jumpUrls[data.value.id] = await updateGoodsDetailUrl(data.value)
    }
    newWin.location = url
  }
  
  const updateGoodsDetailUrl = async (data) => {
    const { mall_code, product: { goods_img, goods_url_name, goods_id, cat_id }, } = data
    const url = await getProductDetailUrl({
      imgSrc: goods_img,
      goods_url_name,
      goods_id,
      cat_id,
      mall_code,
    })
    return url
  }
    

  return {
    jumpDetail,
  }
}
