<template>
  <div
    v-expose="{ id: '1-69-1-1', data: { goods_id: detail.goods_id } }"
    class="goods-detail-reviews"
  >
    <BreadCrumb />
    <div
      v-if="store_product_comments.length && Counts <= 0"
      class="common-reviews__only-store-tip"
    >
      <h2 
        class="common-reviews__only-store-tipContent"
      >
        <span>{{ language.SHEIN_KEY_PC_25740 }}</span>
        <ClientOnly>
          <SPopover
            :content="language.SHEIN_KEY_PC_25742"
            placemen="bottom"
            trigger="hover"
          >
            <template #reference>
              <Icon 
                name="sui_icon_doubt_16px_1" 
                size="16px"
                color="#767676"
              />
            </template>
          </SPopover>
        </ClientOnly>
      </h2>
      <h3>{{ language.SHEIN_KEY_PC_25741 }}</h3>
    </div>
    <!-- 评论真实性说明文案 -->
    <div
      v-if="isShowValuableReviews"
      v-expose="{ id: '1-8-5-58' }"
      class="common-reviews__valuable-reviews"
    >
      <sui_icon_certification_16px color="#198055" />
      <span class="common-reviews__valuable-tips">{{ language.SHEIN_KEY_PC_30795 || 'SHEIN encourages genuine and valuable reviews.' }}</span>
    </div>
    <!-- Customer  Reviews -->
    <Reviews />

    <!-- 底部价格 加车 -->
    <ClientOnly>
      <ProductIntroPrice
        :language="language" 
      />
    </ClientOnly>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
// import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
// import { daEventCenter } from 'public/src/services/eventCenter/index'
// import { getQueryString } from '@shein/common-function'
// import { abtservice } from './../../services/abt/index.js'
// import mitt from 'mitt'
// import { getImageRatio } from 'public/src/pages/common/utils/index.js'



// const { IS_RW } = gbCommonInfo
import { ClientOnly } from '@sheinfe/vue-client-only'
import { mapState, mapGetters } from 'vuex'
import Reviews from './components/Reviews'
import { Icon, sui_icon_certification_16px } from '@shein-aidc/icon-vue3'
import ProductIntroPrice from './components/ProductIntroPrice.vue'
import { daEventCenter } from 'public/src/services/eventCenter'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'

daEventCenter.addSubscriber({ modulecode: '1-69-1' })

export default {
  name: 'App',
  components: {
    sui_icon_certification_16px,
    ClientOnly,
    BreadCrumb: defineAsyncComponent(() => import('./components/BreadCrumb.vue')),
    Reviews,
    Icon,
    ProductIntroPrice,
  },
  directives: { tap, expose },
  data() {
    return {
    }
  },
  computed: {
    ...mapState([
      'language',
      'detail',
      'goodsDetail',
    ]),
    ...mapGetters([
      'reviews_abt',
      'screen_abt_tsp_config',
      'comments_overview',
      'store_product_comments',
      'comment_tags',
      'translate_language',
      'trail_data',
    ]),
    // 是否显示平台真实性文案说明
    isShowValuableReviews() {
      return this.reviews_abt?.Reviewchange?.param?.Comment_des === 'Yes'
    },
    // 展示的评论总数
    // * 不含站外评论
    AllCounts () {
      const { commentNumShow } = this.comments_overview

      return commentNumShow
    },
    // 评论总数, 并不精确，为了兼容之前的逻辑
    Counts() {
      return parseInt(this.AllCounts) + this.trail_data.length
    },
    showRatePanel () {
      // 评分大于0 && 有评论 && 有尺码
      return this.comments_overview.comment_rank_average > 0 && this.Counts > 0
    },
  }
}
</script>

<style lang="less">
.goods-detail-reviews{
  max-width: 1102px;
  margin: 0 auto;
  padding-bottom: 100px;
}
.common-reviews {
  margin-top: 75px;
  font-size: 12px;
  color: #767676;

  // &__guidance{
  //   font-size: 16px;
  //   color:#666666;
  //   font-weight: 400;
  //   font-family: initial;
  //   cursor: pointer;
  // }
  // &__guidance-text{
  //   margin-right: 4px;
  // }
  &__only-store-tipContent {
    color: #222;
    font-size: 24px;
    font-weight: 700;
    margin-top: 6px;
    display: flex;
    align-items: center;
    &.more-top {
      margin-top: 50px;
    }
    & > span {
      display: inline-block;
    }

    & > .sui-popover__trigger {
      height: 32px;
      margin: 0 12px;
    }
  }
  &__valuable-reviews{
    margin-bottom: 15px;
    padding: 6px 8px;
    display: flex;
    align-items: flex-start;
    background: #F5FCFB;
    font-family: system-ui;
    font-weight: 400;
  }
  &__valuable-tips{
    font-size: 12px;
    color: #198055;
    margin-left: 4px;
    line-height: 16px;
    text-transform: none;
  }

}

@media(max-width: 1440px) {
  .goods-detailv2__media {
    width: 1093px + 80px;

    & when(@IS_RW) {
      width: 1280px;
    }
  }
}

@media(max-width: 1366px) {
  .goods-detailv2__media {
    width: 994px + 80px;

    & when(@IS_RW) {
      width: 1180px;
    }
  }
}

@media(max-width: 1150px) {
  .goods-detailv2__media {
    & when(@IS_RW) {
      width: 994px + 80px;
    }
  }
}

@media(max-width: 1074px) {
  .goods-detailv2__media {
    width: 100%;
  }

  .goods-detailv2__media-inner {
    padding-left: 25px;
    padding-right: 25px;
  }
}




@media (min-width: 1366px) and (max-width: 1439px) {
  .goods-detailv2__media-small {
    width: 1074px;
    .product-intro__main {
      width: 354px;
    }
    .product-intro__gallery {
      width: 414px;
    }
    .product-intro__info {
      width: 530px;
    }
    .outfit-recommend__btn {
      padding: 8px 14px;
      font-size: 13px;
    }
  }
}

@media (min-width: 1440px) and (max-width: 1535px) {
  .goods-detailv2__media-small {
    .product-intro__main {
      width: 453px;
    }
    .product-intro__gallery {
      width: 513px;
    }
    .product-intro__info {
      width: 431px;
    }
  }
}

@media (min-width: 1536px) and (max-width: 1919px) {
  .goods-detailv2__media-small {
    .product-intro__main {
      width: 426px;
    }
    .product-intro__gallery {
      width: 486px;
    }
    .product-intro__info {
      width: 458px;
    }
  }
}

@media (min-width: 1920px) {
  .goods-detailv2__media-small {
    width: 1280px;
    .product-intro__main {
      width: 589px;
    }
    .product-intro__gallery {
      width: 649px;
    }
    .product-intro__info {
      width: 501px;
    }
    .outfit-recommend__btn {
      padding: 10px 16px;
      font-size: 12px;
    }
  }
}

@media (max-width: 1440px) {
  .goods-detailv2__media-expand {
    width: 1280px;
  }
}

@media (max-width: 1366px) {
  .goods-detailv2__media-expand {
    width: 1180px;
  }
}

@media (max-width: 1150px) {
  .goods-detailv2__media-expand {
    width: 1074px;
  }
}

@media (max-width: 1074px) {
  .goods-detailv2__media-expand {
    width: 100%;
  }
}
</style>
