<template>
  <div
    class="product-intro__add-btn"
    :class="{
      isForEstimated: isForEstimated,
    }"
  >
    <!-- ONE CLOCK PAY -->
    <ClientOnly>
      <OneClickPayBtn
        :show="showOneClickPayBtn"
        :scene="'ProductDetail'"
        :bill-info="billInfo"
        :goods-data="baseInfo"
        :quantity="quantity"
        :extra-pay-params="extraOneClickPayParams"
        :before-pay="testBeforeOCPHandle"
        :language="contentCombo.language"
        :analysis-data="oneClickPayAnalysisData"
        @pay-success="onOneClickPaySuccessHandle"
        @pay-fail="onOneClickPayFailHandle"
        @trans-complete="onOneClickPayTransComplete"
      >
        <template v-if="showOneClickPayBtn && contentConfig.showFreeShippingTips">
          <FreeShippingTips
            v-if="contentConfig.showFreeShippingTips"
            :language="contentCombo.language"
          />
        </template>
      </OneClickPayBtn>
    </ClientOnly>

    <button
      id="ProductDetailAddBtn"
      v-expose="
        validateParams.isCustomization
          ? { id: '1-8-6-177', data: { activity_from: 'main' } }
          : {}
      "
      v-tap="
        validateParams.isCustomization
          ? { id: '1-8-6-178', data: { activity_from: 'main' } }
          : {}
      "
      class="goodsDetail-btn-xl fsp-element"
      :style="{
        'transition: background 0s, border-color,0s, color 0s;': isIpad,
        'height': '54px !important',
      }"
      :class="{
        disabled: validateParams.isCurrentSoldOut,
        animated: discountLimit,
        'product-intro__shake': discountLimit,
        'she-btn-white half-width': showOneClickPayBtn,
        'she-btn-black': !showOneClickPayBtn,
        'estimated-claim-btn': isForEstimated,
      }"
      @click="handleAddToBagHandle"
    >
      <template v-if="!showOneClickPayBtn">
        <!-- 包邮 TIPS 优先展示 -->
        <FreeShippingTips
          v-if="contentConfig.showFreeShippingTips"
          :language="contentCombo.language"
        />
      </template>
      <div class="goodsDetail-btn-xl__container">
        <template v-if="addToBagLoading">
          <div
            class="la-ball-pulse"
            :class="[
              showOneClickPayBtn
                ? 'la-ball-pulse-black'
                : 'la-ball-pulse-white',
            ]"
          >
            <div></div>
            <div></div>
            <div></div>
          </div>
        </template>
        <template v-else>
          <BtnContentWithPrice
            :default-btn-text="contentText"
            :language="language"
            :countdown-tips="countdownTips"
            :is-show-countdown="isShowCountdown && !!isSatisfied"
            :best-deal-config="bestDealConfig"
            :soldout-state="soldoutState"
            :is-showflash-countdown="priceBannerTypeNormalFlashData.showCountTime"
            :flash-countdown-tips="flashCountdownTips"
            :is-show-promotion-count-down="isShowPromotionCountDown && promotionEndTime"
            :promotion-count-down-tips="promotionCountDownTips"
            :ccc-add-cart-prompt="cccAddCartPrompt"
            :discount-percent="discountPercent"
            :show-benefit-text="customerConfig.showBenefitText && showBenefitText && !validateParams.isCurrentSoldOut"
            :is-strengthen="isStrengthen"
            :is-brand-flash="isBrandFlash"
            :show-price-belt-color="showPriceBeltColor"
            :is-paid-user="isPaidUser"
            :strengthen-color="customerConfig.strengthenColor"
            :shein-club-promotion-info="sheinClubPromotionInfo"
            :is-white-btn="showOneClickPayBtn"
            :is-hit-compliance-mode="isHitComplianceMode"
            @benefit-text="getBenefitText"
          /> 
          <!-- {{ contentText }} -->
        </template>
      </div>
    </button>
  </div>
</template>

<script name="ProductIntroCommonBtn" setup>
import FreeShippingTips from '../../FreeShipping/FreeShippingTips.vue'
import { computed, ref, onMounted, onUnmounted, toRefs, watch } from 'vue'
import { handleAddToBag } from '../Compositions/addToBag'
import {
  testBeforeOCP,
  onOneClickPaySuccess,
  onOneClickPayFail,
} from '../Compositions/useOneClickPay'
import { ClientOnly } from '@sheinfe/vue-client-only'
import OneClickPayBtn from '../../OneClickPay/OneClickPayBtn.vue' // TODO 异步引入
import { parseQueryString, CountDown, debounce } from '@shein/common-function'
import BtnContentWithPrice from './BtnContentWithPrice'
import schttp from 'public/src/services/schttp'
import { useMapGetters, useMapState, useMapActions, useMapMutation } from 'public/src/pages/goods_detail_v2/store/mapHook.js'
import { genStockTips } from 'public/src/pages/goods_detail_v2/utils/inventory.js'
import { useMillSecCountDown } from '@detail/utils/countDown'

const emits = defineEmits(['commonEvents'])
const discountLimit = ref(false)

const props = defineProps({
  contentConfig: {
    type: Object,
    default: () => ({
      isIpad: false,
      isForEstimated: false,
      showOneClickPayBtn: false,
    }),
  },
  contentCombo: {
    type: Object,
    default: () => ({
      addToBagLoading: false,
      language: {},
    }),
  },
  addBagConfig: {
    type: Object,
    default: () => ({
      needOftenBWDrawer: false,
      needAddOnItemDrawer: false
    }),
  },
  contentText: {
    type: String,
    default: '',
  },
  baseInfo: {
    type: Object,
    default: () => ({}),
  },
  validateParams: {
    type: Object,
    default: () => ({}),
  },
  customerAnalysis: {
    type: Object,
    default: () => ({}),
  },
  customerConfig: {
    type: Object,
    default: () => ({
      showBenefitText: true,
      showErrorTips: true,
      pageFrom: '',
      showFreeShippingButton: true,
      openQuick: false,
      showAddWish: true,
      showTopTips: true,
      showBottomTips: true,
      needFix: true,
      oneClickPayBtn: true,
      isForEstimated: false,
      showBuyNow: true
    })
  }
})

const { 
  skuInfo,
  language,
  productIntroData,
} = useMapState([
  'skuInfo',
  'language',
  'saleAttrList',
  'productIntroData',
])
const { 
  cccAddCartPrompt,
  screenAbtConfig,
  screenAbtTspConfig,
  // bestDealConfig,
  flashGoods,
  getEstimatedInfo,
  mallCode,
  isPaidUser,
  sheinClubPromotionInfo,
  newFlashPromotion,
  lowestPriceTipsConfig,
  retentionPositionConfig,
  // priceDetect,
  isBrandFlash,
  showPriceBeltColor,
  isHitComplianceMode,
  currentMall,
  isShowPromotionCountDown,
  promotionEndTime,
  isShowCountDownMillSec,
}  = useMapGetters([
  'cccAddCartPrompt',
  'screenAbtConfig',
  'screenAbtTspConfig',
  'promotionInfo',
  // 'bestDealConfig',
  'flashGoods',
  'getEstimatedInfo',
  'mallCode',
  'isPaidUser',
  'sheinClubPromotionInfo',
  'newFlashPromotion',
  'lowestPriceTipsConfig',
  'retentionPositionConfig',
  // 'priceDetect',
  'isBrandFlash',
  'showPriceBeltColor',
  'isHitComplianceMode',
  'currentMall',
  'isShowPromotionCountDown',
  'promotionEndTime',
  'isShowCountDownMillSec',
])
const {
  isBuyNow
} = useMapGetters('AddBtn', [
  'isBuyNow'
])
const { assignState } = useMapMutation([
  'assignState',
])
const { fetchRecDataActions } = useMapActions([
  'fetchRecDataActions'
])
const isForEstimated = computed(() => props.validateParams.isForEstimated)
const showOneClickPayBtn = computed(() => props.contentConfig.showOneClickPayBtn)
const isIpad = computed(() => props.contentConfig.isIpad)
const addToBagLoading = computed(() => props.contentCombo.addToBagLoading)
const quantity = computed(() => props.baseInfo.quantity)
const billInfo = computed(() => props.baseInfo.billInfo)
const extraOneClickPayParams = computed(() => props.contentCombo.extraOneClickPayParams)
const isStrengthen = computed(() => screenAbtConfig.value?.Interestpointstyle?.p?.Interestpointstyle === 'obvious')

// 一键购埋点数据
const oneClickPayAnalysisData = computed(() => {
  const upperBillNo = parseQueryString(location.search)?.billno
  return {
    location: 'page',
    order_no: upperBillNo || '',
    activity_from: upperBillNo ? 'one_tap_pay' : '',
  }
})

const btnEventCenter = ({ type, data }) => {
  emits('commonEvents', { type, data })
}

const onOneClickPaySuccessHandle = successInfo => onOneClickPaySuccess(
  { baseInfo: props.baseInfo, validateParams: props.validateParams },
  { successInfo, btnEventCenter }
)

const testBeforeOCPHandle = () => testBeforeOCP(
  {
    baseInfo: props.baseInfo,
    validateParams: props.validateParams,
    customerAnalysis: props.customerAnalysis,
  },
  { btnEventCenter, checkNotSelectedTips: props.contentCombo.checkNotSelectedTips }
)

const onOneClickPayFailHandle = () => onOneClickPayFail(
  { baseInfo: props.baseInfo, validateParams: props.validateParams },
  { btnEventCenter }
)

// 一键购支付会话和交互结束
const onOneClickPayTransComplete = type => {
  // 失败，并加车
  if (type === 'fail') {
    handleAddToBagHandle(null, 'one_tap_pay')
  }
}

const addToBagCallbacks = {
  discountLimitOneCallback: () => {
    discountLimit.value
    daEventCenter.triggerNotice({
      daId: '1-8-6-40',
    })
    setTimeout(() => {
      discountLimit.value = false
    }, 500)
  },
  discountLimitCallback: () => {
    btnEventCenter({
      type: 'updatePromotionLimitShow',
      data: true,
    })
    daEventCenter.triggerNotice({
      daId: '1-8-6-41',
    })
  },
  prefetchRecDataActions: () => {
    fetchRecDataActions()
  },
  assignState: (payloady) => {
    assignState(payloady)
  },
}

const handleAddToBagHandle = target => {
  if (addToBagLoading.value) return
  const { checkNotSelectedTips, language } = props.contentCombo
  let customBaseInfo = {}
  if (getEstimatedInfo?.value?.isSatisfiedBuyMultiple) {
    customBaseInfo.quantity = getEstimatedInfo?.value?.buyMultiplePic
  }
  let threshold
  if (getEstimatedInfo.value?.value) {
    if (getEstimatedInfo.value?.isSatisfiedBuyMultiple) {
      threshold = 6
    } else if (getEstimatedInfo.value?.isAb) {
      threshold = 3
    } else if (getEstimatedInfo.value?.isAbCoexist) {
      threshold = getEstimatedInfo.value?.optimalCoupon?.satisfied ? 4 : 5
    } else {
      threshold = getEstimatedInfo.value?.optimalCoupon?.satisfied ? 1 : 2
    }
  }
  handleAddToBag(
    {
      baseInfo: { ...props.baseInfo, ...customBaseInfo, interestpoints: benefitText.value },
      validateParams: props.validateParams,
      addBagConfig: props.addBagConfig,
      callbacks: addToBagCallbacks,
      customerAnalysis: {
        reportData: {
          threshold,
          location: props?.baseInfo?.sku_code ? 'page_promotion' : 'popup_promotion',
        }
      }
    },
    {
      btnEventCenter,
      target,
      customerAnalysis: props.customerAnalysis,
      checkNotSelectedTips,
      language,
    }
  )
}

const millSecCountDownTimer = ref(null)

/* 
 闪购倒计时
*/
const isFlashSaleCountDownAbtHide = computed(() => screenAbtConfig.value?.FlashSaleCountDown?.p?.FlashSaleCountDown === 'Hide')
const priceBannerTypeNormalFlashData = ref({
  flashTimer: null,
  showCountTime: false,
  secondHundred: 0,
  secondInterval: null,
  countdownStr: {},
  showFlashBelt: false,
})
const flashCountdownTips = computed(() => {
  const { H, M, S } = priceBannerTypeNormalFlashData.value.countdownStr || {}
  let spanTxt = txt => `<span>${txt}</span>`
  if(!H && !M && !S) return ''
  if(isShowCountDownMillSec.value) {
    return spanTxt(H) + ':' + spanTxt(M) + ':' + spanTxt(S) + ':' + spanTxt(millSecCountDownTimer.value?.secondHundred)
  }
  return spanTxt(H) + ':' + spanTxt(M) + ':' + spanTxt(S)
})

/**
 * 单品直降活动倒计时
 */
let promotionCountDownTimer = ref(null)
const promotionCountDownTips = computed(() => {
  const { H, M, S } = promotionCountDownTimer.value?.timeObj || {}
  let spanTxt = txt => `<span>${txt}</span>`
  if(isShowCountDownMillSec.value) {
    return spanTxt(H) + ':' + spanTxt(M) + ':' + spanTxt(S) + ':' + spanTxt(millSecCountDownTimer.value?.secondHundred)
  }
  return spanTxt(H) + ':' + spanTxt(M) + ':' + spanTxt(S)
})

const initPromotionCountDownTimer = () => {
  if(!isShowPromotionCountDown.value) return
  // 倒计时开始时间
  promotionCountDownTimer.value = new CountDown()
  const beginTime = promotionEndTime.value * 1000 - new Date().getTime()
  if (beginTime <= 0 || isNaN(beginTime)) {
    beginTime == 0
  }

  // 初始化倒计时
  promotionCountDownTimer.value?.start({
    seconds: beginTime / 1000,
    endFunc: debounce({
      func: () => {
        // location.reload()
      }
    })
  })
}

const resetStatus = () => {
  priceBannerTypeNormalFlashData.value.showCountTime = false
  priceBannerTypeNormalFlashData.value.showFlashBelt = false
  priceBannerTypeNormalFlashData.value.flashTimer && priceBannerTypeNormalFlashData.value.flashTimer.clear()
  priceBannerTypeNormalFlashData.value.secondInterval && clearInterval(priceBannerTypeNormalFlashData.value.secondInterval)
}
const initPriceBannerNormalFlashCountDown = async () => {
  if (isFlashSaleCountDownAbtHide.value) return
  const { currTime: serverTime } = await schttp({ url: '/api/productInfo/currentTime/get' })
  if (flashGoods.value?.endTimestamp && flashGoods.value?.endTimestamp < serverTime) return
  priceBannerTypeNormalFlashData.value.flashTimer && priceBannerTypeNormalFlashData.value.flashTimer.clear()
  priceBannerTypeNormalFlashData.value.secondInterval && clearInterval(priceBannerTypeNormalFlashData.value.secondInterval)
  priceBannerTypeNormalFlashData.value.flashTimer = new CountDown({
    countFunc: (timeObj) => {
      const { D } = timeObj || {}
      let minDay = isBrandFlash.value ? 1 : 3
      if (Number(D) < minDay && priceBannerTypeNormalFlashData.value.showCountTime === false) {
        priceBannerTypeNormalFlashData.value.showCountTime = true
        priceBannerTypeNormalFlashData.value.secondInterval = setInterval(() => {
          priceBannerTypeNormalFlashData.value.secondHundred--
          if (priceBannerTypeNormalFlashData.value.secondHundred < 0) priceBannerTypeNormalFlashData.value.secondHundred = 9
        }, 100)
      }
      if (priceBannerTypeNormalFlashData.value.showCountTime) {
        Object.assign(priceBannerTypeNormalFlashData.value.countdownStr, timeObj)
      }
    },
    endFunc: () => {
      resetStatus()
    },
  })
  priceBannerTypeNormalFlashData.value.flashTimer.start({
    dayMode: true,
    seconds: flashGoods.value?.endTimestamp - serverTime,
  })
}
const goodsId = computed(() => props.baseInfo.goods_id)
watch(() => goodsId.value, (newVal) => {
  if(!newVal) return
  resetStatus()
  initPriceBannerNormalFlashCountDown()
})

/* 
  到手价倒计时 
*/
let countTimer = ref({})
const { isShowCountdown, bestEndTime } = toRefs(getEstimatedInfo.value?.estimatedCompProps?.config)
const { isSatisfied } = getEstimatedInfo.value

const countdownTips = computed(() => {
  const { H, M, S } = countTimer.value?.timeObj || {}
  let spanTxt = txt => `<span>${txt}</span>`
  if(!H && !M && !S) return ''
  if(isShowCountDownMillSec.value) {
    return spanTxt(H) + ':' + spanTxt(M) + ':' + spanTxt(S) + ':' + spanTxt(millSecCountDownTimer.value?.secondHundred)
  }
  return spanTxt(H) + ':' + spanTxt(M) + ':' + spanTxt(S)
})
const initTimer = () => {
  if(!isShowCountdown?.value && !!isSatisfied) return
  // 倒计时开始时间
  countTimer.value = new CountDown()
  const beginTime = bestEndTime?.value * 1000 - new Date().getTime()
  if (beginTime <= 0 || isNaN(beginTime)) {
    beginTime == 0
  }

  // 初始化倒计时
  countTimer.value?.start({
    seconds: beginTime / 1000,
    endFunc: debounce({
      func: () => {
        // location.reload()
      }
    })
  })
}

const showStockTips = computed(()=> {
  const { stock, is_absolute_low_inventory, is_relatively_low_inventory } = currentMall.value || {}
  if(stock <= 0) return false
  if(is_absolute_low_inventory || is_relatively_low_inventory) return true
  return false
})
// 文案
const soldOutTips = computed(() => {
  return genStockTips(currentMall.value, screenAbtConfig.value, screenAbtTspConfig.value, language.value, {
    isFromAddCartBtn: true
  })
})
const soldoutState = computed(() => {
  return {
    showSoldOutTips: showStockTips.value,
    soldOutTips: soldOutTips.value,
  }
})
/* 
 同款最低价
*/
const bestDealConfig = computed(() => {
  return {
    sameitemprice: retentionPositionConfig.value,
    historyprice: lowestPriceTipsConfig.value,
  }
})
/* 
 折扣说明
*/    
// 有from标签不展示折扣
// const showFrom = computed(() => {
//   return priceDetect.value && !skuInfo.value?.sku_code
// })
const discountPercent = computed(() => {
  // if(showFrom.value) return ''
  // 有到手价优先到手价，否则取售价折扣
  const { discountPercentForBelt } = getEstimatedInfo.value || {}
  if (discountPercentForBelt) return discountPercentForBelt

  const price = skuInfo.value?.mall?.[mallCode.value] ||
    productIntroData.value?.detail.mall?.[mallCode.value] || {}

  let retailDiscountPercentValue = newFlashPromotion.value?.retail_discount_percent || price?.retailDiscountPercent
  if (isPaidUser.value && sheinClubPromotionInfo.value) {
    retailDiscountPercentValue = price?.retailDiscountValue
  }
  return retailDiscountPercentValue ? retailDiscountPercentValue  + '%' : ''
})

// 获取最终显示利益点code
const benefitText = ref('')
const getBenefitText = (text) =>{
  benefitText.value = text
}
/* 双按钮不展示利益点文案 */
const showBenefitText = computed(() => (showOneClickPayBtn.value || isBuyNow.value) ? false : true)

onMounted(() => {
  initPriceBannerNormalFlashCountDown()
  initTimer()
  initPromotionCountDownTimer()
})
onUnmounted(() => {
  priceBannerTypeNormalFlashData.value.flashTimer && priceBannerTypeNormalFlashData.value.flashTimer.clear()
  priceBannerTypeNormalFlashData.value.secondInterval && clearInterval(priceBannerTypeNormalFlashData.value.secondInterval)
})

watch(() => isShowCountDownMillSec.value, (val) => {
  if (val) {
    millSecCountDownTimer.value = useMillSecCountDown()
  }
}, {
  immediate: true,
})

</script>

<style lang="less">
.product-intro {
  &__shake{
   animation-name: goodsShake;
  }
  &__add-btn {
    &.isForEstimated {
      width: 100%;
      float: none!important;
      .goodsDetail-btn-xl{
        height: 44px;
        line-height: 44px;
        font-size: 16px;
        font-family: 'Arial';
        margin-bottom: 8px;
      }
    }
    .fl();
    width: 100%;
    font-family: 'Arial Black';
    /*rw:begin*/
    font-family: 'Adieu';
    button {
      width: 100%;
      display: inline-block;
    }
    button.half-width,
    button.product-intro__one-click-pay {
      width: calc(50% - 6px);
      min-width: unset;
      font-size: 12px !important;
      padding: 0 12px !important;
    }
    .goodsDetail-btn-xl {
      position: relative;
      &__container {
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .she-btn-xl {
      position: relative;
    }
  }
}
@keyframes goodsShake {
  from,
  to {
      transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
      transform: translate3d(-6px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
      transform: translate3d(6px, 0, 0);
  }
}
</style>
