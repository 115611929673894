import schttp from 'public/src/services/schttp'
import schttpSync from 'public/src/services/schttp/sync'
// import { checkSchttp } from 'public/src/services/schttp/tools'
import { transformImg } from '@shein/common-function'
import { debuggerLog } from './helpers'
import Methods from './methods'
import { fetchPurchaseRelationApi, fetchCalculateMallApi, fetchOrderCreateApi, fetchCsrfTokenApi, fetchTradeAddPriceApi } from 'public/src/services/api/pay/oneClickPay.js'

const { SiteUID, appLanguage, lang, currency  } = gbCommonInfo

const createRequest = async ({ priceDTO1, priceDTO2, currency, scene }) => {
  if (!priceDTO1 && !priceDTO2) {
    return {}
  }
  if (!priceDTO1) {
    return priceDTO2
  }
  if (!priceDTO2) {
    return priceDTO1
  }
  return await fetchTradeAddPriceApi({
    priceDTO1,
    priceDTO2,
    currency,
    scene
  }).then(res => {
    if (res?.code != 0) return {}
    return res?.info?.resultPriceDTO
  }).catch(() => {
    return {}
  })
}

const getBrSubtotalPriceDetail = async ({ tax, totalPrice }) => {
  // 实缴个人增值税税金
  let acturalPersonVat = tax?.find(item => item.taxPayType == 2 && item.taxType == 2)?.taxPrice
  // 公司补贴增值税
  let companyVat = tax?.find(item => item.taxPayType == 1 && item.taxType == 3)?.taxPrice
  const allTradeAddPrice = [
    createRequest({
      priceDTO1: acturalPersonVat,
      priceDTO2: companyVat,
      currency,
      scene: 'sortedPriceByPc'
    }),
    createRequest({
      priceDTO1: acturalPersonVat,
      priceDTO2: totalPrice,
      currency,
      scene: 'sortedPriceByPc'
    }),
  ]
  const[icmsTotalTaxPrice, icmsSubTotalPrice] = await Promise.all(allTradeAddPrice)

  return {
    icmsTotalTaxPrice,
    icmsSubTotalPrice
  }
}

export async function getPriceSummary ({ calculateMallInfo, countryCode }) {
  const { 
    totalPrice = {},
    grandTotalPrice = {},
    tax = [], 
    handling_fee = {}, 
    onlinePayDiscountInfo = {}, 
    extraPromotion = {},
    newSubTotal = {},
    newSubTotalWithoutVat = {},
    ...otherMallInfo
  } = calculateMallInfo || {}
  // 商品售价*数量 totalPriceWithSymbol
  // 税费：tax–>taxPrice
  // 处置费：handling_fee
  // 在线支付优惠金额：onlinePayDiscountInfo，前面需要拼接【-】符号
  // 营销裂变优惠：extraPromotion--marketFission--amount【-】符号

  const getOnlinePayDiscountPrice = (info) => {
    const { discountPrice, isEnjoyedDiscount = 0 } = info || {}
    return (isEnjoyedDiscount == 1 && Number(discountPrice?.amount || '') !== 0) ? discountPrice.amountWithSymbol : 0
  }

  const getPrice = info => Number(info?.amount || '') !== 0 ? info?.amountWithSymbol : 0

  const getExtraPromotionPrice = info => {
    const { amount = {}, isValidated = 0 } = info || {}
    return isValidated == 1 && Number(amount?.amount || '') !== 0 ? amount?.amountWithSymbol : 0
  }
  let brSubtotalPriceData = {}
  if(countryCode == 'BR') {
    brSubtotalPriceData = await getBrSubtotalPriceDetail({ tax, totalPrice })
  }

  return {
    grandTotalPriceWithSymbol: getPrice(grandTotalPrice), // total
    totalPriceWithSymbol: getPrice(totalPrice), // subtotal
    handlingFee: getPrice(handling_fee),
    newSubTotal: getPrice(newSubTotal),
    newSubTotalWithoutVat: getPrice(newSubTotalWithoutVat),
    onlinePayDiscountPrice: getOnlinePayDiscountPrice(onlinePayDiscountInfo?.onlinePayDiscountInfo || {}),
    marketFission: getExtraPromotionPrice(extraPromotion?.marketFission),
    tax,
    onlinePayDiscountInfo, // 在线支付优惠信息 - 支付方式列表
    personalTaxes: tax?.filter(item => item?.taxPrice?.amount > 0 && item?.taxPayType === 2), // 个人税
    companyTaxes: tax?.filter(item => item?.taxPrice?.amount > 0 && item?.taxPayType === 1), // 公司税
    grandTotalPrice,
    icmsSubTotalPrice: brSubtotalPriceData?.icmsSubTotalPrice || {},
    icmsTotalTaxPrice: brSubtotalPriceData?.icmsTotalTaxPrice || {},
    ...otherMallInfo
  }
}

function getGoodsInfo ({
  addPurchaseRelation = {},
  goods_thumb,
  quantity
}) {
  const { all_relation_order_list = [], merge_buy_tag, merge_buy_billno } = addPurchaseRelation || {}

  let shipOrderGoods = []
  let addedOrderGoods = [{
    order_goods_sum: quantity || 1,
    goods_thumb: transformImg({ img: goods_thumb })
  }]

  function getGoodLists({ order }) {
    return order.map(item => item.goods_list).flat(Infinity).map(item => {
      return {
        ...item,
        goods_thumb: transformImg({ img: item.goods_thumb })
      }
    })
  }

  if (merge_buy_tag === 0) {
    shipOrderGoods = getGoodLists({ order: all_relation_order_list })
  } else {
    const shipOrder = all_relation_order_list.filter(item => item.billno === merge_buy_billno)
    shipOrderGoods = getGoodLists({ order: shipOrder })
  }

  return {
    shipOrderGoods,
    addedOrderGoods
  }
}


/**
 * 查询加购关系
 *
 * @export
 * @param {*} { billno }
 * @return {*} 
 */
export async function  postQueryAddPurchaseRelation ({ billno }) {
  const res = await fetchPurchaseRelationApi({
    billno: billno
  }).catch(() => {})
  return res?.info || {}
}

/**
 * 计价接口
 *
 * @export
 * @param {*} data
 * @return {*} 
 */
export function postCalculateMall (data, { isSync = false, successCb, errorCb } = { }) {
  fetchCalculateMallApi({
    isAsync: !isSync,
    opt: {
      schttp: {
        needLogin: true,
      },
      data,
    },
    successCb,
    errorCb,
  })
}

/**
 * 初始化pci环境及必要abt参数
 *
 * @export
 * @return {*} 
 */
export async function initPciTransformer () {
  try {
    const { code, info = {} } = await schttp({
      url: '/api/ocp/payConfig/get'
    })

    if (code == 300206) return { code, msg: 'need login' }

    const result = await schttp({
      url: '/ltspc/transformer/init',
      method: 'POST',
      data: {
        SiteUID,
        appLanguage,
        lang,
        AppCurrency: currency,
        TokenAes: info?.aesToken,
        _csrf: info?.csrfToken,
        siteFrom: 'pc'
      }
    })
  
    if (result?.code != 0) {
      return { code: result.code, msg: '', info }
    }
    return { code: 0, msg: '', info }
  } catch (e) {
    return { code: -1, msg: '' }
  }
}

/**
 * 聚合查询接口
 *
 * @export
 * @param {*} params
 * @return {{
 *  priceSummary: any,
 *  goodsInfo: any,
 *  paymentMethods: any[],
 *  currencySupportList: any[],
 *  isSuccess: boolean
 * }} 
 */
export async function aggregateQueryPopupInfo (params) {
  const requestParams = {
    billno: params?.billno,
    good_sn: params?.good_sn,
    goods_id: params?.goods_id,
    mall_code: params?.mall_code,
    quantity: params?.quantity,
    sku_code: params?.sku_code,
    country: params?.country_code,
    payment_no: params?.payment_no // 原订单对应主支付单
  }

  const { 
    calculateMall = {}, 
    addPurchaseRelation = {}, 
    paymentList = {},
    currencySupport = {},
    abtInfo = {},
    defaultPaymentInfo,
    ENCRYPT_KEY_URL,
    order_details = {},
    cccTaxIncludedInfo = {},
    orderInfo = {},
    applePayConfig = {}
  } = await schttp({
    method: 'POST',
    url: '/api/ocp/checkoutInfo/get',
    data: requestParams
  }).catch(() => {})

  const priceSummary = await getPriceSummary({ 
    calculateMallInfo: calculateMall?.info || {},
    countryCode: params?.country_code,
  })
  const goodsInfo = getGoodsInfo({
    addPurchaseRelation: addPurchaseRelation?.info,
    goods_thumb: params?.goods_thumb,
    quantity: params?.quantity
  })
  debuggerLog('aggregateQueryPopupInfo---', calculateMall, addPurchaseRelation, paymentList, order_details)
  return {
    priceSummary,
    goodsInfo,
    paymentMethods: paymentList?.info?.payments || [],
    currencySupportList: currencySupport,
    abtInfo,
    defaultPaymentInfo: defaultPaymentInfo || {},
    ENCRYPT_KEY_URL,
    calculateMall,
    isCalculateMallSuccess: calculateMall?.code == 0 && !!Object.keys(calculateMall?.info || {}).length,
    order_details,
    cccTaxIncludedTips: cccTaxIncludedInfo?.taxIncludesTips || '',
    orderInfo,
    applePayConfig
  }
}

export async function postCreateChannelSession (params) {
  return new Promise((resolve, reject) => {
    if (!params?.shipCountry || !params?.orderAmount) return reject(params)
    schttp({
      method: 'post',
      url: '/api/checkout/channelSession/create',
      data: params
    }).then(data => {
      if (data?.code == 0) {
        resolve(data?.info?.sessions || [])
      } else reject(data)
    }).catch(reject)
  })
}

export async function postCreateOneClickPayOrder (data) {
  await window._GB_DeviceFingerPrint.callFuncPromise()
  return new Promise((resolve, reject) => {
    let csrfToken = ''
    // 防御性代码，串行获取csrfToken
    fetchCsrfTokenApi({
      successCb: (_csrf) => {
        csrfToken = _csrf
      },
    })
    debuggerLog('csrf value---', csrfToken)
    fetchOrderCreateApi({
      isAsync: true,
      opt: {
        data: data
      },
      successCb: resolve,
      errorCb: reject
    })
  })
}

export function syncPostCreateOneClickPayOrder ({
  isAsync,
  postData,
  successCb,
  errorCb
}) {
  window._GB_DeviceFingerPrint.callFunc(() => {
    let csrfToken = ''
    // 防御性代码，串行获取csrfToken
    fetchCsrfTokenApi({
      successCb: (_csrf) => {
        csrfToken = _csrf
      },
    })
    debuggerLog('csrf value---', csrfToken)
    fetchOrderCreateApi({
      isAsync,
      opt: {
        schttp: {
          needLogin: true,
        },
        data: postData,
      },
      successCb,
      errorCb,
      csrfToken
    })
  })
}

export async function getPublicKey ({
  countryCode
} = {}) {
  const res = await schttp({
    method: 'get',
    url: '/api/ocp/pubKey/get',
    params: {
      countryCode
    }
  }).catch(() => {})
  return res || {}
}

export async function postTokenUnifiedPay (data) {
  return schttp({
    method: 'post',
    url: '/ltspc/pay/unPay/unifiedPay',
    data: Methods.unFilterTokenData(data)
  })
}

// 获取token渠道参数
export async function getCardRouteInfo (data) {
  return schttp({
    method: 'post',
    url: '/ltspc/pay/rpc/cardRouteInfo/get',
    data
  })
}

export async function postTokenUnPayComplete (data) {
  return schttp({
    method: 'post',
    url: '/ltspc/pay/unPay/complete',
    data
  })
}

export function updateAppCurrency ({ currency }) {
  return schttpSync({
    url: '/api/ocp/appCurrency/update',
    params: {
      currency
    }
  })
}

// 获取分期信息文案
export async function getInstallmentInfo (data) {
  return schttp({
    method: 'post',
    url: '/api/checkout/installmentDetail/get',
    data
  }).catch(() => {})
}
// 获取默认地址
export async function getAddressInfo(data){
  return schttp({
    method: 'get',
    url: '/api/ocp/addDefault/get',
    data
  }).catch(() => {})
}
