<template>
  <!-- 跑马-->
  <div class="shopping-bags-header_container">
    <div 
      v-if="shoppingBagTitleType == 2"
      ref="scrollTextRef"
      :class="['shopping-bags-header_title']" 
    >
      <MarqueeText
        width="100%" 
        :marquee-start="isScrollStatus" 
        :start="false"
      >
        <div class="marquee_contianer">
          <span 
            ref="scrollTextRefOne"
            :class="['one', isScrollStatus == 1 && 'one_single']"
          >
            <Icon
              v-if="!!swiperTitleConfig[0].icon?.name"
              :name="swiperTitleConfig[0].icon?.name"
              size="15px"
              class="bagsIcon"
              :style="{ color: swiperTitleConfig[0].icon?.color }"
            />
            <img
              v-else
              :src="swiperTitleConfig[0].img?.url"
              :style="swiperTitleConfig[0].img?.style"
              alt=""
              class="bagsIcon"
            />
            <span 
              :style="{
                color: swiperTitleConfig[0].text.color,
                'font-size': '12px'
              }"
            >{{ swiperTitleConfig[0].text.label }}</span>
          </span>
          <template v-if="isScrollStatus != 1 && swiperTitleConfig[1]">
            <span class="bar"></span>
            <span               
              ref="scrollTextRefTwo" 
              class="two"
            >
              <Icon
                v-if="!!swiperTitleConfig[1].icon?.name"
                :name="swiperTitleConfig[1].icon?.name"
                size="15px"
                class="bagsIcon"
                :style="{ color: swiperTitleConfig[1].icon?.color }"
              />
              <img
                v-else
                :src="swiperTitleConfig[1].img?.url"
                :style="swiperTitleConfig[1].img?.style"
                alt=""
                class="bagsIcon"
              />
              <span 
                :style="{
                  color: swiperTitleConfig[1].text.color,
                  'font-size': '12px'
                }"
              >{{ swiperTitleConfig[1].text.label }}</span>
            </span>
          </template>
  
          <!-- 无缝跑马需要copy一份，动画移动一半 -->
          <template v-if="isScrollStatus">
            <span 
              ref="scrollTextRefOne"
              :class="['one', isScrollStatus == 1 && 'one_single']"
            >
              <Icon
                v-if="!!swiperTitleConfig[0].icon?.name"
                :name="swiperTitleConfig[0].icon?.name"
                size="15px"
                class="bagsIcon"
                :style="{ color: swiperTitleConfig[0].icon?.color }"
              />
              <img
                v-else
                :src="swiperTitleConfig[0].img?.url"
                :style="swiperTitleConfig[0].img?.style"
                alt=""
                class="bagsIcon"
              />
              <span 
                :style="{
                  color: swiperTitleConfig[0].text.color,
                  'font-size': '12px'
                }"
              >{{ swiperTitleConfig[0].text.label }}</span>
            </span>
            <template v-if="isScrollStatus != 1 && swiperTitleConfig[1]">
              <span class="bar"></span>
              <span
                ref="scrollTextRefTwo" 
                class="two"
              >
                <Icon
                  v-if="!!swiperTitleConfig[1].icon?.name"
                  :name="swiperTitleConfig[1].icon?.name"
                  size="15px"
                  class="bagsIcon"
                  :style="{ color: swiperTitleConfig[1].icon?.color }"
                />
                <img
                  v-else
                  :src="swiperTitleConfig[1].img?.url"
                  :style="swiperTitleConfig[1].img?.style"
                  alt=""
                  class="bagsIcon"
                />
                <span 
                  :style="{
                    color: swiperTitleConfig[1].text.color,
                    'font-size': '12px'
                  }"
                >{{ swiperTitleConfig[1].text.label }}</span>
              </span>
            </template>
          </template>
        </div>
      </MarqueeText>
    </div>
      
    <!-- 轮播 -->
    <CarouselSwiper 
      v-else 
      :config="swiperTitleConfig"
    />
  </div>
</template>
<script setup>
import { computed, ref, defineExpose } from 'vue'
import CarouselSwiper from './CarouselSwiper.vue'
import MarqueeText from './MarqueeText.vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { debuggerLog } from 'public/src/pages/checkout/utils.js'
import { useMapGetters } from 'public/src/pages/store_pages/store/mapHook'
  
defineProps({
  swiperTitleConfig: {
    type: Array,
    default: () => ([])
  }
})
  
// const { abtInfo } = useMapState(['abtInfo'])
const { ShoppingBagStyleAbt } = useMapGetters(['ShoppingBagStyleAbt'])
  
const scrollTextRef = ref(null)
const scrollTextRefOne = ref(null)
const scrollTextRefTwo = ref(null)
const isScrollStatus = ref(0)
  
// 购物袋标题形式 0 保持线上，1 轮播， 2 双拼
const shoppingBagTitleType = computed(() => {
  // const mock = 'Show2'
  // const shoppingBagStyle = abtInfo.value?.PromotionDetailBags?.param?.ShoppingBagStyle || 'Hide'
  const mapType = {
    Hide: 0,
    Show1: 1,
    Show2: 2,
  }
  return mapType[ShoppingBagStyleAbt.value] || 0
})
  
const updateScrollTextStyle = () => {
  // 跑马灯效果
  if(shoppingBagTitleType.value == 2){
    if((scrollTextRef.value && scrollTextRefOne.value) && scrollTextRefOne.value?.clientWidth > scrollTextRef.value?.clientWidth){
      // 第一个利诱点放不下，跑马只跑第一个利诱点，第二个隐藏
      isScrollStatus.value = 1
    } else {
      const doubleClientWidth = scrollTextRefOne.value?.clientWidth + scrollTextRefTwo.value?.clientWidth
      if(doubleClientWidth && doubleClientWidth > scrollTextRef?.value.clientWidth){
        // 第一个利诱点放得下，跑马一起跑两个利诱点
        isScrollStatus.value = 2
      }
    }
  }
  debuggerLog('scrollTextRef.value.clientWidth', scrollTextRef.value?.clientWidth)
  debuggerLog('scrollTextRefOne.value.clientWidth', scrollTextRefOne.value?.clientWidth)
  debuggerLog('scrollTextRefTwo.value.clientWidth', scrollTextRefTwo.value?.clientWidth)
  debuggerLog('isScrollStatus.value', isScrollStatus.value)
}

defineExpose({ updateScrollTextStyle })
  
</script>
  
  <style lang="less">
  .shopping-bags-header_container{
      width: 100%;
      height: 100%;
      overflow: hidden;
      white-space: nowrap;
  }
  .shopping-bags-header_title{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      margin: 0 auto;
      font-size: 14px;
      font-weight: 400;
      .marquee_contianer{
          display: flex;
          .one{
              margin-right: 10px;
              display: flex;
              align-items: center;
          }
          .one_single{
            margin-right: 60px;
          }
          .bar{
              width: 1px;
              background: #FA6338;
              height: 13px;
              display: flex;
              align-self: center;
          }
          .two{
              margin-left: 10px;
              display: flex;
              align-items: center;
              margin-right: 60px;
          }
      }
  }
  
  .bagsIcon{
      margin-right: 6px;
  }
  
  </style>
  
