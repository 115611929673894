<script setup>
import { ref, computed } from 'vue'
import { SDialog } from '@shein-aidc/sui-dialog'
import { BMainCartItem, getConstant } from '@shein-aidc/bs-cart-item-laptop'
import BusinessCartMask from 'public/src/pages/common/business-cart-bff/components/BusinessCartMask.vue'
import { deleteItemReport } from '../utils/analysis'
import { isCustomizationProduct } from '../utils/index'

/**
 * 非标商品行删除弹窗
 */

const emit = defineEmits(['update:visible', 'cancel', 'confirm', 'close'])

// props
const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  },
  uiConfig: {
    type: Object,
    default: () => ({})
  },
  visible: {
    type: Boolean,
    default: false
  }
})

// data
const isShow = computed({
  get: () => props.visible,
  set: val => emit('update:visible', val)
})
const constant = ref(getConstant())
// 商品行配置
const use = computed(() => {
  const defaultUse = [
    'GoodsImg',
    'GoodsDiscountFlag',
    'GoodsBelt',
    'GoodsTitle',
    'EvoluTag',
    'SFSTag',
    'MorePromotionTag',
    'GoodsPrice',
    'DiscountNormalTag',
    'DiscountLimitTag',
    'EstimatedPrice',
    'GoodsSaleAttr',
  ]
  if (props.uiConfig.showQuickShip === '1') {
    defaultUse.push('QuickShipTag')
  }
  if (props.uiConfig.showFreeShip === '1') {
    defaultUse.push('FreeShippingTag')
  }
  if (props.uiConfig.MorePromotionTag === '1') {
    defaultUse.push('MorePromotionTag')
  }
  return defaultUse
})
const config = ref({
  GoodsImg: {
    props({ data }) {
      const imgUrl = data.value.product?.goods_img || ''
      // 是否是定制商品
      const isCustomGood = isCustomizationProduct(data.value)
      return {
        isClick: false,
        isShowMask: false,
        isLazyLoad: false,
        isCutImg: !isCustomGood,
        imgUrl,
      }
    }
  },
  // 折扣角标
  GoodsDiscountFlag: {
    props() {
      return {
        isShowMask: false
      }
    }
  },
  // 商品腰带
  GoodsBelt: {
    props() {
      return {
        isShowMask: false
      }
    }
  },
  GoodsTitle: {
    props() {
      return {
        fontSize: '12px',
        isClick: false,
        isShowMask: false,
        lineHeight: '14px',
      }
    }
  },
  // 快速发货标签
  QuickShipTag: {
    props() {
      return {
        isShowMask: false,
      }
    }
  },
  // 免邮标签
  FreeShippingTag: {
    props() {
      return {
        isShowMask: false,
      }
    }
  },
  // 环保标签
  EvoluTag: {
    props() {
      return {
        isShowMask: false,
      }
    }
  },
  // SFS标签
  SFSTag: {
    props() {
      return {
        isShowMask: false,
      }
    }
  },
  MorePromotionTag: {
    props() {
      return {
        isShowMask: false,
        disabledIcon: true,
      }
    },
  },
  // 商品价格
  GoodsPrice: {
    props({ data }) {
      return {
        isShowPremiumMemberIcon: !!data.value.aggregateProductBusiness?.priceData?.unitPrice?.icon,
        isShowSheinVipIcon: false,
        isShowRomweVipIcon: false,
        isShowTimeIcon: false,
        isShowAppOnlyIcon: false,
        isShowBrandSaleIcon: false,
        isShowFlashSaleIcon: false,
      }
    }
  },
  DiscountNormalTag: {
    props() {
      return {
        isShowMask: false,
      }
    },
  },
  DiscountLimitTag: {
    props() {
      return {
        isShowMask: false,
      }
    },
  },
  // 到手价
  EstimatedPrice: {
    props() {
      return {
        isShowMask: false,
        hideCountdown: true,
      }
    }
  },
  // 商品销售属性
  GoodsSaleAttr: {
    props() {
      return {
        type: 'text',
        size: '12px',
        height: '15px',
        isClick: false,
        isShowMask: false,
      }
    },
  },
})

// methods
const handleCancel = () => {
  emit('cancel', props.data)
  isShow.value = false
}
const handleConfirm = () => {
  emit('confirm', props.data)
  isShow.value = false
}
const handleClose = () => {
  emit('close', props.data)
  isShow.value = false
}
const handleExpose = () => {
  deleteItemReport({ item: props.data })
}
</script>

<template>
  <div
    v-if="isShow"
    class="bc-item-del"
  >
    <BusinessCartMask
      class="bc-item-del__mask"
      @click="handleClose"
    />
    <SDialog
      v-model:visible="isShow"
      :show-close="false"
      :modal="false"
    >
      <template #title>
        {{ language.SHEIN_KEY_PC_30038 }}
      </template>
      <div
        class="bc-item-del__item"
      >
        <BMainCartItem
          :use="use"
          :config="config"
          :data="data"
          :language="language"
          :constant="constant"
          @expose="handleExpose"
        />
      </div>
      <template #footer>
        <SButtonGroup
          :width="'100%'"
          :num="2"
          :hor="true"
        >
          <SButtonGroupItem
            @click="handleCancel"
          >
            {{ language.SHEIN_KEY_PC_30061 }}
          </SButtonGroupItem>
          <SButtonGroupItem
            :type="['primary']"
            @click="handleConfirm"
          >
            {{ language.SHEIN_KEY_PC_30060 }}
          </SButtonGroupItem>
        </SButtonGroup>
      </template>
    </SDialog>
  </div>
</template>

<style lang="less" scoped>
.bc-item-del {
  &__item {
    width: 416px;
    background-color: #fff;
    padding: 12px;
  }
  &__mask {
    z-index: 2;
    border-radius: 12px 12px 0 0;
  }
  /deep/ .sui-dialog .sui-dialog__wrapper {
    padding: 19px 32px 22px 32px;
    width: 480px;
    min-height: 258px;
    background-image: var(--delete-background-img);
    background-size: cover;
  }
  /deep/ .sui-dialog .sui-dialog__title-wrap {
    padding: 0 32px;
    background: transparent;
  }
  /deep/ .sui-dialog .sui-dialog__title {
    white-space: nowrap;
    text-overflow: ellipsis;
    background: transparent;
    font-size: 18px;
    line-height: 21px;
    font-weight: bold;
    margin-bottom: 17px;
    color: var(--cart-delete-title-color);
    overflow: hidden;
  }
  /deep/ .sui-dialog .sui-dialog__body {
    padding: 0;
    min-height: 114px;
    margin-bottom: 21px;
    text-align: left;
  }
  /* stylelint-disable-next-line selector-class-pattern */
  /deep/ .bsc-cart-item-main__left {
    margin-right: 10px;
    width: 90px;
    height: 90px;
  }
  /* stylelint-disable-next-line selector-class-pattern */
  /deep/ .bsc-cart-item-main__right-top {
    margin-bottom: 6px;
  }
  /* stylelint-disable-next-line selector-class-pattern */
  /deep/ .bsc-cart-item-main__right {
    min-height: 80px;
  }
  /* stylelint-disable-next-line selector-class-pattern */
  /deep/ .bsc-cart-item-main__info {
    margin-bottom: 6px;
  }
}
</style>
