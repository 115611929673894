<template>
  <s-dialog
    v-model:visible="showDialog"
    :append-to-body="true"
    :show-close="true"
    type="W720"
    class="custom-content-dialog"
    @open="onOpen"
    @close="onClosed"
  >
    <template
      #title
    >
      {{ customizationInfo.customizationContentTitle }}
    </template>

    <div
      class="custom-content-dialog__body"
    >
      <div
        v-if="customizationInfo.resizeEffects && customizationInfo.resizeEffects.length"
        class="custom-content-module"
      >
        <h6 class="custom-content-module__title">
          {{ customizationInfo.effectsTip }}
        </h6>
        <div class="image-preview-list">
          <img
            v-for="(item, index) in customizationInfo.resizeEffects"
            :key="index"
            v-tap="{
              id: '2-33-3',
            }"
            :src="item"
            class="image-preview-item"
            @click="onPreviewImage(index)"
          />
        </div>
      </div>

      <div
        v-if="customizationInfo.texts.length"
        class="custom-content-module"
      >
        <h6 class="custom-content-module__title">
          {{ customizationInfo.textsTip }}
        </h6>
        <div class="copywriting-detail-list">
          <span
            v-for="(item, index) in customizationInfo.texts"
            :key="index"
            class="copywriting-detail-item"
          >
            {{ item }}
          </span>
        </div>
      </div>

      <div
        v-if="customizationInfo.resizeImages && customizationInfo.resizeImages.length"
        class="custom-content-module"
      >
        <h6 class="custom-content-module__title">
          {{ customizationInfo.imagesTip }}
        </h6>
        <div class="material-detail-list">
          <img
            v-for="(item, index) in customizationInfo.resizeImages"
            :key="index"
            v-tap="{
              id: '2-33-3',
            }"
            class="material-detail-item"
            :src="item"
            @click="onPreviewImage(index + (customizationInfo.resizeEffects?.length || 0))"
          />
        </div>
      </div>
    </div>

    <template
      #footer
    >
      <s-button
        :type="['primary', 'H44PX']"
        @click="showDialog = false"
      >
        {{ customizationInfo.okTip }}
      </s-button>
    </template>
  </s-dialog>

  <ImageViewDialog
    v-model:visible="showPreviewDialog"
    :active-index="activeIndex"
    :effects-tip="customizationInfo.effectsTip"
    :images-tip="customizationInfo.imagesTip"
    :resize-urls="previewResizeUrls"
    :origin-urls="previewOriginUrls"
  />
</template>

<script>
import { uniqueId } from 'lodash'
import { daEventCenter } from 'public/src/services/eventCenter'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import ImageViewDialog from './ImageViewDialog.vue'
import { transformImg } from '@shein/common-function'

daEventCenter.addSubscriber({ modulecode: '2-33' })

export default {
  name: 'CustomContentDialog',
  components: {
    ImageViewDialog,
  },
  directives: {
    expose,
    tap,
  },
  data() {
    return {
      activeIndex: 0,
      showDialog: false,
      showPreviewDialog: false,
      options: {},
    }
  },
  computed: {
    customizationInfo() {
      const data = this.options?.data || {}
      return {
        ...data,
      }
    },
    previewResizeUrls() {
      const { resizeEffects = [], resizeImages = [] } = this.customizationInfo
      const newEffects = resizeEffects.map(url => ({ url, type: 1, id: uniqueId('effect') }))
      const newImages = resizeImages.map(url => ({ url, type: 2, id: uniqueId('image') }))
      return [...newEffects, ...newImages]
    },

    previewOriginUrls() {
      const { originEffects = [], originImages = [] } = this.customizationInfo
      const newEffects = originEffects.map(url => ({ url, type: 1, id: uniqueId('effect') }))
      const newImages = originImages.map(url => ({ url, type: 2, id: uniqueId('image') }))
      return [...newEffects, ...newImages]
    },
  },
  methods: {
    transformImg,
    getAnalysisData() {
      const { originEffects = [], texts = [], originImages = [] } = this.customizationInfo
      const params = {
        preview: originEffects.length || 0,
        texts: texts.length || 0,
        images: originImages.length || 0,
      }
      if (this.options?.analysisData) {
        Object.assign(params, this.options.analysisData)
      }
      return params
    },
    triggerCallback(callbackName, data) {
      if (typeof this.options[callbackName] === 'function') {
        this.options[callbackName](data)
      }
    },
    onPreviewImage(index) {
      this.activeIndex = index
      this.showPreviewDialog = true
      this.triggerCallback('preview', index)
    },
    open(options) {
      this.options = options
      this.showDialog = true
    },
    close() {
      this.showDialog = false
    },
    onOpen() {
      daEventCenter.triggerNotice({
        daId: '2-33-1',
        extraData: this.getAnalysisData(),
      })
      this.triggerCallback('open')
    },
    onClosed() {
      daEventCenter.triggerNotice({
        daId: '2-33-2',
      })
      this.triggerCallback('close')
    },
  },
}
</script>

<style lang="less">
.custom-content-dialog {
  &__body {
    padding: 12px;
    border: 1px solid @sui_color_gray_weak1;
  }

  .image-preview-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 4px;
  }

  .image-preview-item {
    width: 61px;
    height: 61px;
    object-fit: cover;
    cursor: pointer;
  }

  .copywriting-detail-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 8px;
  }

  .copywriting-detail-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    color: @sui_color_gray_dark2;
    font-family: 'SF UI Text';
    font-size: 12px;
    background: @sui_color_gray_weak2;
  }

  .material-detail-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 10px;
  }

  .material-detail-item {
    width: 67px;
    height: 67px;
    object-fit: cover;
    cursor: pointer;
  }
}

.custom-content-module {
  & + & {
    margin-top: 24px;
  }

  &__title {
    color: @sui_color_gray_dark3;
    font-family: 'SF UI Text';
    text-transform: capitalize;
    word-break: break-all;
    margin-bottom: 8px;
  }
}
</style>
