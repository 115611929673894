
import { getCurrentInstance, computed, onMounted, onUnmounted, ref, watch } from 'vue'

import { useRegisterDomExpose } from 'public/src/pages/detail/main/hooks/recommend.composition.js'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import eventBus from 'public/src/pages/goods_detail_v2/utils/eventBus.js'
import { useMapGetters } from 'public/src/pages/detail/main/hooks/vuex.composition.js'

export const BaseAnalysisInfo = Object.freeze({
  from: 'customers_also_viewed',
  module: 'module',
  code: 'recommendProductDetailCustomersAlsoViewedList',
  posKey: 'sixmore,ymalrecommend,ProductDetailRecommend',
  posKeyBase: 'ymalrecommend,ProductDetailRecommend',
  daExposeCode: 'recommendProductDetailCustomersAlsoViewedList',
  daEventExposeId: '2-3-2', //  商品项目曝光
})

export const ERecommendScene = Object.freeze({
  /**
     * 整合所有类目、混品
     */
  'allCate': 'allCate',
  /**
     * youMayLike
     */
  'youMayLike': 'youMayLike',
  /**
     * obw
     */
  'oftenBought': 'oftenBought'
})

const getRecommendConfig = async () => {

  const { sixmore } = (await getUserAbtData() || {})
  const {
    /**
     * three（首次3行，后续每次3行，也就是原商详）
     * eight （首次10行，后续每次10行）
     */
    load = 'three',
    /**
     * five （一行5个+间距调整）
     * six（一行6个+间距调整）
     * oldfive 此方案默认（一行5个+间距不调整，也就是原商详）
     */
    quantity = 'oldfive',
    /**
     * one 旧场景100个，新场景100个
     * two 旧场景100个，新场景200个
     * five 旧场景100个，新场景500个
     */
    qty = 'five',
  } = sixmore?.param || {}

  // 每行展示商卡数量
  const renderRowNum = quantity === 'six' ? 6 : 5

  const bottomInfoStyle = {
    minHeight: quantity !== 'oldfive' ? 'auto' : '98px'
  }

  const _recommendLimitMap = {
    // pc底部推荐新场景，对应的最大商品数配置
    one: 100,
    two: 200,
    five: 500
  }
  // 请求总数
  const totalLimit = _recommendLimitMap[qty] || 500
  // 单次请求数
  const limit = load === 'eight' ? 10 * renderRowNum : 3 * renderRowNum

  return {
    limit,
    totalLimit,
    renderRowNum,
    bottomInfoStyle,
  }
}

export const useRecommendConfig = () => {
  const recommendConfig = ref({
    limit: 15,
    totalLimit: 100,
    renderRowNum: 5,
    bottomInfoStyle: {},
  })

  const loadRecommendConfig = async () => {
    recommendConfig.value = await getRecommendConfig()
  }

  const cItemStyle = computed(() => {
    const { renderRowNum = 5 } = recommendConfig.value
    const style = {
      width: `${Number(100 / renderRowNum).toFixed(7)}%`,
    }
  
    return style
  })

  return {
    loadRecommendConfig,
    recommendConfig,
    cItemStyle,
  }
}


  
/**
   * 加车后未曝光状态刷新底部推荐位实验
   */
export const useAfterAddCartRefresh = ({ onRefresh }) => {
  
  const vm = getCurrentInstance()
  const isUseAddCartParams = ref(false)

  // 是否曝光过
  let isRecommendView = false
  
  const _isValidRefresh = () => !isRecommendView

  const { base_info } = useMapGetters('Adaptor', [
    'base_info',
  ])
  
  const _regExposeWatcher = () => {
    useRegisterDomExpose({
      $el: vm?.proxy?.$el,
      callback: () => {
        // 组件曝光过
        // console.log('useRegisterDomExpose callback')
        isRecommendView = true
      }
    })
  }
  onMounted(() => {
    _regExposeWatcher()
  })
  
  // goods_id变化时，重置状态
  watch(() => base_info.value.goods_id, () => {
    // 曝光过会销毁曝光实例，要重新注册曝光监听
    if (isRecommendView) {
      _regExposeWatcher()
    }
    isRecommendView = false
    isUseAddCartParams.value = false
  })

  // 组件渲染时，判断是否满足增加参数参数
  if (_isValidRefresh()) {
    isUseAddCartParams.value = true
  }

  const onRefreshData = () => {
    // 主商品加车成功后，底部推荐位未曝光，需要刷新
    setTimeout(() => {
      if (isRecommendView) return
      isUseAddCartParams.value = true

      onRefresh()
    }, 0)
  }

  // 监听加车刷新事件
  eventBus.on('addBagToRefreshRecomendList', onRefreshData)
  onUnmounted(() => {
    eventBus.off('addBagToRefreshRecomendList', onRefreshData)
  })
  
  return {
    isUseAddCartParams,
  }
}

