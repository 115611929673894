<template>
  <div
    v-if="props.text"
    ref="textRef"
    class="bsc-text-adaptation"
    :style="{
      fontSize: `${props.sizeLimits?.[1]}px`,
      maxWidth: props.maxWidth
    }"
  >
    {{ props.text }}
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'

const textRef = ref()
const props = defineProps<{
  sizeLimits: number[]
  text: string | number
  maxWidth: string
}>()

const scaleFontSize = () => {
  const { offsetWidth, scrollWidth } = textRef.value
  const isOver = scrollWidth > offsetWidth
  const size = parseInt(textRef.value.style.fontSize)
  if (isOver && size >= props.sizeLimits?.[0]) {
    textRef.value.style.fontSize = `${size - 1}px`
    scaleFontSize()
  }
}

watch(() => [props.text, props.sizeLimits, textRef.value], () => {
  const { text, sizeLimits } = props
  if (!text || !sizeLimits || !textRef.value) return
  if (sizeLimits.length !== 2) {
    console.error('please input [min max] number to font-size')
    return
  }
  scaleFontSize()
})

</script>
<style lang="less">
.bsc-text-adaptation {
  overflow: hidden;
}
</style>
