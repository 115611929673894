import { has } from 'lodash'
const { lang, PUBLIC_CDN, RU_STORE_LAST_LOGISTICS } = gbCommonInfo

/**
 * 宅配或者店配使用谷歌服务的国家
 * @param {Array} fields 0: 解析的字段, 1: 转换的结果, 2: 取值字段(short_name | long_name)
 *
 * */
let GOOGLE_DATA_MAP = {
  // New Zealand
  153: {
    country: 'nz',
    fields: [
      ['administrative_area_level_1', 'ProvinceName'],
      ['locality', 'City'],
      ['postal_code', 'PostalCode'],
      ['sublocality_level_1', 'Line2'],
    ],
  },
  // Denmark
  58: {
    country: 'dk',
    fields: [
      ['administrative_area_level_1', 'ProvinceName'],
      ['locality', 'City'],
      ['postal_code', 'PostalCode'],
    ],
  },
  // Austria
  14: {
    country: 'at',
  },
  // Poland
  172: {
    country: 'pl',
  },
  // Switzerland
  207: {
    country: 'ch',
  },
  // Canada
  38: {
    country: 'ca',
    fields: [
      ['administrative_area_level_1', 'ProvinceName'],
      [
        ({ components = {} }) => {
          const isOntario =
            components?.administrative_area_level_1?.long_name === 'Ontario'
          const locality = components?.locality?.long_name
          if (!isOntario) {
            return locality
          }
          const hasSubLocalityLevel1 = has(components, 'sublocality_level_1')
          if (!hasSubLocalityLevel1) {
            return locality
          }
          const subLocalityLevel1 = components?.sublocality_level_1?.long_name
          return subLocalityLevel1 === 'Old Toronto'
            ? locality
            : subLocalityLevel1
        },
        'City',
      ],
      ['postal_code', 'PostalCode'],
    ],
  },
  // Greece
  85: {
    country: 'gr',
    fields: [
      ['administrative_area_level_3', 'ProvinceName'],
      ['locality', 'City'],
      ['postal_code', 'PostalCode'],
    ],
  },
  // Belgium
  21: {
    country: 'be',
    fields: [
      ['administrative_area_level_1', 'ProvinceName'],
      ['locality', 'City'],
      ['postal_code', 'PostalCode'],
    ],
  },
  // Taiwan
  209: {
    country: 'tw',
    language: 'zh-TW',
    fields: [
      [['administrative_area_level_1', 'administrative_area_level_2'], 'City'],
      ['administrative_area_level_3', 'District'],
      ['postal_code', 'PostalCode'],
    ],
  },
  // Thailand
  212: {
    country: 'th',
    fields: [
      ['administrative_area_level_1', 'ProvinceName'],
      [['administrative_area_level_2', 'sublocality_level_1'], 'City'],
      [
        [
          'administrative_area_level_3',
          'sublocality_level_1',
          'sublocality_level_2',
          'locality',
        ],
        'District',
      ],
      ['postal_code', 'PostalCode'],
    ],
  },
  // Saudi Arabia
  186: {
    country: 'sa',
    language: lang === 'ar' ? 'ar' : 'en',
    bounds: {
      north: 32.15,
      south: 16.38,
      west: 34.51,
      east: 55.66,
    },
    region: 'SA',
    latLng: { lat: 24.73, lng: 46.67 },
    fields: [
      ['administrative_area_level_1', 'state'],
      ['administrative_area_level_2', 'city'],
      ['sublocality_level_1', 'district'],
      ['postal_code', 'postcode'],
      ['route', 'street'],
      ['country', 'countryCode', 'short_name'],
    ],
  },
}

// 宅配不使用但店配使用谷歌服务国家
const SHOP_TRANSIT_GOOGLE_DATA_MAP =
  gbCommonInfo?.SHOP_TRANSIT_GOOGLE_DATA_MAP || {
    150: {
      country: 'nl',
    },
    106: {
      country: 'it',
    },
    74: {
      country: 'fr',
      fields: [
        ['locality', 'City'],
        ['postal_code', 'PostalCode'],
        ['country', 'countryCode', 'short_name'],
      ],
    },
    82: {
      country: 'de',
    },
    225: {
      country: 'uk',
    },
    // AU
    13: {
      country: 'au',
    },
    // pt
    173: {
      country: 'pt',
    },
    // es
    198: {
      country: 'es',
    },
    // se
    206: {
      country: 'se',
      fields: [['postal_code', 'PostalCode']],
    },
    // sg
    191: {
      country: 'sg',
    },
    // hu
    98: {
      country: 'hu',
    },
    // cz
    57: {
      country: 'cz',
    },
  }


// 谷歌联想默认展示数据
Object.entries(GOOGLE_DATA_MAP).forEach(([id, value]) => {
  if (!value.fields) {
    GOOGLE_DATA_MAP[id] = {
      ...value,
      fields: [
        ['administrative_area_level_1', 'ProvinceName'],
        ['locality', 'City'],
        ['postal_code', 'PostalCode'],
      ],
    }
  }
})

// 针对某些国家单独处理逻辑
const COUNTRY_IDS = {
  // 去掉所有空格
  postcode: ['206'],
  // 地址2拼接门牌号
  building: ['82', '150'],
  // 超过校验地址长度，裁切地址1到地址2
  maxLength: {
    225: 35,
    226: 30,
    137: 35,
    13: 40,
    198: 40,
    74: 70,
    82: 50,
    106: 35,
    150: 30,
    206: 45,
    153: 100,
    58: 35,
    14: 35,
    172: 70,
    207: 100,
    38: 35,
  },
}

const MAP_MARKER_ICONS = {
  icon1: `${PUBLIC_CDN}/she_dist/images/google-map-icon1-154ff31b9a.png`, // 默认定位
  icon2: `${PUBLIC_CDN}/she_dist/images/google-map-icon2-61598c4905.png`, // 选中定位
  icon3: `${PUBLIC_CDN}/she_dist/images/google-map-icon3-f82bef7fb7.png`, // 我的定位
}

const countryConfigs = gbCommonInfo?.SHOP_COUNTRY_CONFIGS || {
  74: {
    country: 'fr',
    postcode: /^\d{5}$/,
    latLng: { lat: 48.878503, lng: 2.329555 },
  },
  21: {
    country: 'be',
    postcode: /^[0-9]{4}$/,
    latLng: { lat: 50.8551286, lng: 4.2195296 },
  },
  191: {
    country: 'sg',
    postcode: /^[0-9]{6}$/,
    latLng: { lat: 1.3521, lng: 103.8198 },
  },
  98: {
    country: 'hu',
    postcode: /^[0-9]{4}$/,
    latLng: { lat: 47.4979, lng: 19.0402 },
  },
  57: {
    country: 'cz',
    postcode: /^[0-9]{3}\s[0-9]{2}$/,
    latLng: { lat: 50.088, lng: 14.42 },
  },
  // UK
  225: {
    country: 'uk',
    postcode:
      /^([A-PR-UWYZ0-9][A-HK-Y0-9][A-Z0-9]?[A-Z0-9]? {1}[0-9][ABD-HJLN-UW-Z]{2}|GIR 0AA)$/i,
    latLng: { lat: 51.507351, lng: -0.127758 },
  },
  // ES
  198: {
    country: 'es',
    postcode: /^[0,1,2,3,4,5]\d{4}$/,
    latLng: { lat: 40.416775, lng: -3.70379 },
  },
  // DE
  82: {
    country: 'de',
    postcode: /^\d{5}$/,
    latLng: { lat: 52.520008, lng: 13.404954 },
  },
  // AU
  13: {
    country: 'au',
    postcode: /^[0-9]\d{3}$/,
    latLng: { lat: -35.282128, lng: 149.12877 },
  },
  // RU
  178: {
    country: 'ru',
    postcode: '',
    latLng: { lat: 55.916666666666664, lng: 37.61666666666667 },
  },
  172: {
    country: 'pl',
    postcode: /^[0-9]{2}-[0-9]{3}$/,
    latLng: { lat: 52.13, lng: 21.0 },
  },
  150: {
    country: 'nl',
    postcode: /^\d{4}\s{1}[a-zA-Z]{2}$/,
    latLng: { lat: 52.3388737, lng: 4.8758616 },
  },
  // it
  106: {
    country: 'it',
    postcode: /^((I-)|(IT-)){0,1}\d{5}$/,
    latLng: { lat: 41.9102415, lng: 12.3959151 },
  },
  // pt
  173: {
    country: 'pt',
    postcode: /^\d{4}-\d{3}$/,
    latLng: { lat: 38.8026178, lng: -9.6600585 },
  },
  // dk
  58: {
    country: 'dk',
    postcode: /^[0-9]{4}$/,
    latLng: { lat: 55.6713366, lng: 12.5114242 },
  },
  // se
  206: {
    country: 'se',
    postcode: /^[0-9]{5}$/,
    latLng: { lat: 59.3259449, lng: 17.6702654 },
  },
}

const RU_STORE_LOCATION_TYPE = {
  1: {
    key: 'Parcel Locker',
    value: 'parcel_locker',
  },
  2: {
    key: 'Pick-up Store',
    value: 'pick_up_store',
  },
  3: {
    key: 'Parcel Locker',
    value: 'parcel_locker',
  },
  4: {
    key: 'Postal Point',
    value: 'postal_point',
  },
  5: {
    key: 'Postal Office',
    value: 'postal_office',
  },
  6: {
    key: 'Pick-up Store',
    value: 'pick_up_store',
  },
}

// const RU_STORE_LAST_LOGISTICS = {
//   fivepost: '//img.shein.com/images3/2021/10/20/5post.png',
//   sberlogistics: '//img.shein.com/images3/2021/10/20/sebr.png',
//   DPD: '//img.shein.com/images3/2021/10/20/dpd.png',
//   cainiaoruPUDO: '//img.shein.com/images3/2021/10/20/CAINIAO.png',
//   cdek: '//img.shein.com/images3/2021/10/20/CDEK.png',
//   'CDEK_self-pickup': 'https://img.shein.com/images3/2021/10/20/CDEK.png'
// }

export const TW_NAME_VERIFIED_ABT_KEY = 'Twmustnameverified'

// 所有使用谷歌地址服务的国家（地址联想和经纬度查询）
const GOOGLE_DATA = {
  ...GOOGLE_DATA_MAP,
  ...SHOP_TRANSIT_GOOGLE_DATA_MAP,
}

/** 用fname模拟fullName的店配国家 */
const FNAME_TO_FULLNAME_COUNTRY = [191]

/** 风控页面 */
const RISK_CONTROL_PAGE = {
  1: ['orderlist', 'order_list'],
  2: ['orderDetails', 'order_details'],
  3: ['ordersTrack'],
  4: ['page_abnormal_orderlist'],
  5: ['page_payment_successful', 'pay_success'],
  6: ['checkout_again'],
  7: ['refundReason'],
}

export {
  GOOGLE_DATA_MAP,
  COUNTRY_IDS,
  MAP_MARKER_ICONS,
  countryConfigs,
  RU_STORE_LOCATION_TYPE,
  RU_STORE_LAST_LOGISTICS,
  GOOGLE_DATA,
  FNAME_TO_FULLNAME_COUNTRY,
  RISK_CONTROL_PAGE
}
