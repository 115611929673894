import errorReport from '@/public/src/pages/common/handlePay/log.js'

export function getDeviceInfo() {
  return {
    colorDepth: window.screen.colorDepth,
    javaEnabled: window.navigator.javaEnabled() ? 1 : 0,
    language: window.navigator.language,
    screenHeight: window.screen.height || 0,
    screenWidth: window.screen.width || 0,
    timeZoneOffset: new Date().getTimezoneOffset(),
    userAgent: window.navigator.userAgent,
    origin: window.location.origin
  }
}

/**
 * 整合处理二次下单页url
 *
 * @export
 * @param {*} [{ billno = '' }={}]
 * @return {{
 *  pc: string,
 *  pwa: string
 * }} 
 */
export function getCheckoutAgainUrl({
  billno = '',
  checkoutType = ''
} = {}) {
  const { host, langPath } = gbCommonInfo
  const originSite = host + langPath
  const productType = getProductType({ type: checkoutType })
  if (productType === 'gift_card') {
    return {
      pc: `${originSite}/giftcard/checkout/checkout_again/${billno}`,
      pwa: `${originSite}/giftcard/checkout/again/${billno}`,
    }
  }
  if (productType === 'paid_membership') {
    return {
      pc: `${originSite}/buyprime_again/${billno}`,
      pwa: `${originSite}/user/orders/virtualDetail/${billno}`,
    }
  }
  if (productType === 'paid_shein_saver') {
    return {
      pc: `${originSite}/xtra_again/${billno}`,
      pwa: `${originSite}/user/orders/virtualDetail/${billno}`,
    }
  }
  return {
    pc: `${originSite}/checkout/checkout_again/${billno}`,
    pwa: `${originSite}/user/orders/detail/${billno}`,
  }
}

export function getProductType({ type = '' } = {}) {
  if (type === 'giftcard') return 'gift_card'
  if (type === 'buyprime') return 'paid_membership'
  if (type === 'xtra') return 'paid_shein_saver'
  if (type === 'ocp') return 'one_click_pay'
  return 'normal'
}

export function debuggerLog(...args) {
  if (typeof window === 'undefined') return
  if (gbCommonInfo?.NODE_SERVER_ENV === 'production') return
  try {
    console.info(
      '%cPay Debug Info:',
      'background:#35495e ; padding: 3px 3px; border-radius: 3px;  color: #fff',
      ...args,
      '\n'
    )
  } catch (e) {
    console.log(...args)
  }
}

export function makeForm (params, action, method) {
  if (typeof window == 'undefined') return

  let oldForm = document.querySelector('form[name="pay_jump_form"]')
  oldForm?.parentNode?.removeChild(oldForm)

  method = method || 'post'

  let form = document.createElement('form')
  form.setAttribute('name', 'pay_jump_form')
  form.setAttribute('action', action)
  form.setAttribute('method', method)

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      let input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', key)
      input.setAttribute('value', params[key])
      form.appendChild(input)
    }
  }

  let submitInput = document.createElement('input')
  submitInput.setAttribute('type', 'submit')
  submitInput.setAttribute('value', 'Continue')
  submitInput.style.display = 'none'
  form.appendChild(submitInput)

  document.body.appendChild(form)
  document.pay_jump_form?.submit?.()
}
export const checkoutMarkPoint = new (class CheckoutMarkPoint {
  sceneDict = [
    'CheckoutCreateOrderSuccess',
    'EnterHandlePayFunc',
    'JumpPciFormSumit'
  ]

  sceneDurationSecondsMap = new Map(this.sceneDict.map(key => [key, NaN]))

  _setSceneDurationSeconds (scene = '', curIdx) {
    const firstScene = this.sceneDict[0]
    if (curIdx === 0) this.sceneDurationSecondsMap = new Map(this.sceneDict.map(key => [key, NaN]))
    this.sceneDurationSecondsMap.set(scene, Date.now())
    debuggerLog(scene, ' time => ', this.sceneDurationSecondsMap.get(scene))
    if (scene !== firstScene) {
      debuggerLog(firstScene, ' - ', scene, ' diff time => ', this.sceneDurationSecondsMap.get(scene) - this.sceneDurationSecondsMap.get(firstScene))
    }
  }

  markDurationSeconds  ({
    scene,
    reportData = {}
  } = {}) {
    try {
      const curIdx = this.sceneDict.indexOf(scene)
      // 如果第一条记录不存在则后续操作不做记录
      if (curIdx > 0 && !this.sceneDurationSecondsMap.get(this.sceneDict[0])) {
        return
      }
      if (curIdx === 0) {
        let timeout = setTimeout(() => {
          const lastIdx = this.sceneDict.length - 1
          if (!this.sceneDurationSecondsMap.get(this.sceneDict[lastIdx])) {
            const lastPoint = this.sceneDict.findLast(v => this.sceneDurationSecondsMap.get(v))
            errorReport.sendBusinessInfo('create_order_suc_jump_pci_error', {
              product_type: getProductType({ type: reportData?.checkout_type || '' }),
              extra_scene: lastPoint,
              error_data: {
                order_no: reportData?.billno,
                payment_method: reportData?.payment_method
              }
            })
          }
          this.sceneDurationSecondsMap = new Map(this.sceneDict.map(key => [key, NaN]))
          clearTimeout(timeout)
        }, 3000)
      }
      this._setSceneDurationSeconds(scene, curIdx)
    } catch (e) {
      console.log(e)
    }
  }
})()

export function cartBusinessModelList(currentThis){
  const carts = currentThis?.checkout?.results?.carts?.carts || []
  return carts?.length ? [...new Set(carts.map(m => m?.business_model))] : []
}



// 倒计时器，用于处理ddc超时，超过2秒不等ddc返回，执行下一次操作
let timer = null
/**
 * 通用发送ddc请求
 * @param {*} param.action 请求地址
 * @param {*} param.method 请求方式
 * @param {*} param.params 请求参数
 * @param {*} param.timeout 超时时间，默认2000ms
 * @param {*} param.cb 回调函数
 * @returns 
 */
export function commonSendDdc({ action, method = 'POST', params = '{}', timeout = 2000, cb } = {}) {
  if (!action) {
    return
  }

  // #region 重置操作
  clearTimeout(timer)
  // #endregion
  
  // 获取host，用于匹配ddc返回的host
  let host = ''
  try {
    var urlObj = new URL(action)
    host = urlObj.protocol + '//' + urlObj.host
  } catch (e) {
    var reg =
        /((https?):\/\/)?[-A-Za-z0-9+&@#\/%?=~_|!:,.;]+\.[-A-Za-z+]+\/+/g
    var hostUrl = action.match(reg)[0]
    host = hostUrl ? hostUrl.substring(0, hostUrl.length - 1) : ''
  }

  if (typeof params === 'string') {
    params = JSON.parse(params)
  }

  // ddc收集完成回调和超时回调只处理第一个执行的回调，后续的不再处理
  let hasRunCallback = false
  // 结果回调
  function returnCallback(data) {
    if (hasRunCallback) return
    hasRunCallback = true
    cb && cb(data)
    window.removeEventListener('message', addDdcEvent, false)
  }

  // 添加ddc监听事件
  function addDdcEvent(event) {
    if (event.origin === host) {
      returnCallback(event.data || {})
    }
  }
  window.addEventListener('message', addDdcEvent, false)

  // 创建iframe和form
  const commonDdcIframeId = 'commonDdcIframe'
  const commonDdcFormId = 'commonDdcForm'
  let commonDdcIframe = document.getElementById(commonDdcIframeId)
  let commonDdcForm = document.getElementById(commonDdcFormId)

  if (!commonDdcIframe) {
    commonDdcIframe = document.createElement('iframe')
    commonDdcIframe.id = commonDdcIframeId
    commonDdcIframe.name = commonDdcIframeId
    commonDdcIframe.style.display = 'none'
    document.body.appendChild(commonDdcIframe)
  }

  if (!commonDdcForm) {
    commonDdcForm = document.createElement('form')
    commonDdcForm.id = commonDdcFormId
    document.body.appendChild(commonDdcForm)
  }
  
  commonDdcForm.target = commonDdcIframeId
  commonDdcForm.method = method
  commonDdcForm.action = action
  let inputsStr = ''
  for (const key in params) {
    inputsStr += `<input type="hidden" name="${key}" value="${params[key]}" />`
  }
  commonDdcForm.innerHTML = inputsStr
  commonDdcForm.submit()

  // 超时处理
  timer = setTimeout(() => {
    returnCallback()
  }, timeout)
}
