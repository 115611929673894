<template>
  <section
    v-expose="analysisEvent('1-7-5-3')"
    class="section shipping-sec"
  >
    <!-- 普通免邮、平台免邮、首单免邮、平台首单免邮 -->
    <!-- PS:根节点层级的注释会影响测试环境打包时, 父组件透传属性如class之类 -->
    <div class="section-l">
      <sui_icon_shipping_18px
        class="icon_shipping"
        size="18px"
      />
    </div>
    <div class="section-r">
      <p
        class="title"
      >
        <span>{{ language.SHEIN_KEY_PC_15066?.replace(':','') }}</span>
        <a
          v-if="showShipSupplement"
          class="operation"
          role="button"
          tabindex="0"
          @keydown.stop.enter="clickCartAdd"
          @click="clickCartAdd"
        >
          {{ language.SHEIN_KEY_PC_15215 }}
          <sui_icon_more_right_12px
            :is-rotate="locals.GB_cssRight"
            size="12px"
          />
        </a>
      </p>
      <ul class="contain not-fsp-element">
        <li v-if="freeShippinpTips">
          <p class="contain-tips">
            <span v-html="freeShippinpTips"></span>
          </p>
          <ClientOnly>
            <count-down-time
              v-if="shippingInfo.showCountDown && shippingInfo.countDownTime > 0"
              :time-stamp="shippingInfo.countDownTime"
              :is-left-time="true"
              show-type="inline-promotion"
              :show-end-tips="true"
            />
          </ClientOnly>
        </li>
      </ul>
    </div>
  </section>
</template>
<script>
export default {
  name: 'NewShippingInfo',
}
</script>
<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'

import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { sui_icon_shipping_18px, sui_icon_more_right_12px } from '@shein-aidc/icon-vue3'
import countDownTime from 'public/src/pages/cart_v2/components/functional/common/CartCountDown.vue'
import { useCartAddItem } from 'public/src/pages/cart_v2/hooks/useCartAddItem.js'

const { state } = useStore()
const AddItem = useCartAddItem()

const props = defineProps({
  shippingInfo: {
    type: Object,
    default: () => ({})
  },
  mallCode: {
    type: String,
    default: '',
  }
})
const language = computed(() =>state.language)
const locals = computed(() => state.locals)

const freeShippingStrategy = computed(() => props.shippingInfo?.freeShippingStrategy || {})

const strategyPkData = computed(() => freeShippingStrategy.value?.strategyPkData || {})

const freeShippinpTips = computed(() => {
  return strategyPkData.value?.tip || ''
})

const showShipSupplement = computed(() => {
  return strategyPkData.value?.isAddItem || false
})

const clickCartAdd = () => {
  if (!showShipSupplement.value) {
    return
  }
  daEventCenter.triggerNotice(analysisEvent('1-7-5-4'))
  if (freeShippingStrategy.value.type === 'coupon') {
    AddItem.show({
      coupon: strategyPkData.value?.coupon,
      state: 'coupon_helper_add',
    })
    return
  }
  AddItem.show({
    popupInfoId: props.shippingInfo?.popupInfoId,
    state: 'shipping_add',
  })
}

const analysisEvent = (id) => {
  let shipping_method = strategyPkData.value?.shippingList?.map(item => item.shipping_method_name)?.join(',') || props.shippingInfo?.shipping_method_name || ''
  let shipping_free_type = ''

  if (freeShippingStrategy.value.type === 'coupon') {
    shipping_free_type = strategyPkData.value?.shippingFreeType
  } else {
    shipping_free_type = props.mallCode == 'platform' ? 0 : 3
  }
  return {
    id: id,
    data: {
      type: freeShippingStrategy.value.type,
      is_fullshippingactivity: showShipSupplement.value ? 0 : 1,
      mall_code: props.mallCode == 'platform' ? '' : props.mallCode,
      origin_price: strategyPkData.value?.originPrice?.amountWithSymbol || '-',
      shipping_method,
      shipping_free_type,
    }
  }
}

</script>

<style lang='less' scoped>
.shipping-sec {
  border: 0.5px solid rgba(25, 128, 85, 0.3);
}
.icon_shipping{
  color: #222222;
}
.contain-tips {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
