import components from './components'
import Adaptor from './Adaptor.js'
import Common from 'public/src/pages/detail/main/store/common/index.js'
import BTF from 'public/src/pages/detail/main/store/components/BTF/index.js'


export default {
  ...components,
  Adaptor,
  Common,
  BTF
}
