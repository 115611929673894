<template>
  <div
    ref="marquee"
    :style="{
      '--width': `${width}`,
      '--duration': `${duration}s`,
      '--translateValue': `${translateValue}`
    }"
    class="text-marquee"
  >
    <div
      ref="text"
      :class="['text-marquee__text', isAnimation && 'text-marquee__text_animation']"
    >
      <slot>{{ content }}</slot>
    </div>
  </div>
</template>
  
<script setup>
import { defineProps, ref, watch, nextTick } from 'vue'
const props = defineProps({
  // 跑马灯宽度
  width: {
    type: String,
    default: '68px',
  },
  // 跑马灯速度
  speed: {
    type: Number,
    default: 70,
  },
  // 跑马灯内容
  content: {
    type: String,
    default: '',
  },
  marqueeStart: {
    type: Number,
    default: 0
  },
  // 传入动画移动，-50%一半配置复制数据，实现无缝跑马、 -100%一整个跑马完
  translateValue: {
    type: String,
    default: '-50%'
  }
})
  
const isAnimation = ref(false)
const duration = ref(0)
const marquee = ref(null)
  
const init = () => {
  nextTick(() => {
    const textWidth = marquee.value.scrollWidth
    isAnimation.value = textWidth > marquee.value.clientWidth
    duration.value = (textWidth / props.speed).toFixed(2)
  })
}
  
watch(() => props.marqueeStart, (val) => {
  if(val){
    init()
  }
})
</script>
  
  <style lang="less" scoped>
  .text-marquee {
    overflow: hidden;
    max-width: var(--width, 100%);
    &__text {
      white-space: nowrap;
      width: fit-content;
      &_animation {
        animation-name: marquee;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-duration: var(--duration, 3s);
      }
    }
  }
  @keyframes marquee {
    0% {
      transform: translateX(0) /* rtl: translateX(-100) */;
    }
    100% {
      transform: translateX(var(--translateValue, -50%)) /* rtl: translateX(0) */;
    }
  }
  </style>
  
