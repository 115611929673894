<template>
  <div
    v-if="shippingTip"
    v-expose="analysisDataEvent()"
    class="shipping-header-container"
  >
    <div
      class="shipping-header"
    >
      <sui_icon_shipping_16px size="16px" />
      <div class="shipping-content">
        <span
          class="shipping-tip"
          v-html="shippingTip"
        ></span>
        <ClientOnly>
          <count-down-time
            v-if="firstFreeShipping.showCountDown && firstFreeShipping.countDownTime > 0"
            :time-stamp="firstFreeShipping.countDownTime"
            :is-left-time="true"
          />
        </ClientOnly>
      </div>
      <span
        v-if="showShipSupplement"
        v-expose="analysisEvent('1-7-1-21')"
        v-tap="analysisEvent('1-7-1-20')"
        class="shipping-operation"
        role="button"
        tabindex="0"
        @keydown.stop.enter="clickCartAdd"
        @click="clickCartAdd"
      >
        {{ language.SHEIN_KEY_PC_15215 }}
        <sui_icon_more_right_12px
          :is-rotate="locals.GB_cssRight"
          size="12px"
        />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MallShipping'
}
</script>
<script setup>
import { defineProps, computed } from 'vue'
import countDownTime from 'public/src/pages/cart_v2/components/functional/common/CartCountDown.vue'
import { sui_icon_shipping_16px, sui_icon_more_right_12px } from '@shein-aidc/icon-vue3'
import { useStore } from 'vuex'
import { useCartAddItem } from 'public/src/pages/cart_v2/hooks/useCartAddItem.js'

const { state } = useStore()
const AddItem = useCartAddItem()

const props = defineProps({
  mallCode: {
    type: String,
    default: ''
  },
  shippingActivityData: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  },
  locals: {
    type: Object,
    default: () => ({})
  }
})

const firstFreeShipping = computed(() => { // 首单免邮
  return props.shippingActivityData?.freeType == 1 ?  props.shippingActivityData || {} : {}
})

const shippingTip = computed(() => { // 平台型免邮文案 
  return props.shippingActivityData?.tip || ''
})

const showShipSupplement = computed(() => {
  return props.shippingActivityData?.isOpenDiff == 1 ? true : false
})
const analysisEvent = (id) => {
  return {
    id: firstFreeShipping.value ? '' : id,
    data: {
      shipping_free_type: props.mallCode == 'platform' ? 0 : 3,
      mall_code: props.mallCode == 'platform' ? '' : props.mallCode,
    }
  }
}

const shippingAnalysisParam = computed(() => {
  return state.mallShippingAnalysisParam[`${props.mallCode}`] || {}
})

const analysisDataEvent = (type) => {
  if(firstFreeShipping.value) return {}
  let id = ''
  let { shipping_detail_obj } = props.shippingActivityData
  if(type == 'click'){
    id = shipping_detail_obj ? '1-7-5-6' : '1-7-5-4'
  } else {
    id = shipping_detail_obj ? '1-7-5-5' : '1-7-5-3'
  }
  return {
    id: id,
    data: {
      ...shippingAnalysisParam.value,
      mall_code: props.mallCode == 'platform' ? '' : props.mallCode,
    }
  }
}

const clickCartAdd = () => {
  AddItem.show({
    popupInfoId: props.shippingActivityData?.popupInfoId,
    state: 'shipping_add',
  })
}
</script>
<style lang="less">
.shipping-header-container{
  background-color: @sui_color_safety_bg;
  .shipping-header{
    color: #222222;
    display: flex;
    background: @sui_color_safety_bg;
    padding: 12px 0;
    align-items: center;
    justify-content: space-between;
  }
  .shipping-content{
    flex: 1;
    padding: 0 9px;
  }
  .shipping-tip{
    font-size: 13px;
    line-height: 1.5;
    word-break: break-word;
  }
  .shipping-operation{
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
  }
}
</style>
