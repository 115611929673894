/* globals _GB_LoginEventCenter_ */
const { langPath, host, forceHttp } =
  typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
import { template } from '@shein/common-function'


// 格式化url参数
export function querys() {
  const search = window.location.search.slice(1)
  const params = search.split('&')
  return params.reduce(function (obj, search) {
    const arr = search.split('=')
    obj[arr[0]] = decodeURIComponent(arr[1])
    return obj
  }, {})
}
/**
 * 登录完成重定向
 * @param fromClose 是否来自页面关闭弹窗按钮
 * @param res 用户登录成功之后的数据
 * @returns {*}
 */
export async function urlRedirection(fromClose, res) {
  const query = querys()
  const _redirection = query.redirection
  const _close_redirection = query.close_redirection
  const _prot = query.prot
  const action = query.action

  // 如果是来自全局弹窗登录页
  if (
    window.isLoginPageAsCompnent &&
    typeof _GB_LoginEventCenter_ !== 'undefined'
  ) {
    const { reload = true, to, cb } = _GB_LoginEventCenter_.opts
    if (fromClose) {
      _GB_LoginEventCenter_.$emit('event-loading', false)
      _GB_LoginEventCenter_.$emit('event-login-page-component', { show: false })
      return
    }
    if (!!to) {
      // 如果已经传进来登录成功跳转url
      location.href = to
      return
    }
    if (reload && cb === undefined) {
      // 重新加载
      location.reload()
      return
    }
    // 隐藏相关的loading
    _GB_LoginEventCenter_.$emit('event-loading', false)
    _GB_LoginEventCenter_.$emit('event-login-page-component', { show: false })

    if (typeof cb === 'function') {
      // 回到函数
      cb(res)
    }
    // 如果有展示快速注册弹窗，登录成功之后关闭注册弹窗，todo:以更优雅的方式关掉
    if (typeof window.gbRegisterModule !== 'undefined')
      window.gbRegisterModule.rawHide?.()
    return
  }

  if (fromClose) {
    if (_close_redirection) {
      return (location.href = _close_redirection)
    }
    if (location.search.indexOf('user') > -1) {
      return (location.href = `${langPath}/`)
    }
    if (
      location.search.indexOf('checkout') > -1 ||
      location.search.indexOf('pay') > -1
    ) {
      return (location.href = `${langPath}/cart`)
    }
  }

  // zendesk login
  if (action === 'JWTLogin') {
    try {
      const { zendeskLoginSer } = await import('@login/service.js')
      const data = await zendeskLoginSer()
      if (data.url) window.location = data.url
    } catch (error) {
      console.log(error + '')
    }
    return
  }
  if (window.location.search.indexOf('direction=checkout') > -1) {
    return (window.location = `${host}${langPath}/checkout`)
  }

  if (_redirection != null) {
    //prot == 1 is from http
    if (_prot == 1) {
      return (window.location.href = `${forceHttp}${_redirection}`)
    }
    if (_redirection.indexOf(`${langPath}/`) >= 0) {
      return (window.location.href = `${host}${_redirection}`)
    }
    return (window.location.href = `${host}${langPath}${_redirection}`)
  }

  return (window.location.href = `${forceHttp}${langPath}`)
}

export function dataVisor() {
  return {
    register_country: '',
    resolution:
      window.screen.width * (window.devicePixelRatio || 1) +
      'X' +
      window.screen.height * (window.devicePixelRatio || 1),
    batterystate: '',
    batterylevel: '',
    register_TimeLong: window.signupTimeTag || 0, //注册时长
  }
}

// 获取浏览器数据
export function dataVisorCb(data, cb) {
  const biData = dataVisor()
  biData.register_type = data.register_type
  if (navigator && navigator.getBattery) {
    try {
      navigator.getBattery().then(function (battery) {
        // 设备电池信息
        biData.batterystate = battery.charging ? 'Charging' : 'Unplugged' //获取设备是否在充电
        biData.batterylevel = battery.level * 100 + '%' //电池电量
        data.biData = biData
        cb(data)
      })
    } catch (e) {
      data.biData = biData
      cb(data)
    }
  } else {
    data.biData = biData
    cb(data)
  }
}

export function dataVisorPromise(data) {
  return new Promise((resolve) => {
    dataVisorCb(data, (value) => resolve(value))
  })
}

// 格式化三方统一接口登录的参数特异性
export function formatThirdLoginOptions(type, data) {
  const opts = { ...data }
  if (type == 11) {
    // fb
    opts.facebook_id = opts.socialId
    opts.fbtoken = opts.accessToken
    opts.register_type = 'facebook'
  } else if (type == 12) {
    // google
    opts.google_id = opts.socialId
    opts.id_token = opts.accessToken
    opts.register_type = 'google'
  } else if (type == 13) {
    // vk
    opts.user_id = opts.socialId
    opts.app_token = opts.accessToken
    opts.register_type = 'vk'
  } else if (type == 15) {
    // line
    opts.user_id = opts.socialId
    opts.id_token = opts.accessToken
    opts.register_type = 'line'
  } else if (type == 16) {
    // kakao
    opts.kakao_id = opts.kakao_id || opts.socialId
    opts.kakao_token = opts.kakao_token || opts.accessToken
    opts.register_type = 'kakao'
  } else if (type == 17) {
    // naver
    opts.naver_id = opts.naver_id || opts.socialId
    opts.naver_token = opts.naver_token || opts.accessToken
    opts.register_type = 'naver'
  }
  return opts
}

// 统一处理错误提示
export function formatNormalErrorMessage(res = {}) {
  const langText = window.LoginPageLangText || {}
  const codeTips = {
    402901: langText.SHEIN_KEY_PC_16509,
    402902: langText.SHEIN_KEY_PC_17704,
    402904: langText.SHEIN_KEY_PC_17704,
    402903: langText.SHEIN_KEY_PC_16510,
    400529: langText.SHEIN_KEY_PC_16979,
    400592: langText.SHEIN_KEY_PC_17708,
    400584: langText.SHEIN_KEY_PC_17529,
    400504: langText.SHEIN_KEY_PC_15687,
    400502: langText.SHEIN_KEY_PC_14914,
    400505: langText.SHEIN_KEY_PC_15110,
    400506: langText.SHEIN_KEY_PC_14477,
    400525: langText.SHEIN_KEY_PC_14476,
    400524: langText.SHEIN_KEY_PC_14480,
    400517: langText.SHEIN_KEY_PC_15674,
    400503: langText.SHEIN_KEY_PC_15673,
    400522: langText.SHEIN_KEY_PC_14885,
  }

  const defaultValue = langText.SHEIN_KEY_PC_14478

  const { code = '', info = {}, tips = '' } = res

  let msg = codeTips[code] || tips || defaultValue || ''

  if (['402902', '402904'].includes(code.toString())) {
    msg = msg.replace('{0}', info.lock_time)
  }
  return msg
}

export function formatNormalCodeMessage(code, hasdefault = true) {
  const langText = window.LoginPageLangText || {}
  const codeTips = {
    400502: langText.SHEIN_KEY_PC_14914,
    400505: langText.SHEIN_KEY_PC_15110,
    400506: langText.SHEIN_KEY_PC_14477,
    400504: langText.SHEIN_KEY_PC_15082,
    400525: langText.SHEIN_KEY_PC_14476,
    400524: langText.SHEIN_KEY_PC_14480,
    400517: langText.SHEIN_KEY_PC_15674,
    400503: langText.SHEIN_KEY_PC_15673,
    400522: langText.SHEIN_KEY_PC_14885,
  }
  let defaultValue = ''
  if (hasdefault) defaultValue = langText.SHEIN_KEY_PC_15079

  let msg = codeTips[code] || defaultValue || ''

  return msg
}

export const newPrivacyCache = {
  key: 'isAgreenNewPrivacy',
  get(locationId) {
    try {
      const key = locationId || 'none'
      const val =
        JSON.parse(sessionStorage.getItem(`${this.key}`) || null) || {}
      return val?.[key] || ''
    } catch (e) {
      return ''
    }
  },
  set(locationId) {
    try {
      const key = locationId || 'none'
      const val =
        JSON.parse(sessionStorage.getItem(`${this.key}`) || null) || {}
      val[key] = 1
      sessionStorage.setItem(this.key, JSON.stringify(val))
    } catch (e) {
      return ''
    }
  },
}

// 组装新隐私政策协议
export const combineNewPrivacyLanguage = (opt = {}) => {
  const { from = '', abt = '', type = '', language = {}, location } = opt
  const langText = typeof window !== 'undefined' ? window.LoginPageLangText || language || {} : language || {}
  let str = ''

  const gbCommonLangText = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo.language || {} : {}

  const privacy = `<a href="javascript:void(0);" class="j-privacy-and-cookies" da-event-click="2-7-59" da-event-expose="2-7-60" data-location="${location}"  tabindex="0">${gbCommonLangText.SHEIN_KEY_PC_14730 || langText.SHEIN_KEY_PC_14730 || ''}</a>`

  str = abt == 'yes' ? langText.SHEIN_KEY_PC_17946 : langText.SHEIN_KEY_PC_17948

  if (from === 'third' && abt == 'no') {
    str =
      type == 'signup'
        ? langText.SHEIN_KEY_PC_25568
        : langText.SHEIN_KEY_PC_17947
  }

  if (from === 'newui-continue') {
    str = langText.SHEIN_KEY_PC_25383
  }

  if (from === 'pop' && abt == 'yes') {
    str = `<h3 tabindex="0" ref="newPrivacyTitle">${langText.SHEIN_KEY_PC_19559}</h3><p tabindex="0">${langText.SHEIN_KEY_PC_19560}</p><br/><p>{0}<br/>{1}</p>`
  }

  if(from === 'quick_register_pop'){
    str = langText.SHEIN_KEY_PC_32556 || language.SHEIN_KEY_PC_32556 || ''
  }

  const terms = `<a tabindex="0" href="javascript:void(0);" class="j-terms-and-conditions" da-event-click="2-7-61" da-event-expose="2-7-62" data-location="${location}">${gbCommonLangText.SHEIN_KEY_PC_15730 || langText.SHEIN_KEY_PC_15730 || ''}</a>`

  return template(privacy, terms, str || '')

  // const { SHEIN_KEY_PC_14730, SHEIN_KEY_PC_15730 } = gbCommonInfo.language
  // const str = origin || ''
  // const privacy = `<a href="javascript:void(0);" class="j-privacy-and-cookies">${SHEIN_KEY_PC_14730}</a>`
  // const terms = `<a href="javascript:void(0);" class="j-terms-and-conditions">${SHEIN_KEY_PC_15730}</a>`
  // return str.replace('{0}', privacy).replace('{1}', terms)
}

export function handleNewPrivacyClick(e) {
  // return
  const el = e.target
  if (el.tagName == 'A' && el.className.includes('j-privacy-and-cookies')) {
    window.SHEIN_GLOBAL_PRIVACY.show({ type: 'privacy' })
  }
  if (el.tagName == 'A' && el.className.includes('j-terms-and-conditions')) {
    window.SHEIN_GLOBAL_PRIVACY.show({ type: 'termsAndConditions' })
  }
  if (el.tagName == 'A' && el.className.includes('j-terms-of-use')) {
    window.SHEIN_GLOBAL_PRIVACY.show({ type: 'termsOfUse' })
  }
  if (el.tagName == 'A' && el.className.includes('j-terms-of-sale')) {
    window.SHEIN_GLOBAL_PRIVACY.show({ type: 'termsOfSale' })
  }
}

export async function getLoginPageLanguage(cb) {
  const { getLoginPageLanguage } = await import('@login/utils/loginPageLanguage')
  const getLoginPageLanguageFn = getLoginPageLanguage
  return getLoginPageLanguageFn(cb)
}

export function reloadOneTrustBanner(res = {}) {
  const { IS_SUPPORT_ONETRUST, CUSTOM_PRIVACY_SUPPORT, SiteUID } = gbCommonInfo
  if (CUSTOM_PRIVACY_SUPPORT.includes(SiteUID)) return
  if (!IS_SUPPORT_ONETRUST) return
  const oneTrustInfo = res?.info || {}
  const [onetrustId, onetrustToken] = [
    oneTrustInfo.identifier,
    oneTrustInfo.jwt_token
  ]
  if (!onetrustId) return

  window.localStorage.setItem(
    '_ocs_info_',
    JSON.stringify({ id: onetrustId, t: onetrustToken })
  )

  if (window.OneTrust)
    window.OneTrust.dataSubjectParams = {
      id: onetrustId,
      token: onetrustToken,
      isAnonymous: false,
    }
  const otConsentSdk = document.getElementById('onetrust-consent-sdk')
  if (otConsentSdk) {
    try {
      otConsentSdk.parentNode.removeChild(otConsentSdk)
    } catch (e) {
      console.log(e + '')
    }
  }
  if (window.OneTrust != null && window.OneTrust.Init) {
    window.OneTrust.Init()
    setTimeout( async () => {
      window.OneTrust.LoadBanner()
      const toggleDisplay = document.getElementsByClassName(
        'ot-sdk-show-settings'
      )
      for (var i = 0; i < toggleDisplay.length; i++) {
        toggleDisplay[i].onclick = function (event) {
          event.stopImmediatePropagation()
          window.OneTrust.ToggleInfoDisplay()
        }
      }
      const { reloadOneTrustEvents } = await import('public/src/pages/common/onetrust/index.js')
      reloadOneTrustEvents()
    }, 1000)
  }
}

//  登陆成功清除访客模式
export const clearVisitConfrim = async () => {
  const { getVisitConfrim } = await import('@customerService/tools')
  if ( getVisitConfrim && getVisitConfrim() == 1) {
    sessionStorage.removeItem('visitConfrim')
  }
}

export function isFromShopping() {
  return window?.getSaPageInfo?.page_id == 8
}


// 手机号码区号缓存
export const phoneCodeCache = {
  key: 'p_c_s_cache',
  set(v, k) {
    let vl = window.localStorage.getItem(this.key) || null
    try {
      vl = JSON.parse(vl) || {}
    } catch (e) {
      vl = {}
    }
    vl[k] = v
    window.localStorage.setItem(this.key, JSON.stringify(vl))
  },
  get() {
    let vl = window.localStorage.getItem(this.key)
    try {
      vl = JSON.parse(vl) || {}
    } catch (e) {
      vl = {}
    }
    return vl[1] || vl[0] || ''
  },
  getValue(k) {
    let vl = window.localStorage.getItem(this.key)
    try {
      vl = JSON.parse(vl) || {}
    } catch (e) {
      vl = {}
    }
    return vl[k] || ''
  },
  setValue(vl) {
    window.localStorage.setItem(this.key, JSON.stringify(vl))
  },
  getStr() {
    let vl = window.localStorage.getItem(this.key)
    try {
      vl = JSON.parse(vl) || {}
    } catch (e) {
      vl = {}
    }
    let str = ''
    if (vl[1]) str += `1-${vl[1]}_`
    if (vl[0]) str += `0-${vl[0]}`
    return str
  },
  parseStr(str) {
    if (!str) return ''
    let vl = str.split('_')
    let obj = {}
    vl.forEach((v1) => {
      const [k, v] = v1.split('-')
      if (k) obj[k] = v
    })
    return {
      code: obj[1] || obj[0] || '',
      value: obj,
    }
  },
}

export function dealFinalCase() {
  //  登陆成功清除访客模式
  clearVisitConfrim()

  localStorage.removeItem('NAV_TOGGLE_STATE')
  localStorage.removeItem('IS_SHOW_ASSETS')
  // 多主体合规缓存清除
  window?.sessionStorage?.removeItem('mutiplePartPrivacy')
  window?.sessionStorage?.removeItem('isAgreenNewPrivacy')
  // 登录成功之后，去掉快速注册
  window.gbRegisterModule && window.gbRegisterModule.rawHide()
  // 券包
  if (window?.couponPackage?.login) window.couponPackage.login = true
  // !isLoginPage && window.showPopUpdatePassword({ from: 'login' })
  window?._abt_server_provider?.clear?.()
  window?.ExceptionReport?.login()
}

// 是否展示邮件订阅
export function isShowEmailSubscribe() {
  const { SIGNUP_SUBSCRIPTION_SUPPORT, SIGNUP_SUBSCRIPTION_NO_SUPPORT } =
    gbCommonInfo
  return SIGNUP_SUBSCRIPTION_SUPPORT || SIGNUP_SUBSCRIPTION_NO_SUPPORT || false
}

// 是否展示手机订阅
export function isShowPhoneSubscribe() {
  const {
    SiteUID,
    SMS_SUBSCIRIPE_DEFAULT_SELECTED,
    SMS_SUBSCIRIPE_DEFAULT_NO_SELECTED,
  } = gbCommonInfo
  // us站不展示手机短信订阅
  if (['us'].includes(SiteUID)) return false

  // 手机号默认订阅勾选
  const default_selected = SMS_SUBSCIRIPE_DEFAULT_SELECTED.split?.(',')
  // 如果阿波罗配置手机号码默认勾选，展示手机号订阅
  if (default_selected.includes(SiteUID)) return true

  // 手机号默认订阅不勾选
  const default_no_selected = SMS_SUBSCIRIPE_DEFAULT_NO_SELECTED.split(',')
  // 如果阿波罗配置手机号码默认勾选，展示手机号订阅
  if (default_no_selected.includes(SiteUID)) return true

  return false
}

export function isWhatsAppDefaultChecked() {
  const { SiteUID, WHATSAPP_SUBSCRIPE_CHECKED } = gbCommonInfo
  return WHATSAPP_SUBSCRIPE_CHECKED?.includes?.(SiteUID)
}

export const sleep = (time) =>  {
  return new Promise((resolve) => setTimeout(resolve, time))
}

// 三方降级过滤
export const thirtyLoginFilter = (list, thirtyLoginAbt) => {
  const tmps = []
  list?.forEach?.((v) => {
    v.register_from = parseInt(v.register_from)
    if (v.register_from == 11 && thirtyLoginAbt.facebook) return false
    if (v.register_from == 12 && thirtyLoginAbt.google)  return false
    if (v.register_from == 13 && thirtyLoginAbt.vk) return false
    if (v.register_from == 14 ) return false
    if (v.register_from == 15 && thirtyLoginAbt.line) return false
    if (v.register_from == 16 && thirtyLoginAbt.kakao) return false
    if (v.register_from == 17 && thirtyLoginAbt.naver) return false
    
    const is_third = [11, 12, 13, 14, 15, 16, 17].includes(v.register_from)
    tmps.push({ ...v, is_third })
  })
  return tmps
}
