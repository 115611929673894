<template>
  <UserCenterContainer
    v-if="context.useBffApi"
    :context="context"
  />
  <!-- <App
    v-else
    :context="context"
  /> -->
</template>

<script>
import { defineComponent } from 'vue'
import UserCenterContainer from './index-new.vue'

export default defineComponent({
  name: 'UserCenter',
  components: {
    UserCenterContainer,
    // App: defineAsyncComponent(() => import('./index.vue')),
  },
  props: {
    context: {
      type: Object,
      default: () => ({}),
    },
  },
})
</script>
