/**
 * @description: 新搭配 数据中心
 */
import { getNewOutfitInfo } from 'public/src/pages/detail/main/services/index.js'
import { getPopupInstance } from 'public/src/pages/detail/main/components/Drawer/new_outfit/main-load.js'
import { abtservice } from 'public/src/services/abt'
import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '2-21' })

export default {
  namespaced: true,
  state: {
    new_outfit_info: {},
  },
  mutations: {
    update_new_outfit_info(state, paylod) {
      state.new_outfit_info = paylod
    },
  },
  getters: {
    new_outfit_abt(state, getters, rootState, rootGetters){
      const screen_abt_tsp_config = rootGetters['Adaptor/screen_abt_tsp_config']
      const newoutfit = screen_abt_tsp_config?.newoutfit?.param || {}
      const showNewOutfit = newoutfit.newoutfit === 'new' // 展示新搭配模块
      return {
        showNewOutfit,
        showNewOutfitPhoto: showNewOutfit && newoutfit.pctype !== 'onlymodule', // 默认展示主图
        showNewOutfitModule: showNewOutfit && ['onlymodule', 'both'].includes(newoutfit.pctype), // 展示独立模块
        isShowItemChange: newoutfit.outfitswitch !== 'none', // 搭配弹窗内有切换icon
        showRecommend: ['show-ymal', 'show'].includes(newoutfit.outfitsimilar), // 展示搭配弹窗内的相似品推荐位
        isOutfitShowYmalSource: newoutfit.outfitsimilar === 'show-ymal',
        newOutfitCate: newoutfit.outfitshowcate || 'all', // 品类展示搭配模块控制
        // eslint-disable-next-line @shein-aidc/abt/abt
        abTest: abtservice.getUserAbtResultForAnalysis({ abtInfo: { 'newoutfit': screen_abt_tsp_config.newoutfit || {} } })?.sa || ''
      }
    },
    show_outfit_cate(state, getters, rootState, rootGetters) {
      const is_forward_category =  rootGetters['Adaptor/is_forward_category']
      const { new_outfit_abt } = getters 
      const { newOutfitCate = 'all' } = new_outfit_abt || {}
      const hiddenAll = newOutfitCate == 'onlygood' && !is_forward_category // 命中abt仅正向品类展示搭配模块(老+新)
      if (hiddenAll) return false
      return true
    },
    newoutfit_data_config(state, getters, rootState, rootGetters) {
      const base_info = rootGetters['Adaptor/base_info']
      const language_v2 = rootGetters['Adaptor/language_v2']
      const is_paid_user = rootGetters['Adaptor/is_paid_user']
      const is_hit_compliance_mode = rootGetters['Adaptor/is_hit_compliance_mode']
      const get_estimated_info = rootGetters['Adaptor/get_estimated_info']
      const screen_abt_tsp_config = rootGetters['Adaptor/screen_abt_tsp_config']
      const isS3newPrice = screen_abt_tsp_config?.EstimatedPrice?.param?.S3newprice === 'new'
      return {
        base_info,
        language_v2,
        is_paid_user,
        is_hit_compliance_mode,
        get_estimated_info,
        isS3newPrice
      }
    }
  },
  actions: {
    init_new_outfit({ commit }, _) {
      _.then((res = {}) => {
        const context = res.info || {}
        if(context.productNewCompanionList && context.productNewCompanionList.length) {
          daEventCenter.triggerNotice({
            daId: '2-21-19'
          })
        }
        commit('update_new_outfit_info', context)
      })
    },
    get_new_companion_module_api: async ({ rootGetters }, args = {}) => {
      // 获取新搭配数据
      const base_info = rootGetters['Adaptor/base_info']
      const { goods_id, cat_id, goods_sn, mall_code } = base_info || {}
      const is_paid_user = rootGetters['Adaptor/is_paid_user'] || ''
      const is_sold_out = rootGetters['Adaptor/is_sold_out']
      const params = {
        goods_id,
        cat_id,
        goods_sn,
        is_paid_user,
        is_sold_out,
        mall_code,
        ...args
      }
      const { info = {} } = await getNewOutfitInfo(params)
      return info
    },
    async open_get_the_look_new_popup({ rootGetters, getters, state, dispatch }, { initCateTab = '', activeIndex = 0 } = {}) {
      const GetTheLookNewPopup = await getPopupInstance()
      const new_outfit_abt = getters.new_outfit_abt || {}
      const { isOutfitShowYmalSource, isShowItemChange, showRecommend, abTest } = new_outfit_abt
      const base_info = rootGetters['Adaptor/base_info']
      const { goods_id, cat_id } = base_info || {}
      const sku_code = rootGetters['Adaptor/sku_code']
      const language_v2 = rootGetters['Adaptor/language_v2']
      const get_estimated_info = rootGetters['Adaptor/get_estimated_info']
      const is_paid_user = rootGetters['Adaptor/is_paid_user']
      const is_hit_compliance_mode = rootGetters['Adaptor/is_hit_compliance_mode']
      const comp_liance_mode = rootGetters['Adaptor/comp_liance_mode']
      const ymal_rec_params = rootGetters['Adaptor/ymal_rec_params']
      const hasMainGoodsEstimatedPrice = !!get_estimated_info?.amountWithSymbol
      const data = state.new_outfit_info
      const bff_gtl_main_api = async (args) => {
        return dispatch('get_new_companion_module_api', args)
      }
      const bottomRecParams = isOutfitShowYmalSource ? ymal_rec_params : {}
      const options = {
        goodsId: goods_id,
        catId: cat_id,
        mainSkuCode: sku_code,
        language: language_v2,
        isPaidUser: is_paid_user,
        isHitComplianceMode: is_hit_compliance_mode,
        complianceMode: comp_liance_mode,
        data,
        isShowItemChange,
        initCateTab,
        activeIndex,
        hasMainGoodsEstimatedPrice,
        config: {
          abTspTest: abTest,
          isOutfitShowYmalSource,
          showRecommend,
          bottomRecParams,
          bff_gtl_main_api
        },
        callback: {
          onUpdateCoupon: () => {
            dispatch('Adaptor/update_estimated_coupon_list', {}, { root: true })
          },
        }
      }
      GetTheLookNewPopup.open(options)
    },
  }
}
