import { SILogger, SIMetric } from 'public/src/pages/common/monitor/index.js'

const metricCount = function ({ name, tags = {}, message }) {
  SIMetric.metricCount({
    metric_name: name,
    tags: {
      ...tags,
    },
    message, // 日志信息
  })
}

export function Metric_Advertising_Report_Total({
  scene_type = '',
  advertisers = '',
  report_from = '',
}) {
  metricCount({
    name: 'web_advertising_report_total',
    tags: {
      report_from,
      scene_type,
      advertisers,
    },
    message: 'Advertising report total',
  })
}

export function Metric_Advertising_Third_Report_Total({
  scene_type = '',
  advertisers = '',
}) {
  metricCount({
    name: 'web_advertising_third_report_total',
    tags: {
      scene_type,
      advertisers,
    },
    message: 'Advertising third report total',
  })
}

export function Logger_Advertising_SDK_Load_Error({
  advertiser = '',
  src = '',
  error = '',
}) {
  SILogger.logInfo(
    {
      tag: 'ADVERTISING_SDK_LOAD_ERROR_TAG',
      message: error,
      params: {
        advertiser,
        src,
        message: error,
      },
    },
    {
      immediate: true,
    },
  )
}

export function Logger_Advertising_Third_Report_Error({
  scene_type = '',
  advertiser = '',
  error = '',
}) {
  SILogger.logInfo(
    {
      tag: 'ADVERTISING_THIRD_REPORT_ERROR_TAG',
      message: error,
      params: {
        scene_type,
        advertiser,
        message: error,
      },
    },
    {
      immediate: true,
    },
  )
}

const googleAdvertiseNames = [
  'Conversion',
  'Remarketing',
  'DV360',
] 

export function formatAdvertiserName(subscriber){
  const advertiserName = subscriber.constructor?.name
  if(!advertiserName) return null
  return googleAdvertiseNames.includes(advertiserName) ? `Google-${advertiserName}` : advertiserName
}