export default class PromiseActuator {
  promise
  resolve
  state

  constructor() {
    this.promise = null
    this.resolve = null
    this.state = 'pending'

    this.init()
  }

  init() {
    this.promise = new Promise(r => {
      this.resolve = d => {
        this.state = 'fulfilled'
        r(d)
      }
    })
  }
}
