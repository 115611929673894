import { watch } from 'vue'
import schttp from 'public/src/services/schttp'

export const useDayTime = ({ state, commit }) => {
  const { locals, defaultShipping, checkout, storeData, buyNowNo } = state

  const getCartList = (carts) => {
    return carts?.map(item => {
      let isQuickShip = ''
      if (locals.value.SiteUID?.indexOf('br')) {
        // 巴西
        isQuickShip = +item?.sfs_flag === 1
      } else if (locals.value.SiteUID?.indexOf('jp')){
        // 日本
        isQuickShip = ''
      } else {
        isQuickShip = [1, 2].includes(+item?.real_quick_ship)
      }
      return ({
        cartId: item.id,
        isQuickShip,
        skuCode: item?.product?.sku_code,
        mallCode: item.mall_code,
        storeCode: item.store_code,
        isHl: [1, 2].includes(+item.large_ship)
      })
    })
  }

  const getTransportList = (mallList) => {
    const transport_list = []
    const scenes = [{
      // QS
      isQuickShip: true,
      isHl: false,
    }, {
      // 海陆运
      isQuickShip: false,
      isHl: true,
    }, {
      // 普通商品
      isQuickShip: false,
      isHl: false,
    }]
    mallList.forEach(({ shipping_methods }) => 
      shipping_methods.forEach(({ transport_type }) => {
        scenes.forEach(({ isQuickShip, isHl }) => {
          transport_list.push({
            isQuickShip,
            transportType: transport_type,
            isHl
          })
        })
      }))
    return transport_list
  }

  const updateDayTimeTip = async() => {
    const address = checkout.value.default_address
    const carts = checkout.value.results.carts.carts
    const mallList = checkout.value.results?.mallShippingMethods?.mall_list
    const transportType = defaultShipping.value?.[0]?.shipping_method?.transport_type || ''
    const params = {
      addressInfoOther: address,
      has_shop_transit_address: !!storeData.value ? 1 : 0, 
      checkout_no: buyNowNo.value, 
      scene: 'checkout',
      transport_type: transportType,
      type: 2,
      day_time_params: {
        cart_list: getCartList(carts),
        transport_list: getTransportList(mallList)
      },
    }
    const { code, info = {} } = await schttp({
      url: '/api/common/getAgingInfo/get',
      method: 'POST',
      data: params
    })
    if(code == 0){
      commit('assignState',  { 
        dayTimeMap: {
          cartList: info?.cartList,
          transportList: info?.transportList,
          selectTransportType: transportType,
          hasEffectIgnoreAbt: info?.hasEffectIgnoreAbt
        }
      })
    } else {
      console.error(`newQuickShip err`)
    }
    console.log('🐬🐬🐬 es: dayTime Result', info)
  }

  watch(() => defaultShipping.value, (val) => {
    if (val) {
      updateDayTimeTip()
    }
  }, {
    immediate: true,
  })

  return {
    updateDayTimeTip,
  }
}



