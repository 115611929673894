<template>
  <s-drawer
    v-model:visible="isShowGetCoupon"
    size="440px"
    @close="closeDrawer"
  >
    <template #title>
      {{ language.SHEIN_KEY_PC_22086 }}
    </template>
    <div class="coupon-drawer-wrapper">
      <div class="coupon-top_tip">
        <s-alert
          type="info"
          :closeable="false"
          :show-icon="true"
        >
          {{ language.SHEIN_KEY_PC_22092 }}
        </s-alert>
      </div>
      <ul
        v-show="couponList && couponList.length > 0"
        class="coupon-list"
      >
        <li 
          v-for="(item, index) in couponList"
          :key="`get-coupon-${index}`"
        >
          <CouponItem
            :item="item"
            :language="language"
            :expanded="expandedCoupon"
            :show-coupon-code="false"
            :is-login="login"
            :is-show-post-amount="isShowPostAmount"
            page="cart"
            @trigger-expand="id => expandedCoupon = id"
            @trigger-collapse="expandedCoupon = ''"
            @login-handle="loginHandle"
            @get-coupon-handle="getCouponHandle"
          />
        </li>
      </ul>
    </div>
  </s-drawer>
</template>

<script>
export default {
  name: 'GetCouponDrawer'
}
</script>

<script setup>
import { onMounted, ref, computed, watch, nextTick } from 'vue'
import { getCartStoreCoupon } from 'public/src/pages/cart_v2/services/api.js'
import { useStore } from 'vuex'
import { getPostAmountAbt, listFormat } from 'public/src/pages/components/coupon/mall/utils.js'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { SMessage } from '@shein-aidc/sui-message'
import CouponItem from 'public/src/pages/components/coupon/mall/index.vue'

const { state, commit, dispatch } = useStore()

const language = computed(() => state.language)
const drawerGetCoupon = computed(() => state.drawerGetCoupon)
const mallInfoForGetCoupon = computed(() => state.mallInfoForGetCoupon)

let isShowGetCoupon = ref(false)
let loaded = ref(false)
let couponList = ref({})
const showDrawerGetCoupon = computed(() => loaded.value && drawerGetCoupon.value)

watch(() => showDrawerGetCoupon.value, (v) => {
  if (v) {
    init()
  }
})

onMounted(async() => {
  loaded.value = true
  isShowPostAmount.value = await getPostAmountAbt()
})

const getList = async() => {
  try {
    const couponRes = await getCartStoreCoupon(mallInfoForGetCoupon.value)
    if (couponRes.code == '0') {
      couponList.value = listFormat(couponRes?.info).filter(item => item?.coupon_status != '3') || [] // 过滤已领完的券
      if (!couponList.value?.length) {
        closeDrawer()
        SMessage({
          type: 'info',
          message: language.value?.SHEIN_KEY_PC_22089,
        })
      } else {
        isShowGetCoupon.value = true
        await nextTick()
        let couponListEle = document.querySelector('.coupon-list')
        if (!couponListEle) return
        let couponTopTipEle = document.querySelector('.coupon-top_tip')
        let computedStyle = window.getComputedStyle(couponTopTipEle)
        couponListEle.style.height = `calc(100vh - ${computedStyle.getPropertyValue('height')} - 111px)`
      }
    }
  } catch (err) {
    console.log(err)
  }
}

let login = ref(false)
let expandedCoupon = ref('')
let isShowPostAmount = ref(false)

const init = async () => {
  login.value = isLogin()
  await getList()
  couponDaEvent()
}

const closeDrawer = () => {
  commit('toggleCartGetCouponDrawer', { value: false })
}

// 领取优惠券
const loginHandle = async () => {
  await dispatch('handleCartLogin', { type: 'other', updateSingelDataCb: getList })
}

const couponDaEvent = () => {
  daEventCenter.triggerNotice({
    daId: '1-7-1-37',
    extraData: {
      mall_code: mallInfoForGetCoupon.value?.mall_code || '',
      store_code: mallInfoForGetCoupon.value?.store_code || '',
      coupons_count: couponList.value.length // 优惠券数量
    }
  })
  couponList.value.forEach(item => {
    daEventCenter.triggerNotice({
      daId: '1-7-1-35',
      extraData: {
        coupon_code: item.couponCode,
        coupon_status: item.coupon_status // 优惠券状态
      }
    })
    if (item.coupon_status == '2') {
      daEventCenter.triggerNotice({
        daId: '1-7-1-36',
        extraData: {
          coupon_code: item.couponCode
        }
      })
    }
  })
}

// 点击领取优惠券
const getCouponHandle = ({ code, result }) => {
  daEventCenter.triggerNotice({
    daId: '1-7-1-38',
    extraData: {
      coupon_code: code,
      get_result: result ? '1' : '0'
    }
  })
}
</script>

<style lang="less">
.coupon-drawer-wrapper {
  position: fixed;
  .left(0);
  width: 100%;
  .coupon-list {
    margin-top: 24px;
    padding: 0 30px;
    overflow: auto;
  }
}
</style>
