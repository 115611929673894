import { createApp } from 'vue'
import { registerBaseContext } from 'public/src/pages/common/common_inject'
import Helper from './utils/helper'
import { fetchAddOnABTLanguageCardConfig } from 'public/src/pages/cart_v2/components/business/add-on/hooks/useAddOn.js'

let instance
const creatInstance = (RootComponent) => {
  const app = createApp(RootComponent)
  registerBaseContext(app)
  const root = document.createElement('div')
  document.body.appendChild(root)
  return app.mount(root)
}

if(typeof window !== 'undefined') {
  requestIdleCallback(() => {
    window.preFetchAddOnABTLanguageCardConfig = fetchAddOnABTLanguageCardConfig()
  })
}

export default {
  open(config) {
    if (typeof window === 'undefined') return
    
    if (instance) {
      return instance.open(config)
    }

    return new Promise((resolve) => {
      import(/* webpackChunkName: "add_on_v2" */ './AddOnContainer.vue').then((component) => {
        const _instance = creatInstance(component.default)
        instance = _instance
        instance.open(config)
        resolve()
      })
    })
  },
  updateOpts(options) {
    if (!instance) return
    instance.updateOpts(options)
  },
  close() {
    if (!instance) return
    instance.close()
  },
  Helper,
}
