<!-- 支持三种模式 1、均速轮播；2、间歇性轮播；3、兼容1、2的插槽模式 -->
<template>
  <swiper-container
    ref="carouselSwiperRef"
    class="checkout-component__label-swiper"
    init="false"
  >
    <template v-if="!!config.length">
      <swiper-slide
        v-for="(item, index) in config"
        :key="index"
      >
        <div class="checkout-component__label-swiper_item">
          <Icon
            v-if="!!item.icon?.name"
            :name="item.icon?.name"
            size="15px"
            class="checkout-component__lsr_item-icon"
            :class="item.icon?.name"
            :style="{ color: item.icon?.color }"
          />
          <img
            v-else
            :src="item.img?.url"
            :style="item.img?.style"
            alt=""
          />
          <span
            class="checkout-component__lsr_item-text"
            :style="{ color: item.text?.color }"
          >
            {{ item.text?.label }}
          </span>
        </div>
      </swiper-slide>
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </swiper-container>
</template>
<script setup>
import { ref, nextTick, watch, useSlots, computed, onMounted } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { register } from 'swiper/element'
import { Autoplay } from 'swiper/modules'  
const slots = useSlots()
const isClient = ref(false)
  
if (typeof window !== 'undefined') {
  register()
}
  
const carouselSwiperRef = ref(null)
let numIndex = ref(0)
const isInit = ref(false)
  
const props = defineProps({
  /**
     * @description config 参数说明
     * {
     *   text: {
     *     label: '',
     *        color: ''
     *   },
     *   icon: {
     *     name: '',
     *     color: ''
     *   },
     *   img: {
     *     url: '',
     *     style: {}
     *   }
     * }[]
     * */
  config: {
    type: Array,
    default: () => []
  },
  // 默认1000 匀速播放 传250是间歇性播放
  autoplayDelay: {
    type: Number,
    default: 1000
  },
  // autoplayDelay传递250，则这里传false 间歇性播放方可生效
  isDelayPlay: {
    type: Boolean,
    default: true
  }
})
const initSwiper = () => {
  isInit.value = true
  nextTick(() => {
    carouselSwiperRef.value &&
        Object.assign(carouselSwiperRef.value, {
          direction: 'vertical',
          modules: [Autoplay],
          autoplay: {
            delay: props.autoplayDelay,
            disableOnInteraction: false
          },
          noSwipingClass: 'checkout-component__label-swiper_item',
          loop: true,
          observer: true,
          observeParents: true,
          noSwiping: true,
          on: {
            slideChangeTransitionEnd: async swiper => {
              if (props.isDelayPlay) return
              numIndex.value++
              if (
                numIndex.value ===
                (props?.config?.length || Object.keys(slots).length)
              ) {
                swiper.autoplay.stop()
                numIndex.value = 0
                await timerAutoPlay(swiper)
              }
            }
          }
        })
    carouselSwiperRef.value.initialize()
  })
}
  
const timerAutoPlay = async swiper => {
  return new Promise(res => {
    setTimeout(() => {
      swiper.autoplay.start() // 播放完一轮后停止3秒,再播放
      res(true)
    }, 3000)
  })
}
  
const hasConfigOrSlots = computed(() => {
  return props.config || Object.keys(slots)?.length > 0
})
  
watch(
  [() => isClient.value, () => hasConfigOrSlots.value],
  () => {
    if (isClient.value && hasConfigOrSlots.value && !isInit.value) {
      initSwiper()
    }
  },
  { immediate: true }
)

onMounted(() => {
  isClient.value = true
})
</script>
  <style lang="less">
  .checkout-component {
    &__label-swiper {
      height: 20px;
      width: 100%;
      overflow: hidden;
      &_item {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
      }
    }
  
    &__lsr_item {
      &-text {
        margin-left: 3px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        line-height: normal;
      }
    }
  }
  </style>
  
