import own from './own'
import middleware from './middleware'
import itemInterfaceSer from 'public/src/services/goodsItemInfo/interfaceSer.js'
import { abtservice } from 'public/src/services/abt'
import { getAtomicRequestFileds } from 'public/src/services/goodsItemInfo/utils.js'

class FetchData {
  async parseAbt ({ poskey, newPoskey }) {
    let abtInfo = await abtservice.getUserAbtResult({ posKeys: poskey, newPosKeys: newPoskey })
    return Promise.resolve(abtInfo[poskey || newPoskey])
  }
  async getEmarsysData () {
    const startTime = Date.now()
    let productInfo = null
    try {
      productInfo = {}
      productInfo.time = Date.now() - startTime
      // 请求mall信息 库存先行
      await itemInterfaceSer.getAtomicInfo({ 
        goods: productInfo.products, 
        fields: { mallInfo: true, stock: true, baseInfo: true }
      })
      // 最后做过滤
      productInfo.products = middleware.filterList(productInfo.products)
    } catch (e) {
      console.error(e)
      productInfo = {
        reason: '请求错误或者超时',
        products: [],
        isFaultTolerant: 1,
        time: Date.now() - startTime,
        dataType: 'emarsys'
      }
    }
    return productInfo
  }
  async getOwnData (params) {
    let productInfo = null
    const startTime = Date.now()
    try {
      const extendAtomicFiles = params.itemConfig?.extendAtomicFiles || []
      const requestMenuList = ['prices', 'topPick', 'plusSize', 'locateLabels', ...extendAtomicFiles]
      if (params.withAtomic) {
        // 允许读取外部原子params.data.atomFields
        const fields = params?.data?.atomFields && typeof params.data.atomFields === 'object' ? params.data.atomFields : getAtomicRequestFileds(params.itemConfig, requestMenuList, true)
        params.data.atomFields = fields
        params.data.atomScene = {
          pageKey: params.pageKey || '',
          subPageKey: 'fourFiveImgRec', // 推荐写死的
          sceneKey: 'TWO_IN_A_ROW', // 推荐默认写死
        }
        // 请求推荐带上原子信息请求
        productInfo = await own.fetchData(params)
        productInfo.time = Date.now() - startTime
        // 一个变量要告诉后续是否需要继续请求原子信息
        // withAtomic： true 代表已经在之前请求过原子信息
        productInfo.withAtomic = true
      } else {
        productInfo = await own.fetchData(params)
        productInfo.time = Date.now() - startTime
      }
    } catch (e) {
      console.error(e)
      productInfo = {
        reason: '请求错误或者超时',
        products: [],
        isFaultTolerant: 1,
        time: Date.now() - startTime,
        dataType: 'own'
      }
    }
    return productInfo
  }
}
export {
  FetchData
}
