<template>
  <swiper-container
    v-if="show"
    ref="cartSwiperContainerRef"
    init="false"
    class="cart-swiper"
    :style="swiperStyle"
  >
    <!-- eslint-disable vue/no-deprecated-slot-attribute -->
    <div slot="container-start">
      <slot name="button-prev">
        <div
          ref="cartSwiperButtonPrevRef"
          class="swiper-button-prev swiper-button-disabled j-r-prev"
          tabindex="0"
          aria-label="prev page"
        >
          <sui_icon_more_left_18px
            :size="uiConfig.iconSize"
            :is-rotate="cssRight"
          />
        </div>
      </slot>
    </div>
    <swiper-slide
      v-for="(item, index) in items"
      :key="index"
    >
      <slot
        :item="item"
        :index="index"
      ></slot>
    </swiper-slide>
    <div slot="container-end">
      <slot name="button-next">
        <div
          ref="cartSwiperButtonNextRef"
          class="swiper-button-next j-r-next"
          tabindex="0"
          aria-label="next page"
        >
          <sui_icon_more_right_18px
            :size="uiConfig.iconSize"
            :is-rotate="cssRight"
          />
        </div>
      </slot>
    </div>
  </swiper-container>
</template>

<script setup>
import { ref, computed, watch, nextTick } from 'vue'
import { sui_icon_more_left_18px, sui_icon_more_right_18px } from '@shein-aidc/icon-vue3'
import { register } from 'swiper/element'
import { Navigation } from 'swiper/modules'
typeof window !== 'undefined' && register()

const props = defineProps({
  items: {
    type: Array,
    default(){
      return []
    },
  },
  swiperConfig: {
    type: Object,
    default(){
      return {}
    },
  },
  uiConfig: {
    type: Object,
    default(){
      return {}
    },
  },
  cssRight: {
    type: Boolean,
    default: false,
  },
})

const uiConfig = computed(() => ({
  iconSize: '12px',
  buttonSize: '16px',
  buttonGutter: '5px',
  buttonMarginTop: 'calc(var(--button-size) / -2)',
  ...props.uiConfig,
}))
const swiperStyle = computed(() => ({
  '--icon-size': uiConfig.value.iconSize,
  '--button-size': uiConfig.value.buttonSize,
  '--button-gutter': uiConfig.value.buttonGutter,
  '--button-margin-top': uiConfig.value.buttonMarginTop,
}))

const show = ref(true)
const swiper = ref(null)

const cartSwiperContainerRef = ref(null)
const cartSwiperButtonPrevRef = ref(null)
const cartSwiperButtonNextRef = ref(null)
const initSwiper = () => {
  if (!cartSwiperContainerRef.value) return

  let swiperConfig = {
    modules: [Navigation],
    navigation: {
      prevEl: cartSwiperButtonPrevRef.value,
      nextEl: cartSwiperButtonNextRef.value,
    },
    direction: 'horizontal',
    slidesPerView: 'auto',
    slidesPerGroup: 6,
    spaceBetween: 5,
    roundLengths: false,
    observer: true,
    observeParents: true,
    ...props.swiperConfig,
  }
  Object.assign(cartSwiperContainerRef.value, swiperConfig)
  cartSwiperContainerRef.value?.initialize?.()
  swiper.value = cartSwiperContainerRef.value?.swiper
}
watch(() => props.items, (val) => {
  if(typeof window === 'undefined') return
  if(!val.length) return
  if(swiper.value) {
    swiper.value.destroy?.()
    show.value = false
  }
  swiper.value = null
  nextTick(() => {
    show.value = true
    nextTick(() => {
      initSwiper()
    })
  })
}, { immediate: true })
</script>

<style lang="less">
.cart-swiper {
  position: relative;
  swiper-slide {
    width: max-content;
  }
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 50%;
    margin-top: var(--button-margin-top);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--button-size);
    height: var(--button-size);
    color: #222;
    text-align: center;
    vertical-align: unset;
    background: rgba(255,255,255,.94);
    border-radius: 100%;
    pointer-events: auto !important; /* stylelint-disable-line declaration-no-important */
    cursor: pointer;
  }
  .swiper-button-next {
    right: var(--button-gutter);
  }
  .swiper-button-prev {
    left: var(--button-gutter);
  }
}
</style>
