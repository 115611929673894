import { computed } from 'vue'
import { useStore } from 'vuex'
import { SMessage } from '@shein-aidc/sui-message'
import { expose_disable_wish, expose_wishlist_toast, click_add_collect } from 'public/src/pages/cart_v2/common/analysis/m2w'
import { isLogin } from 'public/src/pages/common/utils/index.js'

export function useGoodsAddWishEvent() {
  const { state, commit, dispatch } = useStore()
  const language = computed(() => state.language)

  const onReport = ({ itemOpts: { data } = {}, analysis = {} }) => {
    if (data.value.isNewcomerItem) {
      expose_disable_wish(data.value, analysis?.expose_disable_wish)
    } else {
      expose_wishlist_toast(data.value, analysis?.expose_wishlist_toast)
    }
  }

  const onButtonClick = async ({ itemOpts: { data, event } = {}, options: { events, isShowOverLimitToast = false } = {}, analysis }) => {
    const quickShip = data.value?.aggregateProductBusiness?.productTags?.some(tag => tag.type === 'quickShip') ? 1 : 0
    if (event === 'yes') {
      if (isLogin()) {
        try {
          commit('updateState', {
            key: 'loadingShow',
            value: true
          })
          const reqData = {
            params: { id: [data.value.id] }
          }
          if (events?.onSuccess) {
            reqData.events = {
              onSuccess: async () => {
                commit('updateState', {
                  key: 'loadingShow',
                  value: false
                })
                await events.onSuccess()
              }
            }
          }
          const res = await dispatch('fetchCartBatchWish', reqData)
          // 【埋点统一】 收藏
          click_add_collect(data.value, (defaultDate) => {
            defaultDate.result = {
              data: res
            }
            defaultDate.postData = {
              ...defaultDate.postData,
              action: 0,
              // 商品所在位置
              index: '',
              // 是否展示QS标签
              quickShip,
            }
            if (analysis?.click_add_collect) {
              return analysis.click_add_collect(defaultDate)
            }
            return defaultDate
          })
          if (res.code == '0') {
            if (typeof GB_analysis_obj !== 'undefined') {
              GB_analysis_obj.saveForLater({
                sku: data.value.product.goods_sn,
                value: 1,
                item: data.value
              })
            }
            dispatch('showOverLimitToast', { id: data.value.id, isShowOverLimitToast })
          } else {
            SMessage({
              offset: 136,
              type: 'error',
              message: res.msg,
            })
          }
        } finally {
          commit('updateState', {
            key: 'loadingShow',
            value: false
          })
        }
        
      } else {
        const { result, res } = await dispatch('handleCartLogin', { type: 'saveforlater' })
        if(res.code == 0) {
          if(!result.equalCart){
            SMessage({
              offset: 136,
              type: 'info',
              message: language.value.SHEIN_KEY_PC_17957,
              duration: 5000
            })
          }
        }
      }
    } else if (event === 'no') {
      click_add_collect(data.value, (defaultDate) => {
        defaultDate.result = 0
        defaultDate.postData = {
          ...defaultDate.postData,
          action: 1,
          // 商品所在位置
          index: '',
          // 是否展示QS标签
          quickShip,
        }
        if (analysis?.click_add_collect) {
          return analysis.click_add_collect(defaultDate)
        }
        return defaultDate
      })
    }
  }

  return {
    onReport,
    onButtonClick,
  }
}
