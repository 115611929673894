<template>
  <div
    v-if="countTimer?.timeObj"
    class="price-promotion__countdown-wrap"
  >
    <div>{{ language.SHEIN_KEY_PC_26828 }}</div>
    <div
      class="price-promotion__countdown-time"
      v-html="countdownTips"
    ></div>
  </div>
</template>

<script name="PromotionCountDown" setup>
import { ref, computed, watch } from 'vue'
import {
  CountDown,
  debounce,
} from '@shein/common-function'
import { useMillSecCountDown } from '@detail/utils/countDown'

/**
 * @file ProductIntroHeadPrice PromotionCountDown
 * 单品直降活动倒计时
 */

const props = defineProps({
  endTime: {
    type: String,
    default: '',
  },
  language: {
    type: Object,
    default: () => ({}),
  },
  isShowCountDownMillSec: {
    type: Boolean,
    default: false,
  },
})
const countTimer = ref(null)
const millSecCountDownTimer = ref(null)



const countdownTips = computed(() => {
  const { H, M, S } = countTimer.value?.timeObj || {}
  let spanTxt = txt => `<span>${txt}</span>`
  if (props.isShowCountDownMillSec) {
    return spanTxt(H) + ':' + spanTxt(M) + ':' + spanTxt(S) + ':' + spanTxt(millSecCountDownTimer.value.secondHundred)
  }
  return spanTxt(H) + ':' + spanTxt(M) + ':' + spanTxt(S)
})



watch(props.endTime, () => {
  initTimer()
}, {
  immediate: true,
})

function initTimer() {
  if (!props.endTime) return
  countTimer.value = new CountDown()
  if(props.isShowCountDownMillSec) {
    millSecCountDownTimer.value = useMillSecCountDown()
  }
  const beginTime = props.endTime * 1000 - new Date().getTime()
  if (beginTime <= 0) {
    beginTime == 0
  }

  // 初始化倒计时
  countTimer.value?.start({
    seconds: beginTime / 1000,
    endFunc: debounce({
      func: () => {
        location.reload()
      },
    }),
  })
}
</script>

<style lang="less">
.price-promotion__countdown-wrap {
  color:  #FA6338;
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  height: 18px;
  margin-left: auto;
  span {
    border-radius: 2px;
    background: #FFECE9;
    display: flex;
    padding: 2px;
    justify-content: center;
    align-items: center;
    margin: 0 2px;
    min-width: 18px;
  }
}

.price-promotion__countdown-time {
  display: flex;
  align-items: center;
  direction: ltr /* rtl:ignore */;
}
</style>
