<template>
  <div class="bsc-ccc-hotzone">
    <!-- 基础热区 -->
    <span
      v-for="(hotZone, i) in hotZones.standard"
      :id="hotZone.id"
      :key="hotZone.id"
      v-tap="{
        id: 'click_block_main.comp_top-banner',
        data: {
          index: i,
          compIndex: index,
          item: hotZone.config.hrefType,
          hotZoneParams: { hot_area: hotZone.name, hot_area_loc: i + 1, tag_nm: hotZone.name },
        },
      }"
      v-expose="{
        id: 'expose_block_main.comp_top-banner',
        data: {
          index: i,
          compIndex: index,
          item: hotZone.config.hrefType,
          hotZoneParams: { hot_area: hotZone.name, hot_area_loc: i + 1, tag_nm: hotZone.name },
        },
      }"
      :style="getHotStyle(hotZone)"
    >
      <a
        :href="handleGetFullLink(hotZone, i)"
        class="j-hotZone block"
        role="link"
        tabindex="0"
        :aria-label="getAriaLabel(hotZone)"
        @click.prevent="clickHotZone(hotZone, i)"
      ></a>
    </span>

    <!-- 时间格式化组件 -->
    <span
      v-for="hotZone in hotZones.timeTransform"
      :id="hotZone.id"
      :key="hotZone.id"
      :style="getHotTimeComponentsStyle(hotZone)"
    >
      <a
        href="javascript:;"
        class="bsc-ccc-hotzone__tag"
        :style="getHotTimeBgColor(hotZone)"
      >
        {{ getDateTime(hotZone) }}
      </a>
    </span>
  </div>
</template>

<script setup>
import { reactive, onMounted, inject } from 'vue'
import { timezoneFormatFromSite } from '../../common/timeFormat.js'
import { useLink } from '@shein-aidc/bs-sdk-ccc-link'
import { commonProps } from '../../common/common-props.js'

const { vTap, vExpose } = inject('analysisInstance')

import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

const appConfigs = useAppConfigs()

const { siteUID, lang, dateLangMap } = appConfigs?.$envs || {}

const dateFormatOptions = {
  SiteUID: siteUID,
  lang,
  dateLangMap,
}

const props = defineProps({
  ...commonProps,
})

const { cccLink } = useLink({
  propData: props.propData,
})

const hotZones = reactive({
  standard: [],
  timeTransform: [],
})

const handleGetFullLink = (hotZone, i) => {
  let item = hotZone?.config?.hrefType || {}
  return cccLink.getFullLink({
    item,
    index: i,
    hotZoneParams: {
      hot_area: hotZone.name,
      tag_nm: hotZone.name,
    },
  })
}

const getHotStyle = (hotZone) => {
  let position = hotZone.position || {}
  return {
    position: 'absolute',
    cursor: 'pointer',
    border: 'none',
    top: position.y * 100 + '%',
    left: position.x * 100 + '%',
    height: position.height * 100 + '%',
    width: position.width * 100 + '%',
  }
}



const getHotTimeComponentsStyle = (hotZone) => {
  let config = hotZone.config || {}
  let fontColor = handleColor(config.fontColor)
  return {
    ...getHotStyle(hotZone),
    color: fontColor || 'black',
    fontSize: `${config.fontSize || '14'}px`,
  }
}

const getHotTimeBgColor = (hotZone) => {
  let config = hotZone.config || {}
  let { bgColorType = 'single' } = config
  let bgColorStart = handleColor(config.bgColorStart)
  let bgColorEnd = handleColor(config.bgColorEnd)
  const timeStyle = {
    background: 'none',
  }

  switch (bgColorType) {
    case 'gradation':
      timeStyle.background = `linear-gradient(to right, ${bgColorStart} 0%, ${bgColorEnd} 100%)`
      break
    case 'single':
      timeStyle.background = bgColorStart
      break
    case 'none':
      timeStyle.background = 'none'
      Object.assign(timeStyle, {
        transform: 'none',
        top: '0',
        left: '0',
        padding: '0',
      })
      break
  }

  return timeStyle
}

const handleColor = (str) => {
  if (!str) return ''
  if (str.length === 9) {
    return str[0].concat(str.slice(3, 9), str.slice(1, 3))
  }
  return str
}

const getAriaLabel = (hotzone) => {
  return hotzone?.config?.hrefType?.ada || 'hotzone'
}

/**
 * 点击热区 跳转 or 弹窗
 */
const clickHotZone = (hotZone, index, type) => {
  const { hotZoneType, hrefType } = hotZone?.config || {}
  // 热点跳转类型
  if (+hotZoneType === 1 && hrefType || type) {
    const url = handleGetFullLink(hotZone, index)
    if (!url || url.indexOf('javascript:;') > -1) return
    location.href = url
  }
}

const getDateTime = (hotZone) => {
  const timeFormat = hotZone?.config?.timeFormat || 'MM/DD HH:mm'
  const start = hotZone?.config?.startTime
  const end = hotZone?.config?.endTime
  const startDate = start ? timezoneFormatFromSite(+start, 'shortDate', dateFormatOptions) : ''
  const startTime = start ? timezoneFormatFromSite(+start, 'onlyShortTime', dateFormatOptions) : ''
  const endDate = end ? timezoneFormatFromSite(+end, 'shortDate', dateFormatOptions) : ''
  const endTime = end ? timezoneFormatFromSite(+end, 'onlyShortTime', dateFormatOptions) : ''
  let result = ''
  switch (timeFormat) {
    case 'MM/DD HH:mm':
    case 'MM/dd HH:mm':
      result = endDate ? `${startDate} ${startTime} - ${endDate} ${endTime}` : `${startDate} ${startTime}`
      break
    case 'MM/DD':
      result = endDate ? `${startDate} - ${endDate}` : `${startDate}`
      break
    case 'HH:mm':
      result = endDate ? `${startTime} - ${endTime}` : `${startTime}`
  }
  return result
}

onMounted(() => {
  hotZones.standard = props.propData?.hotZones?.standard || []
  hotZones.timeTransform = props.propData?.hotZones?.timeTransform || []
})
</script>

<style lang="less" scoped>
/* stylelint-disable declaration-no-important, selector-class-pattern, selector-nested-pattern */

@keyframes breathe {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}

.bsc-ccc-hotzone {
  .block {
    height: 100%;
    width: 100%;
    display: block;
  }
  &__dialog {
    cursor: default;
    .dialog-bg {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  &__tag {
    white-space: nowrap;
    position: absolute;
    color: rgb(0, 0, 0);
    width: fit-content;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
    text-decoration: none;
    line-height: 1.16;
    padding: 8px 32px;
    color: inherit;
  }

  :deep(.sui-dialog__W720) {
    width: 480px;
  }
  :deep(.sui-dialog__body) {
    padding: 0 32px;
  }
}
</style>
