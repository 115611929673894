// 凑单钩子函数
export const HOOKS = {
  open: 'open',
  close: 'close',
  viewCart: 'viewCart',
  updateCart: 'updateCart',
  pickItem: 'pickItem',
  viewTap: 'viewTap',
  openLogin: 'openLogin',
}
