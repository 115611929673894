<template>
  <div
    v-if="isOutOfViewport"
    class="filter-goods-tip"
    @click="viewHighWeightGoods"
  >
    <div class="goods-tip-content">
      <div class="goods-img">
        <img :src="filterData?.recommendProductInfo?.goodsImg" />
      </div>
      <span class="tip-text">
        <span v-html="filterData?.recommendProductInfo?.text"></span>
        <count-down-time
          v-if="filterData?.recommendProductInfo?.endTimeStamp"
          class="count-down-time"
          font-size="12px"
          :hide-tip="true"
          not-hide
          not-reload
          :time-stamp="+filterData?.recommendProductInfo?.endTimeStamp"
        />
      </span>
    </div>
    <div class="view-btn">
      <span class="view-text">{{ language.SHEIN_KEY_PC_31737 }}</span>
      <Icon
        class="view-icon"
        name="sui_icon_doubledirection_12px"
        size="12px"
        color="#FA6338"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HighPriorityGoodsTips',
}
</script>

<script setup>
import { Icon } from '@shein-aidc/icon-vue3'
import { onMounted, defineProps, nextTick, ref, watch } from 'vue'
import { daEventCenter as daEventCenterV2 } from '@shein-aidc/basis-sa-event-center'
import countDownTime from 'public/src/pages/cart_v2/components/functional/common/CartCountDown.vue'
import checkoutIncentive from 'public/src/pages/cart_v2/components/functional/cart-checkout-incentive/checkoutIncentive.js'

const props = defineProps({
  filterData: {
    type: Object,
    default: () => {}
  },
  language: {
    type: Object,
    default: () => {}
  }
})

const isOutOfViewport = ref(false)
const computedSite = () => {
  const targetDom = document.querySelector(`.j-valid-cart-item.j-cart-item-${props.filterData?.recommendProductInfo?.cartId}`)
  if (!targetDom) return false
  const rect = targetDom.getBoundingClientRect()
  const windowHeight = window.innerHeight || document.documentElement.clientHeight
  return rect.bottom > windowHeight
}

watch(() => props.filterData?.recommendProductInfo?.cartId, (val) => {
  if (val) {
    nextTick(() => {
      isOutOfViewport.value = computedSite()
    })
  }
}, { immediate: true })

const viewHighWeightGoods = () => {
  daEvent('click_select_item_information.page_cart')
  const targetDom = document.querySelector(`.j-valid-cart-item.j-cart-item-${props.filterData?.recommendProductInfo?.cartId}`)
  targetDom.scrollIntoView({
    behavior: 'smooth', // 平滑滚动
    block: 'center',    // 垂直居中
  })
  blinkElement(targetDom, '#FFFBEE', 400, 2) // 400ms一次，闪烁2次
}
// 设置闪烁函数
const blinkElement = (el, color, interval, times) => {
  const bsCartItemDom = el.querySelector('.bsc-cart-item-main__wrap')
  let originalColor = window.getComputedStyle(el).background
  let count = 0
  const timer = setInterval(() => {
    if (count % 2 === 0) {
      el.style.background = color
      bsCartItemDom.style.background = color
    } else {
      el.style.background = originalColor
      bsCartItemDom.style.background = originalColor
    }
    count++
    if (count >= times * 2) {
      clearInterval(timer)
      el.style.background = originalColor // 确保最终背景颜色为原始颜色
      bsCartItemDom.style.background = originalColor
    }
  }, interval)
}
const daEvent = (id) => {
  daEventCenterV2.triggerNotice(({
    id,
    data: {
      type: props.filterData?.recommendProductInfo?.biType,
      sku_id: props.filterData?.recommendProductInfo?.skuCode,
    }
  }))
}

onMounted(() => {
  daEvent('expose_select_item_information.page_cart')
  checkoutIncentive.setBlacklistCacheKeys([props.filterData?.recommendProductInfo?.cacheKey])
})
</script>

<style lang="less" scoped>
.filter-goods-tip {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  margin: 8px 0;
  padding: 10px 12px;
  border: solid 1px #FFFFFF;
  background: linear-gradient(#FFF6F6, #FFFFFF);
  box-sizing: border-box;
  margin-top: 10px;
  .goods-tip-content {
    flex: 1;
    display: flex;
    align-items: center;
    .goods-img {
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      overflow: hidden;
      border-radius: 2px;
      margin-right: 4px;
      img {
        width: 100%;
      }
    }
    .tip-text {
      flex: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      font-size: 12px;
      line-height: 15px;
    }
  }
  /deep/.count-down-time {
    display: inline;
    .time-content {
      color: #FA6338;
    }
    .count-number {
      color: #FA6338;
      font-weight: 600;
    }
  }
  .view-btn {
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    width: fit-content;
    max-width: 99px;
    height: 24px;
    padding: 5px 7px;
    border: solid 0.5px #FA6338;
    color: #FA6338;
    border-radius: 28px;
    overflow: hidden;
    cursor: pointer;
    .view-text {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 11px;
      font-weight: 590;
    }
    .view-icon {
      flex-shrink: 0;
    }
  }
}
</style>
