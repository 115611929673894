/**
 * 获取促销活动满足情况
 * isFullPromotion 0: 满足 1: 不满足 2: 满足部分
 * ---------------
 * 3 10 11 12 24 29 30恒满足
 * 8 恒不满足
 */
export function cartPromotionMapHandle({ integrityPromotionInfo  = {}, promotionEventTrackingPoint = [] } = {}) {
  const IS_FULL = 0
  const IS_NOT_FULL = 1
  const IS_FULL_PART = 2

  //all 1 2 3 4(全场) 8 9 10 11 12 13 14(全场) 15 18 19 20 21(全场) 22(全场) 24 25 26(全场)
  // check isFullPromotion
  const isFull = [3, 10, 11, 12, 24, 29, 30]
  const isNotFull = [8]

  /**
   * id: {
      isFullPromotion: 0,
      typeId: xxxx
      hasCartItemChecked: true // 是否选中的商品参与的活动
    }
   */
  const map = {}

  const initMap = ({ typeId, id, hasCartItemChecked, coupon_num }) => {
    if (map[id] === undefined) {
      map[id] = {
        promotion_id: id,
        isFullPromotion: isFull.includes(typeId) ? IS_FULL : isNotFull.includes(+typeId) ? IS_NOT_FULL : 1,
        typeId,
        hasCartItemChecked: !!hasCartItemChecked,
        is_couponbag: [20, 21].includes(+typeId) ? (coupon_num > 1 ? 1 : 0) : '-',
        coupon_num: coupon_num
      }
    }
  }

  const checkPromotionRangeNext = (id, range, next) => {
    if (map[id].isFullPromotion === IS_NOT_FULL) {
      if (range == 0) {
        map[id].isFullPromotion = IS_NOT_FULL
      } else if (range > 0 && next != 0) {
        map[id].isFullPromotion = IS_FULL_PART
      } else if (range > 0 && next == 0) {
        map[id].isFullPromotion = IS_FULL
      }
    }
  }

  Object.values(integrityPromotionInfo).forEach(promotion => {
    let { range, next, coupon_num = 0, type_id, promotion_id, hasCartItemChecked } = promotion.data || {}
    initMap({ typeId: +type_id, id: promotion_id, hasCartItemChecked, coupon_num })
    if (promotion_id) {
      let target = promotionEventTrackingPoint?.find(item => promotion_id == item.promotionId)
      if(target) {
        map[promotion_id].isFullPromotion = target.isFullPromotion
        map[promotion_id].hasCartItemChecked = true //全场活动的默认就是选中的
      } else {
        checkPromotionRangeNext(promotion_id, range, next)
      }
    }
  })

  return map
}
