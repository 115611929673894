import { daEventCenter } from 'public/src/services/eventCenter/index'

export const expose_quantity_edit_Input_button = (data, callback) => {
  const defaultData = {
    skc: data?.product?.goods_sn || '',
    sku: data?.product?.sku_code || '',
    scene: 'cart',
  }
  daEventCenter.triggerNotice({ daId: '1-7-3-44', extraData: callback?.(defaultData) ?? defaultData })
}

export const click_update_quantity = (data, callback) => {
  const defaultData = {
    skc: data?.product?.goods_sn || '',
    sku: data?.product?.sku_code || '',
    result: '-',
    scene: 'cart',
  }
  daEventCenter.triggerNotice({ daId: '1-7-3-47', extraData: callback?.(defaultData) ?? defaultData })
}

export const click_quantity_edit_select_button = (data, callback) => {
  const defaultData = {
    skc: data?.product?.goods_sn || '',
    sku: data?.product?.sku_code || '',
    result: '-',
    scene: 'cart',
  }
  daEventCenter.triggerNotice({ daId: '1-7-3-45', extraData: callback?.(defaultData) ?? defaultData })
}

export const click_quantity_edit_Input_button = (data, callback) => {
  const defaultData = {
    skc: data?.product?.goods_sn || '',
    sku: data?.product?.sku_code || '',
    scene: 'cart',
  }
  daEventCenter.triggerNotice({ daId: '1-7-3-46', extraData: callback?.(defaultData) ?? defaultData })
}

export const expose_reachedflashsalelimit = (data, callback) => {
  const defaultData = {}
  daEventCenter.triggerNotice({ daId: '1-7-7-13', extraData: callback?.(defaultData) ?? defaultData })
}
