<template>
  <div class="c-order-summary">
    <div
      style="position: relative;"
    >
      <div
        class="summary-item total-item"
        tabindex="0"
      >
        <div class="price-label">
          {{ priceLabel }}
        </div>
        <div>
          <CartAllDiscountDetailPopover :disabled="!showTotalPriceDetailEnter">
            <div class="summary-price">
              <div
                v-if="crossedPrice"
                class="summary-price-crossed"
                :title="crossedPrice.description"
              >
                <span
                  class="crossed-price-text"
                >
                  {{ crossedPrice.description }}
                </span>
              </div>
              <div>
                <span
                  v-if="crossedPrice"
                  class="crossed-price-text crossed-price"
                >{{ crossedPrice.price.amountWithSymbol }}</span>
                <span
                  class="total"
                  style="font-weight: bold;"
                  :style="{
                    'color': totalPrice.color
                  }"
                >
                  <template v-if="isSafari">{{ totalPrice.price.amountWithSymbol || 0 }}</template>
                  <number-roll
                    v-else
                    :amount="totalPrice.price.amountWithSymbol || 0"
                    :box-width="16"
                    :box-top="1"
                  />
                </span>
              </div>
            </div>
          </CartAllDiscountDetailPopover>
        </div>
      </div>
      <div
        v-if="lang == 'au'"
        class="summary-item gst-item"
      >
        <span></span> <span>Incl GST</span>
      </div>
      <div
        v-if="cartInfo.taxInclusiveTip"
        class="summary-tax-info not-fsp-element"
      >
        {{ cartInfo.taxInclusiveTip }}
      </div>
      <!-- 优惠明细 -->
      <LazyHydrationWrapper :when-triggered="isIdle">
        <template v-if="savedTip">
          <div class="cart-checkout__discountDetails">
            <CartAllDiscountDetailPopover :disabled="!showSavePriceDetailEnter">
              <span
                class="cart-checkout__discountDetails-text"
              >
                {{ savedTip }}
              </span>
            </CartAllDiscountDetailPopover>
          </div>
        </template>
      </LazyHydrationWrapper>
      <!-- 积分提示 -->
      <CartPointsTip />
    </div>

    <div
      :class="[
        'check-btn',
        checkoutButtonPopoverType == 'incentive' ? 'incentive-check-btn' : '',
      ]"
    >
      <component
        :is="popoverName"
        :tip="language.SHEIN_KEY_PC_25310"
        :checkout-button-popover-type="checkoutButtonPopoverType"
        @set-popover-type="setPopoverTypeWithPriority"
      >
        <s-button
          :type="['primary','H54PX']"
          :class="[
            'j-cart-check',
            'incentive-button',
          ]"
          role="button"
          width="100%"
          :disabled="!cartInfo.cartSumQuantity"
          @click="goCheckout"
        >
          <span
            v-show="!checking"
            class="checkout-btn-content"
          >
            {{ controlCheckoutNameText ? controlCheckoutNameText : checkoutNameDefaultText }}
          </span>
          <CheckoutButtonIncentiveText
            v-show="!checking"
            :control-checkout-name-text="controlCheckoutNameText"
          />
          <div
            v-show="checking"
            :class="['la-ball-pulse', 'la-ball-pulse-white']"
          >
            <div></div>
            <div></div>
            <div></div>
          </div>
          <ClientOnly>
            <AutoStretchSwiperTag
              class="free-shipping-tip not-fsp-element"
              :show-free-shipping-tag="showFreeShipping"
              :show-quick-ship-tag="showQuickShip"
              stretch-direction="rtl"
              :language="language"
            />
          </ClientOnly>
        </s-button>
        <span :class="['incentive-button__sweep-box', checkoutButtonPopoverType == 'incentive' ? 'sweep-light' : '']"></span>
      </component>

      <!-- 优惠券提示 -->
      <p
        class="coupon-point-tip"
        tabindex="0"
        v-html="language.SHEIN_KEY_PC_14551"
      ></p>
    </div>

    <CartRuleInfo
      v-if="showCartRuleInfo"
      :msg="overLimitTipOnBottom"
    />
    <CartLowPriceUser
      v-if="asyncComponentLoadedStatus.lowPriceUserDialogIsLoaded"
      @close-low-price-user-evt="closeLowPriceUserEvt"
      @emit-click-checkout-evt="emitClickCheckoutEvt"
    />
  </div>
</template>

<script>
export default {
  name: 'CartCheckout',
}
</script>
<script setup>
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { useStore } from 'vuex'
import { computed, defineAsyncComponent, ref, onBeforeMount, onMounted } from 'vue'
import { LazyHydrationWrapper } from '@shein-aidc/shein-lazy-hydration'
import { web_checkout_button_jump, web_checkout_button_click, web_checkout_button_error } from 'public/src/pages/cart_v2/utils/metricTagsConfig.js'
import { SMessage } from '@shein-aidc/sui-message'
import AutoStretchSwiperTag from '../common/AutoStretchSwiperTag.vue'
import CartAllDiscountDetailPopover from '../cart-discount-detail/CartAllDiscountDetailPopover.vue'
import CartRuleInfo from './CartRuleInfo.vue'
import CartPointsTip from './CartPointsTip.vue'
import checkoutIncentive from '../cart-checkout-incentive/checkoutIncentive.js'
import { getNoCheckStockCondition } from 'public/src/pages/cart_v2/utils/getNoCheckStockCondition'
import { checkStock } from 'public/src/pages/cart_v2/services/api.js'
import apiReport from 'public/src/pages/common/apiReport'
import { getQueryString } from '@shein/common-function'
import UserInfoManager from 'public/src/services/UserInfoManager/index.js'
import { getToCheckoutUrl } from 'public/src/pages/cart_v2/utils/getToCheckoutUrl'
import { abtservice } from 'public/src/services/abt'
import { CHECKOUT_REFETCH_CART_API } from 'public/src/pages/cart_v2/utils/PromiseActuator.js'

const CheckoutFilterPopover = defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-checkout" */'./checkoutFilterPopover.vue'))
const CheckoutIncentivePopover = defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-checkout" */'./CheckoutIncentivePopover.vue'))
const CheckoutButtonIncentiveText = defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-checkout" */'./CheckoutButtonIncentiveText.vue'))
const CartLowPriceUser = defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-low-price-user" */'../cart-low-price-user/index.vue'))

daEventCenter.addSubscriber(['page_cart', '1-7-2'])

const { state, getters, commit, dispatch } = useStore()
let checkoutButtonPopoverType = ref('')
let checking = ref(false)
let isSafari = ref(false)
let isIdle = ref(false)
let lowerPriceParams = {}
let abtTest = {}

const cartInfo = computed(() => state.cartInfo)
const carts = computed(() => state.carts)
const cartIds = computed(() => state.cartIds)
const abtInfo = computed(() => state.cartAbtInfo)
const language = computed(() => state.language)
const lang = computed(() => state.locals.lang)
const asyncComponentLoadedStatus = computed(() => state.asyncComponentLoadedStatus)
const isExposePriceDetail = computed(() => getters.isExposePriceDetail)
const underPriceAbtValue = computed(() => getters.underPriceAbtValue)
const couponInfo = computed(() => state.couponInfo)

const priceLabel = computed(() => {
  if (!isExposePriceDetail.value) return ''
  return state.isSuggested ? `${language.value.SHEIN_KEY_PC_29824}:` : `${language.value.SHEIN_KEY_PC_29827}:`
})

// 整车价格模块
const mallCartPriceData = computed(() => {
  return cartInfo.value?.mallCartPriceData || {}
})
// 划线价
const crossedPrice = computed(() => {
  return mallCartPriceData.value?.crossedPrice || null
})
// 到手价
const estimatedPrice = computed(() => {
  return mallCartPriceData.value?.estimatedPrice || null
})

// 节省金额
const savedPrice = computed(() => {
  return mallCartPriceData.value?.savedPrice || null
})
// 总价
const totalPrice = computed(() => {
  return mallCartPriceData.value?.totalPrice || null
})
const saveAmount = computed(() => {
  if (mallCartPriceData.value?.priceMode !== 'normal') {
    return estimatedPrice.value
  }
  return savedPrice.value
})
const savedTip = computed(() => {
  return saveAmount.value ? `${saveAmount.value.description} ${saveAmount.value.price.amountWithSymbol}` : ''
})

/**
 * 是否有整车优惠明细
 */
const showPromotionDetailPopUp = computed(() => cartInfo.value.showPromotionDetailPopUp === '1')
/**
 * 优惠提示右侧是否展示整车优惠明细箭头
 */
const showSavePriceDetailEnter = computed(() => {
  return !!saveAmount.value && showPromotionDetailPopUp.value
})
/**
 * 总价右侧是否展示整车优惠明细箭头
 */
const showTotalPriceDetailEnter = computed(() => {
  return !showSavePriceDetailEnter.value && showPromotionDetailPopUp.value
})

const popoverName = computed(() => {
  return checkoutButtonPopoverType.value == 'incentive' ? CheckoutIncentivePopover : CheckoutFilterPopover
})

const controlCheckoutNameText = computed(() => {
  const { interceptAction: action, interceptType, checkoutButtonText } = cartInfo.value?.cartInterceptions?.find(i => i.activityType == 1) || {}
  // checkout文案展示调整
  let {
    ControlLowpriceUser = '',
    ControlCheckoutname = '',

  } = state.cartAbtInfo.LowpriceUser?.param || {}

  // 临时后端兼容性措施, 低客拦截abt
  if(!ControlLowpriceUser || ControlLowpriceUser == 'off') return ''

  if(!action) return ''
  else if((action == 1 || action == 2) && Number(interceptType || 0) > 0 ){
    if(ControlCheckoutname == 'on') return checkoutButtonText || ''
    else return ''
  } else return ''
})

const checkoutNameDefaultText = computed(() => {
  if (!cartInfo.value.checkedCartSumQuantity) return language.value.SHEIN_KEY_PC_14543
  return language.value.SHEIN_KEY_PC_14543 + ` (${cartInfo.value.checkedCartSumQuantity})`
})

const openControlLowPriceUser = computed(() => {
  const { interceptType = 0, lowThresholdValue = 0 } = cartInfo.value?.cartInterceptions?.find(i => i.activityType == 1) || {}

  return {
    interceptType,
    lowThresholdValue
  }
})

const checkoutLabelList = computed(() => state.checkoutState?.checkoutLabelList)
// 是否展示freeShipping
const showFreeShipping = computed(() => {
  return checkoutLabelList.value?.find(item => item.type == 1)?.tip || false
})
// 是否展示quickship
const showQuickShip = computed(() => {
  return checkoutLabelList.value?.find(item => item.type == 0)?.tip || false
})
const overLimitTipOnBottom = computed(() => {
  return state.checkoutState.overLimitTipOnBottom || ''
})
const showCartRuleInfo = computed(() => state.locals.lang == 'br' && state.cartAbtInfo?.brTaxhint?.param?.isSwitchOn == '1' && overLimitTipOnBottom.value)

const setPopoverTypeWithPriority = (value) => {
  if(value == '' || checkoutButtonPopoverType.value == '') {
    checkoutButtonPopoverType.value = value
    return true
  }
  const Priority = ['newUser', 'incentive', 'filter']
  let targetOrder = Priority.indexOf(value)
  let currentOrder = Priority.indexOf(checkoutButtonPopoverType.value)
  if(targetOrder >= 0 && targetOrder < currentOrder) {
    checkoutButtonPopoverType.value = value
    return true
  }
  return false
}

onMounted(() => {
  checkoutIncentive.setHooks({
    show(data) {
      commit('updateCheckoutState', {
        currentCartLure: data.cartLure,
      })
      const res = setPopoverTypeWithPriority('incentive')
      return res
    },
    hide() {
      if (checkoutButtonPopoverType.value !== 'incentive') return false
      setPopoverTypeWithPriority('')
      return true
    },
  })
})

const emitClickCheckoutEvt = ({ value = 0, msg = '', scenes = '', reason = '', options = {} }) => {
  emitInitCheckout({ value, msg, scenes, reason, options })
}

const closeLowPriceUserEvt = ({ click_type = '', click_from = '', click_status = '' } = {}) => {
  commit('updateState', {
    key: 'showLowPriceUser',
    value: false
  })
  if (click_type == 'lowerPriceUser') {
    lowerPriceParams = { click_from, status: click_status }
    goCheckout(click_type)
  }
}

const emitInitCheckout = ({ value = 0, msg = '', scenes = '', reason = '', options = {} }) => {
  let mallList =  state.mallCartList.map(item => {
    return {
      mall_code: item.mall_code,
      mallTotalPrice: item.mallTotalPrice
    }
  })
  daEventCenter.triggerNotice({
    id: 'click_checkout.page_cart',
    data: {
      carts: carts.value,
      value,
      msg,
      scenes: 'conventional',
      cartInfo: cartInfo.value,
      mallShippingAnalysisParam: state.mallShippingAnalysisParam,
      promotionAnalysisParam: state.promotionState.cartPromotionAnalysis,
      isPlatForm: state.isPlatForm,
      mallList,
      showQuickShip: showQuickShip.value,
      reason: reason,
      prime_flag: '-',
      abtest: abtTest?.sa || '',
      button_name: +openControlLowPriceUser.value.interceptType ? openControlLowPriceUser.value.interceptType == 1 ? `lower_checkout_${openControlLowPriceUser.value.lowThresholdValue}` : `power_checkout_${openControlLowPriceUser.value.lowThresholdValue}` : 'checkout',
      click_from: 1,
      status: +openControlLowPriceUser.value.interceptType ? 0 : 1,
      coupon_satisfied: couponInfo.value?.cmpUsableCoupons?.length > 0 ? 1 : 0,
      ...(options || {}),
      ...(lowerPriceParams || {}),
      label_id: state.filterState?.selectFilterLabel?.labelId || 'none',
    }
  })
}

const initLowerPriceUserAbtTest = () => {
  const lowPriceUser = state.cartAbtInfo.LowpriceUser || {}
  // eslint-disable-next-line @shein-aidc/abt/abt
  let abtTest = abtservice.getUserAbtResultForAnalysis({ abtInfo: { 'LowpriceUser': lowPriceUser } })
  abtTest = abtTest || {}
}

const recordLog = (type) => {
  if(+type > 0){
    checking.value = false
  }

  web_checkout_button_jump(type)
}
const goCheckout = async (opt) =>{
  if(checking.value) return
  checking.value = true
  web_checkout_button_click()

  if(!cartInfo.value.checkedCartSumQuantity){
    recordLog('1')
    SMessage({
      message: cartInfo.value.checkedInterceptTip || language.value.SHEIN_KEY_PC_21653,
      type: 'error'
    })
    return
  }
  // 登录注册>闪购限购>限时折扣超限>打折超限 wiki.pageId=1137561716
  const validatorCallback = () => {
    if(cartInfo.value.interceptResultInfo) {
      const needClearFilter = () => {
        const items = cartInfo.value.interceptResultInfo?.promotionInterception?.popupNodeDataList?.flatMap(list => list?.nodeData?.flatMap(node => node?.productInfoList ?? [])) ?? []
        return items.some(item => !carts.value.find(cart => cart.mall_code === item.mall_code && cart.skuCode === item.product.sku_code && cart.store_code === item.store_code))
      }
      if (needClearFilter()) {
        // 异步清空筛选状态
        CHECKOUT_REFETCH_CART_API.init()
        Promise.resolve(dispatch('clearFilter')).finally(() => {
          CHECKOUT_REFETCH_CART_API.resolve()
        }).catch((err) => {
          // 查车接口报错兜底异步场景,尝试异步再尝试一次
          dispatch('clearFilter')
        })
      }
      commit('updateState', { key: 'showPromotionInterception', value: true })
      recordLog('4')
      let promotionOver = {}
      let flashSaleLimit = []
      let discountSaleLimit = []
      carts.value.filter(cartItem => cartItem.is_checked && [10, 11, 24].includes(+cartItem.aggregateProductBusiness?.type_id) && cartItem.aggregateProductBusiness?.overLimit == 1).forEach(item => {
        if(!promotionOver[item.aggregateProductBusiness?.promotion_id]) {
          promotionOver[item.aggregateProductBusiness?.promotion_id] = true
          item.aggregateProductBusiness?.type_id == 24 ? discountSaleLimit.push({ reason: item.aggregateProductBusiness.singleOrTotalOver }) : flashSaleLimit.push({ flashSaleType: item.aggregateProductBusiness?.type_id == 11 ? 11 : item.aggregateProductBusiness.flash_type, reason: item.aggregateProductBusiness.singleOrTotalOver })
        }
      })
      if(flashSaleLimit.length) {
        daEventCenter.triggerNotice({
          id: '1-7-2-4',
          data: {
            is_flashsale: flashSaleLimit.map(item => item.flashSaleType).join(',')
          }
        })
      }
      const flashReasonList = flashSaleLimit.map(i => `${i.flashSaleType > 10 ? i.flashSaleType : 10}\`${i.flashSaleType > 10 ? '-' : i.flashSaleType}\`${i.reason > 2 ? 2 : i.reason}`)
      const limitReasonList = discountSaleLimit.map(i => `24\`-\`${i.reason}`)
      daEventCenter.triggerNotice({
        id: '1-7-2-15',
        data: {
          reason: [...flashReasonList, ...limitReasonList].join(',')
        }
      })
      emitInitCheckout({ value: 0, msg: 'over', reason: 3 })
          
      return false
    }
    let underPriceOverItem = carts.value.some(item => item.aggregateProductBusiness?.overLimit == 1 && item.aggregateProductBusiness?.type_id == 30)
    if(underPriceOverItem) {
      recordLog('7')
      SMessage({ content: underPriceAbtValue.value ? language.value.SHEIN_KEY_PC_24849 : language.value.SHEIN_KEY_PC_24848, })
      dispatch('clearFilter') // 清空筛选状态
      emitInitCheckout({ value: 0, msg: 'over', reason: 3 })
      return
    }

    return true
  }
  const validatorCallbackV2 = async () => {
    const needShow = () => !!cartInfo.value.interceptResultInfo?.promotionInterceptionV2
    const needClearFilter = () => {
      const itemIds = cartInfo.value.interceptResultInfo?.promotionInterceptionV2?.popupNodeDataList?.flatMap(group => group.nodeData.flatMap(node => node.cateIdList ?? []))
      return itemIds.some(id => !cartIds.value.includes(id))
    }
    if (abtInfo.value?.ActivityOver?.param?.ControlActivityOver == 'new') {
      if (needShow()) {
        if (needClearFilter()) {
          try {
            commit('updateState', { key: 'loadingShow', value: true })
            await dispatch('clearFilter')
            return await validatorCallbackV2()
          } catch {
            dispatch('clearFilter')
            return false
          } finally {
            commit('updateState', { key: 'loadingShow', value: false })
          }
        }
        recordLog('4')
        commit('updateState', { key: 'showPromotionInterceptionV2', value: true })
        emitInitCheckout({ value: 0, msg: 'over', reason: 3 })
        return false
      }
      return true
    }
    return validatorCallback()
  }
  
  try {
    const noCheckStockCondition = getNoCheckStockCondition({ carts: carts.value, lastCheckStockTime: state.lastCheckStockTime })
    let res = noCheckStockCondition ? 
      await new Promise(resolve => resolve({ code: 0 })) :
      await checkStock()

    if (res?.code == 0) {
      if (res?.info?.outStockCarts?.length && !noCheckStockCondition) { //如果购物车商品有sold out
        recordLog('3')
        dispatch('checkSoldOut', { outStockCarts: res.info.outStockCarts })
        commit('updateState', { key: 'showSoldOutDialog', value: true })
        emitInitCheckout({ value: 0, msg: 'out of stock', reason: 2 })
      } else {
        // PWA逻辑平移
        !noCheckStockCondition && commit('updateState', { key: 'lastCheckStockTime', value: Date.now() });

        (window.cartCheckLogin || UserInfoManager.get({ key: 'UserInfo', options: { noCache: true }, actionType: 'cart/checkLogin' })).then(async (data) => {
          if (data.member_id) {
            if (!await validatorCallbackV2()) {
              checking.value = false
              return
            }

            opt && opt == 'login' && window.sessionStorage && window.sessionStorage.setItem('page_from', 'login')
            let prime_product_code = ''
            if (openControlLowPriceUser.value.interceptType == 2) {
              recordLog('8')
              // 低客单强拦截
              emitInitCheckout({ value: 0, msg: 'UnArrived Low Price', reason: 4 })
              commit('updateState', {
                key: 'showLowPriceUser',
                value: true
              })
              lowerPriceParams = {}
            } else if (openControlLowPriceUser.value.interceptType == 1 && opt != 'lowerPriceUser') {
              recordLog('8')
              // 低客单弱拦截, 使用opt避免二次拦截
              emitInitCheckout({ value: 0, msg: 'UnArrived Low Price', reason: 4 })
              lowerPriceParams = {}
              commit('updateState', {
                key: 'showLowPriceUser',
                value: true
              })
            } else {
              setTimeout(() => {
                checking.value = false
              }, 6000)
              recordLog('0')
              emitInitCheckout({ value: 1 })

              let checkoutUrl = getToCheckoutUrl({ prime_product_code, coupon_infos: state.mallCartBiData.autoUseCouponCodes })
              if(window.fetchCheckout) {
                try {
                  window.fetchCheckout.then(() => {
                    window.fetchCheckout = null
                    window.location.href = checkoutUrl
                  }).catch(()=>{
                    window.location.href = checkoutUrl
                  })
                } catch(e) {
                  window.location.href = checkoutUrl
                }
              } else {
                window.location.href = checkoutUrl
              }
            }
          } else {
            recordLog('2')
            emitInitCheckout({ value: 0, msg: 'Not login', reason: 1 })
            handleLoginEvent()
          }
        })
      }
    } else {
      recordLog('3')
      emitInitCheckout({ value: 0, msg: res.msg })
      if(res?.code == '10126002') {
        daEventCenter.triggerNotice({
          id: '1-7-2-5'
        })
        apiReport.report({
          apiPath: '/cart/checkout/billing_overrun'
        })
      }
      res.msg && SMessage({
        message: res.msg,
        type: 'error',
        duration: 4000
      })
    }
  } catch (error) {
    web_checkout_button_error(error)
    recordLog('500')
  }
}

const handleLoginEvent = async () => {
      
  await dispatch('handleCartLogin', { type: 'checkout' })
      
  goCheckout('login')
}

const initLowerPriceUserEvt = () => {
  let fromPage = getQueryString({ key: '_fromPage' })
  if (fromPage == 1 && openControlLowPriceUser.value.interceptType > 0) {
    commit('updateState', {
      key: 'showLowPriceUser',
      value: true
    })
  } else if (fromPage == 0) { // 进入购物车后, 自动checkout
    goCheckout()
  }
}

onMounted(() => {
  initLowerPriceUserEvt()
  initLowerPriceUserAbtTest()
  requestIdleCallback(() => {
    isIdle.value = true
  })
})
onBeforeMount(() => {
  if (navigator && navigator.userAgent) {
    isSafari.value = navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome') ? true : false
  }
})
</script>
<style lang="less">
.c-check{
  margin-bottom: 30px;
  .icon_doubt {
    color: @sui_color_gray_dark1;
  }
  &.c-summary-fixed{
    position: fixed;
    top: 0;
    .right(0);
    z-index: @zindex-header;
    background: #fff;
    padding-top: 10px;
  }
}

.flashSale-over-tip{
  text-align: left;
  .tip-item + .tip-item{
    margin-top: 18px;
  }
}
</style>

<style lang="less">
.check-btn{
  :deep(.sui-popover__trigger) {
    width: 100%;
  }
  .sui-popover__trigger {
    width: 100%;
  }
}
.c-check__cart-summary{
  display: flex;
  flex-direction: column;
  padding: 16px;
  max-height: 60vh;
  background: #fff;
  .check-subtitle {
    margin-bottom: 10px;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.30);
  }
  .summary-item {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &::before, &::after{
      display: none;
    }
    .price-label {
      flex-shrink: 99999;
      min-width: 77px;
      margin-right: 12px;
      font-size: 14px;
      color: @sui_color_gray_dark1;
      font-weight: 700;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .total{
      font-family: Arial-Black, Arial;
      font-size: 24px;
      padding: 0 4px;
      color:#222222;
    }
    .crossed-price{
      text-decoration: line-through;
    }
    .crossed-price-text{
      font-size: 13px;
      color: @sui_color_gray_dark3;
    }
  }
  .summary-tax-info {
    text-align: right;
    margin-bottom: 8px;
  }
  .cart-checkout__discountDetails {
    font-size: 14px;
    color: #C44A01;
    text-align: right;
  }
  .check-btn{
    .coupon-point-tip{
      color: #767676;
      text-align: left;
      margin-top: 10px;
      font-size: 12px;
    }
    .free-shipping-tip {  
      position: absolute;
      top: -9px;
      right: -5px;
      &::after {
        content: ' ';
        position: absolute;
        top: 100%;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px 0 0;
        border-color: #16533A transparent transparent transparent;
      }
    }
  }
}
.c-order-summary {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.summary-price{
  display: flex;
  align-items: baseline;
  .summary-price-crossed{
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
    color: #222;
    margin-right: 2px;
  }
}
.incentive-check-btn {
  ::v-deep .sui-popover__content {
    max-width: 368px;
    padding: 8px 20px 8px 8px;
  }
}
.incentive-button {
  display: flex;
  flex-direction: column;
  line-height: normal !important;/* stylelint-disable-line declaration-no-important */
  align-items: center;
  justify-content: center;
  gap: 2px;
  &__sweep-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    overflow: hidden;
  }
  .checkout-btn-content {
    width: 100%;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    box-sizing: border-box;
  }
}
</style>

