<template>
  <span
    data-v-4d906883=""
    class="sui-icon-common__wrap icon__container__preview__container"
    style="vertical-align: middle; display: inline-block; line-height: 0; font-size: 24px;">
    <svg
      style="width: auto; height: 1em;"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.95032 2.69995H16.0503V13.8L8.97167 13.7997L4.95032 17.2064L4.95002 13.7997L1.95032 13.8V2.69995ZM14.7002 12.4497V4.04995H3.30032V12.4497L6.30032 12.45L6.30002 14.2938L8.47673 12.45L14.7002 12.4497ZM5.85024 7.19995H7.65024V8.99995H5.85024V7.19995ZM12.1502 7.19995H10.3502V8.99995H12.1502V7.19995Z"
        fill="#666666" />
    </svg>
  </span>
</template>
