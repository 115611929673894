<template>
  <component
    :is="componentName"
    :filter-data="selectFilterLabel"
    :language="language"
  />
</template>

<script>
export default {
  name: 'FilterAdditionalInfo'
}
</script>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

// components
// import CouponTips from './CouponTips.vue'
import VoucherTips from './VoucherTips.vue'
import HighPriorityGoodsTips from './HighPriorityGoodsTips.vue'

const { state } = useStore()

const language = computed(() => state.language)
const selectFilterLabel = computed(() => state?.filterState?.selectFilterLabel)
const componentName = computed(() => {
  // 品类津提示
  if (selectFilterLabel.value?.filterTagId == 'category_voucher') return VoucherTips
  // 券筛选提示
  // if (selectFilterLabel.value?.groupClassId == 'coupon') return CouponTips
  // 高优商品提示
  if (selectFilterLabel.value?.recommendProductInfo?.cartId) return HighPriorityGoodsTips
  return null
})

</script>
