import { createHead } from '@vueuse/head' // 干嘛的？
import { createSSRApp as createVueApp } from 'vue'
import App from 'public/src/pages/goods_detail_v2/App.vue' // TODO 旧的, 二期开发完后，要用新的
import { registerBaseContext } from 'public/src/pages/common/common_inject.js'
import { GOODS_DETAIL_STORE } from './store/init.js'

export function createApp(context) {
  const store = GOODS_DETAIL_STORE(context)

  const app = createVueApp(App, {
    context
  })
  const head = createHead()
  registerBaseContext(app, context)
  app.use(head)
  app.use(store)
  return { app, store }
}
